"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateRegistrationsMutation = exports.registrationApi = void 0;
var baseApi_1 = require("./baseApi");
exports.registrationApi = baseApi_1.default.injectEndpoints({
    endpoints: function (builder) { return ({
        updateRegistrations: builder.mutation({
            query: function (_a) {
                var siteDomain = _a.siteDomain, programId = _a.programId, editURL = _a.editURL, registrations = _a.registrations, payerId = _a.payerId, siteId = _a.siteId, includeRegistrationTriageFix = _a.includeRegistrationTriageFix;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/registration"),
                    method: 'POST',
                    body: {
                        programId: programId,
                        editURL: editURL,
                        registrations: registrations,
                        payerId: payerId,
                        includeRegistrationTriageFix: includeRegistrationTriageFix,
                    },
                    params: {
                        siteId: siteId,
                    },
                };
            },
        }),
    }); },
});
exports.useUpdateRegistrationsMutation = exports.registrationApi.useUpdateRegistrationsMutation;
