import { useEffect, useState } from 'react';
import {
  CheckCircleIcon,
  ExclamationOctagonIcon,
  Loader,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import {
  useGetCSVBuilderStatusQuery,
  useGetCSVDownloadStatusQuery,
  useTriggerCSVDownloadMutation,
} from 'redux/services/rosterManagementApi';
import * as S from './RosterManagement.styles';

export type DownloadStatus =
  | 'builderIsBusy'
  | 'downloadComplete'
  | 'error'
  | 'checkingBuilder'
  | 'preparingCSV';

/* CSV Download Modal */
export default function CSVDownloadModal({
  body,
  closeModal,
  programId,
  programType,
}: {
  body: FormData;
  closeModal: () => void;
  programId: string;
  programType: string;
}) {
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatus>('checkingBuilder');
  const { data: builderStatusData, isError: isBuilderStatusError } =
    useGetCSVBuilderStatusQuery();
  const [triggerCSVDownload] = useTriggerCSVDownloadMutation();
  const { data: downloadStatusData, isError: isDownloadStatusError } =
    useGetCSVDownloadStatusQuery(downloadStatus, {
      skip: downloadStatus !== 'preparingCSV',
      pollingInterval: 1000,
    });

  useEffect(() => {
    if (builderStatusData === 'busy') {
      setDownloadStatus('builderIsBusy');
    } else if (builderStatusData === 'ready') {
      setDownloadStatus('preparingCSV');
      triggerCSVDownload({
        body,
        programId,
        programType,
      });
    } else if (isBuilderStatusError) {
      setDownloadStatus('error');
    }
  }, [
    body,
    builderStatusData,
    isBuilderStatusError,
    programId,
    programType,
    triggerCSVDownload,
  ]);

  useEffect(() => {
    function downloadCSVAndClose() {
      // `window.location.assign` is used to smoothly trigger the download
      window.location.assign('/export/download');
      setTimeout(closeModal, 3000);
    }

    if (downloadStatusData === 'complete') {
      setDownloadStatus('downloadComplete');
      downloadCSVAndClose();
    } else if (isDownloadStatusError || downloadStatusData === 'failure') {
      setDownloadStatus('error');
    }
  }, [
    builderStatusData,
    closeModal,
    downloadStatusData,
    isDownloadStatusError,
  ]);

  function returnBasedOnStatus() {
    switch (downloadStatus) {
      case 'builderIsBusy':
        return (
          <MessageBox
            messageBody="Please try again in one or two minutes. If the problem persists, please reach out to the tournament organizer."
            messageTitle="This process is busy."
            messageType="error"
          />
        );
      case 'error':
        return (
          <MessageBox
            messageBody="Please try again in one or two minutes. If the problem persists, please reach out to the tournament organizer."
            messageTitle="This process has encountered an unknown error."
            messageType="error"
          />
        );
      case 'downloadComplete':
        return (
          <MessageBox
            messageBody="You may close this window."
            messageTitle="Your CSV file has downloaded successfully."
            messageType="success"
          />
        );
      case 'checkingBuilder':
      case 'preparingCSV':
      default:
        return (
          <Loader
            loading={true}
            isFullscreen={false}
            description="Please do not close this window unless you want to cancel the download."
            title="Preparing CSV file. Do not close this window."
          />
        );
    }
  }

  return (
    <Modal
      hideClose={
        downloadStatus === 'checkingBuilder' ||
        downloadStatus === 'preparingCSV'
      }
      open={true}
      onOpenChange={closeModal}
      primaryAction={
        downloadStatus === 'downloadComplete' ? (
          <ModalClose>
            <ModalPrimaryActionButton>Ok</ModalPrimaryActionButton>
          </ModalClose>
        ) : null
      }
      tertiaryAction={
        downloadStatus !== 'downloadComplete' ? (
          <ModalClose>
            <ModalTertiaryActionButton>
              Cancel download
            </ModalTertiaryActionButton>
          </ModalClose>
        ) : null
      }
      size="small"
      title={`Download CSV`}
    >
      {returnBasedOnStatus()}
    </Modal>
  );
}
/* */

export function MessageBox({
  messageType,
  messageTitle,
  messageBody,
}: {
  messageType: 'error' | 'success';
  messageTitle: string;
  messageBody: string;
}) {
  return (
    <S.MessageBox $messageType={messageType}>
      <S.IconSection>
        {messageType === 'error' ? (
          <ExclamationOctagonIcon fill="var(--red-500)" size="xl" />
        ) : (
          <CheckCircleIcon fill="var(--green-500)" size="xl" />
        )}
      </S.IconSection>
      <S.TextSection>
        <S.MessageTitle>{messageTitle}</S.MessageTitle>{' '}
        <S.MessageBody>{messageBody}</S.MessageBody>
      </S.TextSection>
    </S.MessageBox>
  );
}
