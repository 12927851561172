/**
 * Returns the subtotal for a division
 *
 * @param costPerTeam A currency value with at most two decimal places, for example `5.49`
 * @param numberOfTeams Should be a non-negative integer
 */
export function getDivisionSubtotal(
  costPerTeam: number,
  numberOfTeams: number
) {
  return (costPerTeam * 100 * numberOfTeams) / 100;
}
