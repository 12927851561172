import * as React from 'react';
import { useMemo } from 'react';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { getStripeKey } from 'lib/utils/stripeUtils';
import { getSiteId } from 'redux/coreSlice';
import { useCreateCheckoutSessionQuery } from 'redux/services/paymentsAPI';
import { useAppSelector } from 'redux/store';
import PageTitle from '../Shell/PageTitle/PageTitle';
import * as S from './Membership.styles';

export type MembershipInfo = {};
export type StripePromise = Stripe | PromiseLike<Stripe | null> | null;

/* Membership */
export default function Membership() {
  const siteId = useAppSelector(getSiteId);
  const skip = !siteId;
  const {
    data: checkoutSessionOptions,
    error,
    isError,
    isLoading,
  } = useCreateCheckoutSessionQuery(
    {
      siteId,
    },
    { skip }
  );

  function membershipContentState() {
    if (isLoading) {
      return <>Loading</>;
    } else if (isError && error) {
      return <>{JSON.stringify(error)}</>;
    } else if (checkoutSessionOptions) {
      return <MembershipContent options={checkoutSessionOptions} />;
    } else {
      return <></>;
    }
  }

  return (
    <S.Membership id="membership-page">
      <PageTitle>Membership</PageTitle>
      <ColumnGrid>{membershipContentState()}</ColumnGrid>
    </S.Membership>
  );
}
/* */

/* Membership Content */
export function MembershipContent({ options }: { options: any }) {
  const { isDemoSite } = useFlags();

  const stripeKey = getStripeKey(isDemoSite);

  const stripePromise = useMemo<StripePromise>(
    () => loadStripe(stripeKey),
    [stripeKey]
  );

  return (
    <S.MembershipContent>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </S.MembershipContent>
  );
}
/* */
