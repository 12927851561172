import { MultiSelectOptionProps } from '@la/ds-ui-components';
import { Division } from 'redux/services/types/tournament';
import { TeamSelection } from '../../../../Wizard.types';
import { getNumberOfSpotsLeft } from '../../utils/capacity';

export const AT_CAPACITY_DISABLED_REASON =
  'You have selected the maximum number of teams allowed for this division.';

export const isDivisionAtCapacity = (
  division: Division,
  teamSelections: TeamSelection[]
): boolean => {
  const numberOfSpotsLeft = getNumberOfSpotsLeft(division);
  return (
    numberOfSpotsLeft !== undefined &&
    teamSelections.filter(({ teamId }) => teamId.length).length >=
      numberOfSpotsLeft
  );
};

export const disableTeamForAtCapacity = (
  teamOption: MultiSelectOptionProps,
  selections: TeamSelection[]
): MultiSelectOptionProps => {
  return {
    ...teamOption,
    disabled:
      !teamOption.checkboxState ||
      !!selections.find(
        (teamSelection) => teamSelection.teamId === teamOption.value
      ),
    disabledReason: teamOption.disabled
      ? teamOption.disabledReason
      : AT_CAPACITY_DISABLED_REASON,
  };
};

export const enableTeamForAtCapacity = (
  teamOption: MultiSelectOptionProps
): MultiSelectOptionProps => {
  return {
    ...teamOption,
    disabled:
      teamOption.disabledReason === AT_CAPACITY_DISABLED_REASON
        ? false
        : teamOption.disabled,
    disabledReason:
      teamOption.disabledReason === AT_CAPACITY_DISABLED_REASON
        ? undefined
        : teamOption.disabledReason,
  };
};
