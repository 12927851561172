"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationStartDate = exports.UnavailableMessage = exports.DivisionCardSavedInfoSectionRight = exports.DivisionCardInfoSectionRight = exports.DivisionCardTeamsText = exports.DivisionCardEditDivisionButton = exports.DivisionCardAddDivisionButton = exports.DivisionCardNotSelectedSectionRight = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var mixins_1 = require("../../../../../../lib/media-queries/mixins");
var themedIconButton = "\n  & svg {\n    fill: ".concat(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme.semantic) === null || _b === void 0 ? void 0 : _b.secondary[500];
}, ";\n  }\n\n  &:hover {\n    & svg {\n      fill: ").concat(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme.semantic) === null || _b === void 0 ? void 0 : _b.secondary[600];
}, ";\n    }\n  }\n\n  &:active {\n    & svg {\n      fill: ").concat(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme.semantic) === null || _b === void 0 ? void 0 : _b.secondary[800];
}, ";\n    }\n  }\n");
exports.DivisionCardNotSelectedSectionRight = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  align-self: flex-end;\n  flex-direction: row;\n  position: relative;\n  right: -16px;\n  bottom: -4px;\n"], ["\n  display: flex;\n  align-items: center;\n  align-self: flex-end;\n  flex-direction: row;\n  position: relative;\n  right: -16px;\n  bottom: -4px;\n"])));
exports.DivisionCardAddDivisionButton = (0, styled_components_1.default)(ds_ui_components_1.IconButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 4px 16px;\n\n  ", "\n"], ["\n  padding: 4px 16px;\n\n  ", "\n"])), themedIconButton);
exports.DivisionCardEditDivisionButton = (0, styled_components_1.default)(ds_ui_components_1.IconButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 4px 16px;\n"], ["\n  padding: 4px 16px;\n"])));
exports.DivisionCardTeamsText = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    variant: 'ui',
    size: 'large',
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  white-space: nowrap;\n"], ["\n  white-space: nowrap;\n"])));
exports.DivisionCardInfoSectionRight = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-self: flex-end;\n"], ["\n  align-self: flex-end;\n"])));
exports.DivisionCardSavedInfoSectionRight = (0, styled_components_1.default)(exports.DivisionCardInfoSectionRight)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  right: -12px;\n  bottom: -8px;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  position: relative;\n  right: -12px;\n  bottom: -8px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    right: -16px;\n    bottom: -12px;\n  "], ["\n    right: -16px;\n    bottom: -12px;\n  "]))));
exports.UnavailableMessage = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    variant: 'ui',
    size: 'large',
    weight: 'bold',
})(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-self: flex-end;\n  padding: 4px;\n  text-align: right;\n  color: ", ";\n"], ["\n  align-self: flex-end;\n  padding: 4px;\n  text-align: right;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.foundational.blueGrey[600];
});
exports.RegistrationStartDate = styled_components_1.default.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  white-space: nowrap;\n"], ["\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
