import { MutableRefObject, useState } from 'react';
import { Button, Modal, Typography } from '@la/ds-ui-components';
import { Division } from 'redux/services/types/tournament';
import * as S from './DivisionsModal.styles';

interface DivisionModalProps {
  divisions: Division[];
  refs: Map<string, MutableRefObject<HTMLElement | null>>;
}

export function DivisionsModal({ divisions, refs }: DivisionModalProps) {
  const [isModalDivisionsOpen, setIsDivisionsModalOpen] = useState(false);
  return (
    <>
      <Button variant="outline" onClick={() => setIsDivisionsModalOpen(true)}>
        View divisions
      </Button>
      <Modal
        open={isModalDivisionsOpen}
        title="Tournament divisions"
        onOpenChange={() => setIsDivisionsModalOpen(false)}
      >
        <main>
          <section>
            {divisions.map((division) => (
              <S.SideMenuItemContainer
                onClick={() => {
                  setIsDivisionsModalOpen(false);
                  refs.get(division.id)?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                  });
                }}
              >
                <Typography
                  key={`divisionModalItem${division.id}`}
                  size="large"
                  variant="ui"
                  weight="regular"
                >
                  {division.name}
                </Typography>
              </S.SideMenuItemContainer>
            ))}
          </section>
        </main>
      </Modal>
    </>
  );
}
