import styled from 'styled-components/macro';
import { CloseIcon, InfoIcon } from '@la/ds-ui-components';

export const Callout = styled.div`
  background: #f5f8ff;
  display: flex;
  align-items: center;
  min-height: 56px;
  margin: 0 0 12px;
  padding: 16px;
  color: var(--blue-grey-900);
  font: var(--ui-large-medium);
  border: 1px solid #3679fa;
  border-radius: 8px;
`;

export const CalloutIcon = styled(InfoIcon)`
  margin: 0 12px 0 0;
`;

export const Close_Icon = styled(CloseIcon)`
  margin: 0 0 0 12px;
`;

export const CloseIconWrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0 0 12px;
  flex-grow: 1;
  cursor: pointer;
`;
