/* Invite Players Modal */
import { PropsWithChildren, useState } from 'react';
import {
  Button,
  LinkIcon,
  Modal,
  ModalPrimaryActionButton,
  Select,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { copyTextToClipboard } from 'lib/utils/utilities';
import { InvitationLinks, StaffInvitation } from './RosterManagement';
import * as S from './RosterManagement.styles';

export type InviteModalData = {
  closeModal: () => void;
  playerInvite?: string;
  staffInvites?: StaffInvitation[];
  prompt: string;
  title: string;
};

export default function InvitePlayerStaffModals({
  invitesData,
  openModalName,
  closeModalMethod,
}: {
  invitesData?: InvitationLinks;
  openModalName: string | null;
  closeModalMethod: () => void;
}) {
  switch (openModalName) {
    case 'invitePlayers':
      return (
        <InvitePlayersModal
          closeModal={closeModalMethod}
          playerInvite={invitesData?.playerInviteLink}
          prompt="Invite parents to register their kids."
          title="Get player invite link"
        />
      );
    case 'inviteStaff':
      return (
        <InviteStaffModal
          closeModal={closeModalMethod}
          staffInvites={invitesData?.staffInviteLinks}
          prompt="Select a role to invite staff to register."
          title="Get staff invite link"
        />
      );
    default:
      return null;
  }
}

export function InviteModal({
  children,
  closeModal,
  prompt,
  title,
}: PropsWithChildren<Omit<InviteModalData, 'inviteURLs'>>) {
  return (
    <Modal
      onOpenChange={closeModal}
      open={true}
      primaryAction={
        <ModalPrimaryActionButton onClick={closeModal}>
          Done
        </ModalPrimaryActionButton>
      }
      size="medium"
      title={title}
    >
      <Typography variant="ui" size="large" weight="bold">
        {prompt}
      </Typography>
      {children}
    </Modal>
  );
}

/* Invite Players Modal */
export function InvitePlayersModal({
  closeModal,
  playerInvite,
  prompt,
  title,
}: InviteModalData) {
  return (
    <InviteModal closeModal={closeModal} prompt={prompt} title={title}>
      <InvitePlayerContent playerInvite={playerInvite} />
    </InviteModal>
  );
}
/*  */

/* Invite Player Content */
export function InvitePlayerContent({
  playerInvite,
}: {
  playerInvite?: string;
}) {
  return (
    <S.CopyPlayerLinkFields>
      <TextInput
        disabled={!playerInvite}
        id="share-player-invite-link"
        label="Share invite link"
        value={playerInvite}
      />
      <Button
        disabled={!playerInvite}
        leftIcon={
          <LinkIcon fill={'var(--secondary-400)'} size="xl" variant="bold" />
        }
        onClick={() => copyTextToClipboard(playerInvite!)}
        size="large"
        variant="outline"
      >
        Copy link
      </Button>
    </S.CopyPlayerLinkFields>
  );
}
/* */

/* Invite Staff Modal */
export function InviteStaffModal({
  closeModal,
  staffInvites = [],
  prompt,
  title,
}: InviteModalData) {
  return (
    <InviteModal closeModal={closeModal} prompt={prompt} title={title}>
      <InviteStaffContent staffInvites={staffInvites} />
    </InviteModal>
  );
}
/*  */

export function InviteStaffContent({
  staffInvites,
}: Pick<InviteModalData, 'staffInvites'>) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );
  const [staffInviteURL, setStaffInviteURL] = useState<string | undefined>(
    undefined
  );

  const staffInviteMenuOptions = staffInvites!.map(
    (invite: StaffInvitation) => {
      return { label: invite.role, value: invite.link };
    }
  );

  function handleStaffRoleSelection(option: string) {
    setSelectedOption(option);
    setStaffInviteURL(option);
  }

  function copyInviteURL() {
    if (staffInviteURL) {
      copyTextToClipboard(staffInviteURL);
    }
  }

  return (
    <S.CopyStaffLinkFields>
      <Select
        disabled={!staffInviteMenuOptions.length}
        id="staff-type-select"
        label="Share invite link"
        onChange={handleStaffRoleSelection}
        options={staffInviteMenuOptions}
        placeholder="Select a staff role"
        size="large"
        width="185px"
      />
      <TextInput
        disabled={!selectedOption}
        id="share-staff-invite-link"
        label=""
        value={staffInviteURL}
      />
      <Button
        disabled={!selectedOption}
        leftIcon={
          <LinkIcon fill={'var(--secondary-400)'} size="xl" variant="bold" />
        }
        onClick={copyInviteURL}
        size="large"
        variant="outline"
      >
        Copy link
      </Button>
    </S.CopyStaffLinkFields>
  );
}
