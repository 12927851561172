import styled from 'styled-components';
import { ThemedComponent, Typography } from '@la/ds-ui-components';

export const TeamSelectionContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--primary-35);
  width: 100%;
`;

export const SelectSection = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    return {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      marginTop: 16,

      [theme.mediaQueries.mobile]: {
        flexDirection: 'row',
      },
    };
  }
);

export const SelectContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const AddTeamsButtonContainer = styled.div({
  '& button': {
    backgroundColor: 'white',
  },
});

export const AddedTeamsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type AddedTeamTransientProps = ThemedComponent<{ $isLast?: boolean }>;

export const AddedTeam = styled.div<AddedTeamTransientProps>(
  ({ theme, $isLast }: AddedTeamTransientProps) => {
    const borderColor = theme.semantic
      ? theme.semantic.primary[100]
      : theme.foundational.blueGrey[100];
    return {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.foundational.white,
      minHeight: 48,
      padding: 12,
      borderBottom: $isLast ? 'none' : `1px solid ${borderColor}`,
    };
  }
);

export const ActionIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
`;

export const AddedTeamNameContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow-wrap: break-word;
  flex-wrap: wrap;
`;

export const AddedTeamName = styled(Typography)`
  word-break: break-word;
  flex-grow: 1;
`;

export const DiscardTeamButtonContainer = styled.div<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;
