import { useReducer, useRef, FormEvent, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeElementType,
  StripeError,
  Token,
  TokenResult,
} from '@stripe/stripe-js';
import validator from 'validator';
import { Checkbox, TextInput } from '@la/ds-ui-components';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import TextInputContainer from 'lib/stripe/TextInputContainer/TextInputContainer';
import { StripeTextInputStyle } from 'lib/stripe/TextInputContainer/TextInputContainer.styles';
import { formatToISO } from 'lib/utils/dateUtils';
import { getSiteId } from 'redux/coreSlice';
import {
  useAddCreditCardMutation,
  useGetPaymentMethodQuery,
} from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import {
  AddCreditCardRequestData,
  SingleUseCard,
  StoredCreditCard,
} from '../Checkout.types';
import { useCardFormErrors } from './UseCardFormErrors';
import * as S from './UseCardModal.styles';

export type StripeElementChangeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

export type NetworkError = {
  type: string;
  title: string;
  status: 404 | 422;
  detail: string;
  data?: {
    errorMessage: string;
  };
};

export type UseCardFormData = {
  closeModal: () => void;
  submissionErrorHandler: (errorMessage: string) => void;
  updateLoadingOverlayVisibility: (isVisible: boolean) => void;
};

export type CardholderData = {
  address_country: string;
  address_zip: string;
  name: string;
};

export type FieldActionTarget = 'cardholderName' | 'zipCode';

export type FieldAction = {
  hasError: boolean;
  isComplete?: boolean;
  isTouched?: boolean;
  target: FieldActionTarget | StripeElementType;
};

export type FieldState = {
  hasError: boolean;
  isComplete?: boolean;
  isTouched?: boolean;
};

export type FieldInfo = {
  [key: string]: FieldState;
};

const fieldStates: FieldInfo = {
  cardCvc: { hasError: false, isComplete: false, isTouched: false },
  cardExpiry: { hasError: false, isComplete: false, isTouched: false },
  cardNumber: { hasError: false, isComplete: false, isTouched: false },
  cardholderName: { hasError: false, isComplete: false },
  zipCode: { hasError: false, isComplete: false },
};

function fieldStatesReducer(state: FieldInfo, action: FieldAction): FieldInfo {
  const { hasError, isComplete, isTouched, target } = action;
  switch (target) {
    case 'cardholderName':
      return {
        ...state,
        cardholderName: {
          hasError: hasError,
          isComplete: isComplete,
        },
      };
    case 'cardNumber':
      return {
        ...state,
        cardNumber: {
          hasError: hasError,
          isComplete: isComplete,
          isTouched: isTouched,
        },
      };
    case 'cardExpiry':
      return {
        ...state,
        cardExpiry: {
          hasError: hasError,
          isComplete: isComplete,
          isTouched: isTouched,
        },
      };
    case 'cardCvc':
      return {
        ...state,
        cardCvc: {
          hasError: hasError,
          isComplete: isComplete,
          isTouched: isTouched,
        },
      };
    case 'zipCode':
      return {
        ...state,
        zipCode: { hasError: hasError, isComplete: isComplete },
      };
    default:
      return state;
  }
}

/* Use Card Form */
export default function UseCardForm({
  closeModal,
  submissionErrorHandler,
  updateLoadingOverlayVisibility,
}: UseCardFormData) {
  const { hasAutopayPaymentOptionSelected, updateSelectedPaymentMethod } =
    useCheckoutInfo();
  const siteId = useAppSelector(getSiteId);
  const [addCreditCard] = useAddCreditCardMutation();
  const { data: userId } = useGetUserIdQuery(siteId);
  const { refetch: refetchPaymentMethods } = useGetPaymentMethodQuery({
    siteId: siteId,
    userId: userId,
  });
  const elements = useElements();
  const [state, dispatch] = useReducer(fieldStatesReducer, fieldStates);
  const cardholderNameField = useRef<HTMLInputElement>(null);
  const [shouldStorePayment, setShouldStorePayment] = useState<boolean>(
    hasAutopayPaymentOptionSelected
  );
  const storePaymentCheckbox = useRef<HTMLButtonElement>(null);
  const zipCodeField = useRef<HTMLInputElement>(null);
  const stripe = useStripe();

  /**
   * Updates the locally stored state information of the Cardholder name field,
   * specifically about whether is in an error state, which it will be if it is
   * currently empty or only contains blank spaces.
   */
  function validateCardholderField(): void {
    const isFieldEmpty =
      !!cardholderNameField.current &&
      validator.isEmpty(cardholderNameField.current.value, {
        ignore_whitespace: true,
      });
    dispatch({
      target: 'cardholderName',
      hasError: isFieldEmpty,
      isComplete: !isFieldEmpty,
    });
  }

  /**
   * This function is called both when the field is changed and when it loses
   * focus. The string value, if any, of the Zip code field is passed to the
   * `validator` method `isPostalCode` and the boolean returned is used to
   * set the value of `zipCodeIsValid`. Additionally, if the Zip code field
   * is empty then `zipCodeIsValid` is set to `false`. If `zipCodeIsValid`
   * is true then the locally stored state is updated to remove any existing
   * error state. If `zipCodeIsValid` is false and the function is called
   * when the field loses focus or the function is called without an event
   * passed as an argument then the locally stored state is updated to put
   * the field in an error state.
   * @param evt
   */
  function validateZipCodeField(evt?: FormEvent<HTMLInputElement>): void {
    const zipCodeIsValid =
      !!zipCodeField.current &&
      (validator.isPostalCode(zipCodeField.current.value, 'US') ||
        validator.isPostalCode(zipCodeField.current.value, 'CA'));

    if (zipCodeIsValid) {
      dispatch({ target: 'zipCode', hasError: false, isComplete: true });
    } else if (evt?.type === 'blur' || !evt) {
      dispatch({ target: 'zipCode', hasError: true, isComplete: false });
    }
  }

  /**
   * Following a change to a Stripe Element field this method determines if the
   * field is in an error state, whether the field is "complete" and whether it
   * has been "touched". If the field is in an error state or if it is
   * "complete" then these three values in the field's locally stored state are
   * updated.
   * If the `StripeElementChangeEvent` returns an error OR if the field is empty
   * OR if both the field's locally stored state value for `hasError` is
   * currently `true` AND the field isn't "complete" then the field's locally
   * stored state is updated to reflect the error state.
   * Whether the field is "complete" is extracted from the
   * StripeElementChangeEvent.
   * Any change to this field means that it has received focus and therefor it
   * will be marked as having been "touched".
   * @param evt
   */
  function onStripeFieldChange(evt: StripeElementChangeEvent): void {
    let hasError =
      !!evt?.error || (state[evt.elementType].hasError && !evt?.complete);
    let isComplete = evt?.complete;
    let isTouched = true;

    dispatch({
      hasError: hasError,
      isComplete: isComplete,
      isTouched: isTouched,
      target: evt.elementType,
    });
  }

  /**
   * Called when a Stripe Element field loses focus. If the field is not
   * complete then its locally stored state is updated to reflect this, that
   * the field is in an error state and that it has been "touched".
   * @param evt
   */
  function onStripeFieldBlur(evt: { elementType: StripeElementType }): void {
    const { elementType } = evt;
    if (!state[elementType].isComplete) {
      dispatch({
        hasError: true,
        isComplete: false,
        isTouched: true,
        target: elementType,
      });
    }
  }

  /**
   * Call the two functions that validate the Cardholder name field and the
   * Zip code field respectively.
   */
  function validateUserFields() {
    validateCardholderField();
    validateZipCodeField();
  }

  /**
   * Loop through the three Stripe Element fields, validate each and update its
   * locally stored state if it is not currently complete.
   * NOTE: This function does not interact with any Stripe API. It is only
   * checking the values of the fields' locally stored states.
   */
  function validateStripeFields() {
    const stripeElements: StripeElementType[] = [
      'cardCvc',
      'cardExpiry',
      'cardNumber',
    ];
    stripeElements.forEach((element) => {
      if (!state[element].isComplete) {
        dispatch({
          target: element,
          hasError: true,
          isComplete: false,
          isTouched: true,
        });
      }
    });
  }

  /** Form Submission Functions **/

  /**
   * When the form is submitted its field values are extracted and if they are
   * all complete then they are passed to the `saveCard` function. If they are
   * not all complete then validation is run against all fields and nothing is
   * submitted. Validation is run on all fields if any is invalid when the form
   * is submitted so that if a user tries to submit the form before having given
   * focus to, or "touched", any field, that field will display an error.
   * @param evt
   */
  function onUseCardFormSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    submissionErrorHandler('');

    validateUserFields();
    validateStripeFields();

    const cardNumber = getCardNumber();
    const cardholderData = getCardholderData();

    if (cardholderData && cardNumber) {
      saveCard(cardNumber, cardholderData);
    }
  }

  /**
   * If the Cardholder name and Zip code fields are complete (as recorded in
   * their locally stored states) then they are returned in an object along
   * with an `address_country` value that is always set to "US". If either
   * is incomplete then `null` is returned.
   */
  function getCardholderData() {
    return state.cardholderName.isComplete &&
      state.zipCode.isComplete &&
      cardholderNameField?.current &&
      zipCodeField?.current
      ? {
          address_country: 'US',
          address_zip: zipCodeField.current.value,
          name: cardholderNameField.current.value,
        }
      : null;
  }

  /**
   * If all of the Stripe fields are complete (as recorded in their locally
   * stored states) then this function returns the result of the Stripe
   * method `elements.getElement('cardNumber')` which will include needed
   * information about the Card Number, Card Expiry and Card CVC fields. If
   * any of the Stripe Element fields are currently incomplete then this
   * function returns `null` since these fields are all required.
   */
  function getCardNumber() {
    return state.cardNumber.isComplete &&
      state.cardExpiry.isComplete &&
      state.cardCvc.isComplete
      ? elements?.getElement('cardNumber')
      : null;
  }

  /**
   * This function submits the data gathered from all form fields to Stripe.
   * Stripe returns either an `error` or a `token`. If an `error` is returned it
   * is passed to the `handleStripeCreateTokenFailure` function. If a `token` is returned
   * it is passed to the `handleStripeCreateTokenSuccess` function and then the
   * `handlePaymentMethodStorage` function is called.
   * @param cardNumber
   * @param cardholderData
   */
  function saveCard(
    cardNumber: StripeCardNumberElement,
    cardholderData: CardholderData
  ) {
    updateLoadingOverlayVisibility(true);
    stripe
      ?.createToken(cardNumber, cardholderData)
      .then((result: TokenResult) => {
        if (result.error) {
          handleStripeCreateTokenFailure(result.error);
        } else if (result.token) {
          handleStripeCreateTokenSuccess(result.token);
        }
      });
  }

  /**
   * If `stripe.createToken` returns an `error` then it is passed to this
   * function. If the error has a `message` attribute then that message is
   * set as the value of `errorMessage`, otherwise the value of
   * `fallbackMessage` is used for `errorMessage`. The loading animation
   * overlay is removed and the `errorMessage` is passed to
   * `submissionErrorHandler`. Finally the error is displayed in the console.
   * @param error
   */
  function handleStripeCreateTokenFailure(error: StripeError) {
    const fallbackMessage =
      'There was an error adding this payment method.' +
      ' Please try again in a few seconds.';
    const errorMessage = error?.message ?? fallbackMessage;
    updateLoadingOverlayVisibility(false);
    submissionErrorHandler(errorMessage);
    console.error('stripe.createToken() returned the following error: ', error);
  }

  /**
   * If `stripe.createToken` returns a `token` then it is passed to this
   * function where it is parsed and passed to the `updateCardInfo` method
   * which stores it for further use during this session.
   * @param token
   */
  function handleStripeCreateTokenSuccess(token: Token) {
    const cardInfo: SingleUseCard = {
      cardBrand: token.card?.brand ?? '',
      cardExpirationMonth: token.card?.exp_month ?? null,
      cardExpirationYear: token.card?.exp_year ?? null,
      cardId: token.card?.id ?? '',
      cardLast4: token.card?.last4 ?? '',
      cardType: 'SINGLE_USE',
      paymentType: 'CARD',
      tokenId: token.id ?? '',
    };
    const checkboxStatus = storePaymentCheckbox.current?.dataset.state;

    if (checkboxStatus === 'unchecked') {
      updateLoadingOverlayVisibility(false);
      updateSelectedPaymentMethod(cardInfo);
      closeModal();
    } else {
      handlePaymentMethodStorage(cardInfo);
    }
  }

  /**
   * If the "Store Payment Method" checkbox is unchecked when this function is
   * called then the modal is closed. If it is checked then card info from
   * the response is passed to the DB.
   */
  function handlePaymentMethodStorage({
    cardBrand,
    cardLast4,
    tokenId,
  }: SingleUseCard) {
    const addCreditCardRequestData: AddCreditCardRequestData = {
      autoBilled: true,
      last4Digits: cardLast4,
      paymentNetwork: cardBrand,
      primaryMethod: true,
      stripeToken: tokenId,
    };

    addCreditCard({
      body: addCreditCardRequestData,
      siteId: siteId,
      userId: userId,
    })
      .unwrap()
      .then((response) => {
        const { storedCreditCard } = response;
        const EXPIRATION_DATE_ISO = formatToISO(
          storedCreditCard.expirationYear,
          storedCreditCard.expirationMonth
        );
        const newStoredCreditCardData: StoredCreditCard = {
          cardType: 'STORED',
          createdOn: storedCreditCard.createdOn,
          expirationDate: EXPIRATION_DATE_ISO,
          isPrimaryPaymentOption:
            storedCreditCard.settings.isPrimaryPaymentOption,
          last4Digits: storedCreditCard.last4Digits,
          paymentNetwork: storedCreditCard.creditCardTypeTitle,
          paymentType: 'CARD',
          storedCreditCardId: storedCreditCard.storedCreditCardId,
          paymentMethodId: storedCreditCard.storedCreditCardId,
        };
        refetchPaymentMethods();
        updateSelectedPaymentMethod(newStoredCreditCardData);
        handlePaymentStorageSuccess();
      })
      .catch((error) => {
        handlePaymentStorageFailure(error);
      });
  }

  function handlePaymentStorageSuccess() {
    updateLoadingOverlayVisibility(false);
    closeModal();
  }

  function handlePaymentStorageFailure(error: NetworkError): void {
    updateLoadingOverlayVisibility(false);
    submissionErrorHandler(
      'We had an issue storing your card. Please try again or proceed without storing.'
    );
    console.error(error.title);
  }

  /** **/

  return (
    <form
      id="use-card-form"
      aria-label="Card details"
      noValidate={true}
      onSubmit={onUseCardFormSubmit}
    >
      <S.FormSectionLabel>Card details</S.FormSectionLabel>
      <S.FormFields>
        <TextInput
          errorMessage={useCardFormErrors.INVALID_CARDHOLDER_NAME}
          hasError={state.cardholderName.hasError}
          id="cardholder-name-input"
          label="Cardholder name"
          onBlur={validateCardholderField}
          onChange={validateCardholderField}
          ref={cardholderNameField}
          required={true}
        />
        <TextInputContainer
          errorMessage={useCardFormErrors.INVALID_CARD_NUMBER}
          hasError={state.cardNumber.hasError}
          label="Card number"
          required={true}
        >
          <CardNumberElement
            onBlur={onStripeFieldBlur}
            onChange={onStripeFieldChange}
            options={{ style: StripeTextInputStyle }}
          />
        </TextInputContainer>
        <S.TwoFieldRow>
          <TextInputContainer
            errorMessage={useCardFormErrors.INVALID_EXPIRATION_DATE}
            hasError={state.cardExpiry.hasError}
            label="Exp date"
            required={true}
          >
            <CardExpiryElement
              onBlur={onStripeFieldBlur}
              onChange={onStripeFieldChange}
              options={{ style: StripeTextInputStyle }}
            />
          </TextInputContainer>
          <TextInputContainer
            errorMessage={useCardFormErrors.INVALID_CVC}
            hasError={state.cardCvc.hasError}
            label="CVC/CVV"
            required={true}
          >
            <CardCvcElement
              onBlur={onStripeFieldBlur}
              onChange={onStripeFieldChange}
              options={{ style: StripeTextInputStyle }}
            />
          </TextInputContainer>
        </S.TwoFieldRow>
        <TextInput
          errorMessage={useCardFormErrors.INVALID_ZIP_CODE}
          hasError={state.zipCode.hasError}
          id="cardholder-zip-code-input"
          label="Zip/Postal code"
          onBlur={validateZipCodeField}
          onChange={validateZipCodeField}
          ref={zipCodeField}
          required={true}
        />
      </S.FormFields>
      <S.FormSectionLabel>Card preferences</S.FormSectionLabel>
      <S.CheckboxContainer>
        <Checkbox
          size="large"
          ariaLabel="store-payment-method"
          checked={shouldStorePayment}
          disabled={hasAutopayPaymentOptionSelected}
          id="store-payment-method"
          label="Store payment method"
          onCheckedChange={setShouldStorePayment}
          ref={storePaymentCheckbox}
        />
      </S.CheckboxContainer>
      <S.Note>Note: This will replace a previously stored card.</S.Note>
    </form>
  );
}
/* */
