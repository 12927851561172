"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTournamentForRegistration = void 0;
var react_router_dom_1 = require("react-router-dom");
var services_1 = require("@la/services");
/**
 * Gets the tournament information for the registration wizard
 * including the registrations already created by this user for
 * this tournament.
 */
function useTournamentForRegistration(_a) {
    var id = _a.id, payerId = _a.payerId;
    var siteId = (0, react_router_dom_1.useParams)().siteId;
    var _b = (0, services_1.useGetTournamentRegistrationQuery)({
        siteDomain: 'manager',
        tournamentId: id,
        payerId: payerId,
        siteId: siteId,
    }), tournament = _b.data, isLoading = _b.isLoading, error = _b.error;
    return { tournament: tournament, isLoading: isLoading, error: error };
}
exports.useTournamentForRegistration = useTournamentForRegistration;
