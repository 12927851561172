import { DateTime } from 'luxon';
import { Typography } from '@la/ds-ui-components';
import { CardBody, CardHeader, CardTitle } from 'components/Card/Card';
import { formatDateRange } from 'lib/utils/dateUtils';
import * as S from './TournamentDatesCard.styles';

export type TournamentDatesCardProps = {
  /**
   * Whether or not the location card is showing
   */
  isShowingLocation?: boolean;
  /**
   * The day the tournament starts - in the format YYYY-MM-DD
   */
  startDate: string;
  /**
   * The day the tournament ends - in the format YYYY-MM-DD
   */
  endDate?: string;
};

export function TournamentDatesCard({
  isShowingLocation,
  startDate,
  endDate,
}: TournamentDatesCardProps) {
  const dateRangeText = formatDateRange(
    DateTime.fromISO(startDate),
    endDate ? DateTime.fromISO(endDate) : undefined
  );

  return (
    <S.TournamentDatesCard
      $isShowingLocation={isShowingLocation}
      data-testid="tournament-dates-card"
    >
      <CardHeader>
        <CardTitle>Tournament Dates</CardTitle>
      </CardHeader>
      <CardBody>
        <Typography variant="ui" size="large">
          {dateRangeText}
        </Typography>
      </CardBody>
    </S.TournamentDatesCard>
  );
}
