import { Root, Viewport } from '@radix-ui/react-toast';
import styled, { keyframes } from 'styled-components';
import { Typography } from 'components/Typography/Typography';
const slideIn = keyframes(["from{transform:translateX(calc(100% + 24px));}to{transform:translateX(0);}"]);
const progressForward = keyframes(["from{transform:scaleX(0);}to{transform:scaleX(1);}"]);

// Translating from the right will cause a temporary overflow
// so we wrap Snackbar in an absolute container that is already positioned in the right place
// and hide the overflow so that Snackbar can reach the correct viewport without causing a scrollbar to appear.
export const SnackbarContainer = styled.div.withConfig({
  componentId: "sc-1xe17kp-0"
})({
  position: 'absolute',
  overflow: 'hidden',
  zIndex: -1
});
const AnimatedSnackbar = styled(Root).withConfig({
  componentId: "sc-1xe17kp-1"
})(["animation:", " 150ms cubic-bezier(0.16,1,0.3,1);"], slideIn);
export const SnackbarRoot = styled(AnimatedSnackbar).withConfig({
  componentId: "sc-1xe17kp-2"
})(({
  theme,
  $variant
}) => {
  const {
    foundational: {
      blueGrey,
      blue,
      green,
      red
    },
    spacing
  } = theme;
  const iconColor = {
    info: blue[300],
    success: green[200],
    error: red[200]
  };
  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.xs,
    listStyle: 'none',
    backgroundColor: blueGrey[900],
    borderRadius: spacing.xxs,
    padding: spacing.xs,
    width: '380px',
    marginTop: spacing.small,
    height: '100%',
    '& svg': {
      fill: iconColor[$variant],
      stroke: iconColor[$variant],
      flex: 1
    }
  };
});
export const SnackbarViewport = styled(Viewport).withConfig({
  componentId: "sc-1xe17kp-3"
})(({
  theme
}) => {
  return {
    position: 'fixed',
    bottom: 3,
    right: 'calc((100% - 380px) / 2)',
    [theme.mediaQueries.tablet]: {
      bottom: '24px',
      right: '24px'
    }
  };
});
export const VariantIconContainer = styled.div.withConfig({
  componentId: "sc-1xe17kp-4"
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start'
});
export const SnackbarDescription = styled(Typography).withConfig({
  componentId: "sc-1xe17kp-5"
})(({
  theme
}) => {
  return {
    flex: 1,
    color: theme.foundational.white,
    overflowWrap: 'break-word'
  };
});
export const CloseIconContainer = styled.div.withConfig({
  componentId: "sc-1xe17kp-6"
})(({
  theme
}) => {
  const {
    foundational: {
      white,
      green
    }
  } = theme;
  return {
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      '&:focus': {
        outlineColor: green[200]
      }
    },
    '& button svg': {
      fill: white,
      stroke: white,
      strokeWidth: '24px'
    }
  };
});
const AnimatedProgress = styled.span.withConfig({
  componentId: "sc-1xe17kp-7"
})(["animation:", ";animation-timing-function:linear;transform-origin:left center;z-index:-1;"], progressForward);
export const SnackbarProgressContainer = styled.span.withConfig({
  componentId: "sc-1xe17kp-8"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    position: 'absolute',
    left: 1,
    bottom: -3,
    height: 10,
    width: 'calc(100% - 2px)',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    borderBottomLeftRadius: spacing.xs,
    borderBottomRightRadius: spacing.xs
  };
});
export const SnackbarProgress = styled(AnimatedProgress).withConfig({
  componentId: "sc-1xe17kp-9"
})(({
  theme,
  $variant,
  $duration
}) => {
  const {
    foundational: {
      blue,
      green,
      red
    }
  } = theme;
  const iconColor = {
    info: blue[500],
    success: green[500],
    error: red[500]
  };
  return {
    position: 'absolute',
    left: 0,
    bottom: -3,
    height: '100%',
    width: '100%',
    zIndex: -1,
    backgroundColor: iconColor[$variant],
    animationDuration: `${$duration}ms`
  };
});