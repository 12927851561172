"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasSubmissionErrors = exports.noDivisionsSelected = exports.getIdsOfUnsavedDivisions = exports.registrationWizardReducers = exports.getInitialDivisionCardsState = void 0;
var utilities_1 = require("@la/utilities");
function getInitialDivisionCardsState(_a) {
    var divisions = _a.divisions, timeZone = _a.timeZone, payerId = _a.payerId;
    return divisions.reduce(function (acc, division) {
        /*
         * First we check for reasons the division may be unavailable for registration
         *
         * Potential reasons:
         *   - Registration period has not started yet
         *   - Registration period has ended
         *   - Division has reached maximum capacity
         */
        var _a, _b, _c, _d, _e;
        var _f, _g, _h;
        var registrationPeriodStatus = (0, utilities_1.getRegistrationPeriodStatus)(division.registrationStartDate, division.registrationEndDate, timeZone);
        if (registrationPeriodStatus.state === 'before-registration-period-opens' ||
            registrationPeriodStatus.state === 'after-registration-period-ends') {
            return __assign(__assign({}, acc), (_a = {}, _a[division.id] = {
                value: 'unavailable',
                reasonUnavailable: registrationPeriodStatus.state,
            }, _a));
        }
        var atCapacity = division.maxNumberOfTeams !== undefined &&
            division.numberOfRegisteredTeams !== undefined &&
            division.maxNumberOfTeams <= division.numberOfRegisteredTeams;
        if (atCapacity) {
            return __assign(__assign({}, acc), (_b = {}, _b[division.id] = {
                value: 'unavailable',
                reasonUnavailable: 'at-capacity',
            }, _b));
        }
        /*
         * The division is available for registration.
         */
        if (((_f = division.registrationsForUser) === null || _f === void 0 ? void 0 : _f.team) &&
            ((_g = division.registrationsForUser) === null || _g === void 0 ? void 0 : _g.team.length) > 0) {
            // TODO: Inlude 'IN_ERROR' when we implement the ability to show the appropriate error messages
            var teamsToInclude = (_h = division.registrationsForUser) === null || _h === void 0 ? void 0 : _h.team.filter(function (registration) {
                return registration.registrationStatus === 'CREATED' &&
                    registration.registeringUserId === payerId &&
                    !registration.deleted;
            });
            if (teamsToInclude.length === 0) {
                return __assign(__assign({}, acc), (_c = {}, _c[division.id] = {
                    value: 'not-selected',
                }, _c));
            }
            return __assign(__assign({}, acc), (_d = {}, _d[division.id] = {
                value: 'saved',
                savedTeams: teamsToInclude.map(function (registration) { return registration.teamId; }),
            }, _d));
        }
        return __assign(__assign({}, acc), (_e = {}, _e[division.id] = {
            value: 'not-selected',
        }, _e));
    }, {});
}
exports.getInitialDivisionCardsState = getInitialDivisionCardsState;
exports.registrationWizardReducers = {
    selectDivision: function (state, action) {
        var divisionId = action.payload.divisionId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-selected') {
            state.divisionCardsState[divisionId] = {
                value: 'not-saved',
                teamSelections: [{ teamId: '' }],
                isShowingDivisionNotSavedMessage: false,
                isShowingSubmissionErrorMessage: false,
            };
            state.submitButtonError = null;
        }
        else {
            console.warn("The 'selectDivision' action is only valid for a division that is in the 'not-selected' state. The current state is ".concat(divisionCardState.value));
        }
    },
    cancelDivisionChanges: function (state, action) {
        var divisionId = action.payload.divisionId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-saved') {
            state.divisionCardsState[divisionId] = {
                value: 'not-selected',
            };
            state.submitButtonError = null;
        }
        else if (divisionCardState.value === 'saved-and-editing') {
            state.divisionCardsState[divisionId] = {
                value: 'saved',
                savedTeams: divisionCardState.savedTeams,
            };
            state.submitButtonError = null;
        }
        else {
            console.warn("The 'cancel' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ".concat(divisionCardState.value));
        }
    },
    addTeamSelect: function (state, action) {
        var divisionId = action.payload.divisionId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-saved' ||
            divisionCardState.value === 'saved-and-editing') {
            state.divisionCardsState[divisionId] = __assign(__assign({}, divisionCardState), { teamSelections: __spreadArray(__spreadArray([], divisionCardState.teamSelections, true), [{ teamId: '' }], false) });
        }
        else {
            console.warn("The 'addTeamSelect' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ".concat(divisionCardState.value));
        }
    },
    discardTeamSelect: function (state, action) {
        var _a = action.payload, divisionId = _a.divisionId, selectIndex = _a.selectIndex;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-saved' ||
            divisionCardState.value === 'saved-and-editing') {
            if (selectIndex >= 0 &&
                selectIndex < divisionCardState.teamSelections.length) {
                state.divisionCardsState[divisionId] = __assign(__assign({}, divisionCardState), { teamSelections: divisionCardState.teamSelections.filter(function (selection, index) { return selectIndex !== index; }), isShowingDivisionNotSavedMessage: false });
            }
            else {
                console.warn("The index ".concat(selectIndex, " is not valid based on the current length of the team selections."));
            }
        }
        else if (divisionCardState.value === 'saved') {
            var savedTeams = divisionCardState.savedTeams.filter(function (_, index) { return selectIndex !== index; });
            if (savedTeams.length === 0) {
                state.divisionCardsState[divisionId] = {
                    value: 'not-selected',
                };
            }
            else {
                state.divisionCardsState[divisionId] = __assign(__assign({}, divisionCardState), { savedTeams: savedTeams });
            }
        }
        else {
            console.warn("The 'discardTeamSelect' action is only valid for a division that is in the 'not-saved', 'saved-and-editing', and 'saved' states. The current state is ".concat(divisionCardState.value));
        }
    },
    selectTeam: function (state, action) {
        var _a = action.payload, divisionId = _a.divisionId, selectIndex = _a.selectIndex, teamId = _a.teamId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-saved' ||
            divisionCardState.value === 'saved-and-editing') {
            state.divisionCardsState[divisionId] = __assign(__assign({}, divisionCardState), { teamSelections: divisionCardState.teamSelections.map(function (selection, index) { return (index === selectIndex ? { teamId: teamId } : selection); }), isShowingDivisionNotSavedMessage: false });
        }
        else {
            console.warn("The 'selectTeam' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ".concat(divisionCardState.value));
        }
    },
    saveDivision: function (state, action) {
        var divisionId = action.payload.divisionId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'not-saved' ||
            divisionCardState.value === 'saved-and-editing') {
            // Check for unfilled team selects: they prevent saving, trigger error messages
            if (divisionCardState.teamSelections.some(function (selection) { return selection.teamId === ''; })) {
                state.divisionCardsState[divisionId] = __assign(__assign({}, divisionCardState), { teamSelections: divisionCardState.teamSelections.map(function (selection) {
                        return selection.teamId === '' ? { teamId: '', hasError: true } : selection;
                    }), isShowingDivisionNotSavedMessage: true });
            }
            else {
                state.divisionCardsState[divisionId] = {
                    value: 'saved',
                    savedTeams: divisionCardState.teamSelections.map(function (selection) { return selection.teamId; }),
                };
                state.submitButtonError = null;
            }
        }
        else {
            console.warn("The 'saveDivision' action is only valid for a division that is in the 'not-saved' or 'saved-and-editing' states. The current state is ".concat(divisionCardState.value));
        }
    },
    editDivision: function (state, action) {
        var divisionId = action.payload.divisionId;
        var divisionCardState = state.divisionCardsState[divisionId];
        if (divisionCardState.value === 'saved') {
            state.divisionCardsState[divisionId] = {
                value: 'saved-and-editing',
                savedTeams: divisionCardState.savedTeams,
                teamSelections: divisionCardState.savedTeams.map(function (teamId) { return ({
                    teamId: teamId,
                }); }),
                isShowingDivisionNotSavedMessage: false,
                isShowingSubmissionErrorMessage: false,
            };
        }
        else {
            console.warn("The 'edit' action is only valid for a division that is in the 'saved' state. The current state is ".concat(divisionCardState.value));
        }
    },
    clearDivision: function (state, action) {
        var divisionId = action.payload.divisionId;
        state.divisionCardsState[divisionId] = {
            value: 'not-selected',
        };
        state.submitButtonError = null;
    },
    submit: function (state) {
        var idsOfUnsavedDivisions = getIdsOfUnsavedDivisions(state.divisionCardsState);
        if (idsOfUnsavedDivisions.length > 0) {
            state.submitButtonError = 'unsaved-divisions';
            idsOfUnsavedDivisions.forEach(function (divisionId, index) {
                var divisionCardState = state.divisionCardsState[divisionId];
                // Need to check state value again for typescript to allow operation
                // on property that only occurs in those states.
                if (divisionCardState.value === 'not-saved' ||
                    divisionCardState.value === 'saved-and-editing') {
                    divisionCardState.isShowingSubmissionErrorMessage = true;
                    if (index === 0) {
                        state.divisionToScrollToId = divisionId;
                    }
                }
            });
        }
        else if (noDivisionsSelected(state.divisionCardsState)) {
            state.submitButtonError = 'no-selected-divisions';
        }
    },
    clearIdToScrollTo: function (state) {
        state.divisionToScrollToId = undefined;
    },
};
/**
 * Given the states of the division cards, returns an array of the IDs of all
 * the divisions that are in one of the "editing" states: either 'not-saved' or
 * 'saved-and-editing'.
 */
function getIdsOfUnsavedDivisions(divisionCardsState) {
    var divisionIds = Object.keys(divisionCardsState);
    return divisionIds.filter(function (divisionId) {
        var divisionCardState = divisionCardsState[divisionId];
        return (divisionCardState.value === 'not-saved' ||
            divisionCardState.value === 'saved-and-editing');
    });
}
exports.getIdsOfUnsavedDivisions = getIdsOfUnsavedDivisions;
/**
 * Given the states of the division cards, returns true if all of the division
 * cards are in the 'not-selected' state.
 */
function noDivisionsSelected(divisionCardsState) {
    var divisionIds = Object.keys(divisionCardsState);
    return divisionIds.every(function (divisionId) { return divisionCardsState[divisionId].value === 'not-selected'; });
}
exports.noDivisionsSelected = noDivisionsSelected;
function hasSubmissionErrors(divisionCardsState) {
    return (getIdsOfUnsavedDivisions(divisionCardsState).length > 0 ||
        noDivisionsSelected(divisionCardsState));
}
exports.hasSubmissionErrors = hasSubmissionErrors;
