"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionEmail = exports.OptionLabel = exports.OptionContainer = exports.TypeaheadHelperText = exports.TypeaheadContainer = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.TypeaheadContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: -8px;\n"], ["\n  margin-top: -8px;\n"])));
exports.TypeaheadHelperText = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin-top: 4px;\n  gap: 2px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin-top: 4px;\n  gap: 2px;\n"])));
exports.OptionContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
exports.OptionLabel = (0, styled_components_1.default)(ds_ui_components_1.Typography)(function () {
    return {};
});
exports.OptionEmail = (0, styled_components_1.default)(ds_ui_components_1.Typography)(function (_a) {
    var theme = _a.theme;
    return {
        color: theme.foundational.blueGrey[600],
    };
});
var templateObject_1, templateObject_2, templateObject_3;
