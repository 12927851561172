import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ToastData } from 'components/Toast/Toast';

export type MessageType = 'snackbar' | 'toast';

export type Message = ToastData & {
  messageUUID: string;
  messageType?: MessageType;
};

export type MessagesUpdateAction =
  | {
      type: 'ADD_NEW_MESSAGE';
      payload: { newMessageData: Message };
    }
  | {
      type: 'REMOVE_MESSAGE';
      payload: { messageUUID: string };
    };

function insertMessage(messageArray: Message[], newMessage: Message) {
  const newMessageArray = messageArray.slice();
  newMessageArray.push(newMessage);
  return newMessageArray;
}

function removeMessage(messageArray: Message[], messageUUID: string) {
  const messageIndex = messageArray.findIndex(
    (msg) => msg.messageUUID === messageUUID
  );
  const newMessageArray = messageArray.slice();
  if (messageIndex > -1) {
    newMessageArray.splice(messageIndex, 1);
  }
  return newMessageArray;
}

function messagesReducer(
  messages: Message[],
  action: MessagesUpdateAction
): Message[] {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_NEW_MESSAGE':
      return insertMessage(messages, payload.newMessageData);
    case 'REMOVE_MESSAGE':
      return removeMessage(messages, payload.messageUUID);
    default:
      return messages;
  }
}

const defaultMessagingData: Message[] = [];

const defaultMessagingMethods = {
  addMessage: (
    messageType: MessageType,
    message: Omit<Message, 'messageUUID'>
  ) => {},
  deleteMessage: (messageUUID: string) => {},
};

export const MessagingContext = createContext({
  messages: defaultMessagingData,
  ...defaultMessagingMethods,
});

const MessagingProvider = ({ children }: PropsWithChildren) => {
  const [messages, dispatch] = useReducer(messagesReducer, []);

  const addMessage = useCallback(
    (
      messageType: MessageType,
      newMessageData: Omit<Message, 'messageUUID'>
    ) => {
      const newMessageDataWithTypeAndUUID = {
        ...newMessageData,
        messageType,
        messageUUID: uuidv4(),
      };

      dispatch({
        type: 'ADD_NEW_MESSAGE',
        payload: { newMessageData: newMessageDataWithTypeAndUUID },
      });
    },
    []
  );

  const deleteMessage = useCallback((messageUUID: string) => {
    dispatch({ type: 'REMOVE_MESSAGE', payload: { messageUUID } });
  }, []);

  const MessagingContextValues = useMemo(() => {
    return {
      addMessage,
      deleteMessage,
      messages,
    };
  }, [addMessage, deleteMessage, messages]);

  return (
    <MessagingContext.Provider value={MessagingContextValues}>
      {children}
    </MessagingContext.Provider>
  );
};

export { MessagingProvider };
