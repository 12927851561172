import styled from 'styled-components';
export const badgeVariantHeight = {
  small: '16px',
  medium: '20px',
  large: '24px'
};
const badgeNumberVariantSize = {
  small: '12px',
  medium: '16px',
  large: '20px'
};
const badgeTextTypographyVariant = {
  small: 'xs',
  medium: 'xs',
  large: 'small'
};
const badgePadding = {
  small: '0 4px',
  medium: '0 8px',
  large: '0 8px'
};
export const BadgeWrapper = styled.span.withConfig({
  componentId: "sc-1pid6pk-0"
})({
  display: 'flex'
});
export const BadgeContainer = styled.span.withConfig({
  componentId: "sc-1pid6pk-1"
})(({
  theme,
  $size
}) => {
  const {
    semantic,
    palette,
    spacing
  } = theme;
  const {
    blueGrey
  } = palette.foundational;
  return {
    display: 'flex',
    alignItems: 'center',
    height: badgeVariantHeight[$size],
    width: 'fit-content',
    borderRadius: '24px',
    padding: badgePadding[$size],
    gap: spacing.xxxs,
    backgroundColor: !!semantic ? semantic.primary[35] : blueGrey[50],
    wordBreak: 'break-word'
  };
});
export const BadgeText = styled.span.withConfig({
  componentId: "sc-1pid6pk-2"
})(({
  theme,
  $size
}) => {
  const {
    bold,
    regular
  } = theme.typography.weights;
  const typography = theme.typography.ui[badgeTextTypographyVariant[$size]];
  return {
    ...typography,
    display: 'block',
    fontWeight: $size === 'small' ? bold : regular
  };
});
export const BadgeNumber = styled.span.withConfig({
  componentId: "sc-1pid6pk-3"
})(({
  theme,
  $size,
  $isAlone,
  number
}) => {
  const isDynamic = !!theme.semantic;
  const {
    white,
    green
  } = theme.palette.foundational;
  const {
    bold
  } = theme.typography.weights;
  const size = $size === 'large' ? 'medium' : 'small';
  const typography = theme.typography.ui[badgeTextTypographyVariant[$size]];
  let isMultiDigit = false;

  // I'm sure there's a case for decimals but we will ignore it for now.
  if (number && number >= 10) {
    isMultiDigit = true;
  }
  let appliedStyles = {
    ...typography,
    fontWeight: bold,
    color: white,
    backgroundColor: isDynamic ? theme.semantic?.secondary[600] : green[600],
    padding: '0 3px',
    borderRadius: isMultiDigit ? '6px' : '50%',
    height: badgeNumberVariantSize[size],
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center'
  };
  if (!isMultiDigit) {
    appliedStyles.width = badgeNumberVariantSize[size];
  }
  return appliedStyles;
});