import styled from 'styled-components';
import { generateInputBaseDynamicStyles } from 'theming/tokens/components/input';
export const InputCharacterCounter = styled.span.withConfig({
  componentId: "sc-jqgtns-0"
})(({
  theme,
  $state
}) => {
  const styles = generateInputBaseDynamicStyles(theme, $state);
  return {
    color: styles.borderColor
  };
});