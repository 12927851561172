export type VariantWithLightness = {
  lightness: number;
  variant: string;
};

export type VariantWithAdjustment = {
  adjustment: number;
  variant: string;
};

export const variantAdjustmentPairs_State: VariantWithAdjustment[] = [
  {
    variant: 'hover',
    adjustment: 10,
  },
  {
    variant: 'active',
    adjustment: 15,
  },
];

export const variantLightnessPairs_Dark: VariantWithLightness[] = [
  {
    variant: '35',
    lightness: 96,
  },
  {
    variant: '100',
    lightness: 86,
  },
  {
    variant: '200',
    lightness: 73,
  },
  {
    variant: '400',
    lightness: 54,
  },
  {
    variant: '600',
    lightness: 40,
  },
];

export const variantLightnessPairs_Light: VariantWithLightness[] = [
  {
    variant: '35',
    lightness: 92,
  },
  {
    variant: '100',
    lightness: 76,
  },
  {
    variant: '200',
    lightness: 60,
  },
  {
    variant: '400',
    lightness: 40,
  },
  {
    variant: '600',
    lightness: 26,
  },
];
