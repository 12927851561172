import styled from 'styled-components/macro';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { media } from 'lib/media-queries/mixins';

export const WIZARD_CONTENT_PADDING = 24;

export const WizardContent = styled(ColumnGrid)`
  flex: 1;
  padding: 12px;

  ${media.TabletPortraitUp`
    padding: 16px;
  `}

  ${media.TabletLandscapeUp`
    padding-top: ${WIZARD_CONTENT_PADDING}px;
    padding-bottom: ${WIZARD_CONTENT_PADDING}px;
    margin-bottom: 30px;
  `}
`;

export const WizardAddDivisionsAndTeamsSection = styled.div`
  grid-column: 1 / -1;

  ${media.TabletLandscapeUp`
    grid-column-end: 12;
  `}
`;

export const SectionTitle = styled.h3`
  margin: 0;
  margin-bottom: 12px;
  color: var(--blue-grey-900);
  font: var(--headline-small);

  ${media.TabletPortraitUp`
    margin-bottom: 20px;
  `}
`;
