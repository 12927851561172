import { useEffect, useRef, useState } from 'react';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { NavItemData } from '../NavBar/NavBar';
import * as S from './NavSectionMenu.styles';

export type MenuPosition = {
  top: number;
  right: number;
};

export type NavSectionMenuProps = {
  links?: NavItemData[];
  menuId: string;
};

export type NavItem = Omit<NavSectionMenuItemData, 'links' | 'target'>;

export type NavSectionMenuItemData = Omit<NavItemData, 'links'>;

export const SCROLL_BAR_WIDTH = 15;

/* NavSectionMenu */
export default function NavSectionMenu({ menuId, links }: NavSectionMenuProps) {
  const [leftOffset, setLeftOffset] = useState(0);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { visibleNavMenu } = useMainNavigation();
  const navMenuIsMounted = visibleNavMenu === menuId;

  useEffect(() => {
    if (menuRef.current && navMenuIsMounted && leftOffset === 0) {
      const { left } = menuRef.current.getBoundingClientRect();
      // If the drop down menu is out of the viewPort, update its position shifting it to the left side
      if (left + S.NAV_SECTION_MENU_WIDTH > window.innerWidth) {
        setLeftOffset(
          window.innerWidth - left - S.NAV_SECTION_MENU_WIDTH - SCROLL_BAR_WIDTH
        );
      }
    }
  }, [navMenuIsMounted, leftOffset]);

  return links ? (
    <S.NavSectionMenu
      id={menuId}
      ref={menuRef}
      $navMenuIsMounted={navMenuIsMounted}
      $leftOffset={leftOffset}
    >
      <NavSectionMenuItems links={links} />
    </S.NavSectionMenu>
  ) : null;
}
/* */

/* Nav Section Menu Items */
function NavSectionMenuItems({ links }: { links: NavSectionMenuItemData[] }) {
  return links ? (
    <>
      {links.map(
        ({ href, label, target }: NavSectionMenuItemData, idx: number) => (
          <NavSectionMenuItem
            href={href}
            label={label}
            key={`${label}-${idx}`}
            target={target}
          />
        )
      )}
    </>
  ) : null;
}

/* Nav Section Menu Item */
function NavSectionMenuItem({
  href,
  label,
  target,
}: Omit<NavSectionMenuItemData, 'links'>) {
  return (
    <S.NavSectionMenuItem to={href} tabIndex={0} target={target}>
      {label}
    </S.NavSectionMenuItem>
  );
}
