"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreadcrumbSeparator = exports.BreadcrumbLink = exports.BreadcrumbLabel = exports.Breadcrumb = exports.Breadcrumbs = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.Breadcrumbs = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n"])));
exports.Breadcrumb = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.ui.small), { display: 'flex', color: theme.foundational.white, margin: '0 0 4px', whiteSpace: 'nowrap', a: {
            color: theme.foundational.white,
            '&:hover': {
                color: theme.foundational.white,
            },
        } }));
});
exports.BreadcrumbLabel = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  white-space: wrap;\n"], ["\n  white-space: wrap;\n"])));
exports.BreadcrumbLink = (0, styled_components_1.default)(ds_ui_components_1.Link).attrs({
    size: 'small',
    variant: 'text',
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
exports.BreadcrumbSeparator = (0, styled_components_1.default)(exports.Breadcrumb)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin 0 4px;\n"], ["\n  margin 0 4px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
