import { Link, Typography } from '@la/ds-ui-components';
import { useMediaQuery } from '@la/shared-components/src/lib/hooks';
import { breakpointQueries } from '@la/shared-components/src/lib/media-queries/breakpoints';
import Page from 'domains/Shell/Page/Page';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { FamilyYouthAthleteAccess } from '../YouthAthleteAccess.types';
import YouthAthleteConfirmationSectionList from '../YouthAthleteConfirmationSectionList/YouthAthleteConfirmationSectionList';
import YouthAthleteRevocationSectionList from '../YouthAthleteRevocationSectionList/YouthAthleteRevocationSectionList';
import * as S from '../EditMobileAppAccess.styles';

interface EditMobileAppAccessConfimationProps {
  familyYouthAthleteAccess: FamilyYouthAthleteAccess;
}
export default function EditMobileAppAccessConfirmation({
  familyYouthAthleteAccess,
}: EditMobileAppAccessConfimationProps) {
  const isTabletOrLarger = useMediaQuery(breakpointQueries.tabletPortraitUp);
  const { youthAthletes } = familyYouthAthleteAccess;
  const enabledYouthAthletes = youthAthletes.filter(
    (youthAthlete) => youthAthlete.hasYouthAthletePermission
  );
  const disabledYouthAthletes = youthAthletes.filter(
    (youthAthlete) => !youthAthlete.hasYouthAthletePermission
  );
  const disabledYouthAthleteNames = disabledYouthAthletes
    .map(({ firstName }) => firstName)
    .reduce(joinNames(disabledYouthAthletes.length), '');

  return (
    <Page>
      <PageTitle>Confirmation of Child Mobile App Access Settings</PageTitle>
      <S.MobileAppAccessContainer>
        <S.MobileAppAccessHeadingContainer>
          {enabledYouthAthletes.length > 0
            ? `A sign-up link has been shared with athletes who now have mobile access. `
            : ''}
          {disabledYouthAthletes.length > 0
            ? `Mobile app access is revoked for ${disabledYouthAthleteNames}`
            : ''}
        </S.MobileAppAccessHeadingContainer>
        <YouthAthleteConfirmationSectionList
          enabledYouthAthletes={enabledYouthAthletes}
        />
        <YouthAthleteRevocationSectionList
          disabledYouthAthletes={disabledYouthAthletes}
        />
        <S.MobileAppAccessNoticeContainer>
          <Typography variant="ui" size="large">
            See the status of your child's mobile app access on your family
            dashboard
          </Typography>
        </S.MobileAppAccessNoticeContainer>
        <S.MobileAppAccessConfirmationLinkContainer>
          <Link
            variant="outline"
            hasPadding
            size="large"
            width={isTabletOrLarger ? '' : 'full'}
            href="/groupAccountDashboard"
          >
            Go to family dashboard
          </Link>
        </S.MobileAppAccessConfirmationLinkContainer>
      </S.MobileAppAccessContainer>
    </Page>
  );
}
// Takes the length of the original array and returns a reducer function
// that joins the names together in a human readable way, e.g. for ['a', 'b', 'c'] -> 'a, b and c'
function joinNames(
  numNames: number
): (existingNames: string, newName: string, idx: number) => string {
  return (existingNames: string, newName: string, idx: number) => {
    // First element, just return the name
    if (idx === 0) {
      return newName;
    }
    // Last element return current list and name
    if (idx === numNames - 1) {
      return `${existingNames} and ${newName}`;
    }
    // Any other return current list , name
    return `${existingNames}, ${newName}`;
  };
}
