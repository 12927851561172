import { sortRecordByValue } from 'lib/utils/utilities';
import baseApi from './baseApi';

export type Country = {
  code: string;
  name: string;
  admin1?: Record<string, string>;
};

export type CountriesAndAdministrativeDivisions = {
  countries: Record<string, string>;
  provincesCAN: Record<string, string>;
  statesUSA: Record<string, string>;
};

export const countryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountriesAndAdministrativeDivisions: builder.query<
      CountriesAndAdministrativeDivisions,
      void
    >({
      query: () => ({
        url: `/api/countries`,
        method: 'GET',
      }),
      keepUnusedDataFor: Infinity,
      transformResponse: (countries: Country[]) => {
        const provincesCAN = getSortedSubLocations('CAN', countries);
        const statesUSA = getSortedSubLocations('USA', countries);
        const filteredCountries = sortRecordByValue(
          countries.reduce(
            (countries: Record<string, string>, { code, name }: Country) => {
              if (
                // The data source the countries API uses can return the same country
                // more than once.
                !countries[code] &&
                // Exclude any US territories listed also as a country.
                !Object.values(statesUSA).find(
                  (stateName) => stateName === name
                )
              ) {
                countries[code] = name;
                return countries;
              }
              return countries;
            },
            {}
          )
        );

        return { countries: filteredCountries, provincesCAN, statesUSA };
      },
    }),
  }),
});

/**
 * Gets the sublocations (e.g. states, provinces, etc.) given a country code.
 * @param countryCode Country code to search for (e.g. USA, CAN)
 * @param countries Countries that may or may not have sublocations associated with it.
 * @returns Key-value pairs of sublocation codes and names.
 */
function getSortedSubLocations(
  countryCode: string,
  countries: Country[]
): Record<string, string> {
  const subLocations =
    countries.find(({ code }) => code === countryCode)?.admin1 || {};

  return sortRecordByValue(subLocations);
}

export const { useGetCountriesAndAdministrativeDivisionsQuery } = countryApi;
