import styled from 'styled-components/macro';

export const FormSectionLabel = styled.p`
  font: var(--ui-medium-bold);
  color: var(--blue-grey-900);
`;
export const FormFields = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
`;

export const TwoFieldRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  > * {
    flex: 1;
  }
`;

export const Note = styled.p`
  font: var(--ui-medium-medium);
  font-style: italic;
  margin-top: 6px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 6px;
`;
