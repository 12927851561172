"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetCountriesAndAdministrativeDivisionsQuery = exports.countryApi = void 0;
var utilities_1 = require("@la/utilities");
var baseApi_1 = require("./baseApi");
exports.countryApi = baseApi_1.default.injectEndpoints({
    endpoints: function (builder) { return ({
        getCountriesAndAdministrativeDivisions: builder.query({
            query: function () { return ({
                url: "/api/countries",
                method: 'GET',
            }); },
            keepUnusedDataFor: Infinity,
            transformResponse: function (countries) {
                var provincesCAN = getSortedSubLocations('CAN', countries);
                var statesUSA = getSortedSubLocations('USA', countries);
                var filteredCountries = (0, utilities_1.sortRecordByValue)(countries.reduce(function (countries, _a) {
                    var code = _a.code, name = _a.name;
                    if (
                    // The data source the countries API uses can return the same country
                    // more than once.
                    !countries[code] &&
                        // Exclude any US territories listed also as a country.
                        !Object.values(statesUSA).find(function (stateName) { return stateName === name; })) {
                        countries[code] = name;
                        return countries;
                    }
                    return countries;
                }, {}));
                return { countries: filteredCountries, provincesCAN: provincesCAN, statesUSA: statesUSA };
            },
        }),
    }); },
});
/**
 * Gets the sublocations (e.g. states, provinces, etc.) given a country code.
 * @param countryCode Country code to search for (e.g. USA, CAN)
 * @param countries Countries that may or may not have sublocations associated with it.
 * @returns Key-value pairs of sublocation codes and names.
 */
function getSortedSubLocations(countryCode, countries) {
    var _a;
    var subLocations = ((_a = countries.find(function (_a) {
        var code = _a.code;
        return code === countryCode;
    })) === null || _a === void 0 ? void 0 : _a.admin1) || {};
    return (0, utilities_1.sortRecordByValue)(subLocations);
}
exports.useGetCountriesAndAdministrativeDivisionsQuery = exports.countryApi.useGetCountriesAndAdministrativeDivisionsQuery;
