import styled from 'styled-components/macro';

export const SelectedTeam = styled.li({
  margin: '0 0 12px',
  padding: 0,
});

export const TeamList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  margin: '0 0 8px',
  padding: 0,

  li: {
    margin: 0,
    padding: 0,
  },

  [SelectedTeam]: {
    marginBottom: '4px',
  },
});
