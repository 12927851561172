"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpointQueries = exports.BREAKPOINTS = void 0;
exports.BREAKPOINTS = {
    BIG_DESKTOP: { min: 1500, max: 10000 },
    DESKTOP: { min: 1200, max: 1499 },
    TABLET_LANDSCAPE: { min: 900, max: 1199 },
    TABLET_PORTRAIT: { min: 600, max: 899 },
    PHONE: { min: 0, max: 599 },
};
// Convert to pixels
function toPx(value) {
    return "".concat(value, "px");
}
var bigDesktopPx = {
    min: toPx(exports.BREAKPOINTS.BIG_DESKTOP.min),
};
var desktopPx = {
    min: toPx(exports.BREAKPOINTS.DESKTOP.min),
    max: toPx(exports.BREAKPOINTS.DESKTOP.max),
};
var tabletLandscapePx = {
    min: toPx(exports.BREAKPOINTS.TABLET_LANDSCAPE.min),
    max: toPx(exports.BREAKPOINTS.TABLET_LANDSCAPE.max),
};
var tabletPortraitPx = {
    min: toPx(exports.BREAKPOINTS.TABLET_PORTRAIT.min),
    max: toPx(exports.BREAKPOINTS.TABLET_PORTRAIT.max),
};
var phonePx = {
    max: toPx(exports.BREAKPOINTS.PHONE.max),
};
exports.breakpointQueries = {
    phoneOnly: "(max-width: ".concat(phonePx.max, ")"),
    tabletPortraitUp: "(min-width: ".concat(tabletPortraitPx.min, ")"),
    tabletPortraitDown: "(max-width: ".concat(tabletPortraitPx.max, ")"),
    tabletPortraitOnly: "(min-width: ".concat(tabletPortraitPx.min, ") and (max-width: ").concat(tabletPortraitPx.max, ")"),
    tabletLandscapeUp: "(min-width: ".concat(tabletLandscapePx.min, ")"),
    tabletLandscapeDown: "(max-width: ".concat(tabletLandscapePx.max, ")"),
    tabletLandscapeOnly: "(min-width: ".concat(tabletLandscapePx.min, ") and (max-width: ").concat(tabletLandscapePx.max, ")"),
    desktopUp: "(min-width: ".concat(desktopPx.min, ")"),
    desktopDown: "(max-width: ".concat(desktopPx.max, ")"),
    desktopOnly: "(min-width: ".concat(desktopPx.min, ") and (max-width: ").concat(desktopPx.max, ")"),
    bigDesktopOnly: "(min-width: ".concat(bigDesktopPx.min, ")"),
};
