import { FormEvent, useEffect, useReducer } from 'react';
import {
  Checkbox,
  FileInput,
  InfoIcon,
  InputLabel,
  Select,
  SelectOption,
  TextArea,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import {
  useUpdateRegistrantProfileMutation,
  useUploadFileMutation,
} from 'redux/services/rosterManagementApi';
import * as S from './RegistrantProfileForm.styles';

export type RegistrantProfile = {
  applyToUserType: string;
  baseEventGroupAccountMode: 'ADULT' | 'YOUTH';
  bid: string;
  birthdate: string;
  email: string;
  fieldErrors: FieldErrors;
  fields: Field[];
  firstName: string;
  gender: 'Female' | 'Male' | null;
  hasCustomMemberProfileProperties: boolean;
  hasCustomRegProperties: boolean;
  hasEmail: boolean;
  isMemberBirthdateRequired: boolean;
  lastName: string;
  operation: string;
  orgAccountId: string;
  orphan: boolean;
  playerId: string;
  propertyDefinitionId: string;
  showStaffManagedPropertiesOnly: boolean;
  siteId: string;
  teamId: string;
  userType: 'Adult' | 'Child';
};

export type Field = {
  hasError: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  type:
    | 'FILE_UPLOAD'
    | 'MULTIPLE_CHECKBOXES'
    | 'NUMERIC'
    | 'PICK_LIST'
    | 'TEXT_AREA'
    | 'TEXT_BOX';
  value: string;
  options?: Option[];
  propertyPrefix?: string;
  propertyDefinitionId?: string;
  propertyDefinitionSiteId?: string;
  propertyId?: string;
  publicSiteSecretUrl?: string;
  siteId?: string;
};

export type Option = {
  label: string;
  name?: string;
  selected: boolean;
  value: string;
};

export type FieldErrors = {};

export type FieldAction = {
  fieldName: string;
  fieldValue?: string;
};

export type FieldInfo = {
  name: string;
  value?: string;
};

function fieldStatesReducer(
  fields: FieldInfo[],
  action: FieldAction
): FieldInfo[] {
  const type = fields.find((field) => field.name === action.fieldName)
    ? 'edit'
    : 'add';

  switch (type) {
    case 'add': {
      return [
        ...fields,
        {
          name: action.fieldName,
          value: action.fieldValue,
        },
      ];
    }
    case 'edit': {
      return fields.map((field) => {
        if (field.name === action.fieldName) {
          return { name: field.name, value: action.fieldValue };
        } else {
          return field;
        }
      });
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
}

export default function RegistrantProfileForm({
  closeModal,
  registrantData,
}: {
  closeModal: () => void;
  registrantData: RegistrantProfile;
}) {
  const [state, dispatch] = useReducer(fieldStatesReducer, []);

  const [updateProfile] = useUpdateRegistrantProfileMutation();

  function getAdditionalFields() {
    const additionalFields: Record<string, any> = {
      baseEventGroupAccountMode: registrantData.baseEventGroupAccountMode,
      bid: registrantData.bid,
      hasCustomMemberProfileProperties:
        registrantData.hasCustomMemberProfileProperties,
      hasCustomRegProperties: registrantData.hasCustomRegProperties,
      ignoreBirthDate: true,
      operation: registrantData.operation,
      orgAccountId: registrantData.orgAccountId,
      orphan: registrantData.orphan,
      playerId: registrantData.playerId,
      siteId: registrantData.siteId,
      teamId: registrantData.teamId,
      userType: registrantData.userType,
    };

    if (registrantData.isMemberBirthdateRequired) {
      additionalFields['birthdate'] = registrantData.birthdate;
    }

    return additionalFields;
  }

  function onEditProfileFormSubmit(evt: FormEvent) {
    evt.preventDefault();
    const additionalFields = getAdditionalFields();
    const partnerAddedFields = state.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }),
      {}
    );
    const body = {
      ...additionalFields,
      ...partnerAddedFields,
    };

    updateProfile({ body })
      .unwrap()
      .then((payload) => {
        // the monolith responds with malformed HTML so this should not be seen
        console.log('fulfilled', payload);
      })
      .catch((error) => {
        // if the HTML string contains "error" then handle the error
        if (error.data.search(/error/) > -1) {
          console.error('Edit profile form submission error: ', error);
        } else if (error.data.search(/success/)) {
          closeModal();
        }
      });
  }

  return registrantData.fields.length > 0 ? (
    <S.EditProfileForm
      id="update-registrant-profile-form"
      aria-label="Update Profile Form"
      noValidate={true}
      onSubmit={onEditProfileFormSubmit}
    >
      {PartnerAddedFields(
        dispatch,
        registrantData.fields,
        registrantData.playerId,
        state
      )}
    </S.EditProfileForm>
  ) : null;
}

export function PartnerAddedFields(
  dispatch: React.Dispatch<FieldAction>,
  fieldData: Field[],
  playerId: string,
  state: FieldInfo[]
): JSX.Element | JSX.Element[] {
  useEffect(() => {
    fieldData.forEach((field) => {
      dispatch({
        fieldName: field.name,
        fieldValue: field.value,
      });
    });
  }, [dispatch, fieldData]);

  function getFieldValue(fieldName: string): string | undefined {
    return state.find((field) => field.name === fieldName)?.value;
  }

  function valueChangeHandler(fieldName: string, newValue: string) {
    dispatch({
      fieldName: fieldName,
      fieldValue: newValue,
    });
  }

  return fieldData.map((fieldDataSet, idx) => {
    const { isRequired, label, name, options, type, value } = fieldDataSet;

    switch (type) {
      case 'FILE_UPLOAD':
        return (
          <FileUploadFormComponent
            fileUploadFieldData={fieldDataSet}
            key={`field-${idx}`}
            onChangeValue={(newValue) => {
              valueChangeHandler(name, newValue);
            }}
            userId={playerId}
          />
        );
      case 'MULTIPLE_CHECKBOXES':
        return (
          <CheckboxGroupFormComponent
            checkboxGroupData={fieldDataSet}
            key={`field-${idx}`}
            onChangeValue={(newValue) => {
              valueChangeHandler(name, newValue);
            }}
            value={getFieldValue(name)}
          />
        );
      case 'NUMERIC':
        return (
          <S.NumericInput key={`field-${name}`}>
            <TextInput
              id={name}
              label={label}
              name={name}
              onChange={(evt) => {
                valueChangeHandler(name, evt.target.value);
              }}
              required={isRequired}
              type="number"
              value={getFieldValue(name)}
            />
            <S.NumericInputHelperText>
              <InfoIcon
                fill={'var(--blue-grey-600)'}
                size="medium"
                variant="bold"
              />
              <Typography variant="ui" size="small">
                Enter numbers only
              </Typography>
            </S.NumericInputHelperText>
          </S.NumericInput>
        );
      case 'PICK_LIST':
        return (
          <Select
            value={getFieldValue(name)}
            key={`field-${idx}`}
            id={name}
            label={label}
            onChange={(newValue) => {
              valueChangeHandler(name, newValue);
            }}
            options={options as SelectOption[]}
            placeholder={`Please choose one`}
            required={isRequired}
          />
        );
      case 'TEXT_AREA':
        return (
          <TextArea
            value={value}
            id={name}
            key={`field-${name}`}
            label={label}
            name={name}
            onChange={(evt) => {
              valueChangeHandler(name, evt.target.value);
            }}
            required={isRequired}
          />
        );
      case 'TEXT_BOX':
        return (
          <TextInput
            value={getFieldValue(name)}
            id={name}
            key={`field-${name}`}
            label={label}
            name={name}
            onChange={(evt) => {
              valueChangeHandler(name, evt.target.value);
            }}
            required={isRequired}
          />
        );
      default:
        return <span key={idx} />;
    }
  });
}

export function FileUploadFormComponent({
  fileUploadFieldData,
  onChangeValue,
  userId,
}: {
  fileUploadFieldData: Field;
  onChangeValue: (value: string) => void;
  userId: string;
}) {
  const [uploadFile] = useUploadFileMutation();
  const { propertyDefinitionId } = fileUploadFieldData;

  const metadata = {
    userId: parseInt(userId, 10),
    propertyDefinitionId: propertyDefinitionId,
  };

  function handleFileUploads(
    file: string | ArrayBuffer | null,
    filename: string
  ) {
    if (file) {
      const formData = new FormData();
      const blob = new Blob([file]);

      formData.append('file', blob, filename);
      formData.append(
        'metaData',
        new Blob([JSON.stringify(metadata)], {
          type: 'application/json',
        })
      );
      uploadFile({ formData: formData, siteId: '1' })
        .unwrap()
        .then(({ uuid }) => {
          onChangeValue(uuid);
        })
        .catch((error) => {
          // handle error here
        });
    }
  }

  return (
    <div>
      <Typography variant="ui" size="medium" weight="bold">
        {fileUploadFieldData.label}
      </Typography>
      <FileInput
        accept={['.png', '.jpg', '.jpeg']}
        id={fileUploadFieldData.name}
        infoMessage="Max upload size is 500kb. Supported file types are .jpg and .png."
        multiple={false}
        onUpload={(evt) => {
          handleFileUploads(evt.file, evt.name);
        }}
      />
    </div>
  );
}

export function CheckboxGroupFormComponent({
  checkboxGroupData,
  onChangeValue,
  value,
}: {
  checkboxGroupData: Field;
  onChangeValue: (newValue: string) => void;
  value?: string;
}) {
  const { hasError, name: groupName, isRequired } = checkboxGroupData;
  const selectedValueArray = !!value ? value.split(',') : [];
  const checkboxGroupOptions = setOptionsAsSelected(
    checkboxGroupData?.options,
    selectedValueArray
  );

  function handleCheckboxChange(selected: boolean, value: string) {
    if (selected) {
      selectedValueArray.push(value);
    } else {
      const selectedValueIdx = selectedValueArray.indexOf(value);
      selectedValueArray.splice(selectedValueIdx, 1);
    }
    const updatedValue = selectedValueArray.join();
    onChangeValue(updatedValue);
  }

  const checkboxes = checkboxGroupOptions.map(
    ({ label, name, selected, value }, idx) => {
      return (
        <Checkbox
          ariaLabel={label}
          checked={selected}
          id={`${name}-${value}`}
          key={`${name}-${value}`}
          label={label}
          onCheckedChange={(isSelected) => {
            handleCheckboxChange(isSelected, value);
          }}
          size="large"
        />
      );
    }
  );

  return (
    <>
      <InputLabel
        htmlFor={groupName}
        inputState={hasError ? 'error' : 'default'}
        required={isRequired}
      >
        {checkboxGroupData.label}
      </InputLabel>
      <S.CheckboxGroup>{checkboxes}</S.CheckboxGroup>
    </>
  );
}

export function setOptionsAsSelected(
  options: Option[] = [],
  selectedValueArray: string[]
): Option[] {
  return options.map((option: Option) => {
    return selectedValueArray.includes(option.value)
      ? { ...option, selected: true }
      : { ...option, selected: false };
  });
}
