"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardInfoRightSection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("./DivisionCardInfoSectionRight.styles");
function DivisionCardInfoRightSection(_a) {
    var cardState = _a.cardState, onEditDivision = _a.onEditDivision, onSelectDivision = _a.onSelectDivision;
    if (cardState.value === 'unavailable') {
        return null;
    }
    if (cardState.value === 'not-selected') {
        return ((0, jsx_runtime_1.jsx)(S.DivisionCardNotSelectedSectionRight, { children: (0, jsx_runtime_1.jsx)(S.DivisionCardAddDivisionButton, { ariaLabel: "Add division", icon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.PlusCircleIcon, { variant: "filled", size: "large" }), onClick: onSelectDivision, size: "xl", variant: "text" }) }));
    }
    else if (cardState.value === 'not-saved' ||
        cardState.value === 'saved-and-editing') {
        var numberOfTeamsAdded = cardState.teamSelections.filter(function (teamSelection) { return teamSelection.teamId !== ''; }).length;
        var numberOfTeamsAddedText = void 0;
        if (numberOfTeamsAdded === 1) {
            numberOfTeamsAddedText = '1 team added';
        }
        else if (numberOfTeamsAdded > 1) {
            numberOfTeamsAddedText = "".concat(numberOfTeamsAdded, " teams added");
        }
        return ((0, jsx_runtime_1.jsx)(S.DivisionCardInfoSectionRight, { children: (0, jsx_runtime_1.jsx)(S.DivisionCardTeamsText, { children: numberOfTeamsAddedText }) }));
    }
    else {
        var numberOfTeamsSaved = cardState.savedTeams.length;
        var teamsSavedString = '';
        if (numberOfTeamsSaved > 1) {
            teamsSavedString = "".concat(numberOfTeamsSaved, " teams");
        }
        if (numberOfTeamsSaved === 1) {
            teamsSavedString = '1 team';
        }
        return ((0, jsx_runtime_1.jsxs)(S.DivisionCardSavedInfoSectionRight, { children: [(0, jsx_runtime_1.jsx)(S.DivisionCardTeamsText, { children: teamsSavedString }), (0, jsx_runtime_1.jsx)("span", __assign({ style: { cursor: 'pointer' }, onClick: onEditDivision }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.PencilSquareIcon, { fill: "var(--green-600)", variant: "bold", size: "xl" }) }))] }));
    }
}
exports.DivisionCardInfoRightSection = DivisionCardInfoRightSection;
