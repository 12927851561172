export const generateInputBaseDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: {
      blueGrey,
      green,
      red,
      white
    }
  } = theme;
  const borderColor = {
    default: blueGrey[400],
    hover: blueGrey[600],
    focus: isDynamic ? blueGrey[900] : green[600],
    error: red[500],
    readonly: blueGrey[50],
    disabled: blueGrey[200]
  };
  return {
    borderColor: borderColor[state],
    color: state === 'disabled' ? blueGrey[200] : blueGrey[900],
    backgroundColor: state === 'readonly' ? blueGrey[50] : white
  };
};
export const generateInputPlaceholderDynamicStyles = (theme, state) => {
  const {
    foundational: {
      blueGrey
    }
  } = theme;
  return {
    color: ['disabled', 'readonly'].includes(state) ? blueGrey[250] : blueGrey[400]
  };
};
export const generateInputLabelDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: {
      blueGrey,
      green,
      red
    }
  } = theme;
  const color = {
    default: blueGrey[600],
    disabled: blueGrey[200],
    readonly: blueGrey[600],
    error: red[600],
    focus: isDynamic ? blueGrey[600] : green[600],
    hover: blueGrey[600]
  };
  return {
    color: color[state]
  };
};
export const generateInputAdornmentDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: {
      blueGrey,
      green,
      red,
      white
    }
  } = theme;
  const defaultFill = isDynamic ? blueGrey[900] : green[600];
  const fill = {
    default: defaultFill,
    hover: defaultFill,
    focus: defaultFill,
    error: red[500],
    readonly: blueGrey[400],
    disabled: blueGrey[200]
  };
  return {
    fill: fill[state],
    backgroundColor: state === 'readonly' ? blueGrey[50] : white
  };
};