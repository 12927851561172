import { useNavigate } from 'react-router-dom';
import { BedIcon, Button, Link, Typography } from '@la/ds-ui-components';
import { useMediaQuery } from '@la/shared-components/src/lib/hooks';
import { breakpointQueries } from '@la/shared-components/src/lib/media-queries/breakpoints';
import { getHotelLinks } from 'redux/checkoutSlice';
import { useAppSelector } from 'redux/store';
import { CheckoutCardHeader } from '../CheckoutCard/CheckoutCard';
import * as S from './TransactionConfirmationCard.styles';

export const ACCOMMODATIONS_BUTTON_LABEL = 'Manage accommodations';

export type TransactionConfirmationCardData = {
  cardBody: string[];
  cardTitle: string;
};

/* TransactionConfirmationCard */
export default function TransactionConfirmationCard({
  cardBody,
  cardTitle,
}: TransactionConfirmationCardData) {
  const hotelLinks = useAppSelector(getHotelLinks);
  const hotelLink =
    hotelLinks.length === 1 ? hotelLinks[0].hotelLink : undefined;

  const navigate = useNavigate();

  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  function navigateToDashboard() {
    navigate('/dashboard');
  }

  const bodyText = cardBody.map((paragraph, idx) => (
    <Typography key={`trans-conf-${idx}`} size="large" variant="ui">
      {paragraph}
    </Typography>
  ));

  return (
    <S.TransactionConfirmationCard>
      <CheckoutCardHeader name={cardTitle} />
      <S.TransactionConfirmationCardContents>
        {bodyText}
        <S.TransactionConfirmationCardActions>
          <Button
            size="medium"
            onClick={navigateToDashboard}
            width={isTabletPortaitUp ? 'auto' : '100%'}
          >
            Visit dashboard
          </Button>
          {hotelLink ? (
            <S.HotelLink>
              <Link
                hasPadding
                href={hotelLink}
                isBold
                target="_blank"
                variant="outline"
                width={isTabletPortaitUp ? 'auto' : '100%'}
              >
                <BedIcon fill="var(--secondary-600)" variant="bold" />
                {ACCOMMODATIONS_BUTTON_LABEL}
              </Link>
            </S.HotelLink>
          ) : null}
        </S.TransactionConfirmationCardActions>
      </S.TransactionConfirmationCardContents>
    </S.TransactionConfirmationCard>
  );
}
/* */
