import { setThemeColorValues } from './colorHandling';
import { gridSpacing, mediaQueries } from './grid';
import { palette } from './palette';
import { foundationalTokens } from './tokens/foundational';
import { typography } from './typography';
const extractShadeVariants = shadeVariants => {
  const shades = {};
  shadeVariants.forEach(variant => {
    // Each variant is an object with a single key; e.g. { --primary-300: '#f00'};
    // `key` is the name of that single key; e.g. '--primary-300'
    const key = Object.keys(variant)[0];
    // The fourth item of the above split is the string representation of the shade; e.g. ['', '', 'primary', '300'];
    const shade = +key.split('-')[3];
    // The indexed shade maps to the original variant key; e.g shades: { 300: '#f00' };
    shades[shade] = variant[key];
  });
  return shades;
};
const extractStateVariants = stateVariants => {
  const states = {};
  stateVariants.forEach(variant => {
    // Each state is an object with a single key; e.g. { '--primary-hover': '#f00'};
    // `key` is the name of that single key; e.g. '--primary-hover';
    const key = Object.keys(variant)[0];
    // The fourth item of the above split is the string representation of the state; e.g. ['', '', 'primary', 'hover']
    const strippedKey = key.split('-')[3];
    // The sanitized key is mapped to the original state. e.g. states: { hover: '#f00' };
    states[strippedKey] = variant[key];
  });
  return states;
};
const formatThemeSemanticPalette = palette => {
  // Here we are reusing `colorHandling` logic that depends on css vars
  // The implementation will change in future releases
  const isWhite = Object.values(palette.reactiveTextColor)[0] === 'var(--white)';
  const reactiveTextColor = isWhite ? foundationalTokens.white : foundationalTokens.blueGrey[900];
  const dynamicPalette = {
    ...extractShadeVariants(palette.shadeVariants),
    ...extractStateVariants(palette.stateVariants),
    base: palette.base,
    reactiveTextColor
  };
  return dynamicPalette;
};
export const generatePalette = (baseColor, prefix) => {
  const colors = setThemeColorValues(`--${prefix}`, baseColor);
  return formatThemeSemanticPalette(colors);
};
const defaultTheme = {
  typography,
  palette,
  foundational: foundationalTokens,
  spacing: gridSpacing,
  mediaQueries: mediaQueries
};
export const generateTheme = baseColors => {
  if (!baseColors) {
    return defaultTheme;
  }
  const semantic = {
    primary: generatePalette(baseColors.primary, 'primary'),
    secondary: generatePalette(baseColors.secondary, 'secondary')
  };
  return {
    ...defaultTheme,
    semantic
  };
};