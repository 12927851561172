"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSiteIdentityData = exports.getSiteName = exports.getSiteId = exports.getLogoImageSrc = exports.getLogoHref = exports.setSiteInfo = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    logoHref: '',
    logoImageSrc: '',
    siteId: null,
    siteName: '',
};
var coreSlice = (0, toolkit_1.createSlice)({
    name: 'core',
    initialState: initialState,
    reducers: {
        setSiteInfo: function (state, action) {
            state.logoHref = action.payload.logoHref;
            state.logoImageSrc = action.payload.logoImageSrc;
            state.siteId = action.payload.siteId;
            state.siteName = action.payload.siteName;
        },
    },
});
exports.setSiteInfo = coreSlice.actions.setSiteInfo;
var getLogoHref = function (state) { return state.core.logoHref; };
exports.getLogoHref = getLogoHref;
var getLogoImageSrc = function (state) { return state.core.logoImageSrc; };
exports.getLogoImageSrc = getLogoImageSrc;
var getSiteId = function (state) { return state.core.siteId; };
exports.getSiteId = getSiteId;
var getSiteName = function (state) { return state.core.siteName; };
exports.getSiteName = getSiteName;
exports.getSiteIdentityData = (0, toolkit_1.createSelector)([exports.getLogoHref, exports.getLogoImageSrc, exports.getSiteId, exports.getSiteName], function (logoHref, logoImageSrc, siteId, siteName) {
    return {
        logoHref: logoHref,
        logoImageSrc: logoImageSrc,
        siteId: siteId,
        siteName: siteName,
    };
});
exports.default = coreSlice.reducer;
