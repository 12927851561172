import styled from 'styled-components/macro';
import { IconButton, ThemedComponent, Typography } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

const themedIconButton = `
  & svg {
    fill: ${({ theme }: ThemedComponent) => theme.semantic?.secondary[500]};
  }

  &:hover {
    & svg {
      fill: ${({ theme }: ThemedComponent) => theme.semantic?.secondary[600]};
    }
  }

  &:active {
    & svg {
      fill: ${({ theme }: ThemedComponent) => theme.semantic?.secondary[800]};
    }
  }
`;

export const DivisionCardNotSelectedSectionRight = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  flex-direction: row;
  position: relative;
  right: -16px;
  bottom: -4px;
`;

export const DivisionCardAddDivisionButton = styled(IconButton)`
  padding: 4px 16px;

  ${themedIconButton}
`;

export const DivisionCardEditDivisionButton = styled(IconButton)`
  ${themedIconButton}

  padding: 4px 16px;
`;

export const DivisionCardTeamsText = styled(Typography).attrs({
  variant: 'ui',
  size: 'large',
})`
  white-space: nowrap;
`;

export const DivisionCardInfoSectionRight = styled.div`
  align-self: flex-end;
`;

export const DivisionCardSavedInfoSectionRight = styled(
  DivisionCardInfoSectionRight
)`
  display: flex;
  align-items: center;
  position: relative;
  right: -12px;
  bottom: -8px;

  ${media.TabletPortraitUp`
    right: -16px;
    bottom: -12px;
  `}
`;

export const UnavailableMessage = styled(Typography).attrs({
  variant: 'ui',
  size: 'large',
  weight: 'bold',
})`
  align-self: flex-end;
  padding: 4px;
  text-align: right;
  color: ${({ theme }: ThemedComponent) =>
    theme.palette.foundational.blueGrey[600]};
`;

export const RegistrationStartDate = styled.span`
  white-space: nowrap;
`;
