import { Typography } from '@la/ds-ui-components';
import { ChildYouthAthleteAccess } from '../YouthAthleteAccess.types';
import * as S from '../EditMobileAppAccess.styles';

interface YouthAthleteRevocationSectionListProps {
  disabledYouthAthletes: ChildYouthAthleteAccess[];
}

export default function YouthAthleteRevocationSectionList({
  disabledYouthAthletes,
}: YouthAthleteRevocationSectionListProps) {
  const disabledYouthAthleteSections = disabledYouthAthletes.map(
    (youthAthlete) => (
      <S.MobileAppAccessSection key={youthAthlete.userId}>
        <Typography variant="headline" size="medium">
          Mobile app access has been revoked for {youthAthlete.firstName}.
        </Typography>
        <Typography variant="ui" size="xl">
          {youthAthlete.firstName} will not be able to sign in to the mobile
          app.
        </Typography>
      </S.MobileAppAccessSection>
    )
  );

  return <>{disabledYouthAthleteSections}</>;
}
