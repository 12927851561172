export const generateModalHeaderDynamicStyles = theme => {
  const isDynamic = !!theme.semantic;
  const {
    semantic,
    palette: {
      foundational
    }
  } = theme;
  const color = isDynamic ? semantic?.primary.base : foundational.green[600];
  return color;
};