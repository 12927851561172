import styled from 'styled-components/macro';
import { ReactComponent as L_Logo } from 'assets/LA_lockups/la_L_logo.svg';

const HEADER_TOP_ROW_HEIGHT = 32;
const HEADER_BOTTOM_ROW_HEIGHT = 70;

// This is exported so other components can calculate their height or positioning
// based on this.
export const SITE_HEADER_HEIGHT =
  HEADER_TOP_ROW_HEIGHT + HEADER_BOTTOM_ROW_HEIGHT;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--header-z);
  width: 100%;
  height: 103px;
  background-color: var(--white);
  border-bottom: 1px solid var(--primary);
`;

export const HeaderTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${HEADER_TOP_ROW_HEIGHT}px;
  padding: 0 10px;
  background-color: var(--primary);
`;

export const HeaderBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: ${HEADER_BOTTOM_ROW_HEIGHT}px;
  padding: 0 10px;
`;

export const SiteName = styled.span`
  flex: 1;
  font: var(--ui-medium-bold);
  color: var(--primary-text-color);
`;

export const LAHeaderLogo = styled.span`
  display: flex;
  align-items: center;
  font: var(--ui-medium-medium);
  color: var(--primary-text-color);
`;

export const LA_L_Logo = styled(L_Logo)`
  height: 16px;
  margin: 0 5px 0 0;
  fill: var(--primary-text-color);
`;

export const VerticalDivider = styled.span`
  width: 1px;
  height: 18px;
  margin: 0 8px 0 16px;
  background-color: var(--primary-text-color);
`;
