import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';
import { SITE_HEADER_HEIGHT } from 'domains/Shell/Header/Header.styles';

// This is exported so other components can calculate their height or positioning
// based on this.
export const TOURNAMENT_INFO_HEIGHT = 60;

export const Info = styled.h2`
  width: 100%;
  background: var(--primary-35);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: var(--blue-grey-900);
  font: var(--ui-large-bold);
  border-bottom: 1px solid var(--primary-100);
  // Without this some of the content that was supposed to scroll underneath
  // was showing up on top.
  z-index: 1;

  position: sticky;
  top: 0;

  ${media.TabletPortraitUp`
    height: ${TOURNAMENT_INFO_HEIGHT}px;
    padding: 16px 24px;
    font: var(--ui-xtra-large-bold);
  `}

  ${media.TabletLandscapeUp`
    top: ${SITE_HEADER_HEIGHT}px;
  `}
`;
