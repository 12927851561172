import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import axios from 'axios';
import {
  asyncWithLDProvider,
  LDMultiKindContext,
} from 'launchdarkly-react-client-sdk';
import { setupWorker } from 'msw/browser';
import { apiHandlers } from 'lib/test/handlers';
import launchDarklyClientID from 'lib/utils/launchDarkly';
import { getBaseURL, getLAHostnameParts } from 'lib/utils/urlUtils';
import App from './App';
import './index.css';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

if (
  process.env.REACT_APP_START_MSW === 'true' &&
  process.env.NODE_ENV !== 'test'
) {
  const worker = setupWorker(...apiHandlers);
  worker.start({ onUnhandledRequest: 'bypass' });
}

const container = document.getElementById('root');
const root = createRoot(container!);

const { subdomain } = getLAHostnameParts();
const baseURL = getBaseURL();

const clientID = launchDarklyClientID?.clientID || '';

(async () => {
  let siteId;
  try {
    siteId = (await axios.get(`${baseURL}/api/sites/subdomains/${subdomain}`))
      .data.siteId;
  } catch (error) {
    console.error(error);
    siteId = -1;
  }

  let userId;
  try {
    userId =
      (
        await axios.get(
          `${baseURL}/api/sites/${siteId}/accountInfo?context=member`
        )
      ).data?.user?.id ?? -1;
  } catch (error) {
    console.error(error);
    userId = -1;
  }

  const context: LDMultiKindContext = {
    kind: 'multi',
    user: {
      key: userId.toString(),
      siteId: siteId,
    },
    site: {
      key: siteId.toString(),
    },
  };

  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientID,
    context,
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </LDProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
