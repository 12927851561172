import styled from 'styled-components';
import { typographyWeights, getTypographyVariant } from 'theming/typography';
// The underlying tag will be changed via `as` prop before rendering;
export const Container = styled.p.withConfig({
  componentId: "sc-1xuiuhv-0"
})(({
  theme,
  variant,
  size,
  weight,
  uppercase,
  italic
}) => {
  const styles = getTypographyVariant(variant, size, theme);
  return {
    ...styles,
    fontWeight: weight ? typographyWeights[weight] : styles.fontWeight,
    textTransform: uppercase ? 'uppercase' : 'none',
    fontStyle: italic ? 'italic' : 'normal',
    color: theme.palette.foundational.blueGrey[900],
    margin: 0
  };
});