import { http, HttpResponse } from 'msw';
import { StaffRole } from '@la/types';

export enum StaffRoleEndpoint {
  GetStaffRoles = '*/api/sites/*/staffRoles',
}

const mockStaffRoles: StaffRole[] = [
  {
    id: 1,
    siteId: 1,
    role: 'Team Rep',
    isClubDirector: false,
    isTeamRep: true,
    userSelectable: true,
    allowAddEditScores: true,
    allowCheckin: true,
    allowInvitePlayers: true,
    allowInviteStaff: true,
    allowInviteThisRole: true,
    allowJoinTeam: true,
    allowManagedStaffManagedFields: true,
    allowSelfRegistration: true,
    allowSubmitRoster: true,
  },
  {
    id: 2,
    siteId: 1,
    role: 'Club Director',
    isClubDirector: true,
    isTeamRep: false,
    userSelectable: true,
    allowAddEditScores: true,
    allowCheckin: true,
    allowInvitePlayers: true,
    allowInviteStaff: true,
    allowInviteThisRole: true,
    allowJoinTeam: true,
    allowManagedStaffManagedFields: true,
    allowSelfRegistration: true,
    allowSubmitRoster: true,
  },
  {
    id: 3,
    siteId: 1,
    role: 'Coach',
    isClubDirector: false,
    isTeamRep: false,
    userSelectable: true,
    allowAddEditScores: true,
    allowCheckin: true,
    allowInvitePlayers: true,
    allowInviteStaff: true,
    allowInviteThisRole: true,
    allowJoinTeam: true,
    allowManagedStaffManagedFields: true,
    allowSelfRegistration: true,
    allowSubmitRoster: true,
  },
];

export const staffRoleHandlers = [
  http.get(StaffRoleEndpoint.GetStaffRoles, () => {
    return HttpResponse.json(mockStaffRoles, { status: 200 });
  }),
];
