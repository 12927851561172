"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalSummaryContent = exports.ActionButtons = exports.SubmissionErrorMessage = exports.SubmitButton = exports.SubmitSection = exports.SummaryPanel = exports.SummaryAndSubmit = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var constants_1 = require("../../../../../lib/constants");
var mixins_1 = require("../../../../../lib/media-queries/mixins");
var Wizard_styles_1 = require("../../Wizard.styles");
var WizardHeader_styles_1 = require("../WizardHeader/WizardHeader.styles");
// This is the total vertical space taken up by other elements that we need to know
// to calculate the correct height for this summary and submit button section.
var VERTICAL_SPACE_TOTAL = constants_1.SITE_HEADER_HEIGHT +
    constants_1.MAIN_CONTENT_HEADER_HEIGHT +
    WizardHeader_styles_1.TOURNAMENT_INFO_HEIGHT +
    2 * Wizard_styles_1.WIZARD_CONTENT_PADDING; // leave equal space on top and bottom
// The page title scrolls out of view so the sticking point is based only on the site header
// and tournament info heights.
var STICKING_POINT = constants_1.SITE_HEADER_HEIGHT + WizardHeader_styles_1.TOURNAMENT_INFO_HEIGHT + Wizard_styles_1.WIZARD_CONTENT_PADDING;
exports.SummaryAndSubmit = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1 / -1;\n\n  ", "\n"], ["\n  grid-column: 1 / -1;\n\n  ", "\n"])), mixins_1.media.TabletLandscapeUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-column-start: 12;\n\n    // This section must always remain in view so we set the height to make it fit initially\n    // and have it stick so that it doesn't scroll out of view.\n    height: calc(100vh - ", "px);\n    position: sticky;\n    top: ", "px;\n  "], ["\n    grid-column-start: 12;\n\n    // This section must always remain in view so we set the height to make it fit initially\n    // and have it stick so that it doesn't scroll out of view.\n    height: calc(100vh - ", "px);\n    position: sticky;\n    top: ", "px;\n  "])), VERTICAL_SPACE_TOTAL, STICKING_POINT));
var SUBMIT_BUTTON_HEIGHT = 51;
var SUBMIT_MARGIN_TOP = 12;
var VERTICAL_SPACE_FOR_BUTTON = SUBMIT_BUTTON_HEIGHT + SUBMIT_MARGIN_TOP;
exports.SummaryPanel = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: var(--white);\n  border: 1px solid var(--primary-100);\n  border-radius: 4px;\n  padding: 16px;\n  height: calc(100% - ", "px);\n"], ["\n  background-color: var(--white);\n  border: 1px solid var(--primary-100);\n  border-radius: 4px;\n  padding: 16px;\n  height: calc(100% - ", "px);\n"])), VERTICAL_SPACE_FOR_BUTTON);
exports.SubmitSection = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
exports.SubmitButton = (0, styled_components_1.default)(ds_ui_components_1.Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: ", "px;\n  height: ", "px;\n  font: var(--ui-xtra-large-bold);\n"], ["\n  width: 100%;\n  margin-top: ", "px;\n  height: ", "px;\n  font: var(--ui-xtra-large-bold);\n"])), SUBMIT_MARGIN_TOP, SUBMIT_BUTTON_HEIGHT);
exports.SubmissionErrorMessage = styled_components_1.default.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 4px;\n  color: var(--red);\n  font: var(--ui-small-regular);\n"], ["\n  margin: 4px;\n  color: var(--red);\n  font: var(--ui-small-regular);\n"])));
exports.ActionButtons = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  position: sticky;\n  bottom: 0;\n  display: grid;\n  gap: 8px;\n  padding: 12px 16px;\n  border-top: 1px solid var(--primary-100);\n  background-color: var(--white);\n"], ["\n  width: 100%;\n  position: sticky;\n  bottom: 0;\n  display: grid;\n  gap: 8px;\n  padding: 12px 16px;\n  border-top: 1px solid var(--primary-100);\n  background-color: var(--white);\n"])));
exports.ModalSummaryContent = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: grid;\n  gap: 12px;\n"], ["\n  display: grid;\n  gap: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
