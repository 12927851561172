import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { WaiverData } from 'domains/Checkout/Checkout.types';

export type PlayerRoles = 'captain' | 'teamPlayer' | 'freeAgent';

export type PlayerRolesList = PlayerRoles[];

export type WaiversParameters = {
  siteId: string;
  programId?: string;
  /**
   * List of player roles for form fields/waivers. Defaults to all roles.
   * It will get parsed into a string separating the items with a comma.
   */
  playerRoles?: string[];
  /**
   * List of program staff IDs for form fields/waivers. Defaults to all program staff.
   * It will get parsed into a string separating the items with a comma.
   */
  staffRoles?: number[];
  // Flag to include deleted form fields/waivers in list, defaults to false if omitted.
  includeDeleted?: boolean;
};

const baseUrl = getBaseURL();

const getWaivers = async ({
  siteId,
  programId,
  playerRoles,
  staffRoles,
  includeDeleted,
}: WaiversParameters) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/programs/${programId}/waivers`;
  const token = extractAccessToken();
  const response = await axios.get<WaiverData[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      playerRoles: !playerRoles ? undefined : playerRoles.join(','),
      staffRoles: !staffRoles ? undefined : staffRoles.join(','),
      includeDeleted,
    },
  });
  return response.data.filter((waiver) => waiver.name !== 'Payment Policy');
};

export { getWaivers };
