import {
  EventHandler,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { useUser } from 'lib/context/UserContext';
import { clearStateFromLocalStorage } from 'redux/localStorage';
import { useAppSelector } from 'redux/store';
import { getUserFirstName } from 'redux/userSlice';
import LoginMenu from '../LoginMenu/LoginMenu';
import UserMenu, { UserAccountMenu } from '../UserMenu/UserMenu';
import * as S from './UserMenus.styles';

export type UserMenuButtonProps = {};

/* UserMenus */
export default function UserMenus() {
  const userFirstName = useAppSelector(getUserFirstName);
  const { isUserLoggedIn } = useUser();
  const { updateVisNavMenu, visibleNavMenu } = useMainNavigation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const navSectionMenuId = 'user-menus';

  useEffect((): void => {
    setIsUserMenuOpen(visibleNavMenu === navSectionMenuId);
  }, [navSectionMenuId, visibleNavMenu, setIsUserMenuOpen]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      clearStateFromLocalStorage('app-store');
    }
  }, [isUserLoggedIn]);

  function getUserGreeting(): string {
    return !!userFirstName ? `Hi, ${userFirstName}` : '';
  }

  function showUserMenu(): void {
    updateVisNavMenu(navSectionMenuId);
  }

  function closeUserMenu(): void {
    updateVisNavMenu('');
  }

  function keyDownHandler(evt: KeyboardEvent<HTMLButtonElement>): void {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      if (visibleNavMenu === navSectionMenuId) {
        updateVisNavMenu('');
      } else {
        updateVisNavMenu(navSectionMenuId);
      }
    }
  }

  return (
    <>
      {isUserLoggedIn ? (
        // User Account Button
        <UserMenusButton
          buttonLabelText={getUserGreeting()}
          clickHandler={showUserMenu}
          keyDownHandler={keyDownHandler}
        />
      ) : (
        // User Log In Button
        <UserMenusButton
          buttonLabelText="Log In"
          clickHandler={showUserMenu}
          keyDownHandler={keyDownHandler}
        />
      )}
      {isUserMenuOpen ? (
        isUserLoggedIn ? (
          <UserMenu menuId="account-menu" backdropClickHandler={closeUserMenu}>
            <UserAccountMenu />
          </UserMenu>
        ) : (
          <UserMenu menuId="login-menu" backdropClickHandler={closeUserMenu}>
            <LoginMenu />
          </UserMenu>
        )
      ) : null}
    </>
  );
}

function UserMenusButton({
  buttonLabelText,
  clickHandler,
  keyDownHandler,
}: {
  buttonLabelText: string;
  clickHandler: EventHandler<MouseEvent>;
  keyDownHandler: EventHandler<KeyboardEvent>;
}) {
  return (
    <S.HeaderMenuButton onClick={clickHandler} onKeyDown={keyDownHandler}>
      <S.PersonIcon />
      {buttonLabelText}
      <S.ArrowDown />
    </S.HeaderMenuButton>
  );
}
