"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardHeader = exports.NUMBER_OF_SPOTS_WARNING_AMOUNT = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var luxon_1 = require("luxon");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var S = require("./DivisionCardHeader.styles");
exports.NUMBER_OF_SPOTS_WARNING_AMOUNT = 5;
function DivisionCardHeader(_a) {
    var cardLabelId = _a.cardLabelId, cardState = _a.cardState, cost = _a.cost, name = _a.name, numberOfSpotsLeft = _a.numberOfSpotsLeft, registrationStartDate = _a.registrationStartDate, registrationEndDate = _a.registrationEndDate, variant = _a.variant;
    var cardHeaderRightSection;
    if (cardState.value === 'unavailable') {
        cardHeaderRightSection = ((0, jsx_runtime_1.jsx)(S.DivisionCardHeaderUnavailable, { children: getUnavailableMessage({
                reasonUnavailable: cardState.reasonUnavailable,
                registrationDates: {
                    start: registrationStartDate,
                    end: registrationEndDate,
                },
            }) }));
    }
    else {
        cardHeaderRightSection = ((0, jsx_runtime_1.jsx)("div", { children: (0, ds_ui_components_1.formatAsCurrency)(cost ? Number(cost) : 0) }));
    }
    return ((0, jsx_runtime_1.jsxs)(S.DivisionCardHeader, __assign({ "$variant": variant }, { children: [(0, jsx_runtime_1.jsxs)(S.TitleAndSpotsLeft, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ id: cardLabelId }, { children: name })), (0, jsx_runtime_1.jsx)(DivisionSpotsLeftMessage, { numberOfSpotsLeft: numberOfSpotsLeft })] }), cardHeaderRightSection] })));
}
exports.DivisionCardHeader = DivisionCardHeader;
function DivisionSpotsLeftMessage(_a) {
    var numberOfSpotsLeft = _a.numberOfSpotsLeft;
    if (numberOfSpotsLeft) {
        if (numberOfSpotsLeft < 0) {
            numberOfSpotsLeft = 0;
        }
        var showWarning = numberOfSpotsLeft <= 5 && numberOfSpotsLeft !== 0;
        var spotsLeftText = "".concat(numberOfSpotsLeft === 1 ? '1 spot' : "".concat(numberOfSpotsLeft, " spots"), " left");
        if (showWarning) {
            return ((0, jsx_runtime_1.jsxs)(S.SpotsLeftWarningMessage, { children: [spotsLeftText, "!"] }));
        }
        else {
            return (0, jsx_runtime_1.jsx)(S.SpotsLeftMessage, { children: spotsLeftText });
        }
    }
    return null;
}
function getUnavailableMessage(_a) {
    var reasonUnavailable = _a.reasonUnavailable, registrationDates = _a.registrationDates;
    switch (reasonUnavailable) {
        case 'before-registration-period-opens':
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Registration opens", ' ', (0, jsx_runtime_1.jsx)(S.RegistrationStartDate, { children: (0, utilities_1.formatDateUsingShortMonthName)(luxon_1.DateTime.fromISO(registrationDates.start)) })] }));
        case 'after-registration-period-ends':
            return 'Registration closed';
        case 'at-capacity':
            return 'At capacity';
    }
    return null;
}
