"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAgeGroupOptions = exports.MIN_LOWER_AGE_GROUP = exports.MAX_AGE_GROUP = exports.MIN_AGE_GROUP = exports.ALL_AGES_GROUP_LABEL = void 0;
var DivisionCard_1 = require("../../DivisionCard/DivisionCard");
exports.ALL_AGES_GROUP_LABEL = 'All ages';
exports.MIN_AGE_GROUP = 6;
exports.MAX_AGE_GROUP = 19;
exports.MIN_LOWER_AGE_GROUP = 2;
/**
 * Generates array of whole numbers given a range.
 * @param start The starting age group.
 * @param end The ending age group.
 * @returns Array of numbers from `start` to `end` (inclusive).
 */
function range(start, end) {
    return Array.from({ length: end - start + 1 }, function (_, k) { return k + start; });
}
/**
 * Creates the select options for the age group field. Will generate the options
 * based off of the provided max. Includes an "any" option as well.
 * @param maxAgeGroup The max age group to list.
 * @returns Array of options for a select.
 */
function createAgeGroupOptions(maxAgeGroup) {
    var minAgeGroup = maxAgeGroup === 0 || maxAgeGroup - exports.MIN_LOWER_AGE_GROUP < exports.MIN_AGE_GROUP
        ? exports.MIN_AGE_GROUP
        : maxAgeGroup - exports.MIN_LOWER_AGE_GROUP;
    var validAgeGroups = maxAgeGroup === 0
        ? range(exports.MIN_AGE_GROUP, exports.MAX_AGE_GROUP)
        : range(minAgeGroup, maxAgeGroup);
    var ageGroupOptions = validAgeGroups
        .map(function (ageGroup) {
        return {
            label: (0, DivisionCard_1.ageGroupDisplay)(ageGroup),
            value: ageGroup.toString(),
        };
    })
        .concat([{ label: exports.ALL_AGES_GROUP_LABEL, value: '0' }]);
    return ageGroupOptions;
}
exports.createAgeGroupOptions = createAgeGroupOptions;
