import { useState } from 'react';
import {
  Card,
  CardHeader,
  Checkbox,
  Typography,
  Button,
  InputErrorMessage,
} from '@la/ds-ui-components';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import * as S from './RegistrationWaiversCard.styles';

export const RegistrationWaiversCard = () => {
  const { waivers, signedWaivers, dispatch, waiversTouched } =
    useRegistration();
  const [openedWaivers, setOpenedWaivers] = useState<string[]>(() => {
    return !waivers ? [] : waivers.map((waiver) => waiver.waiverId.toString());
  });

  if (!waivers) {
    return null;
  }

  const handleToggleAccordion = (id: string) => {
    if (openedWaivers.includes(id)) {
      setOpenedWaivers((previous) =>
        previous.filter((waiverId) => waiverId !== id)
      );
    } else {
      setOpenedWaivers((previous) => previous.concat(id));
    }
  };

  const handleCheckChange = (id: string, index: number) => {
    dispatch({ type: 'SET_SIGNED_WAIVERS', payload: { id } });
    if (openedWaivers.includes(id) && !signedWaivers[id]) {
      setOpenedWaivers(openedWaivers.filter((waiverId) => waiverId !== id));
      if (index < waivers.length - 1) {
        const nextWaiver = waivers[index + 1];
        if (!signedWaivers[nextWaiver.waiverId.toString()]) {
          setOpenedWaivers((previous) =>
            previous.concat(nextWaiver.waiverId.toString())
          );
          document.getElementById(`waiver_${index + 1}`)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }
  };

  return (
    <S.WaiversContainer>
      {waivers.map((waiver, i) => {
        const id = waiver.waiverId.toString();
        const isOpened = openedWaivers.includes(id);
        const hasError = !signedWaivers[waiver.waiverId] && waiversTouched;
        return (
          <div key={id} id={`waiver_${i + 1}`}>
            <Card>
              <CardHeader>{waiver.name}</CardHeader>
              <S.CardBody>
                <S.AccordionTriggerContainer>
                  <S.CheckboxWrapper>
                    <S.CheckboxContainer>
                      <Checkbox
                        id={waiver.name}
                        ariaLabel={waiver.name}
                        size="large"
                        checked={signedWaivers[id]}
                        onCheckedChange={() => handleCheckChange(id, i)}
                        hasError={hasError}
                      />
                      <label htmlFor={waiver.name}>
                        I have read and agree to the <b>{waiver.name}</b> waiver
                        below
                      </label>
                    </S.CheckboxContainer>
                    {hasError ? (
                      <InputErrorMessage>
                        Please agree to the required document.
                      </InputErrorMessage>
                    ) : null}
                  </S.CheckboxWrapper>
                  <Button
                    onClick={() => handleToggleAccordion(id)}
                    variant="outline"
                    rightIcon={
                      <S.AccordionCaretContainer $isOpened={isOpened}>
                        <S.AccordionCaret />
                      </S.AccordionCaretContainer>
                    }
                  >
                    {isOpened ? 'Hide document' : 'Show document'}
                  </Button>
                </S.AccordionTriggerContainer>
              </S.CardBody>
              {isOpened && (
                <S.AccordionContentContainer>
                  <S.AccordionContent>
                    <Typography variant="ui" size="large">
                      <span
                        dangerouslySetInnerHTML={{ __html: waiver.content }}
                      />
                    </Typography>
                  </S.AccordionContent>
                </S.AccordionContentContainer>
              )}
            </Card>
          </div>
        );
      })}
    </S.WaiversContainer>
  );
};
