import styled from 'styled-components';
export const buttonPadding = {
  small: '4px 16px',
  medium: '4px 16px',
  large: '8px 16px',
  xl: '8px 20px'
};
export const buttonHeight = {
  small: '16px',
  medium: '32px',
  large: '40px',
  xl: '52px'
};
export const iconButtonSize = {
  small: '12px',
  medium: '16px',
  large: '20px',
  xl: '24px'
};
export const iconButtonPadding = {
  small: 0,
  medium: '4px 16px',
  large: '4px 16px',
  xl: '4px 20px'
};
export const getIconButtonSize = size => {
  if (size === 'small') {
    return iconButtonSize.small;
  }
  if (size === 'xl') {
    return iconButtonSize.large;
  }
  return iconButtonSize.medium;
};
export const getButtonWidth = $width => {
  if ($width === undefined) {
    return 'fit-content';
  }
  if ($width === 'full') {
    return '100%';
  }
  if (typeof $width === 'number') {
    return `${$width}px`;
  }
  return $width;
};
export const generatePrimaryButtonDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    semantic,
    palette
  } = theme;
  const {
    foundational: {
      green,
      blueGrey,
      white
    }
  } = palette;
  const backgroundColor = {
    default: isDynamic ? semantic?.secondary.base : green[600],
    focus: isDynamic ? semantic?.secondary.base : green[600],
    hover: isDynamic ? semantic?.secondary.hover : green[800],
    active: isDynamic ? semantic?.secondary.active : green[900],
    disabled: blueGrey[50]
  };
  const borderColor = {
    default: isDynamic ? semantic?.secondary[600] : green[600],
    focus: isDynamic ? semantic?.secondary[600] : green[600],
    hover: isDynamic ? semantic?.secondary[600] : green[800],
    active: isDynamic ? semantic?.secondary[600] : green[900],
    disabled: blueGrey[50]
  };
  const color = isDynamic ? semantic?.secondary.reactiveTextColor : white;
  const outlineColor = isDynamic ? blueGrey[600] : green[600];
  return {
    backgroundColor: backgroundColor[state],
    borderColor: borderColor[state],
    color: state === 'disabled' ? blueGrey[400] : color,
    outlineColor
  };
};
export const generateOutlineButtonDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    semantic,
    palette
  } = theme;
  const {
    foundational: {
      green,
      blueGrey
    }
  } = palette;
  const borderColor = {
    default: isDynamic ? semantic?.secondary.base : blueGrey[400],
    focus: isDynamic ? semantic?.secondary.base : green[400],
    hover: isDynamic ? semantic?.secondary.hover : green[800],
    active: isDynamic ? semantic?.secondary.active : green[900],
    disabled: blueGrey[200]
  };
  const color = {
    default: blueGrey[900],
    focus: isDynamic ? semantic?.secondary.base : green[400],
    hover: isDynamic ? semantic?.secondary.hover : green[800],
    active: isDynamic ? semantic?.secondary.active : green[900],
    disabled: blueGrey[200]
  };
  const outlineColor = isDynamic ? blueGrey[600] : green[600];
  const {
    fill
  } = generateTextButtonIconDynamicStyles(theme, state, false);
  return {
    borderColor: borderColor[state],
    color: color[state],
    fill,
    outlineColor,
    backgroundColor: state === 'active' ? blueGrey[50] : 'transparent'
  };
};
export const generateTextButtonIconDynamicStyles = (theme, state, isDense) => {
  const isDynamic = !!theme.semantic;
  const {
    semantic,
    palette
  } = theme;
  const {
    foundational: {
      green,
      blueGrey,
      white
    }
  } = palette;
  const fill = {
    default: isDynamic ? semantic?.secondary.base : green[400],
    focus: isDynamic ? semantic?.secondary.base : green[400],
    hover: isDynamic ? semantic?.secondary.hover : green[800],
    active: isDynamic ? semantic?.secondary.active : green[900],
    disabled: isDense ? white : blueGrey[400]
  };
  const backgroundColor = {
    default: isDynamic ? semantic?.secondary.base : green[400],
    focus: isDynamic ? semantic?.secondary.base : green[400],
    hover: isDynamic ? semantic?.secondary.hover : green[800],
    active: isDynamic ? semantic?.secondary.active : green[900],
    disabled: blueGrey[200]
  };
  return {
    fill: isDense && state !== 'disabled' ? white : fill[state],
    backgroundColor: isDense ? backgroundColor[state] : 'transparent'
  };
};
export const createButtonBaseStyles = tag => {
  if (tag === 'a') {
    return styled.a.withConfig({
      componentId: "sc-64d0e0-0"
    })(({
      theme,
      $size,
      $loading,
      $width,
      $hasPadding
    }) => {
      const {
        typography,
        spacing,
        semantic,
        palette: {
          foundational: {
            white
          }
        }
      } = theme;
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: $size === 'small' ? spacing.xxxs : spacing.xxs,
        height: buttonHeight[$size],
        width: getButtonWidth($width),
        backgroundColor: 'transparent',
        borderRadius: spacing.xxxs,
        border: 'none',
        cursor: 'pointer',
        color: semantic?.secondary.reactiveTextColor ?? white,
        padding: !$hasPadding ? 0 : buttonPadding[$size],
        ...typography.ui[$size],
        fontWeight: typography.weights.bold,
        pointerEvents: $loading ? 'none' : 'all',
        textDecoration: 'none',
        '&[disabled]': {
          pointerEvents: 'none'
        }
      };
    });
  }
  return styled.button.withConfig({
    componentId: "sc-64d0e0-1"
  })(({
    theme,
    $size,
    $loading,
    $width,
    $hasPadding
  }) => {
    const {
      typography,
      spacing,
      semantic,
      palette: {
        foundational: {
          white
        }
      }
    } = theme;
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: $size === 'small' ? spacing.xxxs : spacing.xxs,
      height: buttonHeight[$size],
      width: getButtonWidth($width),
      backgroundColor: 'transparent',
      borderRadius: spacing.xxxs,
      border: 'none',
      cursor: 'pointer',
      color: semantic?.secondary.reactiveTextColor ?? white,
      padding: !$hasPadding ? 0 : buttonPadding[$size],
      ...typography.ui[$size],
      fontWeight: typography.weights.bold,
      pointerEvents: $loading ? 'none' : 'all',
      textDecoration: 'none',
      '&[disabled]': {
        pointerEvents: 'none'
      }
    };
  });
};