import { PropsWithChildren } from 'react';
import * as S from './Page.styles';

export interface PageProps {
  id?: string;
}

/* Page */
export default function Page({ children, id }: PropsWithChildren<PageProps>) {
  return <S.PageContent id={id}>{children}</S.PageContent>;
}
/* */
