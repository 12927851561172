import { Scrollbar as RadixScrollbar, Thumb } from '@radix-ui/react-scroll-area';
import { Trigger, Content, Group, Viewport, Value, Label } from '@radix-ui/react-select';
import styled from 'styled-components';
import { generateInputBaseDynamicStyles, generateInputPlaceholderDynamicStyles, generateInputAdornmentDynamicStyles } from 'theming/tokens/components/input';
import { inputVariantHeights } from 'components/InputBase/InputBase.styles';
const getInputContainerWidth = width => {
  if (!width) {
    return 'auto';
  }
  if (typeof width === 'number') {
    return `${width}px`;
  }
  return width;
};
export const SelectWrapper = styled.div.withConfig({
  componentId: "sc-vwdkuv-0"
})(({
  $isInteractive
}) => {
  const cursor = $isInteractive ? 'pointer' : 'not-allowed';
  return {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    cursor,
    '& > label': {
      pointerEvents: $isInteractive ? 'auto' : 'none'
    }
  };
});
export const SelectTrigger = styled(Trigger).withConfig({
  componentId: "sc-vwdkuv-1"
})(({
  theme,
  $state,
  $size,
  $width,
  $isInteractive
}) => {
  const styles = generateInputBaseDynamicStyles(theme, $state);
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '0 8px',
    margin: '4px 0',
    minWidth: 0,
    width: getInputContainerWidth($width),
    height: inputVariantHeights[$size],
    backgroundColor: styles.backgroundColor,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: styles.borderColor,
    pointerEvents: $isInteractive ? 'auto' : 'none',
    '&:focus-within': {
      outline: $isInteractive ? '2px solid' : 'none',
      outlineOffset: '2px',
      outlineColor: styles.borderColor
    }
  };
});
export const ChevronWrapper = styled.div.withConfig({
  componentId: "sc-vwdkuv-2"
})(({
  theme,
  $state
}) => {
  const {
    fill
  } = generateInputAdornmentDynamicStyles(theme, $state);
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0 4px 0',
    fill: fill,
    stroke: fill
  };
});
export const SelectContent = styled(Content).withConfig({
  componentId: "sc-vwdkuv-3"
})(({
  theme
}) => {
  const {
    foundational
  } = theme.palette;
  return {
    width: 'var(--radix-select-trigger-width)',
    backgroundColor: foundational.white,
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${foundational.blueGrey[100]}`,
    borderRadius: theme.spacing.xxxs,
    marginTop: '1px',
    overflowX: 'hidden',
    // Hardcoded until we add zIndexes to theme
    zIndex: 1100
  };
});
export const SelectGroup = styled(Group).withConfig({
  componentId: "sc-vwdkuv-4"
})(({
  theme
}) => {
  return {
    ...theme.typography.ui.medium,
    fontWeight: theme.typography.weights.semiBold,
    lineHeight: '36px'
  };
});
export const SelectViewport = styled(Viewport).withConfig({
  componentId: "sc-vwdkuv-5"
})({
  maxHeight: 'min(376px, var(--radix-select-content-available-height))'
});
export const SelectValueContainer = styled.span.withConfig({
  componentId: "sc-vwdkuv-6"
})(({
  theme,
  $isPlaceholder,
  $state
}) => {
  const {
    color: placeholderColor
  } = generateInputPlaceholderDynamicStyles(theme, $state);
  const {
    color
  } = generateInputBaseDynamicStyles(theme, $state);
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontStyle: $isPlaceholder ? 'italic' : 'normal',
    color: $isPlaceholder ? placeholderColor : color
  };
});
export const SelectValue = styled(Value).withConfig({
  componentId: "sc-vwdkuv-7"
})(({
  theme,
  $size
}) => {
  return theme.typography.ui[$size];
});
export const SelectGroupLabel = styled(Label).withConfig({
  componentId: "sc-vwdkuv-8"
})(({
  theme
}) => {
  return {
    margin: `0 ${theme.spacing.small}`,
    wordBreak: 'break-word'
  };
});
export const Scrollbar = styled(RadixScrollbar).withConfig({
  componentId: "sc-vwdkuv-9"
})(({
  theme
}) => {
  return {
    width: '14px',
    padding: theme.spacing.xxxs,
    backgroundColor: theme.palette.foundational.white
  };
});
export const ScrollbarThumb = styled(Thumb).withConfig({
  componentId: "sc-vwdkuv-10"
})(({
  theme
}) => {
  return {
    backgroundColor: theme.palette.foundational.blueGrey[200],
    borderRadius: theme.spacing.xxxs
  };
});