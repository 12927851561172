import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import * as S from './SecondaryNavigationBar.styles';

export type SecondaryNavigationBarProps = {
  hasVisibleTeams: boolean;
  registerSection?: ReactNode;
  tournamentId: string;
};

export function SecondaryNavigationBar({
  hasVisibleTeams,
  registerSection,
  tournamentId,
}: SecondaryNavigationBarProps) {
  const links = [
    {
      label: 'Tournament profile',
      to: `/app/tournaments/${tournamentId}`,
    },
    // These will be enabled later
    // {
    //   label: 'Schedules',
    //   to: `/app/tournaments/${tournamentId}/schedules`,
    // },
  ];

  if (
    hasVisibleTeams &&
    !links.some((link) => link.label === 'Participating Teams')
  ) {
    links.push({
      label: 'Participating Teams',
      to: `/app/tournaments/participants/${tournamentId}`,
    });
  }

  return (
    <S.SecondaryNavigationBar>
      <NavigationMenu.Root>
        <S.NavigationMenuList>
          {links.map((link) => (
            <NavigationMenu.Item key={link.to}>
              <S.NavigationMenuLink asChild>
                <NavLink to={link.to}>{link.label}</NavLink>
              </S.NavigationMenuLink>
            </NavigationMenu.Item>
          ))}
        </S.NavigationMenuList>
      </NavigationMenu.Root>
      {registerSection}
    </S.SecondaryNavigationBar>
  );
}
