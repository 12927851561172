"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewInvoices = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var TeamRegistrationContext_1 = require("../TeamRegistration/TeamRegistrationContext");
var getNumberOfRegisteringTeams_1 = require("../utilities/getNumberOfRegisteringTeams");
var PaymentOptionsStep_styles_1 = require("../PaymentOptionsStep/PaymentOptionsStep.styles");
var S = require("./ReviewInvoices.styles");
var ReviewInvoices = function (_a) {
    var paymentOption = _a.paymentOption, totalCost = _a.totalCost, mcPaymentPlans = _a.mcPaymentPlans;
    var _b = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state, payer = _b.payer, wizardState = _b.wizardState, selectedPaymentOption = _b.selectedPaymentOption;
    var numberOfRegisteringTeams = (0, getNumberOfRegisteringTeams_1.getNumberOfRegisteringTeams)(wizardState.divisionCardsState);
    var _c = (0, react_1.useState)(function () {
        var detailsHashMap = {};
        var divisions = wizardState.tournament.divisions.map(function (division) { return division.id; });
        divisions.forEach(function (divisionId) {
            detailsHashMap[divisionId] = false;
        });
        return detailsHashMap;
    }), showDetails = _c[0], setShowDetails = _c[1];
    var state = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state;
    var payerName = "".concat(payer.firstName, " ").concat(payer.lastName);
    var formattedCost = (0, ds_ui_components_1.formatAsCurrency)(mcPaymentPlans ? totalCost : state.wizardState.cost);
    var divisionsToRender = state.wizardState.payload.filter(function (payload) { return payload.teams.length > 0; });
    return ((0, jsx_runtime_1.jsxs)(S.ReviewInvoicesContainer, { children: [(0, jsx_runtime_1.jsxs)(S.DescriptionSection, { children: [(0, jsx_runtime_1.jsxs)(S.TitlesSection, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Teams:" })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Invoice total:" })), selectedPaymentOption && selectedPaymentOption !== 'pay-in-full' ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Payment plan:" }))) : null, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Payer:" }))] }), (0, jsx_runtime_1.jsxs)(S.TitlesSection, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: numberOfRegisteringTeams })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: formattedCost })), selectedPaymentOption && selectedPaymentOption !== 'pay-in-full' ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: paymentOption.name }))) : null, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: payerName }))] })] }), divisionsToRender.map(function (payload) {
                var teams = payload.teams;
                var division = state.wizardState.tournament.divisions.find(function (d) { return d.id === payload.programId; });
                var price = 0;
                if (division.cost) {
                    price = parseFloat(division.cost) * teams.length;
                    if (mcPaymentPlans && (paymentOption === null || paymentOption === void 0 ? void 0 : paymentOption.priceAdjustment)) {
                        var priceAdjustment = paymentOption.priceAdjustment;
                        price =
                            'fixedAmount' in priceAdjustment
                                ? price + priceAdjustment.fixedAmount
                                : price + price * (priceAdjustment.percentage / 100);
                    }
                }
                return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Card, __assign({ width: "100%" }, { children: [(0, jsx_runtime_1.jsxs)(ds_ui_components_1.CardHeader, { children: [division.name, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "xl", weight: "bold" }, { children: (0, utilities_1.formatAsUSD)(price < 0 ? 0 : price) }))] }), (0, jsx_runtime_1.jsxs)(ds_ui_components_1.CardBody, { children: [(0, jsx_runtime_1.jsx)(PaymentOptionsStep_styles_1.ExpandOptionDetails, { children: (0, jsx_runtime_1.jsxs)(PaymentOptionsStep_styles_1.ExpandButton, __assign({ "$isOpen": showDetails[division.id], onClick: function () {
                                            return setShowDetails(function (previous) {
                                                var _a;
                                                return (__assign(__assign({}, previous), (_a = {}, _a[division.id] = !previous[division.id], _a)));
                                            });
                                        } }, { children: [showDetails ? 'Hide details' : 'View details', (0, jsx_runtime_1.jsx)(ds_ui_components_1.CaretDownIcon, { variant: "filled", size: "medium" })] })) }), showDetails[division.id] ? ((0, jsx_runtime_1.jsx)(S.PartialAmountContainer, { children: teams.map(function (team) {
                                        return ((0, jsx_runtime_1.jsxs)(S.PricePerTeam, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: team.name })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: (0, ds_ui_components_1.formatAsCurrency)(price / teams.length) }))] }, team.id));
                                    }) })) : null] })] }), payload.programId));
            })] }));
};
exports.ReviewInvoices = ReviewInvoices;
