"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaymentOptions = void 0;
var react_1 = require("react");
var services_1 = require("@la/services");
var usePaymentOptions = function (_a) {
    var siteId = _a.siteId, programId = _a.programId;
    var _b = (0, react_1.useState)(null), paymentOptions = _b[0], setPaymentOptions = _b[1];
    var _c = (0, react_1.useState)(null), paymentRules = _c[0], setPaymentRules = _c[1];
    var _d = (0, react_1.useState)(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = (0, react_1.useState)(null), error = _e[0], setError = _e[1];
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        var promises = [
            (0, services_1.getProgramPaymentPlans)({ siteId: siteId, programId: programId }),
            (0, services_1.getProgramPaymentRules)({ siteId: siteId, programId: programId }),
        ];
        Promise.all(promises)
            .then(function (_a) {
            var paymentOptions = _a[0], paymentRules = _a[1];
            setPaymentOptions(paymentOptions);
            setPaymentRules(paymentRules);
        })
            .catch(function (error) { return setError(error); })
            .finally(function () {
            setIsLoading(false);
        });
    }, [siteId, programId]);
    return { paymentOptions: paymentOptions, paymentRules: paymentRules, isLoading: isLoading, error: error };
};
exports.usePaymentOptions = usePaymentOptions;
