"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardsSectionTitle = exports.DivisionCards = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var mixins_1 = require("../../../../../lib/media-queries/mixins");
exports.DivisionCards = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  ", "\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    gap: 16px;\n  "], ["\n    gap: 16px;\n  "]))));
exports.DivisionCardsSectionTitle = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'xl',
    variant: 'ui',
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 12px;\n\n  ", "\n"], ["\n  margin-top: 12px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-top: 16px;\n  "], ["\n    margin-top: 16px;\n  "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
