import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { getSiteId } from 'redux/coreSlice';
import { useGetSiteNavigationQuery } from 'redux/services/siteInfo';
import { useAppSelector } from 'redux/store';
import HamburgerMenuButton from '../HamburgerMenuButton/HamburgerMenuButton';
import NavBar from '../NavBar/NavBar';
import NavigationPanel from '../NavigationPanel/NavigationPanel';
import ShoppingCartCounter from '../ShoppingCartCounter/ShoppingCartCounter';
import * as S from './MainNavigation.styles';

export type MainNavigationProps = {
  siteLogoImageSrc?: string;
  siteLogoHref?: string;
  siteName: string;
};

/* MainNavigation */
export default function MainNavigation({
  siteLogoImageSrc,
  siteLogoHref,
  siteName,
}: MainNavigationProps) {
  const siteId = useAppSelector(getSiteId);
  const { data /* error, isLoading */ } = useGetSiteNavigationQuery(siteId);
  const { ngTournamentRegistration } = useFlags();
  const { isNavigationCollapsed, updateNavPanelVisibility } =
    useMainNavigation();

  function showNavigationPanel() {
    updateNavPanelVisibility(true);
  }

  return data?.mainNav ? (
    <S.MainNavigation>
      {isNavigationCollapsed ? (
        <>
          {ngTournamentRegistration ? <ShoppingCartCounter /> : null}
          <S.HamburgerButtonContainer>
            <HamburgerMenuButton clickHandler={showNavigationPanel} />
          </S.HamburgerButtonContainer>
          <NavigationPanel
            navData={data.mainNav}
            siteLogoImageSrc={siteLogoImageSrc}
            siteLogoHref={siteLogoHref}
            siteName={siteName}
          />
        </>
      ) : (
        <>
          <NavBar navData={data.mainNav} />
          {ngTournamentRegistration ? (
            <S.ShoppingCartContainer>
              <ShoppingCartCounter />
            </S.ShoppingCartContainer>
          ) : null}
        </>
      )}
    </S.MainNavigation>
  ) : null;
}
/* */
