import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PageTitle = styled.div<{ $hasBreadcrumbs?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: ${({ $hasBreadcrumbs }) => ($hasBreadcrumbs ? 'wrap' : 'nowrap')};
  gap: 4px;
  width: 100%;
  padding: 16px 12px;
  background-color: var(--primary);
  font: var(--headline-medium);
  color: var(--white);

  svg {
    width: 28px;
    height: 28px;
    padding: 0;
    min-height: unset;
  }

  ${({ $hasBreadcrumbs }) => `
    ${media.TabletLandscapeUp`
      padding: 8px 16px 16px;
      font: var(--headline-xtra-large);
      height: ${$hasBreadcrumbs ? 'auto' : '90px'};

      svg {
        width: 40px;
        height: 40px;
      }
    `}
  `}
`;

export const Breadcrumbs = styled.div({
  width: '100%',
  marginBottom: '18px',
});
