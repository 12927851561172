import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export type User = {
  firstName: string;
  lastName: string;
  email?: string;
  gender?: 'Male' | 'Female';
  birthdate?: string | null; // YYYY-MM-DD
};

export type Address = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
};

export type Player = {
  user: User;
  address: Address;
};

export type PlayerMetadata = {
  version: number;
  type: string;
  programRole: string;
  registeredUserId?: number;
  isCoCaptain: boolean;
  teamIdOg: number;
  registeringStaff?: number;
  playerName?: string;
  email?: string;
};

export type PlayerParameters = {
  siteDomain: string;
  player: Player;
};

export type Registration<T> = {
  deleted: boolean;
  id?: string;
  siteId?: number;
  programId: number;
  registrationType: string;
  teamId?: string;
  paymentStatus?: string; //ENUM
  registeringUserId?: number;
  registrationStatus?: string; //ENUM
  teamIdOg: number;
  metadata?: T;
};

export type RegistrationRequestContent<T> = {
  add?: Registration<T>[];
  remove?: Registration<T>[];
  update?: Registration<T>[];
};

export type RegistrationRequest<T> = {
  content: RegistrationRequestContent<T>;
};

export type PlayerRegisterParameters<T> = {
  siteDomain: string;
  registration: RegistrationRequest<T>;
};

const baseUrl = getBaseURL();

const createPlayer = async ({ siteDomain, player }: PlayerParameters) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/player`;
  const token = extractAccessToken();
  const response = await axios.post(endpoint, player, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const registerPlayer = async ({
  siteDomain,
  registration,
}: PlayerRegisterParameters<PlayerMetadata>) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/player/register`;
  const token = extractAccessToken();
  const response = await axios.post(endpoint, registration, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const editPlayer = async ({ siteDomain, player }: PlayerParameters) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/player`;
  const token = extractAccessToken();
  const response = await axios.put(endpoint, player, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export { createPlayer, editPlayer, registerPlayer };
