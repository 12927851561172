import { Modal, ModalPrimaryActionButton } from '@la/ds-ui-components';
import * as S from './PaymentPolicyModal.styles';

export type PaymentPolicyModalProps = {
  closeModal: () => void;
  modalTitle: string;
  open: boolean;
  policyContent?: string;
  policyName?: string;
};

/* PaymentPolicyModal */
export default function PaymentPolicyModal({
  closeModal,
  modalTitle,
  open,
  policyContent,
  policyName,
}: PaymentPolicyModalProps) {
  const dangerousHTML = policyContent
    ? { __html: policyContent }
    : { __html: '' };

  return (
    <Modal
      onOpenChange={closeModal}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton onClick={closeModal}>
          Done
        </ModalPrimaryActionButton>
      }
      size="medium"
      title={modalTitle}
    >
      <S.PaymentPolicyName>
        {policyName ?? 'Payment Policy'}
      </S.PaymentPolicyName>
      <S.PaymentPolicyContent dangerouslySetInnerHTML={dangerousHTML} />
    </Modal>
  );
}
/* */
