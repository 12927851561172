import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Button } from '@la/ds-ui-components';
import { CardTitle } from 'components/Card/Card';
import Chip from 'components/Chip/Chip';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { formatDateRange } from 'lib/utils/dateUtils';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useDeleteCartItemMutation } from 'redux/services/checkoutApi';
import { ReactComponent as ExpandLess } from 'assets/icons/icon_expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/icons/icon_expand_more.svg';
import {
  CartItemData,
  CartItemOptionsSubprogramsDetail,
  CartItemOptionsSubprogramsRegistration,
} from '../Checkout.types';
import RemoveFromCartModal from '../RemoveFromCartModal/RemoveFromCartModal';
import * as S from './CartSummaryCard.styles';

export type CartSummaryCardProps = {
  cartItemData: CartItemData;
  isShowingAdditionalButtons?: boolean;
};

export type CartItemProperties = {
  divisions: CartItemDivision[];
  endDate: string;
  location: string;
  logoUrl?: string;
  name: string;
  startDate: string;
};

export type CartItemDivision = {
  id: string;
  name: string;
  teams: CartItemOptionsSubprogramsRegistration[];
};

type CartItemHeaderProps = {
  logoUrl?: string;
  cartItemName: string;
  cartItemCost: string;
};

type CartItemBodyProps = {
  cartItemId: string;
  editUrl: string;
  isShowingAdditionalButtons?: boolean;
  subprograms: CartItemOptionsSubprogramsDetail[];
  endDate: string;
  location?: string;
  startDate: string;
};

type ActionButtonsProps = {
  editUrl: string;
  isShowingAdditionalButtons?: boolean;
  showDetails: boolean;
  showDetailsHandler: () => void;
  openRemoveItemModal: () => void;
};

const DIVISION_TABLE_HEADER: string[] = ['Division', 'Team', 'Total price'];

/* CartSummaryCard */
export default function CartSummaryCard({
  cartItemData,
  isShowingAdditionalButtons,
}: CartSummaryCardProps) {
  const { cartItemUuid, editUrl, options, subtotal } = cartItemData;
  const { program, subprograms } = options;
  const { startDate, endDate, locationName, programName, programLogo } =
    program;
  const { details } = subprograms;
  return (
    <S.CartSummaryCard>
      <CartCardHeader
        logoUrl={programLogo}
        cartItemName={programName}
        cartItemCost={subtotal}
      />
      <CartCardBody
        isShowingAdditionalButtons={isShowingAdditionalButtons}
        editUrl={editUrl}
        startDate={startDate}
        cartItemId={cartItemUuid}
        subprograms={details}
        endDate={endDate}
        location={locationName}
      />
    </S.CartSummaryCard>
  );
}
/* */

/* CartCardHeader*/
function CartCardHeader({
  logoUrl,
  cartItemName,
  cartItemCost,
}: CartItemHeaderProps) {
  return (
    <S.CartCardHeader>
      <S.CartCardHeaderLeft>
        {logoUrl ? <S.CartCardLogo src={logoUrl} /> : null}
        <CardTitle>{cartItemName}</CardTitle>
      </S.CartCardHeaderLeft>
      <S.CartCardHeaderRight>
        <CardTitle>${cartItemCost}</CardTitle>
      </S.CartCardHeaderRight>
    </S.CartCardHeader>
  );
}
/* */

export function formatDateForCard(startDate?: string, endDate?: string) {
  if (startDate && endDate) {
    return formatDateRange(
      DateTime.fromISO(startDate, { zone: 'utc' }),
      DateTime.fromISO(endDate, { zone: 'utc' })
    );
  } else if (startDate) {
    return formatDateRange(DateTime.fromISO(startDate, { zone: 'utc' }));
  } else {
    return null;
  }
}

/* CartCardBody*/
function CartCardBody({
  cartItemId,
  subprograms,
  endDate,
  editUrl,
  isShowingAdditionalButtons,
  startDate,
  location,
}: CartItemBodyProps) {
  const [
    deleteCartItem,
    { isError: hasDeleteCartItemError, isLoading: isDeletingCartItem },
  ] = useDeleteCartItemMutation();
  const [showDetails, setShowDetails] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const { subdomain } = getLAHostnameParts();

  function showDetailsHandler() {
    setShowDetails(!showDetails);
  }

  function openRemoveItemModal() {
    setShowRemoveItemModal(true);
  }

  function closeRemoveItemModal() {
    setShowRemoveItemModal(false);
  }

  function removeCard() {
    deleteCartItem({ cartItemId, siteDomain: subdomain })
      .unwrap()
      .then(() => {
        setShowRemoveItemModal(false);
      })
      .catch(() => {
        setShowRemoveItemModal(false);
      });
  }

  return (
    <S.CartCardBody>
      {hasDeleteCartItemError && (
        <ErrorCard message="There was an error removing this item. Please try removing again in a few seconds." />
      )}
      <S.CartCardBodyTopContainer>
        <S.TournamentDetail>
          {formatDateForCard(startDate, endDate)}{' '}
          {location ? `at ${location}` : ''}
        </S.TournamentDetail>
        <DivisionList subprograms={subprograms} />
        <ActionButtons
          editUrl={editUrl}
          isShowingAdditionalButtons={isShowingAdditionalButtons}
          showDetails={showDetails}
          showDetailsHandler={showDetailsHandler}
          openRemoveItemModal={openRemoveItemModal}
        />
      </S.CartCardBodyTopContainer>
      {showDetails ? (
        <S.ProgramRegistrationsTableContainer data-testid="program-registrations-table-container">
          <ProgramRegistrationsTable subprograms={subprograms} />
        </S.ProgramRegistrationsTableContainer>
      ) : null}
      {showRemoveItemModal ? (
        <RemoveFromCartModal
          open={showRemoveItemModal}
          closeModal={closeRemoveItemModal}
          removeCard={removeCard}
          loading={isDeletingCartItem}
        />
      ) : null}
    </S.CartCardBody>
  );
}
/* */

/* DivisionList*/
function DivisionList({
  subprograms,
}: {
  subprograms: CartItemOptionsSubprogramsDetail[];
}) {
  return (
    <S.DivisionList>
      {subprograms.map(({ subprogramName, registrations, subprogramId }) => (
        <Chip
          label={subprogramName}
          badge={registrations.length}
          key={subprogramId}
        />
      ))}
    </S.DivisionList>
  );
}
/* */

/* ActionButtons */
function ActionButtons({
  editUrl,
  isShowingAdditionalButtons,
  showDetails,
  showDetailsHandler,
  openRemoveItemModal,
}: ActionButtonsProps) {
  const navigate = useNavigate();
  return (
    <S.ActionButtons>
      <S.MainButtonContainer>
        <ViewDetailsButton
          showDetails={showDetails}
          showDetailsHandler={showDetailsHandler}
        />
      </S.MainButtonContainer>
      {isShowingAdditionalButtons ? (
        <S.AdditionalButtonContainer>
          <Button
            variant="text"
            size="medium"
            onClick={() => navigate(editUrl)}
          >
            Edit
          </Button>
          <Button variant="text" size="medium" onClick={openRemoveItemModal}>
            Remove
          </Button>
        </S.AdditionalButtonContainer>
      ) : null}
    </S.ActionButtons>
  );
}
/* */

/*ViewDetailsButton */
function ViewDetailsButton({
  showDetails,
  showDetailsHandler,
}: {
  showDetails: boolean;
  showDetailsHandler: () => void;
}) {
  return (
    <Button
      leftIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
      size="medium"
      variant="outline"
      onClick={showDetailsHandler}
    >
      {showDetails ? 'Hide Details' : 'Show Details'}
    </Button>
  );
}
/* */

/*ProgramRegistrationsTable */
function ProgramRegistrationsTable({
  subprograms,
}: {
  subprograms: CartItemOptionsSubprogramsDetail[];
}) {
  return (
    <S.ProgramRegistrationsTable aria-label="Program Registrations">
      <ProgramRegistrationsTableHeader headers={DIVISION_TABLE_HEADER} />
      <ProgramRegistrationsTableBody subprograms={subprograms} />
    </S.ProgramRegistrationsTable>
  );
}
/* */

/*ProgramRegistrationsTableHeader */
function ProgramRegistrationsTableHeader({ headers }: { headers: string[] }) {
  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
}
/* */

/*ProgramRegistrationsTableBody */
function ProgramRegistrationsTableBody({
  subprograms,
}: {
  subprograms: CartItemOptionsSubprogramsDetail[];
}) {
  return (
    <tbody>
      {subprograms.map(
        ({ subprogramId, subprogramName, basePrice, registrations }) => (
          <ProgramRegistrationsTableRows
            key={subprogramId}
            subprogramName={subprogramName}
            teams={registrations}
            totalCost={basePrice}
          />
        )
      )}
    </tbody>
  );
}
/* */

/*ProgramRegistrationsTableRows */
function ProgramRegistrationsTableRows({
  subprogramName,
  teams,
  totalCost,
}: {
  subprogramName: string;
  teams: CartItemOptionsSubprogramsRegistration[];
  totalCost: string;
}) {
  return (
    <>
      {teams.map(({ registrationId, teamName }) => (
        <tr key={registrationId}>
          <td>{subprogramName}</td>
          <td>{teamName}</td>
          <td>${totalCost}</td>
        </tr>
      ))}
    </>
  );
}
/* */
