import { ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { formatDateRange } from 'lib/utils/dateUtils';
import { MainContentHeader } from 'domains/Shell/MainContent/MainContentHeader/MainContentHeader';
import { MainContentHeaderBackButton } from 'domains/Shell/MainContent/MainContentHeaderBackButton/MainContentHeaderBackButton';
import { MainContentHeaderTitle } from 'domains/Shell/MainContent/MainContentHeaderTitle/MainContentHeaderTitle';
import * as S from './WizardHeader.styles';

export type WizardHeaderProps = {
  endDate?: DateTime;
  location?: string;
  name: string;
  startDate: DateTime;
  title?: string;
  composedHeader?: ReactNode;
};

/* WizardHeader */
export function WizardHeader({
  endDate,
  location,
  name,
  startDate,
  title,
  composedHeader,
}: WizardHeaderProps) {
  const navigate = useNavigate();
  const { tournamentId } = useParams();

  let formattedDate = formatDateRange(startDate, endDate);

  return (
    <>
      <MainContentHeader>
        <MainContentHeaderBackButton
          onClick={() => {
            navigate(`/app/tournaments/${tournamentId}`);
          }}
        />
        <MainContentHeaderTitle>
          {title ?? 'Registration'}
        </MainContentHeaderTitle>
      </MainContentHeader>
      {composedHeader ? (
        composedHeader
      ) : (
        <S.Info>
          {name}: {formattedDate} {location ? `at ${location}` : null}
        </S.Info>
      )}
    </>
  );
}
/* */
