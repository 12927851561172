import { PropsWithChildren, useEffect } from 'react';
import { Loader } from '@la/ds-ui-components';
import { MainContentCenter } from 'domains/Shell/MainContent/MainContent';
import { getUserIsAuthenticated, requestLIPToken } from './auth';

export const AUTH_LOADER_DESCRIPTION = 'You must log in to view this page.';

/**
 * RequireAuth
 * Used to wrap an element rendered by a Route that requires authentication.
 */
export function RequireAuth({ children }: PropsWithChildren) {
  const isAuthenticated = getUserIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      requestLIPToken();
    }
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <MainContentCenter>
      <Loader
        isFullscreen
        loading
        title="Redirecting"
        description={AUTH_LOADER_DESCRIPTION}
      />
    </MainContentCenter>
  );
}
/* */
