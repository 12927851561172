import {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { DateTime } from 'luxon';
import Calendar from 'components/Calendar/Calendar';
import Portal from 'components/Portal/Portal';
import TextField, { TextFieldProps } from 'components/TextField/TextField';
import {
  detectOutsideEvent,
  getFocusableChildren,
  getScrollableParent,
} from 'lib/utils/utilities';
import { CalendarOrigin, CalendarPosition } from './DatePicker.styles';
import * as S from './DatePicker.styles';

export type DefaultDatePickerInputProps = TextFieldProps;

const DEFAULT_INPUT_PROPS: DefaultDatePickerInputProps = {
  name: 'date',
  id: 'date',
  label: '',
};

type InputProps = {
  'aria-label': string;
  'aria-describedby': string;
  error?: boolean;
  isCalendarOpen: boolean;
  toggleCalendar: () => void;
};

export type DatePickerProps = {
  /**
   * The selected date. Displays in the input, and the calendar
   * will first open in the month of the selected date.
   */
  date?: string;
  /**
   * Props that can be provided to the default
   * input the DatePicker provides.
   */
  defaultInputProps?: DefaultDatePickerInputProps;
  /**
   * Whether or not there is an error with the date selection.
   * If true, a red border will appear on the input and calendar icon will be red.
   * Defaults to `false`.
   */
  error?: boolean;
  /**
   * The format to display the date in for the input.
   * Must be a valid JS Date format to work properly (e.g. MMM dd, yyyy).
   */
  format?: string;
  /**
   * Whether or not the `DatePicker` should have a
   * width of 100%. Affects the input of the `DatePicker`.
   * Defaults to `false`.
   */
  fullWidth?: boolean;
  /**
   * Input that acts as trigger for the `Calendar`. When not provided,
   * defaults to a `TextField`.
   */
  input?: (date: string, props: InputProps) => ReactNode;
  /**
   * Whether or not to outline the current day on the calendar.
   * Defaults to `true`.
   */
  isShowingToday?: boolean;
  /**
   * Handler for when a date is selected on the calendar.
   */
  onDateSelect?: (date?: DateTime) => void;
  /**
   * Text to appear in the DatePicker if no date has been selected.
   * Defaults to `Select a date`
   */
  placeholderText?: string;
  /**
   * Whether or not the calendar should be closed upon
   * date selection. Defaults to `true`.
   */
  shouldCloseOnSelect?: boolean;
};

function getDefaultInput(
  defaultInputProps: DefaultDatePickerInputProps,
  onDateSelect: (date?: DateTime) => void,
  placeholderText?: string,
  ref?: Ref<HTMLInputElement>
) {
  return function (
    formattedDate: string,
    { error, isCalendarOpen, toggleCalendar }: InputProps
  ) {
    function handleKeyDown(event: KeyboardEvent): void {
      const { key } = event;
      if (key === 'Backspace') {
        onDateSelect();
      }
    }
    return (
      <S.DatePickerTextField $isOpen={isCalendarOpen} onKeyDown={handleKeyDown}>
        <TextField
          {...defaultInputProps}
          fullWidth
          icon={
            <div style={{ marginTop: '-4px' }}>
              <S.DateIcon $error={error} $isOpen={isCalendarOpen} />
            </div>
          }
          inputRef={ref}
          multiline={false}
          onIconClick={toggleCalendar}
          onClick={toggleCalendar}
          placeholder={placeholderText}
          readOnly
          value={formattedDate}
          error={error}
        />
      </S.DatePickerTextField>
    );
  };
}

/* DatePicker */
export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      date = '',
      defaultInputProps = DEFAULT_INPUT_PROPS,
      error = false,
      format = 'EEEE, MMMM dd',
      fullWidth = false,
      input,
      isShowingToday = true,
      onDateSelect = () => {},
      placeholderText = 'Select a date',
      shouldCloseOnSelect = true,
    },
    ref?
  ) => {
    const calendarTimeout = useRef<NodeJS.Timeout>();
    const datePickerInput =
      input ??
      getDefaultInput(defaultInputProps, onDateSelect, placeholderText, ref);
    const selectedDate: DateTime = DateTime.fromISO(date);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const [calendarPosition, setCalendarPosition] = useState<CalendarPosition>({
      $left: 0,
      $origin: 'top',
      $top: 0,
    });
    const [calendarAnimationDirection, setCalendarAnimationDirection] =
      useState<S.CalendarTransitionDirection | undefined>();
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

    useEffect(() => {
      return () => clearTimeout(calendarTimeout.current);
    }, []);

    useEffect(() => {
      updatePosition();

      const datePicker = datePickerRef.current;
      const calendar = calendarRef.current;
      if (!!datePicker && !!calendar) {
        const parent = getScrollableParent(datePicker);

        const scrollElements = [window, parent];
        const positionEvents = ['scroll', 'resize'];

        scrollElements.forEach((el) => {
          positionEvents.forEach((e) =>
            el.addEventListener(e, updatePosition, { passive: true })
          );
        });

        const outsideClickHandler = (e: MouseEvent): void =>
          detectOutsideEvent(e, [datePicker, calendar], () =>
            animateCalendar(false)
          );
        window.addEventListener('pointerdown', outsideClickHandler);

        return () => {
          scrollElements.forEach((el) => {
            positionEvents.forEach((e) =>
              el.removeEventListener(e, updatePosition)
            );
          });
          window.removeEventListener('pointerdown', outsideClickHandler);
        };
      }
    }, [isCalendarOpen]);

    function animateCalendar(isOpen: boolean) {
      setCalendarAnimationDirection(isOpen ? 'open' : 'close');

      clearTimeout(calendarTimeout.current);
      calendarTimeout.current = setTimeout(() => {
        if (!isOpen) {
          setCalendarAnimationDirection(undefined);
        }
        setIsCalendarOpen(isOpen);

        if (!isOpen && !!datePickerRef.current) {
          const children = getFocusableChildren(datePickerRef.current);
          if (children.length > 0) {
            children[0].focus({ preventScroll: true });
          }
        }
      }, S.CALENDAR_TRANSITION_DURATION);
    }

    function updatePosition(): void {
      const datePicker = datePickerRef.current;
      const calendar = calendarRef.current;
      if (!!datePicker && !!calendar) {
        const datePickerRect = datePicker.getBoundingClientRect();

        const topAnchor = datePickerRect.top + window.scrollY;
        const bottomAnchor = topAnchor + datePickerRect.height;
        const centerAnchor =
          datePickerRect.left +
          window.scrollX +
          datePickerRect.width / 2 -
          calendar.clientWidth / 2;

        let shouldAnchorAbove;
        if (
          Math.max(topAnchor, window.outerHeight - bottomAnchor) <
          calendar.clientHeight
        ) {
          const parent = getScrollableParent(datePicker) as HTMLElement;
          shouldAnchorAbove = topAnchor > parent.scrollHeight - bottomAnchor;
        } else {
          shouldAnchorAbove =
            window.outerHeight - bottomAnchor >= calendar.clientHeight
              ? false
              : topAnchor > window.outerHeight - bottomAnchor;
        }

        const top: number = shouldAnchorAbove
          ? topAnchor - calendar.clientHeight
          : bottomAnchor;
        const origin: CalendarOrigin = shouldAnchorAbove ? 'bottom' : 'top';

        setCalendarPosition({
          $left: Math.floor(centerAnchor) > 20 ? Math.floor(centerAnchor) : 20,
          $top: Math.floor(top),
          $origin: origin,
        });
      }
    }

    const inputProps: InputProps = useMemo(
      () => ({
        'aria-describedby': 'datepicker-date-format',
        'aria-label':
          !!selectedDate && selectedDate.isValid
            ? `change date, ${selectedDate.toISODate()}`
            : 'choose date',
        isCalendarOpen,
        error,
        toggleCalendar: () => animateCalendar(!isCalendarOpen),
      }),
      [error, selectedDate, isCalendarOpen]
    );

    return (
      <S.DatePicker $fullWidth={fullWidth} ref={datePickerRef}>
        <S.DatePickerInput $fullWidth={fullWidth}>
          {datePickerInput(
            selectedDate.isValid ? selectedDate.toFormat(format) : '',
            inputProps
          )}
          <S.InputFormat id={inputProps['aria-describedby']}>
            date format: "{format}"
          </S.InputFormat>
        </S.DatePickerInput>
        <Portal parentId="body">
          {isCalendarOpen ? (
            <S.CalendarPositionContainer
              data-testid="datepicker-calendar-container"
              style={{
                transform: `translate3d(${calendarPosition.$left}px, ${calendarPosition.$top}px, 0)`,
              }}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                  animateCalendar(false);
                }
              }}
            >
              <S.CalendarContainer
                $direction={calendarAnimationDirection}
                $origin={calendarPosition.$origin}
                key={calendarAnimationDirection}
                ref={calendarRef}
              >
                <Calendar
                  date={selectedDate.isValid ? selectedDate : DateTime.now()}
                  isShowingToday={isShowingToday}
                  onDateSelect={(newDate) => {
                    onDateSelect(newDate);
                    if (shouldCloseOnSelect) {
                      animateCalendar(false);
                    }
                  }}
                  selectedDate={selectedDate.isValid ? selectedDate : undefined}
                />
              </S.CalendarContainer>
            </S.CalendarPositionContainer>
          ) : null}
        </Portal>
      </S.DatePicker>
    );
  }
);
/* */
