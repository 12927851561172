import styled from 'styled-components/macro';

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const MainContentCenter = styled(MainContent)`
  justify-content: center;
`;
