import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Activity } from './openapi-client/models';
import { UpdateActivityData } from './utils/ActivityFormSubmit.utils';

export type RecurringActivityType = 'all' | 'one' | undefined;

export enum ActivityFormWrapperScreens {
  ActivityForm,
  CancelActivity,
  DeleteActivity,
  EditRecurringActivity,
  Loading,
  NotificationEditActivity,
  Submitted,
  UncancelActivity,
}

export type ActivityFormSubmittedData = {
  activity?: Activity[];
  errorMessage?: string;
  numberOfDeletedActivities?: number;
  submitType?: 'canceled' | 'created' | 'deleted' | 'updated' | 'uncanceled';
  willNotify?: boolean;
};

export type ActivityFormData = {
  activities: Activity[];
  setActivities: (activities: Activity[]) => void;
  activityFormTitle: string;
  setActivityFormTitle: (title: string) => void;
  currentScreen: ActivityFormWrapperScreens;
  setCurrentScreen: (screen: ActivityFormWrapperScreens) => void;
  editRecurringActivityType: RecurringActivityType;
  setEditRecurringActivityType: (type: RecurringActivityType) => void;
  submittedForm: ActivityFormSubmittedData;
  setSubmittedForm: (data: ActivityFormSubmittedData) => void;
  updateActivityData?: UpdateActivityData;
  setUpdateActivityData: (data: UpdateActivityData) => void;
};

const activityFormDefaults: ActivityFormData = {
  activities: [],
  setActivities: (activities: Activity[]) => {},
  activityFormTitle: '',
  setActivityFormTitle: (title: string) => {},
  currentScreen: ActivityFormWrapperScreens.Loading,
  setCurrentScreen: (screen: ActivityFormWrapperScreens) => {},
  editRecurringActivityType: undefined,
  setEditRecurringActivityType: (type: RecurringActivityType) => {},
  submittedForm: {},
  setSubmittedForm: (data: ActivityFormSubmittedData) => {},
  updateActivityData: undefined,
  setUpdateActivityData: (data: UpdateActivityData) => {},
};

const ActivityFormContext =
  createContext<ActivityFormData>(activityFormDefaults);

const ActivityFormProvider = ({ children }: PropsWithChildren) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityFormTitle, setActivityFormTitle] = useState('');
  const [currentScreen, setCurrentScreen] = useState(
    ActivityFormWrapperScreens.Loading
  );
  const [editRecurringActivityType, setEditRecurringActivityType] =
    useState<RecurringActivityType>();
  const [submittedForm, setSubmittedForm] = useState<ActivityFormSubmittedData>(
    {}
  );
  const [updateActivityData, setUpdateActivityData] =
    useState<UpdateActivityData>();

  return (
    <ActivityFormContext.Provider
      value={{
        activities,
        setActivities,
        activityFormTitle,
        setActivityFormTitle,
        currentScreen,
        setCurrentScreen,
        editRecurringActivityType,
        setEditRecurringActivityType,
        submittedForm,
        setSubmittedForm,
        updateActivityData,
        setUpdateActivityData,
      }}
    >
      {children}
    </ActivityFormContext.Provider>
  );
};

const useActivityForm = () => {
  const {
    activities,
    setActivities,
    activityFormTitle,
    setActivityFormTitle,
    currentScreen,
    setCurrentScreen,
    editRecurringActivityType,
    setEditRecurringActivityType,
    submittedForm,
    setSubmittedForm,
    updateActivityData,
    setUpdateActivityData,
  } = useContext<ActivityFormData>(ActivityFormContext);

  function handleSetActivities(activities: Activity[]) {
    setActivities(activities);
  }

  function handleSetActivityFormTitle(title: string) {
    setActivityFormTitle(title);
  }

  function handleSetCurrentScreen(screen: ActivityFormWrapperScreens) {
    setCurrentScreen(screen);
  }

  function handleSetRecurringActivityType(type: RecurringActivityType) {
    setEditRecurringActivityType(type);
  }

  function handleSetSubmittedForm(data: ActivityFormSubmittedData) {
    setSubmittedForm(data);
  }

  function handleSetUpdateActivityData(data: UpdateActivityData) {
    setUpdateActivityData(data);
  }

  return {
    activities,
    handleSetActivities: handleSetActivities,
    activityFormTitle: activityFormTitle,
    handleSetActivityFormTitle: handleSetActivityFormTitle,
    currentScreen: currentScreen,
    handleSetCurrentScreen: handleSetCurrentScreen,
    editRecurringActivityType: editRecurringActivityType,
    handleSetRecurringActivityType: handleSetRecurringActivityType,
    submittedForm: submittedForm,
    handleSetSubmittedForm: handleSetSubmittedForm,
    updateActivityData: updateActivityData,
    handleSetUpdateActivityData: handleSetUpdateActivityData,
  };
};

export { ActivityFormProvider, useActivityForm };
