import styled from 'styled-components';
export const TextAreaContainer = styled.div.withConfig({
  componentId: "sc-hmmlch-0"
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});
export const CharacterCounterContainer = styled.div.withConfig({
  componentId: "sc-hmmlch-1"
})({
  alignSelf: 'flex-end'
});
export const TextAreaHorizontalContainer = styled.div.withConfig({
  componentId: "sc-hmmlch-2"
})(({
  theme,
  $isHorizontal
}) => {
  const {
    spacing
  } = theme;
  return {
    marginTop: $isHorizontal ? spacing.xl : 0
  };
});