import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const CardContainer = styled.div`
  display: grid;
  gap: 8px;
`;

export const Card = styled.div<{ $hasError?: boolean }>`
  border: ${({ $hasError }) =>
    $hasError ? '1px solid var(--red)' : '1px solid var(--primary-400)'};
  border-radius: 4px;
  background-color: var(--white);

  // This must be set to override the default value of 'auto' which
  // allows very wide content to make the Card overflow its container.
  min-width: 0;
`;

export const CardHeader = styled.div`
  background-color: var(--primary-35);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 12px 16px;
`;

export const CardTitle = styled.span`
  font: var(--headline-xtra-small);
  margin: 0;

  ${media.TabletPortraitUp`
    font: var(--headline-small);
  `}
`;

export const CardBody = styled.div`
  padding: 12px 12px 12px 28px;
`;

export const CardFooter = styled.div`
  padding: 12px;
`;

export const CardError = styled.div`
  margin-left: 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    fill: var(--red);
  }
`;

export const CardErrorMessage = styled.p`
  font: var(--ui-small-regular);
  color: var(--red);
`;
