import { Division } from 'redux/services/types/tournament';
import { getDivisionCost } from 'domains/Tournaments/TournamentDetailsPage/utils/cost';
import {
  DivisionCardState,
  DivisionCardsState,
  DivisionSaved,
  DivisionSavedAndEditing,
} from '../../../Wizard.types';

const hasSavedTeams = (
  divisionState: DivisionCardState
): divisionState is DivisionSaved | DivisionSavedAndEditing => {
  return ['saved', 'saved-and-editing'].includes(divisionState.value);
};

export const calculateTotalCost = (
  divisionCardStates: DivisionCardsState,
  divisions: Division[]
): number => {
  return Object.entries(divisionCardStates).reduce(
    (total, [id, divisionCardState]) => {
      if (hasSavedTeams(divisionCardState)) {
        const division = divisions.find((d) => d.id === id);
        if (!!division) {
          const { savedTeams } = divisionCardState;
          return total + getDivisionCost(division) * savedTeams.length;
        }
      }
      return total;
    },
    0
  );
};
