import styled from 'styled-components/macro';
import { SiteLogo } from '../SiteLogo/SiteLogo.styles';

export const MobileNavigation = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  top: 0;
  ${({ $isVisible }) => ($isVisible ? 'right: 0' : 'left: 390px')};
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 390px;
  background: var(--white);
  border-top: 32px solid var(--primary);
  overflow: auto;
`;

export const MobileNavigationContainer = styled.div`
  flex: 1 0 auto;
`;

export const MobileMenuHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 10px;
  border-bottom: 1px solid var(--primary);
  background-color: white;

  ${SiteLogo} {
    position: absolute;
    left: 10px;
  }
`;
export const MobileNavigationFooter = styled.div`
  position: sticky;
  bottom: 0;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
`;

export const CloseButtonContainer = styled.div`
  margin-left: 12px;
`;
