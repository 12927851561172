import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type LaunchDarklyPageAccessProps = PropsWithChildren<{
  featureFlagName: string;
}>;

/* LaunchDarklyPageAccess */
export default function LaunchDarklyPageAccess({
  children,
  featureFlagName,
}: LaunchDarklyPageAccessProps) {
  const flags = useFlags();
  const featureFlag = flags[featureFlagName];

  return featureFlag ? <>{children}</> : <Navigate to="/404" />;
}
