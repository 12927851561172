import styled from 'styled-components';
import { Typography } from 'components/Typography/Typography';
export const FileInputContainer = styled.div.withConfig({
  componentId: "sc-xyosps-0"
})(({
  theme,
  $alignment,
  $isHovered,
  $hasError
}) => {
  const {
    blueGrey,
    red
  } = theme.foundational;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: $alignment === 'left' ? 'flex-start' : 'center',
    gap: theme.spacing.small,
    padding: theme.spacing.small,
    border: '1px dashed',
    borderColor: $hasError ? red[500] : blueGrey[400],
    backgroundColor: $isHovered ? blueGrey[35] : 'transparent',
    minHeight: 98
  };
});
export const FileInputInput = styled.input.withConfig({
  componentId: "sc-xyosps-1"
})({
  display: 'none'
});
export const DescriptionContainer = styled.div.withConfig({
  componentId: "sc-xyosps-2"
})(({
  theme
}) => {
  return {
    display: 'flex',
    gap: theme.spacing.xxs,
    alignItems: 'center'
  };
});
export const InfoText = styled(Typography).withConfig({
  componentId: "sc-xyosps-3"
})({
  flex: 1
});
export const ErrorMessageContainer = styled.div.withConfig({
  componentId: "sc-xyosps-4"
})(({
  theme
}) => {
  const {
    red
  } = theme.foundational;
  return {
    display: 'flex',
    gap: theme.spacing.xxxs,
    marginTop: theme.spacing.xxxs,
    '& p': {
      color: red[500]
    }
  };
});
export const FilesListArea = styled.div.withConfig({
  componentId: "sc-xyosps-5"
})(({
  theme
}) => {
  return {
    marginTop: theme.spacing.large
  };
});
export const FilesTagsArea = styled.div.withConfig({
  componentId: "sc-xyosps-6"
})(({
  theme
}) => {
  return {
    marginTop: theme.spacing.xxs,
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing.xxs
  };
});