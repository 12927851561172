import { Button } from '@la/ds-ui-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { RolloverTeam, RolloverTeamType } from './RolloverTeam/RolloverTeam';
import * as S from './RolloverDropzone.styles';

/* OriginRolloverDropzone */
export const NO_TEAM_SELECTED_LABEL = 'Select a team to get started!';

export type OriginRolloverDropzoneProps = {
  /**
   * Triggered when a member is no longer being dragged from the dropzone.
   */
  onDragEnd: () => void;
  /**
   * Triggered when a member starts being dragged from the dropzone.
   */
  onDragStart: () => void;
  /**
   * When the user clicks a trigger for the team selection modal.
   */
  onTeamSelectionClick: () => void;
  /**
   * Team roster the user is rolling members from. Can be undefined.
   */
  teamRoster?: TeamRoster;
};

export function OriginRolloverDropzone({
  onDragEnd,
  onDragStart,
  onTeamSelectionClick,
  teamRoster,
}: OriginRolloverDropzoneProps) {
  return (
    <S.Dropzone
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      $hasSelectedTeam={!!teamRoster}
    >
      {teamRoster ? (
        <RolloverTeam
          onTeamSelectionClick={onTeamSelectionClick}
          showTeamSelection={true}
          teamRoster={teamRoster}
          type={RolloverTeamType.Origin}
        />
      ) : (
        <Button onClick={onTeamSelectionClick} size="large" variant="primary">
          {NO_TEAM_SELECTED_LABEL}
        </Button>
      )}
    </S.Dropzone>
  );
}
/* */
