import styled from 'styled-components/macro';
import { Form, ThemedComponent } from '@la/ds-ui-components';

export const PaymentOptionsModalItems = styled(Form)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
`;

export const PaymentOptionsModalItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

type PaymentOptionsModalRowProps = ThemedComponent<{ $showDivider?: boolean }>;
export const PaymentOptionsModalRow = styled.div<PaymentOptionsModalRowProps>(
  ({ $showDivider, theme }: PaymentOptionsModalRowProps) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: $showDivider ? '4px' : 0,
    paddingBottom: $showDivider ? '4px' : 0,
    borderBottom: $showDivider
      ? `1px solid ${theme.semantic?.primary[100]}`
      : 'none',
  })
);

export const PaymentOptionsModalItemHeader = styled(PaymentOptionsModalRow)`
  flex-direction: row;
  justify-content: space-between;
`;

export const DiscountAmount = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'inline-block',
    color: theme.foundational.blueGrey[900],
    margin: '0 16px 0 auto',
  })
);

type FullAmountProps = ThemedComponent<{ $hasDiscount?: boolean }>;

export const FullAmount = styled.span<FullAmountProps>(
  ({ $hasDiscount, theme }: FullAmountProps) => ({
    ...($hasDiscount
      ? {
          color: $hasDiscount
            ? theme.foundational.blueGrey[600]
            : theme.foundational.blueGrey[400],
          textDecorationLine: 'line-through',
        }
      : {}),
  })
);

export const PaymentOptionsModalItemPaymentOptions =
  styled.div<ThemedComponent>(({ theme }: ThemedComponent) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    input: {
      cursor: 'pointer',
    },

    label: {
      ...theme.typography.ui.large,
      color: theme.foundational.blueGrey[900],
    },
  }));

export const PaymentOptionModalItemRadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const PaymentOptionModalItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const PaymentOptionModalItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > div {
    &:first-child {
      overflow: hidden;
      label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &:nth-child(2) {
      white-space: nowrap;
    }
  }
`;

export const PaymentOptionModalItemDetailsButton = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    flexShrink: 0,

    '&, &:hover, &:active': {
      span: {
        background: 'none',

        svg: {
          fill: theme.semantic?.secondary[600],
        },
      },
    },
  })
);

export const PaymentOptionDetailsSummary = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    color: theme.foundational.blueGrey[600],
    marginLeft: '30px',
  })
);

export const PaymentOptionDetailsSchedule = styled.div`
  margin: 16px 0;

  th:last-child {
    text-align: right;
  }
`;

export const PayLaterCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
