import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Button,
  CaretLeftIcon,
  Loader,
  PlusCircleIcon,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { getSiteId } from 'redux/coreSlice';
import {
  useCreateExternalTeamMutation,
  useGetStaffTeamsQuery,
} from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import { getUserId } from 'redux/userSlice';
import {
  TeamPrimaryStaffSelectOptions,
  TeamWithProps,
} from '../utils/ActivityForm.utils';
import * as S from './ExternalTeamSelect.styles';

type ExternalTeamSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  selectedSubProgramId: string;
  teams: TeamPrimaryStaffSelectOptions[];
  value?: string;
};

export const ExternalTeamSelect = forwardRef<
  HTMLDivElement,
  ExternalTeamSelectProps
>(({ onChange, onClose, selectedSubProgramId, teams, value }, ref?) => {
  const [showCreateExternalTeam, setShowCreateExternalTeam] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnSelect = (team?: string) => {
    onChange(team);
    onClose(team);
  };

  const handleOnBack = (teamId?: string) => {
    setShowCreateExternalTeam(false);

    if (teamId) {
      setSelectedValue(teamId);
      onChange(teamId);
      onClose(teamId);
    }
  };

  return (
    <div>
      {!showCreateExternalTeam ? (
        <>
          <S.TeamOptionContainer
            role="option"
            aria-selected="false"
            onClick={() => setShowCreateExternalTeam(true)}
          >
            <S.ExternalTeamOption>
              <S.ExternalTeamOptionLink>
                <PlusCircleIcon fill="#007A00" variant="regular" /> Create a new
                external team
              </S.ExternalTeamOptionLink>
            </S.ExternalTeamOption>
          </S.TeamOptionContainer>

          {teams.map((team) => {
            return (
              <S.TeamOptionContainer
                role="option"
                aria-disabled={team.disabled}
                aria-selected={selectedValue === team.value ? true : false}
                onClick={() => handleOnSelect(team.value)}
                key={team.value}
              >
                <S.ExternalTeamOption $isDisabled={team.disabled}>
                  <Typography variant="ui" size="large" weight="regular">
                    {team.label}
                  </Typography>
                  <Typography
                    variant="ui"
                    size="medium"
                    weight="regular"
                    italic
                  >
                    {team.primaryStaffName}
                  </Typography>
                </S.ExternalTeamOption>
              </S.TeamOptionContainer>
            );
          })}
        </>
      ) : (
        <CreateExternalTeam
          onBack={handleOnBack}
          selectedSubProgramId={selectedSubProgramId}
        />
      )}
    </div>
  );
});

type CreateExternalTeamProps = {
  onBack: (value?: string) => void;
  selectedSubProgramId: string;
};
export const CreateExternalTeam = forwardRef<
  HTMLDivElement,
  CreateExternalTeamProps
>(({ onBack, selectedSubProgramId }, ref?) => {
  const siteId = useAppSelector(getSiteId);

  const externalTeamNameRef = useRef<HTMLInputElement>(null);
  const [inputHasError, setInputHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [createExternalTeam] = useCreateExternalTeamMutation();
  const userId = useAppSelector(getUserId);
  const { isFetching: isFetchingTeams } = useGetStaffTeamsQuery(
    { userId },
    { skip: !userId ? true : false }
  );

  const [selectedValue, setSelectedValue] = useState<string>();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    // show the loading spinner until request is done & new teams have been fetched
    // in order to show the new external team as the seleced value
    if (selectedValue && !isFetchingTeams && !inputHasError) {
      setisLoading(false);
      onBack(selectedValue);
    }
  }, [inputHasError, isFetchingTeams, onBack, selectedValue]);

  const createNewTeam = () => {
    const teamName = externalTeamNameRef.current?.value;

    if (!teamName) {
      setErrorMessage('Team name is required');
      setInputHasError(true);
    } else {
      setisLoading(true);
      createExternalTeam({
        siteId: Number(siteId),
        externalTeam: {
          name: teamName,
          programId: parseInt(selectedSubProgramId, 10),
        },
      })
        .unwrap()
        .then((data: TeamWithProps) => {
          setSelectedValue(data.id?.toString());
          setInputHasError(false);
        })
        .catch((e: any) => {
          setErrorMessage(
            e?.data ?? 'An unexpected error has occured. Please try again.'
          );
          setInputHasError(true);
          setisLoading(false);
        });
    }
  };

  return (
    <S.TeamOptionContainer role="option" aria-selected="false" $noHover={true}>
      <S.ExternalTeamOption>
        <S.ExternalTeamOptionLink onClick={() => onBack()}>
          <CaretLeftIcon fill="#007A00" variant="regular" /> Back to team
          selection
        </S.ExternalTeamOptionLink>
        <S.AddNewTeamInput>
          <TextInput
            ref={externalTeamNameRef}
            id="new-external-team"
            label="External team name"
            placeholder="Add external team name"
            required
            hasError={inputHasError}
            errorMessage={errorMessage}
          />
          <Button size="large" onClick={createNewTeam}>
            {!isLoading ? <>Add Team</> : <Loader loading />}
          </Button>
        </S.AddNewTeamInput>
      </S.ExternalTeamOption>
    </S.TeamOptionContainer>
  );
});
