import styled from 'styled-components/macro';

export const MainNavigation = styled.div`
  display: inline-flex;
`;

export const ShoppingCartContainer = styled.div`
  margin-left: 12px;
`;

export const HamburgerButtonContainer = styled.div`
  margin-left: 10px;
`;
