import axios from 'axios';
import { ErrorResponse } from 'lib/apis/verifyUrlSignature';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { Location } from '../openapi-client/models';

const baseUrl = getBaseURL();

const getLocations = async ({
  siteId,
  programId,
  pageNum,
  pageSize,
}: {
  siteId: string;
  programId: string;
  pageNum?: number;
  pageSize?: number;
}) => {
  try {
    return await axios.get<Location[]>(
      `${baseUrl}/api/sites/${siteId}/locations?detectAssociationProgramId=${programId}&pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${extractAccessToken()}`,
        },
      }
    );
  } catch (e: any) {
    const error: ErrorResponse = {
      response: {
        status: 400,
        data: {
          message: e?.data?.message ?? 'Error retrieving locations.',
        },
      },
    };
    throw error;
  }
};

export { getLocations };
