export const generateCondensedAccordionDynamicStyles = (theme, state) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: {
      blueGrey,
      green,
      white
    },
    semantic
  } = theme;
  const borderColor = {
    default: isDynamic ? semantic?.primary[400] : blueGrey[400],
    hover: isDynamic ? semantic?.primary[600] : green[800],
    active: isDynamic ? semantic?.primary[600] : green[900],
    disabled: blueGrey[50]
  };
  const backgroundColor = {
    default: isDynamic ? semantic?.primary[35] : white,
    hover: isDynamic ? semantic?.primary[35] : white,
    active: isDynamic ? semantic?.primary[50] : blueGrey[50],
    disabled: blueGrey[50]
  };
  const color = {
    default: blueGrey[900],
    hover: isDynamic ? blueGrey[900] : green[800],
    active: isDynamic ? blueGrey[900] : green[900],
    disabled: blueGrey[400]
  };
  const fill = {
    default: isDynamic ? blueGrey[900] : green[600],
    hover: isDynamic ? blueGrey[900] : green[800],
    active: isDynamic ? blueGrey[900] : green[900],
    disabled: blueGrey[400]
  };
  return {
    backgroundColor: backgroundColor[state],
    borderColor: borderColor[state],
    color: color[state],
    fill: fill[state],
    outlineColor: isDynamic ? blueGrey[600] : green[600]
  };
};