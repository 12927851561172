import React, { PropsWithChildren } from 'react';

type MainNavProps = {
  isNavigationCollapsed: boolean;
  isNavigationPanelVisible: boolean;
  updateIsMobileBreakpoint: (mobileBreakpointStatus: boolean) => void;
  updateIsNavigationPanelVisible: (navigationPanelIsVisible: boolean) => void;
  updateIsNavigationTooWide: (navigationIsTooWide: boolean) => void;
  updateVisibleNavMenu: (menuId: string) => void;
  visibleNavMenu: string;
};

const MainNavDefaultValues = {
  isMobileBreakpoint: true,
  isNavigationCollapsed: true,
  isNavigationPanelVisible: false,
  isNavigationTooWide: false,
  updateIsMobileBreakpoint: (mobileBreakpointStatus: boolean) => {},
  updateIsNavigationPanelVisible: (navigationPanelIsVisible: boolean) => {},
  updateIsNavigationTooWide: (navigationIsTooWide: boolean) => {},
  updateVisibleNavMenu: (menuId: string) => {},
  visibleNavMenu: '',
};

const MainNavigationContext =
  React.createContext<MainNavProps>(MainNavDefaultValues);

const MainNavigationProvider = ({ children }: PropsWithChildren) => {
  const [isMobileBreakpoint, setIsMobileBreakpoint] = React.useState<boolean>(
    MainNavDefaultValues.isMobileBreakpoint
  );
  const [isNavigationPanelVisible, setIsNavigationPanelVisible] =
    React.useState<boolean>(MainNavDefaultValues.isNavigationPanelVisible);
  const [isNavigationTooWide, setIsNavigationTooWide] = React.useState<boolean>(
    MainNavDefaultValues.isNavigationTooWide
  );
  const [visibleNavMenu, setVisibleNavMenu] = React.useState<string>(
    MainNavDefaultValues.visibleNavMenu
  );

  return (
    <MainNavigationContext.Provider
      value={{
        isNavigationCollapsed: isMobileBreakpoint || isNavigationTooWide,
        isNavigationPanelVisible: isNavigationPanelVisible,
        updateIsMobileBreakpoint: setIsMobileBreakpoint,
        updateIsNavigationPanelVisible: setIsNavigationPanelVisible,
        updateIsNavigationTooWide: setIsNavigationTooWide,
        updateVisibleNavMenu: setVisibleNavMenu,
        visibleNavMenu: visibleNavMenu,
      }}
    >
      {children}
    </MainNavigationContext.Provider>
  );
};

const useMainNavigation = () => {
  const {
    isNavigationCollapsed,
    isNavigationPanelVisible,
    updateIsMobileBreakpoint,
    updateIsNavigationPanelVisible,
    updateIsNavigationTooWide,
    updateVisibleNavMenu,
    visibleNavMenu,
  } = React.useContext<MainNavProps>(MainNavigationContext);

  const handleIsMobileBreakpointChange = (mobileBreakpointStatus: boolean) => {
    updateIsMobileBreakpoint(mobileBreakpointStatus);
  };

  const handleIsNavigationPanelTooWideChange = (
    navigationIsTooWide: boolean
  ) => {
    updateIsNavigationTooWide(navigationIsTooWide);
  };

  const handleNavigationPanelVisibilityChange = (
    navigationPanelVisibleStatus: boolean
  ) => {
    updateIsNavigationPanelVisible(navigationPanelVisibleStatus);
  };

  const handleNavMenuChange = (visibleNavMenuId: string) => {
    updateVisibleNavMenu(visibleNavMenuId);
  };

  return {
    isNavigationCollapsed: isNavigationCollapsed,
    isNavigationPanelVisible: isNavigationPanelVisible,
    updateIsMobileBreakpoint: handleIsMobileBreakpointChange,
    updateIsNavBarTooWide: handleIsNavigationPanelTooWideChange,
    updateNavPanelVisibility: handleNavigationPanelVisibilityChange,
    updateVisNavMenu: handleNavMenuChange,
    visibleNavMenu: visibleNavMenu,
  };
};

export { MainNavigationContext, MainNavigationProvider, useMainNavigation };
