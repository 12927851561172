import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const NAV_SECTION_MENU_WIDTH = 200;

export const NavSectionMenu = styled.div<{
  $navMenuIsMounted: boolean;
  $leftOffset: number;
}>`
  position: absolute;
  top: 34px;
  left: ${({ $leftOffset }) => `${$leftOffset}px`};
  display: ${({ $navMenuIsMounted }) => ($navMenuIsMounted ? 'flex' : 'none')};
  flex-direction: column;
  width: ${NAV_SECTION_MENU_WIDTH}px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-bottom-width: 10px;
  border-radius: 4px;
  a {
    border-bottom: 1px solid var(--secondary-100);
  }
  a:last-child {
    border: none;
  }
`;

export const NavSectionMenuItem = styled(Link)`
  padding: 12px 20px;
  font: var(--ui-medium-medium);
  color: var(--blue-grey-900);
  text-decoration: none;
  overflow-wrap: anywhere;
  white-space: break-spaces;

  &:focus,
  &:hover {
    background-color: var(--blue-grey-50);
    outline: none;
  }

  &:active {
    background-color: var(--blue-grey-100);
  }
`;
