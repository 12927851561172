import styled from 'styled-components/macro';
import { ReactComponent as LoadingRippleAnimationIcon } from 'assets/loading_ripple.svg';

export const LoadingBackdrop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 1;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  text-align: center;
`;

export const LoadingRippleAnimation = styled(LoadingRippleAnimationIcon)<{
  $hasMessage?: boolean;
}>`
  margin: ${({ $hasMessage }) => `auto auto ${$hasMessage ? '16px' : 'auto'}`}};
`;

export const LoadingMessage = styled.div`
  margin: 0 auto auto;
  font: var(--ui-large-regular);
`;
