/**
 * If a serialized version of the global store—-partially or in its
 * entirety—-has been stored in the browser's local storage this will
 * retrieve it and return a deserialized version of it as JSON.
 * @param {string} key
 * @returns {any}
 */
export function loadStateFromLocalStorage(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

/**
 * This method receives a key and a JSON object taken from the global store.
 * The JSON is serialized and stored in the browser's local storage. The key
 * passed to the function is used as key for the serialized JSON in local
 * storage.
 * @param {string} key
 * @param state
 * @returns {Promise<void>}
 */
export async function saveStateToLocalStorage(key: string, state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error(error);
  }
}

/**
 * clearStateFromLocalStorage removes any data from local storage that has a
 * key matching the key passed to it as an argument. In most cases it will be
 * called just prior to a user logging out so that the data from the global
 * store does not persist in local storage beyond the session.
 * @param {string} key
 * @returns {Promise<void>}
 */
export async function clearStateFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}
