import { useEffect, useState } from 'react';
import { breakpoints } from 'theming/grid';
const getBreakpointBasedOnWidth = width => {
  // SSR
  if (!width) return null;
  if (width > breakpoints.desktop - 1) return 'desktop';
  if (width > breakpoints.mobile - 1) return 'tablet';
  return 'mobile';
};
const useBreakpoint = (contextBreakpoint = null) => {
  const [breakpoint, setBreakpoint] = useState(() => {
    return contextBreakpoint ?? getBreakpointBasedOnWidth(window.innerWidth);
  });
  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpointBasedOnWidth(window.innerWidth));
    };

    // If already inside a context do not append any listener
    if (!contextBreakpoint) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contextBreakpoint]);
  return breakpoint;
};
export { useBreakpoint };