/* tslint:disable */

/* eslint-disable */

/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// May contain unused imports in some cases
// @ts-ignore
import { DayOfWeek } from './day-of-week';

/**
 *
 * @export
 * @interface ActivityRecurrence
 */
export interface ActivityRecurrence {
  /**
   * Time the participant arrives for the activity. Useful for preparation or pre-event tasks. 24 hour format.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  arrivalTime?: string;
  /**
   * Time the activity officially starts. 24 hour format.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  startTime: string;
  /**
   * Date when the recurrence pattern begins.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  startDate: string;
  /**
   * Time the activity ends. 24 hour format.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  endTime: string;
  /**
   * Date when the recurrence pattern ends. This date is inclusive.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  endDate: string;
  /**
   * Base unit for recurrence pattern: daily, weekly, or monthly.
   * @type {string}
   * @memberof ActivityRecurrence
   */
  frequencyUnit: ActivityRecurrenceFrequencyUnitEnum;
  /**
   * Multiplier for the frequencyUnit, determining how often the activity recurs. E.g., \'2\' with a frequencyUnit of \'week\' means every other week.
   * @type {number}
   * @memberof ActivityRecurrence
   */
  interval?: number;
  /**
   *
   * @type {Array<DayOfWeek>}
   * @memberof ActivityRecurrence
   */
  daysOfWeek?: Array<DayOfWeek>;
  /**
   * Specific day of the month when the activity occurs. Only relevant if frequencyUnit is \'month\'. If the specified day does not exist in a particular month (e.g., 31st in February), the activity will be adjusted to occur on the last day of that month (e.g., 28th or 29th for February).
   * @type {number}
   * @memberof ActivityRecurrence
   */
  dayOfMonth?: number;
  /**
   * Specific week of the month when the activity occurs. To be used in conjunction with dayOfWeek.
   * @type {number}
   * @memberof ActivityRecurrence
   */
  weekOfMonth?: number;
  /**
   *
   * @type {DayOfWeek}
   * @memberof ActivityRecurrence
   */
  dayOfWeek?: DayOfWeek;
}

/**
 * @export
 * @enum {string}
 */
export enum ActivityRecurrenceFrequencyUnitEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}
