"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStaffTeamsQuery = exports.useGetTeamsQuery = exports.useDeleteTeamMutation = exports.useUpdateTeamMutation = exports.useCreateTeamMutation = exports.teamApi = void 0;
var baseApi_1 = require("./baseApi");
exports.teamApi = baseApi_1.default.injectEndpoints({
    endpoints: function (builder) { return ({
        createTeam: builder.mutation({
            query: function (_a) {
                var siteDomain = _a.siteDomain, programId = _a.programId, team = _a.team, payerId = _a.payerId, siteId = _a.siteId;
                return getTeamPostPayload(siteDomain, programId, team, payerId, siteId);
            },
        }),
        updateTeam: builder.mutation({
            query: function (_a) {
                var siteDomain = _a.siteDomain, programId = _a.programId, team = _a.team, payerId = _a.payerId, siteId = _a.siteId;
                return getTeamPostPayload(siteDomain, programId, team, payerId, siteId);
            },
        }),
        deleteTeam: builder.mutation({
            query: function (_a) {
                var siteDomain = _a.siteDomain, programId = _a.programId, editURL = _a.editURL, teamId = _a.teamId, siteId = _a.siteId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/teams"),
                    method: 'DELETE',
                    body: {
                        programId: programId,
                        editURL: editURL,
                        teamId: teamId,
                    },
                    params: {
                        siteId: siteId,
                    },
                };
            },
        }),
        getTeams: builder.query({
            query: function (_a) {
                var siteDomain = _a.siteDomain, payerId = _a.payerId, siteId = _a.siteId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/teams"),
                    method: 'GET',
                    params: {
                        payerId: payerId,
                        siteId: siteId,
                    },
                };
            },
        }),
        getStaffTeams: builder.query({
            query: function (_a) {
                var userId = _a.userId;
                return {
                    url: "/api/users/".concat(userId, "/teams?role=staff&include=primaryStaff"),
                    method: 'GET',
                };
            },
        }),
    }); },
});
function getTeamPostPayload(siteDomain, programId, team, payerId, siteId) {
    return {
        url: "/api/member-portal/".concat(siteDomain, "/teams"),
        method: 'POST',
        body: {
            programId: programId,
            team: team,
            payerId: payerId,
        },
        params: {
            siteId: siteId,
            payerId: payerId,
        },
    };
}
exports.useCreateTeamMutation = exports.teamApi.useCreateTeamMutation, exports.useUpdateTeamMutation = exports.teamApi.useUpdateTeamMutation, exports.useDeleteTeamMutation = exports.teamApi.useDeleteTeamMutation, exports.useGetTeamsQuery = exports.teamApi.useGetTeamsQuery, exports.useGetStaffTeamsQuery = exports.teamApi.useGetStaffTeamsQuery;
