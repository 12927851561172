"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkArray = exports.detectOutsideEvent = exports.trapFocusWithin = exports.getScrollableParent = exports.getFocusableChildren = exports.copyTextToClipboard = exports.roundToNearest = exports.genderNameMap = exports.sortRecordByValue = exports.deepObjectCopy = exports.getCursorOffsetFromContainer = exports.formatAsUSD = exports.hasValue = void 0;
/* eslint-disable */
__exportStar(require("./url"), exports);
__exportStar(require("./auth"), exports);
__exportStar(require("./data-scraping"), exports);
__exportStar(require("./date"), exports);
__exportStar(require("./form-fields"), exports);
__exportStar(require("./string"), exports);
__exportStar(require("./select"), exports);
__exportStar(require("./cost"), exports);
__exportStar(require("./payment-plan"), exports);
__exportStar(require("./phone-number"), exports);
__exportStar(require("./registration"), exports);
__exportStar(require("./route"), exports);
__exportStar(require("./zip-code"), exports);
var hasValue = function (value) {
    return value !== '' && value !== undefined && value !== null;
};
exports.hasValue = hasValue;
/**
 * Given a number, returns a string representation of the value in USD.
 *
 * Examples:
 *
 *  displayCentsAsUSD(5.25) => $5.25
 *  displayCentsAsUSD(29999.99) => $29,999.99
 *
 * @param amount A number
 * @returns A string displaying the number in USD
 *
 */
function formatAsUSD(amount, options) {
    if ((options === null || options === void 0 ? void 0 : options.hideZeroCents) && amount % 1 === 0) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(amount);
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);
}
exports.formatAsUSD = formatAsUSD;
function getCursorOffsetFromContainer(cursorX, cursorY, target) {
    var boundingRect = target.getBoundingClientRect();
    var deltaX = cursorX - boundingRect.x;
    var deltaY = cursorY - boundingRect.y;
    return [deltaX, deltaY];
}
exports.getCursorOffsetFromContainer = getCursorOffsetFromContainer;
function deepObjectCopy(data) {
    return JSON.parse(JSON.stringify(data));
}
exports.deepObjectCopy = deepObjectCopy;
/**
 * Sorts a Record alphabetically by value.
 * @param values Key-value pairs
 * @returns Record with key-value pairs in alphabetical order
 */
function sortRecordByValue(values) {
    return Object.fromEntries(Object.entries(values).sort(function (a, b) { return a[1].localeCompare(b[1]); }));
}
exports.sortRecordByValue = sortRecordByValue;
exports.genderNameMap = {
    any: 'Any',
    co_ed: 'Co-Ed',
    male: 'Male',
    female: 'Female',
};
function roundToNearest(val, nearest) {
    var remainder = val % nearest;
    var valMinusRemainder = val - remainder;
    var roundedRemainder = remainder >= nearest * 0.5 ? nearest : 0;
    return valMinusRemainder + roundedRemainder;
}
exports.roundToNearest = roundToNearest;
function copyTextToClipboard(textToCopy, successHandler, errorHandler) {
    navigator.clipboard.writeText(textToCopy).then(function () {
        if (successHandler !== undefined) {
            successHandler();
        }
    }, function () {
        if (errorHandler !== undefined) {
            errorHandler();
        }
    });
}
exports.copyTextToClipboard = copyTextToClipboard;
function getFocusableChildren(parent) {
    var children = Array.from(parent.querySelectorAll(FOCUS_SELECTOR));
    return children.filter(function (el) { return !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'); });
}
exports.getFocusableChildren = getFocusableChildren;
function getScrollableParent(el) {
    if (!el || el === document.body) {
        return document.body;
    }
    else if (isScrollable(el)) {
        return el;
    }
    else {
        return getScrollableParent(el.parentNode);
    }
}
exports.getScrollableParent = getScrollableParent;
function isScrollable(el) {
    var hasScrollableContent = el.scrollHeight > el.clientHeight;
    var overflowYStyle = window.getComputedStyle(el).overflowY;
    var isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
    return hasScrollableContent && !isOverflowHidden;
}
var FOCUS_SELECTOR = 'a[href], button, input:not([type="hidden"]), textarea, select, details,[tabindex]:not([tabindex="-1"])';
function isFocusableElement(el) {
    return (el.matches(FOCUS_SELECTOR) &&
        !el.hasAttribute('disabled') &&
        !el.getAttribute('aria-hidden'));
}
function findNextFocusableElement(els) {
    var activeElementIndex = els.findIndex(function (el) { return el === document.activeElement; });
    return els.find(function (el, index) { return isFocusableElement(el) && index > activeElementIndex; });
}
function trapFocusWithin(event) {
    var key = event.key, shiftKey = event.shiftKey;
    var parent = event.currentTarget;
    var currentFocus = document.activeElement;
    if (key === 'Tab' && !!parent && !!currentFocus) {
        var hasFocusWithin = parent.contains(currentFocus);
        if (hasFocusWithin) {
            event.preventDefault();
            var focusableElements = getFocusableChildren(parent), children = Array.from(parent.querySelectorAll('*')).flat();
            if (shiftKey) {
                var nextFocus = findNextFocusableElement(children.reverse());
                if (!!nextFocus) {
                    nextFocus.focus();
                }
                else if (!!focusableElements.length) {
                    focusableElements[focusableElements.length - 1].focus();
                }
            }
            else {
                var nextFocus = findNextFocusableElement(children);
                if (!!nextFocus) {
                    nextFocus.focus();
                }
                else if (!!focusableElements.length) {
                    focusableElements[0].focus();
                }
            }
        }
    }
}
exports.trapFocusWithin = trapFocusWithin;
function detectOutsideEvent(event, els, onOutsideClick) {
    var _a;
    var path = (_a = event.composedPath()) !== null && _a !== void 0 ? _a : [];
    if (els.every(function (el) { return !path.includes(el); })) {
        onOutsideClick();
    }
}
exports.detectOutsideEvent = detectOutsideEvent;
/**
 * Returns a subset of length `n` from a larger array.
 * @param array The array to be splitted
 * @param chunkSize The maximum size each inner array should have
 * @returns A 1xN matrix where n <= chunkSize
 */
function chunkArray(array, chunkSize) {
    var chunkedArray = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}
exports.chunkArray = chunkArray;
