import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, formatAsCurrency } from '@la/ds-ui-components';
import { useLazyGetTournamentQuery } from '@la/services';
import { LoadingRipple } from 'components/LoadingRipple/LoadingRipple';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { setHotelLinks } from 'redux/checkoutSlice';
import { getSiteId } from 'redux/coreSlice';
import {
  useGetBillingSummaryMutation,
  useGetCartSummaryQuery,
  useGetPaymentWaiverQuery,
  useProcessCartMutation,
} from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { RollupButtonContainer } from '../CartSummaryRollup/CartSummaryRollup';
import {
  BillingSummaryRequestItems,
  PaymentTerm,
  ProcessPaymentBody,
} from '../Checkout.types';
import CheckoutBillingSummary from '../CheckoutBillingSummary/CheckoutBillingSummary';
import SecureCheckoutBadge from '../SecureCheckoutBadge/SecureCheckoutBadge';
import * as S from './CheckoutRollup.styles';

export type MakePaymentButtonProps = {
  clickHandler: () => void;
  price: string;
};

/* CheckoutRollup */
export default function CheckoutRollup() {
  const dispatch = useAppDispatch();
  const { subdomain } = getLAHostnameParts();
  const siteId = useAppSelector(getSiteId);
  const { data: userId } = useGetUserIdQuery(siteId);
  const {
    discountCodeNames,
    updateCheckoutErrorsAreVisible,
    updateErrorStates,
    updateSubmissionErrorsAreVisible,
    validateCheckout,
    selectedPaymentMethod,
    selectedPaymentMethodType,
    selectedPaymentOptions,
  } = useCheckoutInfo();

  const [
    getBillingSummary,
    {
      data: billingSummaryData,
      error: billingSummaryError,
      isError: isBillingSummaryError,
      isLoading: isBillingSummaryLoading,
    },
  ] = useGetBillingSummaryMutation();

  const [getTournament] = useLazyGetTournamentQuery();
  const { data: cartSummaryData } = useGetCartSummaryQuery({ siteId, userId });
  const { data: paymentWaiver } = useGetPaymentWaiverQuery({
    siteId,
  });
  const [processCart] = useProcessCartMutation();
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useMemo(
    () =>
      getBillingSummary({
        discountCodes: discountCodeNames,
        paymentMethod: selectedPaymentOptions
          ? selectedPaymentMethodType
          : 'CARD',
        paymentTerms: selectedPaymentOptions,
        subdomain: subdomain,
      }),
    [
      getBillingSummary,
      discountCodeNames,
      selectedPaymentOptions,
      selectedPaymentMethodType,
      subdomain,
    ]
  );

  useEffect(() => {
    if (cartSummaryData) {
      const tournamentIds = cartSummaryData.cartItems.map(
        (item) => item.options.program.programId
      );
      const allTournaments = tournamentIds.map((tournamentId) =>
        getTournament({
          siteDomain: subdomain,
          tournamentId: tournamentId.toString(),
        }).unwrap()
      );

      Promise.all(allTournaments).then((tournaments) => {
        const hotelLinks = tournaments.map((tournament) => ({
          id: tournament.id,
          hotelLink: tournament.hotelLinks,
        }));
        dispatch(setHotelLinks(hotelLinks));
      });
    }
  }, [dispatch, cartSummaryData, getTournament, subdomain]);

  function getPaymentMethodId() {
    let paymentId = null,
      tokenId = null;
    if (selectedPaymentMethod) {
      if ('tokenId' in selectedPaymentMethod) {
        tokenId = selectedPaymentMethod.tokenId;
      } else {
        paymentId = selectedPaymentMethod.paymentMethodId;
      }
    }
    return {
      tokenId: tokenId,
      storedPaymentId: paymentId,
    };
  }

  function getPaymentOptions(): BillingSummaryRequestItems[] {
    const cartItems = cartSummaryData ? cartSummaryData.cartItems : [];
    return cartItems.map(({ cartItemUuid }) => {
      const cartItemPaymentTerm = selectedPaymentOptions?.find(
        (selectedPaymentTerm) =>
          selectedPaymentTerm.cartItemUuid === cartItemUuid
      );

      const paymentTerm: PaymentTerm =
        cartItemPaymentTerm?.paymentTerm ?? 'FULL';

      return {
        cartItemUuid: cartItemUuid,
        paymentTerm,
        paymentPlanId: cartItemPaymentTerm?.paymentPlanId ?? null,
        payLater: cartItemPaymentTerm?.payLater,
      };
    });
  }

  function getCartBody(): ProcessPaymentBody {
    const { storedPaymentId, tokenId } = getPaymentMethodId();
    const paymentOptions = getPaymentOptions();
    const paymentWaiverId = paymentWaiver
      ? paymentWaiver.waiverId.toString()
      : null;
    return {
      paymentMethod: {
        storedPaymentId: storedPaymentId,
        token: tokenId,
        type: selectedPaymentMethodType,
      },
      paymentTerms: paymentOptions,
      paymentWaiverId: paymentWaiverId,
    };
  }

  function onMakePaymentClick() {
    const { methodIsValid, waiverIsValid } = validateCheckout(
      billingSummaryData?.dueToday
    );

    updateErrorStates(!methodIsValid, !waiverIsValid);
    updateCheckoutErrorsAreVisible();
    if (methodIsValid && waiverIsValid) {
      submitPayment();
    }
  }

  function submitPayment() {
    setIsProcessingPayment(true);
    const cartBody = getCartBody();
    processCart({
      body: cartBody,
      siteId,
      userId,
    })
      .unwrap()
      .then((payload) => onPaymentSuccess())
      .catch((error) => onPaymentFailure());
  }

  function onPaymentSuccess() {
    updateSubmissionErrorsAreVisible(false);
    navigate('/confirmation');
  }

  function onPaymentFailure() {
    setIsProcessingPayment(false);
    updateSubmissionErrorsAreVisible(true);
  }

  function getRollupBody() {
    if (isBillingSummaryError && billingSummaryError) {
      return <S.RollupBody>{JSON.stringify(billingSummaryError)}</S.RollupBody>;
    }

    if (isBillingSummaryLoading) {
      return (
        <S.RollupBody>
          <LoadingRipple />
        </S.RollupBody>
      );
    }

    if (billingSummaryData) {
      return (
        <>
          <RollupButtonContainer>
            <MakePaymentButton
              clickHandler={onMakePaymentClick}
              price={billingSummaryData.dueToday}
            />
          </RollupButtonContainer>
          <CheckoutBillingSummary
            billingSummaryData={billingSummaryData}
            summaryType="checkout"
          />
          <SecureCheckoutBadge />
        </>
      );
    }

    return null;
  }

  return (
    <S.CheckoutRollup>
      {isProcessingPayment ? (
        <Loader
          loading={true}
          isFullscreen={true}
          title=""
          description="We are processing your payment. Please do not leave or refresh this page."
        />
      ) : null}
      {getRollupBody()}
    </S.CheckoutRollup>
  );
}
/* */

/*MakePaymentButton */
export function MakePaymentButton({
  price,
  clickHandler,
}: MakePaymentButtonProps) {
  return (
    <S.MakePaymentButton onClick={clickHandler} width="100%">
      {`Pay ${formatAsCurrency(Number(price))} and register`}
    </S.MakePaymentButton>
  );
}
/* */
