import { createActivity } from '../apis/createActivity';
import { createAllDayActivity } from '../apis/createAllDayActivity';
import { createRecurringActivity } from '../apis/createRecurringActivity';
import { updateActivity } from '../apis/updateActivity';
import { updateAllDayActivity } from '../apis/updateAllDayActivity';
import { updateRecurringActivities } from '../apis/updateRecurringActivities';
import {
  Activity,
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityTeam,
  AllDayActivityType,
} from '../openapi-client/models';
import {
  ActivityFormAllDayActivity,
  ActivityFormData,
  RecurringActivity,
  TeamWithProps,
  getAllDayActivityRequestBody,
  getCreateActivityRequestBody,
  getHomeAwayTeamsId,
  getUpdateActivityRequestBody,
  getUpdateAllDayActivityRequestBody,
  processCreateRecurringActivitiesData,
  processRecurringSetActivity,
} from './ActivityForm.utils';

export const createActivityHandler = ({
  data,
  siteId,
  programId,
}: {
  data: ActivityFormData;
  siteId: number;
  programId: number;
}) => {
  if (isRecurringActivity(data)) {
    const createRecurringActivityRequest = processCreateRecurringActivitiesData(
      data,
      programId,
      siteId
    );

    return createRecurringActivity({ createRecurringActivityRequest });
  } else if (isAllDayActivityForm(data)) {
    const requestData = getAllDayActivityRequestBody(data);

    const teams: ActivityTeam[] = [
      {
        teamId: parseInt(data.team, 10),
        programId: parseInt(data.subProgram, 10),
        activityId: 0,
      },
    ];

    const createAllDayActivityRequestBody = {
      programId,
      siteId,
      type: data.activityType as AllDayActivityType,
      ...requestData,
      teams: teams, // this has been moved so we don't overwrite in requestData.
    };

    return createAllDayActivity({ createAllDayActivityRequestBody });
  } else {
    const requestData = getCreateActivityRequestBody(data, siteId, programId);
    return createActivity({ createActivityRequestBody: requestData });
  }
};

export type UpdateActivityData = {
  editActivity: Activity;
  data: ActivityFormData;
  siteId: number;
  teams?: TeamWithProps[];
  isRecurringSet?: boolean;
  notify?: string;
};

export const updateActivityHandler = async ({
  editActivity, // a copy of the previous activity
  data, // the new data
  siteId,
  isRecurringSet,
  notify,
}: UpdateActivityData) => {
  // Handle recurring activities
  if (isRecurringSet) {
    const recurringSetRequestData = processRecurringSetActivity(data);
    const homeTeamId = getHomeAwayTeamsId(editActivity.teams).homeTeam;
    const awayTeamId = getHomeAwayTeamsId(editActivity.teams).awayTeam;

    let locationId = editActivity.locationId;
    let subLocationId = editActivity.subLocationId;

    if (recurringSetRequestData.locationId) {
      locationId = recurringSetRequestData.locationId;
      subLocationId = recurringSetRequestData.subLocationId ?? undefined;
    }

    // update full set of recurring activity
    if (editActivity.seqToken) {
      const recurringRequestBody = {
        activityId: editActivity.id,
        locationId: locationId,
        seqToken: editActivity.seqToken,
        siteId: siteId,
        subLocationId: subLocationId,
        subProgramId: editActivity.teams[0].programId,
        teamId: [homeTeamId, awayTeamId],
        updateRecurringActivityRequestBody: {
          notify,
          ...recurringSetRequestData,
        },
      };

      return updateRecurringActivities(recurringRequestBody);
    }

    // Handle All day activites
  } else if (isAllDayActivityForm(data)) {
    const allDayRequestData = getUpdateAllDayActivityRequestBody(data);

    const allDayActivityRequestBody = {
      activityId: editActivity.id,
      siteId,
      updateAllDayActivityRequestBody: {
        notify,
        ...allDayRequestData,
      },
    };

    return updateAllDayActivity(allDayActivityRequestBody);
  } else {
    const activityRequestBody = getUpdateActivityRequestBody(data);

    //remove seqToken if home, or away team is changed
    let seqToken;
    const homeTeamId = getHomeAwayTeamsId(editActivity.teams).homeTeam;
    const homeTeamChanged = homeTeamId.toString() !== data.team;
    const awayTeamId = getHomeAwayTeamsId(editActivity.teams).awayTeam;
    const awayTeamChanged = awayTeamId?.toString() !== data.awayTeam;
    if ((awayTeamChanged || homeTeamChanged) && !!editActivity.seqToken) {
      seqToken = null;
    }

    //update single activity
    const requestBody = {
      activityId: editActivity.id,
      updateActivityRequestBody: {
        siteId,
        ...activityRequestBody,
        notify,
        seqToken: seqToken,
      },
    };

    return updateActivity(requestBody);
  }
};

/* Type-guard for all day activity in form */
function isAllDayActivityForm(
  data: ActivityFormData
): data is ActivityFormAllDayActivity {
  return !!data.startDate && !!data.location && data.allDay;
}

/* Type-guard for recurring activity */
function isRecurringActivity(
  data: ActivityFormData
): data is RecurringActivity {
  return (
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Day ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Week ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Month
  );
}
