"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamRegistrationProvider = exports.TeamRegistrationContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var reducer_1 = require("./reducer");
var teamRegistrationInitialProps = {
    state: {
        payer: null,
        wizardState: null,
        divisions: null,
        isMC: true,
        selectedPaymentOption: null,
        paymentPricing: null,
    },
    setPayer: function () { },
    setWizardState: function () { },
    setDivisions: function () { },
    setSelectedPaymentOption: function () { },
    setPaymentsPricing: function () { },
};
exports.TeamRegistrationContext = (0, react_1.createContext)(teamRegistrationInitialProps);
var TeamRegistrationProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useReducer)(reducer_1.teamRegistrationReducer, reducer_1.teamRegistrationInitialState), state = _b[0], dispatch = _b[1];
    var setPayer = function (payer) {
        dispatch({
            type: 'SET_PAYER',
            payload: { payer: payer },
        });
    };
    var setWizardState = function (wizardState) {
        dispatch({
            type: 'SET_WIZARD_STATE',
            payload: { wizardState: wizardState },
        });
    };
    var setDivisions = function (divisions) {
        dispatch({
            type: 'SET_DIVISIONS',
            payload: { divisions: divisions },
        });
    };
    var setSelectedPaymentOption = function (optionId) {
        dispatch({
            type: 'SET_SELECTED_PAYMENT_OPTION',
            payload: { optionId: optionId },
        });
    };
    var setPaymentsPricing = function (paymentsPricing) {
        dispatch({
            type: 'SET_PAYMENTS_PRICING',
            payload: { paymentsPricing: paymentsPricing },
        });
    };
    return ((0, jsx_runtime_1.jsx)(exports.TeamRegistrationContext.Provider, __assign({ value: {
            state: state,
            setPayer: setPayer,
            setWizardState: setWizardState,
            setDivisions: setDivisions,
            setSelectedPaymentOption: setSelectedPaymentOption,
            setPaymentsPricing: setPaymentsPricing,
        } }, { children: children })));
};
exports.TeamRegistrationProvider = TeamRegistrationProvider;
