import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import arrow_down from 'assets/icons/arrow_down.svg';
import arrow_up from 'assets/icons/arrow_up.svg';

export const UserMenuMobile = styled.div`
  border-bottom: 1px solid var(--primary);
`;

export const Accordion = styled.div``;

export const AccordionToggle = styled.button<{ $menuExpanded: boolean }>`
  width: 100%;
  height: 64px;
  padding: 0 20px;
  font: var(--ui-large-bold);
  line-height: 64px;
  text-align: left;
  text-decoration: none;
  color: var(--blue-grey-900);
  border: none;
  border-bottom: 1px solid var(--blue-grey-100);
  background-color: var(--white);
  background-image: ${({ $menuExpanded }) =>
    $menuExpanded ? `url(${arrow_up})` : `url(${arrow_down})`};
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: top 50% right 28px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--blue-grey-50);
    outline: none;
  }

  &:active {
    background-color: var(--blue-grey-100);
    outline: none;
  }
`;

export const MenuLink = styled(Link)`
  display: block;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  font: var(--ui-large-bold);
  line-height: 64px;
  text-align: left;
  text-decoration: none;
  color: var(--blue-grey-900);
  border: none;
  border-bottom: 1px solid var(--blue-grey-100);
  background-color: var(--white);

  &:hover,
  &:focus {
    background: var(--blue-grey-50);
    outline: none;
  }

  &:active {
    background: var(--blue-grey-100);
  }

  ${Accordion} & {
    padding-left: 36px;
  }
`;

export const AccordionSubMenuContainer = styled.div<{ $isUserMenu: boolean }>`
  padding: ${({ $isUserMenu }) => ($isUserMenu ? '8px 0px' : '6px 0px')};
  border-bottom: ${({ $isUserMenu }) =>
    $isUserMenu ? 'none' : '1px solid var(--blue-grey-100)'};
`;

export const SubMenuLink = styled(Link)<{
  $isUserMenu: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 48px;
  padding: 0 36px;
  font: var(--ui-large-medium);
  text-decoration: none;
  color: var(--blue-grey-900);

  &:first-child {
    padding: ${({ $isUserMenu }) => ($isUserMenu ? '0px 36px' : '0px 28px')};
  }

  &:hover,
  &:focus {
    background: var(--blue-grey-50);
    outline: none;
  }

  &:active {
    background: var(--blue-grey-100);
  }
`;

export const SignOutLink = styled.a`
  display: block;
  width: 100%;
  height: 48px;
  padding: 0px 36px;
  font: var(--ui-large-medium);
  line-height: 48px;
  text-decoration: none;
  color: var(--blue-grey-900);

  &:hover,
  &:focus {
    background: var(--blue-grey-50);
    outline: none;
  }

  &:active {
    background: var(--blue-grey-100);
  }
`;
