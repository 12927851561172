import { ReactNode } from 'react';
import * as S from './ErrorCard.styles';

export type ErrorCardProps = {
  closeCard?: () => void;
  message: string | ReactNode;
};

/* ErrorCard */
export default function ErrorCard({ closeCard, message }: ErrorCardProps) {
  return (
    <S.ErrorCard>
      <S.ErrorIcon />
      <S.ErrorMessage>{message}</S.ErrorMessage>
      {closeCard ? <S.CloseIcon onClick={closeCard} /> : null}
    </S.ErrorCard>
  );
}
/* */
