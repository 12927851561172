"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBooleanStrings = exports.removeTrailingCommas = exports.sanitizeJSON = exports.extractJSONFromHTML = void 0;
var sanitize_html_1 = require("sanitize-html");
function extractJSONFromHTML(html, scriptId) {
    // reduce the size of the string to be searched by stripping out all
    // tags except <script> tags
    var sanitizedHTML = (0, sanitize_html_1.default)(html, {
        allowedTags: ['script'],
        allowVulnerableTags: true,
        allowedAttributes: false,
        parseStyleAttributes: false,
    });
    // locate the script tag in the sanitized  html
    var scriptTag = "<script id=\"".concat(scriptId, "\" type=\"application/json\">");
    var startIdx = sanitizedHTML.indexOf(scriptTag) + scriptTag.length;
    var endIdx = sanitizedHTML.indexOf('</script>', startIdx);
    // return--as a string--the JSON object embedded within the <script> tag
    return sanitizedHTML.slice(startIdx, endIdx);
}
exports.extractJSONFromHTML = extractJSONFromHTML;
function sanitizeJSON(jsonString) {
    var trailingCommasRemoved = removeTrailingCommas(jsonString);
    var booleanStringsConverted = convertBooleanStrings(trailingCommasRemoved);
    return JSON.parse(booleanStringsConverted);
}
exports.sanitizeJSON = sanitizeJSON;
function removeTrailingCommas(jsonString) {
    return (jsonString
        // comma before a closing curly brace
        .replace(/(,\s*})/g, '}')
        // comma before a closing square brace
        .replace(/(,\s*])/g, ']')
        // comma at the end of the entire object
        .replace(/(,\s*$)/g, ''));
}
exports.removeTrailingCommas = removeTrailingCommas;
function convertBooleanStrings(jsonString) {
    return (jsonString
        // replace ': "true"' with ': true' (checks for 0+ spaces after ':')
        .replace(/:\s*"true"/gi, ': true')
        // replace ': "false"' with ': false' (checks for 0+ spaces after ':')
        .replace(/:\s*"false"/gi, ': false'));
}
exports.convertBooleanStrings = convertBooleanStrings;
