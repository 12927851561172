import styled from 'styled-components';
import { Typography } from 'components/Typography/Typography';
import { ExclamationOctagonIcon, InfoIcon } from '../Icon';
export const MessageContainer = styled.div.withConfig({
  componentId: "sc-ztpmmr-0"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    gap: spacing.xxxs,
    width: '100%',
    marginTop: spacing.xxxs
  };
});
export const MessageRoot = styled(Typography).withConfig({
  componentId: "sc-ztpmmr-1"
})(({
  theme,
  $messageType
}) => {
  return $messageType === 'error' ? {
    color: theme.palette.error.medium
  } : {
    color: theme.foundational.blueGrey[600]
  };
});
export const ErrorMessageIcon = styled(ExclamationOctagonIcon).attrs(({
  theme
}) => ({
  fill: theme.palette.error.medium
})).withConfig({
  componentId: "sc-ztpmmr-2"
})({});
export const InfoMessageIcon = styled(InfoIcon).attrs(({
  theme
}) => ({
  fill: theme.foundational.blueGrey[600]
})).withConfig({
  componentId: "sc-ztpmmr-3"
})({});