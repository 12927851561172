"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var S = require("./ErrorCard.styles");
var ErrorCard = function (_a) {
    var closeCard = _a.closeCard, message = _a.message;
    return ((0, jsx_runtime_1.jsxs)(S.ErrorCard, { children: [(0, jsx_runtime_1.jsx)(S.ErrorIcon, {}), (0, jsx_runtime_1.jsx)(S.ErrorMessage, { children: message }), closeCard ? ((0, jsx_runtime_1.jsx)("div", __assign({ onClick: closeCard }, { children: (0, jsx_runtime_1.jsx)(S.CloseIcon, {}) }))) : null] }));
};
exports.ErrorCard = ErrorCard;
