import { ReactComponent as IconCloseX } from 'assets/icons/icon_x.svg';
import * as S from './CloseButton.styles';

export type CloseButtonProps = {
  clickHandler: () => void;
};

/* CloseButton */
export default function CloseButton({ clickHandler }: CloseButtonProps) {
  return (
    <S.CloseButton onClick={clickHandler}>
      <IconCloseX />
    </S.CloseButton>
  );
}
/* */
