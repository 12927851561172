import { http, HttpResponse } from 'msw';
import { Division, Tournament } from 'redux/services/types/tournament';

export enum TournamentEndpoint {
  GetTournament = '*/api/member-portal/*/tournaments/*',
}

export const mockDivisions: Division[] = [
  {
    id: 'div-1',
    name: 'Mites A',
    gender: 'male',
    startDate: '2023-11-04',
    endDate: '2023-11-10',
    registrationStartDate: '2023-10-06',
    registrationEndDate: '2023-10-07',
    ageGroup: 8,
    currency: 'USD',
    customTeamFields: [],
  },
  {
    id: 'div-2',
    name: 'Mites AA',
    gender: 'female',
    startDate: '2023-11-20',
    endDate: '2023-11-20',
    registrationStartDate: '2023-10-10',
    registrationEndDate: '2023-10-15',
    ageGroup: 9,
    cost: '100.00',
    currency: 'USD',
    customTeamFields: [],
  },
  {
    id: 'div-3',
    name: 'Mites AAA',
    gender: 'any',
    startDate: '2023-12-10',
    endDate: '2023-12-14',
    registrationStartDate: '2023-10-07',
    registrationEndDate: '2023-10-31',
    ageGroup: 9,
    cost: '90.50',
    currency: 'USD',
    customTeamFields: [],
  },
];

export const mockTournament: Tournament = {
  id: 'tourn-1',
  name: 'Ice Cup',
  gender: 'any',
  startDate: '2023-11-04',
  endDate: '2023-12-14',
  registrationStartDate: '2023-10-06',
  registrationEndDate: '2023-10-31',
  aboutHTML: '<p>Example description that <strong>contains</strong> HTML.</p>',
  sport: 'Hockey',
  season: {
    label: 'Season',
    value: 'winter',
  },
  experienceLevel: {
    label: 'Level',
    value: 'Beginner',
  },
  logoURL: 'https://google.com',
  ageGroups: [8, 9],
  cost: {
    min: '0',
    max: '100.00',
  },
  currency: 'USD',
  timeZone: 'America/New_York',
  location: {
    id: '123',
    name: 'Greenwich Skating Club',
  },
  hotelLinks: 'https://google.com',
  divisions: mockDivisions,
};

export const tournamentHandlers = [
  // Get tournament with divisions
  http.get(TournamentEndpoint.GetTournament, () => {
    return HttpResponse.json(mockTournament, { status: 200 });
  }),
];
