import { getLAHostnameParts } from './urlUtils';

export const stripeKeyBySiteDomain = new Map([
  ['lapps-local', 'pk_test_GKxxamwbFoLsd2eenLw0uuOn'],
  ['lapps-dev1', 'pk_test_3JYbo4sX39s3UKpqlcav1VHw'],
  ['lapps-dev2', 'pk_test_3JYbo4sX39s3UKpqlcav1VHw'],
  ['lapps-dev3', 'pk_test_3JYbo4sX39s3UKpqlcav1VHw'],
  ['lapps-qa1', 'pk_test_G616J47VpFhCbmq166KQ2jtn'],
  ['lapps-qa2', 'pk_test_G616J47VpFhCbmq166KQ2jtn'],
  [
    'lapps-sandbox',
    'pk_test_51GwtgOKXUSWDTJJ6Uh9kDTEr6gOOzFaTLxcKSMf8pFHqgSyLpHaQ1sYH9dnOfKzbPTNlRUGoifW3gyalh8627iWi00fyAnLiAP',
  ],
  ['lapps-preview', 'pk_test_6Qtt0RyE3YId7erBMcz9orSX000mJIixyb'],
  ['lapps-cicd1', 'pk_test_NPXLyALvgMHJhdRv2bvXW5W6'],
  ['leagueapps', 'pk_live_sVbNgk4Oe26o9jeFN043YMiV'],
]);

export const stripeKeyForSalesDemos = 'pk_test_m9dsVYjJu18DXGesgEAX5LXK';

/**
 * In most cases the Stripe key is referenced by the second-level-domain of
 * the site in which it is being used. However, the Sales team uses our
 * production site to perform demos. A LaunchDarkly feature flag,
 * 'isDemoSite' matches the site ids of those sites used in these demos. If
 * the site's id is one of those used in the sales demos and the
 * LaunchDarkly flag returns true, the Stripe key used in these demos will be
 * returned instead.
 * @param {boolean} isDemoSite
 * @returns {string}
 */
export function getStripeKey(isDemoSite: boolean = false) {
  const { sld } = getLAHostnameParts();
  const stripeKeyFromSiteDomain = stripeKeyBySiteDomain.get(sld) ?? '';
  return isDemoSite ? stripeKeyForSalesDemos : stripeKeyFromSiteDomain;
}
