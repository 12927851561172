// Measurements are in pixels - start with numbers so that one size can depend on another
import { BREAKPOINTS } from '../hooks/BreakpointConstants';

// Convert to pixels

function toPx(value: number) {
  return `${value}px`;
}

const bigDesktopPx = {
  min: toPx(BREAKPOINTS.BIG_DESKTOP.min),
};

const desktopPx = {
  min: toPx(BREAKPOINTS.DESKTOP.min),
  max: toPx(BREAKPOINTS.DESKTOP.max),
};

const tabletLandscapePx = {
  min: toPx(BREAKPOINTS.TABLET_LANDSCAPE.min),
  max: toPx(BREAKPOINTS.TABLET_LANDSCAPE.max),
};

const tabletPortraitPx = {
  min: toPx(BREAKPOINTS.TABLET_PORTRAIT.min),
  max: toPx(BREAKPOINTS.TABLET_PORTRAIT.max),
};

const phonePx = {
  max: toPx(BREAKPOINTS.PHONE.max),
};

export const breakpointQueries = {
  phoneOnly: `(max-width: ${phonePx.max})`,
  tabletPortraitUp: `(min-width: ${tabletPortraitPx.min})`,
  tabletPortraitDown: `(max-width: ${tabletPortraitPx.max})`,
  tabletPortraitOnly: `(min-width: ${tabletPortraitPx.min}) and (max-width: ${tabletPortraitPx.max})`,
  tabletLandscapeUp: `(min-width: ${tabletLandscapePx.min})`,
  tabletLandscapeDown: `(max-width: ${tabletLandscapePx.max})`,
  tabletLandscapeOnly: `(min-width: ${tabletLandscapePx.min}) and (max-width: ${tabletLandscapePx.max})`,
  desktopUp: `(min-width: ${desktopPx.min})`,
  desktopDown: `(max-width: ${desktopPx.max})`,
  desktopOnly: `(min-width: ${desktopPx.min}) and (max-width: ${desktopPx.max})`,
  bigDesktopOnly: `(min-width: ${bigDesktopPx.min})`,
};
