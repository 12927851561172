import { DivisionCardStateWithId } from 'domains/Tournaments/Registration/Wizard/Wizard.types';
import { DivisionSummaryCard } from '../DivisionSummaryCard/DivisionSummaryCard';
import { SummaryAndSubmitProps } from '../SummaryAndSubmit';
import * as S from './SummaryCards.styles';

type SummaryCardsProps = Pick<SummaryAndSubmitProps, 'teams' | 'tournament'> & {
  divisionsForSummarySection: DivisionCardStateWithId[];
};

/* SummaryCards */
export function SummaryCards({
  divisionsForSummarySection,
  teams,
  tournament,
}: SummaryCardsProps) {
  return (
    <S.WizardSummaryCards>
      {divisionsForSummarySection.map((d) => {
        const division = tournament.divisions.find(
          (division) => division.id === d.id
        );

        if (!division) {
          return null;
        }

        let teamIds: string[] = [];
        if (d.value === 'saved' || d.value === 'saved-and-editing') {
          teamIds = d.savedTeams;
        }

        const teamsForDivision = teamIds.map(
          (teamId) => teams.find((team) => team.id === teamId)!
        );

        return (
          <DivisionSummaryCard
            key={division.id}
            division={division}
            teams={teamsForDivision}
          />
        );
      })}
    </S.WizardSummaryCards>
  );
}
/* */
