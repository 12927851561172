import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { CheckCircle, Info, WarningOctagon } from '@phosphor-icons/react';
import * as S from './Toast.styles';

const INTERVAL = 50;

export type ToastVariant = 'error' | 'info' | 'success';

export type ToastData = {
  description: string;
  duration?: number;
  id?: string;
  onClose?: () => void;
  onPause?: () => void;
  onResume?: () => void;
  testId?: string;
  variant?: ToastVariant;
};

const highlightColor = new Map();
highlightColor.set('error', '--red-500');
highlightColor.set('info', '--blue-300');
highlightColor.set('success', '--green-500');

export type SetInterval = string | number | NodeJS.Timeout | undefined;

export default function Toast({
  description,
  variant = 'info',
  duration,
  onClose = () => {},
  id,
  testId,
}: Readonly<ToastData>) {
  const [creationTime] = useState(Date.now());
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [isTimeoutRunning, setIsTimeoutRunning] = useState(true);

  const adjustedDuration = useMemo(() => {
    const messageWordCount = description.split(' ').length;
    const baseDuration = 5000;
    return duration ?? baseDuration + Math.ceil(messageWordCount / 118) * 1000;
  }, [description, duration]);

  const variantIcon = useMemo(() => {
    switch (variant) {
      case 'error':
        return (
          <WarningOctagon
            size={24}
            weight="bold"
            color={`var(${highlightColor.get(variant)})`}
          />
        );
      case 'info':
        return <Info size={24} weight="bold" color="var(--blue-300)" />;
      case 'success':
        return <CheckCircle size={24} weight="bold" color="var(--green-500)" />;
      default:
        return null;
    }
  }, [variant]);

  useEffect(() => {
    let intervalId: SetInterval;

    function getPercentageComplete() {
      return Math.floor(((Date.now() - creationTime) / adjustedDuration) * 100);
    }

    function onInterval() {
      const percentageOfDurationPassed = getPercentageComplete();

      if (!isTimeoutRunning) {
        clearInterval(intervalId);
        setPercentCompleted(100);
      } else if (percentageOfDurationPassed < 100) {
        setPercentCompleted(percentageOfDurationPassed);
      } else {
        clearInterval(intervalId);
        onClose();
      }
    }

    if (!intervalId) {
      intervalId = setInterval(onInterval, INTERVAL);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [adjustedDuration, creationTime, isTimeoutRunning, onClose]);

  return (
    <S.Toast
      aria-label="notification"
      data-testid={testId}
      id={id}
      onMouseEnter={() => setIsTimeoutRunning(false)}
      $isNewToast={creationTime + 1000 > Date.now()}
    >
      <S.ToastContent>
        {variantIcon}
        <S.ToastDescription>{description}</S.ToastDescription>
        <S.CloseButtonX
          size={20}
          weight="bold"
          color="white"
          onClick={onClose}
          alt-text="close"
        />
      </S.ToastContent>
      <ToastProgressBar percentComplete={percentCompleted} variant={variant} />
    </S.Toast>
  );
}

export function ToastProgressBar({
  percentComplete,
  variant = 'info',
}: Readonly<{
  percentComplete: number;
  variant: string;
}>) {
  return (
    <S.ProgressBar
      $barColor={`var(${highlightColor.get(variant)})`}
      $width={percentComplete}
    />
  );
}

export function ToastContainer({ children }: PropsWithChildren) {
  return <S.ToastContainer id="toast-container">{children}</S.ToastContainer>;
}
