import axios from 'axios';
import { FormField } from '@la/types';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { getGroupAccount } from './getGroupAccount';
import { getStaffRoles } from './getStaffRoles';
import { getExistingUserRegistration } from './getUserRegistrations';

type RegistrationWorkflowStringFormField = {
  type: 'TEXT_BOX' | 'TEXT_AREA' | 'FILE_UPLOAD';
  values: string[];
};

type RegistrationWorkflowNumericFormField = {
  type: 'NUMERIC' | 'PICK_LIST' | 'MULTIPLE_CHECKBOXES';
  values: string[];
};

export type RegistrationWorkflowFormField = (
  | RegistrationWorkflowStringFormField
  | RegistrationWorkflowNumericFormField
) & {
  formFieldId: number;
};

export type RegistrationWorkflowWaiver = {
  waiverId: number;
  waiverType: 'REGISTRATION';
};

type RegistrationWorkflowMetadata = {
  version: 1;
  type: 'player' | 'staff';
  programStaffId?: number; // staff only field; omit otherwise
  registeredUserId: number; // player being registered or user id if staff
  formFields?: RegistrationWorkflowFormField[];
  waivers?: RegistrationWorkflowWaiver[];
  teamIdOg: number;
  primaryStaff?: boolean;
  programRole: 'PLAYER';
  email?: string;
};

type RegistrationWorkflow = {
  metadata: RegistrationWorkflowMetadata;
  id?: string; // include if updating an existing registration
  paymentStatus: 'UNPAID';
  registrationType: 'PLAYER' | 'STAFF';
  registeringUserId: number; // logged in user's id
  registrationStatus: 'CREATED' | 'REGISTERED';
  programId: number;
  siteId: number;
  teamIdOg: number;
  deleted: false;
};

type WorkflowRegistrationData = Pick<
  RegistrationWorkflow,
  'registeringUserId' | 'programId'
> &
  Pick<
    RegistrationWorkflowMetadata,
    'formFields' | 'registeredUserId' | 'teamIdOg' | 'waivers'
  > & {
    registrationType: string; // 'PLAYER' | 'Team Representative' | 'Volunteer' | 'Coach';
    siteId: string;
  };

const baseUrl = getBaseURL();

const updateWorkflowRegistration = async (
  {
    siteId,
    registrationType,
    registeringUserId,
    registeredUserId,
    programId,
    formFields,
    waivers,
    teamIdOg,
  }: WorkflowRegistrationData,
  finalize = false,
  workflowWizardVersion = 10
) => {
  const endpoint = `${baseUrl}/api/workflow/v1/workflows/tournament_team_registration_wizard_v${workflowWizardVersion}`;
  const token = extractAccessToken();
  const groupAccount = await getGroupAccount({ siteId });

  const staffRoles = await getStaffRoles({ siteId });
  const programStaffId = staffRoles.find(
    (staffRole) => staffRole.role === registrationType
  )?.id;

  const registration: RegistrationWorkflow = {
    metadata: {
      version: 1,
      type: registrationType === 'PLAYER' ? 'player' : 'staff',
      programStaffId:
        registrationType !== 'PLAYER' ? parseInt(registrationType) : undefined,
      registeredUserId,
      formFields,
      waivers,
      teamIdOg,
      programRole: 'PLAYER',
      primaryStaff: false,
      email: groupAccount?.members.find((m) => m.role === 'adult')?.user.email,
    },
    paymentStatus: 'UNPAID',
    registrationType: registrationType === 'PLAYER' ? 'PLAYER' : 'STAFF',
    registeringUserId,
    registrationStatus: finalize ? 'REGISTERED' : 'CREATED',
    programId,
    siteId: parseInt(siteId),
    teamIdOg,
    deleted: false,
  };

  let addedAndUpdatedRegistrations;
  const existingRegistration = await getExistingUserRegistration({
    registrationType: registrationType === 'PLAYER' ? 'PLAYER' : 'STAFF',
    userId:
      registrationType === 'PLAYER' ? registeredUserId : registeringUserId,
    programId,
    teamIdOg,
    roleId:
      registrationType === 'PLAYER'
        ? programStaffId
        : parseInt(registrationType),
  });

  if (existingRegistration) {
    addedAndUpdatedRegistrations = {
      add: [],
      update: [
        {
          ...registration,
          id: existingRegistration.id,
        },
      ],
    };
  } else {
    addedAndUpdatedRegistrations = { add: [registration], update: [] };
  }

  const response = await axios.post(
    endpoint,
    {
      programId,
      siteId: parseInt(siteId),
      ...addedAndUpdatedRegistrations,
      remove: [],
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
};

/**
 * Format form fields for workflow submission
 * @param formFields Form fields to be included. If a form field does not have
 * a defined `value`, it will be omitted from the return.
 * @returns Form fields formatted as objects for workflow.
 */
const formatFormFieldsForWorkflow = (formFields: FormField[]) => {
  return formFields.reduce<RegistrationWorkflowFormField[]>(
    (allFields, field) => {
      const { type, value } = field;
      const formFieldId = field.propertyDefinitionId;

      if (value) {
        if (type === 'FILE_UPLOAD') {
          if (value.uuid) {
            return allFields.concat({
              formFieldId,
              type,
              values: [value.name, value.uuid],
            });
          }
        } else if (type === 'MULTIPLE_CHECKBOXES') {
          return allFields.concat({
            formFieldId,
            type,
            values: value.map((value) => {
              const optionValue =
                field.items.find((item) => item.itemId === value)?.value ?? '';
              return optionValue;
            }),
          });
        } else if (type === 'NUMERIC') {
          // Need to check the above conditions to separate the type of value `(string | number)`
          // to just be `number` here and just string in the `else` block, otherwise
          // we get a complaint about potential mismatch between the `type` and the `values`.

          return allFields.concat({
            formFieldId,
            type,
            values: [value.toString()],
          });
        } else if (type === 'PICK_LIST') {
          const optionValue =
            field.items.find((item) => item.itemId === value)?.value ?? '';
          return allFields.concat({
            formFieldId,
            type,
            values: [optionValue],
          });
        } else {
          return allFields.concat({
            formFieldId,
            type,
            values: [value.toString()],
          });
        }
      }

      return allFields;
    },
    []
  );
};

export { updateWorkflowRegistration, formatFormFieldsForWorkflow };
