import styled from 'styled-components';
import { media } from 'lib/media-queries/mixins';

export const Header = styled.h1`
  font-family: var(--secondary-font-family);
  font-size: var(--headline-size-large);
  font-style: normal;
  font-weight: var(--ui-weight-bold);
  line-height: 36px;
  letter-spacing: 0.14px;
  color: var(--blue-grey-900, #263238);
`;

export const Container = styled.div`
  margin: 16px 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 75%;

  ${media.TabletPortraitDown`
    width: 100%;`}
`;

export const ModalButtonContainer = styled.div`
  display: none;
  ${media.TabletPortraitDown`
    display: flex;
    `}
`;
