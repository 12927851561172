"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubNavigationMenuItem = exports.SubNavigationSectionMenu = exports.SubNavigationSection = exports.SubNavigation = void 0;
var styled_components_1 = require("styled-components");
exports.SubNavigation = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  grid-area: ", ";\n  position: relative;\n  width: 100%;\n  height: 48px;\n  min-height: 48px;\n  padding: 0 16px;\n  text-align: left;\n  background-color: var(--white);\n  box-shadow: 2px 2px 7px var(--drop-shadow);\n"], ["\n  display: flex;\n  grid-area: ", ";\n  position: relative;\n  width: 100%;\n  height: 48px;\n  min-height: 48px;\n  padding: 0 16px;\n  text-align: left;\n  background-color: var(--white);\n  box-shadow: 2px 2px 7px var(--drop-shadow);\n"])), function (props) { return props.$gridAreaName; });
exports.SubNavigationSection = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  height: 100%;\n\n  > a {\n    color: var(--blue-grey-700);\n    font-size: 16px;\n    font-weight: 700;\n    cursor: pointer;\n    margin-right: 20px;\n    text-decoration: none;\n    line-height: 48px;\n  }\n\n  &:hover {\n    border-bottom: 2px var(--blue-grey-900) solid;\n\n    > a {\n      color: var(--green-700);\n    }\n\n    > div {\n      display: block;\n    }\n  }\n"], ["\n  display: inline-block;\n  height: 100%;\n\n  > a {\n    color: var(--blue-grey-700);\n    font-size: 16px;\n    font-weight: 700;\n    cursor: pointer;\n    margin-right: 20px;\n    text-decoration: none;\n    line-height: 48px;\n  }\n\n  &:hover {\n    border-bottom: 2px var(--blue-grey-900) solid;\n\n    > a {\n      color: var(--green-700);\n    }\n\n    > div {\n      display: block;\n    }\n  }\n"])));
exports.SubNavigationSectionMenu = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  position: absolute;\n  z-index: 110;\n  min-width: 120px;\n  padding: 12px;\n  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);\n  overflow: hidden;\n  background-color: var(--white);\n"], ["\n  display: none;\n  position: absolute;\n  z-index: 110;\n  min-width: 120px;\n  padding: 12px;\n  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);\n  overflow: hidden;\n  background-color: var(--white);\n"])));
exports.SubNavigationMenuItem = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  > a {\n    display: block;\n    font-size: 14px;\n    line-height: 1.75;\n    color: var(--primary-500);\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"], ["\n  > a {\n    display: block;\n    font-size: 14px;\n    line-height: 1.75;\n    color: var(--primary-500);\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
