import { Button, Typography } from '@la/ds-ui-components';
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/Card/Card';
import * as S from './LocationCard.styles';

export const VIEW_LOCATION_TEXT = 'View location';

export type LocationCardProps = {
  /**
   * For now this is used for the `data-href` property on the button being
   * assigned a `link` role. Once we have a true link to use, we can just
   * use this as the `to` value for the React Router link
   */
  locationLink?: string;
  /**
   * The name of the location the tournament is held in.
   */
  name?: string;
  /**
   * This is temporarily being used while we are using a button instead
   * of a regular link.
   */
  navigateToLocation?: () => void;
};

export function LocationCard({
  locationLink,
  name,
  navigateToLocation,
}: LocationCardProps) {
  return (
    <S.LocationCard data-testid="location-card">
      <CardHeader>
        <CardTitle>Location</CardTitle>
      </CardHeader>
      {locationLink && name ? (
        <>
          <CardBody>
            <Typography variant="ui" size="large">
              {name}
            </Typography>
          </CardBody>
          <CardFooter>
            <Button
              // TODO: Replace this with the design system "link styled as button"
              // whenever it is ready
              data-href={locationLink}
              onClick={navigateToLocation}
              role="link"
              variant="outline"
            >
              {VIEW_LOCATION_TEXT}
            </Button>
          </CardFooter>
        </>
      ) : (
        <CardBody>
          <Typography variant="ui" size="large">
            No location set
          </Typography>
        </CardBody>
      )}
    </S.LocationCard>
  );
}
