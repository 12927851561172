import axios from 'axios';
import { getBaseURL, getLAHostnameParts } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const getProgramTeams = async ({ programId }: { programId: string }) => {
  const { subdomain } = getLAHostnameParts();
  const endpoint = `${baseUrl}/api/member-portal/${subdomain}/programTeams?programId=${programId}`;
  const response = await axios.get(endpoint);

  return response.data;
};

export { getProgramTeams };
