"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardSubtotal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var SummaryAndSubmit_1 = require("../SummaryAndSubmit");
var cart_1 = require("../utils/cart");
var S = require("./WizardSubtotal.styles");
/* WizardSubtotal */
function WizardSubtotal(_a) {
    var divisionCardsState = _a.divisionCardsState, tournament = _a.tournament, _b = _a.isMC, isMC = _b === void 0 ? false : _b;
    return ((0, jsx_runtime_1.jsxs)(S.WizardSubtotal, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui" }, { children: isMC ? 'Subtotal to be invoiced:' : SummaryAndSubmit_1.SUBTOTAL_LABEL })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui", weight: "bold" }, { children: (0, utilities_1.formatAsUSD)((0, cart_1.calculateTotalCost)(divisionCardsState, tournament.divisions)) }))] }));
}
exports.WizardSubtotal = WizardSubtotal;
