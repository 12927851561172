import styled from 'styled-components';
import { ButtonFilled, ButtonOutline, ButtonText } from './Button.styles';
export const iconButtonSize = {
  small: '12px',
  medium: '16px',
  large: '20px',
  xl: '24px'
};
export const iconButtonPadding = {
  small: 0,
  medium: '4px 16px',
  large: '4px 16px',
  xl: '4px 20px'
};
export const IconButtonWrapper = styled.div.withConfig({
  componentId: "sc-1pqewv3-0"
})(({
  theme,
  $disabled
}) => {
  const {
    foundational: {
      blueGrey
    }
  } = theme;
  return {
    cursor: $disabled ? 'not-allowed' : 'pointer',
    '& button': {
      pointerEvents: $disabled ? 'none' : 'all'
    },
    '& button svg': {
      fill: $disabled ? blueGrey[200] : undefined
    }
  };
});
export const IconButtonFilled = styled(ButtonFilled).withConfig({
  componentId: "sc-1pqewv3-1"
})(({
  theme,
  $size
}) => {
  const {
    palette: {
      foundational: {
        blueGrey
      }
    }
  } = theme;
  const iconSize = iconButtonSize[$size ?? 'medium'];
  return {
    padding: iconButtonPadding[$size ?? 'medium'],
    width: 'fit-content',
    '& svg': {
      height: iconSize,
      width: iconSize,
      strokeWidth: '2px'
    },
    '&[disabled]': {
      backgroundColor: blueGrey[50],
      borderColor: blueGrey[50],
      '& svg': {
        fill: blueGrey[400]
      }
    }
  };
});
export const IconButtonOutline = styled(ButtonOutline).withConfig({
  componentId: "sc-1pqewv3-2"
})(({
  theme,
  $size
}) => {
  const iconSize = iconButtonSize[$size ?? 'medium'];
  return {
    padding: iconButtonPadding[$size ?? 'medium'],
    width: 'fit-content',
    '& svg': {
      height: iconSize,
      width: iconSize,
      strokeWidth: '2px'
    }
  };
});
export const IconButtonText = styled(ButtonText).withConfig({
  componentId: "sc-1pqewv3-3"
})(({
  theme,
  $size = 'medium',
  $hasPadding
}) => {
  const {
    semantic,
    foundational: {
      blueGrey,
      green,
      white
    }
  } = theme;
  const iconSize = iconButtonSize[$size];
  const isSmall = $size === 'small';
  const iconColor = {
    default: semantic ? semantic.secondary[600] : green[600],
    hover: semantic ? semantic.secondary.hover : green[800],
    active: semantic ? semantic.secondary.active : green[900],
    disabled: blueGrey[200]
  };
  return {
    padding: isSmall || !$hasPadding ? 0 : iconButtonPadding[$size],
    borderRadius: isSmall || !$hasPadding ? '50%' : undefined,
    width: 'fit-content',
    backgroundColor: isSmall && !$hasPadding ? iconColor.default : 'transparent',
    '& svg': {
      height: iconSize,
      width: iconSize,
      strokeWidth: '2px',
      fill: isSmall && !$hasPadding ? white : iconColor.default
    },
    '&:hover': {
      backgroundColor: isSmall && !$hasPadding ? iconColor.hover : 'transparent',
      '& svg': {
        fill: isSmall && !$hasPadding ? white : iconColor.hover
      }
    },
    '&:active': {
      backgroundColor: isSmall && !$hasPadding ? iconColor.active : 'transparent',
      '& svg': {
        fill: isSmall && !$hasPadding ? white : iconColor.active
      }
    },
    '&[disabled]': {
      backgroundColor: isSmall && !$hasPadding ? iconColor.disabled : 'transparent',
      '& svg': {
        fill: isSmall && !$hasPadding ? white : iconColor.disabled
      }
    }
  };
});