import { css } from 'styled-components';
import { breakpointQueries } from './breakpoints';

type cssParams = Parameters<typeof css>;

export const media = {
  PhoneOnly: (...args: cssParams) => css`
    @media ${breakpointQueries.phoneOnly} {
      ${css(...args)};
    }
  `,
  TabletPortraitUp: (...args: cssParams) => css`
    @media ${breakpointQueries.tabletPortraitUp} {
      ${css(...args)};
    }
  `,
  TabletPortraitDown: (...args: cssParams) => css`
    @media ${breakpointQueries.tabletPortraitDown} {
      ${css(...args)};
    }
  `,
  TabletLandscapeUp: (...args: cssParams) => css`
    @media ${breakpointQueries.tabletLandscapeUp} {
      ${css(...args)};
    }
  `,
  TabletLandscapeDown: (...args: cssParams) => css`
    @media ${breakpointQueries.tabletLandscapeDown} {
      ${css(...args)};
    }
  `,
  DesktopUp: (...args: cssParams) => css`
    @media ${breakpointQueries.desktopUp} {
      ${css(...args)};
    }
  `,
  BigDesktopUp: (...args: cssParams) => css`
    @media ${breakpointQueries.bigDesktopOnly} {
      ${css(...args)};
    }
  `,
};
