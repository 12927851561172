/**
 * Takes a URL and separates its hostname into subdomain, sld (second-level
 * domain) and tld (top-level domain) and returns these as an object.
 * If the hostname does not have a subdomain it will substitute an empty string
 * for this section.
 * NOTE: This function will ONLY work with hostnames that are 2 or 3 sections
 * long. It will not, for example, work with international TLDs like `co.uk`
 * @returns {{subdomain: string, sld: string, tld: string}}
 */
export function getLAHostnameParts(): {
  subdomain: string;
  sld: string;
  tld: string;
} {
  const hostname = window.location.hostname;
  const hostnameArray = hostname.split('.');
  return {
    subdomain: hostnameArray[hostnameArray.length - 3] ?? '',
    sld: hostnameArray[hostnameArray.length - 2],
    tld: hostnameArray[hostnameArray.length - 1],
  };
}

export const apiDomainBySiteDomain = new Map([
  ['lapps-cicd1', 'lapps-cicd1.io'],
  ['lapps-dev1', 'lapps-dev1.io'],
  ['lapps-dev2', 'sportvite.net'],
  ['lapps-dev3', 'lapps-dev3.io'],
  ['lapps-eng', 'lapps-eng.net'],
  ['lapps-local', 'lapps-local.com'],
  ['lapps-preview', 'lapps-preview.net'],
  ['lapps-qa1', 'lapps-qa1.io'],
  ['lapps-qa2', 'lmstaging.com'],
  ['lapps-sandbox', 'lapps-sandbox.io'],
  ['leagueapps', 'leagueapps.io'],
]);

export function getBaseURL() {
  const { subdomain, sld, tld } = getLAHostnameParts();
  const apiDomain = apiDomainBySiteDomain.get(sld);
  return apiDomain
    ? `https://api.${apiDomain}`
    : `https://${subdomain}.${sld}.${tld}`;
}

/**
 * Checks if `str` is in a valid email format (aaa@bbb.ccc).
 * @param str string to be compared to email format.
 * @returns true if in valid email format, false otherwise.
 */
export enum Env {
  CICD1,
  Local,
  Dev1,
  Dev2,
  Dev3,
  QA1,
  QA2,
  Preview,
  Production,
}

export enum Domain {
  CICD1 = 'lapps-cicd1',
  Local = 'lapps-local',
  Dev1 = 'lapps-dev1',
  Dev2 = 'lapps-dev2',
  Dev3 = 'lapps-dev3',
  QA1 = 'lapps-qa1',
  QA2 = 'lapps-qa2',
  Preview = 'lapps-preview',
  Production = 'leagueapps',
}

export function getEnv(): Env {
  const { sld } = getLAHostnameParts();
  switch (sld) {
    case Domain.Production:
      return Env.Production;
    case Domain.Dev1:
      return Env.Dev1;
    case Domain.Dev2:
      return Env.Dev2;
    case Domain.Dev3:
      return Env.Dev3;
    case Domain.QA1:
      return Env.QA1;
    case Domain.QA2:
      return Env.QA2;
    case Domain.Local:
      return Env.Local;
    case Domain.CICD1:
      return Env.CICD1;
    case Domain.Preview:
      return Env.Preview;
    default:
      return Env.Local;
  }
}
