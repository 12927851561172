"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
var react_1 = require("react");
// Adapted from https://usehooks-ts.com/react-hook/use-media-query
function useMediaQuery(query) {
    var getMatches = function (query) {
        // Prevents SSR issues
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }
        return false;
    };
    var _a = (0, react_1.useState)(getMatches(query)), matches = _a[0], setMatches = _a[1];
    (0, react_1.useEffect)(function () {
        function handleChange() {
            setMatches(getMatches(query));
        }
        var matchMedia = window.matchMedia(query);
        // Triggered at the first client-side load and if query changes
        handleChange();
        /*
         * Before Safari 14, MediaQueryList is based on EventTarget and only supports
         * addListener/removeListener for media queries.  Read more about this on MDN:
         * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
         */
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        }
        else {
            matchMedia.addEventListener('change', handleChange);
        }
        return function () {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            }
            else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
    }, [query]);
    return matches;
}
exports.useMediaQuery = useMediaQuery;
