import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { MessagingContext } from 'lib/context/MessagingContext';
import Portal from '../Portal/Portal';
import Toast, { ToastContainer } from '../Toast/Toast';

export default function MessagingHub() {
  const { messages, deleteMessage } = useContext(MessagingContext);
  const messagesToDisplay: any[] = [];

  function handleMessageClosed(messageUUID: string) {
    deleteMessage(messageUUID);
  }

  messages.forEach((message) => {
    switch (message.messageType) {
      case 'toast':
        messagesToDisplay.push(
          <Portal parentId="#toast-container" key={message.messageUUID}>
            <Toast
              description={message.description}
              duration={message.duration}
              id={message.id}
              onClose={() => handleMessageClosed(message.messageUUID)}
              testId={message.testId}
              variant={message.variant}
            />
          </Portal>
        );
        break;
      default:
        break;
    }
  });

  return (
    <>
      {createPortal(<ToastContainer />, document.body)}
      {messagesToDisplay}
    </>
  );
}
