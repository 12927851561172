export const validateFiles = ({
  files,
  multiple,
  maxFiles,
  accept,
  maxSize,
  minSize
}) => {
  if (files.length > 1 && !multiple) {
    return {
      code: 'more-than-one-file',
      filename: null
    };
  }
  if (files.length > maxFiles) {
    return {
      code: 'too-many-files',
      filename: null
    };
  }
  for (const file of files) {
    const extension = `.${file.name.split('.').at(-1)}`;
    if (!accept.includes(extension)) {
      return {
        code: 'file-invalid-type',
        filename: file.name
      };
    }
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        filename: file.name
      };
    }
    if (file.size < minSize) {
      return {
        code: 'file-too-small',
        filename: file.name
      };
    }
  }
  return {
    code: 'no-errors',
    filename: null
  };
};