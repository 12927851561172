import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

export const ActionList = styled.ul({
  listStyle: 'none',
  margin: '-16px -24px',
  padding: 0,
});

export const TriggerIcon = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'inline-flex',
    alignItems: 'center',

    svg: {
      stroke: theme.semantic?.secondary.reactiveTextColor,
    },
  })
);

export const Item = styled.li({
  margin: 0,
  padding: 0,
});

export const ItemTrigger = styled.button<ThemedComponent>(({ theme }) => ({
  ...theme.typography.ui.large,
  backgroundColor: 'transparent',
  display: 'block',
  width: '100%',
  margin: 0,
  padding: '8px 28px',
  textAlign: 'left',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.semantic?.primary[50],
  },
}));
