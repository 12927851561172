"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionSummaryCardTeamsTable = exports.DivisionSummaryCardTeamsTableContainer = exports.ShowAndHideButtonIcon = exports.DivisionSummaryCardMainSection = exports.DivisionSummaryCardHeaderName = exports.DivisionSummaryCardHeader = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.DivisionSummaryCardHeader = (0, styled_components_1.default)(ds_ui_components_1.CardHeader)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n"])));
exports.DivisionSummaryCardHeaderName = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-right: 16px;\n  overflow-wrap: anywhere;\n"], ["\n  padding-right: 16px;\n  overflow-wrap: anywhere;\n"])));
exports.DivisionSummaryCardMainSection = (0, styled_components_1.default)(ds_ui_components_1.CardBody)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"])));
exports.ShowAndHideButtonIcon = (0, styled_components_1.default)(ds_ui_components_1.CaretDownIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  transition: transform 0.2s ease;\n\n  ", "\n"], ["\n  transition: transform 0.2s ease;\n\n  ", "\n"])), function (_a) {
    var $isRotated = _a.$isRotated;
    return $isRotated
        ? "\n      transform: rotate(180deg);\n    "
        : null;
});
/*
 * The table and table container classes were copied from the checkout summary and tweaked to
 * follow the newest style guidelines where the table header has a colored background.
 */
exports.DivisionSummaryCardTeamsTableContainer = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 16px 24px;\n  background-color: var(--primary-35);\n  border-radius: 0px 0px 4px 4px;\n"], ["\n  padding: 16px 24px;\n  background-color: var(--primary-35);\n  border-radius: 0px 0px 4px 4px;\n"])));
exports.DivisionSummaryCardTeamsTable = styled_components_1.default.table(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  background: var(--white);\n  border: 1px solid var(--primary-600);\n  border-spacing: 0px;\n  border-radius: 4px;\n  font: var(--ui-medium-medium);\n  color: var(--blue-grey-900);\n\n  thead {\n    color: var(--primary-600);\n    background-color: var(--primary-25);\n    border: 1px solid transparent;\n    tr:first-child {\n      th,\n      td {\n        border-bottom: 1px solid var(--primary-300);\n      }\n    }\n  }\n\n  tbody tr td:first-child {\n    width: 35%;\n  }\n\n  th,\n  td {\n    padding: 7px 0px 7px 12px;\n    text-align: left;\n  }\n\n  th:first-child {\n    border-top-left-radius: 4px;\n  }\n\n  th:last-child {\n    border-top-right-radius: 4px;\n  }\n\n  th:last-child,\n  td:last-child {\n    padding: 7px 12px;\n  }\n"], ["\n  width: 100%;\n  background: var(--white);\n  border: 1px solid var(--primary-600);\n  border-spacing: 0px;\n  border-radius: 4px;\n  font: var(--ui-medium-medium);\n  color: var(--blue-grey-900);\n\n  thead {\n    color: var(--primary-600);\n    background-color: var(--primary-25);\n    border: 1px solid transparent;\n    tr:first-child {\n      th,\n      td {\n        border-bottom: 1px solid var(--primary-300);\n      }\n    }\n  }\n\n  tbody tr td:first-child {\n    width: 35%;\n  }\n\n  th,\n  td {\n    padding: 7px 0px 7px 12px;\n    text-align: left;\n  }\n\n  th:first-child {\n    border-top-left-radius: 4px;\n  }\n\n  th:last-child {\n    border-top-right-radius: 4px;\n  }\n\n  th:last-child,\n  td:last-child {\n    padding: 7px 12px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
