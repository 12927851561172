import styled from 'styled-components/macro';

export const Backdrop = styled.div<{ $transparent: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $transparent }) =>
    $transparent ? 'var(--transparent-overlay)' : 'var(--translucent-overlay)'};
  z-index: var(--modal-z);
`;
