"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDivisionCardsStateArray = exports.SummaryAndSubmitSmallerScreens = exports.SummaryAndSubmitLargerScreens = exports.SUBMIT_IN_MODAL_BUTTON_TEST_ID = exports.DIVISIONS_SUMMARY_MODAL_TITLE = exports.SUBTOTAL_LABEL = exports.HIDE_SUMMARY_CARDS_BUTTON_TEXT = exports.VIEW_SUMMARY_CARDS_BUTTON_TEXT = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var WizardState_1 = require("../../WizardState");
var WizardSubtotal_1 = require("./WizardSubtotal/WizardSubtotal");
var WizardSummary_1 = require("./WizardSummary/WizardSummary");
var S = require("./SummaryAndSubmit.styles");
exports.VIEW_SUMMARY_CARDS_BUTTON_TEXT = 'View added divisions';
exports.HIDE_SUMMARY_CARDS_BUTTON_TEXT = 'Hide details';
exports.SUBTOTAL_LABEL = 'Subtotal:';
exports.DIVISIONS_SUMMARY_MODAL_TITLE = 'Added divisions';
exports.SUBMIT_IN_MODAL_BUTTON_TEST_ID = 'add-to-cart-from-summary-modal-button';
function SummaryAndSubmitLargerScreens(props) {
    var divisionCardsState = props.divisionCardsState, onSubmissionErrors = props.onSubmissionErrors, submitButtonText = props.submitButtonText, onSubmit = props.onSubmit;
    var handleSubmit = function () {
        if ((0, WizardState_1.hasSubmissionErrors)(divisionCardsState)) {
            onSubmissionErrors();
        }
        else {
            onSubmit();
        }
    };
    var divisionsForSummarySection = getDivisionsToShowSummaryForSmallerScreens(divisionCardsState);
    return ((0, jsx_runtime_1.jsxs)(S.SummaryAndSubmit, { children: [(0, jsx_runtime_1.jsx)(S.SummaryPanel, { children: (0, jsx_runtime_1.jsx)(WizardSummary_1.WizardSummary, __assign({}, props)) }), !props.isMC &&
                divisionsForSummarySection.length > 0 &&
                divisionsForSummarySection.some(function (division) { return division.savedTeams && division.savedTeams.length > 0; }) ? ((0, jsx_runtime_1.jsx)(S.SubmitSection, { children: (0, jsx_runtime_1.jsx)(S.SubmitButton, __assign({ onClick: handleSubmit }, { children: submitButtonText })) })) : null] }));
}
exports.SummaryAndSubmitLargerScreens = SummaryAndSubmitLargerScreens;
function SummaryAndSubmitSmallerScreens(props) {
    var divisionCardsState = props.divisionCardsState, onSubmissionErrors = props.onSubmissionErrors, submitButtonText = props.submitButtonText, tournament = props.tournament, onSubmit = props.onSubmit, isMC = props.isMC;
    var _a = (0, react_1.useState)(false), isShowingDivisionSummaries = _a[0], setIsShowingDivisionSummaries = _a[1];
    var handleShowAddedDivisionsButtonClick = function () {
        setIsShowingDivisionSummaries(true);
    };
    var handleOpenChange = function (open) {
        setIsShowingDivisionSummaries(open);
    };
    var divisionsForSummarySection = getDivisionsToShowSummaryForSmallerScreens(props.divisionCardsState);
    var handleSubmit = function () {
        if ((0, WizardState_1.hasSubmissionErrors)(divisionCardsState)) {
            if (isShowingDivisionSummaries) {
                setIsShowingDivisionSummaries(false);
                // Delay needed to allow the modal to close first. Otherwise the
                // scrolling behavior fails.
                setTimeout(onSubmissionErrors, 1);
            }
            else {
                onSubmissionErrors();
            }
        }
        else {
            onSubmit();
        }
    };
    var viewAddedDivisionsButtonRef = (0, react_1.useRef)(null);
    var summariesModal = ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Modal, __assign({ open: isShowingDivisionSummaries, onOpenChange: handleOpenChange, primaryAction: !isMC ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalPrimaryActionButton, __assign({ onClick: handleSubmit, "data-testid": exports.SUBMIT_IN_MODAL_BUTTON_TEST_ID }, { children: submitButtonText }))) : null, secondaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalClose, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalSecondaryActionButton, { children: exports.HIDE_SUMMARY_CARDS_BUTTON_TEXT }) }), title: exports.DIVISIONS_SUMMARY_MODAL_TITLE, trigger: !isMC ? ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ onClick: handleShowAddedDivisionsButtonClick, variant: "outline", ref: viewAddedDivisionsButtonRef, width: "100%" }, { children: exports.VIEW_SUMMARY_CARDS_BUTTON_TEXT }))) : null }, { children: (0, jsx_runtime_1.jsx)(WizardSummary_1.WizardSummary, __assign({}, props)) })));
    // Action buttons are only shown when at least one division has been saved
    if (divisionsForSummarySection.length > 0 &&
        divisionsForSummarySection.some(function (division) { return division.savedTeams && division.savedTeams.length > 0; })) {
        return ((0, jsx_runtime_1.jsxs)(S.ActionButtons, { children: [(0, jsx_runtime_1.jsx)(WizardSubtotal_1.WizardSubtotal, { divisionCardsState: divisionCardsState, tournament: tournament, isMC: props.isMC }), summariesModal, (0, jsx_runtime_1.jsx)(S.SubmitSection, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ onClick: handleSubmit, width: "100%" }, { children: submitButtonText })) })] }));
    }
    return null;
}
exports.SummaryAndSubmitSmallerScreens = SummaryAndSubmitSmallerScreens;
function getDivisionsToShowSummaryForSmallerScreens(divisionCardsState) {
    return getDivisionCardsStateArray(divisionCardsState).filter(function (divisionCardState) {
        return divisionCardState.value === 'saved' ||
            divisionCardState.value === 'saved-and-editing';
    });
}
function getDivisionCardsStateArray(divisionCardsState) {
    return Object.entries(divisionCardsState).map(function (_a) {
        var id = _a[0], divisionCardState = _a[1];
        return (__assign({ id: id }, divisionCardState));
    });
}
exports.getDivisionCardsStateArray = getDivisionCardsStateArray;
