const ACTION_KEYS = ['ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', ' '];

export const moveFocusOnKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>,
  container: HTMLDivElement | null
) => {
  // select should not be navigated using tab key so we prevent it
  if (event.key === 'Tab') {
    event.preventDefault();
  }

  if (!container) {
    return;
  }

  const options = Array.from(
    container.querySelectorAll<HTMLOptionElement>('div[role="option"]')
  ).filter((item) => !item.getAttribute('data-disabled'));

  if (ACTION_KEYS.includes(event.key)) {
    const currentElement = event.target as HTMLOptionElement;
    const currentIndex = options.indexOf(currentElement);
    switch (event.key) {
      case 'Home': {
        options[0].focus();
        break;
      }
      case 'End': {
        options[options.length - 1].focus();
        break;
      }
      case 'ArrowUp': {
        options[
          currentIndex < 1 ? options.length - 1 : currentIndex - 1
        ].focus();
        break;
      }
      case 'ArrowDown': {
        options[(currentIndex + 1) % options.length].focus();
        break;
      }

      case ' ': {
        options[currentIndex].click();
        break;
      }

      case 'Enter': {
        options[currentIndex].click();
        break;
      }
    }

    event.preventDefault();
  }
};
