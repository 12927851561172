"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCost = exports.SpotsLeftWarningMessage = exports.SpotsLeftMessage = exports.TitleAndSpotsLeft = exports.RegistrationStartDate = exports.DivisionCardHeaderUnavailable = exports.DivisionCardHeader = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var mixins_1 = require("../../../../../../lib/media-queries/mixins");
exports.DivisionCardHeader = (0, styled_components_1.default)(ds_ui_components_1.CardHeader)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  overflow-wrap: anywhere;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  overflow-wrap: anywhere;\n"])));
exports.DivisionCardHeaderUnavailable = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'large',
    variant: 'ui',
    weight: 'bold',
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: var(--blue-grey-600);\n  text-align: right;\n"], ["\n  color: var(--blue-grey-600);\n  text-align: right;\n"])));
exports.RegistrationStartDate = styled_components_1.default.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n"], ["\n  white-space: nowrap;\n"])));
exports.TitleAndSpotsLeft = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_4 || (templateObject_4 = __makeTemplateObject(["  \n    align-items: flex-end;\n    flex-direction: row;\n  "], ["  \n    align-items: flex-end;\n    flex-direction: row;\n  "]))));
exports.SpotsLeftMessage = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'small',
    variant: 'ui',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: var(--blue-grey-900);\n"], ["\n  color: var(--blue-grey-900);\n"])));
exports.SpotsLeftWarningMessage = (0, styled_components_1.default)(exports.SpotsLeftMessage).attrs({
    weight: 'bold',
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: var(--red);\n"], ["\n  color: var(--red);\n"])));
exports.DivisionCost = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  white-space: nowrap;\n  margin-left: 8px;\n"], ["\n  white-space: nowrap;\n  margin-left: 8px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
