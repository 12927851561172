"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Info = exports.TOURNAMENT_INFO_HEIGHT = void 0;
var styled_components_1 = require("styled-components");
var constants_1 = require("../../../../../lib/constants");
var mixins_1 = require("../../../../../lib/media-queries/mixins");
// This is exported so other components can calculate their height or positioning
// based on this.
exports.TOURNAMENT_INFO_HEIGHT = 60;
exports.Info = styled_components_1.default.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  background: var(--primary-35);\n  display: flex;\n  align-items: center;\n  padding: 8px 12px;\n  color: var(--blue-grey-900);\n  font: var(--ui-large-bold);\n  border-bottom: 1px solid var(--primary-100);\n  // Without this some of the content that was supposed to scroll underneath\n  // was showing up on top.\n  z-index: 1;\n\n  position: sticky;\n  top: 0;\n\n  ", "\n\n  ", "\n"], ["\n  width: 100%;\n  background: var(--primary-35);\n  display: flex;\n  align-items: center;\n  padding: 8px 12px;\n  color: var(--blue-grey-900);\n  font: var(--ui-large-bold);\n  border-bottom: 1px solid var(--primary-100);\n  // Without this some of the content that was supposed to scroll underneath\n  // was showing up on top.\n  z-index: 1;\n\n  position: sticky;\n  top: 0;\n\n  ", "\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", "px;\n    padding: 16px 24px;\n    font: var(--ui-xtra-large-bold);\n  "], ["\n    height: ", "px;\n    padding: 16px 24px;\n    font: var(--ui-xtra-large-bold);\n  "])), exports.TOURNAMENT_INFO_HEIGHT), mixins_1.media.TabletLandscapeUp(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    top: ", "px;\n  "], ["\n    top: ", "px;\n  "])), constants_1.SITE_HEADER_HEIGHT));
var templateObject_1, templateObject_2, templateObject_3;
