import { Root } from '@radix-ui/react-radio-group';
import styled from 'styled-components';
const radioGroupGaps = {
  small: '8px',
  large: '16px'
};
export const RadioGroupContainer = styled(Root).withConfig({
  componentId: "sc-r83dl8-0"
})(["display:flex;flex-direction:", ";gap:", ";padding:4px;"], ({
  $isHorizontal
}) => $isHorizontal ? 'row' : 'column', ({
  $size,
  $isHorizontal
}) => !$isHorizontal ? radioGroupGaps[$size] : '36px');