"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamRepInfo = exports.Title = exports.HorizontalInputGroup = exports.CreateTeamForm = exports.SelectTemporaryPositionContainer = void 0;
var react_tooltip_1 = require("@radix-ui/react-tooltip");
var styled_components_1 = require("styled-components");
// Currently Radix's select is diverging from the other inputs by an upper margin of 4px we need this container until DS patch this
exports.SelectTemporaryPositionContainer = styled_components_1.default.div({
    marginTop: '-4px',
});
exports.CreateTeamForm = styled_components_1.default.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
exports.HorizontalInputGroup = styled_components_1.default.div(function (_a) {
    var $isMobile = _a.$isMobile;
    return {
        display: 'flex',
        flexDirection: $isMobile ? 'column' : 'row',
        gap: '8px',
        '& > div': {
            flex: 1,
        },
    };
});
exports.Title = styled_components_1.default.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 4px 0 0 0;\n\n  svg {\n    fill: var(--secondary);\n    margin: 0 0 0 8px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 4px 0 0 0;\n\n  svg {\n    fill: var(--secondary);\n    margin: 0 0 0 8px;\n  }\n"])));
exports.TeamRepInfo = (0, styled_components_1.default)(react_tooltip_1.Content)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: var(--blue-grey-900);\n  z-index: var(--modal-z);\n  max-width: 184px;\n  padding: 12px 8px;\n  color: var(--white);\n  font: var(--ui-small-medium);\n  text-align: center;\n  border-radius: 2px;\n\n  .TooltipArrow {\n    fill: var(--blue-grey-900);\n  }\n"], ["\n  background: var(--blue-grey-900);\n  z-index: var(--modal-z);\n  max-width: 184px;\n  padding: 12px 8px;\n  color: var(--white);\n  font: var(--ui-small-medium);\n  text-align: center;\n  border-radius: 2px;\n\n  .TooltipArrow {\n    fill: var(--blue-grey-900);\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
