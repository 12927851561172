import { useState } from 'react';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useRemoveMemberFromRosterMutation } from 'redux/services/rosterManagementApi';
import { TableType } from './RosterTable';

/* RosterSection */
export default function DeleteRegistrantModal({
  userId,
  memberName,
  programId,
  tableType,
  teamId,
  onRemoveSuccess,
  onRemoveError,
  closeModal,
}: {
  userId: number;
  memberName: string;
  programId: string;
  tableType: TableType;
  teamId: string;
  onRemoveSuccess: () => void;
  onRemoveError: () => void;
  closeModal: () => void;
}) {
  const siteSubdomain = getLAHostnameParts().subdomain;
  const memberType = tableType === 'players' ? 'player' : 'staff member';

  const [isRemoveMemberLoading, setIsRemovePlayerLoading] =
    useState<boolean>(false);

  const [removeMemberFromRoster] = useRemoveMemberFromRosterMutation();

  const removeMember = () => {
    setIsRemovePlayerLoading(true);

    removeMemberFromRoster({
      siteSubdomain,
      programId,
      teamId,
      userId: userId.toString(),
    })
      .unwrap()
      .then(onRemoveSuccess)
      .catch(onRemoveError)
      .finally(() => {
        setIsRemovePlayerLoading(false);
        closeModal();
      });
  };

  return (
    <>
      <Modal
        hideClose={false}
        open={true}
        onOpenChange={closeModal}
        primaryAction={
          <ModalPrimaryActionButton
            loading={isRemoveMemberLoading}
            onClick={removeMember}
          >{`Remove ${memberType}`}</ModalPrimaryActionButton>
        }
        tertiaryAction={
          <ModalClose>
            <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
          </ModalClose>
        }
        size="small"
        title={`Remove ${memberType} from roster?`}
      >
        Are you sure you want to remove <strong>{memberName}</strong> from your
        roster? You will need to re-send their invitation if you remove them by
        mistake.
      </Modal>
    </>
  );
}
/* */
