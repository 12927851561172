"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackButtonContainer = exports.ForwardButtonContainer = exports.DrawerContent = exports.BottomDrawer = void 0;
var styled_components_1 = require("styled-components");
var mixins_1 = require("../../lib/media-queries/mixins");
exports.BottomDrawer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);\n  border-top: 1px solid;\n  border-color: var(--primary-500);\n  background-color: white;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);\n  border-top: 1px solid;\n  border-color: var(--primary-500);\n  background-color: white;\n  z-index: 1000;\n"])));
exports.DrawerContent = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px;\n  padding: 12px 16px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px;\n  padding: 12px 16px;\n"])));
exports.ForwardButtonContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  ", "\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  ", "\n"])), mixins_1.media.TabletPortraitDown(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 75%;\n    & div {\n      width: 100%;\n    }\n  "], ["\n    width: 75%;\n    & div {\n      width: 100%;\n    }\n  "]))));
exports.BackButtonContainer = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  ", "\n  & svg > polyline {\n    stroke: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  ", "\n  & svg > polyline {\n    stroke: ", ";\n  }\n"])), mixins_1.media.TabletPortraitDown(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 25%;\n    & div {\n      width: 100%;\n    }\n  "], ["\n    width: 25%;\n    & div {\n      width: 100%;\n    }\n  "]))), function (_a) {
    var $disabled = _a.$disabled;
    return $disabled ? 'var(--blue-grey-400)' : 'var(--blue-grey-900)';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
