"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionTitle = exports.WizardAddDivisionsAndTeamsSection = exports.WizardContent = exports.WIZARD_CONTENT_PADDING = void 0;
var styled_components_1 = require("styled-components");
var ColumnGrid_1 = require("../../../components/ColumnGrid/ColumnGrid");
var mixins_1 = require("../../../lib/media-queries/mixins");
exports.WIZARD_CONTENT_PADDING = 24;
exports.WizardContent = (0, styled_components_1.default)(ColumnGrid_1.ColumnGrid)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  // padding: 12px;\n\n  ", "\n\n  ", "\n"], ["\n  flex: 1;\n  // padding: 12px;\n\n  ", "\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    // padding: 16px;\n  "], ["\n    // padding: 16px;\n  "]))), mixins_1.media.TabletLandscapeUp(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    // padding-top: ", "px;\n    // padding-bottom: ", "px;\n    // margin-bottom: 30px;\n  "], ["\n    // padding-top: ", "px;\n    // padding-bottom: ", "px;\n    // margin-bottom: 30px;\n  "])), exports.WIZARD_CONTENT_PADDING, exports.WIZARD_CONTENT_PADDING));
exports.WizardAddDivisionsAndTeamsSection = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-column: 1 / -1;\n\n  ", "\n"], ["\n  grid-column: 1 / -1;\n\n  ", "\n"])), mixins_1.media.TabletLandscapeUp(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    grid-column-end: 12;\n  "], ["\n    grid-column-end: 12;\n  "]))));
exports.SectionTitle = styled_components_1.default.h3(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0;\n  // margin-bottom: 12px;\n  color: var(--blue-grey-900);\n  font: var(--headline-small);\n\n  ", "\n"], ["\n  margin: 0;\n  // margin-bottom: 12px;\n  color: var(--blue-grey-900);\n  font: var(--headline-small);\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    // margin-bottom: 20px;\n  "], ["\n    // margin-bottom: 20px;\n  "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
