import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PaymentMethodSubtitle = styled.div`
  font: var(--ui-large-bold);
  color: var(--blue-grey-900);
  margin-bottom: 8px;
`;

export const PaymentMethodBadges = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  overflow-x: auto;

  ${media.TabletPortraitUp`
    gap: 16px;
  `};
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  ${media.TabletPortraitUp`
   flex-direction: row;
  `};
`;
