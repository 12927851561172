"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppDispatch = exports.useAppSelector = exports.store = void 0;
var react_redux_1 = require("react-redux");
var toolkit_1 = require("@reduxjs/toolkit");
var lodash_1 = require("lodash");
var checkoutSlice_1 = require("./checkoutSlice");
var coreSlice_1 = require("./coreSlice");
var localStorage_1 = require("./localStorage");
var baseApi_1 = require("./services/baseApi");
var userSlice_1 = require("./userSlice");
exports.store = (0, toolkit_1.configureStore)({
    reducer: (_a = {
            core: coreSlice_1.default,
            checkout: checkoutSlice_1.default,
            user: userSlice_1.default
        },
        _a[baseApi_1.default.reducerPath] = baseApi_1.default.reducer,
        _a),
    preloadedState: (0, localStorage_1.loadStateFromLocalStorage)('app-store'),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(baseApi_1.default.middleware);
    },
});
// NOTE: This will put information from the Redux store into localstorage
// which can be accessed even after a user has left the site if they don't
// log out. Be careful about exposing any Personal Identifiable Information
// (PII) and if you have any questions about the data being stored in
// localstorage this way clear it first with Infosec.
exports.store.subscribe(
// we use debounce to save the state once each 800ms
// for better performances in case multiple changes occur in a short time
(0, lodash_1.debounce)(function () {
    (0, localStorage_1.saveStateToLocalStorage)('app-store', {
        //This limits which slices of the state we are serializing in localstorage
        checkout: exports.store.getState().checkout,
    });
}, 800));
exports.useAppSelector = react_redux_1.useSelector;
var useAppDispatch = function () { return (0, react_redux_1.useDispatch)(); }; // Export a hook that can be reused to resolve types
exports.useAppDispatch = useAppDispatch;
