import styled from 'styled-components/macro';
import { ReactComponent as HamburgerMenu } from 'assets/icons/icon_hamburger.svg';

export const HamburgerMenuButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 6px 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: var(--secondary-600);
  }
`;

export const HamburgerMenuIcon = styled(HamburgerMenu)`
  width: 24px;
  height: 19px;
  fill: var(--secondary-900);
`;
