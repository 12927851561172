"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOptionBody = exports.PaymentOptionInstallments = exports.ExpandButton = exports.ExpandOptionDetails = exports.RadioButton = exports.PaymentOptionHeader = exports.PaymentOption = exports.PaymentOptionsInnerContainer = exports.PaymentOptionsContainer = void 0;
var styled_components_1 = require("styled-components");
exports.PaymentOptionsContainer = styled_components_1.default.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    '& > p': {
        marginBottom: '16px',
    },
});
exports.PaymentOptionsInnerContainer = styled_components_1.default.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});
exports.PaymentOption = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    var palette = theme.palette;
    return {
        border: "1px solid ".concat(palette.foundational.blueGrey[400]),
        borderRadius: '4px',
        width: '100%',
        backgroundColor: palette.foundational.white,
    };
});
exports.PaymentOptionHeader = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    return {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        '& > label': __assign(__assign({}, theme.typography.ui.large), { flexGrow: 1, cursor: 'pointer', marginTop: 4 }),
    };
});
exports.RadioButton = styled_components_1.default.input({
    height: '24px',
    width: '24px',
});
exports.ExpandOptionDetails = styled_components_1.default.div({
    display: 'flex',
    alignSelf: 'center',
});
exports.ExpandButton = styled_components_1.default.button(function (_a) {
    var theme = _a.theme, $isOpen = _a.$isOpen;
    return __assign(__assign({ all: 'unset' }, theme.typography.ui.small), { cursor: 'pointer', textDecoration: 'underline', '& > svg': {
            marginBottom: '-4px',
            marginLeft: '2px',
            fill: '#CC0000',
            transform: $isOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: '180ms linear',
            willChange: 'transform',
        } });
});
exports.PaymentOptionInstallments = styled_components_1.default.div({
    marginLeft: '48px',
    marginBottom: '8px',
    marginTop: '-4px',
});
exports.PaymentOptionBody = styled_components_1.default.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '12px',
    marginLeft: '36px',
});
