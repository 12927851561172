/**
 * Function to determine the width of text given the document body font
 * @param text string
 * @returns width of text
 */
export function getTextWidth(text) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = getComputedStyle(document.body).font;
    return Math.ceil(context.measureText(text).width);
  }
  return 0;
}