export const variantSizes = {
  large: '20px',
  small: '12px'
};
export const labelPaddings = {
  large: '8px',
  small: '4px'
};
export const indicatorSizes = {
  large: 12,
  small: 8
};
export const getComputedIndicatorMargin = size => {
  const offset = Math.round(indicatorSizes[size] / 2);
  return `-${offset}px 0 0 -${offset}px`;
};