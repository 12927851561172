import { MutableRefObject, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { MainContent } from 'domains/Shell/MainContent/MainContent.styles';
import { useTournament } from '../Registration/hooks/useTournament';
import { RegistrationPeriodStatus } from '../TournamentDetailsPage/TournamentDetailsPage';
import { PageTitleSection } from '../TournamentDetailsPage/components/PageTitleSection/PageTitleSection';
import { RegisterSection } from '../TournamentDetailsPage/components/RegisterSection/RegisterSection';
import { SecondaryNavigationBar } from '../TournamentDetailsPage/components/SecondaryNavigationBar/SecondaryNavigationBar';
import { getRegistrationPeriodStatus } from '../TournamentDetailsPage/utils/registration';
import { TOURNAMENT_NOT_FOUND_ERROR_MESSAGE } from '../utils/errorMessages';
import { DivisionsDisplay } from './components/DivisionsDisplay/DivisionsDisplay';
import { DivisionsSideMenu } from './components/DivisionsSideMenu/DivisionsSideMenu';
import * as S from './TournamentParticipantsPage.styles';

export function TournamentParticipantsPage() {
  const { tournamentId } = useParams();
  const [divisionsDisplaysRefs, setDivisionsDisplaysRefs] = useState(
    new Map<string, MutableRefObject<HTMLElement | null>>()
  );

  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  if (!tournamentId) {
    throw new Error('Tournament ID is not defined');
  }

  const { tournament, isLoading } = useTournament({ id: tournamentId });

  const navigate = useNavigate();

  const updateRefs = useCallback(
    (divisionId: string, ref: MutableRefObject<HTMLElement | null>) => {
      const refs = divisionsDisplaysRefs;

      refs.set(divisionId, ref);

      setDivisionsDisplaysRefs(refs);
    },
    [divisionsDisplaysRefs]
  );

  if (isLoading) {
    return <Loader loading={true} />;
  }

  if (!tournament) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  const {
    name,
    logoURL,
    registrationStartDate,
    registrationEndDate,
    sport,
    timeZone,
    divisions,
  } = tournament;

  const registrationLink = `/app/tournaments/${tournamentId}/registration`;

  const navigateToRegistration = () => {
    navigate(registrationLink);
  };

  const noDivisionsRegistrationPeriodState: RegistrationPeriodStatus = {
    state: 'no-divisions',
  };
  const registrationPeriodStatus = divisions.length
    ? getRegistrationPeriodStatus(
        registrationStartDate,
        registrationEndDate,
        timeZone
      )
    : noDivisionsRegistrationPeriodState;

  const registerSection = (
    <RegisterSection
      navigateToRegistration={navigateToRegistration}
      registrationLink={registrationLink}
      registrationPeriodStatus={registrationPeriodStatus}
    />
  );

  const divisionsToShow = divisions.filter(
    (division) => division.shouldDisplay !== false
  );

  return (
    <>
      <MainContent>
        <PageTitleSection
          logoUrl={logoURL}
          sport={sport}
          tournamentName={name}
        />
        <SecondaryNavigationBar
          hasVisibleTeams={divisionsToShow.length > 0}
          registerSection={isTabletLandscapeUp ? registerSection : null}
          tournamentId={tournamentId}
        />
        <S.ParticipantsSection>
          <DivisionsSideMenu
            divisions={divisionsToShow}
            refs={divisionsDisplaysRefs}
          />
          <DivisionsDisplay
            addRef={updateRefs}
            divisions={divisionsToShow}
            refs={divisionsDisplaysRefs}
          />
        </S.ParticipantsSection>
      </MainContent>
      <S.RegisterSectionContainer>{registerSection}</S.RegisterSectionContainer>
    </>
  );
}
