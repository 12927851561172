"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BottomDrawer = exports.NavigationItemContainer = exports.NavigationPanelInnerContainer = exports.NavigationPanelContainer = void 0;
var styled_components_1 = require("styled-components");
exports.NavigationPanelContainer = styled_components_1.default.div({
    display: 'block',
    marginRight: 16,
    minHeight: '100vh',
});
exports.NavigationPanelInnerContainer = styled_components_1.default.div({
    position: 'sticky',
    top: 56,
    left: 0,
    width: 350,
    height: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: 0,
        backgroundColor: 'transparent',
    },
});
exports.NavigationItemContainer = styled_components_1.default.div(function (_a) {
    var theme = _a.theme, $isSelected = _a.$isSelected, $hasBorder = _a.$hasBorder;
    var _b = theme.foundational, blueGrey = _b.blueGrey, green = _b.green, white = _b.white, semantic = theme.semantic;
    var borderColor = semantic ? semantic.primary[600] : green[600];
    return {
        padding: 8,
        cursor: 'pointer',
        borderRight: $hasBorder && $isSelected ? "4px solid ".concat(borderColor) : 'none',
        backgroundColor: $isSelected ? white : 'transparent',
        borderRadius: 4,
        width: '100%',
        '& h4': {
            color: $isSelected ? blueGrey[900] : blueGrey[400],
        },
        '& p': {
            color: $isSelected ? blueGrey[900] : blueGrey[600],
        },
        '&:hover': {
            backgroundColor: white,
        },
    };
});
exports.BottomDrawer = styled_components_1.default.div({
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 64,
    width: '100vw',
    backgroundColor: 'white',
    padding: '12px 16px',
    '& button': {
        height: 40,
    },
});
