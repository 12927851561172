"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionsDeleteMessage = exports.DeleteTeamButton = exports.UpdateTeamButton = exports.UpdateTeamTriggerButton = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var DivisionCardTeamSelectionSection_styles_1 = require("./DivisionCardTeamSelectionSection.styles");
exports.UpdateTeamTriggerButton = (0, styled_components_1.default)(DivisionCardTeamSelectionSection_styles_1.DivisionCardTeamSelectionButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & svg {\n    fill: var(--secondary-500);\n  }\n\n  &:hover {\n    & svg {\n      fill: var(--secondary-600);\n    }\n  }\n\n  &:active {\n    & svg {\n      fill: var(--secondary-800);\n    }\n  }\n"], ["\n  & svg {\n    fill: var(--secondary-500);\n  }\n\n  &:hover {\n    & svg {\n      fill: var(--secondary-600);\n    }\n  }\n\n  &:active {\n    & svg {\n      fill: var(--secondary-800);\n    }\n  }\n"])));
exports.UpdateTeamButton = (0, styled_components_1.default)(ds_ui_components_1.ModalPrimaryActionButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: 136px;\n"], ["\n  min-width: 136px;\n"])));
exports.DeleteTeamButton = (0, styled_components_1.default)(ds_ui_components_1.ModalPrimaryActionButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-width: 129px;\n"], ["\n  min-width: 129px;\n"])));
exports.DivisionsDeleteMessage = styled_components_1.default.strong(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  white-space: nowrap;\n"], ["\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
