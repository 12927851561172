import { KeyboardEvent, useEffect, useMemo, useState, useRef } from 'react';
import { DateTime } from 'luxon';
import {
  CaretDownIcon,
  CaretLeftIcon,
  CaretRightIcon,
  IconButton,
} from '@la/ds-ui-components';
import {
  getFocusableChildren,
  getScrollableParent,
  trapFocusWithin,
} from 'lib/utils/utilities';
import {
  getDays,
  getYears,
  isSelected,
  MAX_DAYS_IN_WEEK,
  navigateDay,
  navigateGrid,
  WEEKDAYS,
} from './Calendar.util';
import * as S from './Calendar.styles';

const TODAY: DateTime = DateTime.now();
const DAYS_PER_ROW: number = MAX_DAYS_IN_WEEK;
const YEARS_PER_ROW: number = 4;

export type CalendarProps = {
  date?: DateTime;
  isShowingToday?: boolean;
  onDateSelect?: (dt: DateTime) => void;
  selectedDate?: DateTime;
};

/* Calendar */
export default function Calendar({
  date = TODAY,
  isShowingToday = true,
  onDateSelect = () => {},
  selectedDate,
}: CalendarProps) {
  const calendarRef = useRef<HTMLDivElement>(null);
  const [previousDate, setPreviousDate] = useState<DateTime>();
  const [displayDate, setDisplayDate] = useState<DateTime>(
    selectedDate ?? date
  );
  const [isSelectingYear, setIsSelectingYear] = useState<boolean>(false);

  useEffect(() => {
    return () => setIsSelectingYear(false);
  }, []);

  useEffect(() => {
    if (!!calendarRef.current && !isSelectingYear) {
      const children = getFocusableChildren(calendarRef.current);
      if (children.length > 0) {
        children[children.length - 1].focus({ preventScroll: true });
      }
    }
  }, [isSelectingYear]);

  function syncPreviousDate(dt: DateTime): void {
    setPreviousDate(displayDate);
    setDisplayDate(dt);
  }

  function jumpToToday() {
    setDisplayDate(TODAY);
    onDateSelect(TODAY);
  }

  return (
    <S.Calendar onKeyDown={(e) => trapFocusWithin(e as any)} ref={calendarRef}>
      <S.Header $isSelectingYear={isSelectingYear}>
        <S.MonthYear
          aria-label={`change ${isSelectingYear ? 'day' : 'year'}`}
          onClick={() => setIsSelectingYear(!isSelectingYear)}
        >
          <S.Month
            aria-live="polite"
            id="calendar-month-and-year"
            key={displayDate.month}
          >
            {displayDate.toLocaleString({ month: 'long', year: 'numeric' })}
          </S.Month>
          <S.YearSelectionToggle $isSelectingYear={isSelectingYear}>
            <IconButton
              icon={<CaretDownIcon size="large" variant="bold" />}
              variant="text"
            />
          </S.YearSelectionToggle>
        </S.MonthYear>
        <S.TodayButton variant="outline" size="medium" onClick={jumpToToday}>
          Today
        </S.TodayButton>
        {!isSelectingYear ? (
          <S.MonthNavigation>
            <S.MonthNavigationButtonContainer>
              <S.MonthNavigationButton
                aria-label="previous month"
                onClick={() =>
                  syncPreviousDate(displayDate.minus({ months: 1 }))
                }
                icon={<CaretLeftIcon size="large" variant="bold" />}
                variant="text"
              />
            </S.MonthNavigationButtonContainer>
            <S.MonthNavigationButtonContainer>
              <S.MonthNavigationButton
                aria-label="next month"
                onClick={() =>
                  syncPreviousDate(displayDate.plus({ months: 1 }))
                }
                icon={<CaretRightIcon size="large" variant="bold" />}
                variant="text"
              />
            </S.MonthNavigationButtonContainer>
          </S.MonthNavigation>
        ) : null}
      </S.Header>
      <S.Body
        $isSelectingYear={isSelectingYear}
        key={isSelectingYear.toString()}
      >
        {isSelectingYear ? (
          <CalendarYears
            selectedDate={selectedDate}
            onDateSelect={(dt: DateTime) => {
              syncPreviousDate(dt);
              setIsSelectingYear(false);
            }}
          />
        ) : (
          <CalendarDays
            displayDate={displayDate}
            isShowingToday={isShowingToday}
            onDisplayDateChange={syncPreviousDate}
            onDateSelect={onDateSelect}
            previousDate={previousDate}
            selectedDate={selectedDate}
          />
        )}
      </S.Body>
    </S.Calendar>
  );
}
/* */

type CalendarYearsProps = {
  range?: number;
  selectedDate?: DateTime;
  onDateSelect: (dt: DateTime) => void;
};

function CalendarYears({
  range = 100,
  selectedDate = DateTime.now(),
  onDateSelect,
}: CalendarYearsProps) {
  const yearsRef = useRef<HTMLTableElement>(null);
  const [focusDate, setFocusDate] = useState<DateTime>(selectedDate);
  const closestCenturyStart: number = Math.round(selectedDate.year / 100) * 100;
  const startYear: number = closestCenturyStart - range;

  const years: number[] = useMemo(() => {
    return getYears(startYear, range * 2);
  }, [startYear, range]);

  const yearsRows: number[] = useMemo(() => {
    return [...Array(Math.ceil(years.length / YEARS_PER_ROW))];
  }, [years]);

  useEffect(() => {
    if (!!yearsRef.current) {
      const newDate = yearsRef.current.querySelector(
        `[data-date="${focusDate.toISODate()}"]`
      );
      if (!!newDate) {
        (newDate as HTMLElement).focus();
      }
    }
  }, [focusDate]);

  function handleKeydown(event: KeyboardEvent, dt: DateTime): void {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      event.preventDefault();
      onDateSelect(dt);
    } else if (key === 'Home') {
      if (!!yearsRef.current) {
        const parent = getScrollableParent(yearsRef.current) as HTMLElement;
        parent.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else if (key === 'End') {
      if (!!yearsRef.current) {
        const parent = getScrollableParent(yearsRef.current) as HTMLElement;
        const height = parent.getBoundingClientRect().height;
        parent.scrollTo({ top: height, behavior: 'smooth' });
      }
    } else {
      navigateGrid(event, dt, 'year', YEARS_PER_ROW, (dt: DateTime) => {
        setFocusDate(dt);
      });
    }
  }

  return (
    <S.CalendarYears ref={yearsRef}>
      <tbody>
        {yearsRows.map((_, i) => (
          <tr key={i}>
            {years
              .slice(i * YEARS_PER_ROW, i * YEARS_PER_ROW + YEARS_PER_ROW)
              .map((year) => (
                <S.CalendarYear
                  data-date={selectedDate.set({ year }).toISODate()}
                  key={year}
                  onClick={() => onDateSelect(selectedDate.set({ year }))}
                  onKeyDown={(e: KeyboardEvent) =>
                    handleKeydown(e, selectedDate.set({ year }))
                  }
                  role="gridcell"
                  tabIndex={year === selectedDate.year ? 0 : -1}
                >
                  <S.Year $selected={year === selectedDate.year}>{year}</S.Year>
                </S.CalendarYear>
              ))}
          </tr>
        ))}
      </tbody>
    </S.CalendarYears>
  );
}

let navigationTimeout: NodeJS.Timeout;

type CalendarDaysProps = {
  dataTestId?: string;
  displayDate: DateTime;
  isShowingToday?: boolean;
  onDateSelect: (dt: DateTime) => void;
  onDisplayDateChange: (dt: DateTime) => void;
  previousDate?: DateTime;
  selectedDate?: DateTime;
};

function CalendarDays({
  displayDate,
  isShowingToday,
  onDateSelect,
  onDisplayDateChange,
  previousDate,
  selectedDate,
}: CalendarDaysProps) {
  const daysRef = useRef<HTMLDivElement>(null);
  const [focusDate, setFocusDate] = useState<DateTime>(selectedDate ?? TODAY);
  const [navigationDirection, setNavigationDirection] = useState<
    S.DaysTransitionDirection | undefined
  >();

  const props = {
    focusDate,
    isShowingToday,
    onDateSelect,
    onDisplayDateChange,
    onFocusDateChange: setFocusDate,
    selectedDate,
  } as CalendarDaysGridProps;

  useEffect(() => {
    if (!!daysRef.current) {
      const newDate = daysRef.current.querySelector(
        `[data-date="${focusDate.toISODate()}"]`
      );
      if (!!newDate) {
        (newDate as HTMLElement).focus({ preventScroll: true });
      }
    }
  }, [focusDate]);

  useEffect(() => {
    if (
      !!previousDate &&
      (!displayDate.hasSame(previousDate, 'month') ||
        !displayDate.hasSame(previousDate, 'year'))
    ) {
      setNavigationDirection(displayDate > previousDate ? 'next' : 'previous');

      clearTimeout(navigationTimeout);
      navigationTimeout = setTimeout(() => {
        setNavigationDirection(undefined);
      }, S.TRANSITION_DURATION);
    }

    return () => clearTimeout(navigationTimeout);
  }, [displayDate, previousDate]);

  return (
    <S.CalendarDays ref={daysRef} data-testid="calendarSelection">
      <div aria-labelledby="calendar-month-and-year" role="grid">
        <S.WeekDays role="row">
          {WEEKDAYS.map(({ label, value }, index) => (
            <S.WeekDay aria-label={value} key={index} role="columnheader">
              {label.substring(0, 2)}
            </S.WeekDay>
          ))}
        </S.WeekDays>
        <S.DaysContainer $direction={navigationDirection} role="presentation">
          <CalendarDaysGrid
            key={displayDate.toISODate()}
            {...props}
            displayDate={displayDate}
          />
          {!!navigationDirection && !!previousDate ? (
            <CalendarDaysGrid
              key={previousDate.toISODate()}
              {...props}
              displayDate={previousDate}
            />
          ) : null}
        </S.DaysContainer>
      </div>
    </S.CalendarDays>
  );
}

type CalendarDaysGridProps = {
  displayDate: DateTime;
  focusDate: DateTime;
  isShowingToday: boolean;
  onDateSelect: (dt: DateTime) => void;
  onFocusDateChange: (dt: DateTime) => void;
  onDisplayDateChange: (dt: DateTime) => void;
  selectedDate?: DateTime;
};

function CalendarDaysGrid({
  displayDate,
  focusDate,
  isShowingToday,
  onDateSelect,
  onDisplayDateChange,
  onFocusDateChange = () => {},
  selectedDate,
}: CalendarDaysGridProps) {
  const days: (number | null)[] = useMemo(
    () => getDays(displayDate),
    [displayDate]
  );

  const weeks: number[] = useMemo(
    () => [...Array(Math.ceil(days.length / DAYS_PER_ROW))],
    [days]
  );

  function handleKeyDown(event: KeyboardEvent, day: number): void {
    navigateDay(
      event,
      displayDate.set({ day }),
      focusDate,
      displayDate,
      onDateSelect,
      (dt: DateTime) => {
        onFocusDateChange(dt);
        if (!dt.hasSame(displayDate, 'month')) {
          onDisplayDateChange(dt);
        }
      }
    );
  }

  function getDayTabIndex(day: number): number {
    const currentDate: DateTime = displayDate.set({ day });
    const specialDate: DateTime = selectedDate ?? TODAY;

    const isSelectedOrToday: boolean = specialDate.hasSame(currentDate, 'day');
    const hasSelectedOrToday: boolean =
      specialDate.hasSame(currentDate, 'year') &&
      specialDate.hasSame(currentDate, 'month');
    const isLastDay: boolean = day === displayDate.daysInMonth;

    return isSelectedOrToday || (!hasSelectedOrToday && isLastDay) ? 0 : -1;
  }

  return (
    <S.Days role="rowgroup">
      {weeks.map((_, i) => (
        <S.Week key={i} role="row">
          {days
            .slice(i * DAYS_PER_ROW, i * DAYS_PER_ROW + DAYS_PER_ROW)
            .map((day, j) =>
              !!day ? (
                <S.Day
                  $isToday={
                    isShowingToday &&
                    TODAY.hasSame(displayDate.set({ day }), 'day')
                  }
                  $selected={isSelected(displayDate.set({ day }), selectedDate)}
                  aria-selected={isSelected(
                    displayDate.set({ day }),
                    selectedDate
                  )}
                  data-date={displayDate.set({ day }).toISODate()}
                  key={i * DAYS_PER_ROW + j}
                  onClick={() => onDateSelect(displayDate.set({ day }))}
                  onKeyDown={(e: KeyboardEvent) => handleKeyDown(e, day)}
                  role="gridcell"
                  tabIndex={getDayTabIndex(day)}
                >
                  {day}
                </S.Day>
              ) : (
                <S.Day
                  $disabled={true}
                  key={i * DAYS_PER_ROW + j}
                  role="gridcell"
                  tabIndex={-1}
                />
              )
            )}
        </S.Week>
      ))}
    </S.Days>
  );
}
