import { PropsWithChildren } from 'react';
import { ExternalLinkIcon } from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteId } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import { getUserIsAdmin } from 'redux/userSlice';
import userMenuData from './UserMenuData.json';
import * as S from './UserProfileMenu.styles';

/* User Profile Menu */
export default function UserProfileMenu() {
  const userIsAdmin = useAppSelector(getUserIsAdmin);
  const siteId = useAppSelector(getSiteId);

  function generateAdminConsoleLink(): string {
    const { sld, tld } = getLAHostnameParts();
    return `https://manager.${sld}.${tld}/console/sites/${siteId}`;
  }

  return (
    <S.UserProfileMenu>
      {userMenuData.map(({ id, label, path }, idx) => {
        return (
          <UserProfileMenuItem id={id} path={path} key={`${id}-${idx}`}>
            {label}
          </UserProfileMenuItem>
        );
      })}
      {userIsAdmin ? (
        <UserProfileMenuItem
          id="adminConsole"
          path={generateAdminConsoleLink()}
          target={'_blank'}
        >
          Admin Console
          <ExternalLinkIcon fill="#263238" variant="bold" />
        </UserProfileMenuItem>
      ) : null}
    </S.UserProfileMenu>
  );
}
/* */

/* User Profile Menu Item */
function UserProfileMenuItem({
  children,
  id,
  path,
  target = '_self',
}: PropsWithChildren<{
  id: string;
  path: string;
  target?: string;
}>) {
  return (
    <S.UserProfileMenuItem to={path} id={id} target={target}>
      {children}
    </S.UserProfileMenuItem>
  );
}
/* */
