import styled from 'styled-components/macro';

export const ModalContent = styled.div`
  font: var(--ui-small-medium);
`;

export const ConfirmSentence = styled.div`
  margin-top: 16px;
  font: var(--ui-medium-bold);
`;
