"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardSubtotal = void 0;
var styled_components_1 = require("styled-components");
exports.WizardSubtotal = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  height: 24px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  height: 24px;\n"])));
var templateObject_1;
