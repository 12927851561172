import styled from 'styled-components/macro';
import { ColumnGrid } from 'components/ColumnGrid/ColumnGrid.styles';
import { media } from 'lib/media-queries/mixins';
import { PageContent } from '../Shell/Page/Page.styles';

export const Membership = styled(PageContent)`
  ${ColumnGrid} {
    padding-bottom: 51px;

    ${media.TabletPortraitUp`
      padding-bottom: 39px;
    `}

    ${media.TabletLandscapeUp`
      padding-bottom: 0;
    `}
  }
`;

export const MembershipContent = styled.div`
  grid-column: 1/-1;

  ${media.TabletPortraitUp`
    grid-column: 1/-1;
  `}

  ${media.TabletLandscapeUp`
    grid-column: 2/16;
  `}

  ${media.DesktopUp`
    grid-column: 3/15;
  `}
`;
