import { useEffect, useState } from 'react';
import { deepObjectCopy } from 'lib/utils/utilities';
import { NavItemData } from '../NavBar/NavBar';
import { Accordion, AccordionMenuLink } from '../UserMenuMobile/UserMenuMobile';

export type NavAccordionProps = {
  navData: NavItemData[];
};

export type NavListProps = {
  navData: NavItemData[];
};

/* NavList */
export default function NavList({ navData }: NavListProps) {
  return (
    <div>
      <NavListItems navData={navData} />
    </div>
  );
}
/* */

/* NavListItems */
export function NavListItems({ navData }: { navData: NavItemData[] }) {
  const [massagedData, setMassagedData] = useState<NavItemData[]>(navData);

  useEffect(() => {
    function massageDataForNavPanel() {
      const navDataCopy = deepObjectCopy(navData) as NavItemData[];
      return navDataCopy.map((dataItem) => {
        if (dataItem.links) {
          dataItem.links.unshift({
            href: dataItem.href,
            label: dataItem.label,
            target: dataItem.target,
          });
        }
        return dataItem;
      });
    }

    const alteredData = massageDataForNavPanel();
    setMassagedData(alteredData);
  }, [navData]);

  return (
    <>
      {massagedData.map(({ href, label, links, target }, idx) => (
        <NavListItem
          href={href}
          key={`navItem-${idx}`}
          label={label}
          links={links}
          target={target}
        />
      ))}
    </>
  );
}

export function NavListItem({ href, label, links, target }: NavItemData) {
  return links ? (
    <Accordion toggleLabel={label} linkData={links} isUserMenu={false} />
  ) : (
    <AccordionMenuLink
      href={href}
      label={label}
      links={links}
      target={target}
    />
  );
}
