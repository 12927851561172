import styled from 'styled-components/macro';

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 8px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  fill: var(--secondary-900);

  &:focus {
    outline: none;
    border: 1px solid var(--secondary-600);
  }
`;
