import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const RegisterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${media.TabletLandscapeUp`
    flex-direction: row;
    align-items: center;
    gap: 16px;
  `};
`;
