import styled, { css, keyframes } from 'styled-components/macro';
import { CalendarIcon } from '@la/ds-ui-components';
import {
  TextFieldLabel,
  TextFieldWrapper,
  TextInput,
} from 'components/TextField/TextField.styles';

export const CALENDAR_TRANSITION_DURATION = 213;
export type CalendarTransitionDirection = 'open' | 'close';
export type CalendarOrigin = 'top' | 'bottom';
export type CalendarPosition = {
  $left: number;
  $origin: CalendarOrigin;
  $top: number;
};

export const DatePicker = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  ${({ $fullWidth }) => (!!$fullWidth ? 'width: 100%;' : null)}
`;

export const DatePickerInput = styled.div<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) => (!!$fullWidth ? 'width: 100%;' : null)}
`;

export const DatePickerTextField = styled.div<{
  $isOpen: boolean;
}>`
  ${TextFieldWrapper}:focus-within {
    ${TextFieldLabel} {
      color: var(--primary-500);
    }
  }
  ${({ $isOpen }) => {
    if ($isOpen) {
      return `
        ${TextFieldLabel} {
          color: var(--primary-500);
        }
        ${TextInput} {
          border-color: var(--primary-500);
        }        
      `;
    }
    return null;
  }}
`;

export const DateIcon = styled(CalendarIcon)<{
  $isOpen: boolean;
  $error?: boolean;
}>`
  ${({ $isOpen, $error }) => {
    if ($isOpen) {
      return 'fill: var(--primary-500)';
    } else if ($error) {
      return 'fill: var(--error)';
    }
    return 'fill: var(--primary-500)';
  }}
`;

export const InputFormat = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

export const CalendarPositionContainer = styled.div`
  position: absolute;
  top: -80px;
  left: 0;
  z-index: var(--modal-z);
  // Allows calendar to be interacted with. Otherwise, point-events: none can be
  // inherited from a parent and prevent interaction.
  pointer-events: auto;
`;

const scaleInContent = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.75, 0.5625);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const calendarOpenAnimation = css`
  ${CALENDAR_TRANSITION_DURATION}ms ${scaleInContent} forwards
`;

const calendarCloseAnimation = css`
  ${CALENDAR_TRANSITION_DURATION}ms ${scaleInContent} reverse
`;

export const CalendarContainer = styled.div<{
  $direction?: CalendarTransitionDirection;
  $origin: CalendarOrigin;
}>`
  animation: ${({ $direction }) => {
    if (!!$direction) {
      return $direction === 'open'
        ? calendarOpenAnimation
        : calendarCloseAnimation;
    }
    return 'none';
  }};
  transform-origin: center ${({ $origin }) => $origin};
  transition:
    opacity 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform ${CALENDAR_TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
