import { Link } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Typography } from '@la/ds-ui-components';
import Callout from 'components/Callout/Callout';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { CartSummaryData, CartItemData } from 'domains/Checkout/Checkout.types';
import CartSummaryCard from '../../CartSummaryCard/CartSummaryCard';
import CartSummaryRollup from '../../CartSummaryRollup/CartSummaryRollup';
import * as S from './CartSummaryBody.styles';

export type CartSummaryBodyProps = {
  error?: FetchBaseQueryError | SerializedError;
  cartSummaryData?: CartSummaryData;
};

/* CartSummaryBody */
export default function CartSummaryBody({
  error,
  cartSummaryData,
}: CartSummaryBodyProps) {
  /* TODO should update loading screen and error handling screen with new design later*/

  if (error) {
    return <div>Something went wrong</div>;
  }

  if (cartSummaryData) {
    if (cartSummaryData.cartItems.length) {
      return (
        <ColumnGrid>
          <S.MajorColumn>
            <NextGenOnlyCallout />
            <CartSummaryCards cartItemsData={cartSummaryData.cartItems} />
          </S.MajorColumn>
          <S.MinorColumn>
            <CartSummaryRollup subTotalPrice={cartSummaryData.subtotal} />
          </S.MinorColumn>
        </ColumnGrid>
      );
    } else {
      return <EmptyCart />;
    }
  }
  return null;
}
/* */

/* CartSummaryCards*/
function CartSummaryCards({
  cartItemsData,
}: {
  cartItemsData: CartItemData[];
}) {
  return cartItemsData ? (
    <>
      {cartItemsData.map((cartItemData) => (
        <CartSummaryCard
          cartItemData={cartItemData}
          key={cartItemData.cartItemUuid}
          isShowingAdditionalButtons={true}
        />
      ))}
    </>
  ) : null;
}

/*EmptyCart */
function EmptyCart() {
  return (
    <S.EmptyCart>
      <NextGenOnlyCallout />
      <Typography variant="headline" size="medium">
        Your cart is currently empty
      </Typography>
      <Typography variant="ui" size="large">
        Return here after you have added a registration or product to your cart.
      </Typography>
    </S.EmptyCart>
  );
}
/* */

function NextGenOnlyCallout() {
  return (
    <Callout>
      <span>
        This is our new registration experience. Only{' '}
        <strong>Next Generation tournaments</strong> will display here.{' '}
        <Link
          to="https://leagueapps.zendesk.com/hc/en-us/articles/20798190416023"
          target="_blank"
          style={{ color: '#2655AF' }}
        >
          Learn more
        </Link>
      </span>
    </Callout>
  );
}
