import { PropsWithChildren } from 'react';
import * as S from './TextInputContainer.styles';

export type TextInputContainerProps = {
  errorMessage?: string;
  hasError: boolean;
  label?: string;
  required?: boolean;
};

/* TextInputContainer */
export default function TextInputContainer({
  children,
  errorMessage,
  hasError,
  label,
  required,
}: PropsWithChildren<TextInputContainerProps>) {
  return (
    <S.TextInputContainer>
      <S.BorderBox $hasError={hasError}>
        {label ? (
          <S.TextInputLabel $hasError={hasError} $required={!!required}>
            {label}
          </S.TextInputLabel>
        ) : null}
        {children}
      </S.BorderBox>
      {hasError ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
    </S.TextInputContainer>
  );
}
/* */
