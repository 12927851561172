import ErrorCard from 'components/ErrorCard/ErrorCard';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import * as S from './CheckoutPageAlert.styles';

/* CheckoutPageAlert */
export default function CheckoutPageAlert() {
  const { submissionErrorsAreVisible } = useCheckoutInfo();

  return submissionErrorsAreVisible ? (
    <S.CheckoutPageAlert>
      <ErrorCard message={CHECKOUT_ERRORS.submissionError} />
    </S.CheckoutPageAlert>
  ) : null;
}
/* */
