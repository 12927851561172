import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export type UserProps = {
  isUserLoggedIn: boolean;
  updateUserHasLIPToken: (userHasLIPTokenStatus: boolean) => void;
  updateUserHasProfileIdForSite: (
    userHasProfileIdForSiteStatus: boolean
  ) => void;
};

export const userDefaultValues = {
  isUserLoggedIn: false,
  updateUserHasLIPToken: (userHasLIPTokenStatus: boolean) => {},
  updateUserHasProfileIdForSite: (userHasProfileIdForSiteStatus: boolean) => {},
  userHasLIPToken: false,
  userHasProfileIdForSite: false,
};

const UserContext = createContext<UserProps>(userDefaultValues);

const UserProvider = ({ children }: PropsWithChildren) => {
  const [userHasLIPToken, setUserHasLIPToken] = useState<boolean>(
    userDefaultValues.userHasLIPToken
  );

  const [userHasProfileIdForSite, setUserHasProfileIdForSite] =
    useState<boolean>(userDefaultValues.userHasProfileIdForSite);

  return (
    <UserContext.Provider
      value={{
        isUserLoggedIn: userHasLIPToken && userHasProfileIdForSite,
        updateUserHasLIPToken: setUserHasLIPToken,
        updateUserHasProfileIdForSite: setUserHasProfileIdForSite,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const {
    isUserLoggedIn,
    updateUserHasLIPToken,
    updateUserHasProfileIdForSite,
  } = useContext<UserProps>(UserContext);

  const handleUserHasLIPTokenChange = (userHasLIPTokenStatus: boolean) => {
    updateUserHasLIPToken(userHasLIPTokenStatus);
  };

  const handleUserHasProfileIdForSite = (
    userHasProfileIdForSiteStatus: boolean
  ) => {
    updateUserHasProfileIdForSite(userHasProfileIdForSiteStatus);
  };

  return {
    isUserLoggedIn: isUserLoggedIn,
    updateUserHasLIPToken: handleUserHasLIPTokenChange,
    updateUserHasProfileIdForSite: handleUserHasProfileIdForSite,
  };
};

export { UserContext, UserProvider, useUser };
