import { ChildYouthAthleteAccess } from '../YouthAthleteAccess.types';
import YouthAthleteConfirmationSection from '../YouthAthleteConfirmationSection/YouthAthleteConfirmationSection';

interface YouthAthleteConfirmationSectionListProps {
  enabledYouthAthletes: ChildYouthAthleteAccess[];
}

export default function YouthAthleteConfirmationSectionList({
  enabledYouthAthletes,
}: YouthAthleteConfirmationSectionListProps) {
  const enabledYouthAthleteSections = enabledYouthAthletes.map(
    (youthAthlete) => (
      <YouthAthleteConfirmationSection
        key={youthAthlete.userId}
        {...youthAthlete}
      />
    )
  );

  return <>{enabledYouthAthleteSections}</>;
}
