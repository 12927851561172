import { useEffect, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import * as S from './ErrorPage.styles';

export type ErrorMessage = {
  message?: string;
  statusText?: string;
  data?: any;
  status?: number;
};

/* ErrorPage */
export default function ErrorPage() {
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, no error message given.'
  );
  const error = useRouteError() as ErrorMessage;

  useEffect(() => {
    if (error.message) {
      setErrorMessage(error.message);
    } else if (error.statusText) {
      setErrorMessage(error.statusText);
    }
    console.error('error: ', error);
  }, [error]);

  return <ErrorContent message={errorMessage} />;
} /* */

export type ErrorContentProps = {
  message: string;
};

export function ErrorContent({ message }: ErrorContentProps) {
  return (
    <S.ErrorPage id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>Error Message: {message}</i>
      </p>
    </S.ErrorPage>
  );
}
