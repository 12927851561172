import styled from 'styled-components/macro';
import { ReactComponent as Error_Icon } from 'assets/icons/alert_error.svg';
import { ReactComponent as Close_Icon } from 'assets/icons/close.svg';

export const ErrorCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0 0 16px 0;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--red);
  background-color: #ffeeee;
`;

export const ErrorIcon = styled(Error_Icon)`
  flex-basis: 18px;
  width: 18px;
  margin: 2px 0 0 0;
  fill: var(--red);
`;

export const ErrorMessage = styled.span`
  flex: 1;
  font: var(--ui-large-medium);
  color: var(--blue-grey-900);
`;

export const CloseIcon = styled(Close_Icon)`
  flex-basis: 11px;
  margin: 2px 0 0 0;
  fill: var(--blue-grey-900);
  cursor: pointer;
`;
