import {
  VariantWithAdjustment,
  VariantWithLightness,
  variantLightnessPairs_Dark,
  variantLightnessPairs_Light,
  variantAdjustmentPairs_State,
} from './VariantLightnessPairs';

type RGB = { red: number; green: number; blue: number };

type HSL = { h: number; s: number; l: number };

type GeneratedCSSVariable = {
  [n: string]: string;
};

/**
 * Validates that `hexValue` is a string that begins with a "#" followed by
 * either 3 or 6 characters that qualify as hexadecimal values
 * @param {string} hexValue
 * @returns {boolean}
 */
export function validateHexValue(hexValue: string): boolean {
  const isValid3CharacterHex =
    hexValue.length === 4 && !!hexValue.match(/#[A-F0-9]{3}/gi);
  const isValid6CharacterHex =
    hexValue.length === 7 && !!hexValue.match(/#[A-F0-9]{6}/gi);
  return isValid3CharacterHex || isValid6CharacterHex;
}

/**
 * Converts hexValue to an rgb color string representing an equivalent
 * color and returns the rgb color string.
 * @param {string} hexValue a hexadecimal color value
 * @returns {string} an rgb color string
 */
export function convertHexadecimalToRGB(hexValue: string): string {
  let r = '0',
    g = '0',
    b = '0';

  // 3 digits
  if (hexValue.length === 4) {
    r = '0x' + hexValue[1] + hexValue[1];
    g = '0x' + hexValue[2] + hexValue[2];
    b = '0x' + hexValue[3] + hexValue[3];

    // 6 digits
  } else if (hexValue.length === 7) {
    r = '0x' + hexValue[1] + hexValue[2];
    g = '0x' + hexValue[3] + hexValue[4];
    b = '0x' + hexValue[5] + hexValue[6];
  }

  return `rgb(${+r}, ${+g}, ${+b})`;
}

/**
 * Extracts numeric values for red, green and blue from `rgbColor` and returns
 * them, converted to numbers, in an object.
 * @param {string} rgbColor an rgb color string
 * @returns {RGB} rgb values in an object
 */
export function extractNumericValuesFromRGB(rgbColor: string): RGB {
  const openParenIndex = rgbColor.indexOf('(');
  const closeParenIndex = rgbColor.indexOf(')');
  const valuesOnly = rgbColor
    .slice(openParenIndex + 1, closeParenIndex)
    .split(',');
  return { red: +valuesOnly[0], green: +valuesOnly[1], blue: +valuesOnly[2] };
}

/**
 * Uses the values of `red`, `green` and `blue` to calculate and return an
 * hsl color string
 * @param {number} red color value between 0 & 255
 * @param {number} green color value between 0 & 255
 * @param {number} blue color value between 0 & 255
 * @returns {string} hsl color string
 */
export function convertRGBToHSL({ red, green, blue }: RGB): string {
  // Make red, g, and b fractions of 1
  let _red = red / 255;
  let _green = green / 255;
  let _blue = blue / 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(_red, _green, _blue),
    cmax = Math.max(_red, _green, _blue),
    delta = cmax - cmin,
    hue,
    saturation,
    lightness;

  // Calculate hue
  // If no difference
  if (delta === 0) {
    hue = 0;
  }
  // if _red is max
  else if (cmax === _red) {
    hue = ((_green - _blue) / delta) % 6;
  }
  // if _green is max
  else if (cmax === _green) {
    hue = (_blue - _red) / delta + 2;
  }
  // if _blue is max
  else {
    hue = (_red - _green) / delta + 4;
  }

  hue = Math.round(hue * 60);

  // Make negative hues positive behind 360°
  if (hue < 0) {
    hue += 360;
  }

  // Calculate lightness
  lightness = (cmax + cmin) / 2;

  // Calculate saturation
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // Multiply saturation and lightness by 100
  saturation = +(saturation * 100).toFixed(1);
  lightness = +(lightness * 100).toFixed(1);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

/**
 * Uses the methods `convertHexadecimalToRGB`, `extractNumericValuesFromRGB` and
 * `convertRGBToHSL` internally to convert `hexValue` to an hsl color value,
 * which it returns
 * @param {string} hexValue a hexadecimal color value
 * @returns {string} an hsl color value
 */
export function convertHexadecimalToHSL(hexValue: string): string {
  const rgbColorValue = convertHexadecimalToRGB(hexValue);
  const redGreenBlueValues = extractNumericValuesFromRGB(rgbColorValue);
  return convertRGBToHSL(redGreenBlueValues);
}

/**
 * Converts `hslColor` to an object containing its respective values, hue,
 * saturation and color, and returns that object.
 * @param {string} hslColor an hsl color string
 * * @returns {HSL} hsl values in an object
 */
export function convertHSLToObject(hslColor: string): HSL {
  const [hue, saturation, lightness] = (
    hslColor.match(/(\d{1,3}\.?\d{0,3})/g) || [0, 0, 0]
  ).map(Number);
  return { h: hue, s: saturation, l: lightness };
}

/**
 * Converts `hslObject` to an hsl color string using its respective values for
 * hue, saturation and color, and returns that string.
 * @param {HSL} hslObject hsl values in an object
 * * @returns {string} an hsl color string
 */
export function convertObjectToHSL({ h, s, l }: HSL): string {
  return `hsl(${h}, ${s}%, ${l}%)`;
}

/**
 * Creates an array of GeneratedHSLValues by combining the `prefix` and the
 * `variant` value from the `variantLightnessPairs` and matching it with an hsl
 * color string created by replacing the lightness value in the
 * `baseColorValue` with the `lightness` value from the `variantLightnessPairs`
 * @param {string} prefix
 * @param {string} baseColorValue an hsl color string
 * @param {VariantWithLightness[]} variantLightnessPairs a schema made up of
 * pairs of variant {string} and lightness {number} values
 * @returns {GeneratedCSSVariable[]}
 */
export function generateShadeVariants(
  prefix: string,
  baseColorValue: string,
  variantLightnessPairs: VariantWithLightness[]
): GeneratedCSSVariable[] {
  const { h, s } = convertHSLToObject(baseColorValue);
  return variantLightnessPairs.map(
    ({ lightness, variant }: VariantWithLightness) => {
      const objKey = `${prefix}-${variant}`;
      const objValue = convertObjectToHSL({ h: h, s: s, l: lightness });
      return { [objKey]: objValue };
    }
  );
}

/**
 * Creates an array of GeneratedHSLValues by combining the `prefix` with a
 * lightness value returned by `adjustedStateLightness`
 * @param {string} prefix
 * @param {string} baseColorValue
 * @param {VariantWithAdjustment[]} variantAdjustmentPairs a schema made up
 * of pairs of variant {string} and lightness {number} values
 * @returns {GeneratedCSSVariable[]}
 */
export function generateStateVariants(
  prefix: string,
  baseColorValue: string,
  variantAdjustmentPairs: VariantWithAdjustment[]
): GeneratedCSSVariable[] {
  const { h, s, l } = convertHSLToObject(baseColorValue);
  return variantAdjustmentPairs.map(
    ({ adjustment, variant }: VariantWithAdjustment) => {
      const objKey = `${prefix}-${variant}`;
      const lightness = adjustStateLightness(adjustment, h, l);
      const objValue = convertObjectToHSL({ h: h, s: s, l: lightness });
      return { [objKey]: objValue };
    }
  );
}

/**
 * Based on the values of the adjustment, hue and lightness arguments, an
 * `adjustedLightness` value is determined and returned.
 * *
 * IF lightness is greater than 30 AND hue is between 7 and 215
 *   OR IF lightness is greater than 65 -->
 *     adjustedLightness = lightness + adjustment, to a maximum of 95
 * ELSE IF lightness is less than 35 -->
 *     adjustedLightness = lightness + adjustment
 * ELSE -->
 *     adjustedLightness = lightness - adjustment
 * *
 * @param {number} adjustment
 * @param {number} hue
 * @param {number} lightness
 * @returns {number}
 */
export function adjustStateLightness(
  adjustment: number,
  hue: number,
  lightness: number
): number {
  let adjustedLightness;
  if ((lightness > 30 && hue > 7 && hue < 215) || lightness > 65) {
    adjustedLightness =
      lightness + adjustment <= 95 ? lightness + adjustment : 95;
  } else if (lightness < 35) {
    adjustedLightness = lightness + adjustment;
  } else {
    adjustedLightness = lightness - adjustment;
  }
  return adjustedLightness;
}

/**
 * After converting the argument `hslString` into an object this function
 * examines the hue and the lightness similarly to `adjustStateLightness`
 * and determines whether text that will overlay elements with those color
 * values should be black or white
 * *
 * IF lightness is greater than 30 AND hue is between 7 and 215
 *   OR IF lightness is greater than 65 -->
 *     return `{`${prefix}-text-color`: 'var(--black)'}`
 * ELSE -->
 *     return `{`${prefix}-text-color`: 'var(--white)'}`
 * *
 * @param {string} prefix
 * @param {string} hslString
 * @returns {GeneratedCSSVariable}
 */
export function getReactiveTextColor(
  prefix: string,
  hslString: string
): GeneratedCSSVariable {
  const { h, l } = convertHSLToObject(hslString);
  const reactiveColorKey: string = `${prefix}-text-color`;
  const reactiveColorValue: string =
    (l > 30 && h > 7 && h < 215) || l > 65 ? 'var(--black)' : 'var(--white)';
  return Object.fromEntries([[reactiveColorKey, reactiveColorValue]]);
}

/**
 * Injects `CSSVariables` into the style attribute of `targetElement`
 * @param {HTMLElement} targetElement
 * @param {GeneratedCSSVariable[]} CSSVariables
 * @return void
 */
export function addVariablesToTheDOM(
  targetElement: HTMLElement,
  CSSVariables: GeneratedCSSVariable[]
): void {
  CSSVariables.forEach((variable) => {
    targetElement.style.setProperty(
      Object.keys(variable)[0],
      Object.values(variable)[0]
    );
  });
}

/**
 * The passed `hslColorValue` is converted to an object and the values of
 * hue (h) and saturation (s) are examined.
 * *
 * IF hue is between 55 and 185 -->
 *   IF saturation is 90 or less -->
 *     The original `hslColorValue` string, along with
 *       the `variantLightnessPairs_Light` object is returned
 *   ELSE IF saturation is greater than 90 -->
 *       it sets the saturation value to 90 and generates an hsl color string
 *         keeping the hue and lightness values from `hslColorValue`
 *     The generated hsl color string, along with
 *       the `variantLightnessPairs_Light` object is returned
 * ELSE -->
 *   The original `hslColorValue` string, along with
 *     the `variantLightnessPairs_Dark` object is returned
 * *
 * @param {string} hslColorValue an hsl color string
 * @returns {hsl: string, variantLightnessPairs: VariantWithLightness[]} An
 * object containing an hsl color string and a schema made up of pairs of
 * `variant` {string} and `lightness` {number} values
 */
export function adjustForLuminance(hslColorValue: string) {
  const { h, s, l } = convertHSLToObject(hslColorValue);
  let returnedHSL = hslColorValue;
  let returnedVariantLightnessPairs = variantLightnessPairs_Dark;
  if (h >= 55 && h <= 185) {
    returnedVariantLightnessPairs = variantLightnessPairs_Light;
    if (s > 90) {
      returnedHSL = convertObjectToHSL({ h: h, s: 90, l: l });
    }
  }
  return {
    hsl: returnedHSL,
    variantLightnessPairs: returnedVariantLightnessPairs,
  };
}

/**
 * Using `convertHexadecimalToHSL`, `generateShadeVariants`,
 * `generateStateVariants`, `getand
 * `addVariablesToTheDOM` internally, generates a series of cssVariables
 * from hexValue and variantLightnessPairs and injects them into the style
 * attribute of DOMTarget along with a css variable made by pairing the
 * prefix and the hexValue
 * @param {string} prefix
 * @param {string} hexValue a hexadecimal color value
 * @param {HTMLElement} DOMTarget
 */
export function setThemeColorValues(
  prefix: string,
  hexValue: string,
  DOMTarget: HTMLElement
): void {
  if (validateHexValue(hexValue)) {
    const hslFromHex = convertHexadecimalToHSL(hexValue);
    const { hsl, variantLightnessPairs } = adjustForLuminance(hslFromHex);
    const shadeVariants = generateShadeVariants(
      prefix,
      hsl,
      variantLightnessPairs
    );
    const stateVariants = generateStateVariants(
      prefix,
      hsl,
      variantAdjustmentPairs_State
    );
    const reactiveTextColor: GeneratedCSSVariable = getReactiveTextColor(
      prefix,
      hsl
    );
    addVariablesToTheDOM(DOMTarget, [
      { [prefix]: hsl },
      reactiveTextColor,
      ...stateVariants,
      ...shadeVariants,
    ]);
  } else {
    return;
  }
}

/*
Oh yes, let's pretend that I figured all this out on my own and didn't crib
 any of it from https://css-tricks.com/converting-color-spaces-in-javascript/
 */
