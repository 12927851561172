import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type ApplicationState = {
  logoHref?: string;
  logoImageSrc?: string;
  siteId: string | null;
  siteName: string;
};

const initialState: ApplicationState = {
  logoHref: '',
  logoImageSrc: '',
  siteId: null,
  siteName: '',
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setSiteInfo(state, action) {
      state.logoHref = action.payload.logoHref;
      state.logoImageSrc = action.payload.logoImageSrc;
      state.siteId = action.payload.siteId;
      state.siteName = action.payload.siteName;
    },
  },
});

export const { setSiteInfo } = coreSlice.actions;

export const getLogoHref = (state: RootState) => state.core.logoHref;
export const getLogoImageSrc = (state: RootState) => state.core.logoImageSrc;
export const getSiteId = (state: RootState) => state.core.siteId;
export const getSiteName = (state: RootState) => state.core.siteName;
export const getSiteIdentityData = createSelector(
  [getLogoHref, getLogoImageSrc, getSiteId, getSiteName],
  (logoHref, logoImageSrc, siteId, siteName) => {
    return {
      logoHref: logoHref,
      logoImageSrc: logoImageSrc,
      siteId: siteId,
      siteName: siteName,
    };
  }
);

export default coreSlice.reducer;
