import styled from 'styled-components/macro';
import { IconButton, ThemedComponent } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH = 28;

export const MainContentHeaderBackButton = styled(IconButton)<ThemedComponent>`
  & svg {
    stroke: ${({ theme }: ThemedComponent) =>
      theme.semantic?.primary.reactiveTextColor};
  }

  &:focus-within {
    outline-color: ${({ theme }: ThemedComponent) =>
      theme.semantic?.primary.reactiveTextColor};
  }

  position: absolute;
  left: 12px;
  width: ${MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH}px;
  height: ${MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH}px;
  padding: 0;

  ${media.TabletLandscapeUp`
    display: none;
  `}
`;
