import styled, { CSSObject } from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

type DropzoneProps = ThemedComponent<{
  $hasSelectedTeam?: boolean;
  $isHovered?: boolean;
}>;

export const Dropzone = styled.div<DropzoneProps>(
  ({ $hasSelectedTeam = false, $isHovered = false, theme }: DropzoneProps) => {
    const styles: CSSObject = {
      background: theme.foundational.white,
      display: 'flex',
      alignItems: $hasSelectedTeam ? 'flex-start' : 'center',
      justifyContent: 'center',
      width: '100%',
      height: '400px',
      padding: '16px',
      border: `1px dashed ${theme.foundational.blueGrey[400]}`,
      borderRadius: '4px',
      overflowY: 'scroll',

      [theme.mediaQueries.tablet]: {
        height: '564px',
      },
    };

    if ($isHovered) {
      return {
        ...styles,
        background: theme.foundational.blueGrey[50],
        alignItems: 'center',
      };
    }

    return styles;
  }
);
