import styled from 'styled-components/macro';
import { Button } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const CheckoutRollup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;

  ${media.TabletLandscapeUp`
    margin-top: 0px;
  `}
`;

export const MakePaymentButton = styled(Button)`
  font: var(--ui-xtra-large-bold);
  padding: 12px;
`;

export const RollupBody = styled.div`
  border: 1px solid var(--primary-400);
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  color: var(--blue-grey-900);
  background-color: var(--white);
`;
