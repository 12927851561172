import { X } from '@phosphor-icons/react';
import { css } from 'styled-components';
import styled, { keyframes } from 'styled-components/macro';

const slideIn = keyframes`
  from {
    opacity: 0;
    translate: 200px 0;
  }

  to {
    opacity: 1;
    translate: 0 0;
  }
`;

const slideInAnimation = () => css`
  ${slideIn} 0.2s linear;
`;

export const Toast = styled.div<{ $isNewToast: boolean }>`
  position: relative;
  width: 380px;

  &:last-of-type {
    animation: ${({ $isNewToast }) =>
      $isNewToast ? slideInAnimation : 'none'};
  }
`;

export const ToastContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 380px;
  margin: 0 0 4px 0;
  padding: 12px 44px 12px 12px;
  font: var(--ui-large-regular);
  color: white;
  border-radius: 8px;
  background-color: var(--blue-grey-900);
`;

export const ToastDescription = styled.span`
  flex: 1;
`;

export const CloseButtonX = styled(X)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export const ProgressBar = styled.div<{ $barColor: string; $width: number }>`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: ${({ $width }) => $width}%;
  height: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${({ $barColor }) => $barColor};
`;

export const ToastContainer = styled.div`
  position: fixed;
  z-index: 500;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
