import { ReactNode } from 'react';
import { CloseIcon, Typography } from '@la/ds-ui-components';
import * as S from './Alert.styles';

export type AlertType = 'success' | 'error';

export type AlertProps = {
  message: string | ReactNode;
  icon?: ReactNode;
  onClose?: () => void;
  type?: AlertType;
};

const Alert = ({ icon, message, onClose, type = 'error' }: AlertProps) => {
  return (
    <S.AlertContainer $type={type}>
      {icon}
      <Typography variant="ui" size="large">
        {message}
      </Typography>
      {onClose ? (
        <S.CloseIconContainer onClick={onClose}>
          <CloseIcon size="medium" fill="var(--blue-grey-900)" />
        </S.CloseIconContainer>
      ) : null}
    </S.AlertContainer>
  );
};

export { Alert };
