import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import { useGetRegistrantProfileDataQuery } from 'redux/services/rosterManagementApi';
import RegistrantProfileForm from './RegistrantProfileForm';
import * as S from './RosterManagement.styles';

export type Team = {
  teamName: string;
  teamId?: string;
  programName: string;
  rosterUrl?: string;
};

/* Edit Registrant Profile Modal */
export function EditRegistrantProfileModal({
  closeModal,
  memberName,
  userId,
}: {
  closeModal: () => void;
  memberName: string;
  userId: number;
}) {
  const { programId, teamId } = useParams();

  const { data /*, error, isError, isLoading */ } =
    useGetRegistrantProfileDataQuery(
      { playerId: userId, programId, teamId },
      { skip: !userId || !programId || !teamId }
    );

  return (
    <Modal
      onOpenChange={closeModal}
      open={true}
      primaryAction={
        <ModalPrimaryActionButton
          form="update-registrant-profile-form"
          type="submit"
        >
          Save changes
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Discard Changes</ModalTertiaryActionButton>
        </ModalClose>
      }
      size="medium"
      title="Edit Registration Fields"
    >
      <S.ViewDifferentTeamModalSubhead size="large" variant="headline">
        {memberName}
      </S.ViewDifferentTeamModalSubhead>
      {data ? (
        <RegistrantProfileForm closeModal={closeModal} registrantData={data} />
      ) : null}
    </Modal>
  );
}
/*  */
