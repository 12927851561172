"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscardTeamButton = exports.DivisionCardTeamSelectionButton = exports.DivisionCardAddTeamSelectButton = exports.DivisionSpotsLeftMessage = exports.DivisionCardTeams = exports.DivisionCardTeamSelectionErrorMessage = exports.DivisionCardTeamSelectionAlertMessage = exports.DivisionCardTeamSelectionTopRow = exports.DivisionCardTeamSelection = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.DivisionCardTeamSelection = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 4px;\n"], ["\n  margin-top: 4px;\n"])));
exports.DivisionCardTeamSelectionTopRow = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  & > :first-child {\n    flex: 1;\n    width: 91%;\n  }\n"], ["\n  display: flex;\n  align-items: flex-end;\n  & > :first-child {\n    flex: 1;\n    width: 91%;\n  }\n"])));
exports.DivisionCardTeamSelectionAlertMessage = styled_components_1.default.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 4px 0;\n  color: var(--red);\n  font: var(--ui-medium-regular);\n"], ["\n  margin: 4px 0;\n  color: var(--red);\n  font: var(--ui-medium-regular);\n"])));
exports.DivisionCardTeamSelectionErrorMessage = styled_components_1.default.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 4px;\n  color: var(--red);\n  font: var(--ui-small-regular);\n"], ["\n  margin: 4px;\n  color: var(--red);\n  font: var(--ui-small-regular);\n"])));
exports.DivisionCardTeams = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
exports.DivisionSpotsLeftMessage = (0, styled_components_1.default)(ds_ui_components_1.Typography)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 auto;\n  padding: 4px 16px;\n"], ["\n  margin: 0 auto;\n  padding: 4px 16px;\n"])));
exports.DivisionCardAddTeamSelectButton = (0, styled_components_1.default)(ds_ui_components_1.Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 auto;\n\n  & svg {\n    fill: #000000;\n  }\n"], ["\n  margin: 0 auto;\n\n  & svg {\n    fill: #000000;\n  }\n"])));
exports.DivisionCardTeamSelectionButton = (0, styled_components_1.default)(ds_ui_components_1.IconButton)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  // Margin needs to match select input so that they align correctly\n  margin: 4px 0;\n\n  padding: 12px;\n  height: 42px;\n"], ["\n  // Margin needs to match select input so that they align correctly\n  margin: 4px 0;\n\n  padding: 12px;\n  height: 42px;\n"])));
exports.DiscardTeamButton = (0, styled_components_1.default)(exports.DivisionCardTeamSelectionButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
