import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetTournamentRegistrationQuery } from 'redux/services/tournamentApi';
import { Tournament } from 'redux/services/types/tournament';

type UseTournamentForRegistrationArgs = {
  /**
   * The tournament id
   */
  id: string;
};

/**
 * Gets the tournament information for the registration wizard
 * including the registrations already created by this user for
 * this tournament.
 */
export function useTournamentForRegistration({
  id,
}: UseTournamentForRegistrationArgs): {
  tournament: Tournament | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
} {
  const { subdomain } = getLAHostnameParts();
  const {
    data: tournament,
    isLoading,
    error,
  } = useGetTournamentRegistrationQuery({
    siteDomain: subdomain,
    tournamentId: id,
  });

  return { tournament, isLoading, error };
}
