import styled from 'styled-components/macro';
import { Button } from '@la/ds-ui-components';
import { ReactComponent as LA_Logo_L } from 'assets/LA_lockups/L_Over_LeagueApps.svg';

export const LoginMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  padding: 0 10px;
`;

export const LogosRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 230px;
  margin-bottom: 3px;
`;

export const SiteLogoContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 0 10px;
`;

export const LALogoContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 0 10px;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: var(--blue-grey-900);
`;

export const LA_Logo = styled(LA_Logo_L)`
  height: 40px;
  fill: var(--green-500);
  text-align: left;
`;

export const LoginButton = styled(Button)`
  padding: 10px 20px;

  &:hover,
  &:focus {
    background: var(--secondary-800);
  }

  &:active {
    background: var(--secondary-700);
  }
`;

export const SignUpLink = styled.a`
  width: 100%;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  font: var(--ui-large-bold);
  text-decoration: none;
  text-align: center;
  color: var(--secondary-900);

  &:focus,
  &:hover {
    background: var(--blue-grey-50);
    color: var(--blue-grey-900);
    border-color: var(--button-outline-border-color-hover);
  }

  &:active {
    background: var(--blue-grey-100);
  }
`;
