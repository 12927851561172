import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, Button, Typography, Form } from '@la/ds-ui-components';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import type { FormField, Team } from '@la/types';
import { truncateWithEllipsis } from '@la/utilities';
import { CustomFields } from 'components/RegistrationInfoCard/CustomFieldsForm/CustomFieldsForm';
import { createValidationSchema } from 'components/RegistrationInfoCard/CustomFieldsForm/utils/validation';
import { extractCustomFieldValue } from '../CreateTeamForm/CreateTeamForm';
import { getIncompleteRequiredFieldsTeams } from '../DivisionCard/TeamSelectionSection/utils/validation';
import * as S from './EditTeamFieldsModal.styles';

const EDIT_TEAMS_FORM_ID = 'bulk-edit-teams';

export type EditTeamFieldsModalProps = {
  teams: Team[];
  addedTeamsIds: string[];
  open: boolean;
  onClose: () => void;
  customFields: FormField[];
  handleFieldsUpdate: (values: any) => void;
};

const EditTeamFieldsModal = ({
  teams,
  open,
  onClose,
  customFields,
  addedTeamsIds,
  handleFieldsUpdate,
}: EditTeamFieldsModalProps) => {
  const [selectedTeam, setSelectedTeam] = useState(teams[0].id);
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const pendingTeams = getIncompleteRequiredFieldsTeams(
    customFields,
    teams.filter((team) => addedTeamsIds.includes(team.id))
  );

  const teamFieldsValidation = pendingTeams.reduce(
    (schema, team) => schema.concat(createValidationSchema(team.id)),
    yup.object({})
  );
  const editTeamsFieldsValidation = yup.object({}).concat(teamFieldsValidation);

  const defaultValues = pendingTeams.reduce((p, c) => {
    return {
      ...p,
      [c.id]: extractCustomFieldValue(
        customFields,
        teams.find((t) => t.id === c.id)?.formFields
      ),
    };
  }, {});

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(editTeamsFieldsValidation),
  });

  useEffect(() => {
    const element = document.getElementById(selectedTeam);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selectedTeam]);

  const renderTeams = () => {
    return pendingTeams.map((team) => {
      const isSelected = selectedTeam === team.id;
      return (
        <S.TeamItemContainer
          key={team.id}
          $isSelected={isSelected}
          onClick={() => setSelectedTeam(team.id)}
        >
          <Typography
            variant="ui"
            size="large"
            weight={isSelected ? 'bold' : 'regular'}
          >
            {truncateWithEllipsis(team.name, 25)}
          </Typography>
        </S.TeamItemContainer>
      );
    });
  };

  const renderFields = () => {
    return pendingTeams.map((team) => {
      return (
        <S.TeamContainer key={team.id} id={team.id}>
          <Typography variant="ui" size="large" weight="bold">
            {team.name}
          </Typography>
          <S.TeamFieldsContainer
            key={team.id}
            $isTabletPortraitUp={isTabletPortaitUp}
          >
            <S.CustomField>
              <CustomFields
                inputSize="large"
                columns={isTabletPortaitUp ? 2 : 1}
                name={team.id}
              />
            </S.CustomField>
          </S.TeamFieldsContainer>
        </S.TeamContainer>
      );
    });
  };

  return (
    <Modal
      size="large"
      open={open}
      onOpenChange={onClose}
      title="Add team details"
      primaryAction={
        <Button form={EDIT_TEAMS_FORM_ID} type="submit">
          Save and continue
        </Button>
      }
      tertiaryAction={
        <Button variant="text" onClick={onClose}>
          Discard changes
        </Button>
      }
    >
      <S.EditTeamsContainer>
        {isTabletPortaitUp ? (
          <S.TeamsLeftPanel>
            <S.TeamLeftPanelInnerContainer>
              {renderTeams()}
            </S.TeamLeftPanelInnerContainer>
          </S.TeamsLeftPanel>
        ) : null}
        <S.EditTeamsContent $isTabletPortraitUp={isTabletPortaitUp}>
          <Typography variant="ui" size="large">
            Before you continue, this program requires additional information.
            Please complete the following fields before completing registration.
          </Typography>
          <S.FormFieldsContainer>
            <FormProvider {...methods}>
              <Form
                id={EDIT_TEAMS_FORM_ID}
                noValidate
                onSubmit={methods.handleSubmit(handleFieldsUpdate)}
              >
                {renderFields()}
              </Form>
            </FormProvider>
          </S.FormFieldsContainer>
        </S.EditTeamsContent>
      </S.EditTeamsContainer>
    </Modal>
  );
};

export { EditTeamFieldsModal };
