import { useRef } from 'react';
import { setThemeColorValues } from 'lib/theme/colorHandling';
import * as S from './SwatchGenerator.styles';

export type ProgramsProps = {};

/* SwatchGenerator Page */
export default function SwatchGenerator(props: ProgramsProps) {
  const primaryInput = useRef<HTMLInputElement | null>(null);
  const secondaryInput = useRef<HTMLInputElement | null>(null);
  function generateSwatches(prefix: string) {
    const variantNames = ['hover', 'active', '35', '100', '200', '400', '600'];
    const variantTextColorName = `${prefix}-text-color`;
    const swatches = variantNames.map((variant, idx) => {
      const variantColorName = `${prefix}-${variant}`;
      return (
        <S.Swatch
          $swatchColor={variantColorName}
          $textColor={variantTextColorName}
          key={`${idx}-variant`}
        >
          {variantColorName}
        </S.Swatch>
      );
    });
    const original = (
      <S.Swatch
        $swatchColor={prefix}
        $textColor={variantTextColorName}
        key={`${prefix}-variant`}
      >
        {`${prefix}`}
      </S.Swatch>
    );
    return [original, ...swatches];
  }

  function setColor(whichColor: string) {
    let prefix,
      hexValue = '';
    if (whichColor === 'primary') {
      prefix = '--primary';
      hexValue = primaryInput.current?.value || '';
    } else {
      prefix = '--secondary';
      hexValue = secondaryInput.current?.value || '';
    }

    if (hexValue.length === 7) {
      setThemeColorValues(prefix, hexValue, document.documentElement);
    } else {
      alert(
        'The value you input must be a six-digit hexadecimal color value and' +
          ' must begin with a #.'
      );
    }
  }

  return (
    <S.SwatchGenerator>
      Generated Colors:
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '30px',
          width: '462px',
          marginBottom: '8px',
          padding: '4px 0',
          borderBottom: '1px solid #999999',
        }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            width: '416px',
          }}
        >
          <input ref={primaryInput} placeholder="#263238" type="text" />
          <button onClick={() => setColor('primary')}>Set Primary Color</button>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            width: '416px',
          }}
        >
          <input ref={secondaryInput} placeholder="#009500" type="text" />
          <button onClick={() => setColor('secondary')}>
            Set Secondary Color
          </button>
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '30px',
        }}
      >
        <S.TempSwatchContainer>
          {generateSwatches('--primary')}
        </S.TempSwatchContainer>
        <S.TempSwatchContainer>
          {generateSwatches('--secondary')}
        </S.TempSwatchContainer>
      </div>
    </S.SwatchGenerator>
  );
}
/* */
