import { ReactNode } from 'react';
import { CloseIcon, Typography } from '@la/ds-ui-components';
import * as S from './Banner.styles';

export type AlertProps = {
  message: string | ReactNode;
  icon?: ReactNode;
  onClose?: () => void;
};

const Banner = ({ icon, message, onClose }: AlertProps) => {
  return (
    <S.BannerContainer>
      {icon}
      <Typography variant="ui" size="large">
        {message}
      </Typography>
      {onClose ? (
        <S.CloseIconContainer onClick={onClose}>
          <CloseIcon size="medium" fill="var(--blue-grey-900)" />
        </S.CloseIconContainer>
      ) : null}
    </S.BannerContainer>
  );
};

export { Banner };
