import styled from 'styled-components/macro';

export type TextInputSize = 'large' | 'medium';

export const TextFieldWrapper = styled.div<{ $fullWidth?: boolean }>`
  position: relative;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`;

export const TextInput = styled.input<{
  cursor?: string;
  disabled?: boolean;
  $error?: boolean;
  $fullWidth?: boolean;
  $iconPosition?: 'start' | 'end';
  $size?: TextInputSize;
}>`
  ${({ cursor }) => (cursor ? `cursor: ${cursor}` : null)};
  height: 32px;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  padding: 8px 12px;
  font: ${({ $size }) =>
    $size === 'large' ? 'var(--ui-large-regular)' : 'var(--ui-medium-regular)'};
  color: ${({ disabled }) =>
    disabled ? 'var(--blue-grey-200)' : 'var(--black-87)'};
  border-radius: 4px;
  border: ${({ disabled, $error }) => {
    if ($error) {
      return '1px solid var(--error)';
    } else if (disabled) {
      return;
    } else {
      return '1px solid var(--blue-grey-400)';
    }
  }};
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    font-weight: 400;
    color: var(--blue-grey-400);
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: ${({ $error }) => {
      if ($error) {
        return 'var(--error)';
      } else {
        return 'var(--primary-500)';
      }
    }};
  }
  &:disabled {
    border: 1px dashed var(--blue-grey-150);
    cursor: not-allowed;
  }

  & > svg {
    stroke: var(--primary-500);
    fill: var(--primary-500);
  }
`;

export const TextArea = styled.textarea<{
  disabled?: boolean;
  $error?: boolean;
  $fullWidth?: boolean;
  $size?: TextInputSize;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  padding: 8px 12px;
  font: ${({ $size }) =>
    $size === 'large' ? 'var(--ui-large-regular)' : 'var(--ui-medium-regular)'};
  color: var(--black-87);
  border-radius: 4px;
  border: ${({ disabled, $error }) => {
    if ($error) {
      return '1px solid var(--error)';
    } else if (disabled) {
      return;
    } else {
      return '1px solid var(--blue-grey-150)';
    }
  }};
  outline: none;
  resize: vertical;

  &::placeholder {
    font-weight: 400;
    color: var(--blue-grey-400);
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: var(--primary-500);
  }
  &:disabled {
    border: 1px dashed var(--blue-grey-150);
    cursor: not-allowed;
  }
`;

export const TextFieldLabel = styled.label<{
  $error?: boolean;
  $required?: boolean;
}>`
  position: absolute;
  top: -7px;
  left: 8px;
  height: 16px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ $error }) => ($error ? 'var(--error)' : 'var(--blue-grey-600)')};
  background-color: var(--white);

  ${({ $required }) =>
    $required
      ? `&::after {
    content: ' *';
    color: red;
  }`
      : null}
`;

export const TextFieldHelperText = styled.div<{
  $error?: boolean;
}>`
  margin: 4px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 0.4px;
  color: ${({ $error }) => ($error ? 'var(--error)' : 'var(--blue-grey-150)')};
`;

export const TextFieldIconContainer = styled.span<{
  $disabled?: boolean;
  $hasError?: boolean;
  $iconPosition?: 'start' | 'end';
  $size?: TextInputSize;
}>`
  position: absolute;
  top: 0;
  ${({ $iconPosition }) => {
    if ($iconPosition === 'start') {
      return 'left: 0;';
    } else if ($iconPosition === 'end') {
      return 'right: 0;';
    }
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: ${({ $size }) => ($size === 'large' ? '42px' : '34px')};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  & > svg path {
    ${({ $disabled, $hasError }) => {
      if ($hasError) {
        return 'fill: var(--red)';
      } else if ($disabled) {
        return 'fill: var(--blue-grey-350)';
      }
    }};
  }
`;
