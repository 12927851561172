"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTeamFormSubmitButton = exports.UnavailableDivisionCard = exports.UnavailableInfo = exports.ConfirmationSentence = exports.DivisionCardSection = exports.DivisionCard = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.DivisionCard = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
exports.DivisionCardSection = (0, styled_components_1.default)(ds_ui_components_1.CardBody)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:nth-child(odd) {\n    background-color: var(--primary-35);\n  }\n"], ["\n  &:nth-child(odd) {\n    background-color: var(--primary-35);\n  }\n"])));
exports.ConfirmationSentence = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 16px 0 0;\n  font: var(--ui-large-bold);\n"], ["\n  margin: 16px 0 0;\n  font: var(--ui-large-bold);\n"])));
exports.UnavailableInfo = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: var(--blue-grey-600);\n"], ["\n  color: var(--blue-grey-600);\n"])));
exports.UnavailableDivisionCard = (0, styled_components_1.default)(exports.DivisionCard)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  > div {\n    color: ", ";\n\n    > div {\n      border: ", ";\n    }\n\n    > div > div:first-child {\n      background-color: ", ";\n    }\n  }\n"], ["\n  > div {\n    color: ", ";\n\n    > div {\n      border: ", ";\n    }\n\n    > div > div:first-child {\n      background-color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.foundational.blueGrey[800];
}, function (_a) {
    var theme = _a.theme;
    return "1px solid ".concat(theme.palette.foundational.blueGrey[400]);
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.foundational.blueGrey[35];
});
exports.CreateTeamFormSubmitButton = (0, styled_components_1.default)(ds_ui_components_1.ModalPrimaryActionButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  min-width: 132px;\n"], ["\n  min-width: 132px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
