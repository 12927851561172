"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardFooter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("./DivisionCardFooter.styles");
function DivisionCardFooter(_a) {
    var onCancel = _a.onCancel, onSave = _a.onSave;
    return ((0, jsx_runtime_1.jsxs)(S.DivisionCardFooter, { children: [(0, jsx_runtime_1.jsx)(S.DivisionCardCancelButton, __assign({ variant: "text", size: "medium", onClick: onCancel }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ variant: "primary", size: "medium", onClick: onSave }, { children: "Save division" }))] }));
}
exports.DivisionCardFooter = DivisionCardFooter;
