import { KeyboardEvent } from 'react';
import { convertToKebabCase } from 'lib/utils/stringUtils';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import * as S from './Chip.styles';

type BaseChipProps = {
  disabled?: boolean;
  label: string | number;
  size?: S.ChipSize;
  badge?: number | string | undefined;
};

type DisplayChipProps = {
  canDelete?: false;
  onDeleteClick?: never;
};

type DeleteChipProps = {
  canDelete: true;
  onDeleteClick: () => void;
};

export type ChipProps = BaseChipProps & (DisplayChipProps | DeleteChipProps);

/* Chip */
export default function Chip({
  canDelete,
  disabled = false,
  label,
  size = 'medium',
  badge,
  ...props
}: ChipProps) {
  const kebabCaseLabel = convertToKebabCase(label.toString());
  const buttonId = `chip-delete-${kebabCaseLabel}`;
  const labelId = `chip-label-${kebabCaseLabel}`;

  function handleChipKeyDown(event: KeyboardEvent): void {
    const { onDeleteClick } = props;
    if (!!onDeleteClick && event.key === 'Backspace') {
      onDeleteClick();
    }
  }

  return (
    <S.Chip>
      {canDelete === true ? (
        <S.DeleteButton
          aria-label="delete"
          aria-labelledby={`${labelId} ${buttonId}`}
          disabled={disabled}
          id={buttonId}
          onClick={props.onDeleteClick}
          onKeyDown={handleChipKeyDown}
          type="button"
        >
          <DeleteIcon />
        </S.DeleteButton>
      ) : null}
      <S.Border $canDelete={canDelete} $disabled={disabled} />
      {badge ? <S.Badge>{badge}</S.Badge> : null}
      <S.Label
        $canDelete={canDelete}
        $isZero={label === 0}
        $size={size}
        id={labelId}
      >
        {label}
      </S.Label>
    </S.Chip>
  );
}
/* */
