import { useEffect } from 'react';
import { Button } from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import {
  ActivityFormWrapperScreens,
  useActivityForm,
  RecurringActivityType,
} from '../ActivityContext';
import * as S from './EditRecurringActivity.styles';

type EditRecurringAcitivityProps = {
  handleSetActivityFormTitle: (arg: string) => void;
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
  originRoute: string;
};

export function EditRecurringActivity({
  originRoute,
  handleSetActivityFormTitle,
  handleSetCurrentScreen,
}: EditRecurringAcitivityProps) {
  const { handleSetRecurringActivityType } = useActivityForm();

  useEffect(() => {
    handleSetActivityFormTitle('Edit Activity');
  });

  const selection = (type: RecurringActivityType) => {
    handleSetRecurringActivityType(type);
    handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
  };

  const handleDiscardChanges = () => {
    const { sld, subdomain, tld } = getLAHostnameParts();
    window.location.href = `https://${subdomain}.${sld}.${tld}/${originRoute}`;
  };

  return (
    <>
      <S.RecurringActivityText variant="ui" size="large">
        <b>This is a recurring activity.</b> Would you like to edit this
        instance of the activity, or all recurring activities in this set?
      </S.RecurringActivityText>
      <S.EditButtons>
        <Button variant="outline" size="large" onClick={() => selection('one')}>
          Edit just this activity
        </Button>
        <Button variant="outline" size="large" onClick={() => selection('all')}>
          Edit ALL FUTURE activities in this set
        </Button>
      </S.EditButtons>
      <S.Flex>
        <Button variant="text" size="large" onClick={handleDiscardChanges}>
          Discard Changes
        </Button>
      </S.Flex>
    </>
  );
}
