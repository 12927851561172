"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainContentHeader = exports.MAIN_CONTENT_HEADER_HEIGHT = void 0;
var styled_components_1 = require("styled-components");
var mixins_1 = require("../../../lib/media-queries/mixins");
var MainContentHeaderBackButton_styles_1 = require("../MainContentHeaderBackButton/MainContentHeaderBackButton.styles");
// This is exported so other components can calculate their height or positioning
// based on this.
exports.MAIN_CONTENT_HEADER_HEIGHT = 90;
exports.MainContentHeader = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  justify-content: center;\n  // 16px = 8px on left and right\n  padding: 16px 8px 8px calc(16px + ", "px);\n  background-color: var(--primary, var(--green-600));\n  color: var(--white);\n  width: 100%;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  justify-content: center;\n  // 16px = 8px on left and right\n  padding: 16px 8px 8px calc(16px + ", "px);\n  background-color: var(--primary, var(--green-600));\n  color: var(--white);\n  width: 100%;\n\n  ", "\n"])), MainContentHeaderBackButton_styles_1.MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH, mixins_1.media.TabletLandscapeUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["    \n    justify-content: flex-start;\n    height: ", "px;\n    padding: 32px 16px 16px;\n  "], ["    \n    justify-content: flex-start;\n    height: ", "px;\n    padding: 32px 16px 16px;\n  "])), exports.MAIN_CONTENT_HEADER_HEIGHT));
var templateObject_1, templateObject_2;
