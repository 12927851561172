import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const CheckoutBillingSummary = styled.div<{
  $summaryType: 'checkout' | 'confirmation';
}>`
  border-radius: 4px;
  padding: 0;
  color: var(--blue-grey-900);
  background-color: var(--white);
  padding: 16px 16px 24px 16px;

  > * + * {
    margin-top: 8px;
  }

  ${({ $summaryType }) => media.TabletPortraitUp`
    border: ${
      $summaryType === 'checkout' ? '1px solid var(--primary-100)' : 'none'
    };
    
    > * + * {
      margin-top: 12px;
  }
  `}
`;

export const CheckoutBillingSummaryRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CheckoutBillingSummarySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.TabletPortraitUp`
    gap: 12px;
  `}
`;

export const CheckoutBillingSummaryPrimaryRow = styled(
  CheckoutBillingSummaryRow
)`
  font: var(--ui-medium-bold);
`;

export const CheckoutBillingSummarySecondaryRow = styled(
  CheckoutBillingSummaryRow
)`
  font: var(--ui-medium-regular);
`;

export const CheckoutBillingSummaryTertiaryRow = styled.div`
  text-align: right;

  a {
    display: inline-flex;
    gap: 4px;
    justify-content: flex-end;
    width: fit-content;
    margin-top: 4px;
  }
`;

export const CheckoutBillingSummaryHeader = styled(
  CheckoutBillingSummarySection
)`
  gap: 4px;
  display: none;

  ${media.TabletPortraitUp`
    display: block;
  `}
`;

export const CheckoutBillingSummaryMobileFooter = styled(
  CheckoutBillingSummarySection
)`
  gap: 4px;
  display: block;

  ${media.TabletPortraitUp`
    display: none;
  `}
`;

export const DueTodayRow = styled(CheckoutBillingSummaryRow)<{
  $summaryType: 'checkout' | 'confirmation';
}>`
  font: var(--ui-large-bold);

  ${({ $summaryType }) => media.TabletPortraitUp`
      font: ${
        $summaryType === 'checkout'
          ? 'var(--headline-small)'
          : 'var(--ui-large-bold)'
      };
  `}
`;

export const TotalPriceRow = styled(CheckoutBillingSummarySecondaryRow)`
  font: var(--ui-medium-regular);
  color: var(--primary-700);

  ${media.TabletPortraitUp`
    font: var(--ui-large-regular);
  `}
`;

export const TotalPrice = styled.span`
  font: var(--ui-medium-bold);

  ${media.TabletPortraitUp`
    font: var(--ui-large-bold);
  `}
`;

export const SummaryItems = styled(CheckoutBillingSummarySection)`
  padding: 0;

  ${media.TabletPortraitUp`
    padding: 12px 0;
    border: solid var(--primary-300);
    border-width: 1px 0;
  `}
`;

export const SummaryItem = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    '&:not(:last-child)': {
      marginBottom: '8px',
      paddingBottom: '20px',
      borderBottom: `1px solid ${theme.semantic?.primary[50]}`,
    },
  })
);

export const TotalCost = styled.span`
  color: var(--primary-700);
`;

export const TransactionFee = styled(CheckoutBillingSummarySecondaryRow)`
  border: solid var(--primary-300);
  border-width: 1px 0;
  padding: 8px 0;

  ${media.TabletPortraitUp`
    border-width: 0;
    padding:0;
  `}
`;
