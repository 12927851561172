import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HorizontalLine = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    return {
      borderBottom: `1px solid ${theme.foundational.blueGrey['200']}`,
    };
  }
);

export const ContentHeader = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { foundational } = theme.palette;

    return {
      ...theme.typography.ui.xl,
      display: 'block',
      fontWeight: theme.typography.weights.bold,
      color: foundational.blueGrey[900],
      marginBottom: 20,
    };
  }
);

export const ContentBody = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { foundational } = theme.palette;

    return {
      ...theme.typography.ui.xl,
      display: 'block',
      color: foundational.blueGrey[900],
    };
  }
);

export const NotifyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
`;

export const FailureText = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    return {
      ...theme.typography.ui.large,
      fontWeight: theme.typography.weights.bold,
      color: theme.foundational.red[500],
    };
  }
);

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 42px;

  div:nth-child(1) button {
    padding-left: 0;
  }
`;
