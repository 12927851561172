import * as yup from 'yup';
import { FormField } from '@la/types';

export const FIELD_REQUIRED_ERROR = 'This field is required.';
export const FILE_FIELD_REQUIRED_ERROR =
  'Please upload required documentation.';

export const createValidationSchema = (id: string): yup.AnyObjectSchema => {
  return yup.object({
    [id]: yup.array().of(
      yup.lazy((field: FormField) => {
        let value;
        const { type, usageLevel } = field;

        switch (type) {
          case 'NUMERIC':
            value = yup
              .number()
              .nullable()
              .transform((_, val) => (val === 0 || val ? Number(val) : null));
            if (usageLevel === 'REQUIRED') {
              value = value.required(FIELD_REQUIRED_ERROR);
            }
            break;
          case 'PICK_LIST':
            value = yup
              .number()
              .transform((val, originalValue) =>
                originalValue === '' ? undefined : val
              );
            if (usageLevel === 'REQUIRED') {
              value = value.required(FIELD_REQUIRED_ERROR);
            }
            break;
          case 'FILE_UPLOAD':
            value = yup
              .object({
                name: yup.string(),
                file: yup.mixed().nullable(),
                uuid: yup.string().optional(),
              })
              .default(undefined);
            if (usageLevel === 'REQUIRED') {
              value = value.required(FILE_FIELD_REQUIRED_ERROR);
            }
            break;
          case 'MULTIPLE_CHECKBOXES':
            value = yup.array().of(yup.mixed());
            if (usageLevel === 'REQUIRED') {
              value = value
                .min(1, FIELD_REQUIRED_ERROR)
                .required(FIELD_REQUIRED_ERROR);
            }
            break;
          default:
            value = yup.string().trim();
            if (usageLevel === 'REQUIRED') {
              value = value.required(FIELD_REQUIRED_ERROR);
            }
            break;
        }

        const properties = {
          propertyDefinitionId: yup.number(),
          programId: yup.number().optional(),
          type: yup.string(),
          name: yup.string(),
          usageLevel: yup.string(),
          order: yup.number(),
          items: yup.array().optional(),
          value,
        };

        return yup.object(properties);
      })
    ),
  });
};
