"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardCancelButton = exports.DivisionCardFooter = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.DivisionCardFooter = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 12px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  padding: 12px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
exports.DivisionCardCancelButton = (0, styled_components_1.default)(ds_ui_components_1.Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  left: -12px;\n"], ["\n  position: relative;\n  left: -12px;\n"])));
var templateObject_1, templateObject_2;
