import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { generateModalHeaderDynamicStyles } from 'theming/tokens/components/modal';
import { breakpointQueries } from 'utilities/css/breakpoints';
import { Button } from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton';
const headerPadding = {
  small: '12px 24px',
  large: '22px 44px'
};
export const ModalHeader = styled.div.withConfig({
  componentId: "sc-so8w6i-0"
})(({
  theme,
  $modalSize
}) => {
  const backgroundColor = generateModalHeaderDynamicStyles(theme);
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: headerPadding.small,
    backgroundColor,
    [`@media ${breakpointQueries.tabletPortraitUp}`]: {
      padding: $modalSize === 'fullscreen' ? headerPadding.large : headerPadding.small
    }
  };
});
export const ModalButton = styled(Button).withConfig({
  componentId: "sc-so8w6i-1"
})(() => {
  return {
    width: '100%'
  };
});
export const ModalHeaderCloseButtonContainer = styled.div.withConfig({
  componentId: "sc-so8w6i-2"
})({
  position: 'relative',
  left: '12px'
});
export const ModalHeaderCloseButton = styled(IconButton).withConfig({
  componentId: "sc-so8w6i-3"
})(({
  theme
}) => {
  const {
    foundational: {
      white
    }
  } = theme;
  return {
    '& svg': {
      fill: white,
      stroke: white,
      height: '16px',
      width: '16px',
      strokeWidth: '24px',
      [`@media ${breakpointQueries.tabletPortraitUp}`]: {
        height: '20px',
        width: '20px'
      }
    }
  };
});
export const ModalTitle = styled(Dialog.Title).withConfig({
  componentId: "sc-so8w6i-4"
})(({
  theme,
  $modalSize
}) => {
  const {
    headline
  } = theme.typography;
  return {
    margin: 0,
    wordBreak: 'break-word',
    color: theme.palette.foundational.white,
    ...headline.small,
    [`@media ${breakpointQueries.tabletPortraitUp}`]: $modalSize === 'fullscreen' ? headline.large : headline.small
  };
});
export const ModalBody = styled.div.withConfig({
  componentId: "sc-so8w6i-5"
})(({
  theme,
  $modalSize,
  $hasPadding
}) => {
  const {
    spacing
  } = theme;
  return {
    padding: $hasPadding ? spacing.large : 0,
    overflowY: 'auto',
    flex: $modalSize === 'fullscreen' ? 1 : undefined,
    [`@media ${breakpointQueries.tabletPortraitUp}`]: {
      padding: $modalSize === 'fullscreen' ? '48px' : undefined
    }
  };
});
export const ModalActionsFooter = styled.div.withConfig({
  componentId: "sc-so8w6i-6"
})(({
  theme,
  $hasTertiaryAction
}) => {
  const {
    spacing,
    foundational
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: spacing.xxs,
    padding: `${spacing.xs} ${spacing.medium}`,
    borderTop: '1px solid',
    borderColor: foundational.blueGrey[200],
    [`@media ${breakpointQueries.tabletPortraitUp}`]: {
      flexDirection: 'row',
      justifyContent: $hasTertiaryAction ? 'space-between' : 'flex-end',
      alignItems: 'center'
    }
  };
});
export const ModalActionsFooterButtonGroup = styled.div.withConfig({
  componentId: "sc-so8w6i-7"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: spacing.xxs,
    [`@media ${breakpointQueries.tabletPortraitUp}`]: {
      flexDirection: 'row'
    }
  };
});