import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';
import { AlertType } from './Alert';

export type AlertContainerProps = ThemedComponent<{ $type: AlertType }>;

export const AlertContainer = styled.div<AlertContainerProps>`
  width: 100%;
  min-height: 56px;
  padding: 16px;
  background-color: ${({ $type, theme }: AlertContainerProps) => {
    if ($type === 'success') {
      return theme.foundational.green[25];
    }
    return theme.foundational.red[25];
  }};
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ $type, theme }: AlertContainerProps) => {
    if ($type === 'success') {
      return theme.foundational.green[500];
    }
    return theme.palette.warning.dark;
  }};
  margin-top: 24px;
  grid-column-start: 1;
  grid-column-end: -1;

  & p {
    margin-left: 12px;
    flex: 1;
  }
  & svg {
    fill: ${({ $type, theme }: AlertContainerProps) => {
      if ($type === 'success') {
        return theme.foundational.green[500];
      }
      return theme.foundational.red[500];
    }};
    margin-top: 2px;
  }
`;

export const CloseIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    cursor: pointer;
  }
`;
