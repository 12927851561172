import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ErrorResponse } from 'lib/apis/verifyUrlSignature';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { Activity } from '../openapi-client/models';

const baseUrl = getBaseURL();

type GetActivitiesRequest = {
  siteId: number;
  programId?: number;
  pageSize?: number;
  pageNum?: number;
};

export const getAllActivities = async ({
  siteId,
  programId,
  pageNum,
  pageSize,
}: GetActivitiesRequest) => {
  try {
    return await axios.get<Activity[]>(
      `${baseUrl}/api/schedule/v1/activities?siteId=${siteId}&programId=${programId}&pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${extractAccessToken()}`,
          'X-Request-ID': uuidv4(),
        },
      }
    );
  } catch (e: any) {
    const error: ErrorResponse = {
      response: {
        status: 400,
        data: {
          message: e?.data?.message ?? 'Error retrieving activities.',
        },
      },
    };
    throw error;
  }
};
