import { Button, Typography } from '@la/ds-ui-components';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { RegistrationPeriodStatus } from '../../TournamentDetailsPage';
import * as S from './RegisterSection.styles';

export type RegisterSectionProps = {
  /**
   * This is temporarily being used while we are using a button instead
   * of a regular link.
   */
  navigateToRegistration: () => void;
  /**
   * For now this is used for the `data-href` property on the button being
   * assigned a `link` role. Once we have a true link to use, we can just
   * use this as the `to` value for the React Router link
   */
  registrationLink: string;
  /**
   * This tells if the current date is before, during, or after the registration
   * period, if it is defined. If the registration period is not defined, then
   * the status state will be 'no-registration-dates'.
   */
  registrationPeriodStatus: RegistrationPeriodStatus;
};

export function RegisterSection({
  navigateToRegistration,
  registrationLink,
  registrationPeriodStatus,
}: RegisterSectionProps) {
  const isRegistrationButtonShowing =
    registrationPeriodStatus.state === 'before-registration-period-ends' ||
    registrationPeriodStatus.state === 'no-registration-dates';

  const registrationMessage = getRegistrationMessage(registrationPeriodStatus);

  const isTabletPortraitDown = useMediaQuery(
    breakpointQueries.tabletPortraitDown
  );

  return (
    <S.RegisterSection>
      {registrationMessage ? (
        <Typography variant="ui" size="large" weight="bold">
          {registrationMessage}
        </Typography>
      ) : null}
      {isRegistrationButtonShowing ? (
        <Button
          // TODO: Update to XL when that becomes an option
          size="large"
          width={isTabletPortraitDown ? '100%' : undefined}
          // This element is styled like a Button but it is serving as a link
          // TODO: Replace this with the design system "link styled as button"
          // whenever it is ready
          role="link"
          data-href={registrationLink}
          onClick={navigateToRegistration}
        >
          Register now
        </Button>
      ) : null}
    </S.RegisterSection>
  );
}

function getRegistrationMessage(
  registrationPeriodStatus?: RegistrationPeriodStatus
) {
  if (!registrationPeriodStatus) {
    return null;
  }

  switch (registrationPeriodStatus.state) {
    case 'before-registration-period-opens': {
      const daysText = getDaysText(registrationPeriodStatus.numberOfDays);
      return `${daysText} until registration opens`;
    }
    case 'before-registration-period-ends': {
      if (!registrationPeriodStatus.numberOfDays) {
        return '';
      }
      const daysText = getDaysText(registrationPeriodStatus.numberOfDays);
      return `${daysText} until registration ends`;
    }
    case 'after-registration-period-ends': {
      return 'Registration is closed';
    }
    case 'no-divisions': {
      return 'Registration is not yet open';
    }
  }
}

function getDaysText(numberOfDays: number) {
  return numberOfDays === 1 ? '1 day' : `${numberOfDays} days`;
}
