import React from 'react';
import * as S from './RadioButton.styles';

export type RadioButtonProps = {
  iconColor: string | null;
  checked?: boolean;
  handleChange?: () => void;
  name?: string;
  label?: string;
  value?: string | number;
};

/* RadioButton */
export function RadioButton({
  iconColor,
  checked = false,
  handleChange,
  label,
  name,
  value,
}: RadioButtonProps) {
  return (
    <S.RadioButtonContainer onClick={handleChange}>
      <S.Radio
        type="radio"
        checked={checked}
        name={name}
        value={value}
        readOnly
      />
      <S.RadioIconContainer>
        <S.UncheckedIcon $iconColor={iconColor} aria-hidden="true" />
        <S.CheckedIcon
          checked={checked}
          $iconColor={iconColor}
          aria-hidden="true"
        />
      </S.RadioIconContainer>
      {label ? <S.RadioLabel>{label}</S.RadioLabel> : null}
    </S.RadioButtonContainer>
  );
}
/* */
