"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderTipContainer = exports.SummaryPlaceholderContainer = exports.WizardSummary = void 0;
var styled_components_1 = require("styled-components");
exports.WizardSummary = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid var(--blue-grey-400);\n  border-radius: 4px;\n  padding: 16px;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid var(--blue-grey-400);\n  border-radius: 4px;\n  padding: 16px;\n"])));
exports.SummaryPlaceholderContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n\n  & > svg {\n    margin-top: 4px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n\n  & > svg {\n    margin-top: 4px;\n  }\n"])));
exports.PlaceholderTipContainer = styled_components_1.default.div(function (_a) {
    var ThemedComponent = _a.theme;
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
    };
});
var templateObject_1, templateObject_2;
