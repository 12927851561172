import { ReactComponent as LoadingRippleSVG } from 'assets/loading_ripple.svg';
import * as S from './LoadingIndicatorOverlay.styles';

export type LoadingIndicatorOverlayProps = {
  message: string;
};

/* LoadingIndicatorOverlay */
export default function LoadingIndicatorOverlay({
  message,
}: LoadingIndicatorOverlayProps) {
  return (
    <S.LoadingIndicatorOverlay>
      <S.LoadingRippleWrapper>
        <LoadingRippleSVG />
      </S.LoadingRippleWrapper>

      <S.LoadingMessage>{message}</S.LoadingMessage>
    </S.LoadingIndicatorOverlay>
  );
}
/* */
