import { DateTime } from 'luxon';

/**
 * @param {DateTime} dateTime
 * @return {string}
 * @description Convert a Luxon DateTime object to an ISO date-time string
 */

/**
 * @param {string} dateTime
 * @return {DateTime}
 * @description Convert an ISO date-time string to a Luxon DateTime object
 */
export function getAdjustedDateTime(dateTime: string): DateTime {
  return DateTime.fromISO(dateTime, { zone: 'utc' });
}

export function convertDateTimeToISO(dateTime: DateTime) {
  return dateTime.toISO({
    includeOffset: false,
    suppressSeconds: true,
  });
}

/**
 * @param {string} dateTime
 * @param {string} returnFormat
 * @returns {string}
 * @description Returns formatted date from ISO 8601 datetime string
 */
export function formatISODateTime(
  dateTime: string,
  returnFormat: string = 'dd/MM/y'
): string {
  return getAdjustedDateTime(dateTime).toFormat(returnFormat);
}
