import PageTitle from '../Shell/PageTitle/PageTitle';
import * as S from './Membership.styles';

/* MembershipSuccess */
export default function MembershipSuccess() {
  return (
    <S.Membership id="membership-success-page">
      <PageTitle>Membership Success</PageTitle>
      <h1
        style={{
          display: 'flex',
          fontSize: '240px',
          fontWeight: '700',
          color: 'black',
          zIndex: 1,
        }}
      >
        SUCCESS!
      </h1>
    </S.Membership>
  );
}
/* */
