"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("./Alert.styles");
var Alert = function (_a) {
    var icon = _a.icon, message = _a.message, onClose = _a.onClose;
    return ((0, jsx_runtime_1.jsxs)(S.AlertContainer, { children: [icon, (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: message })), onClose ? ((0, jsx_runtime_1.jsx)(S.CloseIconContainer, __assign({ onClick: onClose }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.CloseIcon, { size: "medium", fill: "var(--blue-grey-900)" }) }))) : null] }));
};
exports.Alert = Alert;
