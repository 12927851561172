import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';
import { SiteLogo } from '../SiteLogo/SiteLogo.styles';

export const Footer = styled.footer`
  width: 100%;
`;

export const FooterMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  width: 100%;
  height: 60px;
  padding: 0 8px;
  border-top: 1px solid var(--primary);

  ${media.PhoneOnly`
    height: 48px;
    padding: 0px 12px;
  `}
`;

export const SiteLogoContainer = styled.div`
  width: 180px;

  ${media.PhoneOnly`
    display: none;
  `}

  ${SiteLogo} {
    max-height: 54px;
    max-width: 180px;
    float: left;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex: 1;

  ${media.PhoneOnly`
    justify-content:flex-start;
    gap: 20px;
  `}
`;

export const SocialLink = styled.a`
  border: 1px solid transparent;
  padding: 2px;

  &:focus {
    outline: none;
    border: 1px solid var(--secondary-600);
    border-radius: 4px;
  }

  svg {
    fill: var(--secondary-600);
    cursor: pointer;
    display: block;
    height: 22px;

    &:hover {
      fill: var(--secondary-700);
    }

    &:active {
      fill: var(--secondary-800);
    }

    ${media.PhoneOnly`
      width: 16px;
      height: 16px;
    `}
  }
`;

export const PoweredByLALogoContainer = styled.div`
  width: 180px;

  ${media.PhoneOnly`
    width: 140px;
    height: 19px;
  `}

  > img {
    float: right;

    ${media.PhoneOnly`
      width: 140px;
      height: 19px;
    `}
  }
`;
