import styled from 'styled-components/macro';
import { CardHeader, Typography } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const DivisionCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  overflow-wrap: anywhere;
`;

export const DivisionCardHeaderUnavailable = styled(Typography).attrs({
  size: 'large',
  variant: 'ui',
  weight: 'bold',
})`
  color: var(--blue-grey-600);
  text-align: right;
`;

export const RegistrationStartDate = styled.span`
  white-space: nowrap;
`;

export const TitleAndSpotsLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.TabletPortraitUp`  
    align-items: flex-end;
    flex-direction: row;
  `}
`;

export const SpotsLeftMessage = styled(Typography).attrs({
  size: 'small',
  variant: 'ui',
})`
  color: var(--blue-grey-900);
`;

export const SpotsLeftWarningMessage = styled(SpotsLeftMessage).attrs({
  weight: 'bold',
})`
  color: var(--red);
`;

export const DivisionCost = styled.div`
  white-space: nowrap;
  margin-left: 8px;
`;
