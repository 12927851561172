import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PaymentOptionsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentOptionsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TournamentName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--ui-large-regular);
`;

export const PaymentDetail = styled.div`
  font: var(--ui-large-bold);
`;

export const ApplyDiscountCodesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px 16px;
`;

export const ApplyDiscountCodeDivider = styled.hr`
  border: none;
  border-top: 1px solid var(--primary-35);
  margin: 0 0 12px 0;

  ${media.TabletLandscapeUp`
      margin: 0 0 16px 0;
  `}
`;

export const ApplyDiscountCodeSectionTitle = styled.p`
  font: var(--ui-xtra-large-bold);
`;

export const TextInputWithButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  > div:first-of-type {
    flex: 1;
  }

  ${media.TabletPortraitUp`
    width: 380px;
  `}
`;

export const DiscountCodesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
