import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PageTitleSection = styled.div`
  width: 100%;
  padding: 16px 12px;
  background-color: var(--primary);
  color: var(--white);

  ${media.TabletLandscapeUp`
    padding: 32px 16px 16px;
  `}
`;

export const PageTitleSectionLayout = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

export const Logo = styled.img`
  max-width: 180px;
  max-height: 120px;

  ${media.TabletLandscapeUp`
    max-width: 240px;
    max-height: 160px;
  `};
`;

export const TitleAndSubtitle = styled.div<{ $withLogo?: boolean }>`
  padding-bottom: ${({ $withLogo }) => ($withLogo ? '16px' : 0)};
`;
