import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

export const RolloverSummary = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    width: '100%',

    'thead tr, tbody tr:not(:last-child)': {
      'td, th': {
        borderBottom: `1px solid ${theme.semantic?.primary[300]}`,
      },
    },
  })
);
