import { KeyboardEvent } from 'react';
import { DateTime, Info, WeekdayNumbers } from 'luxon';

export type Weekday = {
  label: string;
  value: string;
};

const ISO_WEEKDAYS_SHORT = Info.weekdays('short');
const ISO_WEEKDAYS_LONG = Info.weekdays();
const ISO_WEEKDAYS: Weekday[] = ISO_WEEKDAYS_SHORT.map((weekday, index) => ({
  label: weekday,
  value: ISO_WEEKDAYS_LONG[index],
}));

export const WEEKDAYS: Weekday[] = [
  ISO_WEEKDAYS[ISO_WEEKDAYS.length - 1],
  ...ISO_WEEKDAYS.slice(0, ISO_WEEKDAYS.length - 1),
];

const KEYBOARD_TABLE_EVENTS: string[] = [
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
];
const KEYBOARD_DAYS_EVENTS: string[] = [
  'PageUp',
  'PageDown',
  'Home',
  'End',
  'Enter',
  ' ',
];

export const MAX_WEEKS_IN_MONTH: number = 6;
export const MAX_DAYS_IN_WEEK: number = 7;

export function isSelected(dt: DateTime, selectedDate?: DateTime): boolean {
  return !!selectedDate && selectedDate.hasSame(dt, 'day');
}

export function getDays(date: DateTime): (number | null)[] {
  const firstWeekday = date.startOf('month').weekday;
  const daysInMonth = date.daysInMonth;

  const buffer = [...Array(firstWeekday === 7 ? 0 : firstWeekday)];
  const days = [...Array(daysInMonth)].map((_, index) => index + 1);

  return buffer.concat(days);
}

export function getYears(start: number, total: number): number[] {
  return [...Array(total)].map((_, index) => start + index);
}

export function getSameWeekSameWeekday(
  newDisplayDate: DateTime,
  focusDate: DateTime,
  displayDate: DateTime
): DateTime {
  const endOfFocusWeek: DateTime =
    focusDate.weekday === MAX_DAYS_IN_WEEK
      ? focusDate.plus({ days: MAX_DAYS_IN_WEEK - 1 })
      : focusDate.set({ weekday: (MAX_DAYS_IN_WEEK - 1) as WeekdayNumbers });
  const firstDayDate: DateTime = displayDate.startOf('month');

  const weeksToAdd: number = Math.floor(
    endOfFocusWeek.diff(firstDayDate, 'weeks').toObject().weeks ?? 0
  );
  const weekdayToBe: number = focusDate.weekday;

  let newDate: DateTime = newDisplayDate;
  if (newDisplayDate.month === 1 && displayDate.month === 12) {
    newDate = displayDate.set({ month: 13 });
  }
  newDate = newDate.startOf('month').plus({ weeks: weeksToAdd });

  if (
    weekdayToBe !== MAX_DAYS_IN_WEEK &&
    newDate.weekday === MAX_DAYS_IN_WEEK
  ) {
    newDate = newDate.plus({ days: weekdayToBe });
  } else if (
    weekdayToBe === MAX_DAYS_IN_WEEK &&
    newDate.weekday !== MAX_DAYS_IN_WEEK
  ) {
    newDate = newDate.minus({ days: newDate.weekday });
  } else {
    newDate = newDate.set({ weekday: weekdayToBe as WeekdayNumbers });
  }

  return newDate;
}

export function navigateGrid(
  event: KeyboardEvent,
  date: DateTime,
  unit: 'day' | 'year',
  jumpAmount: number,
  onGridNavigate: (dt: DateTime) => void
): void {
  const { key } = event;

  if (KEYBOARD_TABLE_EVENTS.includes(key)) {
    event.preventDefault();

    let newDate: DateTime | undefined;
    switch (key) {
      case 'ArrowUp':
        newDate = date.minus({ [unit]: jumpAmount });
        break;
      case 'ArrowDown':
        newDate = date.plus({ [unit]: jumpAmount });
        break;
      case 'ArrowLeft':
        newDate = date.minus({ [unit]: 1 });
        break;
      case 'ArrowRight':
        newDate = date.plus({ [unit]: 1 });
        break;
      default:
        break;
    }

    if (!!newDate) {
      onGridNavigate(newDate);
    }
  }
}

export function navigateDay(
  event: KeyboardEvent,
  date: DateTime,
  focusDate: DateTime,
  displayDate: DateTime,
  onDateSelect: (dt: DateTime) => void,
  onDisplayDateChange: (dt: DateTime) => void
): void {
  const { key, shiftKey } = event;

  if (KEYBOARD_DAYS_EVENTS.includes(key)) {
    event.preventDefault();

    let newDate: DateTime;
    switch (key) {
      case 'PageUp':
        if (shiftKey) {
          newDate = displayDate.set({ year: displayDate.year - 1 });
        } else {
          newDate = displayDate.set({ month: displayDate.month - 1, day: 1 });
          newDate = getSameWeekSameWeekday(newDate, focusDate, displayDate);

          if (newDate.month === displayDate.month) {
            newDate = newDate.minus({ weeks: 1 });
          } else if (newDate.month === displayDate.minus({ months: 2 }).month) {
            newDate = newDate.plus({ weeks: 1 });
          }
        }
        onDisplayDateChange(newDate);
        break;
      case 'PageDown':
        if (event.shiftKey) {
          newDate = displayDate.set({ year: displayDate.year + 1 });
        } else {
          newDate = displayDate.set({ month: displayDate.month + 1, day: 1 });
          newDate = getSameWeekSameWeekday(newDate, focusDate, displayDate);

          if (newDate.month === displayDate.month) {
            newDate = newDate.plus({ weeks: 1 });
          } else if (newDate.month === displayDate.plus({ months: 2 }).month) {
            newDate = newDate.minus({ weeks: 1 });
          }
        }
        onDisplayDateChange(newDate);
        break;
      case 'Home':
        if (date.weekday !== 7) {
          const firstDate = date.minus({ days: date.weekday });
          onDisplayDateChange(firstDate);
        }
        break;
      case 'End':
        if (date.weekday !== 6) {
          const lastDate =
            date.weekday === 7
              ? date.plus({ days: MAX_DAYS_IN_WEEK - 1 })
              : date.set({ weekday: (MAX_DAYS_IN_WEEK - 1) as WeekdayNumbers });
          onDisplayDateChange(lastDate);
        }
        break;
      case 'Enter':
      case ' ':
        onDateSelect(date);
        break;
      default:
        break;
    }
  } else {
    navigateGrid(event, date, 'day', MAX_DAYS_IN_WEEK, (dt: DateTime) =>
      onDisplayDateChange(dt)
    );
  }
}
