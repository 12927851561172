import styled from 'styled-components/macro';
import { ThemedComponent, Typography } from '@la/ds-ui-components';

export const RolloverTeam = styled.div<ThemedComponent>({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  marginBottom: '16px',
});

export const SummaryName = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const ChangeTeamButton = styled.div({
  flexGrow: 1,
  flexShrink: 0,
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 400,
    textDecoration: 'underline',
  },
});

export const SummaryDetails = styled(Typography)<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    color: theme.foundational.blueGrey[400],
  })
);

export const Members = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  margin: '16px 0',
});

export const MembersTitle = styled(Typography).attrs({
  size: 'large',
  variant: 'ui',
  weight: 'bold',
})({});

export const NoMembersText = styled(Typography).attrs({
  size: 'large',
  variant: 'ui',
})<ThemedComponent>(({ theme }: ThemedComponent) => ({
  color: theme.foundational.blueGrey[600],
  margin: '8px 0 24px',
  textAlign: 'center',
}));

export const Member = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    margin: '0 -16px',
    padding: '8px 16px',
    cursor: 'pointer',

    '&:hover': {
      background: theme.foundational.blueGrey[50],
    },
  })
);
