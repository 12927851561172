import { Root, Indicator } from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import { checkboxBorderRadius, checkboxGaps, checkboxVariantSizes } from './Checkbox.utils';
export const CheckboxContainer = styled.div.withConfig({
  componentId: "sc-1eh6a57-0"
})(["display:flex;align-items:center;gap:", ";&[data-disabled]{cursor:not-allowed;}"], ({
  $size
}) => checkboxGaps[$size]);
export const CheckboxRoot = styled(Root).withConfig({
  componentId: "sc-1eh6a57-1"
})(["cursor:pointer;border:1px solid;border-color:", ";border-radius:", ";height:", ";width:", ";background-color:var(--white);margin:0;margin-left:", ";padding:0;position:relative;display:flex;align-items:center;justify-content:center;overflow:hidden;outline-width:2px;outline-offset:4px;outline-color:", ";&[data-state='checked'],&[data-state='indeterminate']{background-color:", ";border-color:", ";&:hover{background-color:", ";border-color:", ";}&:active{background-color:", ";border-color:", ";}&[data-disabled]{border-color:var(--secondary-200);background-color:var(--secondary-200);}}&[data-disabled]{border-color:var(--secondary-200);pointer-events:none;}&:hover{border-color:", ";}&:active{border-color:", ";}"], ({
  $error
}) => !$error ? 'var(--secondary-400)' : 'var(--red-500)', ({
  $size
}) => checkboxBorderRadius[$size], ({
  $size
}) => checkboxVariantSizes[$size], ({
  $size
}) => checkboxVariantSizes[$size], ({
  $marginLeft
}) => $marginLeft ? `${$marginLeft + 24}px` : 0, ({
  $error
}) => !$error ? 'var(--primary-400)' : 'var(--red-400)', ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-500)', ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-500)', ({
  $error
}) => !$error ? 'var(--primary-800)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-800)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-900)' : 'var(--red-800)', ({
  $error
}) => !$error ? 'var(--primary-900)' : 'var(--red-800)', ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-800)' : 'var(--red-800)');
export const CheckboxIndicator = styled(Indicator).withConfig({
  componentId: "sc-1eh6a57-2"
})(["display:flex;align-items:center;height:20px;width:20px;justify-content:center;overflow:hidden;"]);
export const CheckboxLabel = styled.label.withConfig({
  componentId: "sc-1eh6a57-3"
})(["font:", ";color:var(--secondary-900);cursor:pointer;&[data-disabled]{color:var(--secondary-200);pointer-events:none;}"], ({
  $size
}) => `var(--ui-${$size}-regular)`);
export const CheckboxRequiredIcon = styled.span.withConfig({
  componentId: "sc-1eh6a57-4"
})(["color:var(--red);font:var(--ui-size-small);margin-left:2px;&::after{content:'*';}"]);