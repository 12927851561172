import styled from 'styled-components/macro';

export const PaymentPolicyName = styled.div`
  margin-bottom: 16px;
  font: var(--headline-small);
  color: var(--blue-grey-900);
`;

export const PaymentPolicyContent = styled.div`
  font: var(--ui-large-regular);
  color: var(--blue-grey-900);
`;
