import { useEffect } from 'react';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import Portal from 'components/Portal/Portal';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetTeamRepLabelQuery } from 'redux/services/staffRoleApi';
import { useAppSelector } from 'redux/store';
import PlayAppLockup from 'domains/Checkout/PlayAppLockup/PlayAppLockup';
import TransactionConfirmationCard from 'domains/Checkout/TransactionConfirmationCard/TransactionConfirmationCard';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import ConfirmationRollup from '../ConfirmationRollup/ConfirmationRollup';
import * as S from './ConfirmationPage.styles';

/* ConfirmationPage */
export default function ConfirmationPage() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: teamRepLabel = '' } = useGetTeamRepLabelQuery({
    siteId: siteId ?? '',
  });

  const transactionConfirmationBody = teamRepLabel
    ? [
        `Almost there! Your assigned ${teamRepLabel}(s) will receive an invitation and further instructions.`,
        "To check on a team's registration status, visit the Member Dashboard.",
      ]
    : [];

  useEffect(() => {
    document.title = siteName ? `${siteName}: Confirmation` : 'Confirmation';
  }, [siteName]);

  return (
    <S.ConfirmationPage>
      <PageTitle>Thank you</PageTitle>
      <ColumnGrid>
        <ConfirmationMessage />
        <ConfirmationRollup />
        <TransactionConfirmationCard
          cardBody={transactionConfirmationBody}
          cardTitle="Tournament registration"
        />
      </ColumnGrid>
      <Portal parentId="#mp-footer-portal">
        <PlayAppLockup />
      </Portal>
    </S.ConfirmationPage>
  );
}
/* */

export function ConfirmationMessage() {
  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );
  const headSize = isTabletLandscapeUp ? 'large' : 'small';
  return (
    <S.ConfirmationMessage>
      <S.ConfirmationHead customTag="h2" variant="headline" size={headSize}>
        You're all set!
      </S.ConfirmationHead>
      <S.MessageBody size="large" variant="ui">
        You will receive a confirmation email in your inbox shortly. If you have
        any questions, please contact the tournament administrator.
      </S.MessageBody>
    </S.ConfirmationMessage>
  );
}
