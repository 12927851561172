"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfSpotsLeft = void 0;
var getNumberOfSpotsLeft = function (division) {
    var numberOfSpotsLeft;
    if (division.maxNumberOfTeams) {
        if (division.numberOfRegisteredTeams) {
            numberOfSpotsLeft =
                division.maxNumberOfTeams - division.numberOfRegisteredTeams;
        }
        else {
            numberOfSpotsLeft = division.maxNumberOfTeams;
        }
    }
    return numberOfSpotsLeft;
};
exports.getNumberOfSpotsLeft = getNumberOfSpotsLeft;
