import { DateTime } from 'luxon';

export const FORMAT_DATE_RANGE_INVALID_DATE_ERROR_MESSAGE =
  'At least one date provided was not valid.';

export const INVALID_DATE_ERROR_MESSAGE = 'The date provided was not valid.';

const SHORTENED_MONTHS: string[] = [
  'January',
  'February',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Shortnes long month names. If month is before March or after July, the
 * month is shortened.
 * @param month The month name. May be null due to month names being
 * a locale-based property of dates.
 * @returns Month name or "Invalid Month" if `month` is `null`.
 */
export const shortenLongMonthNames = (month: string | null): string => {
  if (!month) {
    return 'Invalid Month';
  }

  if (SHORTENED_MONTHS.includes(month)) {
    // Months before March and after July are shortened.
    return `${month.substring(0, 3)}.`;
  }

  return month;
};

export function formatDateUsingShortMonthName(date: DateTime): string {
  if (!date.isValid) {
    throw new Error(INVALID_DATE_ERROR_MESSAGE);
  }
  const dayYearFormat = 'd, yyyy';

  const { monthLong } = date;
  const formattedMonth = shortenLongMonthNames(monthLong);

  return `${formattedMonth} ${date.toFormat(dayYearFormat)}`;
}

/**
 * Formats given start and end dates into a date range, depending on
 * whether the two dates have the same year, month, and or day.
 * @param start Beginning date
 * @param end Ending date
 * @returns Date range joining the `start` and `end` dates. Or, if either
 * of the dates are invalid, returns "Invalid Date Range".
 * - Different years: `month day, year - month day, year`
 * - Different months: `month day - month day, year`
 * - Different days: `month day-day, year`
 * - Same month, day, and year: `month day, year`
 */
export const formatDateRange = (start: DateTime, end?: DateTime): string => {
  if (!start.isValid || (end && !end.isValid)) {
    throw new Error(FORMAT_DATE_RANGE_INVALID_DATE_ERROR_MESSAGE);
  }

  const dayYearFormat = 'd, yyyy';

  const { day: startDay, monthLong: startMonth, year } = start;
  const formattedStartMonth = shortenLongMonthNames(startMonth);

  if (!end) {
    return `Starts on ${startMonth} ${start.toFormat(dayYearFormat)}`;
  }

  const { day: endDay, monthLong: endMonth } = end;
  const formattedEndMonth = shortenLongMonthNames(endMonth);

  if (start.year !== end.year) {
    // month day, year-month day, year
    return (
      `${formattedStartMonth} ${start.toFormat(dayYearFormat)} - ` +
      `${formattedEndMonth} ${end.toFormat(dayYearFormat)}`
    );
  }

  if (startMonth !== endMonth) {
    // month day-month day, year
    return (
      `${formattedStartMonth} ${startDay} - ` +
      `${formattedEndMonth} ${endDay}, ${year}`
    );
  }

  if (startDay !== endDay) {
    // month day-day, year
    return `${formattedStartMonth} ${startDay}-${endDay}, ${year}`;
  }

  // month day, year
  return `${formattedStartMonth} ${start.toFormat(dayYearFormat)}`;
};

export const formatToISO = (year: number, month: number): string =>
  `${DateTime.fromObject({ year: year, month: month }).toISO()}`;
