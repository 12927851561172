import { CSSObject } from 'styled-components';

export const generateBannerHTML = (primaryColor: string | undefined) => {
  // Create a div element
  const divElement = document.createElement('div');

  // Create a style object
  const styles: CSSObject = {
    width: '100%',
    margin: '0 0 24px 0',
    padding: '32px 16px 16px',
    fontFamily: "'IBM Plex Sans Condensed', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 600,
    letterSpacing: '0.5%',
    lineHeight: '1.75rem',
    backgroundColor: primaryColor,
    color: 'white',
  };

  Object.assign(divElement.style, styles);
  const textNode = document.createTextNode('Complete your member profile');
  divElement.appendChild(textNode);

  function updateStyles() {
    const windowInnerWidth = window.innerWidth;

    if (windowInnerWidth >= 600) {
      divElement.style.fontSize = '2rem';
      divElement.style.lineHeight = '2.5rem';
      divElement.style.letterSpacing = '0.75%';
    } else {
      divElement.style.fontSize = '1.5rem';
      divElement.style.lineHeight = '1.75rem';
      divElement.style.letterSpacing = '0.5%';
    }
  }

  updateStyles();

  // Listen for changes in viewport size
  window.addEventListener('resize', updateStyles);
  return divElement;
};

export const createFontLink = () => {
  // Create a link elements
  const linkElement = document.createElement('link');
  linkElement.rel = 'stylesheet';
  linkElement.href =
    'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@600&display=swap'; // Specify the URL of the font family from an online CDN.
  return linkElement;
};
