import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';
import { PageTitle } from '../PageTitle/PageTitle.styles';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
  margin-bottom: 15px;

  ${media.TabletPortraitUp`
    padding-top: 16px;
    padding-bottom: 32px;
  `}

  ${media.TabletLandscapeUp`
    padding-top: 24px;
    padding-bottom: 48px;
  `}

  
  ${PageTitle} {
    width: 100%;
    margin: -8px 0 12px;

    ${media.TabletPortraitUp`
      margin: -16px 0 16px;
    `}

    ${media.TabletLandscapeUp`
      margin: -24px 0 24px;
    `}
  }
`;
