import Backdrop from 'components/Backdrop/Backdrop';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { useUser } from 'lib/context/UserContext';
import PoweredBy from 'assets/LA_lockups/powered-by-leagurapps-white.png';
import CloseButton from '../CloseButton/CloseButton';
import LoginMenuMobile from '../LoginMenuMobile/LoginMenuMobile';
import { NavItemData } from '../NavBar/NavBar';
import NavList from '../NavList/NavList';
// import ShoppingCartCounter from '../ShoppingCartCounter/ShoppingCartCounter';
import SiteLogo from '../SiteLogo/SiteLogo';
import UserMenuMobile from '../UserMenuMobile/UserMenuMobile';
import * as S from './NavigationPanel.styles';

export type MobileNavigationProps = {
  navData: NavItemData[];
  siteLogoImageSrc?: string;
  siteLogoHref?: string;
  siteName: string;
};

/* NavigationPanel */
export default function NavigationPanel({
  navData,
  siteLogoImageSrc,
  siteLogoHref,
  siteName,
}: MobileNavigationProps) {
  const { isUserLoggedIn } = useUser();
  const { isNavigationPanelVisible, updateNavPanelVisibility } =
    useMainNavigation();

  function hideNavigationPanel() {
    updateNavPanelVisibility(false);
  }

  return isNavigationPanelVisible ? (
    <Backdrop clickHandler={hideNavigationPanel} transparent={false}>
      <S.MobileNavigation $isVisible={isNavigationPanelVisible}>
        <S.MobileNavigationContainer>
          <S.MobileMenuHeader>
            <SiteLogo
              siteLogoImageSrc={siteLogoImageSrc}
              siteName={siteName}
              siteLogoHref={siteLogoHref}
            />
            {/* TODO Address cart Icon visuality using LD-flag */}
            {/* <ShoppingCartCounter /> */}
            <S.CloseButtonContainer>
              <CloseButton clickHandler={hideNavigationPanel} />
            </S.CloseButtonContainer>
          </S.MobileMenuHeader>

          {isUserLoggedIn ? <UserMenuMobile /> : <LoginMenuMobile />}
          <NavList navData={navData} />
        </S.MobileNavigationContainer>
        <S.MobileNavigationFooter>
          <img src={PoweredBy} alt="Powered by LeagueApps" />
        </S.MobileNavigationFooter>
      </S.MobileNavigation>
    </Backdrop>
  ) : null;
}
/* */
