import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalSecondaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import LoadingIndicatorOverlay from 'components/LoadingIndicatorOverlay/LoadingIndicatorOverlay';
import { getStripeKey } from 'lib/utils/stripeUtils';
import UseCardForm from './UseCardForm';

export type UseCardModalData = {
  closeModal: () => void;
  hasBackButton: boolean;
  modalTitle: string;
  openEditPaymentModal: () => void;
};

/* AddCreditCard */
export default function UseCardModal({
  closeModal,
  hasBackButton,
  modalTitle,
  openEditPaymentModal,
}: UseCardModalData) {
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isDemoSite } = useFlags();

  function displaySubmissionErrors(errorMessage: string) {
    setErrorMessage(errorMessage);
  }

  const stripePromise: Stripe | PromiseLike<Stripe | null> | null = loadStripe(
    getStripeKey(isDemoSite)
  );

  return (
    <Modal
      onOpenChange={closeModal}
      open={true} // modal is rendered conditionally so it is always open
      primaryAction={
        <ModalPrimaryActionButton form="use-card-form" type="submit">
          Use card
        </ModalPrimaryActionButton>
      }
      secondaryAction={
        hasBackButton ? (
          <ModalSecondaryActionButton onClick={openEditPaymentModal}>
            Back
          </ModalSecondaryActionButton>
        ) : null
      }
      size="medium"
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      title={modalTitle}
    >
      {!!errorMessage ? (
        <ErrorCard
          message={errorMessage}
          closeCard={() => {
            displaySubmissionErrors('');
          }}
        />
      ) : null}
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=DM+Sans&display=swap',
            },
          ],
        }}
      >
        <UseCardForm
          closeModal={closeModal}
          submissionErrorHandler={displaySubmissionErrors}
          updateLoadingOverlayVisibility={setIsOverlayVisible}
        />
      </Elements>
      {isOverlayVisible ? (
        <LoadingIndicatorOverlay message="Your payment method is being added." />
      ) : null}
    </Modal>
  );
}
/* */
