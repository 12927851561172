import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as ShoppingCart } from 'assets/icons/shopping_cart.svg';

export const ShoppingCartCounter = styled(Link)`
  display: block;
  position: relative;
  width: 38px;
  height: 32px;
  padding: 0 4px;
  background: none;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;

  &:focus {
    outline: none;
    padding: 2px;
    border: 1px solid var(--secondary-600);
  }
`;

export const ShoppingCartIcon = styled(ShoppingCart)`
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 4px;
  width: 23px;
  height: 21px;
  fill: var(--secondary-900);
`;

export const ItemCount = styled.span`
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 22px;
  height: 14px;
  padding: 0 3px;
  font-size: 11px;
  font-weight: 700;
  font-family: var(--primary-font-family);
  line-height: 14px;
  color: var(--white);
  background-color: var(--secondary-900);
  border-radius: 7px;
`;
