import styled from 'styled-components';
export const StyledForm = styled.form.withConfig({
  componentId: "sc-zvx2ne-0"
})({
  display: 'flex',
  flexDirection: 'column'
});
export const FormActions = styled.div.withConfig({
  componentId: "sc-zvx2ne-1"
})(({
  theme,
  $inputSize
}) => {
  const {
    spacing
  } = theme;
  const verticalMargin = $inputSize === 'large' ? '36px' : spacing.xxl;
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: `${verticalMargin} 0`
  };
});
export const InputGroup = styled.div.withConfig({
  componentId: "sc-zvx2ne-2"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxs
  };
});