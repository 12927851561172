"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProgramIdFromURL = exports.getSiteIdFromURL = void 0;
function getSiteIdFromURL() {
    var pathname = window.location.pathname;
    var matches = pathname.match(/sites\/(.*)\//);
    if (!matches) {
        return 0;
    }
    // Pull out the number between '/sites/' and the following /.
    return parseInt(matches[1]);
}
exports.getSiteIdFromURL = getSiteIdFromURL;
function getProgramIdFromURL() {
    var urlParams = new URLSearchParams(window.location.search);
    var programId = urlParams.get('bid');
    if (!programId) {
        return 0;
    }
    return parseInt(programId);
}
exports.getProgramIdFromURL = getProgramIdFromURL;
