export const getColumnSize = (columns, breakpoint) => {
  if (!breakpoint || !columns) {
    return 1;
  }
  if (typeof columns === 'number') {
    return columns;
  }
  if (Array.isArray(columns)) {
    const [mobile, tablet, desktop] = columns;
    const sizeMap = {
      mobile,
      tablet,
      desktop
    };
    return sizeMap[breakpoint];
  }
  return columns[breakpoint] ?? 1;
};