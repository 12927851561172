const blue = {
  25: '#F5F8FF',
  50: '#EBF2FF',
  100: '#C3D7FE',
  200: '#9BBCFD',
  300: '#72A1FC',
  400: '#4A86FB',
  500: '#3679FA',
  600: '#316DE1',
  700: '#2655AF',
  800: '#204996',
  900: '#163064'
};
const blueGrey = {
  25: '#F9FAFB',
  35: '#F3F5F6',
  50: '#ECEFF1',
  100: '#D7DEE1',
  150: '#CDD6DA',
  200: '#B0BEC5',
  250: '#A1B2BA',
  300: '#90A4AE',
  350: '#8097A2',
  400: '#78909C',
  500: '#607D8B',
  600: '#546E7A',
  700: '#455A64',
  800: '#37474F',
  900: '#263238'
};
const green = {
  25: '#F8FCF8',
  35: '#F1F9F1',
  50: '#E6F4E6',
  100: '#CDEACD',
  150: '#ADDBAD',
  200: '#80CA80',
  300: '#4DB54D',
  350: '#44A244',
  400: '#1AA01A',
  500: '#009500',
  600: '#007A00',
  700: '#006800',
  800: '#004B00',
  900: '#002D00'
};
const orange = {
  25: '#FFFAF5',
  50: '#FEF0E4',
  100: '#FEE1C7',
  200: '#FDCCA2',
  300: '#FCB87D',
  400: '#FBA458',
  500: '#FA9A46',
  600: '#F98A28',
  700: '#DF6C06',
  800: '#C76005',
  900: '#AE5404'
};
const red = {
  25: '#FEE6E6',
  50: '#FECDCD',
  100: '#FDA5A5',
  200: '#FB7474',
  300: '#FB5656',
  400: '#F91010',
  500: '#D90606',
  600: '#C70505',
  700: '#A40404',
  800: '#820303',
  900: '#5C0202'
};
const white = '#FFFFFF';
export const foundationalTokens = {
  blue,
  blueGrey,
  green,
  orange,
  red,
  white
};