import styled from 'styled-components/macro';

export const LoginMenuMobile = styled.div`
  font: var(--ui-large-bold);
  width: 100%;
  height: 64px;
  padding: 0 20px;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
`;

export const UnderlinedLoginLink = styled.a`
  line-height: 64px;
  color: var(--secondary-900);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
`;
