import styled from 'styled-components/macro';
import { PaymentMethodDetailConfig } from './PaymentMethodBadgeConfig';

export const PaymentMethodBadge = styled.div<{
  $isSelectedOption: boolean;
  $paymentMethodDetailConfig: PaymentMethodDetailConfig;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 268px;
  height: 168px;
  background-color: ${({ $paymentMethodDetailConfig }) =>
    $paymentMethodDetailConfig?.backgroundColor};
  border: 2px solid #191b64;
  border-radius: 7px;
  border-color: ${({ $isSelectedOption, $paymentMethodDetailConfig }) =>
    $isSelectedOption
      ? '#009500'
      : $paymentMethodDetailConfig?.backgroundColor};
  padding: 16px;
  font: var(--ui-small-regular);
  color: ${({ $paymentMethodDetailConfig }) =>
    $paymentMethodDetailConfig?.textColor};
  cursor: pointer;
`;

export const PaymentMethodBadgeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaymentMethodBadgePrimaryRow = styled(PaymentMethodBadgeRow)`
  padding: 4px 0;
`;

export const PaymentMethodDetail = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;
