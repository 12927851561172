import * as S from './ShoppingCartCounter.styles';

export type ShoppingCartCounterProps = {
  count?: number;
};

/* ShoppingCartCounter */
export default function ShoppingCartCounter({
  count = 0,
}: ShoppingCartCounterProps) {
  return (
    <S.ShoppingCartCounter to="/cartSummary">
      <S.ShoppingCartIcon />
      {/*<S.ItemCount>{count}</S.ItemCount>*/}
    </S.ShoppingCartCounter>
  );
}
/* */
