import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/icons/alert_error.svg';
import * as S from './Card.styles';

export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * When this value is truthy (non-empty string), the card will display as
   * being in an error state and display the error message.
   */
  errorMessage?: string;
}

/* Card */
export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, errorMessage, ...restProps }, ref?) => {
    const hasError = !!errorMessage;
    return (
      <S.CardContainer {...restProps}>
        <S.Card $hasError={hasError} ref={ref}>
          {children}
        </S.Card>
        {hasError ? <CardError message={errorMessage} /> : null}
      </S.CardContainer>
    );
  }
);
/* */

/* CardError */
function CardError({ message }: { message: string }) {
  return (
    <S.CardError>
      <ErrorIcon />
      <S.CardErrorMessage>{message}</S.CardErrorMessage>
    </S.CardError>
  );
}
/* */

export const CardBody = S.CardBody;
export const CardTitle = S.CardTitle;
export const CardFooter = S.CardFooter;
export const CardHeader = S.CardHeader;
