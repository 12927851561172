"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSummaryData = exports.setSummaryData = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    summaryData: null,
};
var checkoutSlice = (0, toolkit_1.createSlice)({
    name: 'checkout',
    initialState: initialState,
    reducers: {
        setSummaryData: function (state, action) {
            state.summaryData = action.payload;
        },
    },
});
exports.setSummaryData = checkoutSlice.actions.setSummaryData;
var getSummaryData = function (state) { return state.checkout.summaryData; };
exports.getSummaryData = getSummaryData;
exports.default = checkoutSlice.reducer;
