import { useEffect, useState } from 'react';
const useInputState = container => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };
    const handleBlur = () => {
      setIsFocused(false);
    };
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    const input = container.current;
    if (input) {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
      input.addEventListener('mouseenter', handleMouseEnter);
      input.addEventListener('mouseleave', handleMouseLeave);
    }
    return () => {
      if (input) {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
        input.removeEventListener('mouseenter', handleMouseEnter);
        input.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [container]);
  return {
    isHovered,
    isFocused
  };
};
export { useInputState };