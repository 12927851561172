"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var hooks_1 = require("../../lib/hooks");
var breakpoints_1 = require("../../lib/media-queries/breakpoints");
var S = require("./NavigationPanel.styles");
var NavigationPanel = function (_a) {
    var items = _a.items;
    var _b = (0, react_1.useState)(''), selectedItem = _b[0], setSelectedItem = _b[1];
    var _c = (0, react_1.useState)(false), isMobileModalOpen = _c[0], setIsMobileModalOpen = _c[1];
    var isTabletLandscapeUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletLandscapeUp);
    (0, react_1.useEffect)(function () {
        // If it is the first section we want to scroll back to the top not where the first section meets it's breakpoint.
        if (selectedItem === items[0].id) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else {
            var element = document.getElementById(selectedItem);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, [selectedItem, items]);
    var renderItems = function () {
        return items.map(function (item, i) {
            var isSelected = item.id === selectedItem || (!selectedItem && i === 0);
            return ((0, jsx_runtime_1.jsxs)(S.NavigationItemContainer, __assign({ onClick: function () { return setSelectedItem(item.id); }, "$isSelected": isSelected, "$hasBorder": isTabletLandscapeUp }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "headline", size: "small", weight: isSelected ? 'bold' : 'regular' }, { children: item.title })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "medium" }, { children: item.subtitle }))] })));
        });
    };
    if (isTabletLandscapeUp) {
        return ((0, jsx_runtime_1.jsx)(S.NavigationPanelContainer, { children: (0, jsx_runtime_1.jsx)(S.NavigationPanelInnerContainer, { children: renderItems() }) }));
    }
    if (isMobileModalOpen) {
        return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Modal, __assign({ open: true, onOpenChange: function () { return setIsMobileModalOpen(false); }, size: "large", title: "Registration options menu", hasPadding: false, forceBottom: true }, { children: renderItems() })));
    }
    return ((0, jsx_runtime_1.jsx)(S.BottomDrawer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ id: "open-navation-icons-button", onClick: function () { return setIsMobileModalOpen(true); }, width: "100%" }, { children: "View menu" })) }));
};
exports.NavigationPanel = NavigationPanel;
