import styled from 'styled-components';
import { ReactComponent as ThreeDots } from 'assets/animations/three_dots.svg';
export const LoaderOverlay = styled.div.withConfig({
  componentId: "sc-7qbagi-0"
})(({
  theme
}) => {
  const {
    foundational: {
      white
    }
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'fixed',
    backgroundColor: white,
    opacity: 0.9,
    // Hardcoded until we add zIndexes to theme
    zIndex: 1200,
    overflow: 'hidden'
  };
});
export const LoaderContainer = styled.div.withConfig({
  componentId: "sc-7qbagi-1"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.xxs
  };
});
export const StyledLoader = styled(ThreeDots).withConfig({
  componentId: "sc-7qbagi-2"
})(({
  theme,
  $hasMarginBottom
}) => {
  const {
    semantic,
    foundational: {
      green
    },
    spacing
  } = theme;
  return {
    height: '16px',
    marginBottom: $hasMarginBottom ? spacing.xxs : 0,
    width: 'fit-content',
    fill: !!semantic ? semantic.secondary[600] : green[600]
  };
});