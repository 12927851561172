import { CardBody, CardHeader, CardTitle } from 'components/Card/Card';
import { StyledHTML } from 'components/StyledHTML/StyledHTML';
import * as S from './AboutCard.styles';

export type AboutCardProps = {
  content?: string;
};

export function AboutCard({ content }: AboutCardProps) {
  return (
    <S.AboutCard data-testid="about-card">
      <CardHeader>
        <CardTitle>About</CardTitle>
      </CardHeader>
      <CardBody>
        <StyledHTML content={content} />
      </CardBody>
    </S.AboutCard>
  );
}
