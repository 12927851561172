import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['CartSummary', 'Registration', 'User'],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseURL(),
    timeout: 20000,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = extractAccessToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default baseApi;
