import { FormEvent, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  CaretDownIcon,
  CaretUpIcon,
  Checkbox,
  Modal,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  formatAsCurrency,
} from '@la/ds-ui-components';
import { Button } from '@la/ds-ui-components';
import { RadioButton } from 'components/RadioButton/RadioButton';
import {
  PaymentPlanSummary,
  PaymentPlanSummaryInstallment,
  PaymentOptionsData,
  PaymentOptionsItem,
} from '../Checkout.types';
import { DiscountBadge } from '../DiscountBadge/DiscountBadge';
import { getPaymentPlanSubTotal } from '../utils/paymentPlan';
import * as S from './PaymentOptionsModal.styles';

export const PAY_IN_FULL_DETAIL = 'Pay in full';
export const PAY_IN_FULL_VALUE = 'FULL';

const PAYMENT_OPTIONS_FORM_ID = 'paymentOptions';

export type PaymentOptionsFormFields = {
  [key: string]: {
    paymentOption: string;
    payLater?: boolean;
  };
};

export type PaymentOptionsModalProps = {
  closeModal: () => void;
  onSubmit: (selections: PaymentOptionsFormFields) => void;
  open: boolean;
  paymentOptionsData: PaymentOptionsData;
};

/* PaymentOptionsModal */
export default function PaymentOptionsModal({
  closeModal,
  onSubmit,
  open,
  paymentOptionsData,
}: PaymentOptionsModalProps) {
  const transformPaymentOptionSelections = (
    formData: FormData
  ): PaymentOptionsFormFields => {
    const fields: PaymentOptionsFormFields = {};

    paymentOptionsData.forEach(({ itemId }) => {
      const paymentOption = formData.get(itemId);

      if (paymentOption) {
        if (paymentOption === 'FULL') {
          fields[itemId] = { paymentOption };
        } else {
          const payLater = formData.get(getItemPayLaterId(itemId));
          fields[itemId] = {
            paymentOption: paymentOption.toString(),
            payLater: payLater?.toString() === 'true' ? true : false,
          };
        }
      }
    });

    return fields;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    onSubmit(transformPaymentOptionSelections(formData));
  };

  return (
    <Modal
      onOpenChange={closeModal}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton form={PAYMENT_OPTIONS_FORM_ID} type="submit">
          Save
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalTertiaryActionButton onClick={closeModal}>
          Cancel
        </ModalTertiaryActionButton>
      }
      size="medium"
      title="Payment options"
    >
      <S.PaymentOptionsModalItems
        id={PAYMENT_OPTIONS_FORM_ID}
        onSubmit={handleSubmit}
      >
        {paymentOptionsData.map((item, index) => (
          <PaymentOptionsModalItem key={index} {...item} />
        ))}
      </S.PaymentOptionsModalItems>
    </Modal>
  );
}
/* */

function PaymentOptionsModalItem({
  itemId,
  payInFull,
  payLater,
  paymentOption,
  paymentOptions,
  subTotal,
  tournamentName,
}: PaymentOptionsItem) {
  const [selectedOption, setSelectedOption] = useState<string>(paymentOption);

  const fullAmount = Number(subTotal);
  const discountAmount = useMemo(() => {
    const selectedPaymentPlan =
      paymentOption !== PAY_IN_FULL_VALUE
        ? paymentOptions.find(
            (option) => option.ngPaymentPlanId === selectedOption
          )
        : null;

    if (selectedPaymentPlan) {
      return getPaymentPlanSubTotal(selectedPaymentPlan);
    }

    return fullAmount;
  }, [fullAmount, paymentOption, paymentOptions, selectedOption]);

  return (
    <S.PaymentOptionsModalItem>
      <S.PaymentOptionsModalItemHeader>
        <Typography variant="headline" size="xs">
          {tournamentName}
        </Typography>
        <Typography variant="headline" size="xs">
          {discountAmount < fullAmount ? (
            <S.DiscountAmount>
              {formatAsCurrency(discountAmount)}
            </S.DiscountAmount>
          ) : null}
          <S.FullAmount $hasDiscount={discountAmount < fullAmount}>
            {formatAsCurrency(fullAmount)}
          </S.FullAmount>
        </Typography>
      </S.PaymentOptionsModalItemHeader>
      <PaymentOptionsModalItemPaymentOptions
        itemId={itemId}
        payInFull={payInFull}
        payLater={payLater}
        paymentPlans={paymentOptions}
        paymentTerm={selectedOption}
        onPaymentPlanSelect={setSelectedOption}
      />
    </S.PaymentOptionsModalItem>
  );
}

function PaymentOptionsModalItemPaymentOptions({
  itemId,
  payInFull,
  payLater,
  paymentPlans,
  paymentTerm,
  onPaymentPlanSelect,
}: {
  itemId: string;
  payInFull?: boolean;
  payLater?: boolean;
  paymentPlans: PaymentPlanSummary[];
  paymentTerm: string;
  onPaymentPlanSelect: (paymentTerm: string) => void;
}) {
  const getCheckedState = (paymentPlan: PaymentPlanSummary): boolean => {
    return paymentTerm === paymentPlan.ngPaymentPlanId;
  };

  const handlePlanOptionSelect = (paymentPlan: PaymentPlanSummary): void => {
    onPaymentPlanSelect(paymentPlan.ngPaymentPlanId);
  };

  return (
    <S.PaymentOptionsModalItemPaymentOptions>
      {payInFull ? (
        <S.PaymentOptionsModalRow $showDivider={!!paymentPlans.length}>
          <S.PaymentOptionModalItemRadioButton>
            <RadioButton
              iconColor="var(--blue-grey-600)"
              checked={paymentTerm === PAY_IN_FULL_VALUE}
              handleChange={() => onPaymentPlanSelect(PAY_IN_FULL_VALUE)}
              name={itemId}
              label={PAY_IN_FULL_DETAIL}
              value={PAY_IN_FULL_VALUE}
            />
          </S.PaymentOptionModalItemRadioButton>
        </S.PaymentOptionsModalRow>
      ) : null}
      {paymentPlans.map((paymentPlan, index) => (
        <S.PaymentOptionsModalRow
          $showDivider={index !== paymentPlans.length - 1}
          key={`${itemId}-payment-option-${index}`}
        >
          <PaymentTermModalItemRadioButton
            checked={getCheckedState(paymentPlan)}
            handleChange={() => handlePlanOptionSelect(paymentPlan)}
            itemId={itemId}
            label={paymentPlan.name}
            name={itemId}
            payLater={payLater && paymentPlan.ngPaymentPlanId === paymentTerm}
            paymentPlan={paymentPlan}
          />
        </S.PaymentOptionsModalRow>
      ))}
    </S.PaymentOptionsModalItemPaymentOptions>
  );
}

type PaymentOptionsModalItemRadioButtonProps = {
  checked?: boolean;
  handleChange?: () => void;
  itemId: string;
  label: string;
  name: string;
  payLater?: boolean;
  paymentPlan: PaymentPlanSummary;
};

function PaymentTermModalItemRadioButton({
  checked,
  handleChange,
  itemId,
  label,
  name,
  payLater,
  paymentPlan,
}: PaymentOptionsModalItemRadioButtonProps) {
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(!!checked);

  const detailsIcon = isShowingDetails ? (
    <CaretUpIcon variant="filled" />
  ) : (
    <CaretDownIcon variant="filled" />
  );
  const detailsText = `${isShowingDetails ? 'Hide' : 'View'} details`;

  const handleSelect = (): void => {
    if (handleChange) {
      handleChange();
    }

    if (!isShowingDetails) {
      setIsShowingDetails(true);
    }
  };

  return (
    <>
      <S.PaymentOptionModalItemRadioButton>
        <S.PaymentOptionModalItemHeader>
          <S.PaymentOptionModalItemText>
            <RadioButton
              iconColor="var(--blue-grey-600)"
              checked={checked}
              handleChange={handleSelect}
              name={name}
              label={label}
              value={paymentPlan.ngPaymentPlanId}
            />
            {paymentPlan.priceAdjustment ? (
              <DiscountBadge priceAdjustment={paymentPlan.priceAdjustment} />
            ) : null}
          </S.PaymentOptionModalItemText>
          <PaymentOptionDetailsSummary
            autopayRequired={paymentPlan.autopayRequired}
            numberOfPayments={paymentPlan.installments.length}
          />
        </S.PaymentOptionModalItemHeader>
        <S.PaymentOptionModalItemDetailsButton>
          <Button
            hasPadding={false}
            onClick={() => setIsShowingDetails(!isShowingDetails)}
            rightIcon={detailsIcon}
            size="small"
            variant="text"
          >
            {detailsText}
          </Button>
        </S.PaymentOptionModalItemDetailsButton>
      </S.PaymentOptionModalItemRadioButton>
      {isShowingDetails ? (
        <PaymentOptionDetailsSchedule
          dueCheckout={paymentPlan.dueCheckout}
          isSelected={checked}
          itemId={itemId}
          installments={paymentPlan.installments}
          payLater={payLater}
        />
      ) : null}
    </>
  );
}

function PaymentOptionDetailsSummary({
  autopayRequired = false,
  numberOfPayments,
}: {
  autopayRequired?: boolean;
  numberOfPayments: number;
}) {
  const paymentsText =
    numberOfPayments === 1 ? `1 Payment` : `${numberOfPayments} Payments`;

  return (
    <Typography size="xs" variant="ui">
      <S.PaymentOptionDetailsSummary>
        {paymentsText}
        {autopayRequired ? ' / Autopay' : null}
      </S.PaymentOptionDetailsSummary>
    </Typography>
  );
}

type PaymentOptionDetailsScheduleProps = Pick<
  PaymentPlanSummary,
  'dueCheckout' | 'installments'
> & {
  isSelected?: boolean;
  itemId: string;
  payLater?: boolean;
};

function PaymentOptionDetailsSchedule({
  dueCheckout,
  isSelected,
  itemId,
  installments,
  payLater,
}: PaymentOptionDetailsScheduleProps) {
  const [currentPayLaterStatus, setCurrentPayLaterStatus] =
    useState<boolean>(!!payLater);

  useEffect(() => {
    if (!isSelected) {
      setCurrentPayLaterStatus(false);
    }
  }, [isSelected]);

  return (
    <S.PaymentOptionDetailsSchedule>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Amount due</TableHeader>
            <TableHeader>Date due</TableHeader>
            <TableHeader>Pay later</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {installments.map((item, index) => {
            const dueDate = DateTime.fromISO(item.installmentDate);
            const dueDateIsToday = DateTime.now()
              .startOf('day')
              .equals(dueDate.startOf('day'));

            return (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <PaymentOptionDetailsAmount
                    installmentTotal={item.installmentTotal}
                    percentage={item.percentage}
                  />
                </TableCell>
                <TableCell>
                  {dueDateIsToday || (index === 0 && !currentPayLaterStatus)
                    ? 'Today'
                    : dueDate.toFormat('MMM dd, yyyy')}
                </TableCell>
                <TableCell>
                  {index === 0 && !dueDateIsToday && !dueCheckout ? (
                    <S.PayLaterCheckbox>
                      <Checkbox
                        ariaLabel="Pay first payment later"
                        checked={currentPayLaterStatus}
                        disabled={!isSelected}
                        name={getItemPayLaterId(itemId)}
                        onCheckedChange={setCurrentPayLaterStatus}
                        id="pay-later"
                        size="large"
                        value={currentPayLaterStatus.toString()}
                      />
                    </S.PayLaterCheckbox>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </S.PaymentOptionDetailsSchedule>
  );
}

function PaymentOptionDetailsAmount({
  installmentTotal,
  percentage,
}: Pick<PaymentPlanSummaryInstallment, 'installmentTotal' | 'percentage'>) {
  const formattedInstallmentTotal = formatAsCurrency(Number(installmentTotal));
  const amount = percentage
    ? `${percentage}% (${formattedInstallmentTotal})`
    : formattedInstallmentTotal;

  return <>{amount}</>;
}

function getItemPayLaterId(itemId: string): string {
  return `${itemId}-pay-later`;
}
