import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import {
  Button,
  Checkbox,
  ExclamationOctagonIcon,
  InputErrorMessage,
  Select,
  SelectOption,
  Snackbar,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { SelectPopover } from 'components/SelectPopover/SelectPopover';
import TextField from 'components/TextField/TextField';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useGetLocationsQuery,
  useGetProgramQuery,
} from 'redux/services/activity';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { useGetStaffTeamsQuery } from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import { getUserId } from 'redux/userSlice';
import { ActivityFormWrapperScreens, useActivityForm } from './ActivityContext';
import { ExternalLocationSelect } from './ExternalLocationSelect/ExternalLocationSelect';
import { ExternalTeamSelect } from './ExternalTeamSelect/ExternalTeamSelect';
import { TeamPrimaryStaffSelect } from './TeamPrimaryStaffSelect/TeamPrimaryStaffSelect';
import { getAllActivities } from './apis/getAllActivities';
import { paginatedGet } from './apis/paginatedGet';
import {
  Activity,
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityState,
  ActivityType,
  AllDayActivityType,
  Program,
} from './openapi-client/models';
import {
  ActivityFormData,
  gameTypeOptions,
  creativeActivitySchema,
  durationBetweenTwoDates,
  formHasBeenChanged,
  formatDataForExternalTeams,
  formatDataForHomeAwayTeamSelect,
  frequencyOptions,
  getSubLocationsByLocationId,
  formatDataForActivityTypeSelect,
  getHomeAwayTeamsId,
  TeamWithProps,
  isTeamTBD,
  REQUEST_DELAY,
} from './utils/ActivityForm.utils';
import {
  UpdateActivityData,
  createActivityHandler,
  updateActivityHandler,
} from './utils/ActivityFormSubmit.utils';
import { formatDataForSelectOption } from './utils/select';
import { convertDateTimeToISO, formatISODateTime } from './utils/timeUtils';
import * as S from './ActivityForm.styles';

export type ActivityFormProps = {
  editActivity?: Activity;
  formId: string;
  teamId?: string;
  subProgramId?: string;
  originRoute: string;
  handleSetActivityFormTitle: (arg: string) => void;
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
};

type ExternalTeamType = 'home' | 'away' | undefined;

/* ActivityForm */
export function ActivityForm({
  editActivity,
  formId,
  originRoute,
  subProgramId,
  teamId,
  handleSetActivityFormTitle,
  handleSetCurrentScreen,
}: ActivityFormProps) {
  const {
    editRecurringActivityType,
    handleSetActivities,
    handleSetSubmittedForm,
    handleSetUpdateActivityData,
  } = useActivityForm();
  const {
    control,
    formState: { dirtyFields, errors, isSubmitted, isSubmitting },
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    trigger,
    watch,
  } = useForm<ActivityFormData>({
    defaultValues: {
      activityType: editActivity?.type,
      allDay: editActivity?.allDay,
      arrival: editActivity?.arrival?.time,
      endDate: editActivity?.end?.date,
      endTime: editActivity?.end?.time,
      frequency: 'does_not_repeat',
      hasSubLocations: editActivity?.subLocationId !== null,
      location: editActivity?.locationId?.toString(),
      notes: editActivity?.notes,
      startDate: editActivity?.start?.date,
      startTime: editActivity?.start?.time,
      subLocation: editActivity?.subLocationId?.toString(),
      subProgram: editActivity
        ? editActivity?.teams[0].programId.toString()
        : subProgramId,
      team: editActivity
        ? getHomeAwayTeamsId(editActivity.teams).homeTeam.toString()
        : teamId?.toString(),
      title: editActivity?.title,
      gameType: editActivity?.type,
      awayTeam: editActivity
        ? getHomeAwayTeamsId(editActivity.teams).awayTeam?.toString()
        : undefined,
    },
    mode: 'onSubmit',
    resolver: yupResolver(creativeActivitySchema),
  });

  const [internalTeamError, setInternalTeamError] = useState(false);
  const [internalTeamErrorMessage, setInternalTeamErrorMessage] = useState('');

  useEffect(() => {
    if (editActivity) {
      reset({
        activityType: editActivity.type,
        allDay: editActivity.allDay,
        arrival: editActivity.arrival?.time,
        endDate: editActivity.end?.date,
        endTime: editActivity.end?.time,
        frequency: 'does_not_repeat',
        hasSubLocations: editActivity.subLocationId !== null,
        location: editActivity.locationId?.toString(),
        notes: editActivity.notes,
        startDate: editActivity.start?.date,
        startTime: editActivity.start?.time,
        subLocation: editActivity.subLocationId?.toString(),
        subProgram: editActivity
          ? editActivity.teams[0].programId.toString()
          : subProgramId,
        team: editActivity
          ? getHomeAwayTeamsId(editActivity.teams).homeTeam.toString()
          : teamId?.toString(),
        title: editActivity.title,
        gameType: editActivity.type,
        awayTeam: editActivity
          ? getHomeAwayTeamsId(editActivity.teams).awayTeam?.toString()
          : undefined,
      });
    }
  }, [editActivity, reset, subProgramId, teamId]);

  const [showNoChangeWarning, setShowNoChangeWarning] =
    useState<boolean>(false);

  const [
    showEditRecurringActivityWarning,
    setShowEditRecurringActivityWarning,
  ] = useState<string>();
  const isEditForm = !!editActivity;

  useEffect(() => {
    handleSetActivityFormTitle(
      isEditForm ? 'Edit Activity' : 'Create Activity'
    );
  });
  const isPhoneOnly = useMediaQuery(breakpointQueries.phoneOnly);
  const cancelText = isPhoneOnly ? 'Cancel' : 'Cancel Activity';

  const { siteId } = useAppSelector(getSiteIdentityData);
  const { data: program } = useGetProgramQuery(
    {
      siteId: siteId,
      programId: subProgramId,
    },
    { skip: !subProgramId }
  );

  const programId = getProgramId(program);

  const { data: masterProgram } = useGetProgramQuery(
    {
      siteId: siteId,
      programId: programId,
    },
    { skip: !program }
  );

  const notificationsEnabled = masterProgram?.preferences?.notificationsEnabled;

  const userId = useAppSelector(getUserId);

  const { data: teams = [] } = useGetStaffTeamsQuery(
    { userId },
    { skip: !userId }
  );
  const { data: siteSettings } = useGetSiteSettingsQuery(Number(siteId));

  /**
   * If editing a recurring activity, we need to show the
   * warning snackbar once the form is rendered.
   * eslint complains about missing dependency 'showEditRecurringActivityWarning'
   * but adding it causes an infinite loop
   */
  const getActivities = useCallback(async () => {
    return await paginatedGet<Activity>({
      apiRequest: getAllActivities,
      isIdPagination: true,
      requestData: {
        siteId: Number(siteId),
        programId: Number(program?.masterProgramId),
        pageSize: 50,
      },
    });
  }, [program, siteId]);

  useEffect(() => {
    if (program && siteId && !!editRecurringActivityType && editActivity) {
      getActivities().then((activities: Activity[]) => {
        handleSetActivities(activities);
        let message = '';
        if (editRecurringActivityType === 'one') {
          message = `You're editing one instance in a set of recurring activities. The other activities in this set won't be affected.`;
        } else if (
          editRecurringActivityType === 'all' &&
          editActivity?.seqToken
        ) {
          const recurringActivities = activities.filter(
            (activity: Activity) => {
              return (
                activity.seqToken === editActivity.seqToken &&
                activity.id >= editActivity.id
              );
            }
          );

          const numberOfRecurringActivities = recurringActivities.length;
          const activityPluralSingular =
            numberOfRecurringActivities === 1 ? 'activity' : 'activities';
          message = `You're editing ${numberOfRecurringActivities} ${activityPluralSingular} in a set of recurring activities.`;
        }
        setShowEditRecurringActivityWarning(message);
      });
    }
    // adding handleSetActivities creates an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editActivity, editRecurringActivityType, getActivities, program, siteId]);

  /* Selected fields */
  const frequency = watch('frequency');
  const activityType = watch('activityType');
  const [selectedLocation, setSelectedLocation] = useState<
    SelectOption | undefined
  >();
  const [isLocationSelectOpen, setLocationSelectOpen] =
    useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedRecurringEndDate, setSelectedRecurringEndDate] = useState('');
  // const [selectedSubProgramId, setSelectedSubProgramId] = useState<string>('');
  const selectedSubProgramId = program?.id?.toString() || '';
  const [allDayActivity, setAllDayActivity] = useState<boolean>(
    !!editActivity?.allDay
  );
  const isActivityTypeGame = isGameActivity(activityType);

  /** Team Select Options */
  const [selectedTeam, setSelectedTeam] = useState<SelectOption | undefined>();
  const [isTeamSelectOpen, setIsTeamSelectOpen] = useState(false);
  const [showHomeTeamExternalTeamLabel, setShowHomeTeamExternalTeamLabel] =
    useState<boolean>(isActivityTypeGame);
  const [externalTeamNameLabel, setExternalTeamNameLabel] =
    useState<ExternalTeamType>();
  const [selectedExternalTeam, setSelectedExternalTeam] = useState<
    SelectOption | undefined
  >();
  const [
    isExternalHomeTeamNameSelectOpen,
    setIsExternalHomeTeamNameSelectOpen,
  ] = useState(false);
  const [selectedAwayTeam, setSelectedAwayTeam] = useState<
    SelectOption | undefined
  >();
  const [isAwayTeamSelectOpen, setIsAwayTeamSelectOpen] = useState(false);
  const [showAwayTeamExternalTeamLabel, setShowAwayTeamExternalTeamLabel] =
    useState<boolean>(isActivityTypeGame);
  const [
    isExternalAwayTeamNameSelectOpen,
    setIsExternalAwayTeamNameSelectOpen,
  ] = useState(false);
  const externalTeamsEnabled = siteSettings?.externalTeamsEnabled;
  const showHomeExternalTeamOption =
    externalTeamsEnabled &&
    showHomeTeamExternalTeamLabel &&
    externalTeamNameLabel !== 'away';
  const showAwayExternalTeamOption =
    externalTeamsEnabled &&
    showAwayTeamExternalTeamLabel &&
    externalTeamNameLabel !== 'home';
  /** */

  /* Selectors */
  const activityTypeOptions = useMemo(
    () => formatDataForActivityTypeSelect(editActivity?.type),
    [editActivity]
  );

  const homeTeamOptions = useMemo(() => {
    const awayTeam =
      selectedAwayTeam?.value === 'external'
        ? undefined
        : selectedAwayTeam?.value;

    return formatDataForHomeAwayTeamSelect({
      teams,
      isGame: isActivityTypeGame,
      homeAway: 'home',
      selectedSubProgramId,
      selectedHomeAwayTeamId: awayTeam,
    });
  }, [
    isActivityTypeGame,
    selectedAwayTeam?.value,
    selectedSubProgramId,
    teams,
  ]);

  const awayTeamOptions = useMemo(() => {
    const homeTeam =
      selectedTeam?.value === 'external' ? undefined : selectedTeam?.value;

    return formatDataForHomeAwayTeamSelect({
      teams,
      isGame: isActivityTypeGame,
      homeAway: 'away',
      selectedSubProgramId,
      selectedHomeAwayTeamId: homeTeam,
    });
  }, [isActivityTypeGame, selectedSubProgramId, selectedTeam?.value, teams]);

  const externalTeamOptions = useMemo(() => {
    return formatDataForExternalTeams({
      teams,
      selectedSubProgramId,
    });
  }, [selectedSubProgramId, teams]);

  /** Reset dependent fields when Activity Type is changed */
  useEffect(() => {
    if (isActivityTypeGame) {
      let gameType: ActivityType | '' = '';
      if (editActivity?.type && isGameActivity(editActivity.type)) {
        gameType = editActivity.type;
      }
      resetField('gameType', {
        defaultValue: gameType,
        keepError: true,
      });
    }
  }, [editActivity, isActivityTypeGame, resetField]);

  useEffect(() => {
    setShowHomeTeamExternalTeamLabel(isActivityTypeGame);
    setShowAwayTeamExternalTeamLabel(isActivityTypeGame);

    if (!isActivityTypeGame) {
      //reset dependent fields
      if (selectedTeam?.value === 'external') {
        setSelectedTeam(undefined);
        resetField('team', { defaultValue: '', keepError: true });
        resetField('externalTeam', { defaultValue: '', keepError: true });
      } else if (selectedAwayTeam?.value === 'external') {
        resetField('awayTeam', { defaultValue: '', keepError: true });
        setSelectedAwayTeam(undefined);
      }

      if (
        selectedTeam?.value === 'external' ||
        selectedAwayTeam?.value === 'external'
      ) {
        setSelectedExternalTeam(undefined);
        setExternalTeamNameLabel(undefined);
      }
    }
  }, [
    isActivityTypeGame,
    resetField,
    selectedAwayTeam?.value,
    selectedTeam?.value,
  ]);

  /* Select Options */
  // const subProgramOptions = formatDataForSelectOption([], 'name', 'id');
  const { data: allLocations } = useGetLocationsQuery(
    {
      siteId: siteId ?? '',
      programId: programId,
    },
    { skip: !program || !siteId }
  );

  const locations =
    allLocations?.filter((location) => location.associated) ?? [];
  const locationsOptions = formatDataForSelectOption(locations, 'name', 'id');
  const subLocationOptions = getSubLocationsByLocationId(
    locations,
    selectedLocation?.value ?? ''
  );

  const getLocationName = useCallback(
    (locationId: string) =>
      allLocations?.find(({ id }) => id === Number(locationId))?.name ?? '',
    [allLocations]
  );

  useEffect(() => {
    setValue('hasSubLocations', subLocationOptions.length > 0);
  }, [setValue, subLocationOptions]);

  /**
   * If there's activity data, populate the form helpers with that data.
   */
  useEffect(
    () => {
      if (isEditForm) {
        const { end, locationId, start } = editActivity;
        if (isActivityTypeGame) {
          const homeTeam = editActivity.teams.find(
            (team) => team.homeAway === 'home'
          );
          if (homeTeam) {
            const homeTeamName = getTeamNameById(teams, homeTeam.teamId);
            setSelectedTeam({
              label: homeTeamName,
              value: homeTeam.teamId.toString(),
            });
          }
        } else {
          const selectedTeam = teams.find(({ id }) => id === Number(teamId));
          if (selectedTeam && teamId) {
            setSelectedTeam({ label: selectedTeam.name, value: teamId });
            resetField('team', {
              defaultValue: selectedTeam.id.toString(),
              keepError: true,
            });
          }
        }

        const awayTeam = editActivity.teams.find(
          (team) => team.homeAway === 'away'
        );
        if (awayTeam) {
          const awayTeamName = getTeamNameById(teams, awayTeam.teamId);
          setSelectedAwayTeam({
            label: awayTeamName,
            value: awayTeam.teamId.toString(),
          });
        }
        const currentLocationId = locationId ? locationId.toString() : '';
        if (currentLocationId && !dirtyFields.location) {
          const locationName = getLocationName(currentLocationId);
          setSelectedLocation({
            label: locationName,
            value: currentLocationId,
          });
        }
        setSelectedStartDate(start?.date ?? '');
        setSelectedEndDate(end?.date ?? '');
        setAllDayActivity(!!editActivity.allDay);
      } else if (selectedTeam?.value !== 'external') {
        const teamSelected = teams.find(({ id }) => id === Number(teamId));
        if (teamSelected && teamId) {
          setSelectedTeam({ label: teamSelected.name, value: teamId });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dirtyFields.location,
      editActivity,
      getLocationName,
      isActivityTypeGame,
      isEditForm,
      // selectedTeam,
      teamId,
      teams,
    ]
  );

  function handleAllDayChange(allDay: boolean) {
    setAllDayActivity(allDay);

    resetField('startTime', { keepError: false });
    resetField('endTime', { keepError: false });
    resetField('frequency', { keepError: false });
    setSelectedRecurringEndDate('');

    if (allDay && selectedEndDate === '') {
      setSelectedEndDate(selectedStartDate);
      setValue('endDate', selectedStartDate);
    } else {
      setSelectedEndDate('');
      resetField('endDate', { keepError: false });
    }
  }

  function handleStartDateSelected(date: string) {
    setSelectedStartDate(date);

    if (selectedEndDate === '' && allDayActivity) {
      setSelectedEndDate(date);
      setValue('endDate', date);
    }
    //reset error message if exists
    if (isSubmitted) {
      trigger('endDate').then();
    }
  }

  function handleAllDayEndDateSelected(date: string) {
    setSelectedEndDate(date);

    //reset error message if exists
    if (isSubmitted) {
      trigger('startDate').then();
    }
  }

  function handleRecurringEndDateSelected(date: string) {
    setSelectedRecurringEndDate(date);

    // reset error message if exists
    if (isSubmitted) {
      trigger('startDate').then();
    }
  }

  function repeatsForDuration() {
    if (!selectedStartDate && !selectedRecurringEndDate) {
      return;
    }

    let duration = durationBetweenTwoDates(
      selectedStartDate,
      selectedRecurringEndDate,
      frequency
    );

    let durationType;
    if (frequency === ActivityRecurrenceFrequencyUnitEnum.Day) {
      durationType = duration === 1 ? 'day' : 'days';
    } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Week) {
      durationType = duration === 1 ? 'week' : 'weeks';
    } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Month) {
      duration = duration ? Math.floor(duration) : undefined;
      durationType = duration === 1 ? 'month' : 'months';
    }

    if (
      duration !== undefined &&
      duration > 0 &&
      durationType &&
      !errors.endDate
    ) {
      return `Repeats for ${duration} ${durationType}`;
    }
  }
  /* End Change Event Handlers */

  /* Submit form */
  function onSubmit(data: ActivityFormData) {
    if (isSubmitting) {
      return;
    }

    if (isActivityTypeGame && data.gameType) {
      data.activityType = data.gameType;
    }

    //handle external team ID
    if (selectedExternalTeam) {
      if (data.team === 'external') {
        data.team = selectedExternalTeam?.value;
      } else if (data.awayTeam === 'external') {
        data.awayTeam = selectedExternalTeam?.value;
      }
    }

    if (!subProgramId) {
      const teams: TeamWithProps[] = [];
      let team = teams.find((team) => team.id === Number(data.team));
      if (team) {
        subProgramId = team.programId.toString();
        data.subProgram = subProgramId;
      }
    } else {
      data.subProgram = subProgramId;
    }

    //check either Home or Away is internal team
    if (
      isActivityTypeGame &&
      data.team !== teamId &&
      data.awayTeam !== teamId
    ) {
      const teamName =
        teams.find((team) => team.id === Number(teamId))?.name ??
        'internal team';
      setInternalTeamError(true);
      setInternalTeamErrorMessage(`Home or away team must be ${teamName}.`);
      return;
    }

    if (isEditForm) {
      // Edit case
      submitEditActivity(data);
    } else {
      // Create case
      createActivity(data, siteId, programId);
    }
  }

  async function createActivity(
    data: ActivityFormData,
    siteId: string | null,
    programId?: string
  ) {
    handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);

    await createActivityHandler({
      data,
      siteId: Number(siteId),
      programId: Number(programId),
    })
      .then((data) => {
        const activity = data.activities ?? [data];
        setTimeout(() => {
          handleSetSubmittedForm({ activity, submitType: 'created' });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        }, REQUEST_DELAY);
      })
      .catch((e) => {
        const errorMessage = typeof e === 'string' ? e : e.message;
        handleSetSubmittedForm({ errorMessage });
        handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
      });
  }

  function getRecurringSetDirtyFieldValues(
    data: ActivityFormData
  ): ActivityFormData {
    // Sub-location field is reset once location is changed,
    // which makes it seem 'dirty' even if same value is chosen
    const subLocationEdit =
      editActivity?.subLocationId?.toString() !== data.subLocation;

    const dirtyFieldValues: ActivityFormData = {
      arrival: dirtyFields.arrival ? data.arrival : undefined,
      endTime: dirtyFields.endTime ? data.endTime : undefined,
      hasSubLocations: subLocationEdit,
      location: dirtyFields.location ? data.location : undefined,
      notes: dirtyFields.notes ? data.notes : undefined,
      startTime: dirtyFields.startTime ? data.startTime : undefined,
      subLocation:
        data.subLocation && subLocationEdit ? data.subLocation : undefined,
      title: dirtyFields.title ? data.title : undefined,
      //read only fields
      allDay: false,
      frequency: 'does_not_repeat',
      startDate: '',
      //static fields
      activityType: data.activityType,
      team: data.team,
      awayTeam: isActivityTypeGame ? data.awayTeam : undefined,
      subProgram: data.subProgram,
    };

    return dirtyFieldValues;
  }

  async function submitEditActivity(data: ActivityFormData) {
    if (editActivity) {
      // const editActivityId = editActivity.id;
      if (!formHasBeenChanged(data, editActivity)) {
        setShowNoChangeWarning(true);
        return;
      }

      let requestData = data;
      const isRecurringSet = editRecurringActivityType === 'all';
      if (isRecurringSet) {
        requestData = getRecurringSetDirtyFieldValues(data);
      }

      const updateActivityData: UpdateActivityData = {
        editActivity: editActivity,
        data: requestData,
        siteId: Number(siteId),
        teams,
        isRecurringSet,
      };

      if (notificationsEnabled) {
        handleSetUpdateActivityData(updateActivityData);
        handleSetCurrentScreen(
          ActivityFormWrapperScreens.NotificationEditActivity
        );
      } else {
        handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);

        await updateActivityHandler(updateActivityData)
          .then((data) => {
            const activity = data.activities ?? [data];
            setTimeout(() => {
              handleSetSubmittedForm({ activity, submitType: 'updated' });
              handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
            }, REQUEST_DELAY);
          })
          .catch((e) => {
            const errorMessage = typeof e === 'string' ? e : e.message;
            handleSetSubmittedForm({ errorMessage });
            handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
          });
      }
    }
  }

  const onTeamSelect = (value?: string) => {
    setIsTeamSelectOpen(!isTeamSelectOpen);

    let external: ExternalTeamType =
      externalTeamNameLabel === 'home' ? undefined : externalTeamNameLabel;

    // Check for TBD
    // value will always be negative
    if (value && isTeamTBD(value)) {
      setSelectedTeam({ value: value, label: 'TBD' });
    } else if (value === 'external') {
      setSelectedTeam({ value: value, label: 'External team' });
      external = 'home';
    } else if (value) {
      const teamName = teams.find(({ id }) => id === Number(value))?.name || '';
      setSelectedTeam({ value: value, label: teamName });
      if (!external) {
        //reset external team value
        resetField('externalTeam', { defaultValue: '', keepError: true });
        setSelectedExternalTeam(undefined);
      }
      setInternalTeamError(false);
      setInternalTeamErrorMessage('');
    } else {
      setSelectedTeam(undefined);
    }

    setExternalTeamNameLabel(external);
  };

  const onAwayTeamSelect = (value?: string) => {
    setIsAwayTeamSelectOpen(!isAwayTeamSelectOpen);

    let external: ExternalTeamType =
      externalTeamNameLabel === 'away' ? undefined : externalTeamNameLabel;

    // Check for TBD
    // value will always be negative
    if (value && isTeamTBD(value)) {
      setSelectedAwayTeam({ value: value, label: 'TBD' });
    } else if (value === 'external') {
      setSelectedAwayTeam({ value: value, label: 'External team' });
      external = 'away';
    } else if (value) {
      const teamName = teams.find(({ id }) => id === Number(value))?.name || '';
      setSelectedAwayTeam({ value: value, label: teamName });

      if (!external) {
        //reset external team value
        resetField('externalTeam', { defaultValue: '', keepError: true });
        setSelectedExternalTeam(undefined);
      }
      setInternalTeamError(false);
      setInternalTeamErrorMessage('');
    } else {
      setSelectedAwayTeam(undefined);
    }

    setExternalTeamNameLabel(external);
  };

  const onExternalTeamSelect = (type: ExternalTeamType, value?: string) => {
    if (type === 'home') {
      setIsExternalHomeTeamNameSelectOpen(!isExternalHomeTeamNameSelectOpen);
    } else if (type === 'away') {
      setIsExternalAwayTeamNameSelectOpen(!isExternalAwayTeamNameSelectOpen);
    }

    if (value) {
      const teamName = teams.find(({ id }) => id === Number(value))?.name || '';
      setSelectedExternalTeam({ value: value, label: teamName });
    } else {
      setSelectedExternalTeam(undefined);
    }
  };

  const onLocationSelect = (value?: string) => {
    setLocationSelectOpen(!isLocationSelectOpen);

    if (value) {
      const locationName = getLocationName(value);
      setSelectedLocation({ label: locationName, value: value });
      resetField('subLocation', { defaultValue: '', keepError: true });
    }
  };

  const handleDiscardChanges = () => {
    const { sld, subdomain, tld } = getLAHostnameParts();
    window.location.href = `https://${subdomain}.${sld}.${tld}/${originRoute}`;
  };

  return (
    <S.StyledForm
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      noValidate
    >
      {showNoChangeWarning ? (
        <Snackbar
          description="Please make at least one change before saving."
          duration={10000}
          onOpenChange={() => setShowNoChangeWarning(!showNoChangeWarning)}
          variant="info"
        />
      ) : null}

      {showEditRecurringActivityWarning ? (
        <S.WarningSnackbar>
          <div>
            <ExclamationOctagonIcon fill="#df6c06" size="large" />
            <p>{showEditRecurringActivityWarning}</p>
          </div>
        </S.WarningSnackbar>
      ) : null}

      {editRecurringActivityType !== undefined ? (
        <div id="snackbar-recurring-message"></div>
      ) : null}
      <S.StyledFormSelectFields>
        <S.Flex>
          <Controller
            control={control}
            name="activityType"
            render={({ field }) => {
              return (
                <Select
                  id="activityType"
                  label="Activity type"
                  placeholder="Select an activity type"
                  options={activityTypeOptions}
                  {...field}
                  readOnly={!!allDayActivity && isEditForm}
                  required
                  errorMessage={errors.activityType?.message}
                  hasError={!!errors.activityType?.message}
                />
              );
            }}
          />
          {isActivityTypeGame ? (
            <Controller
              control={control}
              name="gameType"
              render={({ field }) => {
                return (
                  <Select
                    id="gameType"
                    label="Game type"
                    placeholder="Select a game type"
                    options={gameTypeOptions}
                    {...field}
                    required
                    errorMessage={errors.gameType?.message}
                    hasError={!!errors.gameType?.message}
                  />
                );
              }}
            />
          ) : null}
        </S.Flex>
        <Controller
          control={control}
          name="team"
          render={({ field }) => {
            return (
              <SelectPopover
                id="team"
                label={isActivityTypeGame ? 'Home team' : 'Team'}
                placeholder={
                  isActivityTypeGame ? 'Select home team' : 'Select a team'
                }
                size="large"
                onOpenChange={() => onTeamSelect(field.value)}
                open={isTeamSelectOpen}
                content={
                  <TeamPrimaryStaffSelect
                    onClose={onTeamSelect}
                    showExternalOption={showHomeExternalTeamOption}
                    teams={homeTeamOptions}
                    {...field}
                  />
                }
                value={selectedTeam?.label}
                readOnly={
                  !isActivityTypeGame || editRecurringActivityType === 'all'
                }
                required
                hasError={!!errors.team?.message || internalTeamError}
                errorMessage={errors.team?.message || internalTeamErrorMessage}
              />
            );
          }}
        />

        {externalTeamNameLabel === 'home' ? (
          <Controller
            control={control}
            name="externalTeam"
            render={({ field }) => {
              return (
                <SelectPopover
                  id="externalTeam"
                  label="External team name"
                  placeholder="Select external team"
                  size="large"
                  onOpenChange={() => onExternalTeamSelect('home', field.value)}
                  open={isExternalHomeTeamNameSelectOpen}
                  content={
                    <ExternalTeamSelect
                      onClose={(value?: string) =>
                        onExternalTeamSelect('home', value)
                      }
                      teams={externalTeamOptions}
                      selectedSubProgramId={selectedSubProgramId}
                      {...field}
                    />
                  }
                  required
                  readOnly={editRecurringActivityType === 'all'}
                  value={selectedExternalTeam?.label}
                  hasError={!!errors.externalTeam?.message}
                  errorMessage={errors.externalTeam?.message}
                />
              );
            }}
          />
        ) : null}

        {isActivityTypeGame ? (
          <Controller
            control={control}
            name="awayTeam"
            render={({ field }) => {
              return (
                <SelectPopover
                  id="awayTeam"
                  label="Away team"
                  placeholder="Select away team"
                  size="large"
                  onOpenChange={() => {
                    const awayTeam = field.value ?? undefined;
                    onAwayTeamSelect(awayTeam);
                  }}
                  open={isAwayTeamSelectOpen}
                  content={
                    <TeamPrimaryStaffSelect
                      onClose={onAwayTeamSelect}
                      showExternalOption={showAwayExternalTeamOption}
                      teams={awayTeamOptions}
                      {...field}
                    />
                  }
                  value={selectedAwayTeam?.label}
                  required
                  readOnly={
                    editRecurringActivityType === 'all' &&
                    !dirtyFields.activityType
                  }
                  hasError={!!errors.awayTeam?.message}
                  errorMessage={errors.awayTeam?.message}
                />
              );
            }}
          />
        ) : null}

        {externalTeamNameLabel === 'away' ? (
          <Controller
            control={control}
            name="externalTeam"
            render={({ field }) => {
              return (
                <SelectPopover
                  id="externalTeam"
                  label="External team name"
                  placeholder="Select external team"
                  size="large"
                  onOpenChange={() => onExternalTeamSelect('away', field.value)}
                  open={isExternalAwayTeamNameSelectOpen}
                  content={
                    <ExternalTeamSelect
                      onClose={(value?: string) =>
                        onExternalTeamSelect('away', value)
                      }
                      selectedSubProgramId={selectedSubProgramId}
                      teams={externalTeamOptions}
                      {...field}
                    />
                  }
                  required
                  readOnly={
                    editRecurringActivityType === 'all' &&
                    !dirtyFields.activityType
                  }
                  value={selectedExternalTeam?.label}
                  hasError={!!errors.externalTeam?.message}
                  errorMessage={errors.externalTeam?.message}
                />
              );
            }}
          />
        ) : null}

        <Controller
          control={control}
          name="location"
          render={({ field }) => {
            return (
              <SelectPopover
                id="location-menu"
                label="Location"
                placeholder="Select a location"
                size="large"
                onOpenChange={() => onLocationSelect(field.value)}
                open={isLocationSelectOpen}
                content={
                  <ExternalLocationSelect
                    onClose={onLocationSelect}
                    locations={locationsOptions}
                    subProgramId={subProgramId}
                    {...field}
                  />
                }
                value={selectedLocation?.label}
                hasError={!!errors.location?.message}
                errorMessage={errors.location?.message}
              />
            );
          }}
        />
        <input {...register('hasSubLocations')} type="hidden" value="false" />
        {subLocationOptions.length > 0 ? (
          <Controller
            name="subLocation"
            control={control}
            render={({ field }) => (
              <Select
                id="sub-location-menu"
                label="Sub-location"
                options={subLocationOptions}
                placeholder="Select a sub-location"
                {...field}
                onChange={(newSubLocationId) => {
                  if (newSubLocationId === field.value) {
                    return;
                  }
                  field.onChange(newSubLocationId);
                }}
                hasError={!!errors.subLocation?.message}
                errorMessage={errors.subLocation?.message}
              />
            )}
          />
        ) : null}
        <S.BorderedFormSection>
          <S.FlexEnd>
            {editRecurringActivityType !== 'all' ? (
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <div>
                    <Typography variant="ui" size="medium" weight="bold">
                      <S.Label error={!!errors.startDate?.message}>
                        Start Date
                      </S.Label>
                    </Typography>
                    <DatePicker
                      date={selectedStartDate}
                      defaultInputProps={{
                        name: field.name,
                        id: 'start-date',
                        label: '',
                        placeholder: 'Select a start date',
                      }}
                      error={!!errors.startDate?.message}
                      fullWidth
                      onDateSelect={(dt) => {
                        if (dt) {
                          const date = formatDate(dt);
                          field.onChange(date);
                          handleStartDateSelected(date);
                        }
                      }}
                    />
                    <ActivityFormErrorMessage
                      errorMessage={errors.startDate?.message}
                    />
                  </div>
                )}
              />
            ) : null}
            {(isEditForm && !editActivity?.allDay) ||
            isActivityTypeGame ? null : (
              <Controller
                name="allDay"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    ariaLabel="All Day"
                    id="all-day-activity"
                    checked={allDayActivity}
                    label="All Day"
                    onCheckedChange={(allDay: boolean) => {
                      field.onChange(allDay);
                      handleAllDayChange(allDay);
                    }}
                    size="large"
                  />
                )}
              />
            )}
          </S.FlexEnd>

          {!allDayActivity ? null : (
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <div>
                  <Typography variant="ui" size="medium" weight="bold">
                    <S.Label error={!!errors.endDate?.message}>
                      End Date
                    </S.Label>
                  </Typography>
                  <DatePicker
                    date={selectedEndDate}
                    defaultInputProps={{
                      name: field.name,
                      id: 'end-date',
                      label: '',
                      placeholder: 'Select a end date',
                    }}
                    error={!!errors.endDate?.message}
                    fullWidth
                    onDateSelect={(dt) => {
                      if (dt) {
                        const date = formatDate(dt);
                        field.onChange(date);
                        handleAllDayEndDateSelected(date);
                      }
                    }}
                  />
                  <ActivityFormErrorMessage
                    errorMessage={errors.endDate?.message}
                  />
                </div>
              )}
            />
          )}

          {!isEditForm ? (
            <Controller
              name="frequency"
              control={control}
              render={({ field }) => (
                <Select
                  id="frequency"
                  disabled={allDayActivity}
                  label="Frequency"
                  placeholder="Select a frequency"
                  options={frequencyOptions}
                  {...field}
                  onChange={(frequency: string) => {
                    field.onChange(frequency);
                  }}
                  required
                  readOnly={isEditForm}
                  hasError={!!errors.frequency?.message}
                  // errorMessage={errors.frequency?.message}
                />
              )}
            />
          ) : null}

          {!allDayActivity && frequency && frequency !== 'does_not_repeat' ? (
            <S.RecurringDate>
              <Typography variant="ui" size="large" weight="regular">
                Repeats every {frequency} until:
              </Typography>
              <div>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <DatePicker
                        date={selectedRecurringEndDate}
                        defaultInputProps={{
                          name: field.name,
                          id: 'end-date',
                          label: '',
                          placeholder: 'Select an end date',
                        }}
                        error={!!errors.endDate?.message}
                        fullWidth
                        onDateSelect={(dt) => {
                          if (dt) {
                            const date = formatDate(dt);
                            field.onChange(date);
                            handleRecurringEndDateSelected(date);
                          }
                        }}
                      />
                      <ActivityFormErrorMessage
                        errorMessage={errors.endDate?.message}
                      />
                    </div>
                  )}
                />
                {selectedStartDate && selectedRecurringEndDate ? (
                  <Typography
                    variant="ui"
                    size="medium"
                    weight="regular"
                    className="repeatsFor"
                  >
                    {repeatsForDuration()}
                  </Typography>
                ) : null}
              </div>
            </S.RecurringDate>
          ) : null}

          <S.Flex>
            <Controller
              name="arrival"
              control={control}
              render={({ field }) => (
                <div>
                  <Typography variant="ui" size="medium" weight="bold">
                    <S.Label
                      error={!!errors.arrival?.message}
                      disabled={allDayActivity}
                    >
                      Arrival time
                    </S.Label>
                  </Typography>
                  <TextField
                    disabled={allDayActivity}
                    error={!!errors.arrival?.message}
                    helperText={errors.arrival?.message}
                    fullWidth
                    id="arrival-time"
                    data-testid="arrival-time"
                    label=""
                    type="time"
                    {...field}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      field.onChange(evt.target.value);
                    }}
                    ref={null}
                  />
                </div>
              )}
            />
            <Controller
              name="startTime"
              control={control}
              render={({ field }) => (
                <div>
                  <Typography variant="ui" size="medium" weight="bold">
                    <S.Label
                      error={!!errors.startTime?.message}
                      disabled={allDayActivity}
                    >
                      Start time
                    </S.Label>
                  </Typography>
                  <TextField
                    disabled={allDayActivity}
                    error={!!errors.startTime?.message}
                    fullWidth
                    helperText={errors.startTime?.message}
                    id="start-time"
                    data-testid="start-time"
                    label=""
                    type="time"
                    {...field}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      field.onChange(evt.target.value);

                      if (isSubmitted) {
                        trigger('endTime').then();
                      }
                    }}
                    ref={null}
                  />
                </div>
              )}
            />

            <Controller
              name="endTime"
              control={control}
              render={({ field }) => (
                <div>
                  <Typography variant="ui" size="medium" weight="bold">
                    <S.Label
                      error={!!errors.endTime?.message}
                      disabled={allDayActivity}
                    >
                      End time
                    </S.Label>
                  </Typography>
                  <TextField
                    disabled={allDayActivity}
                    error={!!errors.endTime?.message}
                    fullWidth
                    helperText={errors.endTime?.message}
                    id="end-time"
                    data-testid="end-time"
                    label=""
                    type="time"
                    {...field}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      field.onChange(evt.target.value);

                      if (isSubmitted) {
                        trigger('startTime').then();
                      }
                    }}
                    ref={null}
                  />
                </div>
              )}
            />
          </S.Flex>
        </S.BorderedFormSection>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <div>
              <Typography variant="ui" size="medium" weight="bold">
                <S.Label>Notes</S.Label>
              </Typography>
              <S.StyledTextArea
                id="notes"
                maxLength={500}
                placeholder="Add notes"
                rows={3}
                {...field}
                ref={null}
                value={watch('notes')}
              />
            </div>
          )}
        />
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <div>
              <Typography variant="ui" size="medium" weight="bold">
                <S.Label>Custom title</S.Label>
              </Typography>
              <TextInput
                id="activity-title"
                type="text"
                {...field}
                ref={null}
              />
            </div>
          )}
        />
        {isEditForm &&
        editActivity?.state === ActivityState.Scheduled &&
        editRecurringActivityType !== 'all' ? (
          <S.CancelSection>
            <S.CancelText>Would you like to cancel this activity?</S.CancelText>
            <Button
              id="cancel-button"
              onClick={() =>
                handleSetCurrentScreen(
                  ActivityFormWrapperScreens.CancelActivity
                )
              }
            >
              {cancelText}
            </Button>
          </S.CancelSection>
        ) : null}
        <S.FormButtons $isEditMobile={isEditForm && isPhoneOnly}>
          <Button
            onClick={handleDiscardChanges}
            variant="text"
            size="large"
            id="discard"
          >
            Discard Changes
          </Button>
          <S.SecondaryButton $isEditMobile={isEditForm && isPhoneOnly}>
            {!!isEditForm && (
              <Button
                variant="outline"
                size="large"
                onClick={() =>
                  handleSetCurrentScreen(
                    ActivityFormWrapperScreens.DeleteActivity
                  )
                }
              >
                Delete Activity
              </Button>
            )}
            <Button form={formId} type="submit" variant="primary" size="large">
              {isEditForm ? 'Save Changes' : 'Create Activity'}
            </Button>
          </S.SecondaryButton>
        </S.FormButtons>
      </S.StyledFormSelectFields>
    </S.StyledForm>
  );
}
/* */

function ActivityFormErrorMessage({ errorMessage }: { errorMessage?: string }) {
  return (
    <>
      {errorMessage ? (
        <S.ActivityFormErrorMessage>
          <InputErrorMessage>{errorMessage}</InputErrorMessage>
        </S.ActivityFormErrorMessage>
      ) : null}
    </>
  );
}

function formatDate(date: DateTime) {
  const dateAsISO = convertDateTimeToISO(date);
  if (dateAsISO) {
    return formatISODateTime(dateAsISO, 'y-MM-dd');
  }

  return '';
}

export function isGameActivity(
  activityType: ActivityType | AllDayActivityType
) {
  return (
    activityType === ActivityType.GameConsolation ||
    activityType === ActivityType.GameFriendly ||
    activityType === ActivityType.GameLeague ||
    activityType === ActivityType.GamePlayoff ||
    activityType === ActivityType.GameScrimmage ||
    activityType === ActivityType.GameSeason ||
    activityType === ActivityType.GameTournament ||
    activityType === ActivityType.GameFinal
  );
}

function getTeamNameById(teams: TeamWithProps[], teamId: number) {
  if (isTeamTBD(teamId)) {
    return 'TBD';
  }

  const team = teams.find((team) => team.id === teamId);
  if (!team) {
    return '';
  }

  return team.name;
}

function getProgramId(program?: Program) {
  if (!program || !program.id) {
    return '';
  }

  const isStandaloneProgram = !program.master && !program.masterProgramId;

  if (isStandaloneProgram) {
    return program.id.toString();
  }

  return program.masterProgramId?.toString() ?? '';
}
