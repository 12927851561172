import NavItem from '../NavItem/NavItem';
import * as S from './NavBar.styles';

export type NavItemData = {
  href: string;
  label: string;
  links?: NavItemData[];
  target?: string;
};

export type NavBarProps = {
  navData: NavItemData[];
};

/* NavBar */
export default function NavBar({ navData }: NavBarProps) {
  return (
    <S.NavBar>
      <NavItems navData={navData} />
    </S.NavBar>
  );
}
/* */

/* Nav Items */
function NavItems({ navData }: NavBarProps) {
  return (
    <>
      {navData.map(({ href, label, links, target }, idx) => (
        <NavItem
          href={href}
          key={`navItem-${idx}`}
          label={label}
          links={links}
          target={target}
        />
      ))}
    </>
  );
}
/* */
