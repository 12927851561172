/**
 * All styling taken from Design System Repo
 * Used for SelectPopover Component
 */
import {
  Scrollbar as RadixScrollbar,
  Thumb,
} from '@radix-ui/react-scroll-area';
import {
  Content,
  Group,
  Item,
  Label,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import styled from 'styled-components';
import { InputSizeVariant, ThemedComponent } from '@la/ds-ui-components';
import { generateInputBaseDynamicStyles } from 'components/SelectPopover/utils/SelectPopover.utils';
import {
  InputState,
  generateInputAdornmentDynamicStyles,
  generateInputPlaceholderDynamicStyles,
} from './utils/SelectPopover.utils';

type SelectTriggerStyleProps = ThemedComponent<{
  $size: InputSizeVariant;
  $width: string | number;
  $state: InputState;
  $isInteractive: boolean;
}>;

type ChevronWrapperStyleProps = ThemedComponent<{
  $state: InputState;
}>;

export const inputVariantHeights = {
  large: '40px',
  medium: '32px',
};

const getInputContainerWidth = (width?: string | number) => {
  if (!width) {
    return 'auto';
  }
  if (typeof width === 'number') {
    return `${width}px`;
  }
  return width;
};

export const SelectWrapper = styled.div<{ $isInteractive: boolean }>(
  ({ $isInteractive }) => {
    const cursor = $isInteractive ? 'pointer' : 'not-allowed';
    return {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      cursor,
      '& > label': {
        pointerEvents: $isInteractive ? 'auto' : 'none',
      },
    };
  }
);

export const SelectTrigger = styled(Trigger)<SelectTriggerStyleProps>(({
  theme,
  $state,
  $size,
  $width,
  $isInteractive,
}: SelectTriggerStyleProps) => {
  const styles = generateInputBaseDynamicStyles(theme, $state);
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '0 8px',
    margin: '4px 0',
    minWidth: 0,
    width: getInputContainerWidth($width),
    height: inputVariantHeights[$size],
    backgroundColor: styles.backgroundColor,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: styles.borderColor,
    pointerEvents: $isInteractive ? 'auto' : 'none',
    '&:focus-within': {
      outline: $isInteractive ? '2px solid' : 'none',
      outlineOffset: '2px',
      outlineColor: styles.borderColor,
    },
  };
});

export const ChevronWrapper = styled.div(
  ({ theme, $state }: ChevronWrapperStyleProps) => {
    const { fill } = generateInputAdornmentDynamicStyles(theme, $state);
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 0 4px 0',
      fill: fill,
      stroke: fill,
    };
  }
);

export const SelectContent = styled(Content)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  const { foundational } = theme.palette;
  return {
    width: 'var(--radix-select-trigger-width)',
    backgroundColor: foundational.white,
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${foundational.blueGrey[100]}`,
    borderRadius: theme.spacing.xxxs,
    marginTop: '1px',
    overflowX: 'hidden',
    // Hardcoded until we add zIndexes to theme
    zIndex: 1100,
  };
});

export const SelectGroup = styled(Group)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  return {
    ...theme.typography.ui.medium,
    fontWeight: theme.typography.weights.semiBold,
    lineHeight: '36px',
  };
});

export const SelectViewport = styled(Viewport)({
  maxHeight: 'min(376px, var(--radix-select-content-available-height))',
});

export const SelectValueContainer = styled.span<
  ThemedComponent<{
    $isPlaceholder?: boolean;
    $state: InputState;
  }>
>(
  ({
    theme,
    $isPlaceholder,
    $state,
  }: ThemedComponent<{ $isPlaceholder?: boolean; $state: InputState }>) => {
    const { color: placeholderColor } = generateInputPlaceholderDynamicStyles(
      theme,
      $state
    );
    const { color } = generateInputBaseDynamicStyles(theme, $state);
    return {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontStyle: $isPlaceholder ? 'italic' : 'normal',
      color: $isPlaceholder ? placeholderColor : color,
    };
  }
);

export const SelectValue = styled(Value)<
  ThemedComponent<{ $size: InputSizeVariant }>
>(({ theme, $size }: ThemedComponent<{ $size: InputSizeVariant }>) => {
  return theme.typography.ui[$size];
});

export const SelectGroupLabel = styled(Label)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  return {
    margin: `0 ${theme.spacing.small}`,
    wordBreak: 'break-word',
  };
});

export const Scrollbar = styled(RadixScrollbar)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  return {
    width: '14px',
    padding: theme.spacing.xxxs,
    backgroundColor: theme.palette.foundational.white,
  };
});

export const ScrollbarThumb = styled(Thumb)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  return {
    backgroundColor: theme.palette.foundational.blueGrey[200],
    borderRadius: theme.spacing.xxxs,
  };
});

type SelectItemStyleProps = {
  $isPlaceholder?: boolean;
  $size?: InputSizeVariant;
};

export const SelectItemWrapper = styled.div<{ $isDisabled?: boolean }>(
  ({ $isDisabled }: { $isDisabled?: boolean }) => {
    return {
      cursor: $isDisabled ? 'not-allowed' : 'pointer',
    };
  }
);

export const SelectItemContainer = styled(Item)<
  ThemedComponent<SelectItemStyleProps>
>(({
  theme,
  $isPlaceholder,
  $size = 'large',
}: ThemedComponent<SelectItemStyleProps>) => {
  const fontStyle = $isPlaceholder ? 'italic' : 'normal';
  return {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    outline: 'none',
    fontStyle,
    ...theme.typography.ui[$size],
    '&[data-highlighted]': {
      fontStyle,
      backgroundColor: theme.palette.foundational.blueGrey[50],
    },
    '&[data-disabled]': {
      color: theme.palette.foundational.blueGrey[250],
      pointerEvents: 'none',
    },
  };
});

export const SelectItemTextContainer = styled.span<{
  $grouped?: boolean;
  $isPlaceholder?: boolean;
}>(
  ({
    $grouped,
    $isPlaceholder,
  }: {
    $grouped?: boolean;
    $isPlaceholder?: boolean;
  }) => {
    const padding = $grouped && !$isPlaceholder ? '9px 32px' : '9px 16px';
    return {
      width: 'var(--radix-select-trigger-width)',
      padding,
      boxSizing: 'border-box',
      wordBreak: 'break-word',
    };
  }
);
