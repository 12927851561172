"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainContentHeaderTitle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("../../../lib/hooks");
var breakpoints_1 = require("../../../lib/media-queries/breakpoints");
var ReactiveTypography_1 = require("../../ReactiveTypography/ReactiveTypography");
function MainContentHeaderTitle(props) {
    var isTabletLandscapeUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletLandscapeUp);
    var isMC = props.isMC, rest = __rest(props, ["isMC"]);
    return ((0, jsx_runtime_1.jsx)(ReactiveTypography_1.ReactiveTypography, __assign({ background: "primary", customTag: "h1", variant: "headline", size: isTabletLandscapeUp ? 'xl' : 'medium', isWhite: isMC }, rest)));
}
exports.MainContentHeaderTitle = MainContentHeaderTitle;
