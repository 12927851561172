"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Typeahead = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_select_1 = require("react-select");
var async_1 = require("react-select/async");
var lodash_1 = require("lodash");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var TeamRegistrationContext_1 = require("../../tournaments/registration/TeamRegistration/TeamRegistrationContext");
var S = require("./Typeahead.styles");
var memberToOptions = function (members) {
    if (!members) {
        return [];
    }
    return members.map(function (member) { return ({
        value: member,
        label: "".concat(member.firstName, " ").concat(member.lastName),
    }); });
};
var theme = (0, ds_ui_components_1.generateTheme)();
var _a = theme.foundational, blueGrey = _a.blueGrey, green = _a.green, red = _a.red;
var borderColor = {
    default: blueGrey[400],
    hover: blueGrey[600],
    focus: green[600],
    error: red[500],
    readonly: blueGrey[50],
    disabled: blueGrey[200],
};
var selectStyles = {
    control: function (baseStyles, state) { return (__assign(__assign({}, baseStyles), { borderColor: state.isFocused ? borderColor.focus : borderColor.default, height: 40, width: '100%' })); },
    option: function (baseStyles) { return (__assign(__assign(__assign(__assign({}, baseStyles), { cursor: 'pointer', display: 'flex', alignItems: 'center', flex: 1, outline: 'none', fontStyle: 'normal' }), theme.typography.ui['medium']), { '&[data-highlighted]': {
            fontStyle: 'bold',
            backgroundColor: theme.palette.foundational.blueGrey[50],
        }, '&[data-disabled]': {
            color: theme.palette.foundational.blueGrey[250],
            pointerEvents: 'none',
        } })); },
    placeholder: function (baseStyles) { return (__assign(__assign({}, baseStyles), { fontStyle: 'italic' })); },
    menuPortal: function (baseStyles) { return (__assign(__assign({}, baseStyles), { zIndex: 9999 })); },
    menu: function (baseStyles) { return (__assign(__assign({}, baseStyles), { marginTop: 1, boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)', borderRadius: '0px 0px 4px 4px', borderRight: '1px solid', borderBottom: '1px solid ', borderLeft: '1px solid', borderColor: 'var(--blue-grey-400)' })); },
};
var CustomOption = function (props) {
    return ((0, jsx_runtime_1.jsx)(react_select_1.components.Option, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(S.OptionContainer, { children: [(0, jsx_runtime_1.jsx)(S.OptionLabel, __assign({ variant: "ui", size: "large" }, { children: props.data.label })), (0, jsx_runtime_1.jsx)(S.OptionEmail, __assign({ variant: "ui", size: "small" }, { children: props.data.value.email }))] }) })));
};
var Typeahead = function () {
    var _a = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext), setPayer = _a.setPayer, state = _a.state;
    var siteId = (0, react_router_dom_1.useParams)().siteId;
    var searchMemberOptions = function (inputValue, callback) {
        (0, services_1.searchMembers)({
            siteId: siteId,
            search: inputValue,
        }).then(function (members) {
            var options = memberToOptions(members);
            callback(options);
        });
    };
    var debouncedSearchMemberOptions = (0, lodash_1.debounce)(searchMemberOptions, 1000);
    var onMemberSelect = function (option) {
        setPayer(option.value);
    };
    var defaultValue = state.payer
        ? {
            value: state.payer,
            label: "".concat(state.payer.firstName, " ").concat(state.payer.lastName),
        }
        : undefined;
    return ((0, jsx_runtime_1.jsxs)(S.TypeaheadContainer, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.InputLabel, __assign({ required: true, htmlFor: "select-payer", inputState: 'default' }, { children: "Select Payer" })), (0, jsx_runtime_1.jsx)(async_1.default, { isClearable: false, isSearchable: true, name: "select-payer", id: "select-payer", loadOptions: debouncedSearchMemberOptions, placeholder: "Select a payer...", menuPortalTarget: document.body, styles: selectStyles, onChange: onMemberSelect, defaultValue: defaultValue, components: {
                    Option: CustomOption,
                } }), (0, jsx_runtime_1.jsxs)(S.TypeaheadHelperText, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.InfoIcon, { variant: "regular", fill: "#78909C", size: "xl" }), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Enter at least 3 characters to begin search." }))] })] }));
};
exports.Typeahead = Typeahead;
