/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum ActivityType {
  GameSeason = 'game_season',
  GamePoolPlay = 'game_pool_play',
  GamePlayoff = 'game_playoff',
  GameFriendly = 'game_friendly',
  GameConsolation = 'game_consolation',
  GameTravel = 'game_travel',
  GameTournament = 'game_tournament',
  GameLeague = 'game_league',
  GameScrimmage = 'game_scrimmage',
  GameFinal = 'game_final',
  EventPractice = 'event_practice',
  EventLesson = 'event_lesson',
  EventTraining = 'event_training',
  EventMeeting = 'event_meeting',
  EventTournament = 'event_tournament',
  EventLeague = 'event_league',
  EventCamp = 'event_camp',
  EventClass = 'event_class',
  EventOther = 'event_other',
}
