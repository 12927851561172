"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatToISO = exports.formatDateRange = exports.formatDateUsingShortMonthName = exports.shortenLongMonthNames = exports.isWithinDateRange = exports.setDatesTimezone = exports.INVALID_DATE_ERROR_MESSAGE = exports.INVALID_MULTIPLE_DATE_ERROR_MESSAGE = exports.START_DATE_BEFORE_END_DATE_ERROR_MESSAGE = exports.INVALID_TIMEZONE_ERROR_MESSAGE = void 0;
var luxon_1 = require("luxon");
exports.INVALID_TIMEZONE_ERROR_MESSAGE = 'Timezone provided was not valid.';
exports.START_DATE_BEFORE_END_DATE_ERROR_MESSAGE = 'The start date cannot come before the end date.';
exports.INVALID_MULTIPLE_DATE_ERROR_MESSAGE = 'At least one date provided was not valid.';
exports.INVALID_DATE_ERROR_MESSAGE = 'The date provided was not valid.';
/**
 * Sets the timezone for a provided date. This does not change the actual time,
 * rather it adjusts the offset based on the provided timezone.
 *
 * Example:
 *
 * Before: America/New_York
 * 2023-04-04T00:00:00.000-05:00
 *
 * After: America/Los_Angeles
 * 2023-04-04T00:00:00.000-08:00
 *
 * @param dates ISO formatted date strings.
 * @param timezone Timezone for the dates to converted into.
 * @returns `DateTime` array of all dates with their origin timezone set
 * as the provided timezone.
 */
var setDatesTimezone = function (dates, timezone) {
    var isTimezoneValid = luxon_1.DateTime.now().setZone(timezone).isValid;
    if (!isTimezoneValid) {
        throw new Error(exports.INVALID_TIMEZONE_ERROR_MESSAGE);
    }
    return dates.map(function (date) {
        /**
         * When utilizing the second parameter rather than using the setZone()
         * method, it will create the DateTime object in the context of that timezone
         * such that only the offset is affected and not the actual time.
         */
        var dateObject = luxon_1.DateTime.fromISO(date, {
            zone: timezone,
            setZone: true,
        });
        if (!dateObject.isValid) {
            throw new Error(exports.INVALID_MULTIPLE_DATE_ERROR_MESSAGE);
        }
        return dateObject;
    });
};
exports.setDatesTimezone = setDatesTimezone;
/**
 * Checks whether or not the current date is within a given date range.
 * @param start Start date of the range in ISO format.
 * @param end End date of the range in ISO format.
 * @param timezone Timezone to take into account.
 * @returns True if the current date falls with the start and end date
 * range, false otherwise.
 */
var isWithinDateRange = function (start, end, timezone) {
    var _a = (0, exports.setDatesTimezone)([start, end], timezone), startDate = _a[0], endDate = _a[1];
    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');
    if (startDate > endDate) {
        throw new Error(exports.START_DATE_BEFORE_END_DATE_ERROR_MESSAGE);
    }
    var currentDate = luxon_1.DateTime.now().setZone(timezone);
    return luxon_1.Interval.fromDateTimes(startDate, endDate).contains(currentDate);
};
exports.isWithinDateRange = isWithinDateRange;
var SHORTENED_MONTHS = [
    'January',
    'February',
    'August',
    'September',
    'October',
    'November',
    'December',
];
/**
 * Shortnes long month names. If month is before March or after July, the
 * month is shortened.
 * @param month The month name. May be null due to month names being
 * a locale-based property of dates.
 * @returns Month name or "Invalid Month" if `month` is `null`.
 */
var shortenLongMonthNames = function (month) {
    if (!month) {
        return 'Invalid Month';
    }
    if (SHORTENED_MONTHS.includes(month)) {
        // Months before March and after July are shortened.
        return "".concat(month.substring(0, 3), ".");
    }
    return month;
};
exports.shortenLongMonthNames = shortenLongMonthNames;
function formatDateUsingShortMonthName(date) {
    if (!date.isValid) {
        throw new Error(exports.INVALID_DATE_ERROR_MESSAGE);
    }
    var dayYearFormat = 'd, yyyy';
    var monthLong = date.monthLong;
    var formattedMonth = (0, exports.shortenLongMonthNames)(monthLong);
    return "".concat(formattedMonth, " ").concat(date.toFormat(dayYearFormat));
}
exports.formatDateUsingShortMonthName = formatDateUsingShortMonthName;
/**
 * Formats given start and end dates into a date range, depending on
 * whether the two dates have the same year, month, and or day.
 * @param start Beginning date
 * @param end Ending date
 * @returns Date range joining the `start` and `end` dates. Or, if either
 * of the dates are invalid, returns "Invalid Date Range".
 * - Different years: `month day, year - month day, year`
 * - Different months: `month day - month day, year`
 * - Different days: `month day-day, year`
 * - Same month, day, and year: `month day, year`
 */
var formatDateRange = function (start, end) {
    if (!start.isValid || (end && !end.isValid)) {
        throw new Error(exports.INVALID_MULTIPLE_DATE_ERROR_MESSAGE);
    }
    var dayYearFormat = 'd, yyyy';
    var startDay = start.day, startMonth = start.monthLong, year = start.year;
    var formattedStartMonth = (0, exports.shortenLongMonthNames)(startMonth);
    if (!end) {
        return "Starts on ".concat(startMonth, " ").concat(start.toFormat(dayYearFormat));
    }
    var endDay = end.day, endMonth = end.monthLong;
    var formattedEndMonth = (0, exports.shortenLongMonthNames)(endMonth);
    if (start.year !== end.year) {
        // month day, year-month day, year
        return ("".concat(formattedStartMonth, " ").concat(start.toFormat(dayYearFormat), " - ") +
            "".concat(formattedEndMonth, " ").concat(end.toFormat(dayYearFormat)));
    }
    if (startMonth !== endMonth) {
        // month day-month day, year
        return ("".concat(formattedStartMonth, " ").concat(startDay, " - ") +
            "".concat(formattedEndMonth, " ").concat(endDay, ", ").concat(year));
    }
    if (startDay !== endDay) {
        // month day-day, year
        return "".concat(formattedStartMonth, " ").concat(startDay, "-").concat(endDay, ", ").concat(year);
    }
    // month day, year
    return "".concat(formattedStartMonth, " ").concat(start.toFormat(dayYearFormat));
};
exports.formatDateRange = formatDateRange;
var formatToISO = function (year, month) {
    return "".concat(luxon_1.DateTime.fromObject({ year: year, month: month }).toISO());
};
exports.formatToISO = formatToISO;
