import { foundationalTokens } from './tokens/foundational';
const {
  green,
  blueGrey,
  blue,
  red,
  orange
} = foundationalTokens;
const successPalette = {
  lightest: green[25],
  medium: green[500],
  dark: green[700]
};
const disabledPalette = {
  lightest: blueGrey[50],
  medium: blueGrey[200],
  dark: blueGrey[400]
};
const informativePalette = {
  lightest: blue[25],
  medium: blue[500],
  dark: blue[700]
};
const errorPalette = {
  lightest: red[25],
  medium: red[500],
  dark: red[700]
};
const warningPalette = {
  lightest: orange[25],
  medium: orange[500],
  dark: orange[700]
};
export const palette = {
  success: successPalette,
  disabled: disabledPalette,
  informative: informativePalette,
  error: errorPalette,
  warning: warningPalette,
  foundational: foundationalTokens
};