import { useEffect, useState } from 'react';
import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export type ErrorResponse = {
  response: {
    status: number;
    data: { message: string };
  };
};

export type DecodedTokenData = {
  iss: string;
  jti: string;
  name: string;
  prid: string;
  role: string;
  site: string;
  sub: string;
  team: string;
  type: 'player' | 'staff';
};

export async function verifyUrlSignature(
  signedReq: string
): Promise<DecodedTokenData> {
  try {
    const token = extractAccessToken();
    const { data } = await axios.post<DecodedTokenData>(
      `${getBaseURL()}/api/member-portal/verify-url-signature`,
      { signedRequest: signedReq },
      { headers: token ? { Authorization: token } : {} }
    );
    return data;
  } catch (error) {
    throw new Error('Request signature is invalid');
  }
}

export const useVerifyUrlSignature = () => {
  const [decodedData, setDecodedData] = useState<DecodedTokenData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  useEffect(() => {
    // Removing the `?` from the search query
    const token = window.location.search.substring(1);
    setIsLoading(true);
    verifyUrlSignature(token)
      .then((response) => {
        setDecodedData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  return {
    decodedData,
    isLoading,
    error,
  };
};
