import styled from 'styled-components/macro';

export const Layout = styled.div<{ $hasHeader?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  padding: ${({ $hasHeader }) => ($hasHeader ? `100px 0 0 0` : 0)};
  background-color: var(--blue-grey-25);

  & > * {
    margin: 0 auto;
  }
`;
