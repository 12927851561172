/**
 * This file was taken from Design System Repo
 * Used for SelectPopover Component
 */
import { MutableRefObject, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { DefaultTheme } from '@la/ds-ui-components';

type ElementType =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLButtonElement
  | null;

export type InputWidth = string | number;

export type InputStatesObject = {
  disabled?: boolean;
  error?: boolean;
  isFocused?: boolean;
  isHovered?: boolean;
  readOnly?: boolean;
};

export type InputState =
  | 'default'
  | 'error'
  | 'disabled'
  | 'focus'
  | 'hover'
  | 'readonly';

export const useInputState = (container: MutableRefObject<ElementType>) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const input = container.current;

    if (input) {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
      input.addEventListener('mouseenter', handleMouseEnter);
      input.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
        input.removeEventListener('mouseenter', handleMouseEnter);
        input.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [container]);

  return { isHovered, isFocused };
};

export const getInputContainerWidth = (width?: InputWidth) => {
  if (!width) {
    return 'auto';
  }
  if (!isNaN(+width)) {
    return `${width}px`;
  }
  return width;
};

export const determineComponentState = (
  states: InputStatesObject
): InputState => {
  if (states.disabled) {
    return 'disabled';
  }

  if (states.readOnly) {
    return 'readonly';
  }

  if (states.error) {
    return 'error';
  }

  if (states.isFocused) {
    return 'focus';
  }

  if (states.isHovered) {
    return 'hover';
  }

  return 'default';
};

type ComponentTokens = Partial<Record<keyof CSSProperties, string>>;

export const generateInputBaseDynamicStyles = (
  theme: DefaultTheme,
  state: InputState
): ComponentTokens => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: { blueGrey, green, red, white },
  } = theme;

  const borderColor = {
    default: blueGrey[400],
    hover: blueGrey[600],
    focus: isDynamic ? blueGrey[900] : green[600],
    error: red[500],
    readonly: blueGrey[50],
    disabled: blueGrey[200],
  };

  return {
    borderColor: borderColor[state],
    color: state === 'disabled' ? blueGrey[200] : blueGrey[900],
    backgroundColor: state === 'readonly' ? blueGrey[50] : white,
  };
};

export const generateInputAdornmentDynamicStyles = (
  theme: DefaultTheme,
  state: InputState
) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: { blueGrey, green, red, white },
  } = theme;

  const defaultFill = isDynamic ? blueGrey[900] : green[600];
  const fill = {
    default: defaultFill,
    hover: defaultFill,
    focus: defaultFill,
    error: red[500],
    readonly: blueGrey[400],
    disabled: blueGrey[200],
  };

  return {
    fill: fill[state],
    backgroundColor: state === 'readonly' ? blueGrey[50] : white,
  };
};

export const generateInputPlaceholderDynamicStyles = (
  theme: DefaultTheme,
  state: InputState
): ComponentTokens => {
  const {
    foundational: { blueGrey },
  } = theme;
  return {
    color: ['disabled', 'readonly'].includes(state)
      ? blueGrey[250]
      : blueGrey[400],
  };
};

// react-hook-form doesn't play well with mergeRefs() so we need to shared the refs by manually assigning them
// https://react-hook-form.com/faqs#Howtosharerefusage
export const createAssignSharedRefs =
  <T>(
    ref: React.ForwardedRef<T> | React.MutableRefObject<T | null>,
    internalRef: React.MutableRefObject<T | null>
  ) =>
  (element: T | null) => {
    const isCallback = typeof ref === 'function';
    if (isCallback) {
      ref(element);
    }
    if (ref && 'current' in ref) {
      ref.current = element;
    }
    internalRef.current = element;
  };
