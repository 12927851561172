import { useState } from 'react';
import { CartItemData } from '../Checkout.types';
import CheckoutCard, {
  CheckoutCardBody,
  CheckoutCardFooter,
  CheckoutCardPrimaryButton,
} from '../CheckoutCard/CheckoutCard';
import OrderDetailsModal from '../OrderDetailsModal/OrderDetailsModal';

export type OrderSummaryCardProps = {
  cartItems: CartItemData[];
};

export function getNumberOfTeams(cartItems: CartItemData[]) {
  const totalTeams = cartItems.reduce((teams, cartItem) => {
    const {
      options: {
        subprograms: { details },
      },
    } = cartItem;
    details.forEach((detail) => {
      teams += detail.registrations.length;
    });
    return teams;
  }, 0);
  return totalTeams;
}

/* OrderSummaryCard*/
export default function OrderSummaryCard({ cartItems }: OrderSummaryCardProps) {
  const [viewOrderDetails, setViewOrderDetails] = useState<boolean>(false);

  function handleOpenOrderDetails() {
    setViewOrderDetails(true);
  }

  function handleCloseOrderDetails() {
    setViewOrderDetails(false);
  }
  const numberOfTournaments = cartItems.length;
  const tournamentLabel =
    numberOfTournaments === 1 ? 'tournament' : 'tournaments';
  const numberOfTeams = getNumberOfTeams(cartItems);
  const teamLabel = numberOfTeams === 1 ? 'team' : 'teams';

  return (
    <>
      <CheckoutCard cardName="Order summary">
        <CheckoutCardBody>
          {numberOfTeams} {teamLabel} in {numberOfTournaments} {tournamentLabel}
        </CheckoutCardBody>
        <CheckoutCardFooter>
          <CheckoutCardPrimaryButton
            clickHandler={handleOpenOrderDetails}
            label="View order details"
          />
        </CheckoutCardFooter>
      </CheckoutCard>
      <OrderDetailsModal
        closeModal={handleCloseOrderDetails}
        orders={cartItems}
        viewOrderDetails={viewOrderDetails}
      />
    </>
  );
}
/* */
