import { requestLIPToken } from 'lib/auth/auth';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import SiteLogo from '../SiteLogo/SiteLogo';
import * as S from './LoginMenu.styles';

/* LoginMenu */
export default function LoginMenu() {
  return (
    <S.LoginMenu>
      <LoginMenuLogos />
      <LoginButton />
      <S.SignUpLink href="/signup">Sign Up</S.SignUpLink>
    </S.LoginMenu>
  );
}

/*LoginMenuLogosRow */
function LoginMenuLogos() {
  const { logoHref, logoImageSrc, siteName } =
    useAppSelector(getSiteIdentityData);

  return (
    <S.LogosRow>
      <S.SiteLogoContainer>
        <SiteLogo
          $maxHeight="40px"
          isInLoginMenu={true}
          siteLogoImageSrc={logoImageSrc}
          siteLogoHref={logoHref}
          siteName={siteName}
        />
      </S.SiteLogoContainer>
      <S.Divider />
      <S.LALogoContainer>
        <S.LA_Logo />
      </S.LALogoContainer>
    </S.LogosRow>
  );
}
/* */

/* LogIn Button*/
function LoginButton() {
  function redirectToLogin() {
    requestLIPToken();
  }

  return (
    <S.LoginButton
      onClick={redirectToLogin}
      size="large"
      variant="primary"
      width="100%"
    >
      Log In
    </S.LoginButton>
  );
}
/* */
