"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubNavigationMenuItem = exports.SubNavigationSection = exports.SubNavigation = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var services_1 = require("@la/services");
var utilities_1 = require("@la/utilities");
var S = require("./SubNavigation.styles");
function SubNavigation(_a) {
    var $gridAreaName = _a.$gridAreaName, programIdFromProps = _a.programIdFromProps;
    var siteId = (0, utilities_1.getSiteIdFromURL)();
    var pathProgramId = (0, react_router_dom_1.useParams)().programId;
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    var _b = (0, react_1.useState)([]), items = _b[0], setItems = _b[1];
    var getProgramId = function () {
        if (programIdFromProps) {
            return programIdFromProps;
        }
        if (pathProgramId) {
            return parseInt(pathProgramId);
        }
        return parseInt(searchParams.get('bid'));
    };
    var programId = getProgramId();
    (0, react_1.useEffect)(function () {
        (0, services_1.getSubnavigation)({ programId: programId, siteId: siteId })
            .then(function (response) {
            setItems(Object.values(response));
        })
            .catch(function (e) {
            console.error(e);
        });
    }, [programId, siteId]);
    return ((0, jsx_runtime_1.jsx)(S.SubNavigation, __assign({ "$gridAreaName": $gridAreaName }, { children: items.map(function (subNavSection, idx) {
            return ((0, jsx_runtime_1.jsx)(SubNavigationSection, { subNavSection: subNavSection }, "subnavItem-".concat(idx)));
        }) })));
}
exports.SubNavigation = SubNavigation;
function SubNavigationSection(_a) {
    var subNavSection = _a.subNavSection;
    return ((0, jsx_runtime_1.jsxs)(S.SubNavigationSection, { children: [(0, jsx_runtime_1.jsx)("a", __assign({ href: subNavSection.href }, { children: subNavSection.label })), subNavSection.submenu ? ((0, jsx_runtime_1.jsx)(S.SubNavigationSectionMenu, { children: (0, jsx_runtime_1.jsx)("div", { children: subNavSection.submenu.map(function (menuItem, idx) { return ((0, jsx_runtime_1.jsx)(SubNavigationMenuItem, { subNavMenuItem: menuItem }, "dropdownItem-".concat(idx))); }) }) })) : null] }));
}
exports.SubNavigationSection = SubNavigationSection;
/* */
/* DropDownMenu */
function SubNavigationMenuItem(_a) {
    var subNavMenuItem = _a.subNavMenuItem;
    return ((0, jsx_runtime_1.jsx)(S.SubNavigationMenuItem, { children: (0, jsx_runtime_1.jsx)("a", __assign({ href: subNavMenuItem.href }, { children: subNavMenuItem.label })) }));
}
exports.SubNavigationMenuItem = SubNavigationMenuItem;
