import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PlayAppLockup = styled.div`
  padding-top: 20px;

  ${media.TabletPortraitUp`
  padding-top: 0;
`}

  ${media.TabletLandscapeUp`
  padding-top: 32px;
`}

${media.DesktopUp`
  padding-top: 64px;
`}
`;

export const PlayAppLockupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--primary-100);
  background-color: var(--white);
`;

export const PlayAppLockupContent = styled.div`
  --lockup-negative-side-margins-phone: calc(
    var(--grid-side-padding-phone) * -1
  );
  --lockup-negative-side-margins-tabletPortraitUp: calc(
    var(--grid-side-padding-tabletPortraitUp) * -1
  );
  --lockup-negative-side-margins-tabletLandscapeUp: calc(
    var(--grid-side-padding-tabletLandscapeUp) * -1
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 20px var(--lockup-negative-side-margins-phone) 0;
  padding: 0 24px 24px;
  background-color: var(--white);

  ${media.TabletPortraitUp`
    flex-direction: row;
    gap: 12px;
    margin: 0 var(--lockup-negative-side-margins-tabletPortraitUp);
    padding: 12px 24px 24px;
  `}

  ${media.TabletLandscapeUp`
    gap: 40px;
    margin: 20px var(--lockup-negative-side-margins-tabletLandscapeUp) 0;
    padding: 12px 24px;
  `};

  ${media.DesktopUp`
    max-width: 1440px;
    padding: 32px 24px 16px;
  `}
`;

export const LockupImage = styled.img`
  margin-top: -20px;

  ${media.TabletPortraitUp`
    margin-top: 0;
  `}

  ${media.TabletLandscapeUp`
    margin-top: -32px;
  `}

  ${media.DesktopUp`
    margin-top: -64px;
  `}
`;

export const LockupInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${media.TabletPortraitUp`
    gap: 20px;
  `}
`;

export const LockupHead = styled.p`
  margin-bottom: 4px;
  font: var(--headline-small);
  color: var(--blue-grey-900);

  span {
    display: inline-block;
  }

  ${media.TabletLandscapeUp`
    font: var(--headline-medium);
  `}
`;

export const LockupBody = styled.p`
  font: var(--ui-medium-regular);

  ${media.TabletLandscapeUp`
    font: var(--ui-medium-medium);
  `}
`;

export const AppStoreButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 40px;

  img {
    height: 40px;
  }

  ${media.TabletLandscapeUp`
    height: 60px;
    gap: 12px;
    
    img {
      height: 60px;
    }
  `};
`;
