import { MouseEvent, PropsWithChildren, ReactElement, useState } from 'react';
import { ExternalLinkIcon } from '@la/ds-ui-components';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteId } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import { getUserFirstName, getUserIsAdmin } from 'redux/userSlice';
import { NavItemData } from '../NavBar/NavBar';
import * as S from './UserMenuMobile.styles';

export type AccordionMenuLinkData = Omit<NavItemData, 'links'>;

export type AccordionSubMenuLinkProps = {
  href: string;
  label: string;
  icon?: ReactElement;
  isUserMenu: boolean;
  target?: string;
};

export const UserMenuData = [
  {
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    href: '/schedule',
    label: 'My schedule',
  },
  {
    href: '/invoices',
    label: 'Invoices',
  },
  {
    href: '/editProfile',
    label: 'My profile',
  },
  {
    href: '/accountSettings',
    label: 'Account settings',
  },
];

/* UserMenuMobile */
export default function UserMenuMobile() {
  const userFirstName = useAppSelector(getUserFirstName);

  return (
    <S.UserMenuMobile>
      <Accordion
        toggleLabel={`Hi, ${userFirstName}`}
        linkData={UserMenuData}
        isUserMenu={true}
      ></Accordion>
    </S.UserMenuMobile>
  );
}
/* */

export type AccordionProps = {
  toggleLabel: string;
  linkData: NavItemData[];
  isUserMenu: boolean;
};

export function Accordion({
  children,
  toggleLabel,
  linkData,
  isUserMenu,
}: PropsWithChildren<AccordionProps>) {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  function handleToggleClick(evt: MouseEvent) {
    evt.stopPropagation();
    setIsAccordionExpanded(!isAccordionExpanded);
  }

  return (
    <S.Accordion>
      <S.AccordionToggle
        $menuExpanded={isAccordionExpanded}
        onClick={handleToggleClick}
      >
        {toggleLabel}
      </S.AccordionToggle>
      {isAccordionExpanded ? (
        <>
          <AccordionMenuLinks linkData={linkData} isUserMenu={isUserMenu} />
          {children}
        </>
      ) : null}
    </S.Accordion>
  );
}

export function AccordionMenuLinks({
  linkData,
  isUserMenu,
}: {
  linkData: NavItemData[];
  isUserMenu: boolean;
}) {
  const userIsAdmin = useAppSelector(getUserIsAdmin);
  const siteId = useAppSelector(getSiteId);

  function generateAdminConsoleLink(): string {
    const { sld, tld } = getLAHostnameParts();
    return `https://manager.${sld}.${tld}/console/sites/${siteId}`;
  }

  function generateLinks(linkData: AccordionMenuLinkData[]) {
    return linkData.map((link, idx) => {
      return (
        <AccordionSubMenuLink
          href={link.href}
          isUserMenu={isUserMenu}
          key={`${link.href}-${idx}`}
          label={link.label}
          target={link.target}
        />
      );
    });
  }

  return (
    <S.AccordionSubMenuContainer $isUserMenu={isUserMenu}>
      {generateLinks(linkData)}
      {isUserMenu && userIsAdmin ? (
        <AccordionSubMenuLink
          href={generateAdminConsoleLink()}
          icon={<ExternalLinkIcon fill="#263238" variant="bold" />}
          isUserMenu={isUserMenu}
          label="Admin Console"
          target={'_blank'}
        />
      ) : null}
      {isUserMenu ? (
        <S.SignOutLink href="/logout">Sign out</S.SignOutLink>
      ) : null}
    </S.AccordionSubMenuContainer>
  );
}

export function AccordionMenuLink({ href, label, target }: NavItemData) {
  const { updateNavPanelVisibility } = useMainNavigation();

  function handleLinkClick(evt: MouseEvent) {
    updateNavPanelVisibility(false);
    evt.stopPropagation();
  }

  return (
    <S.MenuLink
      to={href}
      onClick={handleLinkClick}
      target={target ? target : undefined}
    >
      {label}
    </S.MenuLink>
  );
}

export function AccordionSubMenuLink({
  href,
  label,
  icon,
  isUserMenu,
  target,
}: AccordionSubMenuLinkProps) {
  const { updateNavPanelVisibility } = useMainNavigation();

  function handleLinkClick(evt: MouseEvent) {
    updateNavPanelVisibility(false);
    evt.stopPropagation();
  }

  return (
    <S.SubMenuLink
      to={href}
      onClick={handleLinkClick}
      $isUserMenu={isUserMenu}
      target={target}
    >
      {label} {icon ? icon : null}
    </S.SubMenuLink>
  );
}
