import { useState } from 'react';
import { ArrowCircleRight } from '@la/ds-ui-components';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { DestinationRolloverDropzone } from './RolloverDropzone/DestinationRolloverDropzone';
import { OriginRolloverDropzone } from './RolloverDropzone/OriginRolloverDropzone';
import * as S from './RolloverWizard.styles';

export type RolloverWizardProps = {
  /**
   * Team roster the user is rolling members to.
   */
  destinationTeamRoster: TeamRoster;
  /**
   * When the user clicks a trigger for the team selection modal.
   */
  onTeamSelectionClick: () => void;
  /**
   * Team roster the user is rolling members from.
   */
  selectedTeamRoster?: TeamRoster;
};

export function RolloverWizard({
  destinationTeamRoster,
  onTeamSelectionClick,
  selectedTeamRoster,
}: Readonly<RolloverWizardProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const [newRoster] = useState<TeamRoster>({
    team: destinationTeamRoster.team,
    players: [],
    staff: [],
  });
  const [destinationDropzoneIsHovered, setDestinationDropzoneIsHovered] =
    useState<boolean>(false);

  const onOriginDragEnd = (): void => {
    setDestinationDropzoneIsHovered(false);
  };

  const onOriginDragStart = (): void => {
    setDestinationDropzoneIsHovered(true);
  };

  return (
    <S.RolloverWizard>
      <OriginRolloverDropzone
        onDragEnd={onOriginDragEnd}
        onDragStart={onOriginDragStart}
        onTeamSelectionClick={onTeamSelectionClick}
        teamRoster={selectedTeamRoster}
      />
      <S.RolloverButton
        leftIcon={
          <ArrowCircleRight fill="var(--secondary-600)" variant="bold" />
        }
        size="xl"
        variant="outline"
        width={isTabletPortaitUp ? 'auto' : '100%'}
      >
        Rollover
      </S.RolloverButton>
      <DestinationRolloverDropzone
        showHoveredState={destinationDropzoneIsHovered}
        teamRoster={newRoster}
      />
    </S.RolloverWizard>
  );
}
