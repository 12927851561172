import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const SideMenu = styled.div`
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  width: 24%;
  position: sticky;

  ${media.TabletPortraitDown`
    display: none;
  `}
`;

export const MenuItem = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    fontFamily: 'var(--secondary-font-family)',
    fontSize: 'var(--ui-size-xtra-large)',
    fontStyle: 'normal',
    fontWeight: 'var(--ui-weight-semi-bold)',
    lineHeight: 'var(--headline-size-medium)',
    letterSpacing: '0.15px',
    padding: '8px',
    borderRadius: '4px',
    color: '#78909c',
    cursor: 'pointer',

    '&.active': {
      color: 'var(--blue-grey-900, #263238)',
      cursor: 'default',
      backgroundColor: theme.semantic?.primary[35],
      borderRight: `4px solid ${theme.semantic?.primary.base}`,
    },
  })
);
