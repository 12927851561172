export const tagHeight = {
  small: '24px',
  medium: '28px'
};
export const tagPadding = {
  small: '4px 8px',
  medium: '6px 6px'
};
export const generateSelectableTagDynamicStyles = (theme, state, readOnly = false, dangerState = false) => {
  const isDynamic = !!theme.semantic;
  const {
    foundational: {
      blueGrey,
      green,
      red,
      white
    }
  } = theme;

  // When in a readonly state the action button styles are not affected
  const dangerStyled = dangerState && !readOnly;
  const borderColor = {
    default: dangerStyled ? red[500] : blueGrey[400],
    hover: dangerStyled ? red[500] : isDynamic ? blueGrey[800] : green[800],
    active: dangerStyled ? red[700] : isDynamic ? blueGrey[900] : green[900],
    disabled: blueGrey[200]
  };
  const color = {
    default: dangerStyled ? red[500] : blueGrey[900],
    hover: dangerStyled ? red[500] : isDynamic ? blueGrey[800] : green[800],
    active: dangerStyled ? red[700] : isDynamic ? blueGrey[900] : green[900],
    disabled: blueGrey[200]
  };
  return {
    borderColor: readOnly ? blueGrey[50] : borderColor[state],
    backgroundColor: state === 'active' || readOnly ? blueGrey[50] : white,
    color: color[state]
  };
};
export const generateDismissButtonDynamicStyles = (theme, state, readOnly = false) => {
  const {
    foundational: {
      blueGrey,
      red,
      white
    }
  } = theme;
  const backgroundColor = {
    default: blueGrey[50],
    hover: red[500],
    active: red[700],
    disabled: blueGrey[50]
  };
  const borderColor = {
    default: readOnly ? blueGrey[50] : blueGrey[400],
    hover: red[500],
    active: red[700],
    disabled: blueGrey[50]
  };
  const fill = {
    default: blueGrey[900],
    hover: white,
    active: white,
    disabled: blueGrey[400]
  };
  return {
    borderColor: borderColor[state],
    backgroundColor: backgroundColor[state],
    fill: fill[state],
    outlineColor: red[500]
  };
};