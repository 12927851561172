import { NavLink as Nav_Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const NavItem = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  padding: 0;
  white-space: nowrap;

  &:hover {
    outline: none;
  }
`;

export const NavLink = styled(Nav_Link)`
  display: inline;
  height: 34px;
  font: var(--ui-large-medium);
  line-height: 34px;
  color: var(--blue-grey-900);
  text-decoration: none;
  padding: 0px 3px;
  border: 1px solid transparent;
  border-width: 1px 0;

  ${NavItem}:hover & {
    border: 1px solid var(--primary);
    border-width: 1px 0;
  }

  &:focus {
    border: solid var(--primary);
    border-width: 1px 0;
    color: var(--blue-grey-900);
    outline: none;
  }

  &.active {
    border-bottom: 1px solid var(--primary);
  }

  &.active + button {
    border-bottom: 1px solid var(--primary);
  }
`;

export const NavMenuButton = styled.button`
  height: 34px;
  padding-top: 5px;
  background: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-width: 1px 0;

  ${NavItem}:hover & {
    border: 1px solid var(--primary);
    border-width: 1px 0;
  }

  &:focus {
    border: 1px solid var(--primary);
    border-width: 1px 0;
    outline: none;
  }

  &:active {
    border-bottom: 1px solid var(--primary);
  }

  svg {
    width: 11px;
    padding-bottom: 3px;
    fill: var(--blue-grey-900);
  }
`;
