import { Typography } from '@la/ds-ui-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { RolloverTeam, RolloverTeamType } from './RolloverTeam/RolloverTeam';
import * as S from './RolloverDropzone.styles';

export const HOVERED_STATE_TEXT = 'Drop players and staff here.';

export type DestinationRolloverDropzoneProps = {
  /**
   * Whether or not to display the hovered state of the dropzone. Should use when
   * a member is currently being dragged.
   */
  showHoveredState?: boolean;
  /**
   * Team roster the user is rolling members from. Cannot be undefined.
   */
  teamRoster: TeamRoster;
};

/* DestinationRolloverDropzone */
export function DestinationRolloverDropzone({
  showHoveredState,
  teamRoster,
}: DestinationRolloverDropzoneProps) {
  return (
    <S.Dropzone $hasSelectedTeam $isHovered={showHoveredState}>
      {showHoveredState ? (
        <Typography size="large" variant="ui" weight="bold">
          {HOVERED_STATE_TEXT}
        </Typography>
      ) : (
        <RolloverTeam
          teamRoster={teamRoster}
          type={RolloverTeamType.Destination}
        />
      )}
    </S.Dropzone>
  );
}
/* */
