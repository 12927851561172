import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

export const BannerContainer = styled.div<ThemedComponent>`
  width: 100%;
  height: 56px;
  padding: 16px;
  background-color: ${({ theme }: ThemedComponent) =>
    theme.foundational.green[25]};
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }: ThemedComponent) => theme.palette.success.dark};
  margin-top: 24px;
  grid-column-start: 1;
  grid-column-end: -1;

  & p {
    margin-left: 12px;
    flex: 1;
  }
  & svg {
    fill: ${({ theme }: ThemedComponent) => theme.foundational.green[500]};
  }
`;

export const CloseIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    cursor: pointer;
  }
`;
