import { ChangeEvent, useState } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import TextField from 'components/TextField/TextField';
import {
  ActivityFormWrapperScreens,
  useActivityForm,
} from '../ActivityContext';
import { REQUEST_DELAY } from '../utils/ActivityForm.utils';
import { updateActivityHandler } from '../utils/ActivityFormSubmit.utils';
import * as S from './NotificationEditActivity.styles';

type NotificationEditActivityProps = {
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
  originRoute: string;
};

export function NotificationEditActivity({
  originRoute,
  handleSetCurrentScreen,
}: NotificationEditActivityProps) {
  const { updateActivityData, handleSetSubmittedForm } = useActivityForm();
  const handleDiscardChanges = () => {
    handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
  };
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleEditActivity = async (notify: boolean) => {
    if (!updateActivityData) {
      return;
    }
    let requestData = {
      ...updateActivityData,
    };

    if (notify) {
      requestData = {
        ...updateActivityData,
        notify: notificationMessage,
      };
    }

    handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);

    return await updateActivityHandler(requestData)
      .then((data) => {
        const activity = data.activities ?? [data];
        setTimeout(() => {
          handleSetSubmittedForm({
            activity,
            submitType: 'updated',
            willNotify: notify,
          });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        }, REQUEST_DELAY);
      })
      .catch((e) => {
        const errorMessage = typeof e === 'string' ? e : e.message;
        handleSetSubmittedForm({ errorMessage });
        handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
      });
  };

  const onMessageChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNotificationMessage(e.target.value);
  };

  return (
    <>
      <S.TextContainer>
        <Typography variant="ui" size="large" weight="bold">
          Would you like to send an activity change notification to the
          participants involved?
        </Typography>{' '}
        <Typography variant="ui" size="large">
          Your changes have not been saved yet.
        </Typography>
      </S.TextContainer>
      <TextField
        fullWidth
        id="notification"
        label="Notification Message"
        multiline
        name="notification"
        onChange={onMessageChange}
        placeholder="Add your optional notification message here"
      />

      <S.FormButtons>
        <S.StyledTextButton
          onClick={handleDiscardChanges}
          variant="text"
          size="large"
          className="no-left-padding"
        >
          Discard Changes
        </S.StyledTextButton>
        <S.Flex>
          <Button
            variant="outline"
            size="large"
            onClick={() => handleEditActivity(false)}
          >
            Save & Don't Send
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={() => handleEditActivity(true)}
          >
            Save & Send
          </Button>
        </S.Flex>
      </S.FormButtons>
    </>
  );
}
