"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setOptionsAsSelected = exports.CheckboxGroupFormComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("./CheckboxGroupFormComponent.styles");
function CheckboxGroupFormComponent(_a) {
    var checkboxGroupData = _a.checkboxGroupData, onChangeValue = _a.onChangeValue, value = _a.value;
    var hasError = checkboxGroupData.hasError, groupName = checkboxGroupData.name, isRequired = checkboxGroupData.isRequired;
    var selectedValueArray = value ? value.split(',') : [];
    var checkboxGroupOptions = setOptionsAsSelected(selectedValueArray, checkboxGroupData === null || checkboxGroupData === void 0 ? void 0 : checkboxGroupData.options);
    function handleCheckboxChange(selected, value) {
        if (selected) {
            selectedValueArray.push(value);
        }
        else {
            var selectedValueIdx = selectedValueArray.indexOf(value);
            selectedValueArray.splice(selectedValueIdx, 1);
        }
        var updatedValue = selectedValueArray.join();
        onChangeValue(updatedValue);
    }
    var checkboxes = checkboxGroupOptions.map(function (_a, idx) {
        var label = _a.label, name = _a.name, selected = _a.selected, value = _a.value;
        return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Checkbox, { ariaLabel: label, checked: selected, id: "".concat(name, "-").concat(value), label: label, onCheckedChange: function (isSelected) {
                handleCheckboxChange(isSelected, value);
            }, size: "large" }, "".concat(name, "-").concat(value)));
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.InputLabel, __assign({ htmlFor: groupName, inputState: hasError ? 'error' : 'default', required: isRequired }, { children: checkboxGroupData.label })), (0, jsx_runtime_1.jsx)(S.CheckboxGroup, { children: checkboxes })] }));
}
exports.CheckboxGroupFormComponent = CheckboxGroupFormComponent;
function setOptionsAsSelected(selectedValueArray, options) {
    if (options === void 0) { options = []; }
    return options.map(function (option) {
        return selectedValueArray.includes(option.value)
            ? __assign(__assign({}, option), { selected: true }) : __assign(__assign({}, option), { selected: false });
    });
}
exports.setOptionsAsSelected = setOptionsAsSelected;
