import styled from 'styled-components';
import { Typography } from 'components/Typography/Typography';
export const FieldsetContainer = styled.fieldset.withConfig({
  componentId: "sc-17j07wg-0"
})(({
  theme,
  $hasHorizontalRule,
  $size,
  $hasGutters
}) => {
  const {
    palette,
    spacing
  } = theme;
  const marginTop = $size === 'medium' ? spacing.xxl : '36px';
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    border: 'none',
    borderTop: $hasHorizontalRule ? '1px solid' : 'none',
    borderColor: palette.foundational.blueGrey[150],
    marginTop: $hasGutters ? marginTop : 0
  };
});
export const FieldsetControlsContainer = styled.div.withConfig({
  componentId: "sc-17j07wg-1"
})(({
  theme,
  $isHorizontal,
  $size
}) => {
  const {
    spacing
  } = theme;
  const verticalMargin = $size === 'medium' ? spacing.xxs : spacing.xs;
  return {
    flex: 1,
    display: 'flex',
    flexDirection: $isHorizontal ? 'row' : 'column',
    flexWrap: 'wrap',
    rowGap: $size === 'medium' ? spacing.small : spacing.large,
    columnGap: $size === 'medium' ? spacing.xxs : spacing.small,
    margin: `${verticalMargin} 0`
  };
});
export const FieldsetHeader = styled(Typography).withConfig({
  componentId: "sc-17j07wg-2"
})(({
  theme,
  $size,
  $hasHorizontalRule
}) => {
  const {
    spacing
  } = theme;
  if (!$hasHorizontalRule) {
    return {};
  }
  return {
    marginTop: $size === 'medium' ? spacing.large : spacing.xl,
    marginBottom: $size === 'medium' ? spacing.xxs : spacing.small
  };
});