import { FileUploadFormField, NonFileUploadFormField } from '@la/types';
import { GroupAccount } from 'redux/services/groupAccountApi';

export type RegistrantState = {
  nonFileUploadFormFields: Record<number, NonFileUploadFormField>;
  fileUploadFormFields: Record<number, FileUploadFormField>;
  groupAccount: GroupAccount | null;
  signedWaivers: Record<string, boolean>;
  selectedPlayer: string;
  existingRegistrationError: string;
};

export type RegistrantStateActions =
  | {
      type: 'SET_NON_FILE_UPLOAD_FORM_FIELD';
      payload: {
        id: number;
        field: NonFileUploadFormField;
      };
    }
  | {
      type: 'SET_FILE_UPLOAD_FORM_FIELD';
      payload: {
        id: number;
        field: FileUploadFormField;
      };
    }
  | { type: 'SET_GROUP_ACCOUNT'; payload: GroupAccount | null }
  | {
      type: 'SET_GROUP_ACCOUNT_AND_SELECTED_PLAYER';
      payload: GroupAccount | null;
    }
  | {
      type: 'SET_SIGNED_WAIVERS';
      payload: {
        id: string;
        signed?: boolean;
      };
    }
  | { type: 'SET_SELECTED_PLAYER'; payload: string }
  | { type: 'SET_EXISTING_REGISTRATION_ERROR'; payload: string };

export const registrantInitialState: RegistrantState = {
  nonFileUploadFormFields: {},
  fileUploadFormFields: {},
  groupAccount: null,
  signedWaivers: {},
  selectedPlayer: '',
  existingRegistrationError: '',
};

export function registrantReducer(
  state = registrantInitialState,
  action: RegistrantStateActions
): RegistrantState {
  switch (action.type) {
    case 'SET_NON_FILE_UPLOAD_FORM_FIELD': {
      return {
        ...state,
        nonFileUploadFormFields: {
          ...state.nonFileUploadFormFields,
          [action.payload.id]: action.payload.field,
        },
      };
    }
    case 'SET_FILE_UPLOAD_FORM_FIELD': {
      return {
        ...state,
        fileUploadFormFields: {
          ...state.fileUploadFormFields,
          [action.payload.id]: action.payload.field,
        },
      };
    }
    case 'SET_GROUP_ACCOUNT': {
      return {
        ...state,
        groupAccount: action.payload,
      };
    }
    case 'SET_GROUP_ACCOUNT_AND_SELECTED_PLAYER': {
      const groupAccount = action.payload;
      const members = groupAccount?.members;

      // The last member is the array is the most recently added
      const lastMemberId = members
        ? members[members.length - 1].user.id.toString()
        : '';

      return {
        ...state,
        groupAccount: action.payload,
        selectedPlayer: lastMemberId,
      };
    }
    case 'SET_SIGNED_WAIVERS': {
      const { id, signed } = action.payload;
      return {
        ...state,
        signedWaivers: {
          ...state.signedWaivers,
          [id]: signed === undefined ? !state.signedWaivers[id] : signed,
        },
      };
    }

    case 'SET_SELECTED_PLAYER':
      return {
        ...state,
        selectedPlayer: action.payload,
      };

    case 'SET_EXISTING_REGISTRATION_ERROR':
      return {
        ...state,
        existingRegistrationError: action.payload,
      };
    default:
      throw new Error('Invalid action');
  }
}
