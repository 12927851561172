"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamRegistrationReducer = exports.teamRegistrationInitialState = void 0;
exports.teamRegistrationInitialState = {
    payer: null,
    wizardState: null,
    divisions: null,
    isMC: true,
    selectedPaymentOption: null,
    paymentPricing: null,
};
function teamRegistrationReducer(state, action) {
    if (state === void 0) { state = exports.teamRegistrationInitialState; }
    switch (action.type) {
        case 'SET_PAYER': {
            return __assign(__assign({}, state), { payer: action.payload.payer });
        }
        case 'SET_WIZARD_STATE': {
            return __assign(__assign({}, state), { wizardState: action.payload.wizardState });
        }
        case 'SET_DIVISIONS': {
            return __assign(__assign({}, state), { divisions: action.payload.divisions });
        }
        case 'SET_SELECTED_PAYMENT_OPTION': {
            return __assign(__assign({}, state), { selectedPaymentOption: action.payload.optionId });
        }
        case 'SET_PAYMENTS_PRICING': {
            return __assign(__assign({}, state), { paymentPricing: action.payload.paymentsPricing });
        }
    }
}
exports.teamRegistrationReducer = teamRegistrationReducer;
