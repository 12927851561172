import { useEffect, useRef, useState } from 'react';
import { getUserIsAuthenticated } from 'lib/auth/auth';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { useUser } from 'lib/context/UserContext';
import useBreakpoint from 'lib/hooks/useBreakpoint';
import { getSiteIdentityData } from 'redux/coreSlice';
import { clearStateFromLocalStorage } from 'redux/localStorage';
import { useGetUserInfoQuery } from 'redux/services/userInfo';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { resetUserInfo, setUserInfo } from 'redux/userSlice';
import UserMenus from '../HeaderMenuButton/UserMenus';
import MainNavigation from '../MainNavigation/MainNavigation';
import SiteLogo from '../SiteLogo/SiteLogo';
import * as S from './Header.styles';

/* Header */
export default function Header() {
  const dispatch = useAppDispatch();
  const { logoImageSrc, logoHref, siteId, siteName } =
    useAppSelector(getSiteIdentityData);
  const [minNavBarWidth, setMinNavBarWidth] = useState<number | null>(null);
  const breakpoint = useBreakpoint();
  const { updateIsMobileBreakpoint, updateIsNavBarTooWide, visibleNavMenu } =
    useMainNavigation();
  const { updateUserHasLIPToken, updateUserHasProfileIdForSite } = useUser();
  const header = useRef<HTMLDivElement>(null);
  const { data: userInfoData, error: userInfoError } =
    useGetUserInfoQuery(siteId);

  useEffect(() => {
    if (
      userInfoError &&
      'status' in userInfoError &&
      userInfoError.status === 403
    ) {
      dispatch(resetUserInfo());
      clearStateFromLocalStorage('app-store');
      updateUserHasProfileIdForSite(false);
    }

    if (userInfoData?.user) {
      dispatch(setUserInfo(userInfoData.user));
      updateUserHasProfileIdForSite(true);
    }
  }, [userInfoData, dispatch, userInfoError, updateUserHasProfileIdForSite]);

  useEffect(() => {
    const isUserAuthenticated = getUserIsAuthenticated();
    updateUserHasLIPToken(isUserAuthenticated);
  }, [updateUserHasLIPToken]);

  useEffect(() => {
    if (header.current) {
      if (minNavBarWidth) {
        updateIsNavBarTooWide(header.current.offsetWidth < minNavBarWidth);
      } else if (
        !visibleNavMenu &&
        header.current.offsetWidth < header.current.scrollWidth
      ) {
        setMinNavBarWidth(header.current.scrollWidth);
      }
    }
  }, [minNavBarWidth, updateIsNavBarTooWide, visibleNavMenu]);

  useEffect(() => {
    if (
      breakpoint === 'Phone' ||
      breakpoint === 'TabletPortrait' ||
      breakpoint === 'TabletLandscape'
    ) {
      updateIsMobileBreakpoint(true);
    } else {
      updateIsMobileBreakpoint(false);
    }
  }, [breakpoint, updateIsMobileBreakpoint]);

  return (
    <S.Header ref={header}>
      <HeaderTopRow siteName={siteName} />
      <HeaderBottomRow
        siteLogoImageSrc={logoImageSrc}
        siteName={siteName}
        siteLogoHref={logoHref}
      />
    </S.Header>
  );
}
/* */

/* HeaderTopRow */
function HeaderTopRow({ siteName }: { siteName: string }) {
  const { isNavigationCollapsed } = useMainNavigation();

  return (
    <S.HeaderTopRow>
      <S.SiteName>{siteName}</S.SiteName>
      <S.LAHeaderLogo>
        <S.LA_L_Logo />
        LeagueApps
      </S.LAHeaderLogo>
      {!isNavigationCollapsed ? (
        <>
          <S.VerticalDivider tabIndex={-1} />
          <UserMenus />
        </>
      ) : null}
    </S.HeaderTopRow>
  );
}
/* */

/*HeaderBottomRow */
function HeaderBottomRow({
  siteLogoImageSrc,
  siteName,
  siteLogoHref,
}: {
  siteLogoImageSrc?: string;
  siteName: string;
  siteLogoHref?: string;
}) {
  return (
    <S.HeaderBottomRow>
      <SiteLogo
        siteLogoImageSrc={siteLogoImageSrc}
        siteName={siteName}
        siteLogoHref={siteLogoHref}
      />
      <MainNavigation
        siteLogoImageSrc={siteLogoImageSrc}
        siteName={siteName}
        siteLogoHref={siteLogoHref}
      />
    </S.HeaderBottomRow>
  );
}
/* */
