"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnGrid = void 0;
var styled_components_1 = require("styled-components");
var mixins_1 = require("../../lib/media-queries/mixins");
exports.ColumnGrid = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-auto-rows: min-content;\n  column-gap: 16px;\n  max-width: 1440px;\n  width: 100%;\n  // padding: 0 16px;\n\n  ", "\n\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-auto-rows: min-content;\n  column-gap: 16px;\n  max-width: 1440px;\n  width: 100%;\n  // padding: 0 16px;\n\n  ", "\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    grid-template-columns: repeat(8, 1fr);\n  "], ["\n    grid-template-columns: repeat(8, 1fr);\n  "]))), mixins_1.media.TabletLandscapeUp(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    grid-template-columns: repeat(16, 1fr);\n  "], ["\n    grid-template-columns: repeat(16, 1fr);\n  "]))));
var templateObject_1, templateObject_2, templateObject_3;
