import styled from 'styled-components';
import { getButtonWidth } from 'theming/tokens/components/button';
import { ButtonBaseStyles, ButtonFilledStyles, ButtonOutlineStyles, ButtonTextStyles } from 'components/Button/Button.styles';
export const LinkWrapper = styled.div.withConfig({
  componentId: "sc-18reas6-0"
})(({
  theme,
  $isInline,
  $isBold,
  $variant,
  $hasPadding,
  $width
}) => {
  return {
    width: getButtonWidth($width),
    display: $isInline ? 'inline' : 'block',
    '& > a': {
      display: $isInline ? 'inline' : undefined,
      fontWeight: $isInline && !$isBold ? 400 : undefined,
      color: $isInline && $variant === 'text' && !$hasPadding ? theme.foundational.green[600] : undefined
    }
  };
});
export const LinkBase = styled.a.withConfig({
  componentId: "sc-18reas6-1"
})(() => ButtonBaseStyles);
export const LinkFilled = styled(LinkBase).withConfig({
  componentId: "sc-18reas6-2"
})(() => ButtonFilledStyles);
export const LinkOutline = styled(LinkBase).withConfig({
  componentId: "sc-18reas6-3"
})(() => ButtonOutlineStyles);
export const LinkText = styled(LinkBase).withConfig({
  componentId: "sc-18reas6-4"
})(() => ButtonTextStyles);