import { useState } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import { getLAHostnameParts } from '@la/utilities';
import { ChildYouthAthleteAccess } from '../YouthAthleteAccess.types';
import * as S from '../EditMobileAppAccess.styles';

interface YouthAthleteConfirmationSectionProps
  extends Omit<ChildYouthAthleteAccess, 'siteId' | 'youthAthleteId'> {}
export default function YouthAthleteConfirmationSection({
  firstName,
  userId,
}: YouthAthleteConfirmationSectionProps) {
  // Copy Button Text Logic
  const initialButtonText = 'Copy sign up link';
  let [buttonText, setButtonText] = useState(initialButtonText);

  const onClickHandler = () => {
    const { subdomain, sld } = getLAHostnameParts();
    const linkUrl = `https://${subdomain}.${sld}.com/youthAthleteJoin?ngmp_2023_iframe_transition=1&memberId=${userId}`;
    window.navigator.clipboard.writeText(linkUrl);
    setButtonText('Copied!');
    window.setTimeout(() => setButtonText(initialButtonText), 1000);
  };

  return (
    <S.MobileAppAccessSection>
      <Typography variant="headline" size="medium">
        Copy link to share with {firstName}
      </Typography>
      <Typography variant="ui" size="xl">
        Copy the link to share with this child so they can join the mobile app.
      </Typography>
      <Button size="large" variant="primary" onClick={onClickHandler}>
        {buttonText}
      </Button>
    </S.MobileAppAccessSection>
  );
}
