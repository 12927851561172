import { CartItemData } from 'domains/Checkout/Checkout.types';
import baseApi from './baseApi';

export type UpdateRegistrationsPayload = {
  programId: string;
  teams: { id: string; name: string }[];
};

export const registrationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRegistrations: builder.mutation<
      CartItemData,
      {
        siteDomain: string;
        programId: string;
        editURL: string;
        registrations: UpdateRegistrationsPayload[];
        includeRegistrationTriageFix?: boolean;
      }
    >({
      query: ({
        siteDomain,
        programId,
        editURL,
        registrations,
        includeRegistrationTriageFix,
      }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registration`,
          method: 'POST',
          body: {
            programId,
            editURL,
            registrations,
            includeRegistrationTriageFix,
          },
        };
      },
      invalidatesTags: ['CartSummary', 'Registration'],
    }),
  }),
});

export const { useUpdateRegistrationsMutation } = registrationApi;
