import { http, HttpResponse } from 'msw';
import { mockCartSummaryData } from 'domains/Checkout/__dev__/mockValues_Checkout';

export const cartHandlers = [
  // Get cart items
  http.get('*/api/cart/sites/*/users/*/cart/summary', () => {
    return HttpResponse.json(mockCartSummaryData, { status: 200 });
  }),
  // Remove cart item
  http.delete(`*/api/cart/sites/*/users/*/cart/items/*/delete`, () => {
    return HttpResponse.json({}, { status: 204 });
  }),
];
