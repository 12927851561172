import { http, HttpResponse } from 'msw';

export enum RegistrationEndpoint {
  UpdateRegistrations = '*/api/member-portal/*/registration',
}

export const registrationHandlers = [
  http.post(RegistrationEndpoint.UpdateRegistrations, () => {
    return HttpResponse.json({}, { status: 201 });
  }),
];
