import { PropsWithChildren, useState } from 'react';
import { CloseIcon } from '@la/ds-ui-components';
import * as S from './Callout.styles';

/**
 * NOTE: This is not intended to be a permanent implementation of this
 * component. It has been developed as a one-off because there are currently
 * a few similar components in use in the Management Console but not yet in
 * the Design System. -DC 1/26/2024
 */
export default function Callout({ children }: PropsWithChildren) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return isVisible ? (
    <S.Callout>
      <S.CalloutIcon fill="#3679FA" size="xl" variant="bold" />
      {children}
      <S.CloseIconWrapper onClick={() => setIsVisible(false)}>
        <CloseIcon fill="#263238" size="medium" variant="bold" />
      </S.CloseIconWrapper>
    </S.Callout>
  ) : null;
}
