import { PropsWithChildren } from 'react';
import { ReactiveTypography } from 'components/ReactiveTypography/ReactiveTypography';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';

export function MainContentHeaderTitle(props: PropsWithChildren) {
  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  return (
    <ReactiveTypography
      background="primary"
      customTag="h1"
      variant="headline"
      size={isTabletLandscapeUp ? 'xl' : 'medium'}
      {...props}
    />
  );
}
