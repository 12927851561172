// Measurements are in pixels - start with numbers so that one size can depend on another

const bigDesktop = {
  min: 1500
};
const desktop = {
  min: 1200,
  max: bigDesktop.min - 1
};
const tabletLandscape = {
  min: 900,
  max: desktop.min - 1
};
const tabletPortrait = {
  min: 600,
  max: tabletLandscape.min - 1
};
const phone = {
  max: tabletPortrait.min - 1
};

// Convert to pixels

function toPx(value) {
  return `${value}px`;
}
const bigDesktopPx = {
  min: toPx(bigDesktop.min)
};
const desktopPx = {
  min: toPx(desktop.min),
  max: toPx(desktop.max)
};
const tabletLandscapePx = {
  min: toPx(tabletLandscape.min),
  max: toPx(tabletLandscape.max)
};
const tabletPortraitPx = {
  min: toPx(tabletPortrait.min),
  max: toPx(tabletPortrait.max)
};
const phonePx = {
  max: toPx(phone.max)
};
export const breakpointQueries = {
  phoneOnly: `(max-width: ${phonePx.max})`,
  tabletPortraitUp: `(min-width: ${tabletPortraitPx.min})`,
  tabletPortraitDown: `(max-width: ${tabletPortraitPx.max})`,
  tablePortraitOnly: `(min-width: ${tabletPortraitPx.min}) and (max-width: ${tabletPortraitPx.max})`,
  tabletLandscapeUp: `(min-width: ${tabletLandscapePx.min})`,
  tabletLandscapeDown: `(max-width: ${tabletLandscapePx.max})`,
  tabletLandscapeOnly: `(min-width: ${tabletLandscapePx.min}) and (max-width: ${tabletLandscapePx.max})`,
  desktopUp: `(min-width: ${desktopPx.min})`,
  desktopDown: `(max-width: ${desktopPx.max})`,
  desktopOnly: `(min-width: ${desktopPx.min}) and (max-width: ${desktopPx.max})`,
  bigDesktopOnly: `(min-width: ${bigDesktopPx.min})`
};