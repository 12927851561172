import * as S from './LoadingRipple.styles';

export type LoadingRippleProps = {
  message?: string;
};

/* LoadingRipple */
export function LoadingRipple({ message }: LoadingRippleProps) {
  return (
    <S.LoadingBackdrop>
      <S.LoadingRippleAnimation $hasMessage={!!message} />
      {message ? <S.LoadingMessage>{message}</S.LoadingMessage> : null}
    </S.LoadingBackdrop>
  );
}
/* */
