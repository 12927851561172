import { useEffect } from 'react';
import { Loader } from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetCartSummaryQuery } from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import CartSummaryBody from './CartSummaryBody/CartSummaryBody';
import * as S from './CartSummary.styles';

/* CartSummary */
export default function CartSummary() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: userId } = useGetUserIdQuery(siteId);
  const skip = !userId || !siteId;
  const { data, isLoading, isUninitialized, error } = useGetCartSummaryQuery(
    {
      siteId,
      userId,
    },
    { skip }
  );

  useEffect(() => {
    document.title = siteName ? `${siteName}: Cart Summary` : 'Cart Summary';
  }, [siteName]);

  return (
    <S.CartSummary id="cart-summary-page">
      {isLoading || isUninitialized ? (
        <Loader
          loading
          description="We are loading your cart."
          isFullscreen={true}
        />
      ) : (
        <>
          <PageTitle>Cart Summary</PageTitle>
          <CartSummaryBody cartSummaryData={data} error={error} />
        </>
      )}
    </S.CartSummary>
  );
}
/* */
