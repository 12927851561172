import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';
import { Card } from 'components/Card/Card';
import { media } from 'lib/media-queries/mixins';

export const TransactionConfirmationCard = styled(Card)`
  grid-column-start: 1;
  grid-column-end: 5;
  align-self: start;

  ${media.TabletPortraitUp`
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
  ${media.TabletLandscapeUp`
    grid-column-start: 9;
    grid-column-end: 17;
  `}
  `}
`;

export const TransactionConfirmationCardContents = styled.div`
  padding: 16px 16px 12px;

  > * + * {
    margin-top: 24px;
  }

  p {
    margin-left: 8px;
    margin-right: 8px;
  }

  ${media.TabletLandscapeUp`
    padding: 16px;
    
    p {
      margin-left: 4px;
      margin-right: 4px;
  `}
`;

export const TransactionConfirmationCardActions = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.mediaQueries.mobile]: {
      flexDirection: 'row',
    },
  })
);

export const HotelLink = styled.div`
  & > div,
  a {
    display: inline-flex;
  }
`;
