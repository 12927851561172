import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { ErrorResponse } from './verifyUrlSignature';

export type GroupAccountUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: 'adult' | 'child';
};

export type Member = {
  role: string;
  user: GroupAccountUser;
};

export type GroupAccount = {
  id: number;
  description: string;
  members: Member[];
};

export type GroupAccountParameters = {
  siteId: string;
};

const baseUrl = getBaseURL();

const getGroupAccount = async ({
  siteId,
}: GroupAccountParameters): Promise<GroupAccount | null> => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/groupAccount`;
  const token = extractAccessToken();
  try {
    const response = await axios.get<GroupAccount>(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    if ((e as ErrorResponse).response.status === 404) {
      return null;
    }
    throw e;
  }
};

export { getGroupAccount };
