import { ReactNode, useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

export type PortalProps = {
  children: ReactNode;
  parentId: string; // This is actually any valid CSS identifier
};

export default function Portal({ children, parentId }: PortalProps) {
  const [parentElement, setParentElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.querySelector(parentId) as HTMLElement;
    setParentElement(element);
  }, [parentId]);

  // parentElement state will be null on very first render.
  return parentElement !== null ? createPortal(children, parentElement) : null;
}
