import styled from 'styled-components';
import { ReactComponent as RadioChecked } from 'assets/icons/radio_checked.svg';
import { ReactComponent as RadioCircle } from 'assets/icons/radio_circle.svg';

export const UncheckedIcon = styled(RadioCircle)<{
  $iconColor: string | null;
}>`
  user-select: none;
  width: 18px;
  height: 18px;
  fill: ${({ $iconColor }) => ($iconColor ? $iconColor : 'var(--white)')};
`;

export const CheckedIcon = styled(RadioChecked)<{
  checked: boolean;
  $iconColor: string | null;
}>`
  user-select: none;
  width: 10px;
  height: 10px;
  fill: ${({ $iconColor, checked }) =>
    checked ? ($iconColor ? $iconColor : 'var(--white)') : 'transparent'};
  left: 4px;
  top: 4px;
  position: absolute;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  min-height: 24px;
  border: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
`;

export const Radio = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
`;

export const RadioLabel = styled.label`
  font: var(--ui-large-medium);
  color: var(--secondary-900);
  margin-left: 12px;
`;

export const RadioIconContainer = styled.span`
  position: relative;
  display: flex;
`;
