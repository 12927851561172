// https://stackoverflow.com/a/39495173

export const gridSpacing = {
  xxl: '32px',
  xl: '28px',
  large: '24px',
  medium: '20px',
  small: '16px',
  xs: '12px',
  xxs: '8px',
  xxxs: '4px'
};
export const breakpoints = {
  desktop: 1200,
  tablet: 900,
  mobile: 600
};
export const breakpointColumns = {
  desktop: 12,
  tablet: 8,
  mobile: 4
};
export const breakpointConfiguration = {
  desktop: {
    columns: breakpointColumns.desktop,
    horizontalMargin: gridSpacing.large,
    gutters: gridSpacing.small
  },
  tablet: {
    columns: breakpointColumns.tablet,
    horizontalMargin: gridSpacing.large,
    gutters: gridSpacing.small
  },
  mobile: {
    columns: breakpointColumns.mobile,
    horizontalMargin: gridSpacing.small,
    gutters: gridSpacing.small
  }
};
export const mediaQueries = Object.entries(breakpoints).reduce((acc, [breakpoint, width]) => ({
  ...acc,
  [breakpoint]: `@media screen and (min-width: ${width}px)`
}), {});