import styled from 'styled-components/macro';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as IconPerson } from 'assets/icons/icon_person.svg';

export const ArrowDown = styled(IconArrowDown)`
  width: 5px;
  height: 2.75px;
  fill: var(--primary-text-color);
`;

export const PersonIcon = styled(IconPerson)`
  width: 13.5px;
  height: 15px;
  fill: var(--primary-text-color);
`;

export const HeaderMenuButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 2px 8px;
  font: var(--ui-medium-medium);
  color: var(--primary-text-color);
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--secondary);
    outline: none;
    color: var(--secondary-text-color);

    > ${PersonIcon} {
      fill: var(--secondary-text-color);
    }
    > ${ArrowDown} {
      fill: var(--secondary-text-color);
    }
  }
`;

export const UserMenuWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 10px;
`;
