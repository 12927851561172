import axios from 'axios';
import { TeamWithProps } from '@la/types';
import { ErrorResponse } from 'lib/apis/verifyUrlSignature';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const getStaffTeams = async ({
  userId,
  pageNum,
  pageSize,
}: {
  userId: number;
  pageNum?: number;
  pageSize?: number;
}) => {
  try {
    return await axios.get<TeamWithProps[]>(
      `${baseUrl}/api/users/${userId}/teams?role=staff&include=primaryStaff&includeExternal=true&pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${extractAccessToken()}`,
        },
      }
    );
  } catch (e: any) {
    const error: ErrorResponse = {
      response: {
        status: 400,
        data: {
          message: e?.data?.message ?? 'Error retrieving teams.',
        },
      },
    };
    throw error;
  }
};

export { getStaffTeams };
