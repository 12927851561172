import { useState, KeyboardEvent, useEffect } from 'react';
import { Checkbox } from '@la/ds-ui-components';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import CheckoutCard from '../CheckoutCard/CheckoutCard';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import PaymentPolicyModal from '../PaymentPolicyModal/PaymentPolicyModal';
import * as S from './TermsAndConditionsAgreementBox.styles';

export type TermsAndConditionsAgreementBoxProps = {
  policyContent?: string;
  policyName?: string;
};

/* TermsAndConditionsAgreementBox */
export default function TermsAndConditionsAgreementBox({
  policyContent,
  policyName,
}: TermsAndConditionsAgreementBoxProps) {
  const {
    checkoutFieldErrorStates,
    checkoutErrorsAreVisible,
    hasAgreedToPaymentPolicy,
    updateHasAgreedToPaymentPolicy,
    updateHasPaymentWaiver,
  } = useCheckoutInfo();
  const [isOpenPaymentPolicyModal, setIsOpenPaymentPolicyModal] =
    useState<boolean>(false);

  useEffect(() => {
    updateHasPaymentWaiver(true);
  }, [updateHasPaymentWaiver]);

  function openPolicyModal() {
    setIsOpenPaymentPolicyModal(true);
  }

  function closePolicyModal() {
    setIsOpenPaymentPolicyModal(false);
  }

  function handleKeyDownTermsModal(
    evt: KeyboardEvent<HTMLButtonElement>
  ): void {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      openPolicyModal();
    }
  }

  const handleCheckbox = (checked: boolean) => {
    updateHasAgreedToPaymentPolicy(checked);
  };

  const isShowingErrorMessage =
    checkoutFieldErrorStates.hasPaymentPolicyError && checkoutErrorsAreVisible;

  return (
    <CheckoutCard
      errorMessage={isShowingErrorMessage ? CHECKOUT_ERRORS.paymentTerms : ''}
    >
      <S.TermsAndConditionsAgreementBox>
        <Checkbox
          ariaLabel="I have read and agree to all Payment and Refund Policies"
          id="terms-condition-agreement"
          checked={hasAgreedToPaymentPolicy}
          onCheckedChange={handleCheckbox}
        />
        <S.Label>
          I have read and agree to all{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={openPolicyModal}
            onKeyDown={handleKeyDownTermsModal}
          >
            Payment and Refund Policies
          </span>
        </S.Label>
      </S.TermsAndConditionsAgreementBox>
      <PaymentPolicyModal
        closeModal={closePolicyModal}
        modalTitle="Payment policies"
        open={isOpenPaymentPolicyModal}
        policyContent={policyContent}
        policyName={policyName}
      />
    </CheckoutCard>
  );
}
/* */
