import { CaretLeftIcon } from '@la/ds-ui-components';
import * as S from '../MainContentHeaderBackButton/MainContentHeaderBackButton.styles';

export type MainContentHeaderBackButtonProps = {
  onClick: () => void;
};

export function MainContentHeaderBackButton({
  onClick,
}: MainContentHeaderBackButtonProps) {
  return (
    <S.MainContentHeaderBackButton
      ariaLabel="Back Button"
      icon={<CaretLeftIcon />}
      onClick={onClick}
      size="xl"
      variant="text"
    />
  );
}
