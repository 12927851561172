"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccessToken = exports.extractAccessToken = exports.hasCookie = exports.getCookie = exports.deleteCookie = exports.requestLIPToken = exports.getUserIsAuthenticated = exports.verifyAuth = void 0;
/*
  if authentication isn't verified this redirects to a login page
*/
function verifyAuth() {
    var isAuthenticated = getUserIsAuthenticated();
    if (!isAuthenticated) {
        requestLIPToken();
        return false;
    }
    return true;
}
exports.verifyAuth = verifyAuth;
function getUserIsAuthenticated() {
    return document.cookie.includes('lip.access-token');
}
exports.getUserIsAuthenticated = getUserIsAuthenticated;
function requestLIPToken() {
    var _a = window.location, host = _a.host, pathname = _a.pathname, search = _a.search;
    var hostParts = host.split('.');
    var requestDomain, requestSubDomain;
    if (hostParts[0].indexOf('la-member-portal') > -1) {
        requestDomain = 'lapps-dev1';
        requestSubDomain = 'svssc';
    }
    else {
        requestDomain = hostParts[hostParts.length - 2];
        requestSubDomain = hostParts[0];
    }
    var redirectToLIPUrl = "https://".concat(requestSubDomain, ".").concat(requestDomain, ".com/login?redirect_uri=").concat(pathname).concat(search);
    window.location.assign(redirectToLIPUrl);
}
exports.requestLIPToken = requestLIPToken;
/*
    Effectively deletes a cookie by setting its expiration to 1/1/1970
   */
function deleteCookie(name, path, requestDomain) {
    if (hasCookie(name)) {
        document.cookie =
            name +
                '=' +
                (path ? ';path=' + path : '') +
                (requestDomain ? ';domain=' + requestDomain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
}
exports.deleteCookie = deleteCookie;
/*
    Extracts an individual cookie by name from all site cookies and returns it
   */
function getCookie(name) {
    if (!hasCookie(name)) {
        return null;
    }
    var c = document.cookie.split(';');
    var idx = c.filter(function (ck) {
        return ck.trim().startsWith(name + '=');
    });
    return idx[0];
}
exports.getCookie = getCookie;
/*
    Verifies that the cookie being looked for exists for this domain
   */
function hasCookie(name) {
    return document.cookie.split(';').some(function (c) {
        return c.trim().startsWith(name + '=');
    });
}
exports.hasCookie = hasCookie;
/**
 * Extracts an access token from the LIP Cookie
 * If the cookie is not present it returns null
 * @returns {string | null}
 */
function extractAccessToken() {
    var LIPCookie = getCookie('lip.access-token');
    return LIPCookie ? LIPCookie.substring(LIPCookie.indexOf('=') + 1) : null;
}
exports.extractAccessToken = extractAccessToken;
/*
    Checks for authentication.
    If not authenticated it redirects to a login page and returns null.
    If authenticated it extracts and returns an access token from the LIP cookie.
   */
function getAccessToken() {
    return verifyAuth() ? extractAccessToken() : null;
}
exports.getAccessToken = getAccessToken;
