"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUniqueStrings = exports.truncateWithEllipsis = exports.decodeHTMLEntities = exports.isValidEmail = exports.capitalizeFirstLetter = exports.convertToSnakeCase = exports.convertToKebabCase = exports.convertToCamelCase = exports.prepareForCaseChange = exports.sortByNameAlphabetically = void 0;
function sortByNameAlphabetically(a, b) {
    return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}
exports.sortByNameAlphabetically = sortByNameAlphabetically;
function prepareForCaseChange(str) {
    return (str
        // lowercase all letters in the string
        .toLocaleLowerCase()
        // replace all punctuation with spaces
        .replace(/([^\w\d\s]|_)/g, ' ')
        // replace repeated spaces with a single space
        .replace(/\s+/g, ' ')
        // remove leading and trailing spaces
        .trim());
}
exports.prepareForCaseChange = prepareForCaseChange;
function convertToCamelCase(str) {
    return (prepareForCaseChange(str)
        // split the string up along any spaces
        .split(' ')
        // in every word after the first make the first letter uppercase
        .map(function (word, idx) {
        var letters = word.split('');
        if (idx !== 0) {
            letters[0] = letters[0].toLocaleUpperCase();
        }
        return letters.join('');
    })
        // rejoin all words into a single string
        .join(''));
}
exports.convertToCamelCase = convertToCamelCase;
function convertToKebabCase(str) {
    return (prepareForCaseChange(str)
        // replace all spaces with hyphens
        .replace(/\s+/g, '-'));
}
exports.convertToKebabCase = convertToKebabCase;
function convertToSnakeCase(str) {
    return (prepareForCaseChange(str)
        // replace all spaces with underscores
        .replace(/\s+/g, '_'));
}
exports.convertToSnakeCase = convertToSnakeCase;
function capitalizeFirstLetter(str) {
    var trimmed = str.trimStart();
    return !trimmed ? str : trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
function isValidEmail(str) {
    return !!str.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
}
exports.isValidEmail = isValidEmail;
function decodeHTMLEntities(text) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}
exports.decodeHTMLEntities = decodeHTMLEntities;
/**
 * Takes up to the first X characters of a string, returning
 * a truncated version of the string with an ellipses at the end.
 */
function truncateWithEllipsis(input, characterLimit) {
    if (input.length <= characterLimit) {
        return input;
    }
    //trimEnd is used to remove trailing white space at end of input
    return input.slice(0, characterLimit - 3).trimEnd() + '...';
}
exports.truncateWithEllipsis = truncateWithEllipsis;
/**
 * Given an array of strings `getUniqueStrings` returns a new array
 * consisting only of unique strings from the original array
 * @param arr
 */
function getUniqueStrings(arr) {
    return Array.from(new Set(arr));
}
exports.getUniqueStrings = getUniqueStrings;
