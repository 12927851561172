export const hasValue = (value: string | undefined | null): boolean => {
  return value !== '' && value !== undefined && value !== null;
};

/**
 * Given a number, returns a string representation of the value in USD.
 *
 * Examples:
 *
 *  displayCentsAsUSD(5.25) => $5.25
 *  displayCentsAsUSD(29999.99) => $29,999.99
 *
 * @param amount A number
 * @returns A string displaying the number in USD
 *
 */
export function formatAsUSD(
  amount: number,
  options?: {
    hideZeroCents?: boolean;
  }
): string {
  if (options?.hideZeroCents && amount % 1 === 0) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

export function getCursorOffsetFromContainer(
  cursorX: number,
  cursorY: number,
  target: HTMLElement
): number[] {
  const boundingRect = target.getBoundingClientRect();
  const deltaX = cursorX - boundingRect.x;
  const deltaY = cursorY - boundingRect.y;
  return [deltaX, deltaY];
}

export function deepObjectCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

/**
 * Sorts a Record alphabetically by value.
 * @param values Key-value pairs
 * @returns Record with key-value pairs in alphabetical order
 */
export function sortRecordByValue(
  values: Record<string, string>
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(values).sort((a, b) => a[1].localeCompare(b[1]))
  );
}

export const genderNameMap = {
  any: 'Any',
  co_ed: 'Co-Ed',
  male: 'Male',
  female: 'Female',
};

export function roundToNearest(val: number, nearest: number) {
  const remainder = val % nearest;
  const valMinusRemainder = val - remainder;
  const roundedRemainder = remainder >= nearest * 0.5 ? nearest : 0;
  return valMinusRemainder + roundedRemainder;
}

export function copyTextToClipboard(
  textToCopy: string,
  successHandler?: () => {},
  errorHandler?: () => {}
) {
  navigator.clipboard.writeText(textToCopy).then(
    () => {
      if (successHandler !== undefined) {
        successHandler();
      }
    },
    () => {
      if (errorHandler !== undefined) {
        errorHandler();
      }
    }
  );
}

export function getFocusableChildren(
  parent: Element | HTMLElement
): HTMLElement[] {
  const children: HTMLElement[] = Array.from(
    parent.querySelectorAll(FOCUS_SELECTOR)
  );
  return children.filter(
    (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  );
}

export function getScrollableParent(el: ParentNode | null): ParentNode {
  if (!el || el === document.body) {
    return document.body;
  } else if (isScrollable(el as HTMLElement)) {
    return el;
  } else {
    return getScrollableParent(el.parentNode);
  }
}

function isScrollable(el: HTMLElement): boolean {
  const hasScrollableContent = el.scrollHeight > el.clientHeight;

  const overflowYStyle = window.getComputedStyle(el).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
}

const FOCUS_SELECTOR =
  'a[href], button, input:not([type="hidden"]), textarea, select, details,[tabindex]:not([tabindex="-1"])';

function isFocusableElement(el: Element | HTMLElement): boolean {
  return (
    el.matches(FOCUS_SELECTOR) &&
    !el.hasAttribute('disabled') &&
    !el.getAttribute('aria-hidden')
  );
}

function findNextFocusableElement(
  els: (Element | HTMLElement)[]
): Element | undefined {
  const activeElementIndex = els.findIndex(
    (el) => el === document.activeElement
  );
  return els.find(
    (el, index) => isFocusableElement(el) && index > activeElementIndex
  );
}

export function trapFocusWithin(event: KeyboardEvent): void {
  const { key, shiftKey } = event;
  const parent = event.currentTarget as Element;

  const currentFocus: Element | null = document.activeElement;

  if (key === 'Tab' && !!parent && !!currentFocus) {
    const hasFocusWithin: boolean = parent.contains(currentFocus);

    if (hasFocusWithin) {
      event.preventDefault();
      const focusableElements: HTMLElement[] = getFocusableChildren(parent),
        children: Element[] = Array.from(parent.querySelectorAll('*')).flat();

      if (shiftKey) {
        const nextFocus: Element | undefined = findNextFocusableElement(
          children.reverse()
        );
        if (!!nextFocus) {
          (nextFocus as HTMLElement).focus();
        } else if (!!focusableElements.length) {
          focusableElements[focusableElements.length - 1].focus();
        }
      } else {
        const nextFocus: Element | undefined =
          findNextFocusableElement(children);
        if (!!nextFocus) {
          (nextFocus as HTMLElement).focus();
        } else if (!!focusableElements.length) {
          focusableElements[0].focus();
        }
      }
    }
  }
}

export function detectOutsideEvent(
  event: any,
  els: HTMLElement[],
  onOutsideClick: () => void
) {
  let path: Element[] = event.composedPath() ?? [];
  if (els.every((el) => !path.includes(el))) {
    onOutsideClick();
  }
}

/**
 * Returns a subset of length `n` from a larger array.
 * @param array The array to be splitted
 * @param chunkSize The maximum size each inner array should have
 * @returns A 1xN matrix where n <= chunkSize
 */
export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunkedArray: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
}
