import axios, { AxiosError } from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { EditMobileAppAccessOnSubmitProps } from 'domains/YouthAthleteAccess/EditMobileAppAccessPage/EditMobileAppAccessPage';
import {
  ChildYouthAthleteAccess,
  FamilyYouthAthleteAccess,
} from 'domains/YouthAthleteAccess/YouthAthleteAccess.types';

const baseUrl = getBaseURL();

const setEmail = async ({
  siteId,
  userId,
  email,
  firstName,
}: {
  siteId: number;
  userId: number;
  firstName: string;
  email?: string;
}): Promise<void> => {
  if (!email) {
    return;
  }
  const endpoint = `${baseUrl}/api/sites/${siteId}/users/${userId}/email`;
  const token = extractAccessToken();
  try {
    return await axios.patch(
      endpoint,
      { email: email },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    let msg = '';
    if (e instanceof AxiosError) {
      console.error(e.response);
      msg = ` ${e.response?.data?.error || e.message}`;
    }
    throw new Error(`Could not set email to ${email} for ${firstName}.${msg}`);
  }
};

const addYouthAthlete = async ({
  siteId,
  userId,
  firstName,
  canChat,
}: {
  siteId: number;
  userId: number;
  firstName: string;
  canChat?: boolean;
}): Promise<{ userId: number; youthAthleteId: number }> => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/youthAthletes`;
  const token = extractAccessToken();
  try {
    const { data } = await axios.post<{ youthAthleteId: number }>(
      endpoint,
      { userId, canChat },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return { userId, youthAthleteId: data.youthAthleteId };
  } catch (e) {
    let msg = '';
    if (e instanceof AxiosError) {
      msg = ` ${e.response?.data?.error || e.message}`;
    }
    throw new Error(`Could not add youth athlete: ${firstName}.${msg}`);
  }
};

const deleteYouthAthlete = async ({
  siteId,
  youthAthleteId,
  firstName,
}: {
  siteId: number;
  youthAthleteId?: number;
  firstName: string;
}): Promise<void> => {
  if (!youthAthleteId) {
    return;
  }
  const endpoint = `${baseUrl}/api/sites/${siteId}/youthAthletes/${youthAthleteId}`;
  const token = extractAccessToken();
  try {
    await axios.delete(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    let msg = '';
    if (e instanceof AxiosError) {
      msg = ` ${e.response?.data?.error || e.message}`;
    }
    throw new Error(`Could not delete youth athlete ${firstName}.${msg}`);
  }
};

const updateYouthAthlete = async ({
  siteId,
  firstName,
  youthAthleteId,
  canChat,
}: {
  siteId: number;
  firstName: string;
  youthAthleteId?: number;
  canChat?: boolean;
}): Promise<void> => {
  if (!youthAthleteId) {
    return;
  }
  const endpoint = `${baseUrl}/api/sites/${siteId}/youthAthletes/${youthAthleteId}`;
  const token = extractAccessToken();
  try {
    await axios.patch(
      endpoint,
      { canChat: canChat },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    let msg = '';
    if (e instanceof AxiosError) {
      msg = ` ${e.response?.data?.error || e.message}`;
    }
    throw new Error(`Could not update youth athlete ${firstName}.${msg}`);
  }
};

/**
 *  setGroupAccountYouthAthletes submits form data for youth athletes in the group account. Will update emails,
 * add youth athlete records or delete them, depending on what was changed in the form submission.
 * @param mobileAppAccessFormData object containing the original youth athlete access data for the group account
 *  and the new data from the form
 * @returns A promise containing the new FamilyYouthAthleteAccess data to populate the confirmation page
 * @throws An error if cannot update email, add youth athlete or delete youth athlete.
 */
export default async function setGroupAccountYouthAthletes({
  originalData,
  newData,
}: EditMobileAppAccessOnSubmitProps): Promise<{
  emailChanged: boolean;
  accessAdded: boolean;
  accessRemoved: boolean;
  youthAthleteUpdated: boolean;
  familyYouthAthleteAccess: FamilyYouthAthleteAccess;
}> {
  const { acceptTerms, youthAthletes } = newData;
  // TODO: Add field level error handling.
  // right now, errors are shown for the entire form.
  // also, the first error caught is the only one that is shown
  if (acceptTerms && originalData && originalData.youthAthletes) {
    // Create a map of original data to check if something is new
    const { youthAthletes: origYouthAthletes } = originalData;
    const originalYouthAthleteMap = new Map<number, ChildYouthAthleteAccess>(
      origYouthAthletes
        .filter((youthAthlete) => youthAthlete && youthAthlete.userId)
        .map((youthAthlete) => [
          youthAthlete?.userId as number,
          youthAthlete as ChildYouthAthleteAccess,
        ])
    );
    // Get list of youth athletes that need their emails updated
    const toChangeEmail = youthAthletes.filter(
      ({ userId, email }) =>
        email &&
        originalYouthAthleteMap.has(userId) &&
        originalYouthAthleteMap.get(userId)?.email !== email
    );
    const toSetAccess = youthAthletes.filter(
      ({ hasYouthAthletePermission, youthAthleteId }) =>
        hasYouthAthletePermission && !youthAthleteId
    );
    const toRemoveAccess = youthAthletes.filter(
      ({ youthAthleteId, hasYouthAthletePermission }) =>
        !hasYouthAthletePermission && youthAthleteId
    );
    const toUpdateYouthAthlete = youthAthletes.filter(
      ({ userId, youthAthleteId, canChat, hasYouthAthletePermission }) => {
        return (
          youthAthleteId &&
          hasYouthAthletePermission &&
          originalYouthAthleteMap.has(userId) &&
          originalYouthAthleteMap.get(userId)?.canChat !== canChat
        );
      }
    );
    // Update email, set access, and revoke access
    await Promise.all(
      toChangeEmail.map(({ siteId, userId, email, firstName }) =>
        setEmail({ siteId, userId, email, firstName })
      )
    );
    // Add youth athletes that have been granted access
    // returns the youthAthleteIds
    const youthAthleteIds = await Promise.all(
      toSetAccess.map(({ siteId, userId, firstName, canChat }) =>
        addYouthAthlete({ siteId, userId, firstName, canChat })
      )
    );
    // Remove youthAthletes that have had access revoked
    await Promise.all(
      toRemoveAccess.map(({ siteId, youthAthleteId, firstName }) =>
        deleteYouthAthlete({ siteId, youthAthleteId, firstName })
      )
    );
    // Update youth athletes that have had fields updated (only canChat for now)
    await Promise.all(
      toUpdateYouthAthlete.map(
        ({ siteId, youthAthleteId, firstName, canChat }) =>
          updateYouthAthlete({ siteId, youthAthleteId, firstName, canChat })
      )
    );
    // Create a map of the new youthAthleteIds to the
    // userIds
    const youthAthleteIdMap = new Map<number, number>(
      youthAthleteIds.map(({ userId, youthAthleteId }) => [
        userId,
        youthAthleteId,
      ])
    );
    const newYouthAthleteData = youthAthletes.map((youthAthlete) =>
      youthAthleteIdMap.has(youthAthlete.userId)
        ? {
            ...youthAthlete,
            youthAthleteId: youthAthleteIdMap.get(youthAthlete.userId),
          }
        : youthAthlete
    );

    return {
      emailChanged: toChangeEmail.length > 0,
      accessAdded: toSetAccess.length > 0,
      accessRemoved: toRemoveAccess.length > 0,
      youthAthleteUpdated: toUpdateYouthAthlete.length > 0,
      familyYouthAthleteAccess: {
        ...newData,
        youthAthletes: newYouthAthleteData,
        acceptTerms: false,
      },
    };
  }

  throw new Error('Cannot submit Youth Athlete Data');
}
