import { KeyboardEvent, useRef, MouseEvent } from 'react';
import { useMainNavigation } from 'lib/context/MainNavigationContext';
import { convertToKebabCase } from 'lib/utils/stringUtils';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { NavItemData } from '../NavBar/NavBar';
import NavSectionMenu from '../NavSectionMenu/NavSectionMenu';
import * as S from './NavItem.styles';

/* NavItem */
export default function NavItem({ href, label, links, target }: NavItemData) {
  const navItem = useRef<HTMLSpanElement>(null);
  const { updateVisNavMenu, visibleNavMenu } = useMainNavigation();
  const navSectionMenuId = `${convertToKebabCase(label)}-section-menu`;

  function mouseOverHandler(evt: MouseEvent) {
    if (visibleNavMenu === navSectionMenuId) {
      updateVisNavMenu('');
    } else {
      updateVisNavMenu(navSectionMenuId);
    }
  }
  return (
    <S.NavItem
      ref={navItem}
      onMouseEnter={mouseOverHandler}
      onMouseLeave={() => {
        updateVisNavMenu('');
      }}
    >
      <NavLink href={href} label={label} target={target} />
      {links ? (
        <>
          <NavMenuButton
            ariaLabel={`More ${label} pages`}
            controlledMenuId={navSectionMenuId}
          />
          <NavSectionMenu links={links} menuId={navSectionMenuId} />
        </>
      ) : null}
    </S.NavItem>
  );
}
/* */

/* Nav Link*/
function NavLink({ href, label, target }: NavItemData) {
  const { updateVisNavMenu, visibleNavMenu } = useMainNavigation();

  function keyDownHandler(evt: KeyboardEvent) {
    if ((evt.key === 'Enter' || evt.key === ' ') && visibleNavMenu !== '') {
      updateVisNavMenu('');
    }
  }

  return (
    <S.NavLink
      to={href}
      tabIndex={0}
      onKeyDown={keyDownHandler}
      target={target}
    >
      {label}
    </S.NavLink>
  );
}
/* */

/* Nav Menu Button */
function NavMenuButton({
  ariaLabel,
  controlledMenuId,
}: {
  ariaLabel: string;
  controlledMenuId: string;
}) {
  const { updateVisNavMenu, visibleNavMenu } = useMainNavigation();
  const isExpanded = controlledMenuId === visibleNavMenu;

  function keyDownHandler(evt: KeyboardEvent<HTMLButtonElement>) {
    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      if (visibleNavMenu === controlledMenuId) {
        updateVisNavMenu('');
      } else {
        updateVisNavMenu(controlledMenuId);
      }
    }
  }

  return (
    <S.NavMenuButton
      aria-controls={controlledMenuId}
      aria-expanded={isExpanded}
      aria-label={ariaLabel}
      onKeyDown={keyDownHandler}
    >
      <ArrowDown />
    </S.NavMenuButton>
  );
}
