import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
} from '@la/ds-ui-components';
import CartSummaryCard from '../CartSummaryCard/CartSummaryCard';
import { CartItemData } from '../Checkout.types';
import * as S from './OrderDetailsModal.styles';

export type OrderDetailsModalProps = {
  closeModal: () => void;
  orders: CartItemData[];
  viewOrderDetails: boolean;
};

/* OrderDetailsModal */
export default function OrderDetailsModal({
  closeModal,
  orders,
  viewOrderDetails,
}: OrderDetailsModalProps) {
  return (
    <Modal
      hideClose={false}
      open={viewOrderDetails}
      onOpenChange={closeModal}
      primaryAction={
        <ModalClose>
          <ModalPrimaryActionButton>Done</ModalPrimaryActionButton>
        </ModalClose>
      }
      size="medium"
      title="Order details"
    >
      <S.OrderDetailsModal>
        <OrderCards orders={orders} />
      </S.OrderDetailsModal>
    </Modal>
  );
}
/* */

/* OrderCards*/
function OrderCards({ orders }: { orders: CartItemData[] }) {
  return orders ? (
    <>
      {orders.map((orderItemData) => (
        <CartSummaryCard
          cartItemData={orderItemData}
          key={orderItemData.cartItemUuid}
        />
      ))}
    </>
  ) : null;
}
