import { requestLIPToken } from 'lib/auth/auth';
import * as S from './LoginMenuMobile.styles';

export type LoginMenuMobileProps = {};

/* LoginMenuMobile */
export default function LoginMenuMobile() {
  return (
    <S.LoginMenuMobile>
      <S.UnderlinedLoginLink onClick={requestLIPToken}>
        Log in
      </S.UnderlinedLoginLink>
      {' or '}
      <S.UnderlinedLoginLink href="/signup">Sign up</S.UnderlinedLoginLink>
    </S.LoginMenuMobile>
  );
}
/* */
