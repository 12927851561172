import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../Page/Page';
import { createFontLink, generateBannerHTML } from './util';
import * as S from './IFrameContainer.styles';

export type IFrameContainerProps = {};

function injectBannerIntoIFrame(iframeContent: Document) {
  const primaryColor = document!
    .querySelector('html')!
    .style.getPropertyValue('--primary');
  const fontLinkElement = createFontLink();
  const bannerHTML = generateBannerHTML(primaryColor);
  iframeContent.head.prepend(fontLinkElement);
  iframeContent.body.prepend(bannerHTML);
}

/* IFrameContainer */
export default function IFrameContainer(props: IFrameContainerProps) {
  const [sourceURL, setSourceURL] = useState<string | undefined>(undefined);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  let { '*': splat } = useParams();

  /**
   * When a page loads into the iframe this script injects a `baseElement`
   * tag into that page's DOM with the property of `target` set to `_top`.
   * This ensures that when navigation is triggered within the iframe the
   * URL is handled by the top-level frame, which will always be the browser.
   * If the url is /signUp, then injects a `linkElement` tag into that page's DOM
   * to get the font we are currently using and inserts a `bannerHTML` div tag to render
   * the banner.
   */

  function onIFrameContentLoaded() {
    const url = new URL(window.location.href);
    if (iframeRef.current?.contentDocument) {
      const doc = iframeRef.current.contentDocument as Document;
      const baseElement = doc.createElement('base');
      baseElement.target = '_top';

      doc.body.prepend(baseElement);

      document.title = doc.title;

      if (url.pathname === '/signup') {
        injectBannerIntoIFrame(doc);
      }
    }
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const iframeParam = 'ngmp_2023_iframe_transition';

    if (params.has(iframeParam)) {
      setSourceURL(undefined);
    } else {
      if (url.pathname === '/themePreview') {
        params.append(iframeParam, '1');
        setSourceURL(`/dashboard?${params.toString()}`);
      } else {
        params.append(iframeParam, '1');
        setSourceURL(`${url.pathname}?${params.toString()}`);
      }
    }
  }, [splat]);

  return sourceURL ? (
    <Page>
      <S.IFrameContainer
        src={sourceURL}
        ref={iframeRef}
        onLoad={onIFrameContentLoaded}
        id="monolith-iframe"
      />
    </Page>
  ) : null;
}
/* */
