import { TeamRoster } from 'redux/services/rosterManagementApi';

export type RolloverMember = {
  userId: number;
  name: string;
  role: string;
  roleId?: number;
  parent?: string;
  emailAddress?: string;
};

export enum RosterRolloverActionType {
  UpdateStep = 'UPDATE_STEP',
  UpdateMembers = 'UPDATE_MEMBERS',
  SelectTeamRoster = 'SELECT_TEAM_ROSTER',
  ToggleShowTeamSelectionLoadMoreOption = 'TOGGLE_SHOW_TEAM_SELECTION_LOAD_MORE_OPTION',
  IncrementTeamDataPage = 'INCREMENT_TEAM_DATA_PAGE',
  ToggleModal = 'TOGGLE_MODAL',
}

export enum ModalName {
  TeamSelection = 'TEAM_SELECTION',
  Invite = 'INVITE',
}

export type RosterRolloverAction =
  | { type: RosterRolloverActionType.UpdateStep; payload: number }
  | {
      type: RosterRolloverActionType.UpdateMembers;
      payload: {
        add?: RolloverMember[];
        update?: RolloverMember[];
        remove?: RolloverMember[];
      };
    }
  | {
      type: RosterRolloverActionType.SelectTeamRoster;
      payload: TeamRoster;
    }
  | {
      type: RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption;
      payload: boolean;
    }
  | {
      type: RosterRolloverActionType.IncrementTeamDataPage;
    }
  | {
      type: RosterRolloverActionType.ToggleModal;
      payload?: ModalName;
    };

export type RosterRolloverState = {
  /**
   * Current step number in roster rollover flow.
   */
  step: number;
  /**
   * Members selected to rollover.
   */
  members: RolloverMember[];
  /**
   * Team roster the user is rolling members from.
   */
  selectedTeamRoster?: TeamRoster;
  /**
   * Whether or not the "Load more" option should show in the team
   * selection modal.
   */
  showTeamSelectionLoadMoreOption?: boolean;
  /**
   * Number of pages loaded of team data.
   */
  teamDataPage: number;
  /**
   * The modal that should currently be displayed.
   */
  openModal?: string;
};

export const rosterRolloverReducer = (
  state: RosterRolloverState,
  action: RosterRolloverAction
): RosterRolloverState => {
  switch (action.type) {
    case RosterRolloverActionType.UpdateStep:
      return { ...state, step: action.payload };
    case RosterRolloverActionType.UpdateMembers:
      const { members } = state;

      // Add members (checks for existing as safeguard to prevent duplication)
      const membersAdded = members.concat(
        action.payload.add?.filter((member) => {
          const isNewMember = !members.find((m) => m.userId === member.userId);
          return isNewMember;
        }) ?? []
      );

      // Update members (if member not found in update array, will return
      // existing member as is)
      const membersUpdated = membersAdded.map((member) => {
        const updatedMember = action.payload.update?.find(
          (m) => m.userId === member.userId
        );
        return updatedMember ?? member;
      });

      // Remove members
      const membersRemoved = membersUpdated.filter((member) => {
        const shouldRemove = action.payload.remove?.find(
          (m) => m.userId === member.userId
        );
        return !shouldRemove;
      });

      return { ...state, members: membersRemoved };
    case RosterRolloverActionType.SelectTeamRoster:
      return {
        ...state,
        selectedTeamRoster: action.payload,
        openModal: undefined,
      };
    case RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption:
      return { ...state, showTeamSelectionLoadMoreOption: action.payload };
    case RosterRolloverActionType.IncrementTeamDataPage:
      return { ...state, teamDataPage: state.teamDataPage + 1 };
    case RosterRolloverActionType.ToggleModal:
      return {
        ...state,
        openModal: action.payload,
      };
  }
};
