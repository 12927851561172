import { PaymentPlanSummary } from 'domains/Checkout/Checkout.types';

export const getPaymentPlanSubTotal = (
  paymentPlan: PaymentPlanSummary
): number => {
  return paymentPlan.installments.reduce(
    (total, installment) => Number(installment.installmentTotal) + total,
    0
  );
};
