import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const TermsAndConditionsAgreementBox = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  background-color: var(--white);
  padding: 16px;

  ${media.DesktopUp`
    align-items: center;
    padding:16px 24px;
  `}
`;

export const Label = styled.div`
  font: var(--ui-large-regular);
  color: var(--blue-grey-900);
  margin-left: 8px;

  & span {
    font: var(--ui-large-bold);
    color: var(--secondary-600);
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--secondary-700);
    }
  }
`;
