import styled from 'styled-components/macro';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BorderBox = styled.div<{ $hasError: boolean }>`
  position: relative;
  height: 42px;
  margin-top: 8px;
  padding: 0 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $hasError }) =>
    $hasError ? 'var(--red)' : 'var(--secondary-200)'};
  border-radius: 4px;
`;

export const TextInputLabel = styled.label<{
  $hasError: boolean;
  $required: boolean;
}>`
  position: absolute;
  top: -9px;
  left: 8px;
  height: 16px;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ $hasError }) =>
    $hasError ? 'var(--red)' : 'var(--secondary-600)'};
  background-color: var(--white);

  ${({ $required }) =>
    $required
      ? `
      &:after {
        content: " *";
        font-size: 12px;
        color: var(--red);
      }
    `
      : null}
`;

export const RequiredSymbol = styled.span<{ $required: boolean }>`
  display: ${({ $required }) => ($required ? 'inline' : 'none')};
  font-size: 12px;
  color: var(--red);
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin: 0;
  font-size: var(--ui-size-small);
  color: var(--red);
  word-break: break-word;
`;

/*
This is NOT a styled-component. This is an object that is passed to Stripe
 in order to define styles that are used in the form fields Stripe provides.
 */
export const StripeTextInputStyle = {
  base: {
    color: 'var(--black)',
    fontWeight: '400',
    fontFamily: 'DM Sans, Segoe UI, Tahoma, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    lineHeight: '40px',
  },
  invalid: {
    iconColor: 'var(--red)',
    color: 'var(--red)',
  },
};
