"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDivisionSummaryCardId = exports.DivisionSummaryCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var DivisionSummaryCard_utils_1 = require("./DivisionSummaryCard.utils");
var S = require("./DivisionSummaryCard.styles");
var TEAMS_TABLE_COLUMN_HEADERS = ['Team #', 'Team name'];
function DivisionSummaryCard(_a) {
    var division = _a.division, teams = _a.teams;
    var subtotal = (0, DivisionSummaryCard_utils_1.getDivisionSubtotal)((0, utilities_1.getDivisionCost)(division), teams.length);
    var cardId = getDivisionSummaryCardId(division);
    var variant = 'dense';
    /*
     * This ID is used to associate the division name as the label for interactive content
     * inside the card so that assistive technologies will announce the division name when
     * a user goes directly to the show/hide button.
     */
    var cardLabelId = "".concat(cardId, "-label");
    // This ID used to associate the disclosure button with the content it shows / hides
    var teamsListId = "division-".concat(division.id, "-summary-teams-list");
    var _b = (0, react_1.useState)(false), isShowingTeams = _b[0], setIsShowingTeams = _b[1];
    var toggleIsShowingTeams = function () { return setIsShowingTeams(!isShowingTeams); };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ "aria-labelledby": cardLabelId, role: "group", "data-testid": cardId }, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Card, __assign({ variant: variant }, { children: [(0, jsx_runtime_1.jsxs)(S.DivisionSummaryCardHeader, { children: [(0, jsx_runtime_1.jsx)(S.DivisionSummaryCardHeaderName, __assign({ id: cardLabelId }, { children: division.name })), teams.length > 0 ? (0, jsx_runtime_1.jsx)("div", { children: (0, ds_ui_components_1.formatAsCurrency)(subtotal) }) : null] }), (0, jsx_runtime_1.jsx)(DivisionSummaryCardMainSection, { isTeamsListShowingTeams: isShowingTeams, handleToggleButtonClick: toggleIsShowingTeams, teams: teams, teamsListId: teamsListId, variant: variant }), isShowingTeams ? ((0, jsx_runtime_1.jsx)(DivisionSummaryCardTeamsListSection, { teams: teams, id: teamsListId })) : null] })) })));
}
exports.DivisionSummaryCard = DivisionSummaryCard;
function DivisionSummaryCardTeamsListSection(_a) {
    var teams = _a.teams, id = _a.id;
    return ((0, jsx_runtime_1.jsx)(S.DivisionSummaryCardTeamsTableContainer, { children: (0, jsx_runtime_1.jsxs)(S.DivisionSummaryCardTeamsTable, __assign({ id: id }, { children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsx)("tr", { children: TEAMS_TABLE_COLUMN_HEADERS.map(function (header) { return ((0, jsx_runtime_1.jsx)("th", { children: header }, header)); }) }) }), (0, jsx_runtime_1.jsx)("tbody", { children: teams.map(function (_a, index) {
                        var teamId = _a.id, name = _a.name;
                        return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { children: "Team ".concat(index + 1) }), (0, jsx_runtime_1.jsx)("td", { children: name })] }, teamId));
                    }) })] })) }));
}
function DivisionSummaryCardMainSection(_a) {
    var isTeamsListShowingTeams = _a.isTeamsListShowingTeams, teams = _a.teams, teamsListId = _a.teamsListId, variant = _a.variant, handleToggleButtonClick = _a.handleToggleButtonClick;
    var numberOfTeams = teams.length;
    if (numberOfTeams === 0) {
        return ((0, jsx_runtime_1.jsx)(S.DivisionSummaryCardMainSection, __assign({ "$variant": variant }, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui" }, { children: ["You have ", (0, jsx_runtime_1.jsx)("b", { children: "not" }), " saved any teams to this division yet"] })) })));
    }
    else {
        return ((0, jsx_runtime_1.jsxs)(S.DivisionSummaryCardMainSection, __assign({ "$variant": variant }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ size: "large", variant: "ui" }, { children: getTeamsMessage(numberOfTeams) })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ "aria-controls": teamsListId, "aria-expanded": isTeamsListShowingTeams, onClick: handleToggleButtonClick, variant: "outline", width: "fit-content", leftIcon: (0, jsx_runtime_1.jsx)(S.ShowAndHideButtonIcon, { "$isRotated": isTeamsListShowingTeams, variant: "bold", size: "large", fill: "rgb(38, 50, 56)" }) }, { children: isTeamsListShowingTeams ? 'Hide teams' : 'Show teams' }))] })));
    }
}
function getTeamsMessage(numberOfTeams) {
    if (numberOfTeams === 1) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("b", { children: "1 team" }), " added"] }));
    }
    else {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("b", { children: [numberOfTeams, " teams"] }), " added"] }));
    }
}
function getDivisionSummaryCardId(division) {
    return "reg-wizard-division-summary-card-".concat(division.id);
}
exports.getDivisionSummaryCardId = getDivisionSummaryCardId;
