import styled from 'styled-components/macro';
import { Card, CardHeader } from 'components/Card/Card.styles';
import { Border, Chip, Label } from 'components/Chip/Chip.styles';
import { media } from 'lib/media-queries/mixins';

export const CartCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.TabletPortraitDown`
    padding: 12px;
  `}
`;

export const CartSummaryCard = styled(Card)`
  border-color: var(--primary-400);
`;

export const CartCardLogo = styled.img`
  width: auto;
  height: auto;
  max-width: 32px;
  max-height: 32px;

  ${media.PhoneOnly`
    display: none;
  `}
`;

export const CartCardHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--blue-grey-900);
`;

export const CartCardHeaderRight = styled.div`
  color: var(--blue-grey-900);
`;

export const CartCardBody = styled.div`
  ${Chip} {
    padding: 4px 8px;
    gap: 4px;
  }

  ${Label} {
    padding: 0;
  }

  ${Border} {
    border-color: var(--primary-200);
    right: 0;
  }
`;

export const CartCardBodyTopContainer = styled.div`
  padding: 16px 16px 12px;
  background-color: var(--white);
  border-radius: 0 0 4px 4px;

  ${media.TabletLandscapeUp`
    padding: 16px;
  `}
`;

export const DivisionList = styled.div`
  display: none;

  ${media.TabletLandscapeUp`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 24px;
    padding: 0px 8px;
  `}
`;

export const TournamentDetail = styled.div`
  font: var(--ui-medium-medium);
  color: var(--blue-grey-900);
  margin-bottom: 16px;

  ${media.TabletLandscapeUp`
    font: var(--ui-large-bold);
    margin-bottom: 12px;
    padding: 0px 8px;
  `}
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const MainButtonContainer = styled.div`
  flex: 1;
`;

export const AdditionalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ProgramRegistrationsTableContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--primary-35);
  border-radius: 0px 0px 4px 4px;
`;

export const ProgramRegistrationsTable = styled.table`
  width: 100%;
  background: var(--white);
  border: 1px solid var(--primary-300);
  border-spacing: 0px;
  border-radius: 4px;
  font: var(--ui-medium-medium);
  color: var(--blue-grey-900);

  thead {
    color: var(--primary-700);
    tr:first-child {
      th {
        border-bottom: 1px solid var(--primary-300);
      }
    }
  }

  tbody tr td:first-child {
    width: 35%;
  }

  th,
  td {
    padding: 7px 0px 7px 12px;
    text-align: left;
  }

  th:last-child,
  td:last-child {
    padding: 7px 12px;
  }
`;
