import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const UserProfileMenu = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 8px 0;
`;

export const UserProfileMenuItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--blue-grey-100);
  width: 100%;
  height: 44px;
  font: var(--ui-large-medium);
  color: var(--blue-grey-900);
  text-decoration: none;

  svg {
    margin: 0 0 1px 4px;
  }

  &:focus,
  &:hover {
    background-color: var(--blue-grey-50);
  }

  &:active {
    background-color: var(--blue-grey-100);
  }

  &:focus-visible {
    outline: none;
  }
`;
