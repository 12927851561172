import baseApi from './baseApi';

export const paymentsApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['PaymentOptions'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createCheckoutSession: builder.query({
        query: ({ siteId }) => {
          const url = `/api/payment-option/sites/${siteId}/createCheckoutSession`;
          const successUrl = `https://${window.location.hostname}/membershipSuccess`;
          return {
            url: url,
            method: 'POST',
            body: {
              returnUrl: successUrl,
            },
          };
        },
      }),
    }),
  });

export const { useCreateCheckoutSessionQuery } = paymentsApi;
