/**
 * Given a date format it based on the parts and style of the object.
 *
 *
 * @param Date A number (epoch) or a Date
 * @param parts The part of the date to include. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts#description
 * @param type The type of the data to be displayed.
 * @default long
 * @param options Options object from Intl.DateTimeFormat. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_options
 * @param locales The format of the language used in the user interface. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_locales
 * @default en-US
 * @returns A string displaying the formatted date
 *
 */
const formatDate = ({
  date,
  parts = ['year', 'month', 'day'],
  type = 'long',
  options = {},
  locales = 'en-US'
}) => {
  const formattedDate = new Intl.DateTimeFormat(locales, {
    year: 'numeric',
    day: type === 'numeric' ? '2-digit' : 'numeric',
    month: type === 'numeric' ? '2-digit' : type,
    ...options
  }).formatToParts(date).filter(p => parts.includes(p.type) || p.type === 'literal').map(p => p.value).reduce((p, c) => p + c);
  return formattedDate.at(-1) === ':' ? formattedDate.slice(0, -1) : formattedDate;
};
export { formatDate };