import styled from 'styled-components/macro';

export const SiteLogo = styled.a<{
  $maxHeight?: string;
  $isInLoginMenu?: boolean;
}>`
  display: flex;
  align-items: center;
  max-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : '60px')};
  max-width: 300px;
  margin: 0;
  border: none;
  background: none;
  text-decoration: none;

  > img {
    max-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : '60px')};
    font-size: 14px;
    max-width: ${({ $isInLoginMenu }) => ($isInLoginMenu ? '150px' : '300px')};
  }
`;
