import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { Loader } from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import {
  UpdateRegistrationsPayload,
  useUpdateRegistrationsMutation,
} from 'redux/services/registrationApi';
import { useCreateTeamMutation } from 'redux/services/teamApi';
import { Team } from 'redux/services/types/team';
import { Tournament } from 'redux/services/types/tournament';
import {
  MainContent,
  MainContentCenter,
} from 'domains/Shell/MainContent/MainContent';
import {
  RegistrationWizardSliceState,
  getInitialDivisionCardsState,
} from './WizardState';
import { DivisionCards } from './components/DivisionCards/DivisionCards';
import {
  SummaryAndSubmitLargerScreens,
  SummaryAndSubmitSmallerScreens,
} from './components/SummaryAndSubmit/SummaryAndSubmit';
import { WizardHeader } from './components/WizardHeader/WizardHeader';
import { useWizardState } from './hooks/useWizardState';
import * as S from './Wizard.styles';

export const ADD_TO_CART_LOADING_MESSAGE =
  'We are adding your registrations to the cart.';
export const ADD_TO_CART_ERROR_MESSAGE =
  'There was an error adding your registrations. Please try adding again in a few seconds.';

export const UPDATE_CART_LOADING_MESSAGE =
  'We are updating your registrations in the cart.';
export const UPDATE_CART_ERROR_MESSAGE =
  'There was an error updating your registrations. Please try updating again in a few seconds.';
export const SUBMISSION_NO_SELECTED_DIVISIONS_ERROR_MESSAGE =
  'Please add at least one team to register.';
export const DELETE_TEAM_DIVISION_ERROR_MESSAGE =
  'This team cannot be deleted because it is registered for another tournament.';

export type WizardProps = {
  hasRegistrationsToEdit: boolean;
  sectionTitle: string;
  submitButtonText: string;
  submitUnsavedChangesErrorMessage: string;
  teams: Team[];
  tournament: Tournament;
};

/* Wizard */
export function Wizard({
  hasRegistrationsToEdit,
  sectionTitle,
  submitButtonText,
  submitUnsavedChangesErrorMessage,
  teams,
  tournament,
}: WizardProps) {
  const { registrationTriageFix } = useFlags();
  const [createTeam] = useCreateTeamMutation();
  const [hasDeleteTeamDivisionError, setHasDeleteTeamDivisionError] =
    useState(false);
  const [
    updateRegistrations,
    {
      isLoading: isUpdateRegistrationsLoading,
      isError: updateRegistrationsError,
    },
  ] = useUpdateRegistrationsMutation();
  const navigate = useNavigate();

  const initialWizardState: RegistrationWizardSliceState = {
    divisionCardsState: getInitialDivisionCardsState({
      divisions: tournament.divisions,
      timeZone: tournament.timeZone,
    }),
    submitButtonError: null,
  };

  const {
    submitButtonError,
    divisionCardsState,
    divisionToScrollToId,
    addTeamSelect,
    submit,
    cancelDivisionChanges,
    clearDivision,
    clearIdToScrollTo,
    discardTeamSelect,
    editDivision,
    saveDivision,
    selectDivision,
    selectTeam,
  } = useWizardState({
    initialState: initialWizardState,
  });

  const { subdomain } = getLAHostnameParts();

  const addTeam = async (
    programId: string,
    selectIndex: number,
    team: Omit<Team, 'id' | 'ageGroup'>
  ): Promise<void> => {
    return createTeam({
      siteDomain: subdomain,
      programId,
      team,
    })
      .unwrap()
      .then(({ id }: { id: string }) => {
        selectTeam(programId, selectIndex, id);
      });
  };

  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  const { name, startDate, endDate, location, divisions } = tournament;

  const { divisionsStartDate, divisionsEndDate } = useMemo(() => {
    let divisionsStartDate: DateTime | undefined;
    let divisionsEndDate: DateTime | null = null;

    divisions.forEach((division) => {
      if (
        divisionsStartDate === undefined ||
        DateTime.fromISO(division.startDate) < divisionsStartDate
      ) {
        divisionsStartDate = DateTime.fromISO(division.startDate);
      }

      if (
        division.endDate !== null &&
        division.endDate !== undefined &&
        (divisionsEndDate === null ||
          DateTime.fromISO(division.endDate) > divisionsEndDate)
      ) {
        divisionsEndDate = DateTime.fromISO(division.endDate);
      }
    });

    return { divisionsStartDate, divisionsEndDate };
  }, [divisions]);

  const assembleUpdateRegistrationsPayload = () => {
    const registrations: UpdateRegistrationsPayload[] = [];
    for (const [programId, division] of Object.entries(divisionCardsState)) {
      const registration: UpdateRegistrationsPayload = {
        programId,
        teams:
          division.value === 'saved'
            ? division.savedTeams.map((id) => ({
                id,
                name: teams.find((team) => team.id === id)?.name ?? '',
              }))
            : [],
      };
      registrations.push(registration);
    }
    return registrations;
  };

  const handleSubmit = () => {
    const payload = assembleUpdateRegistrationsPayload();
    updateRegistrations({
      siteDomain: subdomain,
      programId: tournament.id,
      editURL: `/app/tournaments/${tournament.id}/registration`,
      registrations: payload,
      includeRegistrationTriageFix: registrationTriageFix,
    }).then(() => {
      navigate('/cartSummary');
    });
  };

  if (isUpdateRegistrationsLoading) {
    const loadingMessage = hasRegistrationsToEdit
      ? UPDATE_CART_LOADING_MESSAGE
      : ADD_TO_CART_LOADING_MESSAGE;

    return (
      <MainContentCenter>
        <Loader loading description={loadingMessage} />
      </MainContentCenter>
    );
  }

  const updateRegistrationsErrorMessage = hasRegistrationsToEdit
    ? UPDATE_CART_ERROR_MESSAGE
    : ADD_TO_CART_ERROR_MESSAGE;

  return (
    <MainContent>
      <WizardHeader
        endDate={divisionsEndDate ? divisionsEndDate : undefined}
        location={!tournament.hideLocationDetails ? location?.name : undefined}
        name={name}
        startDate={divisionsStartDate || DateTime.fromISO(startDate)}
      />
      <S.WizardContent>
        <S.WizardAddDivisionsAndTeamsSection>
          {updateRegistrationsError ? (
            <ErrorCard message={updateRegistrationsErrorMessage} />
          ) : null}

          {submitButtonError ? (
            <ErrorCard
              message={
                submitButtonError === 'unsaved-divisions'
                  ? submitUnsavedChangesErrorMessage
                  : SUBMISSION_NO_SELECTED_DIVISIONS_ERROR_MESSAGE
              }
            />
          ) : null}

          {hasDeleteTeamDivisionError ? (
            <ErrorCard message={DELETE_TEAM_DIVISION_ERROR_MESSAGE} />
          ) : null}

          <S.SectionTitle>{sectionTitle}</S.SectionTitle>
          <DivisionCards
            divisions={divisions}
            divisionCardsStateLookup={divisionCardsState}
            divisionToScrollToId={divisionToScrollToId}
            hideLocationDetails={tournament.hideLocationDetails}
            tournamentLocation={tournament.location}
            onTeamCreate={addTeam}
            onScrollToDivision={clearIdToScrollTo}
            onAddTeamSelect={addTeamSelect}
            onCancelDivisionChanges={cancelDivisionChanges}
            onClearDivision={clearDivision}
            onDiscardTeamSelect={discardTeamSelect}
            onEditDivision={editDivision}
            onSaveDivision={saveDivision}
            onSelectDivision={selectDivision}
            onSelectTeam={selectTeam}
            setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
            teams={teams}
            tournamentDates={{
              start: startDate,
              end: endDate,
            }}
            tournamentId={tournament.id}
          />
        </S.WizardAddDivisionsAndTeamsSection>
        {isTabletLandscapeUp ? (
          <SummaryAndSubmitLargerScreens
            divisionCardsState={divisionCardsState}
            onSubmissionErrors={submit}
            onSubmit={handleSubmit}
            submitButtonText={submitButtonText}
            teams={teams}
            tournament={tournament}
          />
        ) : null}
      </S.WizardContent>
      {!isTabletLandscapeUp ? (
        <SummaryAndSubmitSmallerScreens
          divisionCardsState={divisionCardsState}
          onSubmissionErrors={submit}
          onSubmit={handleSubmit}
          submitButtonText={submitButtonText}
          teams={teams}
          tournament={tournament}
        />
      ) : null}
    </MainContent>
  );
}
/* */
