import styled from 'styled-components';

export const ConfirmationMessage = styled.div<{
  $error?: boolean;
}>`
  margin: 20px 0;
  p {
    color: ${({ $error }) =>
      $error ? 'var(--error)' : 'var(--blue-grey-900)'};
  }
`;
