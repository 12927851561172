import * as S from './SiteLogo.styles';

export type SiteLogoData = {
  $maxHeight?: string;
  isInLoginMenu?: boolean;
  siteLogoImageSrc?: string;
  siteLogoHref?: string;
  siteName: string;
};

/* SiteLogo */
export default function SiteLogo({
  $maxHeight,
  isInLoginMenu,
  siteLogoImageSrc,
  siteLogoHref,
  siteName,
}: SiteLogoData) {
  return (
    <S.SiteLogo
      $isInLoginMenu={isInLoginMenu}
      $maxHeight={$maxHeight}
      href={siteLogoHref}
    >
      {siteLogoImageSrc ? <img src={siteLogoImageSrc} alt={siteName} /> : null}
    </S.SiteLogo>
  );
}
/* */
