import {
  Button,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { addPlaidBankAccount } from 'lib/plaid/plaid';
import { getSiteId } from 'redux/coreSlice';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import { ReactComponent as BankIcon } from 'assets/icons/icon_bank.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/icon_payment_card.svg';
import { PaymentMethodInfo, StoredPaymentMethod } from '../Checkout.types';
import PaymentMethodBadge from '../PaymentMethodBadge/PaymentMethodBadge';
import * as S from './PaymentMethodModal.styles';

export type PaymentMethodModalProps = {
  closeModal: () => void;
  modalTitle: string;
  paymentMethodData?: PaymentMethodInfo;
  openCardModal: () => void;
};

/* PaymentMethodModal */
export default function PaymentMethodModal({
  closeModal,
  modalTitle = 'Payment method',
  openCardModal,
  paymentMethodData,
}: PaymentMethodModalProps) {
  const siteId = useAppSelector(getSiteId);
  const { data: userId } = useGetUserIdQuery(siteId);
  function handlePaymentMethodUpdate() {
    //logic for updating payments will go here
    closeModal();
  }

  return (
    <Modal
      onOpenChange={closeModal}
      open={true} // modal is rendered conditionally so it is always open
      primaryAction={
        <ModalPrimaryActionButton onClick={handlePaymentMethodUpdate}>
          Done
        </ModalPrimaryActionButton>
      }
      size="medium"
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      title={modalTitle}
    >
      <S.PaymentMethodSubtitle>Choose payment method</S.PaymentMethodSubtitle>
      <PaymentMethodBadges paymentMethodData={paymentMethodData} />
      <S.ActionButtons>
        <Button
          leftIcon={<BankIcon />}
          size="medium"
          onClick={() => addPlaidBankAccount(userId, siteId)}
        >
          {paymentMethodData?.storedBankAccounts
            ? 'Use another Bank Account'
            : 'Add a Bank Account'}
        </Button>
        <Button
          leftIcon={<CreditCardIcon />}
          size="medium"
          onClick={openCardModal}
        >
          {paymentMethodData?.storedCreditCards
            ? 'Use another Card'
            : 'Add a Card'}
        </Button>
      </S.ActionButtons>
    </Modal>
  );
}
/* */

/*PaymentMethodBadges */
function PaymentMethodBadges({
  paymentMethodData,
}: {
  paymentMethodData: PaymentMethodInfo | undefined;
}) {
  const { selectedPaymentMethod } = useCheckoutInfo();
  if (!paymentMethodData) {
    return null;
  }
  const allStoredPaymentMethods: StoredPaymentMethod[] = [
    ...(paymentMethodData.storedBankAccounts ?? []),
    ...(paymentMethodData.storedCreditCards ?? []),
  ];

  return (
    <S.PaymentMethodBadges>
      {allStoredPaymentMethods.map(
        (paymentMethod: StoredPaymentMethod, index) => (
          <PaymentMethodBadge
            isSelectedPaymentMethod={
              paymentMethod.paymentMethodId ===
              selectedPaymentMethod?.paymentMethodId
            }
            paymentMethod={paymentMethod}
            key={`${index}_${paymentMethod.last4Digits}`}
          />
        )
      )}
    </S.PaymentMethodBadges>
  );
}
/* */
