import styled from 'styled-components';
import { media } from 'lib/media-queries/mixins';

export const ActivityFormContainer = styled.div`
  margin: 50px auto;
  width: 100%;
  max-width: 800px;
  padding: 20px;

  ${media.PhoneOnly`
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  `}

  > h2 {
    margin-bottom: 40px;
  }
`;
