import { useState } from 'react';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import LoadingIndicatorOverlay from 'components/LoadingIndicatorOverlay/LoadingIndicatorOverlay';
import UseAccountForm from './UseAccountForm';

export type UseAccountModalData = {
  closeModal: () => void;
  modalTitle: string;
};

/* UseAccount */
export default function UseAccountModal({
  closeModal,
  modalTitle = 'Payment method',
}: UseAccountModalData) {
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

  return (
    <Modal
      onOpenChange={closeModal}
      open={true} // modal is rendered conditionally so it is always open
      primaryAction={
        <ModalPrimaryActionButton form="use-account-form" type="submit">
          Use account
        </ModalPrimaryActionButton>
      }
      size="small"
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      title={modalTitle}
    >
      <UseAccountForm updateLoadingOverlayVisibility={setIsOverlayVisible} />
      {isOverlayVisible ? (
        <LoadingIndicatorOverlay message="Your payment method is being added" />
      ) : null}
    </Modal>
  );
}
/* */
