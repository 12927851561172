import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const uploadFile = async ({
  siteId,
  propertyDefinitionId,
  userId,
  file,
  filename,
}: {
  siteId: string;
  propertyDefinitionId: string;
  userId: string;
  file: ArrayBuffer;
  filename: string;
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/file`;

  const metadata = {
    userId: parseInt(userId),
    propertyDefinitionId: parseInt(propertyDefinitionId),
  };

  const formData = new FormData();
  const blob = new Blob([file]);

  formData.append('file', blob, filename);
  formData.append(
    'metaData',
    new Blob([JSON.stringify(metadata)], {
      type: 'application/json',
    })
  );

  const token = extractAccessToken();
  const response = await axios.post(endpoint, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export { uploadFile };
