import {
  Button,
  CaretDownIcon,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalProps,
} from '@la/ds-ui-components';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { Team as TeamType } from '@la/types';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { Team } from './Team/Team';
import * as S from './TeamSelectionModal.styles';

export const TEAM_SELECTION_MODAL_TITLE = 'Select a team';
export const LOAD_MORE_LABEL = 'Load more';

export type TeamSelectionModalProps = Pick<
  ModalProps,
  'onOpenChange' | 'open'
> & {
  /**
   * Error to display within the modal.
   */
  error?: string;
  /**
   * Triggered when the load more button is clicked.
   */
  onLoadMoreClick: () => void;
  /**
   * Triggered when a non-selected team is clicked.
   * @param team The team being selected
   */
  onTeamSelect: (team: TeamType) => void;
  /**
   * The currently selected team. Will render in the top of the list and
   * is not clickable. Does not render in the list of selectable teams.
   */
  selectedTeam?: TeamType;
  /**
   * Whether or not to show the load more button at the bottom of the
   * team list.
   */
  showLoadMoreOption?: boolean;
  /**
   * Teams to render as options for selection.
   */
  teams: TeamType[];
};

/* TeamSelectionModal */
export function TeamSelectionModal({
  error,
  onLoadMoreClick,
  onOpenChange,
  onTeamSelect,
  open,
  selectedTeam,
  showLoadMoreOption = false,
  teams,
}: Readonly<TeamSelectionModalProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      primaryAction={
        <ModalClose>
          <ModalPrimaryActionButton width={isTabletPortaitUp ? 'auto' : '100%'}>
            Go back
          </ModalPrimaryActionButton>
        </ModalClose>
      }
      title={TEAM_SELECTION_MODAL_TITLE}
    >
      {error ? <ErrorCard message={error} /> : null}
      <S.TeamList>
        {selectedTeam ? (
          <S.SelectedTeam
            data-testid={getTeamId(selectedTeam.id, selectedTeam.programId!)}
          >
            <Team selected team={selectedTeam} />
          </S.SelectedTeam>
        ) : null}
        {teams?.map((team) => {
          if (selectedTeam?.id === team.id) {
            return null;
          }

          return (
            <li data-testid={getTeamId(team.id, team.programId!)} key={team.id}>
              <Team onClick={() => onTeamSelect(team)} team={team} />
            </li>
          );
        })}
      </S.TeamList>
      {showLoadMoreOption ? (
        <Button
          onClick={onLoadMoreClick}
          rightIcon={
            <CaretDownIcon fill="var(--secondary-600)" variant="bold" />
          }
          size="large"
          variant="outline"
          width="100%"
        >
          {LOAD_MORE_LABEL}
        </Button>
      ) : null}
    </Modal>
  );
}
/* */

export function getTeamId(id: string, programId: number): string {
  return `team-${id}-${programId}`;
}
