import { PropsWithChildren } from 'react';
import Backdrop from 'components/Backdrop/Backdrop';
import { clearStateFromLocalStorage } from 'redux/localStorage';
import { useAppSelector } from 'redux/store';
import { getUserFirstName, getUserProfilePicture } from 'redux/userSlice';
import UserProfileMenu from '../UserProfileMenu/UserProfileMenu';
import * as S from './UserMenu.styles';

export type UserMenuProps = {
  backdropClickHandler: () => void;
  menuId?: string;
};

/* UserMenu */
export default function UserMenu({
  backdropClickHandler,
  children,
  menuId,
}: PropsWithChildren<UserMenuProps>) {
  return (
    <Backdrop clickHandler={backdropClickHandler} transparent={true}>
      <S.UserMenu id={menuId}>{children}</S.UserMenu>
    </Backdrop>
  );
}

export function UserAccountMenu() {
  return (
    <>
      <UserInfo />
      <UserProfileMenu />
      <SignOutLink />
    </>
  );
}
/* */

/* UserInfo */
function UserInfo() {
  const userFirstName = useAppSelector(getUserFirstName);
  return (
    <S.UserInfo>
      <UserAvatar />
      <S.UserName>Hi, {userFirstName}</S.UserName>
    </S.UserInfo>
  );
}
/* */

/* UserAvatar */
function UserAvatar() {
  const userProfilePictureSrc = useAppSelector(getUserProfilePicture);
  return <S.AvatarImage src={userProfilePictureSrc} alt="User Avatar" />;
}
/* */

/*SignOutLink */
function SignOutLink() {
  return (
    <S.SignOutLink
      href="/logout"
      onClick={() => clearStateFromLocalStorage('app-store')}
    >
      Sign Out
    </S.SignOutLink>
  );
}
/* */
