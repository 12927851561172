import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

export const ContentBody = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { foundational } = theme.palette;

    return {
      ...theme.typography.ui.xl,
      display: 'block',
      color: foundational.blueGrey[900],
    };
  }
);

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 42px;

  div:nth-child(1) button {
    padding-left: 0;
  }
`;
