import { StaffRole } from '@la/types';
import baseApi from './baseApi';

export const staffRoleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffRoles: builder.query<StaffRole[], { siteId: string }>({
      query: ({ siteId }) => `/api/sites/${siteId}/staffRoles`,
    }),
    getTeamRepLabel: builder.query<string, { siteId: string }>({
      query: ({ siteId }) => `/api/sites/${siteId}/staffRoles`,
      transformResponse: (staffRoles: StaffRole[]) => {
        const teamRepRole = staffRoles.find((staffRole) => staffRole.isTeamRep);
        return teamRepRole ? teamRepRole.role : 'Team Rep';
      },
    }),
  }),
});

export const { useGetStaffRolesQuery, useGetTeamRepLabelQuery } = staffRoleApi;
