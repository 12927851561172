import { Typography } from '@la/ds-ui-components';
import { formatAsUSD } from 'lib/utils/utilities';
import { Tournament } from 'redux/services/types/tournament';
import { DivisionCardsState } from 'domains/Tournaments/Registration/Wizard/Wizard.types';
import { SUBTOTAL_LABEL } from '../SummaryAndSubmit';
import { calculateTotalCost } from '../utils/cart';
import * as S from './WizardSubtotal.styles';

export type WizardSubtotalProps = {
  divisionCardsState: DivisionCardsState;
  tournament: Tournament;
};

/* WizardSubtotal */
export function WizardSubtotal({
  divisionCardsState,
  tournament,
}: WizardSubtotalProps) {
  return (
    <S.WizardSubtotal>
      <Typography size="large" variant="ui">
        {SUBTOTAL_LABEL}
      </Typography>
      <Typography size="large" variant="ui" weight="bold">
        {formatAsUSD(
          calculateTotalCost(divisionCardsState, tournament.divisions)
        )}
      </Typography>
    </S.WizardSubtotal>
  );
}
/* */
