import { Division } from 'redux/services/types/tournament';

export const getNumberOfSpotsLeft = (
  division: Division
): number | undefined => {
  let numberOfSpotsLeft;
  if (division.maxNumberOfTeams) {
    if (division.numberOfRegisteredTeams) {
      numberOfSpotsLeft =
        division.maxNumberOfTeams - division.numberOfRegisteredTeams;
    } else {
      numberOfSpotsLeft = division.maxNumberOfTeams;
    }
  }

  return numberOfSpotsLeft;
};
