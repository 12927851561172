"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainContentHeaderBackButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("../MainContentHeaderBackButton/MainContentHeaderBackButton.styles");
function MainContentHeaderBackButton(_a) {
    var onClick = _a.onClick;
    return ((0, jsx_runtime_1.jsx)(S.MainContentHeaderBackButton, { ariaLabel: "Back Button", icon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.CaretLeftIcon, {}), onClick: onClick, size: "xl", variant: "text" }));
}
exports.MainContentHeaderBackButton = MainContentHeaderBackButton;
