import { ReactiveTypography } from 'components/ReactiveTypography/ReactiveTypography';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import * as S from './PageTitleSection.styles';

export type PageTitleSectionProps = {
  logoUrl?: string;
  sport: string;
  tournamentName: string;
};

export function PageTitleSection({
  logoUrl,
  sport,
  tournamentName,
}: PageTitleSectionProps) {
  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  return (
    <S.PageTitleSection>
      <S.PageTitleSectionLayout>
        {logoUrl ? <S.Logo src={logoUrl} /> : null}
        <S.TitleAndSubtitle $withLogo={!!logoUrl}>
          <ReactiveTypography
            background="primary"
            variant="headline"
            size={isTabletLandscapeUp ? 'xl' : 'medium'}
          >
            {tournamentName}
          </ReactiveTypography>
          <ReactiveTypography
            background="primary"
            variant="ui"
            size={isTabletLandscapeUp ? 'large' : 'medium'}
          >
            {`${sport} tournament`}
          </ReactiveTypography>
        </S.TitleAndSubtitle>
      </S.PageTitleSectionLayout>
    </S.PageTitleSection>
  );
}
