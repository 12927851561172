import { Badge } from '@la/ds-ui-components';
import { PriceAdjustment } from '@la/types';
import { formatAsUSD, isFixedAmountPriceAdjustment } from '@la/utilities';

export type DiscountBadgeProps = {
  priceAdjustment: PriceAdjustment;
};

export function DiscountBadge({ priceAdjustment }: DiscountBadgeProps) {
  let discountAmount, formattedDiscount;
  if (isFixedAmountPriceAdjustment(priceAdjustment)) {
    discountAmount = priceAdjustment.fixedAmount;
    formattedDiscount = formatAsUSD(Math.abs(discountAmount));
  } else {
    discountAmount = priceAdjustment.percentage;
    formattedDiscount = `${Math.abs(discountAmount)}%`;
  }

  if (discountAmount >= 0) {
    return null;
  }

  const discountText = `${formattedDiscount} Discount`;

  return <Badge size="small" text={discountText} />;
}
