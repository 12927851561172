"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@reduxjs/toolkit/query/react");
var utilities_1 = require("@la/utilities");
var baseApi = (0, react_1.createApi)({
    reducerPath: 'baseApi',
    baseQuery: (0, react_1.fetchBaseQuery)({
        baseUrl: (0, utilities_1.getBaseURL)(),
        prepareHeaders: function (headers) {
            // By default, if we have a token in the store, let's use that for authenticated requests
            var token = (0, utilities_1.extractAccessToken)();
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            return headers;
        },
    }),
    endpoints: function () { return ({}); },
});
exports.default = baseApi;
