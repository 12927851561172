"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getManagementConsoleDomain = exports.baseConfig = exports.getEnv = exports.getDomainPrefix = exports.getDomain = exports.Domain = exports.Env = void 0;
/* eslint-disable no-unused-vars */
var Env;
(function (Env) {
    Env[Env["CICD1"] = 0] = "CICD1";
    Env[Env["Local"] = 1] = "Local";
    Env[Env["Dev1"] = 2] = "Dev1";
    Env[Env["Dev2"] = 3] = "Dev2";
    Env[Env["Dev3"] = 4] = "Dev3";
    Env[Env["QA1"] = 5] = "QA1";
    Env[Env["QA2"] = 6] = "QA2";
    Env[Env["Preview"] = 7] = "Preview";
    Env[Env["Production"] = 8] = "Production";
})(Env = exports.Env || (exports.Env = {}));
var Domain;
(function (Domain) {
    Domain["CICD1"] = "lapps-cicd1";
    Domain["Local"] = "lapps-local";
    Domain["Dev1"] = "lapps-dev1";
    Domain["Dev2"] = "lapps-dev2";
    Domain["Dev3"] = "lapps-dev3";
    Domain["QA1"] = "lapps-qa1";
    Domain["QA2"] = "lapps-qa2";
    Domain["Preview"] = "lapps-preview";
    Domain["Production"] = "leagueapps";
})(Domain = exports.Domain || (exports.Domain = {}));
var ApiUrl;
(function (ApiUrl) {
    ApiUrl["CICD1"] = "lapps-cicd1.io";
    ApiUrl["Local"] = "lapps-local.com";
    ApiUrl["Dev1"] = "lapps-dev1.io";
    ApiUrl["Dev2"] = "sportvite.net";
    ApiUrl["Dev3"] = "lapps-dev3.io";
    ApiUrl["QA1"] = "lapps-qa1.io";
    ApiUrl["QA2"] = "lmstaging.com";
    ApiUrl["Preview"] = "lapps-preview.com";
    ApiUrl["Production"] = "leagueapps.io";
})(ApiUrl || (ApiUrl = {}));
var cicd1Config = {
    env: Env.CICD1,
    apiUrl: ApiUrl.CICD1,
};
var localConfig = {
    env: Env.Local,
    apiUrl: ApiUrl.Local,
};
var dev1Config = {
    env: Env.Dev1,
    apiUrl: ApiUrl.Dev1,
};
var dev2Config = {
    env: Env.Dev2,
    apiUrl: ApiUrl.Dev2,
};
var dev3Config = {
    env: Env.Dev3,
    apiUrl: ApiUrl.Dev3,
};
var qa1Config = {
    env: Env.QA1,
    apiUrl: ApiUrl.QA1,
};
var qa2Config = {
    env: Env.QA2,
    apiUrl: ApiUrl.QA2,
};
var previewConfig = {
    env: Env.Preview,
    apiUrl: ApiUrl.Preview,
};
var productionConfig = {
    env: Env.Production,
    apiUrl: ApiUrl.Production,
};
var config = new Map();
config.set(Env.CICD1, cicd1Config);
config.set(Env.Local, localConfig);
config.set(Env.Dev1, dev1Config);
config.set(Env.Dev2, dev2Config);
config.set(Env.Dev3, dev3Config);
config.set(Env.QA1, qa1Config);
config.set(Env.QA2, qa2Config);
config.set(Env.Preview, previewConfig);
config.set(Env.Production, productionConfig);
function getDomain() {
    var hostNameParts = window.location.hostname.split('.');
    // Return second to last level domain
    return hostNameParts[hostNameParts.length - 2];
}
exports.getDomain = getDomain;
/**
 * To handle dynamic environments for testing backend changes
 * For context: the backend preview envs are targeted via dedicated subdomains, so we want the following format for our baseURL:
 * <some-string>.manager.lapps-cicd1.com -> <same-string>.api.lapps-cicd1.io
 * * <some-string>.react.lapps-cicd1.com -> <same-string>.api.lapps-cicd1.io
 */
function getDomainPrefix() {
    var domain = getDomain();
    var domainPrefix = window.location.hostname.split(domain)[0];
    if (domainPrefix.includes('react')) {
        //string before 'react' in URL
        return domainPrefix.split('react.')[0];
    }
    else if (domainPrefix.includes('manager')) {
        //string before 'manager' in URL
        return domainPrefix.split('manager.')[0];
    }
    return '';
}
exports.getDomainPrefix = getDomainPrefix;
function getEnv() {
    var domain = getDomain();
    switch (domain) {
        case Domain.Production:
            return Env.Production;
        case Domain.Dev1:
            return Env.Dev1;
        case Domain.Dev2:
            return Env.Dev2;
        case Domain.Dev3:
            return Env.Dev3;
        case Domain.QA1:
            return Env.QA1;
        case Domain.QA2:
            return Env.QA2;
        case Domain.Local:
            return Env.Local;
        case Domain.CICD1:
            return Env.CICD1;
        case Domain.Preview:
            return Env.Preview;
        default:
            return Env.QA2;
    }
}
exports.getEnv = getEnv;
exports.baseConfig = config.get(getEnv());
var getManagementConsoleDomain = function () {
    return "https://manager.".concat(getDomain(), ".com");
};
exports.getManagementConsoleDomain = getManagementConsoleDomain;
