import { DateTime } from 'luxon';
import { Button, Typography } from '@la/ds-ui-components';
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/Card/Card';
import { formatDateRange } from 'lib/utils/dateUtils';
import * as S from './RegistrationWindowCard.styles';

export type RegistrationWindowCardProps = {
  /**
   * This is temporarily being used while we are using a button instead
   * of a regular link.
   */
  navigateToRegistration: () => void;
  /**
   * For now this is used for the `data-href` property on the button being
   * assigned a `link` role. Once we have a true link to use, we can just
   * use this as the `to` value for the React Router link
   */
  registrationLink: string;
  /**
   * The day registration opens
   */
  startDate?: string;
  /**
   * The day registration closes
   */
  endDate?: string;
  /**
   * When `true`, the register button should be shown
   */
  isRegisterLinkShowing?: boolean;
};

export function RegistrationWindowCard({
  navigateToRegistration,
  registrationLink,
  startDate,
  endDate,
  isRegisterLinkShowing,
}: RegistrationWindowCardProps) {
  let windowText;

  if (!startDate || !endDate) {
    windowText = 'Open';
  } else {
    windowText = formatDateRange(
      DateTime.fromISO(startDate),
      DateTime.fromISO(endDate)
    );
  }

  return (
    <S.RegistrationWindowCard>
      <CardHeader>
        <CardTitle>Registration window</CardTitle>
      </CardHeader>
      <CardBody>
        <Typography variant="ui" size="large">
          {windowText}
        </Typography>
      </CardBody>
      {isRegisterLinkShowing ? (
        <CardFooter>
          <Button
            // This element is styled like a Button but it is serving as a link
            // TODO: Replace this with the design system "link styled as button"
            // whenever it is ready
            role="link"
            data-href={registrationLink}
            onClick={navigateToRegistration}
          >
            Register now
          </Button>
        </CardFooter>
      ) : null}
    </S.RegistrationWindowCard>
  );
}
