"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBreadcrumbId = exports.Breadcrumbs = exports.SEPARATOR = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var S = require("./Breadcrumbs.styles");
exports.SEPARATOR = '/';
var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs;
    var getBreadcrumb = function (breadcrumb) {
        if (breadcrumb.link) {
            var _a = breadcrumb.link, href = _a.href, _b = _a.newTab, newTab = _b === void 0 ? false : _b;
            return ((0, jsx_runtime_1.jsx)(S.BreadcrumbLink, __assign({ href: href, target: newTab ? '_blank' : '_self' }, { children: breadcrumb.label })));
        }
        return breadcrumb.label;
    };
    return ((0, jsx_runtime_1.jsx)(S.Breadcrumbs, { children: breadcrumbs.map(function (breadcrumb, index) {
            var breadcrumbId = (0, exports.getBreadcrumbId)(index);
            return ((0, jsx_runtime_1.jsxs)(S.Breadcrumb, __assign({ "data-testid": breadcrumbId }, { children: [(0, jsx_runtime_1.jsx)(S.BreadcrumbLabel, { children: getBreadcrumb(breadcrumb) }), index < breadcrumbs.length - 1 ? ((0, jsx_runtime_1.jsx)(S.BreadcrumbSeparator, { children: exports.SEPARATOR })) : null] }), breadcrumbId));
        }) }));
};
exports.Breadcrumbs = Breadcrumbs;
var getBreadcrumbId = function (index) {
    return "breadcrumb-".concat(index);
};
exports.getBreadcrumbId = getBreadcrumbId;
