import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ArrowLeftRightIcon,
  Button,
  DownloadIcon,
  PrintIcon,
} from '@la/ds-ui-components';
import { RosterActionsModalProps } from './RosterActionsModal/RosterActionsModal';
import * as S from './RosterManagement.styles';

const SECONDARY_ACTION_ICON_COLOR = 'var(--secondary-600)';

export type RosterUtilitiesProps = Pick<
  RosterActionsModalProps,
  'onDownloadRosterCSVClick' | 'onPrintRosterClick' | 'onRosterRolloverClick'
>;

/* Roster Utilities */
export default function RosterUtilities({
  onDownloadRosterCSVClick,
  onPrintRosterClick,
  onRosterRolloverClick,
}: RosterUtilitiesProps) {
  const { ngRosterRollover } = useFlags();

  return (
    <S.RosterUtilities>
      <Button
        leftIcon={
          <DownloadIcon
            fill={SECONDARY_ACTION_ICON_COLOR}
            variant="bold"
            size="xl"
          />
        }
        onClick={onDownloadRosterCSVClick}
        size="large"
        variant="text"
      >
        Download roster CSV
      </Button>
      <Button
        leftIcon={
          <PrintIcon
            fill={SECONDARY_ACTION_ICON_COLOR}
            variant="bold"
            size="xl"
          />
        }
        onClick={onPrintRosterClick}
        size="large"
        variant="text"
      >
        Print roster
      </Button>
      {ngRosterRollover ? (
        <Button
          leftIcon={
            <S.RosterRolloverIcon>
              <ArrowLeftRightIcon size="xl" variant="bold" />
            </S.RosterRolloverIcon>
          }
          onClick={onRosterRolloverClick}
          size="large"
          variant="primary"
        >
          Rollover roster
        </Button>
      ) : null}
    </S.RosterUtilities>
  );
}
/* */
