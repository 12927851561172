import { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import { useGetUserIdQuery } from '@la/services';
import {
  Breadcrumb,
  MainContent,
  MainContentCenter,
  Stepper,
} from '@la/shared-components';
import { Team } from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetProgramQuery } from 'redux/services/activity';
import {
  TeamRoster,
  useGetInvitesQuery,
  useGetTeamRosterQuery,
  useLazyGetTeamRosterQuery,
} from 'redux/services/rosterManagementApi';
import { useGetFacadeTeamsQuery } from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import Page from 'domains/Shell/Page/Page';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { InviteModal } from './InviteModal/InviteModal';
import { RolloverSummary } from './RolloverSummary/RolloverSummary';
import { RolloverWizard } from './RolloverWizard/RolloverWizard';
import { TeamSelectionModal } from './TeamSelectionModal/TeamSelectionModal';
import { mockRolloverMembers } from './__dev__/mockRolloverMembers';
import {
  ModalName,
  RosterRolloverActionType,
  rosterRolloverReducer,
  RosterRolloverState,
} from './utils/reducer';
import * as S from './RosterRollover.styles';

const TEAM_PAGE_SIZE = 10;

const ROSTER_ROLLOVER_STEPS: Record<number, string> = {
  1: 'Add players and staff from a previous roster to your new roster',
  2: 'Review and invite players and staff to register',
};
const TOTAL_STEPS = Object.entries(ROSTER_ROLLOVER_STEPS).length;

const ROSTER_ROLLOVER_NEXT_ACTIONS: Record<number, string> = {
  1: 'Review changes',
  2: 'Complete rollover',
};

export const INITIAL_ROSTER_ROLLOVER_STATE: RosterRolloverState = {
  step: 1,
  members: [],
  showTeamSelectionLoadMoreOption: true,
  teamDataPage: 1,
};

/* RosterRollover */
export function RosterRollover() {
  const { subdomain } = getLAHostnameParts();
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { programId, programType, teamId } = useParams();

  const [state, dispatch] = useReducer(
    rosterRolloverReducer,
    INITIAL_ROSTER_ROLLOVER_STATE
  );

  const {
    step,
    members,
    selectedTeamRoster,
    showTeamSelectionLoadMoreOption,
    teamDataPage,
    openModal,
  } = state;
  const expectedTeamsResultsLength = TEAM_PAGE_SIZE * teamDataPage;

  const [getTeamRoster, { isError: hasGetTeamRosterError }] =
    useLazyGetTeamRosterQuery();

  const {
    data: userId,
    isLoading: isUserLoading,
    isError: hasUserError,
  } = useGetUserIdQuery(siteId);

  const {
    data: program,
    isLoading: isProgramLoading,
    isError: hasProgramError,
  } = useGetProgramQuery({
    siteId,
    programId,
  });

  const {
    data: masterProgram,
    isLoading: isMasterProgramLoading,
    isError: hasMasterProgramError,
  } = useGetProgramQuery(
    {
      siteId,
      programId: program?.masterProgramId?.toString(),
    },
    {
      skip: !program?.masterProgramId,
    }
  );

  const {
    data: teamRoster,
    isLoading: isTeamRosterLoading,
    isError: hasTeamRosterError,
  } = useGetTeamRosterQuery({
    siteSubdomain: subdomain,
    teamId,
  });

  const {
    data: rawTeams,
    isLoading: isTeamsLoading,
    isError: hasTeamsError,
  } = useGetFacadeTeamsQuery(
    {
      pageNum: teamDataPage,
      pageSize: TEAM_PAGE_SIZE,
      role: 'staff',
      userId: userId?.toString() || '',
    },
    { skip: !userId }
  );

  const {
    data: invites,
    isLoading: isInvitesLoading,
    isError: hasInvitesError,
  } = useGetInvitesQuery({ programId, siteSubdomain: subdomain, teamId });

  const teams = useMemo(() => {
    if (rawTeams) {
      return rawTeams.filter((team) => team.id !== teamId);
    }
    return [];
  }, [rawTeams, teamId]);

  /**
   * Toggles the load more button in the team selection modal. There is one scenario
   * where the load more will render when there are actually no more results, and that is
   * when the last page number of teams is equal to the TEAM_PAGE_SIZE count.
   */
  useEffect(() => {
    if (teams) {
      dispatch({
        type: RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption,
        payload: teams.length >= expectedTeamsResultsLength,
      });
    }
  }, [dispatch, expectedTeamsResultsLength, teams]);

  if (
    isUserLoading ||
    isProgramLoading ||
    (program?.masterProgramId && isMasterProgramLoading) ||
    isTeamRosterLoading ||
    isTeamsLoading ||
    isInvitesLoading
  ) {
    return (
      <Page>
        <MainContentCenter>
          <Loader description="We are loading your team data" loading />
        </MainContentCenter>
      </Page>
    );
  }

  if (
    hasUserError ||
    hasProgramError ||
    (program?.masterProgramId && hasMasterProgramError) ||
    hasTeamRosterError ||
    hasTeamsError ||
    hasInvitesError
  ) {
    return (
      <Page>
        <MainContent>
          <ErrorCard message="There was an error loading this page. Please try again in a few seconds." />
        </MainContent>
      </Page>
    );
  }

  const onTeamSelectionClick = (): void => {
    dispatch({
      type: RosterRolloverActionType.ToggleModal,
      payload: ModalName.TeamSelection,
    });
  };

  const onLoadMoreClick = (): void => {
    dispatch({
      type: RosterRolloverActionType.IncrementTeamDataPage,
    });
  };

  const onTeamSelect = (team: Team): void => {
    getTeamRoster({
      siteSubdomain: subdomain,
      teamId: team.id,
    })
      .unwrap()
      .then((teamRoster: TeamRoster) =>
        dispatch({
          type: RosterRolloverActionType.SelectTeamRoster,
          payload: {
            team,
            players: teamRoster.players,
            staff: teamRoster.staff,
          },
        })
      );
  };

  const toggleInviteModal = (open: boolean): void => {
    dispatch({
      type: RosterRolloverActionType.ToggleModal,
      payload: open ? ModalName.Invite : undefined,
    });
  };

  const navigateBack = (): void => {
    if (step > 1) {
      dispatch({
        type: RosterRolloverActionType.UpdateStep,
        payload: step - 1,
      });
    }
  };

  const navigateForward = (): void => {
    if (step === TOTAL_STEPS) {
      // TODO: Add call to API to update roster
      toggleInviteModal(true);
    } else {
      // TODO: Adding mock members to show the reducer action working as expected. This action will actually get
      // dispatched when they're doing actions WITHIN the first step in a future ticket.
      dispatch({
        type: RosterRolloverActionType.UpdateMembers,
        payload: { add: mockRolloverMembers },
      });
      dispatch({
        type: RosterRolloverActionType.UpdateStep,
        payload: step + 1,
      });
    }
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      label: 'Dashboard',
      link: { href: '/dashboard' },
    },
    {
      label: teamRoster?.team.name ?? '',
      link: {
        href: `/app/${programType}/${programId}/teams/${teamId}/roster`,
      },
    },
    {
      label: 'Registration',
    },
  ];

  const totalPlayers = members.filter(
    (member) => member.role === 'Player'
  ).length;
  const totalStaff = members.filter(
    (member) => member.role !== 'Player'
  ).length;

  const renderStep = (): ReactNode => {
    switch (step) {
      case 1:
        return teamRoster ? (
          <RolloverWizard
            destinationTeamRoster={{
              ...teamRoster,
              team: {
                ...teamRoster.team,
                programName: program?.name,
                groupedProgramName: masterProgram?.name,
              },
            }}
            onTeamSelectionClick={onTeamSelectionClick}
            selectedTeamRoster={selectedTeamRoster}
          />
        ) : null;
      case 2:
        return <RolloverSummary members={members} />;
      default:
        return <></>;
    }
  };

  let teamSelectionError;
  if (hasGetTeamRosterError) {
    teamSelectionError = `There was an error retrieving your team's roster. Please try again in a few seconds.`;
  }

  return (
    <Page>
      <PageTitle breadcrumbs={breadcrumbs}>Roster rollover</PageTitle>
      <S.RosterRollover>
        <Stepper
          currentNextAction={ROSTER_ROLLOVER_NEXT_ACTIONS[step]}
          currentStep={ROSTER_ROLLOVER_STEPS[step]}
          error={false}
          form={{}}
          handleNextClick={navigateForward}
          isMC={false}
          numberOfTotalSteps={TOTAL_STEPS}
          onBackClick={navigateBack}
          showDrawer
          stepNumber={step}
          showSteps
          type="button"
        >
          {renderStep()}
        </Stepper>
      </S.RosterRollover>
      {teams ? (
        <TeamSelectionModal
          error={teamSelectionError}
          onLoadMoreClick={onLoadMoreClick}
          onOpenChange={(open: boolean) => {
            if (!open) {
              dispatch({ type: RosterRolloverActionType.ToggleModal });
            }
          }}
          onTeamSelect={onTeamSelect}
          open={openModal === ModalName.TeamSelection}
          selectedTeam={selectedTeamRoster?.team}
          showLoadMoreOption={showTeamSelectionLoadMoreOption}
          teams={teams}
        />
      ) : null}
      {invites ? (
        <InviteModal
          invitationLinks={invites}
          onOpenChange={toggleInviteModal}
          open={openModal === ModalName.Invite}
          totalPlayers={totalPlayers}
          totalStaff={totalStaff}
        />
      ) : null}
    </Page>
  );
}
/* */
