"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnv = exports.Domain = exports.Env = exports.getBaseURL = exports.apiDomainBySiteDomain = exports.getLAHostnameParts = void 0;
/* eslint-disable */
/**
 * Takes a URL and separates its hostname into subdomain, sld (second-level
 * domain) and tld (top-level domain) and returns these as an object.
 * If the hostname does not have a subdomain it will substitute an empty string
 * for this section.
 * NOTE: This function will ONLY work with hostnames that are 2 or 3 sections
 * long. It will not, for example, work with international TLDs like `co.uk`
 * @returns {{subdomain: string, sld: string, tld: string}}
 */
function getLAHostnameParts() {
    var _a;
    var hostname = window.location.hostname;
    var hostnameArray = hostname.split('.');
    return {
        subdomain: (_a = hostnameArray[hostnameArray.length - 3]) !== null && _a !== void 0 ? _a : '',
        sld: hostnameArray[hostnameArray.length - 2],
        tld: hostnameArray[hostnameArray.length - 1],
    };
}
exports.getLAHostnameParts = getLAHostnameParts;
exports.apiDomainBySiteDomain = new Map([
    ['lapps-cicd1', 'lapps-cicd1.io'],
    ['lapps-dev1', 'lapps-dev1.io'],
    ['lapps-dev2', 'sportvite.net'],
    ['lapps-dev3', 'lapps-dev3.io'],
    ['lapps-eng', 'lapps-eng.net'],
    ['lapps-local', 'lapps-local.com'],
    ['lapps-preview', 'lapps-preview.net'],
    ['lapps-qa1', 'lapps-qa1.io'],
    ['lapps-qa2', 'lmstaging.com'],
    ['lapps-sandbox', 'lapps-sandbox.io'],
    ['leagueapps', 'leagueapps.io'],
]);
function getBaseURL() {
    var _a = getLAHostnameParts(), subdomain = _a.subdomain, sld = _a.sld, tld = _a.tld;
    var apiDomain = exports.apiDomainBySiteDomain.get(sld);
    return apiDomain
        ? "https://api.".concat(apiDomain)
        : "https://".concat(subdomain, ".").concat(sld, ".").concat(tld);
}
exports.getBaseURL = getBaseURL;
/**
 * Checks if `str` is in a valid email format (aaa@bbb.ccc).
 * @param str string to be compared to email format.
 * @returns true if in valid email format, false otherwise.
 */
var Env;
(function (Env) {
    Env[Env["CICD1"] = 0] = "CICD1";
    Env[Env["Local"] = 1] = "Local";
    Env[Env["Dev1"] = 2] = "Dev1";
    Env[Env["Dev2"] = 3] = "Dev2";
    Env[Env["Dev3"] = 4] = "Dev3";
    Env[Env["QA1"] = 5] = "QA1";
    Env[Env["QA2"] = 6] = "QA2";
    Env[Env["Preview"] = 7] = "Preview";
    Env[Env["Production"] = 8] = "Production";
})(Env = exports.Env || (exports.Env = {}));
var Domain;
(function (Domain) {
    Domain["CICD1"] = "lapps-cicd1";
    Domain["Local"] = "lapps-local";
    Domain["Dev1"] = "lapps-dev1";
    Domain["Dev2"] = "lapps-dev2";
    Domain["Dev3"] = "lapps-dev3";
    Domain["QA1"] = "lapps-qa1";
    Domain["QA2"] = "lapps-qa2";
    Domain["Preview"] = "lapps-preview";
    Domain["Production"] = "leagueapps";
})(Domain = exports.Domain || (exports.Domain = {}));
function getEnv() {
    var sld = getLAHostnameParts().sld;
    switch (sld) {
        case Domain.Production:
            return Env.Production;
        case Domain.Dev1:
            return Env.Dev1;
        case Domain.Dev2:
            return Env.Dev2;
        case Domain.Dev3:
            return Env.Dev3;
        case Domain.QA1:
            return Env.QA1;
        case Domain.QA2:
            return Env.QA2;
        case Domain.Local:
            return Env.Local;
        case Domain.CICD1:
            return Env.CICD1;
        case Domain.Preview:
            return Env.Preview;
        default:
            return Env.Local;
    }
}
exports.getEnv = getEnv;
