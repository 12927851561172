import { Badge } from '@la/ds-ui-components';
import { Team as TeamType } from '@la/types';
import { TeamDetails } from '../../TeamDetails/TeamDetails';
import * as S from './Team.styles';

export const SELECTED_TEXT = 'Current selection';

export type TeamProps = {
  /**
   * Triggered when the component is clicked
   * @param team The team that was clicked
   */
  onClick?: (team: TeamType) => void;
  /**
   * Whether or not the team should render in a "selected" state. Determines
   * whether or not the selection badge renders.
   */
  selected?: boolean;
  /**
   * The team whose detials are rendered.
   */
  team: TeamType;
};

export function Team({ onClick, selected = false, team }: Readonly<TeamProps>) {
  return (
    <S.Team
      $hasClickHandler={!!onClick}
      $selected={selected}
      onClick={() => {
        if (onClick) {
          onClick(team);
        }
      }}
      type="button"
    >
      <S.TeamDetails>
        <S.Name size="medium" variant="ui" weight="bold">
          {team.name}
        </S.Name>
        <TeamDetails team={team} />
      </S.TeamDetails>
      {selected ? (
        <S.SelectedBadge>
          <Badge size="small" text={SELECTED_TEXT} />
        </S.SelectedBadge>
      ) : null}
    </S.Team>
  );
}
