import styled from 'styled-components/macro';

export type ChipSize = 'medium' | 'large';

export const Chip = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const Border = styled.div<{ $canDelete?: boolean; $disabled: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid var(--blue-grey-150);
  ${({ $canDelete, $disabled }) => {
    if ($canDelete && !$disabled) {
      return `
        &:hover {
          border-color: var(--green-500);
        }
        &:focus {
          border-color: var(--green-700);
          outline: none;
        }
      `;
    }
    return null;
  }}
`;

export const DeleteButton = styled.button`
  background: none;
  position: relative;
  order: 2;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 2px 4px;
  padding: 1px 1px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      svg {
        fill: var(--blue-grey-400);
      }
      + ${Border}:not(:hover) {
        border-color: var(--red);
        pointer-events: none;
      }
    }

    &:active svg {
      fill: var(--red);
    }
  }

  svg {
    fill: var(--blue-grey-200);
  }
`;

export const Label = styled.span<{
  $canDelete?: boolean;
  $isZero: boolean;
  $size: ChipSize;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $canDelete }) => ($canDelete ? '3px 0 3px 10px' : '3px 10px')};
  font: ${({ $size }) =>
    $size === 'medium'
      ? 'var(--ui-small-regular)'
      : 'var(--ui-medium-regular)'};
  color: ${({ $isZero }) =>
    $isZero ? 'var(--blue-grey-400)' : 'var(--blue-grey-900)'};
`;

export const Badge = styled.div`
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  font: var(--ui-small-medium);
  line-height: 16px;
  text-align: center;
  color: var(--white);
  border-radius: 8px;
  background-color: var(--secondary-600);
`;
