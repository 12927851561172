"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDivisionSubtotal = void 0;
/**
 * Returns the subtotal for a division
 *
 * @param costPerTeam A currency value with at most two decimal places, for example `5.49`
 * @param numberOfTeams Should be a non-negative integer
 */
function getDivisionSubtotal(costPerTeam, numberOfTeams) {
    return (costPerTeam * 100 * numberOfTeams) / 100;
}
exports.getDivisionSubtotal = getDivisionSubtotal;
