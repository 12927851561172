import styled from 'styled-components/macro';

export const AlertContainer = styled.div({
  '& > div': {
    marginTop: 0,
  },
});

export const Info = styled.div({
  margin: '24px 0',
});
