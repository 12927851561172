import { http, HttpResponse } from 'msw';
import { brandingThemeHandlers } from './brandingTheme';
import { cartHandlers } from './cart';
import { checkoutHandlers } from './checkout';
import { countryHandlers } from './country';
import { headerHandlers } from './header';
import { plaidHandlers } from './plaid';
import { registrationHandlers } from './registration';
import { rosterManagementHandlers } from './rosterManagement';
import { siteHandlers } from './site';
import { socialMediaHandlers } from './socialMedia';
import { staffRoleHandlers } from './staffRole';
import { teamHandlers } from './team';
import { tournamentHandlers } from './tournament';

export const apiHandlers = [
  ...siteHandlers,
  ...brandingThemeHandlers,
  ...checkoutHandlers,
  ...countryHandlers,
  ...headerHandlers,
  ...cartHandlers,
  ...registrationHandlers,
  ...rosterManagementHandlers,
  ...socialMediaHandlers,
  ...staffRoleHandlers,
  ...teamHandlers,
  ...tournamentHandlers,
  ...plaidHandlers,
];

export const fallThroughHandler = http.get('*', () => {
  // console.error(`Please add request handler for ${req.url.toString()}`);
  return HttpResponse.json(
    { error: 'Please add request handler' },
    { status: 500 }
  );
});
