"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxZipCodeHandlers = exports.maskZipCodeKeyDown = exports.maskZipCodeKeyUp = exports.ZIP_CODE_MAX_LENGTH = void 0;
exports.ZIP_CODE_MAX_LENGTH = 5;
var ALLOWED_ACTIONS = [
    'Backspace',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
];
var maskZipCodeKeyUp = function (e) {
    var value = e.currentTarget.value;
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    value = value.replace(/[^0-9]/g, '').substring(0, exports.ZIP_CODE_MAX_LENGTH);
    return value;
};
exports.maskZipCodeKeyUp = maskZipCodeKeyUp;
var maskZipCodeKeyDown = function (e) {
    var _a;
    var value = (_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '';
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    if (!e.ctrlKey && !e.metaKey && isNaN(Number(e.key))) {
        e.preventDefault();
    }
    return value;
};
exports.maskZipCodeKeyDown = maskZipCodeKeyDown;
var getMaxZipCodeHandlers = function (
// eslint-disable-next-line no-unused-vars
callback) { return ({
    onKeyUp: function (e) {
        return callback((0, exports.maskZipCodeKeyUp)(e));
    },
    onKeyDown: function (e) {
        return callback((0, exports.maskZipCodeKeyDown)(e));
    },
}); };
exports.getMaxZipCodeHandlers = getMaxZipCodeHandlers;
