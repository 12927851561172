"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlideOutMenu = exports.SlideOutMenuPrimaryActionButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var S = require("./SlideOutMenu.styles");
exports.SlideOutMenuPrimaryActionButton = (0, react_1.forwardRef)(function (_a, ref) {
    var size = _a.size, disabled = _a.disabled, loading = _a.loading, props = __rest(_a, ["size", "disabled", "loading"]);
    return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({}, props, { disabled: disabled, loading: loading, size: "large", variant: "primary", width: "100%", ref: ref })));
});
function SlideOutMenu(_a) {
    var children = _a.children, onClose = _a.onClose, primaryAction = _a.primaryAction, _b = _a.side, side = _b === void 0 ? 'left' : _b, title = _a.title, open = _a.open;
    var _c = (0, react_1.useState)(open), showMenu = _c[0], setShowMenu = _c[1];
    var menuContainerRef = (0, react_1.useRef)(null);
    var onAnimationEnd = function () {
        if (!open) {
            setShowMenu(false);
        }
    };
    (0, react_1.useEffect)(function () {
        if (open) {
            setShowMenu(true);
        }
    }, [open]);
    (0, react_1.useEffect)(function () {
        var outsideClickHandler = function (e) {
            if (!!menuContainerRef.current) {
                (0, utilities_1.detectOutsideEvent)(e, [menuContainerRef.current], function () { return onClose(); });
            }
        };
        window.addEventListener('pointerdown', outsideClickHandler);
        return function () {
            window.removeEventListener('pointerdown', outsideClickHandler);
        };
    }, [onClose]);
    var primaryActionButton = primaryAction ? ((0, jsx_runtime_1.jsx)(S.MenuActionsFooter, { children: primaryAction })) : null;
    return showMenu ? ((0, jsx_runtime_1.jsx)(S.MenuBackdrop, __assign({ "$transparent": false, "$open": open, onAnimationEnd: onAnimationEnd }, { children: (0, jsx_runtime_1.jsxs)(S.MenuContainer, __assign({ "aria-hidden": !open, ref: menuContainerRef, "$open": open, "$side": side, onAnimationEnd: onAnimationEnd }, { children: [(0, jsx_runtime_1.jsxs)(S.MenuHeader, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "headline", size: "small" }, { children: title })), (0, jsx_runtime_1.jsx)(S.HeaderCloseIcon, { ariaLabel: "Close", icon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.CloseIcon, { fill: "white" }), onClick: onClose, variant: "text" })] }), (0, jsx_runtime_1.jsx)(S.MenuBody, { children: children }), primaryActionButton] })) }))) : null;
}
exports.SlideOutMenu = SlideOutMenu;
