"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseIconContainer = exports.AlertContainer = void 0;
var styled_components_1 = require("styled-components");
exports.AlertContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 56px;\n  padding: 16px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: ", ";\n  margin-top: 24px;\n  grid-column-start: 1;\n  grid-column-end: -1;\n\n  & p {\n    margin-left: 12px;\n    flex: 1;\n  }\n  & svg {\n    fill: ", ";\n  }\n"], ["\n  width: 100%;\n  height: 56px;\n  padding: 16px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: ", ";\n  margin-top: 24px;\n  grid-column-start: 1;\n  grid-column-end: -1;\n\n  & p {\n    margin-left: 12px;\n    flex: 1;\n  }\n  & svg {\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.foundational.red[25];
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.warning.dark;
}, function (_a) {
    var theme = _a.theme;
    return theme.foundational.red[500];
});
exports.CloseIconContainer = styled_components_1.default.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n  & svg {\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n  & svg {\n    cursor: pointer;\n  }\n"])));
var templateObject_1, templateObject_2;
