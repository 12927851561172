import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled from 'styled-components';
export const PopoverContent = styled(PopoverPrimitive.Content).withConfig({
  componentId: "sc-f97mjg-0"
})(["width:", ";padding:", ";border:1px solid var(--secondary-150);border-radius:8px;background:var(--white);transform:none;word-wrap:break-word;", ";", ";& span:last-child{left:12px !important;}"], ({
  $width
}) => `${$width}px`, ({
  $hasPadding
}) => $hasPadding ? `12px` : '0px', ({
  $maxHeight
}) => $maxHeight ? `max-height: ${$maxHeight}` : null, ({
  $scrollable
}) => $scrollable ? 'overflow: auto' : null);
export const PopoverArrow = styled(PopoverPrimitive.Arrow).withConfig({
  componentId: "sc-f97mjg-1"
})(["transform:rotate(180deg);width:33px;height:10px;"]);
export const ArrowContainer = styled.div.withConfig({
  componentId: "sc-f97mjg-2"
})(["position:relative;top:5px;"]);