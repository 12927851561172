import styled, { css, keyframes } from 'styled-components/macro';
import { Button, IconButton, ThemedComponent } from '@la/ds-ui-components';

export const TRANSITION_DURATION: number = 350;
export type DaysTransitionDirection = 'previous' | 'next';

const fadeContentIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const fadeAnimation = css`
  250ms ${fadeContentIn} cubic-bezier(0.4, 0, 0.2, 1) forwards
`;

export const Calendar = styled.div`
  background: var(--white);
  width: 320px;
  border-radius: 4px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

export const Header = styled.div<{ $isSelectingYear: boolean }>`
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ $isSelectingYear }) => {
    if ($isSelectingYear) {
      return `
        position: sticky;
        top: 0;
      `;
    }
    return null;
  }}
  z-index: 1;
`;

type YearSelectionToggleProps = ThemedComponent & { $isSelectingYear: boolean };
export const YearSelectionToggle = styled.div<YearSelectionToggleProps>(
  ({ $isSelectingYear, theme }: YearSelectionToggleProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: 28,
    height: 28,
    borderRadius: '100%',
    '& svg': {
      stroke: theme.semantic?.secondary[500],
      strokeWidth: 24,
      transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      ...($isSelectingYear ? { transform: 'rotate(180deg)' } : {}),
    },
  })
);

export const MonthYear = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  border: none;
  cursor: pointer;
`;

export const Month = styled.div`
  color: var(--blue-grey-900);
  font: var(--ui-large-medium);
  animation: ${fadeAnimation};
`;

export const MonthNavigation = styled.div`
  display: flex;
`;

// This div is necessary for the SVG to render on iOS.
export const MonthNavigationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 28px;
  height: 28px;
  margin: 0 5px;
  border-radius: 100%;
`;

export const MonthNavigationButton = styled(IconButton)<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    '& svg': {
      stroke: theme.semantic?.secondary[500],
      strokeWidth: 24,
    },
  })
);

export const Body = styled.div<{ $isSelectingYear: boolean }>`
  ${({ $isSelectingYear }) => {
    if ($isSelectingYear) {
      return `
        height: 254px;
        overflow-y: scroll;
      `;
    }
    return null;
  }}
  opacity: 1;
  animation: ${fadeAnimation};
`;

export const CalendarYears = styled.table`
  width: 100%;
  margin: 0 auto;
`;

export const CalendarYear = styled.td`
  font: var(--ui-large-regular);
  text-align: center;
  vertical-align: middle;
`;

export const Year = styled.div<{ $selected: boolean }>`
  ${({ $selected }) => ($selected ? 'background: var(--green-500);' : null)}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: ${({ $selected }) =>
    $selected ? 'var(--white)' : 'var(--blue-grey-900)'};
  margin: 6px 0;
  padding: 1px 6px;
  font: ${({ $selected }) =>
    $selected ? 'var(--ui-large-bold)' : 'var(--ui-large-regular)'};
  border-radius: 18px;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    ${({ $selected }) => {
      if (!$selected) {
        return `
          background: var(--green-50);
          color: var(--green-700);
        `;
      }
      return null;
    }}
  }
`;

export const CalendarDays = styled.div`
  padding: 8px 8px 10px;
`;

export const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 6px 0;
`;

export const WeekDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  color: var(--blue-grey-300);
  font: var(--ui-small-regular);
`;

const slideIn = ($direction?: DaysTransitionDirection) => keyframes`
  0% {
    position: relative;
    transform: translate(${$direction === 'previous' ? '-100%' : '100%'});
    transition: transform 350ms cubic-bezier(0.35, 0.8, 0.4, 1) 0ms;
  }
  100% { position: relative; transform: translate(0%); }
`;

const slideInAnimation = ({
  $direction,
}: {
  $direction?: DaysTransitionDirection;
}) => css`
  350ms ${slideIn($direction)}
`;

const slideOut = ($direction?: DaysTransitionDirection) => keyframes`
  0% {
    position: absolute;
    transform: translate(0%);
    transition: transform 350ms cubic-bezier(0.35, 0.8, 0.4, 1) 0ms;
  }
  100% { position: absolute; transform: translate(${
    $direction === 'previous' ? '100%' : '-100%'
  }); }
`;

const slideOutAnimation = ({
  $direction,
}: {
  $direction?: DaysTransitionDirection;
}) => css`
  350ms ${slideOut($direction)}
`;

export const Days = styled.div<{ $direction?: DaysTransitionDirection }>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

export const DaysContainer = styled.div<{
  $direction?: DaysTransitionDirection;
}>`
  display: flex;
  position: relative;

  ${Days} {
    &:first-child {
      animation: ${({ $direction }) => {
        if (!!$direction) {
          return slideInAnimation;
        }
        return 'none';
      }};
    }
    &:nth-child(2) {
      animation: ${({ $direction }) => {
        if (!!$direction) {
          return slideOutAnimation;
        }
        return 'none';
      }};
    }
  }
`;

export const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 4px 0;
`;

export const Day = styled.div<{
  $disabled?: boolean;
  $isToday?: boolean;
  $selected?: boolean;
}>`
  background: ${({ $selected }) =>
    $selected ? 'var(--green-500)' : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: ${({ $selected }) =>
    $selected ? 'var(--white)' : 'var(--blue-grey-900)'};
  font: var(--ui-medium-regular);
  text-align: center;
  border: ${({ $isToday, $selected }) => {
    if ($selected) {
      return '1px solid var(--green-500)';
    } else if ($isToday) {
      return '1px solid rgba(0, 0, 0, 0.6)';
    }
    return 'none';
  }};
  border-radius: 18px;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};

  &:hover,
  &:focus {
    ${({ $disabled, $selected }) => {
      if (!$disabled && !$selected) {
        return `
          background: var(--green-50);
          color: var(--green-700);
        `;
      }
      return null;
    }}
  }
`;

export const TodayButton = styled(Button)({
  padding: '4px 6px',
  fontWeight: '400',
});
