import { useReducer } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  registrationWizardReducers,
  RegistrationWizardSliceState,
} from '../WizardState';

type UseWizardStateParams = {
  initialState: RegistrationWizardSliceState;
};

export function useWizardState({ initialState }: UseWizardStateParams) {
  const {
    actions: {
      selectDivision,
      cancelDivisionChanges,
      addTeamSelect,
      discardTeamSelect,
      selectTeam,
      saveDivision,
      editDivision,
      clearDivision,
      submit,
      clearIdToScrollTo,
    },
    reducer,
  } = createSlice({
    name: 'registrationWizard',
    initialState,
    reducers: registrationWizardReducers,
  });

  const [state, dispatch] = useReducer(reducer, initialState);
  const { teamSelectionVisualUpdates } = useFlags();

  return {
    ...state,
    selectDivision: (divisionId: string) =>
      dispatch(selectDivision({ divisionId })),
    cancelDivisionChanges: (divisionId: string) =>
      dispatch(cancelDivisionChanges({ divisionId })),
    addTeamSelect: (divisionId: string) =>
      dispatch(addTeamSelect({ divisionId })),
    discardTeamSelect: (divisionId: string, selectIndex: number) =>
      dispatch(discardTeamSelect({ divisionId, selectIndex })),
    selectTeam: (divisionId: string, selectIndex: number, teamId: string) =>
      dispatch(
        selectTeam({
          divisionId,
          selectIndex,
          teamId,
        })
      ),
    saveDivision: (divisionId: string) =>
      dispatch(saveDivision({ divisionId, teamSelectionVisualUpdates })),
    editDivision: (divisionId: string) =>
      dispatch(editDivision({ divisionId })),
    clearDivision: (divisionId: string) =>
      dispatch(clearDivision({ divisionId })),
    submit: () => dispatch(submit()),
    clearIdToScrollTo: () => dispatch(clearIdToScrollTo()),
  };
}
