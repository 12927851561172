const ACTION_KEYS = ['ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', ' '];
export const moveFocusOnKeyDown = (event, container) => {
  // select should not be navigated using tab key so we prevent it
  if (event.key === 'Tab') {
    event.preventDefault();
  }
  if (!container) {
    return;
  }
  const options = Array.from(container.querySelectorAll('div[role="option"]')).filter(item => !item.getAttribute('data-disabled'));
  if (ACTION_KEYS.includes(event.key)) {
    const currentElement = event.target;
    const currentIndex = options.indexOf(currentElement);
    switch (event.key) {
      case 'Home':
        {
          options[0].focus();
          break;
        }
      case 'End':
        {
          options[options.length - 1].focus();
          break;
        }
      case 'ArrowUp':
        {
          options[currentIndex < 1 ? options.length - 1 : currentIndex - 1].focus();
          break;
        }
      case 'ArrowDown':
        {
          options[(currentIndex + 1) % options.length].focus();
          break;
        }
      case ' ':
      case 'Enter':
        {
          const option = options[currentIndex];
          if (option) {
            options[currentIndex].click();
          }
          break;
        }
    }
    event.preventDefault();
  }
};