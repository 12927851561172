import { PropsWithChildren } from 'react';
import { Button } from '@la/ds-ui-components';
import { Card, CardTitle } from 'components/Card/Card';
import * as S from './CheckoutCard.styles';

export type CheckoutCardProps = {
  cardName?: string;
  errorMessage?: string;
};

export type CheckoutCardPrimaryButtonProps = {
  label: string;
  clickHandler: () => void;
};

/*CheckoutCard*/
export default function CheckoutCard({
  cardName,
  children,
  errorMessage,
}: PropsWithChildren<CheckoutCardProps>) {
  return (
    <Card errorMessage={errorMessage}>
      {cardName ? <CheckoutCardHeader name={cardName} /> : null}
      {children}
    </Card>
  );
}
/* */

/* CheckoutCardHeader*/
export function CheckoutCardHeader({ name }: { name: string }) {
  return (
    <S.CheckoutCardHeader>
      <CardTitle>{name}</CardTitle>
    </S.CheckoutCardHeader>
  );
}
/* */

/*CheckoutCardBody */
export function CheckoutCardBody({ children }: PropsWithChildren) {
  return <S.CheckoutCardBody>{children}</S.CheckoutCardBody>;
}
/* */

/* CheckoutCardFooter*/
export function CheckoutCardFooter({ children }: PropsWithChildren) {
  return <S.CheckoutCardFooter>{children}</S.CheckoutCardFooter>;
}
/* */

/* CheckoutCardPrimaryButton*/
export function CheckoutCardPrimaryButton({
  label,
  clickHandler,
}: CheckoutCardPrimaryButtonProps) {
  return (
    <Button onClick={clickHandler} variant="outline" size="medium">
      {label}
    </Button>
  );
}
/* */
