import styled, { css } from 'styled-components';
import { buttonHeight, buttonPadding, getButtonWidth, getIconButtonSize } from 'theming/tokens/components/button';
export const ButtonWrapper = styled.div.withConfig({
  componentId: "sc-ty8pka-0"
})(({
  $disabled,
  $width
}) => {
  return {
    cursor: $disabled ? 'not-allowed' : 'pointer',
    width: getButtonWidth($width)
  };
});
export const ButtonBaseStyles = css(({
  theme,
  $size = 'medium',
  $hasPadding,
  $loading
}) => {
  const {
    typography,
    spacing,
    semantic,
    palette: {
      foundational: {
        blueGrey,
        green
      }
    }
  } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: $size === 'small' ? spacing.xxxs : spacing.xxs,
    height: buttonHeight[$size],
    width: '100%',
    borderRadius: spacing.xxxs,
    cursor: 'pointer',
    padding: !$hasPadding ? 0 : buttonPadding[$size],
    ...typography.ui[$size],
    fontWeight: typography.weights.bold,
    pointerEvents: $loading ? 'none' : 'all',
    textDecoration: 'none',
    '&[disabled]': {
      pointerEvents: 'none'
    },
    '&:focus-within': {
      outline: '2px solid',
      outlineOffset: '1px',
      outlineColor: semantic ? blueGrey[600] : green[600]
    }
  };
});
export const ButtonFilledStyles = css(({
  theme,
  $disabled
}) => {
  const {
    semantic,
    palette: {
      foundational: {
        green,
        white,
        blueGrey
      }
    }
  } = theme;
  const backgroundColor = semantic ? semantic.secondary.base : green[600];
  const borderColor = semantic ? semantic.secondary[600] : green[600];
  const color = semantic ? semantic.secondary.reactiveTextColor : white;
  return {
    border: '1px solid',
    backgroundColor: $disabled ? blueGrey[50] : backgroundColor,
    borderColor: $disabled ? blueGrey[50] : borderColor,
    color: $disabled ? blueGrey[400] : color,
    '& svg': {
      fill: color
    },
    '&:hover': {
      backgroundColor: semantic ? semantic.secondary.hover : green[800],
      borderColor: semantic ? semantic.secondary[600] : green[800]
    },
    '&:active': {
      backgroundColor: semantic ? semantic.secondary.active : green[900],
      borderColor: semantic ? semantic.secondary[600] : green[900]
    }
  };
});
export const ButtonOutlineStyles = css(({
  theme,
  $disabled
}) => {
  const {
    semantic,
    palette: {
      foundational: {
        green,
        blueGrey
      }
    }
  } = theme;
  const borderColor = {
    default: semantic ? semantic.secondary.base : blueGrey[400],
    hover: semantic ? semantic.secondary.hover : green[800],
    active: semantic ? semantic.secondary.active : green[900]
  };
  const color = {
    default: blueGrey[900],
    hover: semantic ? semantic.secondary[800] : green[800],
    active: semantic ? semantic.secondary[900] : green[900]
  };
  return {
    border: '1px solid',
    borderColor: $disabled ? blueGrey[200] : borderColor.default,
    backgroundColor: 'transparent',
    color: $disabled ? blueGrey[200] : color.default,
    '& svg': {
      fill: semantic ? semantic.secondary[600] : green[600]
    },
    '&:hover': {
      borderColor: $disabled ? blueGrey[200] : borderColor.hover,
      color: $disabled ? blueGrey[200] : color.hover,
      '& svg': {
        fill: $disabled ? blueGrey[200] : color.hover
      }
    },
    '&:active': {
      borderColor: $disabled ? blueGrey[200] : borderColor.active,
      backgroundColor: $disabled ? 'transparent' : blueGrey[50],
      color: $disabled ? blueGrey[200] : color.active,
      '& svg': {
        fill: $disabled ? blueGrey[200] : color.active
      }
    }
  };
});
export const ButtonTextStyles = css(({
  theme,
  $disabled,
  $hasPadding,
  $size
}) => {
  const {
    semantic,
    palette: {
      foundational: {
        green,
        blueGrey,
        white
      }
    }
  } = theme;
  const color = {
    default: blueGrey[900],
    hover: semantic ? semantic.secondary[800] : green[800],
    active: semantic ? semantic.secondary[900] : green[900]
  };
  const iconBackground = {
    default: semantic ? semantic.secondary[600] : green[600],
    hover: semantic ? semantic.secondary.hover : green[800],
    active: semantic ? semantic.secondary.active : green[900]
  };
  const iconColor = semantic ? semantic.secondary.reactiveTextColor : white;
  return {
    border: 'none',
    backgroundColor: 'transparent',
    padding: !$hasPadding ? 0 : undefined,
    height: !$hasPadding ? 'fit-content' : undefined,
    textDecoration: !$hasPadding ? 'underline' : 'none',
    color: $disabled ? blueGrey[200] : color.default,
    '& span': {
      backgroundColor: $size === 'small' ? iconBackground.default : 'transparent',
      '& svg': {
        fill: $size === 'small' ? iconColor : iconBackground.default
      }
    },
    '&:hover': {
      color: $disabled ? blueGrey[200] : color.hover,
      '& span': {
        backgroundColor: $size === 'small' ? iconBackground.hover : 'transparent',
        '& svg': {
          fill: $size === 'small' ? iconColor : iconBackground.hover
        }
      }
    },
    '&:active': {
      color: $disabled ? blueGrey[200] : color.active,
      '& span': {
        backgroundColor: $size === 'small' ? iconBackground.active : 'transparent',
        '& svg': {
          fill: $size === 'small' ? iconColor : iconBackground.active
        }
      }
    }
  };
});
export const ButtonBase = styled.button.withConfig({
  componentId: "sc-ty8pka-1"
})(() => ButtonBaseStyles);
export const ButtonFilled = styled(ButtonBase).withConfig({
  componentId: "sc-ty8pka-2"
})(() => ButtonFilledStyles);
export const ButtonOutline = styled(ButtonBase).withConfig({
  componentId: "sc-ty8pka-3"
})(() => ButtonOutlineStyles);
export const ButtonText = styled(ButtonBase).withConfig({
  componentId: "sc-ty8pka-4"
})(() => ButtonTextStyles);
export const IconContainer = styled.span.withConfig({
  componentId: "sc-ty8pka-5"
})(({
  $buttonSize,
  $hasPadding
}) => {
  const isSmall = $buttonSize === 'small';
  const iconSize = getIconButtonSize($buttonSize);
  return {
    display: 'flex',
    alignItems: 'center',
    textDecoration: !$hasPadding ? 'underline' : 'none',
    borderRadius: isSmall || !$hasPadding ? '50%' : undefined,
    padding: isSmall || !$hasPadding ? 0 : undefined,
    minHeight: isSmall || !$hasPadding ? 'auto' : undefined,
    '& svg': {
      height: iconSize,
      width: iconSize
    }
  };
});