const defaultOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

/**
 * Given a date format it's time based on the parts and style of the time object.
 *
 *
 * @param Date A number (epoch) or a Date
 * @param parts The part of the date to include. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts#description
 * @param options Options object from Intl.DateTimeFormat. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_options
 * @param locales The format of the language used in the user interface. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_locales
 * @default en-US
 * @returns A string displaying the formatted time
 *
 */
const formatTime = ({
  date,
  parts = ['hour', 'minute'],
  options = {},
  locales = 'en-US'
}) => {
  const dateFormatOptions = {
    ...defaultOptions,
    ...options
  };
  const formattedDate = new Intl.DateTimeFormat(locales, dateFormatOptions).formatToParts(date).filter(p => parts.includes(p.type) || ['dayPeriod', 'literal'].includes(p.type)).map(p => p.value).reduce((p, c) => p + c);

  // When we add an extra literal from a stripped second you get an array as follows: [..., ":", "", "A","M"]
  // So if there's a `dayPeriod` indicator the index of the extra indicator will be -4 otherwise it will be the last element (-1);
  const extraIndicatorIndex = defaultOptions.hour12 ? -4 : -1;
  if (formattedDate.at(extraIndicatorIndex) === ':') {
    const formattedDateArray = formattedDate.split('');
    formattedDateArray.splice(extraIndicatorIndex, 1);
    return formattedDateArray.join('');
  }
  return formattedDate;
};
export { formatTime };