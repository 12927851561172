import { Root, Thumb } from '@radix-ui/react-switch';
import styled from 'styled-components';
export const SwitchContainer = styled.div.withConfig({
  componentId: "sc-1f9hi7i-0"
})(["display:flex;gap:8px;align-items:center;margin-left:", ";&[data-disabled]{cursor:not-allowed;}"], ({
  $marginLeft
}) => $marginLeft ? `${$marginLeft + 24}px` : 0);
export const SwitchRoot = styled(Root).withConfig({
  componentId: "sc-1f9hi7i-1"
})(["height:16px;width:32px;background-color:var(--secondary-400);cursor:pointer;outline-width:2px;outline-offset:4px;outline-color:var(--primary-600);border:none;border-radius:8px;padding:0;display:flex;align-items:center;padding-inline:2px;&[data-disabled]{background-color:var(--secondary-200);pointer-events:none;}&[data-state='checked']{background-color:var(--primary-600);&:active{background-color:var(--primary-800);}&:hover{> span{outline:4px solid var(--primary-800);}}&[data-disabled]{background-color:var(--primary-200);}}&:hover{> span{outline:4px solid var(--secondary-700);}}&:active{background-color:var(--secondary-700);}"]);
export const SwitchThumb = styled(Thumb).withConfig({
  componentId: "sc-1f9hi7i-2"
})(["height:12px;width:12px;border-radius:50%;display:flex;background-color:var(--white);margin:0;border-sizing:content-box;will-change:transform;transition:transform 100ms;&[data-state='checked']{transform:translateX(16px);}"]);
export const SwitchLabel = styled.label.withConfig({
  componentId: "sc-1f9hi7i-3"
})(["color:var(--primary-800);font:var(--ui-large-regular);cursor:pointer;&[data-disabled]{pointer-events:none;}"]);
export const SwitchRequiredIcon = styled.span.withConfig({
  componentId: "sc-1f9hi7i-4"
})(["color:var(--red);font:var(--ui-size-small);margin-left:4px;&::after{content:'*';}"]);