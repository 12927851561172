import styled from 'styled-components';
import { CaretDownIcon } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const SuccessCardWrapper = styled.div<{ $role: string }>(({ $role }) => {
  if ($role === 'player') {
    return {};
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

export const SuccessCardContainer = styled.div<{ $role: string }>(
  ({ $role }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      textAlign: $role === 'player' ? 'left' : 'center',
      gap: 8,
      marginBottom: 8,
      maxWidth: $role === 'player' ? '100%' : '668px',
    };
  }
);

export const CardsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & div {
    width: 100%;
  }

  ${media.TabletLandscapeUp`
    flex-direction: row;
  `}
`;

export const DescriptionContainer = styled.div`
  min-height: 92px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${media.TabletPortraitDown`
    flex-direction: column;
    & div {
      width: 100%;
      & button {
        width: 100%;
      }
    }
  `}
`;

export const AccordionCaretContainer = styled.div<{ $isOpened: boolean }>(
  ({ $isOpened }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: $isOpened ? 'rotate(180deg)' : 'rotate(0)',
    transition: 'transform 120ms linear',
    willChange: 'transform',
  })
);

export const AccordionCaret = styled(CaretDownIcon).attrs(() => ({
  size: 'small',
  variant: 'filled',
}))({});

export const StaffCardContainer = styled.div`
  max-width: 668px;
  align-self: center;
`;

export const HotelLink = styled.div`
  & > div,
  a {
    display: inline-flex;
  }
`;
