"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseIcon = exports.ErrorMessage = exports.ErrorIcon = exports.ErrorCard = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.ErrorCard = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  margin: 0 0 16px 0;\n  padding: 16px;\n  border-radius: 8px;\n  border: 1px solid var(--red);\n  background-color: #ffeeee;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  margin: 0 0 16px 0;\n  padding: 16px;\n  border-radius: 8px;\n  border: 1px solid var(--red);\n  background-color: #ffeeee;\n"])));
exports.ErrorIcon = (0, styled_components_1.default)(ds_ui_components_1.ExclamationCircleIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-basis: 18px;\n  width: 18px;\n  margin: 2px 0 0 0;\n  fill: var(--red);\n"], ["\n  flex-basis: 18px;\n  width: 18px;\n  margin: 2px 0 0 0;\n  fill: var(--red);\n"])));
exports.ErrorMessage = styled_components_1.default.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  font: var(--ui-large-medium);\n  color: var(--blue-grey-900);\n"], ["\n  flex: 1;\n  font: var(--ui-large-medium);\n  color: var(--blue-grey-900);\n"])));
exports.CloseIcon = (0, styled_components_1.default)(ds_ui_components_1.CloseIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-basis: 11px;\n  margin: 2px 0 0 0;\n  fill: var(--blue-grey-900);\n  cursor: pointer;\n"], ["\n  flex-basis: 11px;\n  margin: 2px 0 0 0;\n  fill: var(--blue-grey-900);\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
