import { mediaQueries } from './grid';
export const fontFamily = {
  headline: "'IBM Plex Sans Condensed', 'Segoe UI', Tahoma, sans-serif;",
  body: "'DM Sans', 'Segoe UI', Tahoma, sans-serif;"
};
export const typographyWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};
const fontSizesUI = {
  xxxs: '0.5rem',
  xxs: '0.5625rem',
  xs: '0.625rem',
  small: '0.75rem',
  medium: '0.875rem',
  large: '1rem',
  xl: '1.125rem'
};
const fontSizesHeadline = {
  xs: '1.125rem',
  small: '1.25rem',
  medium: '1.5rem',
  large: '1.75rem',
  xl: '2rem'
};
const headingTags = {
  xs: 'h5',
  small: 'h4',
  medium: 'h3',
  large: 'h2',
  xl: 'h1'
};
export const getTypographyTag = (variant, size) => {
  if (variant === 'ui') {
    return 'p';
  }
  if (!Object.keys(headingTags).includes(size)) {
    console.warn(`Variant ${size} is not supported in headline variant.`);
    return 'p';
  }
  return headingTags[size];
};
export const getTypographyVariant = (variant, size, theme) => {
  if (variant === 'ui' || size === 'xxs' || size === 'xxxs') {
    return theme.typography.ui[size];
  }
  return theme.typography.headline[size];
};
export const tabletUpTypography = {
  headline: {
    xl: {
      fontSize: fontSizesHeadline.xl,
      letterSpacing: '0.75%',
      lineHeight: '2.5rem'
    },
    large: {
      fontSize: fontSizesHeadline.large,
      letterSpacing: '0.5%',
      lineHeight: '2.25rem'
    },
    medium: {
      fontSize: fontSizesHeadline.medium,
      letterSpacing: '0.5%',
      lineHeight: '1.75rem'
    }
  }
};
export const typography = {
  headline: {
    xl: {
      fontFamily: fontFamily.headline,
      fontWeight: typographyWeights.bold,
      fontSize: fontSizesHeadline.large,
      lineHeight: '2.5rem',
      letterSpacing: '0.5%',
      [mediaQueries.tablet]: tabletUpTypography.headline?.xl
    },
    large: {
      fontFamily: fontFamily.headline,
      fontWeight: typographyWeights.bold,
      fontSize: fontSizesHeadline.medium,
      lineHeight: '1.75rem',
      letterSpacing: '0.5%',
      [mediaQueries.tablet]: tabletUpTypography.headline?.large
    },
    medium: {
      fontFamily: fontFamily.headline,
      fontWeight: typographyWeights.bold,
      fontSize: fontSizesHeadline.small,
      lineHeight: '1.5rem',
      letterSpacing: '0',
      [mediaQueries.tablet]: tabletUpTypography.headline?.medium
    },
    small: {
      fontFamily: fontFamily.headline,
      fontWeight: typographyWeights.semiBold,
      fontSize: fontSizesHeadline.small,
      lineHeight: '1.5rem',
      letterSpacing: '0.15%'
    },
    xs: {
      fontFamily: fontFamily.headline,
      fontWeight: typographyWeights.semiBold,
      fontSize: fontSizesHeadline.xs,
      lineHeight: '1.5rem',
      letterSpacing: '0.15%'
    }
  },
  ui: {
    xl: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.xl,
      lineHeight: '1.75rem',
      letterSpacing: '1%'
    },
    large: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.large,
      lineHeight: '1.5rem',
      letterSpacing: '1%'
    },
    medium: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.medium,
      lineHeight: '1.5rem',
      letterSpacing: '1%'
    },
    small: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.small,
      lineHeight: '1rem',
      letterSpacing: '0'
    },
    xs: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.xs,
      lineHeight: '1rem',
      letterSpacing: '0'
    },
    xxs: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.regular,
      fontSize: fontSizesUI.xxs,
      lineHeight: '0.75rem',
      letterSpacing: '1%'
    },
    xxxs: {
      fontFamily: fontFamily.body,
      fontWeight: typographyWeights.semiBold,
      fontSize: fontSizesUI.xxxs,
      lineHeight: '0.75rem',
      letterSpacing: '1%'
    }
  },
  weights: typographyWeights,
  sizes: {
    ui: fontSizesUI,
    headline: fontSizesHeadline
  }
};