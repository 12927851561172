import styled from 'styled-components/macro';
import { ModalPrimaryActionButton } from '@la/ds-ui-components';
import { DivisionCardTeamSelectionButton } from './DivisionCardTeamSelectionSection.styles';

export const UpdateTeamTriggerButton = styled(DivisionCardTeamSelectionButton)`
  & svg {
    fill: var(--secondary-500);
  }

  &:hover {
    & svg {
      fill: var(--secondary-600);
    }
  }

  &:active {
    & svg {
      fill: var(--secondary-800);
    }
  }
`;

export const UpdateTeamButton = styled(ModalPrimaryActionButton)`
  min-width: 136px;
`;

export const DeleteTeamButton = styled(ModalPrimaryActionButton)`
  min-width: 129px;
`;

export const DivisionsDeleteMessage = styled.strong`
  white-space: nowrap;
`;
