import axios from 'axios';
import { getAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

type StaffRole = {
  id: number;
  siteId: number;
  role: string;
  userSelectable: boolean;
  allowSelfRegistration: boolean;
  allowJoinTeam: boolean;
  allowInviteStaff: boolean;
  allowInviteThisRole: boolean;
  allowInvitePlayers: boolean;
  allowManagedStaffMangaedFields: boolean;
  allowSubmitRoster: boolean;
  allowCheckin: boolean;
  allowAddEditScores: boolean;
  isTeamRep: boolean;
  isClubDirector: boolean;
};

const baseUrl = getBaseURL();

const getStaffRoles = async ({ siteId }: { siteId: string }) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/staffRoles`;
  const token = getAccessToken();
  const response = await axios.get<StaffRole[]>(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export { getStaffRoles };
