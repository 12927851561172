"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputContainer = exports.InputContainer = exports.InputGroupContainer = void 0;
var styled_components_1 = require("styled-components");
exports.InputGroupContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n  gap: 8px;\n  margin-bottom: 12px;\n\n  // TODO: Remove after release of NG tournaments\n  @media (min-width: 900px) {\n    flex-direction: row;\n    margin-bottom: ", "\n    align-items: flex-start;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n  gap: 8px;\n  margin-bottom: 12px;\n\n  // TODO: Remove after release of NG tournaments\n  @media (min-width: 900px) {\n    flex-direction: row;\n    margin-bottom: ", "\n    align-items: flex-start;\n  }\n"])), function (_a) {
    var $gap = _a.$gap;
    return ($gap ? "".concat($gap, "px") : '12px');
});
exports.InputContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1 / span 2;\n\n  // TODO: Replace after release of NG tournaments\n  @media (min-width: 900px) {\n    grid-column: ", ";\n  }\n"], ["\n  grid-column: 1 / span 2;\n\n  // TODO: Replace after release of NG tournaments\n  @media (min-width: 900px) {\n    grid-column: ", ";\n  }\n"])), function (_a) {
    var $columnSpan = _a.$columnSpan;
    return $columnSpan === 2 ? '1 / span 2' : 'auto';
});
exports.TextInputContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1 / span 2;\n  width: 100%;\n  margin-bottom: 0;\n\n  & label {\n    word-break: break-word;\n    white-space: normal;\n    text-align: left;\n  }\n\n  // TODO: Remove after release of NG tournaments\n  @media (min-width: 900px) {\n    grid-column: ", ";\n    padding-top: 4px;\n    margin-bottom: 4px;\n  }\n"], ["\n  grid-column: 1 / span 2;\n  width: 100%;\n  margin-bottom: 0;\n\n  & label {\n    word-break: break-word;\n    white-space: normal;\n    text-align: left;\n  }\n\n  // TODO: Remove after release of NG tournaments\n  @media (min-width: 900px) {\n    grid-column: ", ";\n    padding-top: 4px;\n    margin-bottom: 4px;\n  }\n"])), function (_a) {
    var $columnSpan = _a.$columnSpan;
    return $columnSpan === 2 ? '1 / span 2' : 'auto';
});
var templateObject_1, templateObject_2, templateObject_3;
