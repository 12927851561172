import invert from 'invert-color';
const variantAdjustmentPairs_State = [{
  variant: 'hover',
  adjustment: 10
}, {
  variant: 'active',
  adjustment: 15
}];
const variantLightnessPairs_Dark = [{
  variant: '25',
  lightness: 98
}, {
  variant: '35',
  lightness: 96
}, {
  variant: '50',
  lightness: 94
}, {
  variant: '100',
  lightness: 86
}, {
  variant: '200',
  lightness: 73
}, {
  variant: '300',
  lightness: 62
}, {
  variant: '400',
  lightness: 54
}, {
  variant: '500',
  lightness: 46
}, {
  variant: '600',
  lightness: 40
}, {
  variant: '700',
  lightness: 33
}, {
  variant: '800',
  lightness: 26
}, {
  variant: '900',
  lightness: 18
}];
const variantLightnessPairs_Light = [{
  variant: '25',
  lightness: 94
}, {
  variant: '35',
  lightness: 92
}, {
  variant: '50',
  lightness: 84
}, {
  variant: '100',
  lightness: 76
}, {
  variant: '150',
  lightness: 68
}, {
  variant: '200',
  lightness: 60
}, {
  variant: '300',
  lightness: 52
}, {
  variant: '350',
  lightness: 46
}, {
  variant: '400',
  lightness: 40
}, {
  variant: '500',
  lightness: 34
}, {
  variant: '600',
  lightness: 26
}, {
  variant: '700',
  lightness: 23
}, {
  variant: '800',
  lightness: 16
}, {
  variant: '900',
  lightness: 8
}];

/**
 * Validates that `hexValue` is a string that begins with a "#" followed by
 * either 3 or 6 characters that qualify as hexadecimal values
 * @param {string} hexValue
 * @returns {boolean}
 */
export function validateHexValue(hexValue) {
  const isValid3CharacterHex = hexValue.length === 4 && !!hexValue.match(/#[A-F0-9]{3}/gi);
  const isValid6CharacterHex = hexValue.length === 7 && !!hexValue.match(/#[A-F0-9]{6}/gi);
  return isValid3CharacterHex || isValid6CharacterHex;
}

/**
 * Converts hexValue to an rgb color string representing an equivalent
 * color and returns the rgb color string.
 * @param {string} hexValue a hexadecimal color value
 * @returns {string} an rgb color string
 */
export function convertHexadecimalToRGB(hexValue, alpha) {
  let r = '0',
    g = '0',
    b = '0';

  // 3 digits
  if (hexValue.length === 4) {
    r = '0x' + hexValue[1] + hexValue[1];
    g = '0x' + hexValue[2] + hexValue[2];
    b = '0x' + hexValue[3] + hexValue[3];

    // 6 digits
  } else if (hexValue.length === 7) {
    r = '0x' + hexValue[1] + hexValue[2];
    g = '0x' + hexValue[3] + hexValue[4];
    b = '0x' + hexValue[5] + hexValue[6];
  }
  if (alpha) {
    return `rgba(${+r}, ${+g}, ${+b}, ${alpha})`;
  }
  return `rgb(${+r}, ${+g}, ${+b})`;
}

/**
 * Extracts numeric values for red, green and blue from `rgbColor` and returns
 * them, converted to numbers, in an object.
 * @param {string} rgbColor an rgb color string
 * @returns {RGB} rgb values in an object
 */
export function extractNumericValuesFromRGB(rgbColor) {
  const openParenIndex = rgbColor.indexOf('(');
  const closeParenIndex = rgbColor.indexOf(')');
  const valuesOnly = rgbColor.slice(openParenIndex + 1, closeParenIndex).split(',');
  return {
    red: +valuesOnly[0],
    green: +valuesOnly[1],
    blue: +valuesOnly[2]
  };
}

/**
 * Uses the values of `red`, `green` and `blue` to calculate and return an
 * hsl color string
 * @param {number} red color value between 0 & 255
 * @param {number} green color value between 0 & 255
 * @param {number} blue color value between 0 & 255
 * @returns {string} hsl color string
 */
export function convertRGBToHSL({
  red,
  green,
  blue
}) {
  // Make red, g, and b fractions of 1
  let _red = red / 255;
  let _green = green / 255;
  let _blue = blue / 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(_red, _green, _blue),
    cmax = Math.max(_red, _green, _blue),
    delta = cmax - cmin,
    hue,
    saturation,
    lightness;

  // Calculate hue
  // If no difference
  if (delta === 0) {
    hue = 0;
  }
  // if _red is max
  else if (cmax === _red) {
    hue = (_green - _blue) / delta % 6;
  }
  // if _green is max
  else if (cmax === _green) {
    hue = (_blue - _red) / delta + 2;
  }
  // if _blue is max
  else {
    hue = (_red - _green) / delta + 4;
  }
  hue = Math.round(hue * 60);

  // Make negative hues positive behind 360°
  if (hue < 0) {
    hue += 360;
  }

  // Calculate lightness
  lightness = (cmax + cmin) / 2;

  // Calculate saturation
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // Multiply saturation and lightness by 100
  saturation = +(saturation * 100).toFixed(1);
  lightness = +(lightness * 100).toFixed(1);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

/**
 * Uses the methods `convertHexadecimalToRGB`, `extractNumericValuesFromRGB` and
 * `convertRGBToHSL` internally to convert `hexValue` to an hsl color value,
 * which it returns
 * @param {string} hexValue a hexadecimal color value
 * @returns {string} an hsl color value
 */
export function convertHexadecimalToHSL(hexValue) {
  const rgbColorValue = convertHexadecimalToRGB(hexValue);
  const redGreenBlueValues = extractNumericValuesFromRGB(rgbColorValue);
  return convertRGBToHSL(redGreenBlueValues);
}

/**
 * Converts `hslColor` to an object containing its respective values, hue,
 * saturation and color, and returns that object.
 * @param {string} hslColor an hsl color string
 * * @returns {HSL} hsl values in an object
 */
export function convertHSLToObject(hslColor) {
  const [hue, saturation, lightness] = (hslColor.match(/(\d{1,3}\.?\d{0,3})/g) || [0, 0, 0]).map(Number);
  return {
    h: hue,
    s: saturation,
    l: lightness
  };
}

/**
 * Converts `hslObject` to an hsl color string using its respective values for
 * hue, saturation and color, and returns that string.
 * @param {HSL} hslObject hsl values in an object
 * * @returns {string} an hsl color string
 */
export function convertObjectToHSL({
  h,
  s,
  l
}) {
  return `hsl(${h}, ${s}%, ${l}%)`;
}

/**
 * Creates an array of GeneratedHSLValues by combining the `prefix` and the
 * `variant` value from the `variantLightnessPairs` and matching it with an hsl
 * color string created by replacing the lightness value in the
 * `baseColorValue` with the `lightness` value from the `variantLightnessPairs`
 * @param {string} prefix
 * @param {string} baseColorValue an hsl color string
 * @param {VariantWithLightness[]} variantLightnessPairs a schema made up of
 * pairs of variant {string} and lightness {number} values
 * @returns {GeneratedCSSVariable[]}
 */
export function generateShadeVariants(prefix, baseColorValue, variantLightnessPairs) {
  const {
    h,
    s
  } = convertHSLToObject(baseColorValue);
  return variantLightnessPairs.map(({
    lightness,
    variant
  }) => {
    const objKey = `${prefix}-${variant}`;
    const objValue = convertObjectToHSL({
      h: h,
      s: s,
      l: lightness
    });
    return {
      [objKey]: objValue
    };
  });
}

/**
 * Creates an array of GeneratedHSLValues by combining the `prefix` with a
 * lightness value returned by `adjustedStateLightness`
 * @param {string} prefix
 * @param {string} baseColorValue
 * @param {VariantWithAdjustment[]} variantAdjustmentPairs a schema made up
 * of pairs of variant {string} and lightness {number} values
 * @returns {GeneratedCSSVariable[]}
 */
export function generateStateVariants(prefix, baseColorValue, variantAdjustmentPairs) {
  const {
    h,
    s,
    l
  } = convertHSLToObject(baseColorValue);
  return variantAdjustmentPairs.map(({
    adjustment,
    variant
  }) => {
    const objKey = `${prefix}-${variant}`;
    const lightness = adjustStateLightness(adjustment, h, l);
    const objValue = convertObjectToHSL({
      h: h,
      s: s,
      l: lightness
    });
    return {
      [objKey]: objValue
    };
  });
}

/**
 * Based on the values of the adjustment, hue and lightness arguments, an
 * `adjustedLightness` value is determined and returned.
 * *
 * IF lightness is greater than 30 AND hue is between 7 and 215
 *   OR IF lightness is greater than 65 -->
 *     adjustedLightness = lightness + adjustment, to a maximum of 95
 * ELSE IF lightness is less than 35 -->
 *     adjustedLightness = lightness + adjustment
 * ELSE -->
 *     adjustedLightness = lightness - adjustment
 * *
 * @param {number} adjustment
 * @param {number} hue
 * @param {number} lightness
 * @returns {number}
 */
export function adjustStateLightness(adjustment, hue, lightness) {
  let adjustedLightness;
  if (lightness > 30 && hue > 7 && hue < 215 || lightness > 65) {
    adjustedLightness = lightness + adjustment <= 95 ? lightness + adjustment : 95;
  } else if (lightness < 35) {
    adjustedLightness = lightness + adjustment;
  } else {
    adjustedLightness = lightness - adjustment;
  }
  return adjustedLightness;
}

/**
 * Determines whether to use "black" or "white" as the reactive text color and returns
 * an object containing the CSS variable key and value pair. The return value will be
 * either { `${prefix}-text-color`: 'var(--black)' } or { `${prefix}-text-color`: 'var(--white)' }
 *
 * @param prefix The prefix to use in the css variable name
 * @param hexValue The hex value of the background color the text would appear on top of
 * @returns An object with a single property representing the css variable key and value
 *
 */
export function getReactiveTextColor(prefix, hexValue) {
  const reactiveColorKey = `${prefix}-text-color`;

  // The `invert` function returns either the black or white value when using the
  // second parameter. The custom threshold was chosen because it optimizes the output
  // given that we are using the color `#263238` for "black" text. The optimal threshold
  // value was found by testing a range of possible values.
  // See https://github.com/onury/invert-color
  const reactiveColorValue = invert(hexValue, {
    black: '#263238',
    white: '#FFFFFF',
    threshold: 0.24
  }) === '#263238' ? 'var(--black)' : 'var(--white)';
  return Object.fromEntries([[reactiveColorKey, reactiveColorValue]]);
}

/**
 * Injects `CSSVariables` into the style attribute of `targetElement`
 * @param {HTMLElement} targetElement
 * @param {GeneratedCSSVariable[]} CSSVariables
 * @return void
 */
export function addVariablesToTheDOM(targetElement, CSSVariables) {
  CSSVariables.forEach(variable => {
    targetElement.style.setProperty(Object.keys(variable)[0], Object.values(variable)[0]);
  });
}

/**
 * The passed `hslColorValue` is converted to an object and the values of
 * hue (h) and saturation (s) are examined.
 * *
 * IF hue is between 55 and 185 -->
 *   IF saturation is 90 or less -->
 *     The original `hslColorValue` string, along with
 *       the `variantLightnessPairs_Light` object is returned
 *   ELSE IF saturation is greater than 90 -->
 *       it sets the saturation value to 90 and generates an hsl color string
 *         keeping the hue and lightness values from `hslColorValue`
 *     The generated hsl color string, along with
 *       the `variantLightnessPairs_Light` object is returned
 * ELSE -->
 *   The original `hslColorValue` string, along with
 *     the `variantLightnessPairs_Dark` object is returned
 * *
 * @param {string} hslColorValue an hsl color string
 * @returns {hsl: string, variantLightnessPairs: VariantWithLightness[]} An
 * object containing an hsl color string and a schema made up of pairs of
 * `variant` {string} and `lightness` {number} values
 */
export function adjustForLuminance(hslColorValue) {
  const {
    h,
    s,
    l
  } = convertHSLToObject(hslColorValue);
  let returnedHSL = hslColorValue;
  let returnedVariantLightnessPairs = variantLightnessPairs_Dark;
  if (h >= 55 && h <= 185) {
    returnedVariantLightnessPairs = variantLightnessPairs_Light;
    if (s > 90) {
      returnedHSL = convertObjectToHSL({
        h: h,
        s: 90,
        l: l
      });
    }
  }
  return {
    hsl: returnedHSL,
    variantLightnessPairs: returnedVariantLightnessPairs
  };
}

/**
 * Using `convertHexadecimalToHSL`, `generateShadeVariants`,
 * `generateStateVariants`, `getand
 * `addVariablesToTheDOM` internally, generates a series of cssVariables
 * from hexValue and variantLightnessPairs and injects them into the style
 * attribute of DOMTarget along with a css variable made by pairing the
 * prefix and the hexValue
 * @param {string} prefix
 * @param {string} hexValue a hexadecimal color value
 * @param {HTMLElement} DOMTarget
 */
export function setThemeColorValues(prefix, hexValue, DOMTarget = document.documentElement) {
  const isValidHex = validateHexValue(hexValue);
  if (!isValidHex) throw new Error('Invalid hexadecimal value for base color');
  const hslFromHex = convertHexadecimalToHSL(hexValue);
  const {
    hsl,
    variantLightnessPairs
  } = adjustForLuminance(hslFromHex);
  const shadeVariants = generateShadeVariants(prefix, hsl, variantLightnessPairs);
  const stateVariants = generateStateVariants(prefix, hsl, variantAdjustmentPairs_State);
  const reactiveTextColor = getReactiveTextColor(prefix, hexValue);
  const palette = {
    base: hsl,
    reactiveTextColor,
    stateVariants,
    shadeVariants
  };
  addVariablesToTheDOM(DOMTarget, [{
    [prefix]: hsl
  }, reactiveTextColor, ...stateVariants, ...shadeVariants]);
  return palette;
}