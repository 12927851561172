import styled from 'styled-components/macro';
import { Typography } from '@la/ds-ui-components';
import { ColumnGrid } from 'components/ColumnGrid/ColumnGrid.styles';
import { media } from 'lib/media-queries/mixins';
import { PageContent } from '../../Shell/Page/Page.styles';

export const ConfirmationPage = styled(PageContent)`
  padding-bottom: 0;

  ${ColumnGrid} {
    row-gap: 16px;

    ${media.TabletLandscapeUp`
      row-gap: 32px;
    `}
  }
`;

export const ConfirmationMessage = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;

  ${media.TabletPortraitUp`
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
    grid-column-end: 17;
  `}

  text-align: center;
`;

export const ConfirmationHead = styled(Typography)`
  margin-bottom: 4px;
`;

export const MessageBody = styled(Typography)`
  display: inline;

  ${media.TabletPortraitUp`
    display: inline-block;
  `}
`;

export const ConfirmationPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.TabletPortraitUp`
    gap: 8px;
  `}

  ${media.TabletLandscapeUp`
    gap: 40px;
  `}

  ${media.DesktopUp`
    gap: 32px;
  `}
`;
