import { ExternalLinkIcon, formatAsCurrency, Link } from '@la/ds-ui-components';
import { getHotelLinks } from 'redux/checkoutSlice';
import { useAppSelector } from 'redux/store';
import {
  BillingSummaryData,
  BillingSummaryItem,
  CartItemHotelLink,
} from '../Checkout.types';
import * as S from './CheckoutBillingSummary.styles';

export type CheckoutBillingSummaryProps = {
  billingSummaryData: BillingSummaryData;
  showHotelLinks?: boolean;
  summaryType: 'checkout' | 'confirmation';
};

export const DUE_TODAY = 'Due today:';
export const PAID_TODAY = 'Paid today:';

/* CheckoutBillingSummary */
export default function CheckoutBillingSummary({
  billingSummaryData,
  showHotelLinks = false,
  summaryType,
}: CheckoutBillingSummaryProps) {
  const { dueToday, totalAmount, transactionFees, summary } =
    billingSummaryData;
  const hotelLinks = useAppSelector(getHotelLinks);

  const hasPaymentOption = summary.some(
    (summaryItem) => summaryItem.paymentTerm !== 'FULL'
  );
  return (
    <S.CheckoutBillingSummary $summaryType={summaryType}>
      <CheckoutBillingSummaryHeader
        dueToday={dueToday}
        summaryType={summaryType}
        totalAmount={totalAmount}
        hasPaymentOption={hasPaymentOption}
      />
      <CheckoutBillingSummaryDetail
        hotelLinks={showHotelLinks && hotelLinks.length > 1 ? hotelLinks : []}
        summaryItems={summary}
      />
      <TransactionFee
        transanctionFee={formatAsCurrency(Number(transactionFees))}
      />
      <CheckoutBillingSummaryMobileFooter
        dueToday={dueToday}
        summaryType={summaryType}
        totalAmount={totalAmount}
        hasPaymentOption={hasPaymentOption}
      />
    </S.CheckoutBillingSummary>
  );
}
/* */

/*CheckoutBillingSummaryHeader */
function CheckoutBillingSummaryHeader({
  dueToday,
  summaryType,
  totalAmount,
  hasPaymentOption,
}: {
  dueToday: string;
  summaryType: 'checkout' | 'confirmation';
  totalAmount: string;
  hasPaymentOption: boolean;
}) {
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentOption
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <S.CheckoutBillingSummaryHeader>
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        <span>{formatAsCurrency(Number(dueToday))}</span>
      </S.DueTodayRow>
      {hasPaymentOption ? (
        <S.TotalPriceRow>
          <span>Total price:</span>
          <S.TotalPrice>{formatAsCurrency(Number(totalAmount))}</S.TotalPrice>
        </S.TotalPriceRow>
      ) : null}
    </S.CheckoutBillingSummaryHeader>
  );
}
/* */

/*CheckoutBillingSummaryMobileFooter */
function CheckoutBillingSummaryMobileFooter({
  dueToday,
  summaryType,
  totalAmount,
  hasPaymentOption,
}: {
  dueToday: string;
  summaryType: 'checkout' | 'confirmation';
  totalAmount: string;
  hasPaymentOption: boolean;
}) {
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentOption
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <S.CheckoutBillingSummaryMobileFooter>
      {hasPaymentOption ? (
        <S.TotalPriceRow>
          <span>Total price:</span>
          <S.TotalPrice>{formatAsCurrency(Number(totalAmount))}</S.TotalPrice>
        </S.TotalPriceRow>
      ) : null}
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        <span>{formatAsCurrency(Number(dueToday))}</span>
      </S.DueTodayRow>
    </S.CheckoutBillingSummaryMobileFooter>
  );
}
/* */

/*CheckoutBillingSummaryDetail */
function CheckoutBillingSummaryDetail({
  hotelLinks,
  summaryItems,
}: {
  hotelLinks: CartItemHotelLink[];
  summaryItems: BillingSummaryItem[];
}) {
  function renderSummaryItems() {
    return summaryItems.map(
      (summaryItemData: BillingSummaryItem, idx: number) => {
        return (
          <SummaryItem
            {...summaryItemData}
            key={`${summaryItemData.cartItemName}-${idx}`}
            hotelLink={hotelLinks[idx]?.hotelLink}
          />
        );
      }
    );
  }
  return <S.SummaryItems>{renderSummaryItems()}</S.SummaryItems>;
}
/* */

function SummaryItem({
  cartItemName,
  description,
  dueToday,
  hotelLink,
  paymentTerm,
  paymentTermLabel,
  totalAmount,
}: BillingSummaryItem & { hotelLink?: string }) {
  return (
    <S.SummaryItem>
      <S.CheckoutBillingSummaryPrimaryRow>
        <span>{cartItemName}:</span>
        <span>
          {paymentTerm === 'PAYMENT_PLAN' ? (
            <>{formatAsCurrency(Number(dueToday))}/</>
          ) : null}
          <S.TotalCost>{formatAsCurrency(Number(totalAmount))}</S.TotalCost>
        </span>
      </S.CheckoutBillingSummaryPrimaryRow>
      <S.CheckoutBillingSummarySecondaryRow>
        <span>{description}</span>
        <span>{paymentTermLabel}</span>
      </S.CheckoutBillingSummarySecondaryRow>
      {hotelLink ? (
        <S.CheckoutBillingSummaryTertiaryRow>
          <Link href={hotelLink} isBold variant="text" target="_blank">
            Manage accommodations
            <ExternalLinkIcon
              fill="var(--secondary-600)"
              variant="bold"
              size="large"
            />
          </Link>
        </S.CheckoutBillingSummaryTertiaryRow>
      ) : null}
    </S.SummaryItem>
  );
}

function TransactionFee({ transanctionFee }: { transanctionFee: string }) {
  return (
    <S.TransactionFee>
      <span> Transaction fees:</span>
      <span>{transanctionFee}</span>
    </S.TransactionFee>
  );
}
