import styled from 'styled-components';
import { generateDismissButtonDynamicStyles, generateSelectableTagDynamicStyles, tagHeight, tagPadding } from 'theming/tokens/components/tag';
export const SelectableTagContainer = styled.div.withConfig({
  componentId: "sc-o5oa71-0"
})(({
  theme,
  $size,
  $isFocused,
  $readOnly,
  $disabled
}) => {
  const {
    spacing,
    foundational: {
      blueGrey,
      green,
      white
    },
    semantic
  } = theme;
  const styles = {
    default: generateSelectableTagDynamicStyles(theme, 'default', $readOnly)
  };
  const outlineStyles = $isFocused ? {
    outline: '2px solid',
    outlineOffset: '1px',
    outlineColor: !!semantic ? blueGrey[600] : green[600]
  } : {};
  const defaultCursor = $readOnly ? 'default' : 'pointer';
  return {
    maxWidth: 'min-content',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    height: tagHeight[$size],
    backgroundColor: $disabled ? white : styles.default.backgroundColor,
    borderRadius: spacing.xxxs,
    cursor: $disabled ? 'not-allowed' : defaultCursor,
    ...outlineStyles
  };
});
export const ActionButton = styled.div.withConfig({
  componentId: "sc-o5oa71-1"
})(({
  theme,
  $disabled,
  $size,
  $readOnly,
  $dismissable,
  $isDismissHover,
  $isDismissActive
}) => {
  const {
    spacing,
    foundational: {
      blueGrey,
      green,
      red
    },
    semantic
  } = theme;
  const getBadgeBackgroundColor = () => {
    if ($isDismissActive && !$readOnly) {
      return red[700];
    }
    if ($isDismissHover && !$readOnly) {
      return red[500];
    }
    return !!semantic ? blueGrey[600] : green[600];
  };
  const styles = {
    default: generateSelectableTagDynamicStyles(theme, 'default', $readOnly, $isDismissHover),
    hover: generateSelectableTagDynamicStyles(theme, 'hover', $readOnly, $isDismissHover),
    active: generateSelectableTagDynamicStyles(theme, 'active', $readOnly, $isDismissHover),
    disabled: generateSelectableTagDynamicStyles(theme, 'disabled', false, $isDismissHover)
  };
  const defaultBorderColor = $isDismissActive && !$readOnly ? red[700] : styles.default.borderColor;
  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxxs,
    cursor: 'pointer',
    height: '100%',
    padding: tagPadding[$size],
    color: $isDismissActive && !$readOnly ? red[700] : styles.default.color,
    border: '1px solid',
    borderColor: defaultBorderColor,
    borderRightColor: $readOnly ? 'transparent' : defaultBorderColor,
    borderRadius: $dismissable ? `${spacing.xxxs} 0 0 ${spacing.xxxs}` : spacing.xxxs,
    backgroundColor: 'transparent',
    pointerEvents: $readOnly ? 'none' : 'all',
    '& span': {
      backgroundColor: getBadgeBackgroundColor()
    },
    '&:enabled:focus': {
      outline: 'none'
    },
    '&:enabled:hover': {
      borderColor: styles.hover.borderColor,
      color: styles.hover.color,
      '& span': {
        backgroundColor: $isDismissHover ? red[500] : styles.hover.color
      }
    },
    '&:enabled:active': {
      backgroundColor: styles.active.backgroundColor,
      borderColor: styles.active.borderColor,
      color: styles.active.color,
      '& span': {
        backgroundColor: $isDismissActive ? red[700] : styles.active.color
      }
    },
    ...($disabled ? {
      borderColor: styles.disabled.borderColor,
      color: styles.disabled.color,
      cursor: 'not-allowed',
      '& span': {
        backgroundColor: styles.disabled.color
      }
    } : {})
  };
});
export const DismissButton = styled.div.withConfig({
  componentId: "sc-o5oa71-2"
})(({
  theme,
  $disabled,
  $size,
  $readOnly
}) => {
  const {
    spacing
  } = theme;
  // Ideally we would wan't to use Icon's `size='small` and `size='xs'` properties,
  // but Tags are an exception where small icons are not `filled`.
  const iconSize = $size === 'medium' ? '12px' : '8px';
  const styles = {
    default: generateDismissButtonDynamicStyles(theme, 'default', $readOnly),
    hover: generateDismissButtonDynamicStyles(theme, 'hover', $readOnly),
    active: generateDismissButtonDynamicStyles(theme, 'active', $readOnly),
    disabled: generateDismissButtonDynamicStyles(theme, 'disabled', $readOnly)
  };
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: tagPadding[$size],
    height: '100%',
    backgroundColor: styles.default.backgroundColor,
    border: '1px solid',
    borderColor: styles.default.borderColor,
    borderLeft: 'none',
    cursor: 'pointer',
    borderRadius: `0 ${spacing.xxxs} ${spacing.xxxs} 0`,
    '& svg': {
      height: iconSize,
      width: iconSize,
      fill: styles.default.fill,
      stroke: styles.default.fill
    },
    '&:hover': {
      backgroundColor: styles.hover.backgroundColor,
      borderColor: styles.hover.borderColor,
      '& svg': {
        fill: styles.hover.fill,
        stroke: styles.hover.fill
      }
    },
    '&:enabled:active': {
      backgroundColor: styles.active.backgroundColor,
      borderColor: styles.active.borderColor,
      '& svg': {
        fill: styles.hover.fill,
        stroke: styles.hover.fill
      }
    },
    ...($disabled ? {
      backgroundColor: styles.disabled.backgroundColor,
      borderColor: styles.disabled.borderColor,
      cursor: 'not-allowed',
      '& svg': {
        fill: styles.disabled.fill,
        stroke: styles.disabled.fill
      }
    } : {}),
    '&:focus': {
      outline: '2px solid',
      outlineOffset: '1px',
      outlineColor: styles.disabled.outlineColor
    }
  };
});
export const ContentText = styled.p.withConfig({
  componentId: "sc-o5oa71-3"
})(({
  theme,
  $size
}) => {
  return {
    ...theme.typography.ui[$size],
    lineHeight: '100%'
  };
});