import {
  AccountInfo,
  AccountInfoContext,
  getAccountInfo,
} from 'lib/apis/getAccountInfo';
import baseApi from './baseApi';

export const accountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountInfo: builder.query<
      AccountInfo,
      { context: AccountInfoContext; siteId: string }
    >({
      queryFn: async ({ context, siteId }) => {
        const response = await getAccountInfo({ context, siteId });
        return { data: response };
      },
    }),
  }),
});

export const { useGetAccountInfoQuery } = accountApi;
