import { HttpResponse, http } from 'msw';

export const brandingThemeHandlers = [
  http.get('*/api/theme/sites/*/branding-theme', () =>
    HttpResponse.json(
      {
        theme: {
          primaryColor: '#B711E3',
          secondaryColor: '#E1DA20',
          siteId: 1,
        },
      },
      { status: 200 }
    )
  ),
];
