import styled from 'styled-components/macro';

export const AddPlayerModalFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddPlayerTextFieldsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const AddPlayerTextFieldContainer = styled.span`
  flex: 1;
`;

export const CopyPlayerLinkFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  margin-top: 24px;

  > div:first-of-type {
    flex: 1;
  }
`;
