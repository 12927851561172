"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCardSecondaryInfoValue = exports.DivisionCardSecondaryInfo = exports.DivisionCardSecondaryInfoContainer = exports.DivisionCardInfoValue = exports.DivisionCardInfoLabel = exports.DivisionCardInfo = exports.DivisionCardPrimaryInfo = exports.DivisionCardPrimaryInfoContainer = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var mixins_1 = require("../../../../../../lib/media-queries/mixins");
exports.DivisionCardPrimaryInfoContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  gap: 32px;\n\n  ", "\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  gap: 32px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n  "], ["\n    align-items: center;\n  "]))));
exports.DivisionCardPrimaryInfo = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", "\n"])), mixins_1.media.TabletPortraitUp(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex-direction: row;\n    gap: 24px;\n  "], ["\n    flex-direction: row;\n    gap: 24px;\n  "]))));
exports.DivisionCardInfo = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  p {\n    display: inline-block;\n  }\n"], ["\n  p {\n    display: inline-block;\n  }\n"])));
exports.DivisionCardInfoLabel = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'large',
    variant: 'ui',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
exports.DivisionCardInfoValue = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'large',
    variant: 'ui',
    weight: 'bold',
})(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
exports.DivisionCardSecondaryInfoContainer = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  gap: 16px;\n  margin-top: 8px;\n"])));
exports.DivisionCardSecondaryInfo = styled_components_1.default.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  svg {\n    fill: var(--blue-grey-300);\n    margin-right: 4px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  svg {\n    fill: var(--blue-grey-300);\n    margin-right: 4px;\n  }\n"])));
exports.DivisionCardSecondaryInfoValue = (0, styled_components_1.default)(ds_ui_components_1.Typography).attrs({
    size: 'small',
    variant: 'ui',
})(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: var(--blue-grey-600);\n"], ["\n  color: var(--blue-grey-600);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
