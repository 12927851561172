"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDivisionCost = exports.INVALID_COST_ERROR_MESSAGE = void 0;
exports.INVALID_COST_ERROR_MESSAGE = 'Division cost is not a valid value.';
/**
 * Retrieves cost of division, which is an optional registration setting
 * for a given Division.
 * @param division The division to get the cost from.
 * @returns The cost of the division, defaulting to 0 if no cost is specified.
 */
var getDivisionCost = function (division) {
    var cost = division.cost ? Number(division.cost) : 0;
    if (isNaN(cost)) {
        throw new Error('Division cost is not a valid value.');
    }
    return cost;
};
exports.getDivisionCost = getDivisionCost;
