export function sortByNameAlphabetically(
  a: { name: string },
  b: { name: string }
) {
  return a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export function prepareForCaseChange(str: string) {
  return (
    str
      // lowercase all letters in the string
      .toLocaleLowerCase()
      // replace all punctuation with spaces
      .replace(/([^\w\d\s]|_)/g, ' ')
      // replace repeated spaces with a single space
      .replace(/\s+/g, ' ')
      // remove leading and trailing spaces
      .trim()
  );
}

export function convertToCamelCase(str: string) {
  return (
    prepareForCaseChange(str)
      // split the string up along any spaces
      .split(' ')
      // in every word after the first make the first letter uppercase
      .map((word, idx) => {
        const letters = word.split('');
        if (idx !== 0) {
          letters[0] = letters[0].toLocaleUpperCase();
        }
        return letters.join('');
      })
      // rejoin all words into a single string
      .join('')
  );
}

export function convertToKebabCase(str: string) {
  return (
    prepareForCaseChange(str)
      // replace all spaces with hyphens
      .replace(/\s+/g, '-')
  );
}

export function convertToSnakeCase(str: string) {
  return (
    prepareForCaseChange(str)
      // replace all spaces with underscores
      .replace(/\s+/g, '_')
  );
}

export function capitalizeFirstLetter(str: string): string {
  const trimmed = str.trimStart();
  return !trimmed ? str : trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
}

export function isValidEmail(str: string): boolean {
  return !!str.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
}

export function decodeHTMLEntities(text: string): string {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
