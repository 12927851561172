"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryCards = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var DivisionSummaryCard_1 = require("../DivisionSummaryCard/DivisionSummaryCard");
var S = require("./SummaryCards.styles");
/* SummaryCards */
function SummaryCards(_a) {
    var divisionsForSummarySection = _a.divisionsForSummarySection, teams = _a.teams, tournament = _a.tournament;
    return ((0, jsx_runtime_1.jsx)(S.WizardSummaryCards, { children: divisionsForSummarySection.map(function (d) {
            var division = tournament.divisions.find(function (division) { return division.id === d.id; });
            if (!division) {
                return null;
            }
            var teamIds = [];
            if (d.value === 'saved' || d.value === 'saved-and-editing') {
                teamIds = d.savedTeams;
            }
            var teamsForDivision = teamIds.map(function (teamId) { return teams.find(function (team) { return team.id === teamId; }); });
            return ((0, jsx_runtime_1.jsx)(DivisionSummaryCard_1.DivisionSummaryCard, { division: division, teams: teamsForDivision }, division.id));
        }) }));
}
exports.SummaryCards = SummaryCards;
