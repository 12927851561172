import styled from 'styled-components';
import { generateInputLabelDynamicStyles } from 'theming/tokens/components/input';
export const LabelRoot = styled.label.withConfig({
  componentId: "sc-1gjps8f-0"
})(({
  theme,
  $state,
  $isHorizontal
}) => {
  const styles = generateInputLabelDynamicStyles(theme, $state);
  const size = $isHorizontal ? 'large' : 'medium';
  const weight = $isHorizontal ? 'regular' : 'bold';
  return {
    display: 'flex',
    ...theme.typography.ui[size],
    fontWeight: theme.typography.weights[weight],
    color: $isHorizontal && $state !== 'error' ? theme.palette.foundational.blueGrey[900] : styles.color,
    cursor: 'pointer',
    overflowWrap: 'anywhere'
  };
});
export const RequiredIcon = styled.span.withConfig({
  componentId: "sc-1gjps8f-1"
})(({
  theme
}) => {
  return {
    ...theme.typography.ui.medium,
    fontWeight: theme.typography.weights.bold,
    color: theme.palette.error.medium,
    flexGrow: 1,
    marginLeft: '2px'
  };
});