import { AxiosPromise } from 'axios';

// Recursively get all the pages for an API call.
export async function paginatedGet<T>({
  apiRequest,
  // IdPagination uses the last returned object ID as the start of the next page.
  isIdPagination = false,
  requestData,
}: {
  apiRequest: (requestData: any) => AxiosPromise;
  isIdPagination?: boolean;
  pageSize?: number;
  requestData: any;
}): Promise<T[]> {
  const pageSize = requestData.pageSize ?? 100;
  let accumulatedData: T[] = [];
  let currentPage = 1;
  let hasMore = true;

  do {
    const { data } = await apiRequest({
      ...requestData,
      pageNum: currentPage,
      pageSize: pageSize,
    });

    accumulatedData = [...accumulatedData, ...data];

    if (data.length < pageSize) {
      // No more pages to fetch
      hasMore = false;
    } else {
      // Calculate the next page number or ID for pagination
      currentPage = isIdPagination ? data.at(-1).id : currentPage + 1;
    }
  } while (hasMore);

  return accumulatedData;
}
