import axios from 'axios';
import { extractAccessToken } from '../auth/auth';
import { getLAHostnameParts, getBaseURL } from '../utils/urlUtils';

export function addPlaidBankAccount(
  userId: number | undefined,
  siteId: string | null
) {
  const { sld, tld } = getLAHostnameParts();
  const baseURL = getBaseURL();
  const token = extractAccessToken();
  if (userId && siteId && token) {
    axios
      .post(
        `${baseURL}/api/sites/${siteId}/users/${userId}/bankAccounts/plaidLinkInfo/create`,
        {
          redirectUri: window.location.href,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const {
          data: { plaidLinkInfoId },
        } = resp;

        window.open(
          `https://plaid.${sld}.${tld}/plaidLink?plaidLinkRedirectInfoId=${plaidLinkInfoId}`,
          '_self'
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
