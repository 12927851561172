import styled from 'styled-components/macro';

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 24px;
  width: auto;
  padding: 0 0 0 6px;
  border-radius: 4px;
  background-color: var(--blue-grey-50);
`;

export const TagLabel = styled.span`
  font: var(--ui-small-regular);
  line-height: 24px;
  color: var(--blue-grey-900);
`;

export const CloseButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: var(--red-500);
  }

  &:focus {
    color: white;
    background-color: var(--red-500);
    outline: var(--red-500) solid 2px;
    outline-offset: 1px;
  }
`;
