import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  LinkIcon,
  Loader,
  Modal,
  ModalPrimaryActionButton,
  Snackbar,
  TextInput,
} from '@la/ds-ui-components';
import {
  PlayerMetadata,
  registerPlayer,
  RegistrationRequest,
} from 'lib/apis/player';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { copyTextToClipboard } from 'lib/utils/utilities';
import * as S from './AddPlayerStaffModals.styles';

export type AddPlayerStaffModalProps = {
  closeModal: () => void;
  openModalName: string | null;
  playerInviteLink?: string;
};

export type AddPlayerModalProps = {
  closeModal: () => void;
  playerInviteLink?: string;
  savePlayer: (
    playerFirstName: string,
    playerLastName: string,
    parentEmailAddress: string,
    subdomain: string,
    onLoadChange: (value: boolean) => void
  ) => void;
};

const SNACKBAR_DURATION = 5000;

export function AddPlayerModal({
  closeModal,
  playerInviteLink,
  savePlayer,
}: AddPlayerModalProps) {
  const { subdomain } = getLAHostnameParts();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [playerFirstName, setPlayerFirstName] = useState<string>('');
  const [playerLastName, setPlayerLastName] = useState<string>('');
  const [parentEmailAddress, setParentEmailAddress] = useState<string>('');

  const playerFirstNameHasError = useMemo(
    () => !playerFirstName,
    [playerFirstName]
  );
  const playerLastNameHasError = useMemo(
    () => !playerLastName,
    [playerLastName]
  );
  const parentEmailAddressHasError = useMemo(
    () =>
      !parentEmailAddress ||
      !parentEmailAddress
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
    [parentEmailAddress]
  );

  return (
    <Modal
      onOpenChange={closeModal}
      open={true}
      primaryAction={
        <ModalPrimaryActionButton
          disabled={
            playerFirstNameHasError ||
            playerLastNameHasError ||
            parentEmailAddressHasError
          }
          loading={isLoading}
          onClick={() =>
            savePlayer(
              playerFirstName,
              playerLastName,
              parentEmailAddress,
              subdomain,
              setIsLoading
            )
          }
        >
          Add player
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
      }
      title="Add a player"
    >
      {isLoading ? (
        <Loader loading description="Saving player data" />
      ) : (
        <>
          <S.AddPlayerModalFields>
            <S.AddPlayerTextFieldsContainer>
              <S.AddPlayerTextFieldContainer>
                <TextInput
                  errorMessage="Please enter a valid first name"
                  hasError={playerFirstNameHasError}
                  id="add-player-first-name"
                  label="Player first name"
                  onChange={(event) => setPlayerFirstName(event.target.value)}
                  placeholder="Enter player's first name..."
                  required={true}
                  value={playerFirstName}
                />
              </S.AddPlayerTextFieldContainer>
              <S.AddPlayerTextFieldContainer>
                <TextInput
                  errorMessage="Please enter a valid last name"
                  hasError={playerLastNameHasError}
                  id="add-player-last-name"
                  label="Player last name"
                  onChange={(event) => setPlayerLastName(event.target.value)}
                  placeholder="Enter player's last name..."
                  required={true}
                  value={playerLastName}
                />
              </S.AddPlayerTextFieldContainer>
            </S.AddPlayerTextFieldsContainer>
            <S.AddPlayerTextFieldContainer>
              <TextInput
                errorMessage="Please enter a valid email address"
                hasError={parentEmailAddressHasError}
                id="add-player-email-address"
                label="Parent email address"
                onChange={(event) => setParentEmailAddress(event.target.value)}
                placeholder="Enter email address..."
                required={true}
                value={parentEmailAddress}
              />
            </S.AddPlayerTextFieldContainer>
            <S.CopyPlayerLinkFields>
              <TextInput
                disabled={!playerInviteLink}
                id="share-player-invite-link"
                label="Share invite link"
                value={playerInviteLink}
              />
              <Button
                disabled={!playerInviteLink}
                leftIcon={
                  <LinkIcon
                    fill="var(--secondary-400)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={() => copyTextToClipboard(playerInviteLink!)}
                size="large"
                variant="outline"
              >
                Copy link
              </Button>
            </S.CopyPlayerLinkFields>
          </S.AddPlayerModalFields>
        </>
      )}
    </Modal>
  );
}

export default function AddPlayerStaffModal({
  closeModal,
  openModalName,
  playerInviteLink,
}: AddPlayerStaffModalProps) {
  const [showAddErrorSnackBar, setShowAddErrorSnackBar] =
    useState<boolean>(false);
  const [showAddSuccessSnackBar, setShowAddSuccessSnackBar] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { programId, teamId } = useParams();

  let modal: JSX.Element | undefined = undefined;

  if (openModalName === 'addPlayers') {
    const savePlayer = async (
      playerFirstName: string,
      playerLastName: string,
      parentEmailAddress: string,
      subdomain: string,
      onLoadChange: (value: boolean) => void
    ) => {
      if (!playerFirstName || !playerLastName) {
        return;
      }
      onLoadChange(true);

      const registrationRole = 'PLAYER';
      const teamIdOg: number = parseInt(teamId || '');

      const registration: RegistrationRequest<PlayerMetadata> = {
        content: {
          add: [
            {
              deleted: false,
              programId: programId ? parseInt(programId) : 0,
              registrationType: 'INVITE_PLAYER',
              registrationStatus: 'PENDING_INVITE',
              paymentStatus: 'UNPAID',
              teamIdOg: teamIdOg,
              metadata: {
                version: 3,
                type: registrationRole,
                programRole: registrationRole,
                isCoCaptain: false,
                teamIdOg: teamIdOg,
                playerName: `${playerFirstName} ${playerLastName}`,
                email: parentEmailAddress,
              },
            },
          ],
          remove: [],
          update: [],
        },
      };

      registerPlayer({ siteDomain: subdomain, registration })
        .then((r) => {
          setSuccessMessage(
            `Player ${playerFirstName} ${playerLastName} added successfuly`
          );
          setShowAddSuccessSnackBar(true);
        })
        .catch((err) => {
          setErrorMessage(
            `Error adding player ${playerFirstName} ${playerLastName}`
          );
          setShowAddErrorSnackBar(true);
        })
        .finally(() => {
          onLoadChange(false);
          closeModal();
        });
    };

    modal = (
      <>
        <AddPlayerModal
          closeModal={closeModal}
          playerInviteLink={playerInviteLink}
          savePlayer={savePlayer}
        />
      </>
    );
  }

  return (
    <>
      {modal}
      <Snackbar
        description={errorMessage}
        duration={SNACKBAR_DURATION}
        onOpenChange={setShowAddErrorSnackBar}
        open={showAddErrorSnackBar}
        variant="error"
      />
      <Snackbar
        description={successMessage}
        duration={SNACKBAR_DURATION}
        onOpenChange={setShowAddSuccessSnackBar}
        open={showAddSuccessSnackBar}
      />
    </>
  );
}
