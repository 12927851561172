export const CHECKOUT_ERRORS = {
  submissionError:
    'There was an error processing this request. Please try' +
    ' again in a few seconds.',
  paymentMethod: 'Please select a payment method.',
  paymentMethodWithAutopay: 'Please add a payment method and agree to autopay.',
  autopay: 'Please agree to autopay.',
  storePaymentMethod: 'Please store the payment method.',
  storePaymentMethodAndAutopay:
    'Please store the payment method and agree to autopay.',
  paymentTerms: 'Please read and agree to the payment and refund policies.',
};
