"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.media = void 0;
var styled_components_1 = require("styled-components");
var breakpoints_1 = require("./breakpoints");
exports.media = {
    PhoneOnly: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.phoneOnly, styled_components_1.css.apply(void 0, args));
    },
    TabletPortraitUp: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.tabletPortraitUp, styled_components_1.css.apply(void 0, args));
    },
    TabletPortraitDown: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.tabletPortraitDown, styled_components_1.css.apply(void 0, args));
    },
    TabletLandscapeUp: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.tabletLandscapeUp, styled_components_1.css.apply(void 0, args));
    },
    TabletLandscapeDown: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.tabletLandscapeDown, styled_components_1.css.apply(void 0, args));
    },
    DesktopUp: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.desktopUp, styled_components_1.css.apply(void 0, args));
    },
    BigDesktopUp: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    @media ", " {\n      ", ";\n    }\n  "], ["\n    @media ", " {\n      ", ";\n    }\n  "])), breakpoints_1.breakpointQueries.bigDesktopOnly, styled_components_1.css.apply(void 0, args));
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
