import { PropsWithChildren, ReactElement, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Link,
  Loader,
  Typography,
} from '@la/ds-ui-components';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { decodeHTMLEntities } from 'lib/utils/stringUtils';
import {
  RosterPageData,
  useGetSideNavDataQuery,
} from 'redux/services/rosterManagementApi';
import teamRoster from 'assets/placeholders/team-roster.png';
import { ViewDifferentTeamModal } from './ViewDifferentTeamModal';
import * as S from './RosterSideNav.styles';

export type RosterSideNavProps = RosterPageData & {
  teamNameSize: 'medium' | 'large';
};

export default function RosterSideNav({
  programId,
  programType,
  teamId,
  teamNameSize,
}: RosterSideNavProps) {
  const { data: sideNavData, /* error, isError, */ isLoading } =
    useGetSideNavDataQuery(
      { programId, programType, teamId },
      { skip: !programId || !programType || !teamId }
    );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return isLoading ? (
    <S.RosterSideNavLoader>
      <Loader loading={isLoading} description="Team information is loading" />
    </S.RosterSideNavLoader>
  ) : (
    <S.RosterSideNav>
      <S.RosterSideNavTop>
        <S.TeamLogoLink href={sideNavData?.teamLogoLink}>
          <img alt="Team Logo" src={sideNavData?.teamLogoURL ?? teamRoster} />
        </S.TeamLogoLink>
        <S.LinksTeamName>
          <ProgramSubprogramLinks
            programLink={sideNavData?.programLink}
            programName={sideNavData?.programName}
            subprogramLink={sideNavData?.subprogramLink}
            subprogramName={sideNavData?.subprogramName}
          />
          <S.RosterTeamName size={teamNameSize} variant="headline">
            {decodeHTMLEntities(sideNavData?.teamName)}
          </S.RosterTeamName>
          <Link size="small" href={`${sideNavData?.editProfileLink}`}>
            Edit team profile
          </Link>
        </S.LinksTeamName>
      </S.RosterSideNavTop>
      <S.ViewAnotherTeamButton
        onClick={() => setIsModalOpen(true)}
        size="medium"
        variant="outline"
        width="full"
      >
        View another team
      </S.ViewAnotherTeamButton>
      <S.RosterSideNavLinks>
        <S.RosterSideNavLink href={`${sideNavData?.scheduleLink}`}>
          <Typography size="small" variant="headline">
            {sideNavData?.scheduleLabel
              ? sideNavData.scheduleLabel
              : 'Schedule'}
          </Typography>
        </S.RosterSideNavLink>
        <S.RosterSideNavLink $isActive={true}>
          <Typography size="small" variant="headline">
            Roster
          </Typography>
        </S.RosterSideNavLink>
      </S.RosterSideNavLinks>
      <RosterSideNavBottom>
        <Typography size="medium" variant="ui" weight="regular">
          {sideNavData?.standingWins} - {sideNavData?.standingLosses} -{' '}
          {sideNavData?.standingTies}
        </Typography>
        <span>
          <Typography size="medium" variant="ui" weight="bold">
            Registration Status
          </Typography>
          <Typography size="medium" variant="ui" weight="regular">
            {sideNavData?.teamRegistrationStatus}
          </Typography>
        </span>
        <span>
          <Typography size="medium" variant="ui" weight="bold">
            {sideNavData?.divisionsLabel}
          </Typography>
          <Typography size="medium" variant="ui" weight="regular">
            None
          </Typography>
        </span>
        <Link href={`${sideNavData?.standingsLink}`}>View standings</Link>
      </RosterSideNavBottom>
      {isModalOpen ? (
        <ViewDifferentTeamModal
          closeModal={() => setIsModalOpen(false)}
          programName={sideNavData?.programName || sideNavData?.subprogramName}
          teamId={teamId}
          teamName={decodeHTMLEntities(sideNavData?.teamName)}
        />
      ) : null}
    </S.RosterSideNav>
  );
}

export function ProgramSubprogramLinks({
  programLink,
  programName,
  subprogramLink,
  subprogramName,
}: {
  programLink?: string;
  programName?: string;
  subprogramLink?: string;
  subprogramName?: string;
}): ReactElement {
  const hasProgram = !!programLink && !!programName;
  const hasSubprogram = !!subprogramLink && !!subprogramName;

  return (
    <>
      {/*only render program links if name and link are present*/}
      {hasProgram ? (
        <Link href={programLink} isBold={true}>
          {programName}
        </Link>
      ) : null}
      {/*only render subprogram if name and link are present*/}
      {hasSubprogram ? (
        <Link href={subprogramLink} isBold={true}>
          {subprogramName}
        </Link>
      ) : null}
    </>
  );
}

export function RosterSideNavBottom({ children }: PropsWithChildren) {
  const { tabletPortraitDown } = breakpointQueries;
  const showAccordion = useMediaQuery(tabletPortraitDown);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const accordionLabel = isOpen ? 'Hide team details' : 'View team details';

  return showAccordion ? (
    <S.RosterSideNavBottom>
      <Accordion variant="condensed" onValueChange={() => setIsOpen(!isOpen)}>
        <AccordionItem value="team details">
          <AccordionTrigger>{accordionLabel}</AccordionTrigger>
          <AccordionContent>
            <S.TeamDetailsContent>{children}</S.TeamDetailsContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </S.RosterSideNavBottom>
  ) : (
    <S.RosterSideNavBottom>{children}</S.RosterSideNavBottom>
  );
}
