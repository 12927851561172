"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Registration = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var utilities_1 = require("@la/utilities");
var ErrorCard_1 = require("../../components/ErrorCard/ErrorCard");
var MainContent_1 = require("../../components/MainContent/MainContent");
var Stepper_1 = require("../../components/Stepper/Stepper");
var SubNavigation_1 = require("../../components/SubNavigation/SubNavigation");
var BaseRegistration_1 = require("./BaseRegistration");
var PaymentOptionsStep_1 = require("./PaymentOptionsStep/PaymentOptionsStep");
var ReviewInvoices_1 = require("./ReviewInvoices/ReviewInvoices");
var SelectPayer_1 = require("./SelectPayer/SelectPayer");
var MCRegistrationSteps_1 = require("./TeamRegistration/MCRegistrationSteps");
var TeamRegistrationContext_1 = require("./TeamRegistration/TeamRegistrationContext");
var Wizard_1 = require("./Wizard/Wizard");
var WizardHeader_1 = require("./Wizard/components/WizardHeader/WizardHeader");
var constants_1 = require("./constants");
var usePaymentOptions_1 = require("./hooks/usePaymentOptions");
var getNumberOfRegisteringTeams_1 = require("./utilities/getNumberOfRegisteringTeams");
var S = require("./Registration.styles");
var Wizard_styles_1 = require("./Wizard/Wizard.styles");
var BaseRegistration = function (_a) {
    var _b = _a.isMC, isMC = _b === void 0 ? false : _b, _c = _a.includeRegistrationTriageFix, includeRegistrationTriageFix = _c === void 0 ? false : _c, _d = _a.mcPaymentPlans, mcPaymentPlans = _d === void 0 ? false : _d;
    var _e = (0, react_router_dom_1.useParams)(), tournamentId = _e.tournamentId, siteId = _e.siteId;
    var _f = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext), state = _f.state, setWizardState = _f.setWizardState;
    var _g = (0, react_1.useState)(1), step = _g[0], setStep = _g[1];
    var _h = (0, react_1.useState)(false), payerTouched = _h[0], setPayerTouched = _h[1];
    var _j = (0, react_1.useState)(false), paymentOptionTouched = _j[0], setPaymentOptionTouched = _j[1];
    var _k = (0, react_1.useState)(false), teamsTouched = _k[0], setTeamsTouched = _k[1];
    var _l = (0, react_1.useState)(false), saveDivisions = _l[0], setSaveDivisions = _l[1];
    var _m = (0, react_1.useState)(false), isCreatingInvoices = _m[0], setIsCreatingInvoices = _m[1];
    var _o = (0, react_1.useState)(0), totalCost = _o[0], setTotalCost = _o[1];
    var _p = (0, react_1.useState)(false), isCalculatingPrices = _p[0], setIsCalculatingPrices = _p[1];
    if (!tournamentId) {
        throw new Error('The tournament ID is not defined');
    }
    var _q = (0, services_1.useGetTournamentQuery)({
        siteDomain: 'svssc',
        tournamentId: tournamentId,
        siteId: siteId,
    }), tournament = _q.data, isTournamentLoading = _q.isLoading, tournamentError = _q.error;
    var _r = (0, usePaymentOptions_1.usePaymentOptions)({
        siteId: siteId,
        programId: tournamentId,
    }), paymentOptions = _r.paymentOptions, paymentRules = _r.paymentRules, isPaymentOptionsLoading = _r.isLoading;
    var _s = (0, services_1.useGetCountriesAndAdministrativeDivisionsQuery)(), isCountriesLoading = _s.isLoading, hasCountriesError = _s.isError;
    var MCStepTitles = (0, react_1.useMemo)(function () {
        return (0, MCRegistrationSteps_1.getMCStepTitles)(!mcPaymentPlans || !paymentOptions || !paymentOptions.length);
    }, [paymentOptions, mcPaymentPlans]);
    var numberOfSteps = Object.keys(MCStepTitles).length;
    if (isTournamentLoading ||
        isCountriesLoading ||
        isPaymentOptionsLoading ||
        isCalculatingPrices) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "We are gathering available divisions...", loading: true }) }));
    }
    if (isCreatingInvoices) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "Creating invoices...", loading: true }) }));
    }
    if (tournamentError || hasCountriesError) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContent, { children: (0, jsx_runtime_1.jsx)(Wizard_styles_1.WizardContent, { children: (0, jsx_runtime_1.jsx)(S.RegistrationErrorContainer, { children: (0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: constants_1.API_ERROR_MESSAGE }) }) }) }));
    }
    var registrationEndDate = tournament.registrationEndDate, registrationStartDate = tournament.registrationStartDate, timeZone = tournament.timeZone;
    if (tournament.divisions.length === 0 ||
        (registrationStartDate &&
            registrationEndDate &&
            !(0, utilities_1.isWithinDateRange)(registrationStartDate, registrationEndDate, timeZone))) {
        throw new Error(constants_1.TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
    }
    var hasRegistrationsToEdit = !!(tournament === null || tournament === void 0 ? void 0 : tournament.divisions.find(function (division) {
        var _a;
        return ((_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team) &&
            division.registrationsForUser.team.find(function (registration) { return registration.registrationStatus === 'CREATED'; }) &&
            division.registrationsForUser.team.find(function (registration) { var _a; return registration.registeringUserId === ((_a = state.payer) === null || _a === void 0 ? void 0 : _a.id.toString()); });
    }));
    var renderSteps = function () {
        switch (MCStepTitles[step]) {
            case constants_1.ADD_PRIMARY_STAFF:
                return (0, jsx_runtime_1.jsx)(SelectPayer_1.SelectPayer, {});
            case constants_1.ADD_TEAMS_TO_PROGRAM:
                return ((0, jsx_runtime_1.jsx)(BaseRegistration_1.BaseWizard, { saveDivisions: saveDivisions, stepForward: stepForward, setWizardState: setWizardState, includeRegistrationTriageFix: includeRegistrationTriageFix, paymentOptions: paymentOptions, setIsCalculatingPrices: setIsCalculatingPrices, mcPaymentPlans: mcPaymentPlans }));
            case constants_1.SELECT_PAYMENT_OPTIONS: {
                return ((0, jsx_runtime_1.jsx)(PaymentOptionsStep_1.PaymenOptionsStep, { paymentOptions: paymentOptions, paymentRules: paymentRules, setTotalCost: setTotalCost }));
            }
            case constants_1.REVIEW_REGISTRATIONS: {
                var paymentOption = paymentOptions === null || paymentOptions === void 0 ? void 0 : paymentOptions.find(function (option) { return option.paymentPlanId === state.selectedPaymentOption; });
                return ((0, jsx_runtime_1.jsx)(ReviewInvoices_1.ReviewInvoices, { paymentOption: state.selectedPaymentOption === 'pay-in-full'
                        ? null
                        : paymentOption, totalCost: totalCost, mcPaymentPlans: mcPaymentPlans }));
            }
        }
    };
    var stepForward = function () {
        setStep(function (step) {
            if (step < numberOfSteps) {
                return step + 1;
            }
            return step;
        });
    };
    var renderWizard = function () {
        var _a;
        if (!isMC) {
            return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: hasRegistrationsToEdit ? ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Edit your divisions and teams", submitButtonText: "Update cart", submitUnsavedChangesErrorMessage: "Registrations were not updated in the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) : ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Add your divisions and teams", submitButtonText: "Add to cart", submitUnsavedChangesErrorMessage: "Registrations were not added to the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, includeRegistrationTriageFix: includeRegistrationTriageFix })) }));
        }
        var registeredTeams = (0, getNumberOfRegisteringTeams_1.getNumberOfRegisteringTeams)((_a = state.wizardState) === null || _a === void 0 ? void 0 : _a.divisionCardsState);
        var checkForErrors = function () {
            switch (MCStepTitles[step]) {
                case constants_1.ADD_PRIMARY_STAFF:
                    return !state.payer && payerTouched;
                case constants_1.ADD_TEAMS_TO_PROGRAM:
                    return registeredTeams === 0 && teamsTouched;
                case constants_1.SELECT_PAYMENT_OPTIONS:
                    return !state.selectedPaymentOption && paymentOptionTouched;
                case constants_1.REVIEW_REGISTRATIONS:
                    return false;
            }
        };
        var hasError = checkForErrors();
        var sendRegistrationsToWorkflow = function () { return __awaiter(void 0, void 0, void 0, function () {
            var numberOfRegisteredTeams;
            return __generator(this, function (_a) {
                numberOfRegisteredTeams = (0, getNumberOfRegisteringTeams_1.getNumberOfRegisteringTeams)(state.wizardState.divisionCardsState);
                setIsCreatingInvoices(true);
                (0, services_1.sendRegistrationsForInvoice)({
                    state: state,
                    siteId: siteId,
                })
                    .then(function () {
                    var invoicePagePath = "https://".concat(window.location.host, "/console/sites/").concat(siteId, "/tournaments/").concat(tournament.id, "?registeredTeams=").concat(numberOfRegisteredTeams);
                    window.location.assign(invoicePagePath);
                })
                    .catch(function (err) {
                    setIsCreatingInvoices(false);
                    console.error(err);
                });
                return [2 /*return*/];
            });
        }); };
        var handleNextClick = function () {
            switch (MCStepTitles[step]) {
                case constants_1.ADD_PRIMARY_STAFF: {
                    setPayerTouched(true);
                    if (state.payer) {
                        stepForward();
                    }
                    break;
                }
                case constants_1.ADD_TEAMS_TO_PROGRAM: {
                    setTeamsTouched(true);
                    if (registeredTeams === 0) {
                        break;
                    }
                    setSaveDivisions(true);
                    break;
                }
                case constants_1.SELECT_PAYMENT_OPTIONS: {
                    setPaymentOptionTouched(true);
                    if (state.selectedPaymentOption) {
                        stepForward();
                    }
                    break;
                }
                case constants_1.REVIEW_REGISTRATIONS: {
                    sendRegistrationsToWorkflow();
                    break;
                }
            }
        };
        var onBackClick = function () {
            switch (MCStepTitles[step]) {
                case constants_1.ADD_PRIMARY_STAFF: {
                    break;
                }
                case constants_1.ADD_TEAMS_TO_PROGRAM: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
                case constants_1.SELECT_PAYMENT_OPTIONS: {
                    setSaveDivisions(false);
                    onBackwards();
                    break;
                }
                case constants_1.REVIEW_REGISTRATIONS: {
                    onBackwards();
                    break;
                }
            }
        };
        var onBackwards = function () {
            setStep(function (step) {
                if (step > 1) {
                    return step - 1;
                }
                return step;
            });
        };
        return ((0, jsx_runtime_1.jsx)(Stepper_1.Stepper, __assign({ currentStep: MCStepTitles[step], error: hasError, form: {}, handleNextClick: handleNextClick, numberOfTotalSteps: numberOfSteps, onBackClick: onBackClick, showDrawer: true, stepNumber: step, showSteps: true, type: "button", isMC: true }, { children: renderSteps() })));
    };
    return ((0, jsx_runtime_1.jsxs)(MainContent_1.MainContent, { children: [(0, jsx_runtime_1.jsx)(WizardHeader_1.WizardHeader, { isMC: isMC, tournament: tournament }), (0, jsx_runtime_1.jsx)(SubNavigation_1.SubNavigation, { programIdFromProps: parseInt(tournamentId) }), renderWizard()] }));
};
// Here we will fork MP registration and MC registration components
var Registration = function (props) { return ((0, jsx_runtime_1.jsx)(services_1.Provider, __assign({ store: services_1.store }, { children: (0, jsx_runtime_1.jsx)(BaseRegistration, __assign({}, props)) }))); };
exports.Registration = Registration;
