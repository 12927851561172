import styled from 'styled-components';
import { ThemedComponent, Typography } from '@la/ds-ui-components';

export const RecurringActivityText = styled(Typography)<ThemedComponent>(({
  theme,
}: ThemedComponent) => {
  const { foundational } = theme.palette;
  return {
    color: foundational.blueGrey[900],
    marginBottom: '24px',
  };
});

export const EditButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Flex = styled.div`
  margin-top: 44px;

  button {
    padding-left: 0;
  }
`;
