import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

export const PaymentMethodCardBody = styled.div<{
  $hasPaymentMethod: boolean;
}>`
  padding-bottom: ${({ $hasPaymentMethod }) =>
    $hasPaymentMethod ? '0px' : '16px'};
`;

export const SelectedPaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px 24px;
`;

export const PaymentMethodDetail = styled.div`
  font: var(--ui-large-regular);
  color: var(--blue-grey-900);
`;

export const PaymentMethodLogo = styled.img`
  border: 1px solid var(--blue-grey-400);
  border-radius: 4px;
`;

export const AutopayPaymentMethodWarning = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    padding: '0 24px 8px',

    '& > div': {
      backgroundColor: theme.foundational.orange[50],
      alignItems: 'flex-start',
      height: 'auto',

      svg: {
        stroke: theme.foundational.orange[700],
        width: '24px',
        height: '24px',
      },
    },
  })
);

export const AutopayContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    padding: '8px 24px 16px',

    label: {
      color: theme.foundational.blueGrey[900],
      '&[data-disabled]': {
        color: theme.foundational.blueGrey[200],
      },
    },
  })
);

export const AutopayDisclaimer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    color: theme.foundational.blueGrey[600],
    paddingTop: '12px',
  })
);
