"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardSummary = exports.REGISTRATION_WIZARD_SUMMARY_TEST_ID = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var SummaryAndSubmit_1 = require("../SummaryAndSubmit");
var SummaryCards_1 = require("../SummaryCards/SummaryCards");
var WizardSubtotal_1 = require("../WizardSubtotal/WizardSubtotal");
var S = require("./WizardSummary.styles");
exports.REGISTRATION_WIZARD_SUMMARY_TEST_ID = 'registration-wizard-summary-cards-and-subtotal';
/* WizardSummary */
function WizardSummary(props) {
    var divisionCardsState = props.divisionCardsState, tournament = props.tournament;
    var divisionsForSummarySection = getDivisionsToShowSummaryForLargerScreens(props.divisionCardsState);
    var shouldShowSubtotal = Object.values(divisionCardsState).some(function (_a) {
        var value = _a.value;
        return ['saved', 'saved-and-editing', 'not-saved'].includes(value);
    }) &&
        divisionsForSummarySection.some(function (division) { return division.savedTeams && division.savedTeams.length > 0; });
    return ((0, jsx_runtime_1.jsxs)(S.WizardSummary, __assign({ "data-testid": exports.REGISTRATION_WIZARD_SUMMARY_TEST_ID }, { children: [shouldShowSubtotal ? ((0, jsx_runtime_1.jsx)(SummaryCards_1.SummaryCards, { divisionsForSummarySection: divisionsForSummarySection.filter(function (division) { return division.savedTeams && division.savedTeams.length > 0; }), teams: props.teams, tournament: props.tournament })) : ((0, jsx_runtime_1.jsx)(SummaryPlaceholder, {})), shouldShowSubtotal ? ((0, jsx_runtime_1.jsx)(WizardSubtotal_1.WizardSubtotal, { divisionCardsState: divisionCardsState, tournament: tournament, isMC: props.isMC })) : null] })));
}
exports.WizardSummary = WizardSummary;
/* */
var SummaryPlaceholder = function () {
    return ((0, jsx_runtime_1.jsxs)(S.SummaryPlaceholderContainer, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "headline", size: "small" }, { children: "You have not added any teams." })), (0, jsx_runtime_1.jsxs)(S.PlaceholderTipContainer, { children: [(0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: ["Select a", ' '] })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.PlusCircleIcon, { fill: "var(--primary, var(--green-600))", size: "xl", variant: "filled" }), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "to get started" }))] })] }));
};
function getDivisionsToShowSummaryForLargerScreens(divisionCardsState) {
    return (0, SummaryAndSubmit_1.getDivisionCardsStateArray)(divisionCardsState).filter(function (division) {
        return division.value === 'not-saved' ||
            division.value === 'saved' ||
            division.value === 'saved-and-editing';
    });
}
