import * as S from './SecureCheckoutBadge.styles';

export type SecureCheckoutBadgeProps = {};

/* SecureCheckoutBadge */
export default function SecureCheckoutBadge() {
  return (
    <S.SecureCheckoutBadge>
      <S.SecureIcon />
      Secure checkout powered by
      <S.LALeagueappsLogo />
    </S.SecureCheckoutBadge>
  );
}
/* */
