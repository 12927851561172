import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MessagingHub from 'components/MessagingHub/MessagingHub';
import { MainNavigationProvider } from 'lib/context/MainNavigationContext';
import { MessagingProvider } from 'lib/context/MessagingContext';
import { UserProvider } from 'lib/context/UserContext';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import * as S from './Layout.styles';

export type LayoutProps = {
  hasHeader?: boolean;
  hasFooter?: boolean;
};

/* Layout */
export default function Layout({
  hasHeader = true,
  hasFooter = true,
}: LayoutProps) {
  const { pathname } = useLocation();

  /**
   * Whenever the user navigates to a new page and the pathname changes
   * the window will be scrolled to the top
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <UserProvider>
      <MessagingProvider>
        <MainNavigationProvider>
          <S.Layout $hasHeader={hasHeader}>
            {hasHeader ? <Header /> : null}
            <Outlet />
            {hasFooter ? <Footer /> : null}
            <MessagingHub />
          </S.Layout>
        </MainNavigationProvider>
      </MessagingProvider>
    </UserProvider>
  );
}
/* */
