export const checkboxVariantSizes = {
  small: '16px',
  large: '20px'
};
export const checkboxBorderRadius = {
  small: '2px',
  large: '4px'
};
export const checkboxGaps = {
  small: '6px',
  large: '8px'
};