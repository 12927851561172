"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalCost = void 0;
var utilities_1 = require("@la/utilities");
var hasSavedTeams = function (divisionState) {
    return ['saved', 'saved-and-editing'].includes(divisionState.value);
};
var calculateTotalCost = function (divisionCardStates, divisions) {
    return Object.entries(divisionCardStates).reduce(function (total, _a) {
        var id = _a[0], divisionCardState = _a[1];
        if (hasSavedTeams(divisionCardState)) {
            var division = divisions.find(function (d) { return d.id === id; });
            if (division) {
                var savedTeams = divisionCardState.savedTeams;
                return total + (0, utilities_1.getDivisionCost)(division) * savedTeams.length;
            }
        }
        return total;
    }, 0);
};
exports.calculateTotalCost = calculateTotalCost;
