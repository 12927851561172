import { FormField, Team } from '@la/types';

export const hasIncompleteRequiredFields = (
  formFields: FormField[],
  team: Team
) => {
  const requiredFields = formFields.filter(
    (field) => field.usageLevel === 'REQUIRED'
  );

  if (!requiredFields.length) {
    return false;
  }

  return requiredFields.some((field) => {
    const teamFormField = team.formFields?.find(
      ({ formFieldId }) => formFieldId === field.propertyDefinitionId
    );

    if (!teamFormField || !teamFormField.values) {
      return true;
    }

    if (field.type === 'MULTIPLE_CHECKBOXES' || field.type === 'PICK_LIST') {
      const value = teamFormField.values[0];
      return !field.items.find((item) => item.itemId.toString() === value);
    }

    return !teamFormField.values[0];
  });
};

export const getIncompleteRequiredFieldsTeams = (
  formFields: FormField[],
  teams: Team[]
) => {
  return teams.filter((team) => hasIncompleteRequiredFields(formFields, team));
};
