import { http, HttpResponse } from 'msw';
import {
  CountriesAndAdministrativeDivisions,
  Country,
} from 'redux/services/countryApi';

export enum CountryEndpoint {
  GetCountries = '*/api/countries',
}

export const mockCountries: Country[] = [
  {
    code: 'CAN',
    name: 'Canada',
    admin1: { AB: 'Alberta', BC: 'British Columbia' },
  },
  { code: 'FRA', name: 'France' },
  {
    code: 'USA',
    name: 'United States',
    admin1: { DE: 'Delaware', MA: 'Massachusetts', PA: 'Pennsylvania' },
  },
];

export const mockCountriesAndAdministrativeDivisions: CountriesAndAdministrativeDivisions =
  {
    countries: { CAN: 'Canada', FRA: 'France', USA: 'United States' },
    provincesCAN: { AB: 'Alberta', BC: 'British Columbia' },
    statesUSA: { DE: 'Delaware', MA: 'Massachusetts', PA: 'Pennsylvania' },
  };

export const countryHandlers = [
  http.get(CountryEndpoint.GetCountries, () => {
    return HttpResponse.json(mockCountries, { status: 200 });
  }),
];
