"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactiveTypography = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
exports.ReactiveTypography = (0, styled_components_1.default)(ds_ui_components_1.Typography)(function (_a) {
    var theme = _a.theme, background = _a.background, _b = _a.isWhite, isWhite = _b === void 0 ? false : _b;
    var semantic = theme.semantic, _c = theme.foundational, blueGrey = _c.blueGrey, white = _c.white;
    if (!semantic) {
        return {
            color: isWhite ? white : blueGrey[900],
        };
    }
    return {
        color: semantic[background].reactiveTextColor,
    };
});
