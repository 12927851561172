import { PropsWithChildren, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SecureCheckoutBadge from '../SecureCheckoutBadge/SecureCheckoutBadge';
import SubtotalReceipt from '../SubtotalReceipt/SubtotalReceipt';
import * as S from './CartSummaryRollup.styles';

export type CartSummaryRollupProps = {
  subTotalPrice: string;
};
/* CartSummaryRollup */
export default function CartSummaryRollup({
  subTotalPrice,
}: CartSummaryRollupProps) {
  const navigate = useNavigate();

  //TO DO should add more logic in the future
  function handleProceedToCheckout() {
    navigate('/checkout');
  }

  return (
    <S.CartSummaryRollup>
      <RollupButtonContainer>
        <S.ProceedToCheckoutButton
          onClick={handleProceedToCheckout}
          width="100%"
          size="large"
        >
          Proceed to checkout
        </S.ProceedToCheckoutButton>
      </RollupButtonContainer>
      <SubtotalReceipt subTotalPrice={subTotalPrice} />
      <SecureCheckoutBadge />
    </S.CartSummaryRollup>
  );
}
/* */

/*RollupButtonContainer */
export function RollupButtonContainer({ children }: PropsWithChildren) {
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerPosition, setContainerPosition] = useState<number>(0);

  useEffect(() => {
    const buttonContainer = buttonContainerRef.current;
    const footer = document.querySelector('footer');

    const handleScroll = () => {
      if (buttonContainer && footer) {
        const distanceToFooter =
          footer.getBoundingClientRect().top -
          buttonContainer.getBoundingClientRect().bottom;
        if (distanceToFooter < 4) {
          setContainerPosition(footer.offsetHeight);
        } else {
          setContainerPosition(0);
        }
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <S.RollupButtonContainer
      ref={buttonContainerRef}
      $containerPosition={containerPosition}
    >
      {children}
    </S.RollupButtonContainer>
  );
}
/* */
