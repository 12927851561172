import { Env, getEnv } from './urlUtils';

const ClientID = {
  Local: process.env.REACT_APP_LD__LOCAL_HOST_CLIENT_ID || '',
  Production: process.env.REACT_APP_LD__PRODUCTION_CLIENT_ID || '',
};

type LaunchDarklyConfig = {
  env: Env;
  clientID: string;
};

const cicd1Config: LaunchDarklyConfig = {
  env: Env.CICD1,
  clientID: ClientID.Local,
};

const localConfig: LaunchDarklyConfig = {
  env: Env.Local,
  clientID: ClientID.Local,
};

const dev1Config: LaunchDarklyConfig = {
  env: Env.Dev1,
  clientID: ClientID.Local,
};

const dev2Config: LaunchDarklyConfig = {
  env: Env.Dev2,
  clientID: ClientID.Local,
};

const dev3Config: LaunchDarklyConfig = {
  env: Env.Dev3,
  clientID: ClientID.Local,
};

const qa1Config: LaunchDarklyConfig = {
  env: Env.QA1,
  clientID: ClientID.Local,
};

const qa2Config: LaunchDarklyConfig = {
  env: Env.QA2,
  clientID: ClientID.Local,
};

const previewConfig: LaunchDarklyConfig = {
  env: Env.Preview,
  clientID: ClientID.Local,
};

const productionConfig: LaunchDarklyConfig = {
  env: Env.Production,
  clientID: ClientID.Production,
};

const launchDarklyClientID = new Map<Env, LaunchDarklyConfig>();
launchDarklyClientID.set(Env.CICD1, cicd1Config);
launchDarklyClientID.set(Env.Local, localConfig);
launchDarklyClientID.set(Env.Dev1, dev1Config);
launchDarklyClientID.set(Env.Dev2, dev2Config);
launchDarklyClientID.set(Env.Dev3, dev3Config);
launchDarklyClientID.set(Env.QA1, qa1Config);
launchDarklyClientID.set(Env.QA2, qa2Config);
launchDarklyClientID.set(Env.Preview, previewConfig);
launchDarklyClientID.set(Env.Production, productionConfig);

export default launchDarklyClientID.get(getEnv());
