import styled from 'styled-components/macro';
import { Button, Typography } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const RosterSideNavLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 480px;
  padding: 16px;
  background-color: var(--white);
  border: 1px solid var(--blue-grey-400);
`;

export const RosterSideNav = styled.div`
  display: flex;
  flex-direction: column;

  ${media.TabletLandscapeUp`
    min-height: 480px;
    background-color: var(--white);
    border: 1px solid var(--blue-grey-400);
  `}
`;

export const RosterSideNavTop = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0 16px;
  grid-template-areas: 'TeamLogo LinksTeamName LinksTeamName LinksTeamName';

  ${media.TabletPortraitUp`
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-areas: 'TeamLogo LinksTeamName LinksTeamName LinksTeamName LinksTeamName LinksTeamName';
  `}

  ${media.TabletLandscapeUp`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    gap: 8px;
    padding: 16px;
  `}
`;

export const TeamLogoLink = styled.a`
  grid-area: TeamLogo;

  > img {
    display: block;
    object-fit: cover;
    max-width: 100%;
    height: auto;
  }
`;

export const LinksTeamName = styled.div`
  grid-area: LinksTeamName;
  width: 100%;

  > div {
    display: block;

    > a {
      color: var(--blue-grey-900);

      &:hover {
        color: var(--secondary-800);
      }
    }
  }

  ${media.TabletLandscapeUp`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const RosterTeamName = styled(Typography)`
  ${media.TabletLandscapeUp`
      text-align: center;
    `}
`;

export const RosterSideNavBottom = styled.div`
  padding: 16px 0;

  a {
    color: var(--blue-grey-900);

    &:hover {
      color: var(--secondary-800);
    }
  }

  ${media.TabletLandscapeUp`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  `}
`;

export const TeamDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
`;

export const ViewAnotherTeamButton = styled(Button)`
  margin: 32px 0 40px;
  padding: 0 16px;
  background-color: white;

  ${media.TabletLandscapeUp`
    margin: 24px 16px 32px;
    width: calc(100% - 32px);
  `}
`;

export const RosterSideNavLinks = styled.div`
  width: 100%;
`;

export const RosterSideNavLink = styled.a<{ $isActive?: boolean }>`
  display: inline-block;
  width: 50%;
  height: 56px;
  padding-top: 16px;
  border: 1px solid var(--primary-600);
  text-align: center;
  text-decoration: none;
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--primary-25)' : 'var(--white)'};

  &:hover {
    background-color: var(--primary-25);
  }

  :last-of-type {
    border-left: none;
  }

  ${media.TabletPortraitUp``}
  ${media.TabletLandscapeUp`
    width: 100%;
    height: 72px;
    padding-top: 24px;
    border: 1px solid var(--blue-grey-400);
    border-right: none;
    border-left: none;
  
    :last-of-type {
      border-top: none;
    }
  `}
`;
