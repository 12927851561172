import { ReactNode } from 'react';
import {
  CheckCircleIcon,
  Modal,
  ModalPrimaryActionButton,
  ModalProps,
} from '@la/ds-ui-components';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { InvitationLinks } from '@la/types';
import { Alert } from 'components/Alert/Alert';
import {
  InvitePlayerContent,
  InviteStaffContent,
} from 'domains/RosterManagement/InvitePlayerStaffModals';
import * as S from './InviteModal.styles';

export const INVITE_MODAL_TITLE = 'Invite your roster';
export const OK_LABEL = 'OK';

export type InviteModalProps = Pick<ModalProps, 'onOpenChange' | 'open'> & {
  /**
   * Links for inviting players and staff to registration.
   */
  invitationLinks: InvitationLinks;
  /**
   * Total players rolled over.
   */
  totalPlayers?: number;
  /**
   * Total staff rolled over.
   */
  totalStaff?: number;
};

/* InviteModal */
export function InviteModal({
  invitationLinks,
  onOpenChange,
  open,
  totalPlayers = 0,
  totalStaff = 0,
}: Readonly<InviteModalProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const rolloverMessage = getRolloverMessage(totalPlayers, totalStaff);

  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton
          onClick={() => {
            if (onOpenChange) {
              onOpenChange(false);
            }
          }}
          width={isTabletPortaitUp ? 'auto' : '100%'}
        >
          {OK_LABEL}
        </ModalPrimaryActionButton>
      }
      title={INVITE_MODAL_TITLE}
    >
      <S.AlertContainer>
        <Alert
          icon={
            <CheckCircleIcon
              fill="var(--green-500)"
              size="large"
              variant="bold"
            />
          }
          message={rolloverMessage}
          type="success"
        />
      </S.AlertContainer>
      <S.Info>
        Now it's time to invite them to register. Share the invite links below
        with your staff and players so they can join your team.
      </S.Info>
      <InvitePlayerContent playerInvite={invitationLinks.playerInviteLink} />
      <InviteStaffContent staffInvites={invitationLinks.staffInviteLinks} />
    </Modal>
  );
}
/* */

function getRolloverMessage(
  totalPlayers: number,
  totalStaff: number
): ReactNode {
  if (totalPlayers === 0 && totalStaff > 0) {
    return (
      <>
        Success! <strong>{getStaffText(totalStaff)}</strong> have been
        successfully rolled over.
      </>
    );
  } else if (totalPlayers > 0 && totalStaff === 0) {
    return (
      <>
        Success! <strong>{getPlayerText(totalPlayers)}</strong> have been
        successfully rolled over.
      </>
    );
  } else {
    return (
      <>
        Success!{' '}
        <strong>{getStaffAndPlayerText(totalPlayers, totalStaff)}</strong> have
        been successfully rolled over.
      </>
    );
  }
}

export function getStaffText(count: number): string {
  return `${count} staff member${count > 1 ? 's' : ''}`;
}

export function getPlayerText(count: number): string {
  return `${count} player${count > 1 ? 's' : ''}`;
}

export function getStaffAndPlayerText(
  playerCount: number,
  staffCount: number
): string {
  return `${getPlayerText(playerCount)} and ${getStaffText(staffCount)}`;
}
