import styled from 'styled-components';
import { DefaultTheme } from '@la/ds-ui-components';

export const TeamPrimaryStaffHeader = styled.div`
  padding: 9px 16px;
  display: grid;
  grid-template-columns: 60% 40%;
`;

type TeamOptionProps = {
  $isDisabled?: boolean;
  $isPlaceholder?: boolean;
  theme: DefaultTheme;
};

export const TeamOptionContainer = styled.div<TeamOptionProps>(
  ({ $isDisabled, $isPlaceholder, theme }: TeamOptionProps) => {
    const { foundational } = theme;
    const fontStyle = $isPlaceholder ? 'italic' : 'normal';

    const cursor = $isDisabled ? 'not-allowed' : 'pointer';
    const pointerEvents = $isDisabled ? 'none' : 'auto';

    return {
      cursor,
      pointerEvents,
      display: 'grid',
      width: '100%',
      fontStyle,
      background: 'transparent',
      padding: '9px 16px',
      textAlign: 'left',

      '&[aria-selected="true"], :hover': {
        background: foundational.blueGrey[50],
      },
    };
  }
);

export const TeamOption = styled.div<{ $isDisabled?: boolean }>`
  padding: 0;
  word-break: break-word;
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 10px;

  > p {
    color: ${({ $isDisabled }) =>
      $isDisabled ? 'var(--blue-grey-250)' : 'var(--black)'};
  }
`;
