"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainContentCenter = exports.MainContent = void 0;
var styled_components_1 = require("styled-components");
exports.MainContent = styled_components_1.default.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n  width: 100%;\n"])));
exports.MainContentCenter = (0, styled_components_1.default)(exports.MainContent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n"], ["\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2;
