import { MouseEvent, PropsWithChildren } from 'react';
import * as S from './Backdrop.styles';

export type BackdropProps = {
  clickHandler: (evt: MouseEvent<HTMLDivElement>) => void;
  transparent: boolean;
};

/* Backdrop */
export default function Backdrop({
  children,
  clickHandler,
  transparent = false,
}: PropsWithChildren<BackdropProps>) {
  return (
    <S.Backdrop $transparent={transparent} onClick={clickHandler} role="none">
      {children}
    </S.Backdrop>
  );
}
/* */
