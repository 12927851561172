import { getSiteId, getSiteIdentityData } from 'redux/coreSlice';
import { useGetSocialMediaInfoQuery } from 'redux/services/socialMediaInfo';
import { useAppSelector } from 'redux/store';
import PowerByLAFooter from 'assets/LA_lockups/power_by_LA_footer.png';
import { ReactComponent as FacebookIcon } from 'assets/facebook_footer.svg';
import { ReactComponent as InstagramIcon } from 'assets/instagram_footer.svg';
import { ReactComponent as XIcon } from 'assets/x_footer.svg';
import SiteLogo from '../SiteLogo/SiteLogo';
import * as S from './Footer.styles';

export type SocialMedia = {
  name: string;
  href: string;
};

/* Footer */
export default function Footer() {
  const { logoHref, logoImageSrc, siteName } =
    useAppSelector(getSiteIdentityData);
  const siteId = useAppSelector(getSiteId);
  const skip = !siteId;
  const { data } = useGetSocialMediaInfoQuery(siteId, { skip });
  let socialIcons: SocialMedia[] = [];
  if (data) {
    Object.entries(data).forEach(([platform, { username }]) => {
      if (username) {
        if (platform === 'facebook') {
          socialIcons.push({
            name: platform,
            href: `https://facebook.com/${username}`,
          });
        }

        if (platform === 'twitter' || platform === 'x') {
          socialIcons.push({
            name: platform,
            href: `https://x.com/${username}`,
          });
        }

        if (platform === 'instagram') {
          socialIcons.push({
            name: platform,
            href: `https://instagram.com/${username}`,
          });
        }
      }
    });
  }

  if (
    window &&
    (window.location.href.includes('/registration/player') ||
      window.location.href.includes('/registration/staff'))
  ) {
    return null;
  }
  return (
    <S.Footer>
      <div id="mp-footer-portal"></div>
      <S.FooterMainContainer>
        <S.SiteLogoContainer>
          <SiteLogo
            $maxHeight="54px"
            siteLogoHref={logoHref}
            siteLogoImageSrc={logoImageSrc}
            siteName={siteName}
          />
        </S.SiteLogoContainer>
        <SocialIcons socialIcons={socialIcons} />
        <S.PoweredByLALogoContainer>
          <img src={PowerByLAFooter} alt="Powered by LeagueApps" />
        </S.PoweredByLALogoContainer>
      </S.FooterMainContainer>
    </S.Footer>
  );
}
/**/

/* Social Icons*/
function SocialIcons({ socialIcons }: { socialIcons: SocialMedia[] }) {
  return (
    <S.SocialIcons>
      {socialIcons.map((socialIcon) => (
        <S.SocialLink
          href={socialIcon.href}
          target="_blank"
          rel="noopener noreferrer"
          key={socialIcon.name}
        >
          {renderSocialIcon(socialIcon.name)}
        </S.SocialLink>
      ))}
    </S.SocialIcons>
  );
}
/**/

/*Render social icon */
function renderSocialIcon(name: string) {
  switch (name) {
    case 'facebook':
      return <FacebookIcon aria-label="Facebook" />;
    case 'twitter':
      return <XIcon aria-label="Twitter" />;
    case 'instagram':
      return <InstagramIcon aria-label="Instagram" />;
  }
}
/* */
