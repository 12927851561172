/**
 * This file was taken from Design System Repo
 * Used for SelectPopover Component
 */
import { createContext, ReactNode, useContext } from 'react';

export type FormContextProps =
  | {
      /**
       * The orientation of fieldsets, groups and input fields.
       * @default vertical
       */
      orientation?: 'vertical';
      /**
       * The size of the form fields inside the form. Will be propagated to all input fields inside the form.
       * Affects specifically: `Fieldset`, `Checkbox`, `NumberInput`, `TextArea`, `TextInput`, `RadioGroup`, `Select`.
       * Declaring a different `inputSize` at a component level will override this property (see Nested Forms).
       * @default large
       */
      inputSize?: 'large' | 'medium';
    }
  | {
      orientation?: 'horizontal';
      inputSize?: 'medium';
    };

type ProviderProps = {
  children: ReactNode;
} & FormContextProps;

const FormContext = createContext<FormContextProps>({});

const FormContextProvider = ({
  children,
  orientation,
  inputSize,
}: ProviderProps) => {
  const getValue = (): FormContextProps => {
    if (orientation === 'horizontal') {
      return {
        orientation,
        inputSize: 'medium',
      };
    }

    return {
      orientation,
      inputSize,
    };
  };
  return (
    <FormContext.Provider value={getValue()}>{children}</FormContext.Provider>
  );
};

const useFormContext = () => {
  const context = useContext(FormContext);
  return context;
};

export { FormContextProvider, useFormContext };
