/**
 * Given a number, returns a string representation of the value in currency format.
 *
 *
 * @param amount A number to be formatted as currency
 * @param locale  The language used in the user interface
 * @default en-US
 * @param currency ISO 4217 currency to use in currency formatting
 * @default USD
 * @returns A string displaying the number in USD
 *
 * Examples:
 *
 *  formatAsCurrency(5.25) => $5.25
 *
 *  formatAsCurrency(29999.99, 'JPY') => ￥123,457
 */
export function formatAsCurrency(amount, currency = 'USD', locale = 'en-US') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(amount);
}