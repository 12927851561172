"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardHeader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var luxon_1 = require("luxon");
var utilities_1 = require("@la/utilities");
var MainContentHeader_1 = require("../../../../../components/MainContent/MainContentHeader/MainContentHeader");
var MainContentHeaderBackButton_1 = require("../../../../../components/MainContent/MainContentHeaderBackButton/MainContentHeaderBackButton");
var MainContentHeaderTitle_1 = require("../../../../../components/MainContent/MainContentHeaderTitle/MainContentHeaderTitle");
var S = require("./WizardHeader.styles");
/* WizardHeader */
function WizardHeader(_a) {
    var title = _a.title, composedHeader = _a.composedHeader, _b = _a.isMC, isMC = _b === void 0 ? false : _b, tournament = _a.tournament;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var tournamentId = (0, react_router_dom_1.useParams)().tournamentId;
    var divisions = tournament.divisions, startDate = tournament.startDate, name = tournament.name, location = tournament.location;
    var _c = (0, react_1.useMemo)(function () {
        var divisionsStartDate;
        var divisionsEndDate = null;
        divisions.forEach(function (division) {
            if (divisionsStartDate === undefined ||
                luxon_1.DateTime.fromISO(division.startDate) < divisionsStartDate) {
                divisionsStartDate = luxon_1.DateTime.fromISO(division.startDate);
            }
            if (division.endDate !== null &&
                division.endDate !== undefined &&
                (divisionsEndDate === null ||
                    luxon_1.DateTime.fromISO(division.endDate) > divisionsEndDate)) {
                divisionsEndDate = luxon_1.DateTime.fromISO(division.endDate);
            }
        });
        return { divisionsStartDate: divisionsStartDate, divisionsEndDate: divisionsEndDate };
    }, [divisions]), divisionsStartDate = _c.divisionsStartDate, divisionsEndDate = _c.divisionsEndDate;
    var endDate = divisionsEndDate ? divisionsEndDate : undefined;
    var formattedDate = (0, utilities_1.formatDateRange)(divisionsStartDate || luxon_1.DateTime.fromISO(startDate), endDate);
    var renderHeader = function () {
        if (isMC) {
            return null;
        }
        if (composedHeader) {
            return composedHeader;
        }
        return ((0, jsx_runtime_1.jsxs)(S.Info, { children: [name, ": ", formattedDate, " ", location ? "at ".concat(location) : null] }));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(MainContentHeader_1.MainContentHeader, { children: [(0, jsx_runtime_1.jsx)(MainContentHeaderBackButton_1.MainContentHeaderBackButton, { onClick: function () {
                            navigate("/app/tournaments/".concat(tournamentId));
                        } }), (0, jsx_runtime_1.jsx)(MainContentHeaderTitle_1.MainContentHeaderTitle, __assign({ isMC: isMC }, { children: !isMC ? (title !== null && title !== void 0 ? title : 'Registration') : "".concat(name, " > Register teams") }))] }), renderHeader()] }));
}
exports.WizardHeader = WizardHeader;
