import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const IFrameContainer = styled.iframe`
  width: 100%;
  height: calc(100vh - 163px); //163px = header + footer heights
  margin: -8px 0 -16px; // to reverse the padding that Page adds
  border: none;

  ${media.TabletPortraitUp`
    margin: -16px 0 -32px; // to reverse the padding that Page adds
  `}

  ${media.TabletLandscapeUp`
    margin: -24px 0 -48px; // to reverse the padding that Page adds
  `}
`;
