import { useEffect, useState } from 'react';
import { Button, Checkbox } from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetProgramQuery } from 'redux/services/activity';
import { useAppSelector } from 'redux/store';
import {
  ActivityFormWrapperScreens,
  useActivityForm,
} from '../ActivityContext';
import { updateActivity } from '../apis/updateActivity';
import { updateAllDayActivity } from '../apis/updateAllDayActivity';
import { Activity } from '../openapi-client/models';
import { REQUEST_DELAY } from '../utils/ActivityForm.utils';
import * as S from './CancelActivity.styles';

enum ActivityState {
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
  Canceled = 'canceled',
}

export function CancelActivity({
  cancelActivity,
  handleSetActivityFormTitle,
  handleSetCurrentScreen,
  subProgramId,
}: {
  cancelActivity?: Activity;
  handleSetActivityFormTitle: (arg: string) => void;
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
  subProgramId?: string;
}) {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { handleSetSubmittedForm } = useActivityForm();

  const { data: program } = useGetProgramQuery(
    {
      siteId: siteId,
      programId: subProgramId,
    },
    { skip: !subProgramId }
  );

  const { data: masterProgram } = useGetProgramQuery(
    {
      siteId: siteId,
      programId: program?.masterProgramId?.toString(),
    },
    { skip: !program }
  );
  const notificationsEnabled = masterProgram?.preferences?.notificationsEnabled;

  const [willSendNotification, setWillSendNotification] = useState(false);

  const toggleWillSendNotification = () =>
    setWillSendNotification(!willSendNotification);

  useEffect(() => {
    handleSetActivityFormTitle('Cancel Activity');
  });
  const handleDiscardChanges = () => {
    handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
  };

  const handleCancelActivity = async () => {
    if (!cancelActivity) {
      return;
    }

    handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);

    if (cancelActivity.allDay) {
      await updateAllDayActivity({
        activityId: cancelActivity.id,
        siteId: Number(siteId),
        updateAllDayActivityRequestBody: {
          notify: willSendNotification ? '' : undefined,
          state: ActivityState.Canceled,
        },
      })
        .then((data) => {
          setTimeout(() => {
            handleSetSubmittedForm({
              activity: [data],
              submitType: 'canceled',
              willNotify: willSendNotification,
            });
            handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
          }, REQUEST_DELAY);
        })
        .catch((e) => {
          const errorMessage = typeof e === 'string' ? e : e.message;
          handleSetSubmittedForm({ errorMessage });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        });
    } else {
      await updateActivity({
        activityId: cancelActivity.id,
        updateActivityRequestBody: {
          notify: willSendNotification ? '' : undefined,
          siteId: Number(siteId),
          state: ActivityState.Canceled,
        },
      })
        .then((data) => {
          setTimeout(() => {
            handleSetSubmittedForm({
              activity: [data],
              submitType: 'canceled',
              willNotify: willSendNotification,
            });
            handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
          }, REQUEST_DELAY);
        })
        .catch((e) => {
          const errorMessage = typeof e === 'string' ? e : e.message;
          handleSetSubmittedForm({ errorMessage });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        });
    }
  };

  return (
    <>
      <S.ContentHeader>
        Are you sure you want to cancel this activity?
      </S.ContentHeader>
      <S.ContentBody>
        A canceled activity will remain visible on the schedule with an updated
        status of Canceled.
      </S.ContentBody>
      <S.NotifyContainer>
        <Checkbox
          ariaLabel="notify-participants"
          checked={willSendNotification}
          disabled={!notificationsEnabled}
          id="notify-checkbox"
          label={
            'Notify participants of the cancellation via email and push notification.'
          }
          size="large"
          onCheckedChange={toggleWillSendNotification}
        />
      </S.NotifyContainer>
      <S.FormButtons>
        <Button onClick={handleDiscardChanges} variant="text" size="large">
          Discard Changes
        </Button>
        <Button variant="primary" size="large" onClick={handleCancelActivity}>
          Cancel Activity
        </Button>
      </S.FormButtons>
    </>
  );
}
