import { DateTime, Interval } from 'luxon';

export const INVALID_TIMEZONE_ERROR_MESSAGE =
  'Timezone provided was not valid.';
export const START_DATE_BEFORE_END_DATE_ERROR_MESSAGE =
  'The start date cannot come before the end date.';
export const INVALID_DATE_ERROR_MESSAGE =
  'At least one date provided was not valid.';

/**
 * Sets the timezone for a provided date. This does not change the actual time,
 * rather it adjusts the offset based on the provided timezone.
 *
 * Example:
 *
 * Before: America/New_York
 * 2023-04-04T00:00:00.000-05:00
 *
 * After: America/Los_Angeles
 * 2023-04-04T00:00:00.000-08:00
 *
 * @param dates ISO formatted date strings.
 * @param timezone Timezone for the dates to converted into.
 * @returns `DateTime` array of all dates with their origin timezone set
 * as the provided timezone.
 */
export const setDatesTimezone = (
  dates: string[],
  timezone: string
): DateTime[] => {
  const isTimezoneValid = DateTime.now().setZone(timezone).isValid;
  if (!isTimezoneValid) {
    throw new Error(INVALID_TIMEZONE_ERROR_MESSAGE);
  }

  return dates.map((date) => {
    /**
     * When utilizing the second parameter rather than using the setZone()
     * method, it will create the DateTime object in the context of that timezone
     * such that only the offset is affected and not the actual time.
     */
    const dateObject = DateTime.fromISO(date, {
      zone: timezone,
      setZone: true,
    });
    if (!dateObject.isValid) {
      throw new Error(INVALID_DATE_ERROR_MESSAGE);
    }

    return dateObject;
  });
};

/**
 * Checks whether or not the current date is within a given date range.
 * @param start Start date of the range in ISO format.
 * @param end End date of the range in ISO format.
 * @param timezone Timezone to take into account.
 * @returns True if the current date falls with the start and end date
 * range, false otherwise.
 */
export const isWithinDateRange = (
  start: string,
  end: string,
  timezone: string
): boolean => {
  let [startDate, endDate] = setDatesTimezone([start, end], timezone);
  startDate = startDate.startOf('day');
  endDate = endDate.endOf('day');

  if (startDate > endDate) {
    throw new Error(START_DATE_BEFORE_END_DATE_ERROR_MESSAGE);
  }

  const currentDate = DateTime.now().setZone(timezone);

  return Interval.fromDateTimes(startDate, endDate).contains(currentDate);
};
