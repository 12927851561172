import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { ErrorResponse } from './verifyUrlSignature';

const baseUrl = getBaseURL();

const getTeam = async ({
  orgId,
  teamId,
}: {
  orgId: number;
  teamId: string;
}) => {
  const endpoint = `${baseUrl}/api/orgs/${orgId}/teams/${teamId}`;
  const token = extractAccessToken();
  try {
    if (teamId === 'null') {
      return null;
    }
    const response = await axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    if ((e as ErrorResponse).response.status === 404) {
      return null;
    }
    throw e;
  }
};

export { getTeam };
