"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDuplicateFormFields = exports.mapFormFieldToWorkflow = void 0;
/**
 * Maps properties from custom form fields to the format that the
 * registration workflow expects
 * @param formField Custom form field
 * @returns The format that the registration workflow expects
 */
var mapFormFieldToWorkflow = function (formField) {
    var _a, _b;
    var values;
    if (!formField.value) {
        values = [];
    }
    else if (formField.type === 'FILE_UPLOAD') {
        values = [(_a = formField.value) === null || _a === void 0 ? void 0 : _a.name, (_b = formField.value) === null || _b === void 0 ? void 0 : _b.uuid];
    }
    else if (formField.type === 'MULTIPLE_CHECKBOXES') {
        values = formField.value;
    }
    else {
        values = [formField.value];
    }
    return { values: values, formFieldId: formField.propertyDefinitionId };
};
exports.mapFormFieldToWorkflow = mapFormFieldToWorkflow;
/**
 * Filters array of form fields with their values uniquely. The first occurrence
 * of a `formFieldId` will be kept.
 * @param formFields Array of form fields that may or may not have reoccurring `formFieldId`s.
 * @returns Set of form fields where each `formFieldId` only occurs once.
 */
var removeDuplicateFormFields = function (formFields) {
    return formFields.reduce(function (fields, field) {
        var existingField = fields.find(function (_a) {
            var formFieldId = _a.formFieldId;
            return formFieldId === field.formFieldId;
        });
        return existingField ? fields : fields.concat(field);
    }, []);
};
exports.removeDuplicateFormFields = removeDuplicateFormFields;
