"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditTeamFieldsModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var hooks_1 = require("../../lib/hooks");
var breakpoints_1 = require("../../lib/media-queries/breakpoints");
var S = require("./EditTeamFieldsModal.styles");
var EditTeamFieldsModal = function (_a) {
    var teams = _a.teams, open = _a.open, onClose = _a.onClose, customFields = _a.customFields, addedTeams = _a.addedTeams;
    var _b = (0, react_1.useState)(teams[0].id), selectedTeam = _b[0], setSelectedTeam = _b[1];
    var isTabletPortaitUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletPortraitUp);
    (0, react_1.useEffect)(function () {
        var element = document.getElementById(selectedTeam);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [selectedTeam]);
    var renderTeams = function () {
        return teams
            .filter(function (team) { return addedTeams.includes(team.id); })
            .map(function (team) {
            var isSelected = selectedTeam === team.id;
            return ((0, jsx_runtime_1.jsx)(S.TeamItemContainer, __assign({ "$isSelected": isSelected, onClick: function () { return setSelectedTeam(team.id); } }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: isSelected ? 'bold' : 'regular' }, { children: (0, utilities_1.truncateWithEllipsis)(team.name, 25) })) }), team.id));
        });
    };
    var renderFields = function () {
        return teams
            .filter(function (team) { return addedTeams.includes(team.id); })
            .map(function (team) {
            return ((0, jsx_runtime_1.jsxs)(S.TeamContainer, __assign({ id: team.id }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: team.name })), (0, jsx_runtime_1.jsx)(S.TeamFieldsContainer, __assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: customFields.map(function (field) { return ((0, jsx_runtime_1.jsx)(S.CustomField, __assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.TextInput, { label: field.name + ' *', id: field.name, required: false }) }), field.name)); }) }), team.id)] }), team.id));
        });
    };
    return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Modal, __assign({ size: "large", open: open, onOpenChange: onClose, title: "Add team details", primaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ type: "submit" }, { children: "Save and continue" })), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ variant: "text", onClick: onClose }, { children: "Discard changes" })) }, { children: (0, jsx_runtime_1.jsxs)(S.EditTeamsContainer, { children: [isTabletPortaitUp ? ((0, jsx_runtime_1.jsx)(S.TeamsLeftPanel, { children: (0, jsx_runtime_1.jsx)(S.TeamLeftPanelInnerContainer, { children: renderTeams() }) })) : null, (0, jsx_runtime_1.jsxs)(S.EditTeamsContent, __assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: "Before you continue, this program requires additional information. Please complete the following fields before completing registration." })), (0, jsx_runtime_1.jsx)(S.FormFieldsContainer, { children: renderFields() })] }))] }) })));
};
exports.EditTeamFieldsModal = EditTeamFieldsModal;
