import { Button, Typography } from '@la/ds-ui-components';
import { TeamRoster } from 'redux/services/rosterManagementApi';
import { TeamDetails } from 'domains/RosterManagement/RosterRollover/TeamDetails/TeamDetails';
import * as S from './RolloverTeam.styles';

export const CHANGE_TEAM_LABEL = 'Change team';

export enum RolloverTeamType {
  Destination = 'Destination',
  Origin = 'Origin',
}

export type RolloverTeamProps = {
  /**
   * Triggered when `showTeamSelectionOption` is `true` and the change
   * team button is clicked.
   */
  onTeamSelectionClick?: () => void;
  /**
   * Whether or not to render the change team button.
   */
  showTeamSelection?: boolean;
  /**
   * The team to render players and staff for.
   */
  teamRoster: TeamRoster;
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the text that
   * appears when there are no members.
   */
  type: RolloverTeamType;
};

/* RolloverTeam */
export function RolloverTeam({
  onTeamSelectionClick,
  showTeamSelection = false,
  teamRoster,
  type,
}: RolloverTeamProps) {
  return (
    <S.RolloverTeam>
      <S.SummaryName>
        <Typography size="small" variant="headline">
          {teamRoster.team.name}
        </Typography>
        {showTeamSelection ? (
          <S.ChangeTeamButton>
            <Button onClick={onTeamSelectionClick} size="small" variant="text">
              {CHANGE_TEAM_LABEL}
            </Button>
          </S.ChangeTeamButton>
        ) : null}
      </S.SummaryName>
      <TeamDetails team={teamRoster.team} />
      <S.Members>
        <div data-testid={getMemberSectionId('player', type)}>
          <S.MembersTitle>Players</S.MembersTitle>
          {teamRoster.players.length > 0 ? (
            teamRoster.players.map((player) => (
              <S.Member draggable key={player.userId}>
                {player.name}
              </S.Member>
            ))
          ) : (
            <S.NoMembersText>
              There are no players on this team. <br />
              {getNoMembersText('player', type)}
            </S.NoMembersText>
          )}
        </div>
        <div data-testid={getMemberSectionId('staff', type)}>
          <S.MembersTitle>Staff</S.MembersTitle>
          {teamRoster.staff.length > 0 ? (
            teamRoster.staff.map((staff) => (
              <S.Member draggable key={staff.userId}>
                {staff.name}
              </S.Member>
            ))
          ) : (
            <S.NoMembersText>
              There are no staff on this team. <br />
              {getNoMembersText('staff', type)}
            </S.NoMembersText>
          )}
        </div>
      </S.Members>
    </S.RolloverTeam>
  );
}
/* */

export function getMemberSectionId(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
) {
  const memberTypeName = memberType === 'player' ? 'players' : 'staff';
  const typeName = type === RolloverTeamType.Origin ? 'origin' : 'destination';
  return `${memberTypeName}-${typeName}`;
}

export function getNoMembersText(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
) {
  return type === RolloverTeamType.Destination
    ? `Start adding ${memberType === 'player' ? 'players' : 'staff'} now!`
    : 'Please select another team.';
}
