import { http, HttpResponse } from 'msw';
import {
  mockCheckoutBillingSummary,
  mockCheckoutWaivers,
} from 'domains/Checkout/__dev__/mockValues_Checkout';

export enum CheckoutEndpoint {
  GetBillingSummary = '*/api/member-portal/*/checkout/billingSummary*',
  GetPaymentOptions = '*/api/sites/*/users/*/paymentOptions',
  GetWaivers = '*/api/sites/*/waivers',
  ProcessCart = '*/api/cart/sites/*/users/*/cart/process',
}

export const checkoutHandlers = [
  // Payment options
  http.get(CheckoutEndpoint.GetPaymentOptions, () => {
    return HttpResponse.json({}, { status: 200 });
  }),
  // Checkout billing summary
  http.get(CheckoutEndpoint.GetBillingSummary, () => {
    return HttpResponse.json(mockCheckoutBillingSummary, { status: 200 });
  }),
  // Checkout waivers
  http.get(CheckoutEndpoint.GetWaivers, () => {
    return HttpResponse.json(mockCheckoutWaivers, { status: 200 });
  }),
  // ProcessCart
  http.post(CheckoutEndpoint.ProcessCart, () => {
    return new HttpResponse(null, { status: 204 });
  }),
  // Add Discount Codes
  http.post('*/api/member-portal/*/checkout/billingSummary*', () => {
    return new HttpResponse(null, { status: 204 });
  }),
];
