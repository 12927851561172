"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfRegisteringTeams = void 0;
var getNumberOfRegisteringTeams = function (states) {
    if (!states) {
        return 0;
    }
    var total = 0;
    Object.values(states).forEach(function (divisionState) {
        if (divisionState.value === 'saved' && divisionState.savedTeams) {
            total += divisionState.savedTeams.length;
        }
    });
    return total;
};
exports.getNumberOfRegisteringTeams = getNumberOfRegisteringTeams;
