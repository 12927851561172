import styled from 'styled-components/macro';
import { Button } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const CartSummaryRollup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;

  ${media.TabletLandscapeUp`
    margin-top: 0px;
  `}
`;

export const RollupButtonContainer = styled.div<{
  $containerPosition: number;
}>`
  ${({ $containerPosition }) => media.TabletPortraitDown`
    position:fixed;
    bottom:${$containerPosition}px;
    left:0;
    width:100%;
    padding:8px 12px;
    background-color:var(--white);
    transition:bottom 0.1s ease;
  `}
`;

export const ProceedToCheckoutButton = styled(Button)`
  font: var(--ui-xtra-large-bold);
  padding: 12px;
`;
