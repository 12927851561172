import styled from 'styled-components';
import { ExclamationOctagonIcon } from 'components/Icon/ExclamationOctagon';
import { Typography } from 'components/Typography/Typography';
export const InputErrorMessageContainer = styled.div.withConfig({
  componentId: "sc-1jwum0g-0"
})(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    gap: spacing.xxxs,
    width: '100%',
    marginTop: spacing.xxxs
  };
});
export const InputErrorMessageRoot = styled(Typography).withConfig({
  componentId: "sc-1jwum0g-1"
})(({
  theme
}) => {
  return {
    color: theme.palette.error.medium
  };
});
export const ErrorIcon = styled(ExclamationOctagonIcon).attrs(({
  theme
}) => ({
  fill: theme.palette.error.medium
})).withConfig({
  componentId: "sc-1jwum0g-2"
})({});