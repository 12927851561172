import styled from 'styled-components/macro';
import {
  ThemedComponent,
  Typography,
  TypographyProps,
} from '@la/ds-ui-components';

export type ReactiveTypographyProps = ThemedComponent<
  {
    /**
     * This specifies which theme color is being used for the background
     * that this text will appear on top of. The component will use the
     * appropriate text color to ensure a high enought color contrast ratio.
     */
    background: 'primary' | 'secondary';
  } & TypographyProps
>;

export const ReactiveTypography = styled(Typography)<ReactiveTypographyProps>(({
  theme,
  background,
}: ReactiveTypographyProps) => {
  const {
    semantic,
    foundational: { blueGrey },
  } = theme;

  if (!semantic) {
    console.error('Reactive typography error: semantic colors are not defined');
    return {
      color: blueGrey[900],
    };
  }

  return {
    color: semantic[background].reactiveTextColor,
  };
});
