import { Table, TableCell, TableHeader, TableRow } from '@la/ds-ui-components';
import { RolloverMember } from '../utils/reducer';
import * as S from './RolloverSummary.styles';

export type RolloverSummaryProps = {
  /**
   * Members (players and/or staff) being rolled over.
   */
  members: RolloverMember[];
};

const TABLE_HEADERS = ['Rolled over member', 'Role', 'Parent', 'Email address'];

/* RolloverSummary */
export function RolloverSummary({ members }: Readonly<RolloverSummaryProps>) {
  return (
    <S.RolloverSummary>
      <Table>
        <thead>
          <TableRow>
            {TABLE_HEADERS.map((header, index) => (
              <TableHeader key={`rollover-summary-header-${index}`}>
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <TableRow key={`rollover-summary-member-${index}`}>
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.role}</TableCell>
              <TableCell>{member.parent}</TableCell>
              <TableCell>{member.emailAddress}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </S.RolloverSummary>
  );
}
/* */
