import { useCallback } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetStaffTeamsQuery } from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import { getUserId } from 'redux/userSlice';
import { useActivityForm } from '../ActivityContext';
import { isGameActivity } from '../ActivityForm';
import { isTeamTBD } from '../utils/ActivityForm.utils';
import * as S from './ActivityFormConfirmation.styles';

type ActivityFormConfirmationProps = {
  originRoute: string;
};
export function ActivityFormConfirmation({
  originRoute,
}: ActivityFormConfirmationProps) {
  const userId = useAppSelector(getUserId);
  const { data: teams = [] } = useGetStaffTeamsQuery(
    { userId },
    { skip: !userId }
  );

  const { submittedForm } = useActivityForm();
  const {
    activity,
    errorMessage,
    numberOfDeletedActivities,
    submitType,
    willNotify,
  } = submittedForm;

  const formatActivity = useCallback(() => {
    if (submitType) {
      window.history.pushState({}, document.title, '#updated');
    }

    if (submitType === 'deleted') {
      const deleteMessage =
        numberOfDeletedActivities === 1
          ? 'Activity was deleted successfully.'
          : `${numberOfDeletedActivities} activities were deleted successfully.`;
      return deleteMessage;
    } else if (activity?.length && !errorMessage) {
      const type = isGameActivity(activity[0].type)
        ? 'game'
        : activity[0].type.split('_')[1];

      //recurring activitites
      const recurring = activity.every(
        ({ origin, seqToken, state }) =>
          origin === 'generated' && !!seqToken && state !== 'canceled'
      );
      const activityCount = activity.length;
      const activityCountType = `${activityCount} ${
        recurring ? 'recurring' : ''
      } ${activity.length === 1 ? `${type} has` : `${type}s have`}`;

      const homeTeam =
        activity[0].teams.find(({ homeAway }) => homeAway === 'home') ??
        activity[0].teams[0];
      let homeTeamName = '';

      if (isTeamTBD(homeTeam.teamId)) {
        homeTeamName = 'TBD';
      } else {
        homeTeamName =
          teams.find(({ id }) => id === homeTeam?.teamId)?.name ?? '';
      }

      const awayTeam = activity[0].teams.find(
        ({ homeAway }) => homeAway === 'away'
      );

      let awayTeamName = '';
      if (awayTeam && isTeamTBD(awayTeam.teamId)) {
        awayTeamName = 'TBD';
      } else {
        awayTeamName =
          teams.find(({ id }) => id === awayTeam?.teamId)?.name ?? '';
      }

      return `${activityCountType} been been ${submitType} for ${homeTeamName}${
        awayTeamName ? ` vs ${awayTeamName}.` : '.'
      } ${willNotify ? `Notifications will be sent to participants. View schedule to see new activity details.` : 'View schedule to see new activity details.'}`;
    } else {
      return `${errorMessage}. Please refresh this page and try again.`;
    }
  }, [
    activity,
    errorMessage,
    numberOfDeletedActivities,
    submitType,
    teams,
    willNotify,
  ]);

  const handleOnViewSchedule = () => {
    const { sld, subdomain, tld } = getLAHostnameParts();
    window.location.href = `https://${subdomain}.${sld}.${tld}/${originRoute}`;
  };

  return (
    <>
      <S.ConfirmationMessage $error={!!errorMessage}>
        <Typography variant="ui" size="xl">
          {formatActivity()}
        </Typography>
      </S.ConfirmationMessage>
      <Button variant="primary" size="large" onClick={handleOnViewSchedule}>
        View Schedule
      </Button>
    </>
  );
}
