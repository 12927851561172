"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFixedAmountPriceAdjustment = exports.isPercentagePriceAdjustment = void 0;
var isPercentagePriceAdjustment = function (priceAdjustment) {
    var percentage = priceAdjustment === null || priceAdjustment === void 0 ? void 0 : priceAdjustment.percentage;
    return percentage !== undefined && percentage !== null;
};
exports.isPercentagePriceAdjustment = isPercentagePriceAdjustment;
var isFixedAmountPriceAdjustment = function (priceAdjustment) {
    var fixedAmount = priceAdjustment === null || priceAdjustment === void 0 ? void 0 : priceAdjustment.fixedAmount;
    return fixedAmount !== undefined && fixedAmount !== null;
};
exports.isFixedAmountPriceAdjustment = isFixedAmountPriceAdjustment;
