import { convertToSnakeCase } from 'lib/utils/stringUtils';

/**
 * Formats data for the new Select component
 * Select requires data to be formatted with 'label' & 'value' fields
 * Also sorts data to display alphabetically
 */
export function formatDataForSelectOption(
  data: any[],
  labelKey: string,
  valueKey?: string
) {
  return data
    .map((obj) => {
      const value = valueKey
        ? obj[valueKey].toString()
        : convertToSnakeCase(obj[labelKey]);
      return { label: obj[labelKey], value: value };
    })
    .sort(
      (
        a: { label: string; value: string },
        b: { label: string; value: string }
      ) => {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }
    );
}
