import styled from 'styled-components';
import { generateInputBaseDynamicStyles, generateInputPlaceholderDynamicStyles } from 'theming/tokens/components/input';
export const TextArea = styled.textarea.withConfig({
  componentId: "sc-1egrv4j-0"
})(({
  theme,
  $size,
  $state
}) => {
  const isInteractive = !['disabled', 'readonly'].includes($state);
  const styles = generateInputBaseDynamicStyles(theme, $state);
  const placeHolderStyles = generateInputPlaceholderDynamicStyles(theme, $state);
  return {
    ...theme.typography.ui.medium,
    boxSizing: 'border-box',
    color: styles.color,
    fontSize: theme.typography.ui[$size].fontSize,
    backgroundColor: styles.backgroundColor,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: styles.borderColor,
    padding: '4px 8px',
    cursor: 'pointer',
    maxWidth: '100%',
    maxHeight: '100%',
    resize: 'both',
    pointerEvents: isInteractive ? 'auto' : 'none',
    margin: '4px 0 0',
    '&:focus-within': {
      outline: '2px solid',
      outlineOffset: '2px',
      outlineColor: styles.borderColor
    },
    '&::placeholder': {
      fontSize: theme.typography.ui[$size].fontSize,
      color: placeHolderStyles.color,
      fontStyle: 'italic'
    }
  };
});