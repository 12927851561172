"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectOptions = void 0;
var getSelectOptions = function (options) {
    return Object.entries(options).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({ label: value, value: key });
    });
};
exports.getSelectOptions = getSelectOptions;
