import { Content as TooltipContent } from '@radix-ui/react-tooltip';
import styled from 'styled-components/macro';

// Currently Radix's select is diverging from the other inputs by an upper margin of 4px we need this container until DS patch this
export const SelectTemporaryPositionContainer = styled.div({
  marginTop: '-4px',
});

export const CreateTeamForm = styled.form`
  width: 100%;
`;

export const HorizontalInputGroup = styled.div<{ $isMobile: boolean }>(
  ({ $isMobile }) => {
    return {
      display: 'flex',
      flexDirection: $isMobile ? 'column' : 'row',
      gap: '8px',
      '& > div': {
        flex: 1,
      },
    };
  }
);

export const Title = styled.span`
  display: flex;
  align-items: center;
  margin: 4px 0 0 0;

  svg {
    fill: var(--secondary);
    margin: 0 0 0 8px;
  }
`;

export const TeamRepInfo = styled(TooltipContent)`
  background: var(--blue-grey-900);
  z-index: var(--modal-z);
  max-width: 184px;
  padding: 12px 8px;
  color: var(--white);
  font: var(--ui-small-medium);
  text-align: center;
  border-radius: 2px;

  .TooltipArrow {
    fill: var(--blue-grey-900);
  }
`;
