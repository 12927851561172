import * as S from './HamburgerMenuButton.styles';

export type HamburgerMenuButtonProps = {
  clickHandler: () => void;
};

/* HamburgerMenuButton */
export default function HamburgerMenuButton({
  clickHandler,
}: HamburgerMenuButtonProps) {
  return (
    <S.HamburgerMenuButton aria-label="toggle menu" onClick={clickHandler}>
      <S.HamburgerMenuIcon />
    </S.HamburgerMenuButton>
  );
}
/* */
