import { RolloverMember } from '../utils/reducer';

export const mockRolloverMembers: RolloverMember[] = [
  {
    userId: 1,
    name: 'John Locke',
    role: 'Club Director',
    roleId: 1,
    emailAddress: 'johnlocke@theisland.com',
  },
  {
    userId: 2,
    name: 'Walter Lloyd',
    role: 'Player',
    parent: 'Michael Dawson',
    emailAddress: 'mdawson@gmail.com',
  },
  {
    userId: 3,
    name: 'Michael Dawson',
    role: 'Coach',
    roleId: 2,
    emailAddress: 'mdawson@gmail.com',
  },
  {
    userId: 4,
    name: 'Jin-Soo Kwon',
    role: 'Team Rep',
    roleId: 3,
  },
];
