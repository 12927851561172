import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useEffect,
  useState,
  useRef,
} from 'react';
import validator from 'validator';
import { Checkbox, TextInput } from '@la/ds-ui-components';
import { useAppSelector } from 'redux/store';
import { getUserName } from 'redux/userSlice';
import * as S from './UseAccountModal.styles';

export type UseAccountFormData = {
  updateLoadingOverlayVisibility: (isVisible: boolean) => void;
};

//TODO connect Plaid to get all valid data about the bank detail in other ticket
export type AccountDetail = {
  account_type: string;
  bank_name: string;
  last4: string;
};

const accountDetailData: AccountDetail = {
  account_type: 'Checking',
  bank_name: 'Chase',
  last4: '1234',
};

export const useAccountFormErrors = {
  INVALID_ACCOUNT_HOLDER_NAME: 'Please enter the account holder name',
};

function getIsFieldEmpty(field: HTMLInputElement | null | undefined) {
  return (
    !!field &&
    validator.isEmpty(field.value, {
      ignore_whitespace: true,
    })
  );
}

/*UseBankForm*/
export default function UseBankForm({
  updateLoadingOverlayVisibility,
}: UseAccountFormData) {
  const userName = useAppSelector(getUserName);
  const accountHolderNameField = useRef<HTMLInputElement>(null);
  const accountNumberField = useRef<HTMLInputElement>(null);
  const accountTypeField = useRef<HTMLInputElement>(null);
  const bankNameField = useRef<HTMLInputElement>(null);
  const storeBankCheckbox = useRef<HTMLButtonElement>(null);
  const [hasAccountHolderNameFieldError, setHasAccountHolderNameFieldError] =
    useState<boolean>(false);

  useEffect(() => {
    if (userName && !!accountHolderNameField.current) {
      accountHolderNameField.current.value = userName;
    }
  }, [userName]);

  useEffect(() => {
    if (
      accountDetailData &&
      !!accountNumberField.current &&
      !!accountTypeField.current &&
      !!bankNameField.current
    ) {
      accountNumberField.current.value = '****' + accountDetailData['last4'];
      accountTypeField.current.value = accountDetailData['account_type'];
      bankNameField.current.value = accountDetailData['bank_name'];
    }
  }, []);

  function validateAccountHolderNameField(
    evt: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) {
    setHasAccountHolderNameFieldError(getIsFieldEmpty(evt.target));
  }

  function onUseAccountFormSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    const isAccountHolderNameFieldEmpty = getIsFieldEmpty(
      accountHolderNameField.current
    );

    if (!isAccountHolderNameFieldEmpty && accountDetailData) {
      //TODO should update the callback function to store bank detail
      updateLoadingOverlayVisibility(true);
    } else {
      setHasAccountHolderNameFieldError(true);
    }
  }

  return (
    <form
      id="use-account-form"
      aria-label="Account details"
      noValidate={true}
      onSubmit={onUseAccountFormSubmit}
    >
      <S.FormSectionLabel>Account details</S.FormSectionLabel>
      <S.FormFields>
        <TextInput
          errorMessage={useAccountFormErrors.INVALID_ACCOUNT_HOLDER_NAME}
          hasError={hasAccountHolderNameFieldError}
          id="account-holder-name"
          label="Account holder name"
          onBlur={validateAccountHolderNameField}
          onChange={validateAccountHolderNameField}
          required={true}
          ref={accountHolderNameField}
        />
        <TextInput
          disabled={true}
          id="bank-name"
          label="Bank name"
          required={true}
          ref={bankNameField}
        />
        <TextInput
          disabled={true}
          id="account-number"
          label="Account number"
          required={true}
          ref={accountNumberField}
        />
        <TextInput
          disabled={true}
          id="account-type"
          label="Account type"
          required={true}
          ref={accountTypeField}
        />
      </S.FormFields>
      <S.FormSectionLabel>Account preferences</S.FormSectionLabel>
      <S.CheckboxContainer>
        <Checkbox
          ariaLabel="store-bank-detail"
          id="store-bank-detail"
          label="Store bank account"
          ref={storeBankCheckbox}
        />
      </S.CheckboxContainer>

      <S.Note>Note: This will replace a previously stored bank account.</S.Note>
    </form>
  );
}
/* */
