import styled from 'styled-components/macro';
import { Button, IconButton, Typography } from '@la/ds-ui-components';

export const DivisionCardTeamSelection = styled.div`
  margin-top: 4px;
`;

export const DivisionCardTeamSelectionTopRow = styled.div`
  display: flex;
  align-items: flex-end;
  & > :first-child {
    flex: 1;
    width: 91%;
  }
`;

export const DivisionCardTeamSelectionAlertMessage = styled.p`
  margin: 4px 0;
  color: var(--red);
  font: var(--ui-medium-regular);
`;

export const DivisionCardTeamSelectionErrorMessage = styled.p`
  margin: 4px;
  color: var(--red);
  font: var(--ui-small-regular);
`;

export const DivisionCardTeams = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivisionSpotsLeftMessage = styled(Typography)`
  margin: 0 auto;
  padding: 4px 16px;
`;

export const DivisionCardAddTeamSelectButton = styled(Button)`
  margin: 0 auto;

  & svg {
    fill: #000000;
  }
`;

export const DivisionCardTeamSelectionButton = styled(IconButton)`
  // Margin needs to match select input so that they align correctly
  margin: 4px 0;

  padding: 12px;
  height: 42px;
`;

export const DiscardTeamButton = styled(DivisionCardTeamSelectionButton)`
  & svg {
    fill: none;
    stroke: var(--secondary-500);
  }

  &:hover {
    & svg {
      fill: none;
      stroke: var(--secondary-600);
    }
  }

  &:active {
    & svg {
      fill: none;
      stroke: var(--secondary-800);
    }
  }
`;
