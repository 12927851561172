import { http, HttpResponse } from 'msw';
import { mockUserData } from 'domains/Shell/mockValues_Shell';

export const headerHandlers = [
  // Main Navigation
  http.get('*/api/sites/*/shell/navigation', () => {
    return HttpResponse.json({}, { status: 200 });
  }),
  // User Data
  http.get('*/api/sites/*/accountInfo', () => {
    return HttpResponse.json(mockUserData, { status: 200 });
  }),
];
