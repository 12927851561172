"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkArray = void 0;
/**
 * Returns a subset of length `n` from a larger array.
 * @param array The array to be splitted
 * @param chunkSize The maximum size each inner array should have
 * @returns A 1xN matrix where n <= chunkSize
 */
function chunkArray(array, chunkSize) {
    var chunkedArray = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}
exports.chunkArray = chunkArray;
