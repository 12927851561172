import { Team } from '@la/types';
import * as S from './TeamDetails.styles';

export type TeamDetailsProps = {
  /**
   * Team whose following details should be rendered:
   * - Date creation relative to today
   * - Site
   * - Grouped program name (if applicable)
   * - Program name (if applicable)
   */
  team: Team;
};

export function TeamDetails({ team }: Readonly<TeamDetailsProps>) {
  return (
    <S.TeamDetails size="small" variant="ui">
      {/* TODO: Remove hardcoded values once we get the details back from the teams endpoint */}
      Created today / Sportsvite and Social Club DC /{' '}
      {team.groupedProgramName ? `${team.groupedProgramName} / ` : ''}
      {team.programName}
    </S.TeamDetails>
  );
}
