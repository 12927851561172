import { useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalSecondaryActionButton,
} from '@la/ds-ui-components';
import { Team } from 'redux/services/types/team';
import { Tournament } from 'redux/services/types/tournament';
import {
  DivisionCardStateWithId,
  DivisionCardsState,
} from '../../Wizard.types';
import { hasSubmissionErrors } from '../../WizardState';
import { WizardSubtotal } from './WizardSubtotal/WizardSubtotal';
import { WizardSummary } from './WizardSummary/WizardSummary';
import * as S from './SummaryAndSubmit.styles';

export const VIEW_SUMMARY_CARDS_BUTTON_TEXT = 'View added divisions';
export const HIDE_SUMMARY_CARDS_BUTTON_TEXT = 'Hide details';
export const SUBTOTAL_LABEL = 'Subtotal:';
export const DIVISIONS_SUMMARY_MODAL_TITLE = 'Added divisions';
export const SUBMIT_IN_MODAL_BUTTON_TEST_ID =
  'add-to-cart-from-summary-modal-button';

export type SummaryAndSubmitProps = {
  divisionCardsState: DivisionCardsState;
  onSubmissionErrors: () => void;
  onSubmit: () => void;
  submitButtonText: string;
  teams: Team[];
  tournament: Tournament;
};

export function SummaryAndSubmitLargerScreens(props: SummaryAndSubmitProps) {
  const { divisionCardsState, onSubmissionErrors, submitButtonText, onSubmit } =
    props;

  const handleSubmit = () => {
    if (hasSubmissionErrors(divisionCardsState)) {
      onSubmissionErrors();
    } else {
      onSubmit();
    }
  };

  const divisionsForSummarySection =
    getDivisionsToShowSummaryForSmallerScreens(divisionCardsState);

  return (
    <S.SummaryAndSubmit>
      <S.SummaryPanel>
        <WizardSummary {...props} />
      </S.SummaryPanel>
      {divisionsForSummarySection.length > 0 &&
      divisionsForSummarySection.some(
        (division) => division.savedTeams && division.savedTeams.length > 0
      ) ? (
        <div>
          <S.SubmitButton onClick={handleSubmit}>
            {submitButtonText}
          </S.SubmitButton>
        </div>
      ) : null}
    </S.SummaryAndSubmit>
  );
}

export function SummaryAndSubmitSmallerScreens(props: SummaryAndSubmitProps) {
  const {
    divisionCardsState,
    onSubmissionErrors,
    submitButtonText,
    tournament,
    onSubmit,
  } = props;

  const [isShowingDivisionSummaries, setIsShowingDivisionSummaries] =
    useState(false);
  const handleShowAddedDivisionsButtonClick = () => {
    setIsShowingDivisionSummaries(true);
  };
  const handleOpenChange = (open: boolean) => {
    setIsShowingDivisionSummaries(open);
  };

  const divisionsForSummarySection = getDivisionsToShowSummaryForSmallerScreens(
    props.divisionCardsState
  );

  const handleSubmit = () => {
    if (hasSubmissionErrors(divisionCardsState)) {
      if (isShowingDivisionSummaries) {
        setIsShowingDivisionSummaries(false);
        // Delay needed to allow the modal to close first. Otherwise the
        // scrolling behavior fails.
        setTimeout(onSubmissionErrors, 1);
      } else {
        onSubmissionErrors();
      }
    } else {
      onSubmit();
    }
  };

  const viewAddedDivisionsButtonRef = useRef<HTMLButtonElement>(null);

  const summariesModal = (
    <Modal
      open={isShowingDivisionSummaries}
      onOpenChange={handleOpenChange}
      primaryAction={
        <ModalPrimaryActionButton
          onClick={handleSubmit}
          data-testid={SUBMIT_IN_MODAL_BUTTON_TEST_ID}
        >
          {submitButtonText}
        </ModalPrimaryActionButton>
      }
      secondaryAction={
        <ModalClose>
          <ModalSecondaryActionButton>
            {HIDE_SUMMARY_CARDS_BUTTON_TEXT}
          </ModalSecondaryActionButton>
        </ModalClose>
      }
      title={DIVISIONS_SUMMARY_MODAL_TITLE}
      trigger={
        <Button
          onClick={handleShowAddedDivisionsButtonClick}
          variant="outline"
          ref={viewAddedDivisionsButtonRef}
          width="100%"
        >
          {VIEW_SUMMARY_CARDS_BUTTON_TEXT}
        </Button>
      }
    >
      <WizardSummary {...props} />
    </Modal>
  );

  // Action buttons are only shown when at least one division has been saved
  if (
    divisionsForSummarySection.length > 0 &&
    divisionsForSummarySection.some(
      (division) => division.savedTeams && division.savedTeams.length > 0
    )
  ) {
    return (
      <S.ActionButtons>
        <WizardSubtotal
          divisionCardsState={divisionCardsState}
          tournament={tournament}
        />
        {summariesModal}
        <div>
          <Button onClick={handleSubmit} width="100%">
            {submitButtonText}
          </Button>
        </div>
      </S.ActionButtons>
    );
  }

  return null;
}

function getDivisionsToShowSummaryForSmallerScreens(
  divisionCardsState: DivisionCardsState
): DivisionCardStateWithId[] {
  return getDivisionCardsStateArray(divisionCardsState).filter(
    (divisionCardState) =>
      divisionCardState.value === 'saved' ||
      divisionCardState.value === 'saved-and-editing'
  );
}

export function getDivisionCardsStateArray(
  divisionCardsState: DivisionCardsState
): DivisionCardStateWithId[] {
  return Object.entries(divisionCardsState).map(([id, divisionCardState]) => ({
    id: id,
    ...divisionCardState,
  }));
}
