"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainContentHeaderBackButton = exports.MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var mixins_1 = require("../../../lib/media-queries/mixins");
exports.MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH = 28;
exports.MainContentHeaderBackButton = (0, styled_components_1.default)(ds_ui_components_1.IconButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & svg {\n    stroke: ", ";\n  }\n\n  &:focus-within {\n    outline-color: ", ";\n  }\n\n  position: absolute;\n  left: 12px;\n  width: ", "px;\n  height: ", "px;\n  padding: 0;\n\n  ", "\n"], ["\n  & svg {\n    stroke: ", ";\n  }\n\n  &:focus-within {\n    outline-color: ", ";\n  }\n\n  position: absolute;\n  left: 12px;\n  width: ", "px;\n  height: ", "px;\n  padding: 0;\n\n  ", "\n"])), function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme.semantic) === null || _b === void 0 ? void 0 : _b.primary.reactiveTextColor;
}, function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme.semantic) === null || _b === void 0 ? void 0 : _b.primary.reactiveTextColor;
}, exports.MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH, exports.MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH, mixins_1.media.TabletLandscapeUp(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "]))));
var templateObject_1, templateObject_2;
