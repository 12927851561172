import styled from 'styled-components/macro';
import { Button, ThemedComponent } from '@la/ds-ui-components';

export const TextContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { spacing } = theme;
    return {
      marginBottom: spacing.large,
      p: {
        display: 'inline',
      },
    };
  }
);

export const Flex = styled.div`
  display: flex;
  gap: 12px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 42px;
`;

export const StyledTextButton = styled(Button)`
  padding-left: 0;
`;
