import { ReactNode } from 'react';
import { ExternalLinkIcon, Link } from '@la/ds-ui-components';
import { CardBody, CardHeader, CardTitle } from 'components/Card/Card';
import { formatAsUSD, genderNameMap } from 'lib/utils/utilities';
import { Gender } from 'redux/services/types/tournament';
import * as S from './DetailsCard.styles';

export const LODGING_LABEL = 'Lodging';

type LabelAndValue = {
  /**
   * A unique ID used for the `key` and for testing purposes
   */
  id: string;
  /**
   * The text for the label
   */
  label: string;
  /**
   * The text for the value
   */
  value: string | string[] | ReactNode;
};

export type DetailsCardProps = {
  /**
   * Names of all divisions in tournment
   */
  divisionNames?: string[];
  /**
   * Required value set during program creation, will be displayed capitalized
   */
  gender?: Gender;
  /**
   * Hotel link set at the tournament level. Renders a link to a new tab.
   */
  hotelLink?: string;
  /**
   * The lowest and highest prices for divisions in this tournament
   */
  cost?: {
    min: string;
    max: string;
  };
  /**
   * Custom label set for cost. Default is "Cost".
   */
  costLabel?: string;
  /**
   * This can be customized to have a label other than "Season", hence the label value
   */
  season?: {
    label: string;
    value: string;
  };
  /**
   * Required value set at the parent program level, will be displayed as is
   */
  sport: string;
};

export function DetailsCard({
  divisionNames,
  gender,
  hotelLink,
  cost,
  costLabel,
  season,
  sport,
}: DetailsCardProps) {
  const details: LabelAndValue[] = [];

  if (divisionNames && divisionNames.length > 0) {
    details.push({
      id: 'tournament-details-division-names',
      label: 'Divisions',
      value: divisionNames,
    });
  }
  if (season) {
    details.push({
      id: 'tournament-details-season',
      label: season.label,
      value: season.value,
    });
  }
  details.push({
    id: 'tournament-details-sport',
    label: 'Sport',
    value: sport,
  });
  if (gender) {
    details.push({
      id: 'tournament-details-gender',
      label: 'Gender',
      value: genderNameMap[gender],
    });
  }
  if (cost) {
    details.push({
      id: 'tournament-details-cost',
      label: costLabel || 'Cost',
      value: getCost(cost),
    });
  }
  if (hotelLink) {
    details.push({
      id: 'tournament-details-hotel-link',
      label: LODGING_LABEL,
      value: (
        <S.ValueLink>
          <Link href={hotelLink} isBold size="large" target="_blank">
            View{' '}
            <span>
              accommodations
              <ExternalLinkIcon variant="bold" />
            </span>
          </Link>
        </S.ValueLink>
      ),
    });
  }

  return (
    <S.DetailsCard data-testid="details-card">
      <CardHeader>
        <CardTitle>Details</CardTitle>
      </CardHeader>
      <CardBody>
        <LabeledValues list={details} />
      </CardBody>
    </S.DetailsCard>
  );
}

/**
 * Returns a string that displays the cost of the tournament,
 * a single dollar amount if the minimum and maximum are the same,
 * otherwise a range of min to max.
 */
function getCost(cost: { min: string; max: string }): string {
  const min = Number(cost.min);
  const max = Number(cost.max);

  if (min === max) {
    return formatAsUSD(min, {
      hideZeroCents: true,
    });
  }
  return `${formatAsUSD(min, {
    hideZeroCents: true,
  })}-${formatAsUSD(max, {
    hideZeroCents: true,
  })}`;
}

function LabeledValues({ list }: { list: LabelAndValue[] }) {
  const getValue = (value: string | string[] | ReactNode) => {
    if (Array.isArray(value)) {
      return (
        <S.ValueList>
          {value.map((v, index) => (
            <S.Value as="span" key={index}>
              {v}
            </S.Value>
          ))}
        </S.ValueList>
      );
    }

    return <S.Value>{value}</S.Value>;
  };

  return (
    <S.LabeledValues>
      {list.map((obj) => (
        <S.LabeledValue data-testid={obj.id} key={obj.id}>
          <S.Label>{obj.label}</S.Label>
          {getValue(obj.value)}
        </S.LabeledValue>
      ))}
    </S.LabeledValues>
  );
}
