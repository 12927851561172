import styled from 'styled-components/macro';

export const EditProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NumericInput = styled.div`
  input[type='number'] {
    text-align: left;
  }
`;

export const NumericInputHelperText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 4px;

  > p {
    color: var(--blue-grey-600);
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
