import {
  Modal,
  ModalPrimaryActionButton,
  Typography,
} from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetDifferentTeamsQuery } from 'redux/services/rosterManagementApi';
import * as S from './RosterManagement.styles';

export type Team = {
  teamName: string;
  teamId?: string;
  programName: string;
  rosterUrl?: string;
};

function removeCurrentTeam(teamsData: Team[], currentTeamId?: string): Team[] {
  const currentTeamIndex = teamsData.findIndex(
    (team) => team.teamId === currentTeamId
  );
  teamsData.splice(currentTeamIndex, 1);
  return teamsData;
}

function filterDuplicateTeams(teams: Team[]): Team[] {
  return teams.filter((team, index, currentTeams) => {
    // Returns true when the index of team is equal to the index of
    // a team in the the currentTeams array (should only occur once).
    return (
      currentTeams.findIndex(
        (currentTeam) => currentTeam.teamId === team.teamId
      ) === index
    );
  });
}

/* View Different Teams Modal */
export function ViewDifferentTeamModal({
  closeModal,
  programName,
  teamId,
  teamName,
}: {
  closeModal: () => void;
  programName: string;
  teamId?: string;
  teamName: string;
}) {
  const siteSubdomain = getLAHostnameParts().subdomain;
  const skip = !siteSubdomain;
  const { data: teamsData /* error, isError, isLoading */ } =
    useGetDifferentTeamsQuery({ siteSubdomain }, { skip });

  const differentTeamsData = teamsData
    ? removeCurrentTeam(filterDuplicateTeams([...teamsData]), teamId)
    : null;

  return (
    <Modal
      onOpenChange={closeModal}
      open={true}
      primaryAction={
        <ModalPrimaryActionButton onClick={closeModal}>
          Go back
        </ModalPrimaryActionButton>
      }
      size="medium"
      title="View a different team"
    >
      <S.ViewDifferentTeamModalSubhead variant="ui" size="large" weight="bold">
        Select a team to view their roster
      </S.ViewDifferentTeamModalSubhead>
      <S.CurrentlySelectedBadge>Currently selected</S.CurrentlySelectedBadge>
      <TeamInfoRow teamName={teamName} programName={programName} />
      {differentTeamsData ? (
        <DifferentTeamsListing teamsData={differentTeamsData} />
      ) : null}
    </Modal>
  );
}
/*  */

/* Team Info Row */
export function TeamInfoRow({
  teamName,
  teamId,
  programName,
  rosterUrl,
}: Team) {
  return (
    <S.TeamInfoRow>
      <Typography variant="ui" size="medium" weight="regular">
        {rosterUrl ? <a href={rosterUrl}>{teamName}</a> : teamName}
      </Typography>
      <Typography variant="ui" size="small" weight="regular">
        {programName}
      </Typography>
    </S.TeamInfoRow>
  );
}
/*  */

export function DifferentTeamsListing({ teamsData }: { teamsData: Team[] }) {
  return (
    <S.DifferentTeams>
      {teamsData.map((team, idx) => (
        <TeamInfoRow
          teamId={team.teamId}
          programName={team.programName}
          rosterUrl={team.rosterUrl}
          teamName={team.teamName}
          key={`team-${idx}`}
        />
      ))}
    </S.DifferentTeams>
  );
}
