import styled from 'styled-components/macro';
import { ReactComponent as LALeagueapps } from 'assets/LA_lockups/la_logo_leagueapps_green.svg';
import { ReactComponent as SecureSVGIcon } from 'assets/icons/secure_icon.svg';

export const SecureCheckoutBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--ui-small-medium);
  color: var(--blue-grey-900);
`;

export const SecureIcon = styled(SecureSVGIcon)`
  margin-right: 7px;
`;

export const LALeagueappsLogo = styled(LALeagueapps)`
  margin-left: 4px;
`;
