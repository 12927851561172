import styled from 'styled-components';
import { generateInputBaseDynamicStyles, generateInputPlaceholderDynamicStyles } from 'theming/tokens/components/input';
export const inputVariantHeights = {
  large: '40px',
  medium: '32px'
};
export const InputRoot = styled.input.withConfig({
  componentId: "sc-p5w3b5-0"
})(({
  theme,
  $state,
  $type,
  $size,
  $forceLeftAlignment
}) => {
  const isInteractive = !['disabled', 'readonly'].includes($state);
  const styles = generateInputBaseDynamicStyles(theme, $state);
  const placeholderStyles = generateInputPlaceholderDynamicStyles(theme, $state);
  const textAlign = $type !== 'number' || $forceLeftAlignment ? 'left' : 'center';
  return {
    ...theme.typography.ui[$size],
    color: styles.color,
    backgroundColor: styles.backgroundColor,
    outline: 'none',
    border: 'none',
    padding: 0,
    minWidth: 0,
    flex: 1,
    cursor: 'text',
    pointerEvents: isInteractive ? 'auto' : 'none',
    textAlign,
    '&::placeholder': {
      fontSize: theme.typography.ui[$size].fontSize,
      color: placeholderStyles.color,
      fontStyle: 'italic'
    },
    '::-webkit-input-placeholder': {
      fontSize: theme.typography.ui[$size].fontSize,
      color: placeholderStyles.color,
      fontStyle: 'italic'
    },
    '::-moz-placeholder': {
      fontSize: theme.typography.ui[$size].fontSize,
      color: placeholderStyles.color,
      fontStyle: 'italic'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '-moz-appearance': 'textfield'
  };
});