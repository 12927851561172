"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTournamentRegistrantQuery = exports.useGetUserRegistrationsQuery = exports.useGetTournamentRegistrationQuery = exports.useLazyGetTournamentQuery = exports.useGetTournamentQuery = exports.tournamentApi = void 0;
var baseApi_1 = require("./baseApi");
exports.tournamentApi = baseApi_1.default.injectEndpoints({
    endpoints: function (builder) { return ({
        getTournament: builder.query({
            query: function (_a) {
                var siteDomain = _a.siteDomain, tournamentId = _a.tournamentId, siteId = _a.siteId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/tournaments/").concat(tournamentId),
                    method: 'GET',
                    params: {
                        siteId: siteId,
                    },
                };
            },
        }),
        getTournamentRegistration: builder.query({
            query: function (_a) {
                var siteDomain = _a.siteDomain, tournamentId = _a.tournamentId, payerId = _a.payerId, siteId = _a.siteId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/tournaments/").concat(tournamentId, "/registration"),
                    method: 'GET',
                    params: {
                        payerId: payerId,
                        siteId: siteId,
                    },
                };
            },
        }),
        getTournamentRegistrant: builder.query({
            query: function (_a) {
                var siteDomain = _a.siteDomain, tournamentId = _a.tournamentId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/tournaments/").concat(tournamentId, "/registrant"),
                    method: 'GET',
                };
            },
        }),
        getUserRegistrations: builder.query({
            query: function (_a) {
                var programId = _a.programId, siteDomain = _a.siteDomain, payerId = _a.payerId, siteId = _a.siteId;
                return {
                    url: "/api/member-portal/".concat(siteDomain, "/registration"),
                    method: 'GET',
                    params: {
                        programId: programId,
                        payerId: payerId,
                        siteId: siteId,
                    },
                };
            },
        }),
    }); },
});
exports.useGetTournamentQuery = exports.tournamentApi.useGetTournamentQuery, exports.useLazyGetTournamentQuery = exports.tournamentApi.useLazyGetTournamentQuery, exports.useGetTournamentRegistrationQuery = exports.tournamentApi.useGetTournamentRegistrationQuery, exports.useGetUserRegistrationsQuery = exports.tournamentApi.useGetUserRegistrationsQuery, exports.useGetTournamentRegistrantQuery = exports.tournamentApi.useGetTournamentRegistrantQuery;
