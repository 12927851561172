import styled from 'styled-components/macro';
import { Button } from '@la/ds-ui-components';

export const UserMenu = styled.div`
  position: absolute;
  top: 28px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  padding: 12px 0 16px;
  border: 2px solid var(--blue-grey-100);
  border-radius: 4px;
  background: var(--white);
`;

export const LoggedInMenu = styled.div`
  padding: 12px 0px;
`;

export const UserInfo = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

export const UserName = styled.div`
  font: var(--ui-large-bold);
  color: var(--blue-grey-900);
`;

export const AvatarImage = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 4px;
  border-radius: 24px;
`;

export const SignOutButton = styled(Button)`
  border-color: var(--primary);

  &:hover,
  &:focus {
    background: var(--blue-grey-50);
    color: var(--blue-grey-900);
  }

  &:active {
    background: var(--blue-grey-100);
  }
`;

export const SignOutLink = styled.a`
  width: calc(100% - 20px);
  padding: 8px 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  font: var(--ui-medium-bold);
  text-decoration: none;
  text-align: center;
  color: var(--secondary-900);

  &:hover,
  &:focus {
    background: var(--blue-grey-50);
    color: var(--blue-grey-900);
    border-color: var(--button-outline-border-color-hover);
  }

  &:active {
    background: var(--blue-grey-100);
  }
`;
