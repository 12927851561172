export const getInputContainerWidth = width => {
  if (!width) {
    return 'auto';
  }
  if (!isNaN(+width)) {
    return `${width}px`;
  }
  return width;
};
export const determineComponentState = states => {
  if (states.disabled) {
    return 'disabled';
  }
  if (states.readOnly) {
    return 'readonly';
  }
  if (states.error) {
    return 'error';
  }
  if (states.isFocused) {
    return 'focus';
  }
  if (states.isHovered) {
    return 'hover';
  }
  return 'default';
};