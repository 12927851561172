import { useEffect } from 'react';
import { Button } from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import {
  ActivityFormWrapperScreens,
  useActivityForm,
} from '../ActivityContext';
import { deleteActivity as deleteActivityRequest } from '../apis/deleteActivity';
import { deleteRecurringActivities } from '../apis/deleteRecurringActivites';
import { Activity } from '../openapi-client/models';
import { REQUEST_DELAY } from '../utils/ActivityForm.utils';
import * as S from './DeleteActivity.styles';

export function DeleteActivity({
  deleteActivity,
  handleSetActivityFormTitle,
  handleSetCurrentScreen,
}: {
  deleteActivity?: Activity;
  handleSetActivityFormTitle: (arg: string) => void;
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
}) {
  const { activities, editRecurringActivityType, handleSetSubmittedForm } =
    useActivityForm();
  const { siteId } = useAppSelector(getSiteIdentityData);

  const handleDiscardChanges = () => {
    handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
  };

  const numberOfActivities =
    editRecurringActivityType === 'all' ? getNumberOfRecurringActivities() : 1;

  useEffect(() => {
    const title =
      numberOfActivities === 1 ? 'Delete Activity' : 'Delete Activities';
    handleSetActivityFormTitle(title);
  }, [handleSetActivityFormTitle, numberOfActivities]);

  function getNumberOfRecurringActivities() {
    if (activities.length === 0 || !deleteActivity?.seqToken) {
      return 0;
    }

    const recurringActivities = activities.filter((activity) => {
      return (
        activity.seqToken === deleteActivity.seqToken &&
        activity.id >= deleteActivity.id
      );
    });

    return recurringActivities.length;
  }

  const handleDeleteActivity = async () => {
    if (!deleteActivity) {
      return;
    }

    handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);
    await submitDeleteActivity()
      .then((data) => {
        setTimeout(() => {
          handleSetSubmittedForm({
            numberOfDeletedActivities: numberOfActivities,
            submitType: 'deleted',
          });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        }, REQUEST_DELAY);
      })
      .catch((e) => {
        const errorMessage = typeof e === 'string' ? e : e.message;
        handleSetSubmittedForm({ errorMessage });
        handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
      });
  };

  async function submitDeleteActivity() {
    if (!deleteActivity) {
      return;
    }

    if (editRecurringActivityType === 'all') {
      await deleteRecurringActivities({
        activityId: deleteActivity.id,
        siteId: Number(siteId),
        seqToken: deleteActivity?.seqToken,
      });
    } else {
      await deleteActivityRequest({
        activityId: deleteActivity.id,
        siteId: Number(siteId),
      });
    }
  }

  return (
    <>
      <S.ContentBody>
        Are you sure you want to delete{' '}
        {numberOfActivities === 1
          ? 'this activity'
          : `${numberOfActivities} activities`}
        ?
      </S.ContentBody>

      <S.FormButtons>
        <Button onClick={handleDiscardChanges} variant="text" size="large">
          Discard Changes
        </Button>
        <Button variant="primary" size="large" onClick={handleDeleteActivity}>
          {numberOfActivities === 1 ? 'Delete Activity' : 'Delete Activities'}
        </Button>
      </S.FormButtons>
    </>
  );
}
