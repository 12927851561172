export const mockUserData = {
  user: {
    id: 1,
    type: 'adult',
    firstName: 'Person',
    lastName: 'Ofinterest',
    email: 'person@email.com',
    profilePicture:
      'https://svite-league-apps-img-stg.s3.amazonaws.com/profile-0-s3.jpg',
  },
  site: {
    id: 1,
    name: 'Sportsvite Sport & Social Club DC',
  },
  orgAccount: {
    id: 1,
    name: 'Sportsvite Sport & Social Club',
  },
};
