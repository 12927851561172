"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectPayer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var Typeahead_1 = require("../../../components/Typeahead/Typeahead");
var S = require("./SelectPayer.styles");
var SelectPayer = function () {
    return ((0, jsx_runtime_1.jsx)(S.SelectPayerContainer, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Card, __assign({ width: "100%" }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.CardHeader, { children: "Primary staff info" }), (0, jsx_runtime_1.jsx)(ds_ui_components_1.CardBody, { children: (0, jsx_runtime_1.jsx)(Typeahead_1.Typeahead, {}) })] })) }));
};
exports.SelectPayer = SelectPayer;
