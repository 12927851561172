import { Button } from '@la/ds-ui-components';
import * as S from './DivisionCardFooter.styles';

type DivisionCardFooterProps = {
  onCancel: () => void;
  onSave: () => void;
  shouldUpdateFields?: boolean;
};

export function DivisionCardFooter({
  onCancel,
  onSave,
  shouldUpdateFields,
}: DivisionCardFooterProps) {
  return (
    <S.DivisionCardFooter>
      <S.DivisionCardCancelButton
        variant="text"
        size="medium"
        onClick={onCancel}
      >
        Cancel
      </S.DivisionCardCancelButton>
      <Button variant="primary" size="medium" onClick={onSave}>
        {shouldUpdateFields ? 'Update and save' : 'Save division'}
      </Button>
    </S.DivisionCardFooter>
  );
}
