import styled from 'styled-components/macro';

export const WizardSummaryCards = styled.div<{
  $isSubmissionErrorShowing?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow: auto;
`;
