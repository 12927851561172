"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMCStepTitles = void 0;
var getMCStepTitles = function (hasPaymentPlans) {
    if (hasPaymentPlans) {
        return {
            1: 'Add primary staff',
            2: 'Add teams to your program',
            3: 'Review your registrations and invoices',
        };
    }
    return {
        1: 'Add primary staff',
        2: 'Add teams to your program',
        3: 'Select payment options',
        4: 'Review your registrations and invoices',
    };
};
exports.getMCStepTitles = getMCStepTitles;
