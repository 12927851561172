import { ReactNode } from 'react';
import { X } from '@phosphor-icons/react';
import * as S from './Tag.styles';

export default function Tag({
  icon,
  label,
  closeHandler,
}: {
  icon?: ReactNode;
  label: string;
  closeHandler?: () => void;
}) {
  return (
    <S.Tag>
      {icon}
      <S.TagLabel>{label}</S.TagLabel>
      <S.CloseButton onClick={closeHandler}>
        <X width="12px" height="12px" />
      </S.CloseButton>
    </S.Tag>
  );
}
