import { http, HttpResponse } from 'msw';
import FakeNavData from 'domains/Shell/MainNavigation/FakeNavData.json';

export enum SiteEndpoint {
  GetSiteInfo = '*/api/sites/subdomains/*',
  GetSiteNavigation = '*/api/sites/*/shell/navigation',
}

export const siteHandlers = [
  // Get site info
  http.get(SiteEndpoint.GetSiteInfo, () =>
    HttpResponse.json(
      {
        logoHref: '',
        logoImageSrc: '',
        siteId: '1',
        siteName: 'svssc',
      },
      { status: 200 }
    )
  ),
  // Get site navigation
  http.get(SiteEndpoint.GetSiteNavigation, () =>
    HttpResponse.json({ mainNav: FakeNavData }, { status: 200 })
  ),
];
