import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import * as S from './RemoveFromCartModal.styles';

export type RemoveFromCartModalProps = {
  open: boolean;
  closeModal: () => void;
  loading: boolean;
  removeCard: () => void;
};

/* RemoveFromCartModal */
export default function RemoveFromCartModal({
  open,
  closeModal,
  loading,
  removeCard,
}: RemoveFromCartModalProps) {
  return (
    <Modal
      open={open}
      onOpenChange={closeModal}
      title="Remove item"
      hideClose={false}
      size="small"
      primaryAction={
        <ModalPrimaryActionButton onClick={removeCard} loading={loading}>
          Yes, remove this
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
    >
      <S.ModalContent>
        You are now removing this item from your cart, all registration progress
        will be lost.
        <S.ConfirmSentence>
          Are you sure you want to continue?
        </S.ConfirmSentence>
      </S.ModalContent>
    </Modal>
  );
}
/* */
