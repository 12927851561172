import styled from 'styled-components';
import { media } from 'lib/media-queries/mixins';

export const ParticipantsSection = styled.section`
  width: 100%;
  margin: 0 24px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

export const RegisterSectionContainer = styled.section`
  ${media.TabletPortraitDown`
    display: block;
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 12px 16px;
    border-top: 1px solid var(--primary-100);
    background-color: var(--white);
  `}
  display:none;
`;
