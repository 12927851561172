"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionCards = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ageGroup_1 = require("../CreateTeamForm/utils/ageGroup");
var DivisionCard_1 = require("../DivisionCard/DivisionCard");
var S = require("./DivisionCards.styles");
function DivisionCards(props) {
    var divisions = props.divisions, divisionCardsStateLookup = props.divisionCardsStateLookup, divisionToScrollToId = props.divisionToScrollToId, hideLocationDetails = props.hideLocationDetails, onAddTeamSelect = props.onAddTeamSelect, onCancelDivisionChanges = props.onCancelDivisionChanges, onClearDivision = props.onClearDivision, onDiscardTeamSelect = props.onDiscardTeamSelect, onEditDivision = props.onEditDivision, onSaveDivision = props.onSaveDivision, onSelectDivision = props.onSelectDivision, onSelectTeam = props.onSelectTeam, onScrollToDivision = props.onScrollToDivision, onTeamCreate = props.onTeamCreate, teams = props.teams, tournamentDates = props.tournamentDates, tournamentId = props.tournamentId, tournamentLocation = props.tournamentLocation, setHasDeleteTeamDivisionError = props.setHasDeleteTeamDivisionError, refetchTeams = props.refetchTeams;
    var divisionToScrollToRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (divisionToScrollToId) {
            if (divisionToScrollToRef.current) {
                divisionToScrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                onScrollToDivision();
            }
        }
    }, [divisionToScrollToId, onScrollToDivision]);
    // If at least one division's dates differs from the tournament as a whole,
    // then we show the division dates in all the division cards.
    var showDivisionDates = divisions.some(function (division) {
        return division.startDate !== tournamentDates.start ||
            division.endDate !== tournamentDates.end;
    });
    var showLocation = !hideLocationDetails &&
        divisions.some(function (division) { var _a; return ((_a = division.location) === null || _a === void 0 ? void 0 : _a.id) !== (tournamentLocation === null || tournamentLocation === void 0 ? void 0 : tournamentLocation.id); });
    var availableDivisions = divisions.filter(function (division) {
        var cardState = divisionCardsStateLookup[division.id];
        return (cardState.value !== 'unavailable' ||
            (cardState.value === 'unavailable' &&
                cardState.reasonUnavailable === 'at-capacity'));
    });
    var upcomingDivisions = divisions.filter(function (division) {
        var cardState = divisionCardsStateLookup[division.id];
        return (cardState.value === 'unavailable' &&
            cardState.reasonUnavailable === 'before-registration-period-opens');
    });
    var pastDivisions = divisions.filter(function (division) {
        var cardState = divisionCardsStateLookup[division.id];
        return (cardState.value === 'unavailable' &&
            cardState.reasonUnavailable === 'after-registration-period-ends');
    });
    return ((0, jsx_runtime_1.jsxs)(S.DivisionCards, { children: [availableDivisions.map(function (division) {
                var _a;
                var divisionCardState = divisionCardsStateLookup[division.id];
                var addTeamSelect = function () {
                    onAddTeamSelect(division.id);
                };
                var cancel = function () {
                    onCancelDivisionChanges(division.id);
                };
                var clear = function () {
                    onClearDivision(division.id);
                };
                var discardTeamSelect = function (selectIndex) {
                    onDiscardTeamSelect(division.id, selectIndex);
                };
                var editDivision = function () {
                    onEditDivision(division.id);
                };
                var save = function () {
                    onSaveDivision(division.id);
                };
                var selectDivision = function () {
                    onSelectDivision(division.id);
                };
                var selectTeam = function (selectIndex, teamId) {
                    onSelectTeam(division.id, selectIndex, teamId);
                };
                var discardTeamSelects = function (div, teamId) {
                    var cardState = divisionCardsStateLookup[div.id];
                    var cardStateValue = cardState.value;
                    if (cardStateValue === 'not-saved' ||
                        cardStateValue === 'saved-and-editing') {
                        // When a division is 'not-saved' or 'saved-and-editing', we
                        // CLEAR the selection
                        var teamSelections = cardState.teamSelections;
                        var indexOfDeletedTeam = teamSelections.findIndex(function (teamSelection) { return teamSelection.teamId === teamId; });
                        if (indexOfDeletedTeam > -1) {
                            onSelectTeam(div.id, indexOfDeletedTeam, '');
                        }
                    }
                    else if (cardStateValue === 'saved') {
                        // When a division is 'saved', we DISCARD that selection
                        var savedTeams = cardState.savedTeams;
                        var indexOfDeletedTeam = savedTeams.indexOf(teamId);
                        if (indexOfDeletedTeam > -1) {
                            onDiscardTeamSelect(div.id, indexOfDeletedTeam);
                        }
                    }
                };
                var discardDeletedTeamSelects = function (teamId) {
                    divisions.forEach(function (div) {
                        discardTeamSelects(div, teamId);
                    });
                };
                var maxDivisionAgeGroup = (_a = division.ageGroup) !== null && _a !== void 0 ? _a : 0;
                var availableTeams = maxDivisionAgeGroup === 0
                    ? teams
                    : teams.filter(function (team) {
                        // Age group should be between the max and max minus MIN_LOWER_AGE_GROUP (inclusive)
                        return team.ageGroup === 0 ||
                            (team.ageGroup <= maxDivisionAgeGroup &&
                                team.ageGroup >= maxDivisionAgeGroup - ageGroup_1.MIN_LOWER_AGE_GROUP);
                    });
                return ((0, jsx_runtime_1.jsx)(DivisionCard_1.DivisionCard, { availableTeams: availableTeams, cardState: divisionCardState, division: division, divisionCardsState: divisionCardsStateLookup, maxDivisionAgeGroup: maxDivisionAgeGroup, onAddTeamSelect: addTeamSelect, onCancel: cancel, onClear: clear, onDeleteTeam: discardDeletedTeamSelects, onDiscardTeamSelect: discardTeamSelect, onEditDivision: editDivision, onSave: save, onSelectDivision: selectDivision, onSelectTeam: selectTeam, onTeamCreate: onTeamCreate, refetchTeams: refetchTeams, ref: division.id === divisionToScrollToId
                        ? divisionToScrollToRef
                        : null, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, showDates: showDivisionDates, showLocation: showLocation, tournamentId: tournamentId }, division.id));
            }), upcomingDivisions.length > 0 ? ((0, jsx_runtime_1.jsx)(S.DivisionCardsSectionTitle, { children: "Upcoming registrations" })) : null, upcomingDivisions.map(function (division) {
                var divisionCardState = divisionCardsStateLookup[division.id];
                if (divisionCardState.value !== 'unavailable') {
                    throw new Error('Upcoming division has invalid state.');
                }
                return ((0, jsx_runtime_1.jsx)(DivisionCard_1.DivisionCard, { cardState: divisionCardState, division: division, ref: division.id === divisionToScrollToId
                        ? divisionToScrollToRef
                        : null, showDates: showDivisionDates, showLocation: showLocation }, division.id));
            }), pastDivisions.length > 0 ? ((0, jsx_runtime_1.jsx)(S.DivisionCardsSectionTitle, { children: "Closed Registrations" })) : null, pastDivisions.map(function (division) {
                var divisionCardState = divisionCardsStateLookup[division.id];
                if (divisionCardState.value !== 'unavailable') {
                    throw new Error('Past division has invalid state.');
                }
                return ((0, jsx_runtime_1.jsx)(DivisionCard_1.DivisionCard, { cardState: divisionCardState, division: division, ref: division.id === divisionToScrollToId
                        ? divisionToScrollToRef
                        : null, showDates: showDivisionDates, showLocation: showLocation }, division.id));
            })] }));
}
exports.DivisionCards = DivisionCards;
