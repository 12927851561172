import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Button,
  CaretLeftIcon,
  Loader,
  PlusCircleIcon,
  Popover,
  PopoverTrigger,
  SelectOption,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useCreateProgramLocationMutation,
  useGetLocationsQuery,
} from 'redux/services/activity';
import {
  GooglePlaceAddressComponents,
  GooglePlacesAddressTypes,
  GooglePlacesAutoCompleteSuggestions,
  useGetPlacesAutoCompleteQuery,
  useLazyGetPlacesInfoQuery,
} from 'redux/services/googleMaps';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { useAppSelector } from 'redux/store';
import { Address, Location } from '../openapi-client/models';
import * as S from './ExternalLocationSelect.styles';

type ExternalLocationSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  locations: SelectOption[];
  subProgramId?: string;
  value?: string;
};

type CreateExternalLocationProps = {
  onBack: (value?: string) => void;
  showCreateExternalLocation: boolean;
  subProgramId?: string;
};

type AutoCompleteAddressProps = {
  placeId: string;
  placeName: string;
  onSelectAddress: (placeId: string) => void;
  buttonWidth: number;
};

type AutoCompleteAddressDropdownProps = {
  formattedPredictions: PlacePrediction[];
  onSelectAddress: (placeId: string) => void;
  buttonWidth: number;
};

type PlacePrediction = {
  placeId: string;
  placeName: string;
};

export const ExternalLocationSelect = forwardRef<
  HTMLDivElement,
  ExternalLocationSelectProps
>(({ onChange, onClose, locations, subProgramId, value }, ref?) => {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { data: siteSettings } = useGetSiteSettingsQuery(Number(siteId), {
    skip: !siteId,
  });
  const allowCreateNewLocation = siteSettings?.appCreateLocation;

  const [showCreateExternalLocation, setShowCreateExternalLocation] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnSelect = (location?: string) => {
    onChange(location);
    onClose(location);
  };

  const handleOnBack = (locationId?: string) => {
    setShowCreateExternalLocation(false);

    if (locationId) {
      setSelectedValue(locationId);
      onChange(locationId);
      onClose(locationId);
    }
  };

  return (
    <div>
      {!showCreateExternalLocation ? (
        <>
          {!!allowCreateNewLocation && (
            <S.ExternalLocationOptionContainer
              role="option"
              aria-selected="false"
              onClick={() => setShowCreateExternalLocation(true)}
            >
              <S.ExternalLocationOption>
                <S.ExternalLocationOptionLink>
                  <PlusCircleIcon fill="#007A00" variant="regular" /> Add a new
                  location
                </S.ExternalLocationOptionLink>
              </S.ExternalLocationOption>
            </S.ExternalLocationOptionContainer>
          )}

          {locations.map((location) => {
            return (
              <S.ExternalLocationOptionContainer
                role="option"
                aria-disabled={false}
                aria-selected={selectedValue === location.value ? true : false}
                onClick={() => handleOnSelect(location.value?.toString())}
                key={location.value}
              >
                <S.ExternalLocationOption $isDisabled={false}>
                  <Typography variant="ui" size="large" weight="regular">
                    {location.label}
                  </Typography>
                </S.ExternalLocationOption>
              </S.ExternalLocationOptionContainer>
            );
          })}
        </>
      ) : (
        <CreateExternalLocation
          onBack={handleOnBack}
          showCreateExternalLocation={showCreateExternalLocation}
          subProgramId={subProgramId}
        />
      )}
    </div>
  );
});

export const CreateExternalLocation = forwardRef<
  HTMLDivElement,
  CreateExternalLocationProps
>(({ onBack, showCreateExternalLocation, subProgramId }, ref?) => {
  const [inputAddress, setInputAddress] = useState('');
  const [inputAddressComponents, setInputAddressComponents] =
    useState<Address>();
  const [skipToken, setSkipToken] = useState(false);
  const [placeId, setPlaceId] = useState('');
  const [externalLocationName, setExternalLocationName] = useState('');
  const [inputIsValid, setInputIsValid] = useState<boolean>(false);
  const [locationNameErrorMessage, setLocationNameErrorMessage] = useState('');
  const [locationAddressErrorMessage, setLocationAddressErrorMessage] =
    useState('');
  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState<string>();
  const [externalLocationDescription, setExternalLocationDescription] =
    useState('');
  const externalLocationRef = useRef<HTMLInputElement>(null);

  /* Google Maps API */
  // We should eventually remove the requirement of putting this in the path for the request since we rly dont need it.
  const { subdomain: siteDomain } = getLAHostnameParts();

  const { data: autoCompleteData } = useGetPlacesAutoCompleteQuery(
    { input: inputAddress, siteDomain },
    {
      skip: !inputAddress.length || skipToken,
      refetchOnMountOrArgChange: true,
    }
  );
  const [getLazyPlaceInfo] = useLazyGetPlacesInfoQuery();

  const formatPredictions = (
    predictions: GooglePlacesAutoCompleteSuggestions[]
  ): PlacePrediction[] => {
    return predictions.map((i: GooglePlacesAutoCompleteSuggestions) => ({
      placeId: i?.placePrediction?.placeId,
      placeName: i?.placePrediction?.text?.text,
    }));
  };
  const predictions = autoCompleteData?.suggestions ?? [];
  const formattedPredictions = predictions.length
    ? formatPredictions(predictions)
    : [];

  const handleSelectAddress = async (placeId: string) => {
    // Set place
    setPlaceId(placeId);
    const data = await getLazyPlaceInfo({
      input: placeId,
      siteDomain: siteDomain,
    }).unwrap();

    // Set address associated to the place + the name if we have it
    if (data) {
      setInputAddress(data.formattedAddress);
      setInputAddressComponents(
        formatGoogleMapsAddress(data.addressComponents)
      );
    }
    if (data.displayName?.text && externalLocationName === '') {
      setExternalLocationName(data?.displayName?.text);
    }
  };

  // used to debounce the search so we don't send so many reqs
  useEffect(() => {
    setSkipToken(true);
    const timer = setTimeout(() => {
      setSkipToken(false);
    }, 750);

    return () => {
      clearTimeout(timer);
    };
  }, [inputAddress]);

  const showExternalAddressPopOver =
    showCreateExternalLocation &&
    !!inputAddress.length &&
    !!formattedPredictions.length &&
    !placeId;

  const popOverWidth = externalLocationRef.current?.clientWidth ?? 700;
  /** */

  /* Create Location */

  const { siteId } = useAppSelector(getSiteIdentityData);
  const [
    createProgramLocation,
    {
      isLoading: isCreateAddressLocationLoading,
      isError: isCreateAddressLocationError,
    },
  ] = useCreateProgramLocationMutation();
  const { isFetching: isFetchingLocations } = useGetLocationsQuery(
    {
      siteId: siteId ?? '',
      programId: subProgramId ?? '',
    },
    { skip: !subProgramId || !siteId }
  );

  useEffect(() => {
    // show the loading spinner until request is done & new locations have been fetched
    // in order to show the newly created location as the seleced value
    if (selectedValue && !isFetchingLocations && inputIsValid) {
      onBack(selectedValue);
    }
  }, [inputIsValid, isFetchingLocations, onBack, selectedValue]);

  useEffect(() => {
    if (isCreateAddressLocationError && !requestErrorMessage) {
      setRequestErrorMessage(
        'An unexpected error has occured. Please try again.'
      );
    }
  }, [isCreateAddressLocationError, requestErrorMessage]);

  // Address validation
  const validAddress = () => {
    let valid = true;
    setLocationAddressErrorMessage('');
    setLocationNameErrorMessage('');

    if (!placeId) {
      setLocationAddressErrorMessage('Please enter a valid address.');
      valid = false;
    }
    if (!inputAddress) {
      setLocationAddressErrorMessage('Location address is required.');
      valid = false;
    }
    if (!externalLocationName) {
      setLocationNameErrorMessage('Location name is required.');
      valid = false;
    }

    setInputIsValid(valid);
    return valid;
  };

  const createNewExternalLocation = () => {
    const adddressIsValid = validAddress();

    if (adddressIsValid) {
      createProgramLocation({
        siteId,
        programId: subProgramId,
        createLocationRequestBody: {
          address: inputAddressComponents,
          description: externalLocationDescription,
          name: externalLocationName,
        },
      })
        .unwrap()
        .then((data: Location) => {
          setSelectedValue(data.id?.toString());
          setInputIsValid(true);
        })
        .catch((e: any) => {
          setRequestErrorMessage(
            e?.data.error ??
              'An unexpected error has occured. Please try again.'
          );
          setInputIsValid(false);
        });
    }
  };
  /** */

  return (
    <S.ExternalLocationOptionContainer
      role="option"
      aria-selected="false"
      $noHover={true}
    >
      <S.ExternalLocationOption>
        <S.ExternalLocationOptionLink onClick={() => onBack()}>
          <CaretLeftIcon fill="#007A00" variant="regular" /> Back to location
          selection
        </S.ExternalLocationOptionLink>
        <S.AddNewLocationInput>
          <Popover open={showExternalAddressPopOver}>
            <PopoverTrigger>
              <span>
                <TextInput
                  id="external-location-address"
                  label="Location address"
                  placeholder="Add location address"
                  required
                  hasError={!!locationAddressErrorMessage}
                  errorMessage={locationAddressErrorMessage}
                  value={inputAddress}
                  onChange={(e) => {
                    e.preventDefault();
                    setInputAddress(e.target.value);
                    setPlaceId('');
                  }}
                  ref={externalLocationRef}
                />
              </span>
            </PopoverTrigger>
            <S.ExternalLocationAddressPopover
              sideOffset={-200}
              arrow={false}
              avoidCollisions={false}
              side="bottom"
              $modalWidth={popOverWidth + 17}
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <AutoCompleteAddressDropdown
                formattedPredictions={formattedPredictions}
                onSelectAddress={handleSelectAddress}
                buttonWidth={popOverWidth}
              />
            </S.ExternalLocationAddressPopover>
          </Popover>
          <TextInput
            id="external-location-name"
            label="Location name"
            placeholder="Add location name"
            required
            value={externalLocationName}
            onChange={(e) => setExternalLocationName(e.target.value)}
            hasError={!!locationNameErrorMessage}
            errorMessage={locationNameErrorMessage}
          />

          <div>
            <Typography variant="ui" size="medium" weight="bold">
              <S.Label>Location Description</S.Label>
            </Typography>
            <S.DescriptionTextArea
              id="notes"
              placeholder="Add location description"
              rows={3}
              value={externalLocationDescription}
              onChange={(e) => setExternalLocationDescription(e.target.value)}
            />
          </div>
        </S.AddNewLocationInput>

        <S.ButtonContainer>
          <S.RequestErrorMessage>{requestErrorMessage}</S.RequestErrorMessage>
          <Button size="large" onClick={createNewExternalLocation}>
            {!isCreateAddressLocationLoading ? (
              <>Add Location</>
            ) : (
              <Loader loading />
            )}
          </Button>
        </S.ButtonContainer>
      </S.ExternalLocationOption>
    </S.ExternalLocationOptionContainer>
  );
});

const AutoCompleteAddressDropdown = ({
  formattedPredictions,
  onSelectAddress,
  buttonWidth,
}: AutoCompleteAddressDropdownProps) => {
  return (
    <div>
      {formattedPredictions.map((placePrediction: PlacePrediction) => {
        return (
          <S.ExternalLocationAddressOption key={placePrediction.placeId}>
            <AutoCompleteAddress
              placeId={placePrediction.placeId}
              placeName={placePrediction.placeName}
              onSelectAddress={onSelectAddress}
              buttonWidth={buttonWidth}
            />
          </S.ExternalLocationAddressOption>
        );
      })}
    </div>
  );
};

const AutoCompleteAddress = ({
  placeId,
  placeName,
  onSelectAddress,
}: AutoCompleteAddressProps) => {
  return (
    <Button
      onClick={() => {
        onSelectAddress(placeId);
      }}
      variant="text"
    >
      <Typography variant="ui" size="large">
        {placeName}
      </Typography>
    </Button>
  );
};

const cityTypes: GooglePlacesAddressTypes[] = [
  'locality',
  'sublocality',
  'sublocality_level_1',
];

const formatGoogleMapsAddress = (
  address: GooglePlaceAddressComponents[]
): Address => {
  const address1 = `${findAddressType(address, 'street_number')?.longText} ${findAddressType(address, 'route')?.longText}`;
  const address2 = findAddressType(address, 'subpremise')?.longText ?? '';
  const cityType = cityTypes.find((type) => findAddressType(address, type));
  const city = cityType ? findAddressType(address, cityType)?.shortText : '';
  const state =
    findAddressType(address, 'administrative_area_level_1')?.shortText ?? '';
  const country = findAddressType(address, 'country')?.shortText ?? '';
  const zipCode = findAddressType(address, 'postal_code')?.longText ?? '';

  return {
    address1,
    address2,
    city,
    state,
    country,
    zipCode,
  };
};

const findAddressType = (
  address: GooglePlaceAddressComponents[],
  addressType: GooglePlacesAddressTypes
) => {
  return address.find(({ types }) => types.indexOf(addressType) > -1);
};
