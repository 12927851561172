import styled from 'styled-components';
export const StyledLegend = styled.legend.withConfig({
  componentId: "sc-xuxgiw-0"
})(({
  theme
}) => {
  const {
    typography,
    foundational: {
      blueGrey
    }
  } = theme;
  return {
    padding: 0,
    margin: 0,
    ...typography.ui.large,
    color: blueGrey[900],
    position: 'relative',
    float: 'left'
  };
});