// react-hook-form doesn't play well with mergeRefs() so we need to merge the refs by manually assigning them
// https://react-hook-form.com/faqs#Howtosharerefusage
export const createAssignSharedRefs = (ref, internalRef) => element => {
  const isCallback = typeof ref === 'function';
  if (isCallback) {
    ref(element);
  }
  if (ref && 'current' in ref) {
    ref.current = element;
  }
  internalRef.current = element;
};