import styled from 'styled-components';
export const StyledTableContainer = styled.div.withConfig({
  componentId: "sc-1kxn7jw-0"
})(() => {
  return {
    overflowX: 'auto',
    maxWidth: '100%'
  };
});
export const StyledTable = styled.table.withConfig({
  componentId: "sc-1kxn7jw-1"
})(({
  theme
}) => {
  const {
    semantic,
    foundational: {
      blueGrey
    }
  } = theme;
  return {
    width: '100%',
    border: '1px solid',
    borderColor: !!semantic ? semantic.primary[300] : blueGrey[300],
    borderSpacing: 0,
    borderRadius: '4px',
    overflow: 'hidden'
  };
});
export const StyledTableRow = styled.tr.withConfig({
  componentId: "sc-1kxn7jw-2"
})(({
  theme
}) => {
  const {
    semantic,
    foundational: {
      blueGrey
    },
    typography,
    mediaQueries
  } = theme;
  return {
    ...typography.ui.small,
    color: blueGrey[900],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: !!semantic ? semantic.primary[25] : blueGrey[25]
    },
    [mediaQueries.desktop]: {
      ...typography.ui.medium
    }
  };
});
export const StyledTableHeader = styled.th.withConfig({
  componentId: "sc-1kxn7jw-3"
})(({
  theme,
  $type
}) => {
  const {
    semantic,
    foundational: {
      blueGrey
    },
    typography,
    mediaQueries
  } = theme;
  return {
    textAlign: $type === 'currency' ? 'right' : 'left',
    padding: '8px 12px',
    borderBottom: '1px solid',
    borderColor: !!semantic ? semantic.primary[300] : blueGrey[300],
    backgroundColor: !!semantic ? semantic.primary[35] : blueGrey[35],
    ...typography.ui.small,
    color: !!semantic ? semantic.primary[700] : blueGrey[700],
    fontWeight: typography.weights.bold,
    height: '32px',
    maxHeight: '100%',
    [mediaQueries.desktop]: {
      height: '36px',
      ...typography.ui.medium,
      fontWeight: typography.weights.bold
    }
  };
});
export const StyledTableCell = styled.td.withConfig({
  componentId: "sc-1kxn7jw-4"
})(({
  $isWrapped,
  $type
}) => {
  return {
    textAlign: $type === 'currency' ? 'right' : 'left',
    padding: '8px 12px',
    whiteSpace: $isWrapped ? 'break-spaces' : 'nowrap'
  };
});