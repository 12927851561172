"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymenOptionsStep = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var luxon_1 = require("luxon");
var ds_ui_components_1 = require("@la/ds-ui-components");
var TeamRegistrationContext_1 = require("../TeamRegistration/TeamRegistrationContext");
var getNumberOfRegisteringTeams_1 = require("../utilities/getNumberOfRegisteringTeams");
var S = require("./PaymentOptionsStep.styles");
var PaymenOptionsStep = function (_a) {
    var paymentOptions = _a.paymentOptions, paymentRules = _a.paymentRules, setTotalCost = _a.setTotalCost;
    var _b = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext), state = _b.state, setSelectedPaymentOption = _b.setSelectedPaymentOption;
    var payInFull = paymentRules.payInFull;
    var numberOfRegisteringTeams = (0, react_1.useMemo)(function () {
        return (0, getNumberOfRegisteringTeams_1.getNumberOfRegisteringTeams)(state.wizardState.divisionCardsState);
    }, [state]);
    var getTotalCost = function () {
        if (!state.selectedPaymentOption ||
            state.selectedPaymentOption === 'pay-in-full') {
            var divisionCardsState = state.wizardState.divisionCardsState;
            var totalCost_1 = 0;
            var _loop_1 = function (divisionId, divisionState) {
                if (divisionState.value !== 'saved') {
                    return "continue";
                }
                var division = state.divisions.find(function (d) { return d.id === divisionId; });
                if (!division.cost) {
                    return "continue";
                }
                totalCost_1 +=
                    parseFloat(division.cost) * divisionState.savedTeams.length;
            };
            for (var _i = 0, _a = Object.entries(divisionCardsState); _i < _a.length; _i++) {
                var _b = _a[_i], divisionId = _b[0], divisionState = _b[1];
                _loop_1(divisionId, divisionState);
            }
            return totalCost_1;
        }
        var paymentPricing = state.paymentPricing.find(function (pricing) {
            return pricing.ngPaymentPlanId === state.selectedPaymentOption;
        });
        return paymentPricing.amount;
    };
    var totalCost = getTotalCost();
    (0, react_1.useEffect)(function () {
        setTotalCost(totalCost);
    });
    var handleSelectOption = function (optionId) {
        setSelectedPaymentOption(optionId);
    };
    var cost = (0, ds_ui_components_1.formatAsCurrency)(totalCost);
    return ((0, jsx_runtime_1.jsxs)(S.PaymentOptionsContainer, { children: [(0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: ["Select a payment option for the", ' ', (0, jsx_runtime_1.jsx)("strong", { children: numberOfRegisteringTeams }), ' ', numberOfRegisteringTeams === 1 ? 'invoice' : 'invoices', " totaling", ' ', (0, jsx_runtime_1.jsx)("strong", { children: cost }), "."] })), (0, jsx_runtime_1.jsxs)(S.PaymentOptionsInnerContainer, { children: [paymentOptions.map(function (paymentOption) { return ((0, jsx_runtime_1.jsx)(PaymentOptionComponent, { onSelectPaymentOption: handleSelectOption, paymentOption: paymentOption, paymentPricing: state.paymentPricing.find(function (pricing) {
                            return pricing.ngPaymentPlanId === paymentOption.paymentPlanId;
                        }), cost: totalCost }, paymentOption.paymentPlanId)); }), payInFull ? ((0, jsx_runtime_1.jsx)(S.PaymentOption, { children: (0, jsx_runtime_1.jsxs)(S.PaymentOptionHeader, { children: [(0, jsx_runtime_1.jsx)(S.RadioButton, { type: "radio", name: "selected_option", id: "pay-in-full", onClick: function () { return handleSelectOption('pay-in-full'); } }), (0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: "pay-in-full" }, { children: "Pay in full" }))] }) })) : null] })] }));
};
exports.PaymenOptionsStep = PaymenOptionsStep;
function isTodayWithinRange(start, end) {
    var today = luxon_1.DateTime.now();
    var startDate = start ? luxon_1.DateTime.fromISO(start) : null;
    var endDate = end ? luxon_1.DateTime.fromISO(end) : null;
    if (startDate && endDate) {
        return today >= startDate && today <= endDate;
    }
    if (startDate) {
        return today > startDate;
    }
    if (endDate) {
        return today <= endDate;
    }
    return true;
}
var PaymentOptionComponent = function (_a) {
    var paymentOption = _a.paymentOption, paymentPricing = _a.paymentPricing, cost = _a.cost, onSelectPaymentOption = _a.onSelectPaymentOption;
    var _b = (0, react_1.useState)(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var paymentPlanId = paymentOption.paymentPlanId, isPrivate = paymentOption.isPrivate, userEmails = paymentOption.userEmails, startDate = paymentOption.startDate, endDate = paymentOption.endDate;
    var state = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state;
    var installments = paymentPricing.lineItems[0].installments;
    var isUserAllowed = isPrivate
        ? userEmails.includes(state.payer.email)
        : true;
    var isWithinRange = isTodayWithinRange(startDate, endDate);
    if (!isUserAllowed || !isWithinRange) {
        return null;
    }
    var getFormattedDueDate = function (date) {
        var isoDate = luxon_1.DateTime.fromISO(date);
        var formattedDate = isoDate.toFormat('MMM d, yyyy');
        return formattedDate;
    };
    return ((0, jsx_runtime_1.jsxs)(S.PaymentOption, { children: [(0, jsx_runtime_1.jsxs)(S.PaymentOptionHeader, { children: [(0, jsx_runtime_1.jsx)(S.RadioButton, { type: "radio", name: "selected_option", id: paymentPlanId, onClick: function () { return onSelectPaymentOption(paymentPlanId); } }), (0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: paymentPlanId }, { children: paymentOption.name })), (0, jsx_runtime_1.jsx)(S.ExpandOptionDetails, { children: (0, jsx_runtime_1.jsxs)(S.ExpandButton, __assign({ "$isOpen": isExpanded, onClick: function () {
                                setIsExpanded(function (expanded) { return !expanded; });
                            } }, { children: [isExpanded ? 'Hide details' : 'View details', (0, jsx_runtime_1.jsx)(ds_ui_components_1.CaretDownIcon, { variant: "filled", size: "medium" })] })) })] }), (0, jsx_runtime_1.jsx)(S.PaymentOptionInstallments, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "xs" }, { children: [installments.length, " Payments"] })) }), isExpanded ? ((0, jsx_runtime_1.jsx)(S.PaymentOptionBody, { children: installments.map(function (installment) { return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "medium" }, { children: [(0, ds_ui_components_1.formatAsCurrency)(installment.fixedAmount
                            ? cost + installment.fixedAmount
                            : cost * (installment.percentage / 100)), ' ', "due", ' ', (0, jsx_runtime_1.jsx)("strong", { children: getFormattedDueDate(installment.paymentDate) })] }), installment.paymentPlanInstallmentId)); }) })) : null] }, paymentPlanId));
};
