import styled from 'styled-components/macro';
import {
  CloseIcon,
  ShootingStarIcon,
  ThemedComponent,
} from '@la/ds-ui-components';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';

export const CardSection = styled(ColumnGrid)`
  margin: 16px 0;
  row-gap: 16px;
`;

/**
 * This container is used to make the register section stick to the bottom
 * on smaller screens.
 **/
export const RegisterSectionContainer = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 12px 16px;
  border-top: 1px solid var(--primary-100);
  background-color: var(--white);
`;

export const Alert = styled.div<ThemedComponent>`
  width: 100%;
  height: 56px;
  padding: 16px;
  background-color: ${({ theme }: ThemedComponent) =>
    theme.foundational.orange[50]};
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }: ThemedComponent) => theme.palette.warning.dark};
  margin-top: 24px;
  grid-column-start: 1;
  grid-column-end: -1;

  & p {
    margin-left: 12px;
    flex: 1;
  }
  & svg {
    fill: ${({ theme }: ThemedComponent) => theme.foundational.orange[700]};
  }
`;

export const ShootingStarIconContainer = styled(ShootingStarIcon).attrs({
  size: 'medium',
  variant: 'filled',
})({});

export const CloseIconClickableContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIconContainer = styled(CloseIcon).attrs({
  size: 'medium',
  fill: 'var(--blue-grey-900)',
})`
  cursor: pointer;
`;
