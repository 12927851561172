import { DateTime } from 'luxon';
import { RegistrationPeriodStatus } from '../TournamentDetailsPage';
import {
  START_DATE_BEFORE_END_DATE_ERROR_MESSAGE,
  setDatesTimezone,
} from './date';

/**
 * Calculates the number of days between two dates.
 * @param dateA Date to calculate difference from.
 * @param dateB Date to calculate difference to.
 * @returns Number of days between the two dates, rounded up to the nearest whole day.
 */
const getNumberOfDays = (dateA: DateTime, dateB: DateTime): number => {
  return Math.ceil(dateA.diff(dateB, 'days').days);
};

/**
 * Get the registration period status given the registration start
 * and end dates.
 * @param start Start of registration period in ISO format.
 * @param end End of registration period in ISO format.
 * @param timezone The timezone that the start and end dates should be
 * considered the origin.
 * @returns `RegistrationPeriodStatus` depending on where the current date falls.
 *
 * If there is no start and end registration date, then the program is
 * considered to have an open registration period:
 * ```
 *  { state: 'no-registration-dates' }
 * ```
 * If the current date is before the registration start date:
 * ```
 * {
 *  state: 'before-registration-period-opens',
 *  numberOfDays: difference between `start` and current date, rounded up to nearest whole day
 * }
 * ```
 * if the current date is after the registration start date and
 * there is no registration end date:
 * ```
 * {
 *  state: 'after-registration-period-opens'
 * }
 * ```
 * If the current date is before the registration end date:
 * ```
 * {
 *  state: 'before-registration-period-ends',
 *  numberOfDays: difference between `end` and current date, rounded up to nearest whole day
 * }
 * ```
 */
export const getRegistrationPeriodStatus = (
  start: string | undefined,
  end: string | undefined,
  timezone: string
): RegistrationPeriodStatus => {
  const currentDate = DateTime.now().setZone(timezone);
  // start and end date defined
  if (start && end) {
    let [startDate, endDate] = setDatesTimezone([start, end], timezone);
    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');

    if (startDate > endDate) {
      throw new Error(START_DATE_BEFORE_END_DATE_ERROR_MESSAGE);
    }

    if (currentDate >= startDate) {
      if (currentDate <= endDate) {
        return {
          state: 'before-registration-period-ends',
          numberOfDays: getNumberOfDays(endDate, currentDate),
        };
      }

      return {
        state: 'after-registration-period-ends',
      };
    }

    return {
      state: 'before-registration-period-opens',
      numberOfDays: getNumberOfDays(startDate, currentDate),
    };
  }

  // start date defined, end date undefined
  if (start && !end) {
    let [startDate] = setDatesTimezone([start], timezone);
    startDate = startDate.startOf('day');

    if (currentDate < startDate) {
      return {
        state: 'before-registration-period-opens',
        numberOfDays: getNumberOfDays(startDate, currentDate),
      };
    }
    return {
      state: 'before-registration-period-ends',
    };
  }

  // end date defined, start date undefined
  if (end && !start) {
    let [endDate] = setDatesTimezone([end], timezone);
    endDate = endDate.endOf('day');

    if (currentDate <= endDate) {
      return {
        state: 'before-registration-period-ends',
        numberOfDays: getNumberOfDays(endDate, currentDate),
      };
    }

    return {
      state: 'after-registration-period-ends',
    };
  }

  // no dates defined
  return {
    state: 'no-registration-dates',
  };
};
