import styled from 'styled-components/macro';
import Page from '../Shell/Page/Page';

export const SwatchGenerator = styled(Page)`
  padding: 20px;
`;

export const Swatch = styled.span<{ $swatchColor: string; $textColor: string }>`
  width: 200px;
  padding: 10px;
  font-size: 18px;
  color: ${({ $textColor }) => `var(${$textColor});`};
  border-radius: 4px;
  background-color: ${({ $swatchColor }) => `var(${$swatchColor});`};
`;

export const TempSwatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 216px;
  padding: 8px;
  border: 1px solid #666666;
  border-radius: 4px;
  float: left;
`;
