type BreakpointName =
  | 'BIG_DESKTOP'
  | 'DESKTOP'
  | 'TABLET_LANDSCAPE'
  | 'TABLET_PORTRAIT'
  | 'PHONE';

type BreakpointRange = {
  [key in BreakpointName]: { min: number; max: number };
};

export const BREAKPOINTS: BreakpointRange = {
  BIG_DESKTOP: { min: 1500, max: 10000 },
  DESKTOP: { min: 1200, max: 1499 },
  TABLET_LANDSCAPE: { min: 900, max: 1199 },
  TABLET_PORTRAIT: { min: 600, max: 899 },
  PHONE: { min: 0, max: 599 },
};
