import styled from 'styled-components/macro';

export const LoadingIndicatorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: var(--white);
  opacity: 0.9;
`;

export const LoadingRippleWrapper = styled.div`
  height: fit-content;
`;

export const LoadingMessage = styled.div`
  font: var(--ui-large-regular);
  text-align: center;
`;
