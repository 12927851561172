import { useEffect, useState } from 'react';
import { BREAKPOINTS } from './BreakpointConstants';

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    if (
      BREAKPOINTS.PHONE.min <= windowSize.width &&
      windowSize.width <= BREAKPOINTS.PHONE.max
    ) {
      setBreakPoint('Phone');
    }
    if (
      BREAKPOINTS.TABLET_PORTRAIT.min <= windowSize.width &&
      windowSize.width <= BREAKPOINTS.TABLET_PORTRAIT.max
    ) {
      setBreakPoint('TabletPortrait');
    }
    if (
      BREAKPOINTS.TABLET_LANDSCAPE.min <= windowSize.width &&
      windowSize.width <= BREAKPOINTS.TABLET_LANDSCAPE.max
    ) {
      setBreakPoint('TabletLandscape');
    }
    if (
      BREAKPOINTS.DESKTOP.min <= windowSize.width &&
      windowSize.width <= BREAKPOINTS.DESKTOP.max
    ) {
      setBreakPoint('Desktop');
    }
    if (windowSize.width >= BREAKPOINTS.BIG_DESKTOP.min) {
      setBreakPoint('BigDesktop');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  return breakpoint;
};

export default useBreakpoint;

/**
 * https://github.com/chilupa/useBreakpoint-hook/blob/develop/src/hooks/useBreakpoint.js
 */
