"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuActionsFooter = exports.MenuBody = exports.HeaderCloseIcon = exports.MenuHeader = exports.MenuContainer = exports.MenuBackdrop = void 0;
var styled_components_1 = require("styled-components");
var ds_ui_components_1 = require("@la/ds-ui-components");
var Backdrop_styles_1 = require("../Backdrop/Backdrop.styles");
var fadeInBackdrop = (0, styled_components_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { opacity: 0; }\n  100% { opacity: 1; }\n"], ["\n  0% { opacity: 0; }\n  100% { opacity: 1; }\n"])));
var fadeOutBackdrop = (0, styled_components_1.keyframes)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% { opacity: 1; }\n  100% { opacity: 0;  }\n"], ["\n  0% { opacity: 1; }\n  100% { opacity: 0;  }\n"])));
function animation(_a) {
    var $open = _a.$open, $side = _a.$side;
    if ($open) {
        if ($side === 'right') {
            return (0, styled_components_1.keyframes)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        0% { right: -100%; }\n        100% { right: 0; }\n       "], ["\n        0% { right: -100%; }\n        100% { right: 0; }\n       "])));
        }
        return (0, styled_components_1.keyframes)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      0% { left: -100%; }\n      100% { left: 0; }\n    "], ["\n      0% { left: -100%; }\n      100% { left: 0; }\n    "])));
    }
    else {
        if ($side === 'right') {
            return (0, styled_components_1.keyframes)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        0% { right: 0; }\n        100% { right: -100%; }\n       "], ["\n        0% { right: 0; }\n        100% { right: -100%; }\n       "])));
        }
        return (0, styled_components_1.keyframes)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      0% { left: 0; }\n      100% { left: -100%; }\n    "], ["\n      0% { left: 0; }\n      100% { left: -100%; }\n    "])));
    }
}
exports.MenuBackdrop = (0, styled_components_1.default)(Backdrop_styles_1.Backdrop)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  opacity: ", ";\n  animation: ", " 0.3s\n    ease-in-out;\n"], ["\n  opacity: ", ";\n  animation: ", " 0.3s\n    ease-in-out;\n"])), function (_a) {
    var $open = _a.$open;
    return ($open ? 1 : 0);
}, function (_a) {
    var $open = _a.$open;
    return ($open ? fadeInBackdrop : fadeOutBackdrop);
});
exports.MenuContainer = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  width: 390px;\n  background-color: var(--white);\n\n  ", "\n\n  animation: ", " 0.3s ease-in-out;\n  height: 100vh;\n"], ["\n  position: absolute;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  width: 390px;\n  background-color: var(--white);\n\n  ", "\n\n  animation: ", " 0.3s ease-in-out;\n  height: 100vh;\n"])), function (_a) {
    var $side = _a.$side;
    if ($side === 'right') {
        return 'right: 0px;';
    }
    return 'left: 0px;';
}, function (_a) {
    var $open = _a.$open, $side = _a.$side;
    return animation({ $open: $open, $side: $side });
});
exports.MenuHeader = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    var _b = theme.foundational, green = _b.green, white = _b.white;
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
        backgroundColor: green[600],
        h4: {
            color: white,
        },
        button: {
            minHeight: 'auto',
        },
    };
});
exports.HeaderCloseIcon = (0, styled_components_1.default)(ds_ui_components_1.IconButton)({
    '& svg': {
        height: '12px',
        width: '12px',
        strokeWidth: '24px',
    },
});
exports.MenuBody = styled_components_1.default.div({
    padding: '24px',
    height: '100vh',
    overflowY: 'auto',
});
exports.MenuActionsFooter = styled_components_1.default.div(function (_a) {
    var theme = _a.theme;
    var _b = theme.foundational, blueGrey = _b.blueGrey, white = _b.white;
    return {
        borderTop: "1px solid ".concat(blueGrey[100]),
        background: "".concat(white),
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.05)',
        padding: '12px',
    };
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
