import { forwardRef, useState } from 'react';
import { Typography } from '@la/ds-ui-components';
import { TeamPrimaryStaffSelectOptions } from '../utils/ActivityForm.utils';
import * as S from './TeamPrimaryStaffSelect.styles';

type TeamPrimaryStaffSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  showExternalOption?: boolean;
  teams: TeamPrimaryStaffSelectOptions[];
  value?: string;
};

export const TeamPrimaryStaffSelect = forwardRef<
  HTMLDivElement,
  TeamPrimaryStaffSelectProps
>(({ onChange, onClose, showExternalOption, teams, value }, ref?) => {
  const placeholderActive = value ? false : true;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleOnSelect = (team?: string) => {
    onChange(team);
    onClose(team);
  };

  return (
    <div>
      <S.TeamPrimaryStaffHeader>
        <Typography variant="ui" size="medium" weight="medium">
          Team name:
        </Typography>
        <Typography variant="ui" size="medium" weight="medium">
          Primary staff member:
        </Typography>
      </S.TeamPrimaryStaffHeader>

      {/* Placeholder option */}
      <S.TeamOptionContainer
        role="option"
        aria-selected={placeholderActive && !isHovered}
        $isPlaceholder={true}
        onMouseOver={() => setIsHovered(true)}
        onClick={() => handleOnSelect()}
      >
        <S.TeamOption>Select a team...</S.TeamOption>
      </S.TeamOptionContainer>

      {/* Extrernal Team Option */}
      {!!showExternalOption ? (
        <S.TeamOptionContainer
          role="option"
          aria-selected={value === 'external'}
          onMouseOver={() => setIsHovered(true)}
          onClick={() => handleOnSelect('external')}
        >
          <S.TeamOption>External team</S.TeamOption>
        </S.TeamOptionContainer>
      ) : null}

      {teams.map((team) => {
        return (
          <S.TeamOptionContainer
            role="option"
            aria-disabled={team.disabled}
            aria-selected={value === team.value ? true : false}
            $isDisabled={team.disabled}
            onMouseOver={() => setIsHovered(true)}
            onClick={() => handleOnSelect(team.value)}
            key={team.value}
          >
            <S.TeamOption $isDisabled={team.disabled}>
              <Typography variant="ui" size="large" weight="regular">
                {team.label}
              </Typography>
              <Typography variant="ui" size="medium" weight="regular" italic>
                {team.primaryStaffName}
              </Typography>
            </S.TeamOption>
          </S.TeamOptionContainer>
        );
      })}
    </div>
  );
});
