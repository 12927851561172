import { createSlice } from '@reduxjs/toolkit';
import {
  BillingSummaryData,
  CartItemHotelLink,
  CartSummaryData,
} from 'domains/Checkout/Checkout.types';
import { RootState } from './store';

interface State {
  summaryData: BillingSummaryData | null;
  cartSummaryData: CartSummaryData | null;
  hotelLinks: CartItemHotelLink[];
}

const initialState: State = {
  summaryData: null,
  cartSummaryData: null,
  hotelLinks: [],
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setSummaryData(state, action) {
      state.summaryData = action.payload;
    },
    setCartSummaryData(state, action) {
      state.cartSummaryData = action.payload;
    },
    setHotelLinks(state, action) {
      state.hotelLinks = action.payload;
    },
  },
});

export const { setSummaryData, setCartSummaryData, setHotelLinks } =
  checkoutSlice.actions;

export const getSummaryData = (state: RootState) => state.checkout.summaryData;
export const getCartSummaryData = (state: RootState) =>
  state.checkout.cartSummaryData;
export const getHotelLinks = (state: RootState) => state.checkout.hotelLinks;

export default checkoutSlice.reducer;
